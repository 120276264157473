import * as React from "react";
import { Svg, type SvgProps } from "replo-utils/react/components";

export const SvgDynamicData = (props: SvgProps) => (
  <Svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
    <circle cx={6.25} cy={6.25} r={1.25} fill="#94A3B8" />
    <circle cx={11.25} cy={6.25} r={1.25} fill="#94A3B8" />
    <circle cx={6.25} cy={11.25} r={1.25} fill="#94A3B8" />
    <circle cx={11.25} cy={11.25} r={1.25} fill="#94A3B8" />
  </Svg>
);
export default SvgDynamicData;

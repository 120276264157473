import Switch from "@common/designSystem/Switch";
import * as React from "react";

const ToggleWithLabel: React.FC<{
  label: string;
  isActive: boolean;
  setActive: (active: boolean) => void;
}> = ({ label, isActive, setActive }) => {
  return (
    <div className="flex flex-row justify-between">
      <span className="w-4/5 text-xs text-slate-400">{label}</span>
      <Switch
        isOn={isActive}
        backgroundOnColor="bg-blue-600"
        thumbColor="#fff"
        onChange={(active) => setActive(active)}
      />
    </div>
  );
};

export default ToggleWithLabel;

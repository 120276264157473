import Tooltip from "@common/designSystem/Tooltip";
import PaginationMetrics from "@editor/components/analytics/PaginationMetrics";
import { SortButton } from "@editor/components/analytics/SortIndicator";
import TableCellContent from "@editor/components/analytics/TableCellContent";
import {
  Input,
  useOverridableInput,
} from "@editor/components/common/designSystem/Input";
import { SimpleSkeletonLoader } from "@editor/components/common/designSystem/SkeletonLoader";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { calculateDelta } from "@editor/utils/analytics";
import { Button } from "@replo/design-system/components/button";
import { Combobox } from "@replo/design-system/components/shadcn/core/combobox";
import { DatePickerWithRange } from "@replo/design-system/components/shadcn/core/date-range-picker";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@replo/design-system/components/shadcn/core/table";
import classNames from "classnames";
import isEqual from "lodash-es/isEqual";
import * as React from "react";
import type { DateAfter, DateRange } from "react-day-picker";
import {
  BsCalendar3,
  BsCaretDownFill,
  BsGlobe2,
  BsSearch,
} from "react-icons/bs";
import { dateRangeFormatter } from "replo-utils/lib/datetime";
import {
  type AnalyticsReadQuery,
  ConditionOperatorEnum,
  type FilterName,
  type MetricName,
  type RangeResult,
} from "schemas/analyticsRead";

import {
  DEFAULT_FILTERS,
  DEFAULT_QUERY,
  DEFAULT_URL_HOST,
  RESULTS_PER_PAGE,
  TABLE_METRICS,
} from "@/features/analytics/constants";
import { NoData } from "@/features/analytics/NoData";
import type { DefaultTimeframeOption } from "@/features/analytics/time";
import {
  DEFAULT_CUSTOM_DATE_RANGE_COMBOBOX_OPTION_VALUE,
  LAST_7_DAYS_COMBOBOX_OPTION_VALUE,
  MS_IN_SELECTED_RANGE,
  TIMEFRAME_COMBOBOX_OPTIONS,
} from "@/features/analytics/time";
import useAnalyticsRead from "@/features/analytics/useAnalyticsRead";
import useWorkspaceUrlHosts from "@/features/analytics/useWorkspaceUrlHosts";

const AllMetricsRow: React.FC<{
  urlPath: string;
  mainRangeResultMetrics: RangeResult["metrics"];
  compareAtRangeResultMetrics: RangeResult["metrics"];
  metadata: RangeResult["metadata"];
}> = ({
  urlPath,
  mainRangeResultMetrics,
  compareAtRangeResultMetrics,
  metadata,
}) => {
  const { title, url } = metadata;

  const compareAtRangeViewsArray = compareAtRangeResultMetrics.views ?? [];
  const doesCompareValueExist = (compareAtRangeViewsArray[0] ?? 0) > 0;

  const adjustedUrlPath =
    urlPath === "" || urlPath === "/" ? "Homepage" : urlPath;

  return (
    <TableRow key={adjustedUrlPath} className="h-[56px]">
      <TableCell className="flex justify-left items-center min-w-[250px] px-4 py-3 h-[56px]">
        <div className="flex flex-col gap-1 max-w-[250px] min-w-[250px]">
          <Tooltip triggerAsChild content={title} delay={800}>
            <span className="text-blue-600 overflow-hidden overflow-ellipsis truncate">
              {title}
            </span>
          </Tooltip>
          <Tooltip
            triggerAsChild
            content={url}
            delay={800}
            className="overflow-hidden break-words"
          >
            <a
              href={url}
              target="_blank"
              className="text-xs font-light text-slate-500 hover:underline overflow-hidden break-words truncate"
              rel="noreferrer"
            >
              {adjustedUrlPath}
            </a>
          </Tooltip>
        </div>
      </TableCell>
      {TABLE_METRICS.map((metric) => {
        const mainRangeResultCurrentMetricArray =
          mainRangeResultMetrics[metric.key as MetricName] ?? [];
        const compareAtRangeResultCurrentMetricArray =
          compareAtRangeResultMetrics[metric.key as MetricName] ?? [];

        return (
          <TableCell key={metric.key} className="px-4 py-3">
            <TableCellContent
              name={metric.key}
              value={mainRangeResultCurrentMetricArray[0]}
              delta={calculateDelta(
                mainRangeResultCurrentMetricArray[0],
                compareAtRangeResultCurrentMetricArray[0],
              )}
              doesCompareValueExist={doesCompareValueExist}
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const AllMetricsRows: React.FC<{
  mainRangeResults: RangeResult[];
  compareAtRangeResults: RangeResult[];
}> = ({ mainRangeResults, compareAtRangeResults }) => {
  return (
    <>
      {mainRangeResults.map((mainRangeResult) => {
        const {
          urlPath,
          metadata,
          metrics: mainRangeResultMetrics,
        } = mainRangeResult;

        const compareAtRangeResult = compareAtRangeResults.find(
          (rangeResult) => rangeResult.urlPath === urlPath,
        );

        const compareAtRangeResultMetrics = compareAtRangeResult?.metrics ?? {};

        return (
          <AllMetricsRow
            key={urlPath}
            urlPath={urlPath}
            mainRangeResultMetrics={mainRangeResultMetrics}
            compareAtRangeResultMetrics={compareAtRangeResultMetrics}
            metadata={metadata}
          />
        );
      })}
    </>
  );
};

const AllMetricsTable: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();
  const shopifyURLOptions = useWorkspaceUrlHosts(workspaceId);

  return shopifyURLOptions.isLoading ? (
    <SimpleSkeletonLoader height="500" width="100%" />
  ) : (
    <LoadedAllMetricsTable
      shopifyURLOptions={shopifyURLOptions.data}
      workspaceId={workspaceId ?? ""}
    />
  );
};

type LoadedAllMetricsTableProps = {
  shopifyURLOptions: { value: string; label: string }[];
  workspaceId: string;
};

const LoadedAllMetricsTable: React.FC<LoadedAllMetricsTableProps> = ({
  shopifyURLOptions,
  workspaceId,
}) => {
  const extendedShopifyURLOptions = React.useMemo(
    () => [
      ...(shopifyURLOptions.length > 1
        ? [{ value: DEFAULT_URL_HOST, label: "All Domains" }]
        : []),
      ...shopifyURLOptions,
    ],
    [shopifyURLOptions],
  );

  const [openTimeRangePicker, setOpenTimeRangePicker] = React.useState(false);
  const [currentQuery, setCurrentQuery] = React.useState<AnalyticsReadQuery>({
    ...DEFAULT_QUERY,
    urlHosts: shopifyURLOptions.map((option) => option.value),
  });

  const mainRangeQuery = currentQuery.ranges.mainRange;
  // NOTE (Max, 2024-09-10): We take the 1st element as in the All Metrics Page, we only allow
  // compareAt with 1 other range.
  const compareAtRangesQuery = currentQuery.ranges.compareAtRanges[0];

  const { rangeResults, totalRowsCount, isLoading } = useAnalyticsRead(
    currentQuery,
    workspaceId,
  );

  const mainRangeResults = React.useMemo(
    () =>
      rangeResults?.filter(
        (rangeResultObj) => rangeResultObj.rangeId === mainRangeQuery.id,
      ) as RangeResult[],
    [rangeResults, mainRangeQuery.id],
  );

  const compareAtRangeResults = React.useMemo(
    () =>
      rangeResults?.filter(
        (rangeResultObj) => rangeResultObj.rangeId === compareAtRangesQuery?.id,
      ) as RangeResult[],
    [rangeResults, compareAtRangesQuery?.id],
  );

  // NOTE (Max, 2024-08-26): pageNumber is the value we show to the users. It starts
  // from 1. However, when querying the backend, we'll need to substract 1, as
  // ClickHouse's OFFSET starts at 0
  const [currentPageNumber, setCurrentPageNumber] = React.useState<number>(1);

  const resultsPerPage = RESULTS_PER_PAGE;
  // TODO (Max, 2024-08-27): Uncomment once we allow user to specify how many
  // pages per result they want

  // const [resultsPerPage, setResultsPerPage] =
  //   React.useState<number>(RESULTS_PER_PAGE);

  const handleOnPageNumberChange = (clickedPageNumber: number) => {
    if (clickedPageNumber !== currentPageNumber) {
      setCurrentPageNumber(clickedPageNumber);

      // NOTE (Max, 2024-08-26): Relevant to have it as state variable once we allow users
      // to choose how many results per page they want
      const offset = (clickedPageNumber - 1) * resultsPerPage;
      setCurrentQuery({
        ...currentQuery,
        offset,
      });
    }
  };

  const shopifyUrlOptionValues = React.useMemo(
    () => shopifyURLOptions.map((option) => option.value),
    [shopifyURLOptions],
  );

  const handleUrlHostChange = (value: string) => {
    setCurrentQuery({
      ...currentQuery,
      urlHosts: value === DEFAULT_URL_HOST ? shopifyUrlOptionValues : [value],
    });
  };

  const handleMetricSortClick = (sortMetric: MetricName) => {
    const isActiveMetric = currentQuery.sortMetric === sortMetric;
    setCurrentPageNumber(1);
    setCurrentQuery({
      ...currentQuery,
      sortMetric,
      order: isActiveMetric
        ? // TODO (Gabe 2024-09-10): prettier --write undoes what eslint --fix does
          // here, so i'm just disabling the eslint rule.
          // eslint-disable-next-line unicorn/no-nested-ternary
          currentQuery.order === "DESC"
          ? "ASC"
          : "DESC"
        : currentQuery.order,
      offset: 0,
    });
  };

  const [selectedTimeRange, setSelectedTimeRange] = React.useState<
    DefaultTimeframeOption | string
  >(LAST_7_DAYS_COMBOBOX_OPTION_VALUE);

  const handleTimeRangeChange = (
    selectedTimeRangeValue: DefaultTimeframeOption | DateRange,
  ) => {
    let startDatetime: number, endDatetime: number, interval: number;

    const isCustomRange = typeof selectedTimeRangeValue !== "string";

    if (
      isCustomRange &&
      selectedTimeRangeValue.to &&
      selectedTimeRangeValue.from
    ) {
      endDatetime = selectedTimeRangeValue.to.getTime();
      startDatetime = selectedTimeRangeValue.from.getTime();
      interval = endDatetime - startDatetime;
      setSelectedTimeRange(
        dateRangeFormatter({
          from: selectedTimeRangeValue.from,
          to: selectedTimeRangeValue.to,
        }),
      );
    } else {
      const selectedTimeRangeOption =
        selectedTimeRangeValue as DefaultTimeframeOption;

      endDatetime = Date.now();
      interval = MS_IN_SELECTED_RANGE[selectedTimeRangeOption];
      startDatetime = endDatetime - interval;

      setSelectedTimeRange(selectedTimeRangeOption);
    }

    const updatedRanges = {
      mainRange: {
        id: crypto.randomUUID(),
        startDatetime,
        endDatetime,
        interval,
      },
      /**
       * NOTE (Max, 2024-09-11): By default, we compare with the previous period, hence
       * the (- interval) for startDatetime & endDatetime
       */
      compareAtRanges: [
        {
          id: crypto.randomUUID(),
          startDatetime: startDatetime - interval,
          endDatetime: endDatetime - interval,
          interval,
        },
      ],
    };

    setCurrentPageNumber(1);
    setCurrentQuery((prevQuery) => ({
      ...prevQuery,
      ranges: updatedRanges,
      offset: 0,
    }));
  };

  /**
   * NOTE (Max, 2024-09-05): If the value is empty, it means the user doens't
   * want to apply any filter, so we apply the default filtes (e.g. /checkouts/ etc..)
   */
  const handleFilterChange = (filterName: FilterName, value: string) => {
    setCurrentPageNumber(1);
    setCurrentQuery({
      ...currentQuery,
      filters: {
        ...currentQuery.filters,
        [filterName]: value
          ? [
              {
                // NOTE (Max, 2024-09-05): For now we're hard-coding to CONTAINS - in the future
                // we'll need some more complex logic to account for other operators "OR" / "AND"
                // "NOT" ...
                operator: ConditionOperatorEnum.CONTAINS,
                value,
              },
            ]
          : DEFAULT_FILTERS[filterName],
      },
      offset: 0,
    });
  };

  const [searchValue, setSearchValue] = React.useState<string>("");

  const searchInputProps = useOverridableInput({
    value: searchValue,
    onValueChange: (value: string) => {
      handleFilterChange("urlPath", value);
      setSearchValue(value);
    },
  });

  const resetAllFilters = () => {
    setCurrentQuery({
      ...DEFAULT_QUERY,
      offset: 0,
      urlHosts: shopifyURLOptions.map((option) => option.value),
    });
    setCurrentPageNumber(1);
  };

  const DisplayTableContent = () => {
    if (isLoading || !totalRowsCount || !mainRangeResults) {
      return (
        // TODO (Max, 2024-09-11): Placeholder for loading states, which will be added in
        // https://linear.app/replo/issue/REPL-13561/
        <></>
      );
    } else if (totalRowsCount === 0) {
      return (
        <TableRow className="border-b-0">
          <TableCell colSpan={TABLE_METRICS.length} className="h-[300px]">
            <div className="flex justify-center items-end h-full w-full">
              <NoData resetAll={resetAllFilters} />
            </div>
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableBody>
          <AllMetricsRows
            mainRangeResults={mainRangeResults}
            compareAtRangeResults={compareAtRangeResults}
          />
        </TableBody>
      );
    }
  };

  return (
    <div className="relative min-h-screen min-w-screen">
      <div className="flex w-full flex-col space-y-6">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-2">
            <Combobox
              options={extendedShopifyURLOptions}
              value={
                isEqual(
                  currentQuery.urlHosts,
                  shopifyURLOptions.map((option) => option.value),
                ) && shopifyURLOptions.length > 1
                  ? DEFAULT_URL_HOST
                  : currentQuery.urlHosts[0]
              }
              onChange={handleUrlHostChange}
              isSearchable={false}
              startEnhancer={() => <BsGlobe2 className="h-4 w-4" />}
              endEnhancer={() => <BsCaretDownFill className="h-2 w-2" />}
            />
            <Combobox
              options={TIMEFRAME_COMBOBOX_OPTIONS}
              value={selectedTimeRange}
              onChange={(selectedTimeRange) =>
                handleTimeRangeChange(
                  selectedTimeRange as DefaultTimeframeOption | DateRange,
                )
              }
              isSearchable={false}
              startEnhancer={() => <BsCalendar3 className="h-4 w-4" />}
              endEnhancer={() => <BsCaretDownFill className="h-2 w-2" />}
              open={openTimeRangePicker}
              onOpenChange={setOpenTimeRangePicker}
              separator={{
                value: TIMEFRAME_COMBOBOX_OPTIONS.find(
                  (option) => option.value === selectedTimeRange,
                )
                  ? DEFAULT_CUSTOM_DATE_RANGE_COMBOBOX_OPTION_VALUE
                  : selectedTimeRange,
                label: "Custom date range",
                component: () => (
                  <DatePickerWithRange
                    initialDateRange={{
                      from: new Date(mainRangeQuery.startDatetime),
                      to: new Date(mainRangeQuery.endDatetime),
                    }}
                    disabledDates={{ after: new Date() } as DateAfter}
                    updateAction={(dateRange) => {
                      handleTimeRangeChange(dateRange);
                      setOpenTimeRangePicker(false);
                    }}
                    popoverTriggerComponent={() => (
                      <span className="text-default">Custom date range</span>
                    )}
                    closeAction={() => setOpenTimeRangePicker(false)}
                    rangeExtendsOnSelect={false}
                  />
                ),
              }}
            />
          </div>
          <div className="min-w-[300px]">
            <Input
              placeholder="Search path..."
              size="base"
              startEnhancer={() => (
                <BsSearch className="text-xs text-slate-400" />
              )}
              {...searchInputProps}
            />
          </div>
        </div>
        <div className="flex gap-5 flex-col min-[950px]:flex-row">
          <Table>
            <TableHeader>
              <TableRow className="bg-slate-100 h-[36px]">
                <TableHead className="max-w-[300px] px-4">
                  <div className="flex items-center gap-x-1.5 max-w-[300px] min-w-[300px]">
                    <span className="font-medium text-xs text-default">
                      Page Name
                    </span>
                  </div>
                </TableHead>
                {TABLE_METRICS.map((metric) => (
                  <TableHead key={metric.key} className="max-h-[36px] px-4">
                    <div
                      className={classNames(
                        "flex items-center gap-x-1.5 max-w-[190px] min-w-[150px]",
                      )}
                    >
                      <span className="font-medium text-xs text-default">
                        {metric.label}
                      </span>
                      <Button variant="no-style" className="flex items-center">
                        <SortButton
                          sortMetric={metric.key}
                          sortOrder={currentQuery.order}
                          isActiveMetric={
                            currentQuery.sortMetric === metric.key
                          }
                          onChange={() => handleMetricSortClick(metric.key)}
                        />
                      </Button>
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            {DisplayTableContent()}
          </Table>
        </div>
      </div>
      {totalRowsCount && (
        <div className="grid grid-cols-2 mt-5 mb-4">
          <div className="col-start-2">
            <PaginationMetrics
              currentPageNumber={currentPageNumber}
              totalRowsCount={totalRowsCount}
              resultsPerPage={resultsPerPage}
              handleOnPageNumberChange={handleOnPageNumberChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AllMetricsTable;

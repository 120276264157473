// Solved with https://github.com/agraboso/redux-api-middleware/commit/a8a0d9d0e6d9881dcd5726a563da83b7f55c732e
import {
  addAuthorizationHeaders,
  addPublisherStagingHeadersIfNeeded,
} from "@editor/infra/auth";
import type { Middleware } from "redux";
import { isRSAA, RSAA } from "redux-api-middleware";
import { getPublisherUrl } from "replo-runtime/shared/config";

const envMiddleware: Middleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    if (!isRSAA(action)) {
      return next(action);
    }

    const rsaa = action[RSAA];

    /* Sometimes RSAA.endpoint is a function */
    if (typeof rsaa.endpoint === "function") {
      rsaa.endpoint = rsaa.endpoint(getState());
    }

    const isSignup = rsaa.endpoint === "api/v1/user" && rsaa.method === "POST";

    // Don't send auth headers in signup, because the view expects a valid auth
    // token, and if there _is_ an auth token but it's invalid then it won't be
    // accepted. This is so that saved auth tokens which are no longer valid don't
    // get sent if the user signs into a different account
    if (!isSignup) {
      rsaa.headers = addAuthorizationHeaders(rsaa.headers);
    }
    rsaa.headers = addPublisherStagingHeadersIfNeeded(rsaa.headers);

    rsaa.endpoint = `${getPublisherUrl()}/${rsaa.endpoint}`;

    return next(action);
  };

export default envMiddleware;

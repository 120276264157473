import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _oneTimePurchaseText: {
      name: "One-time Purchase Text",
      type: "string",
      defaultValue: "One-time purchase",
      description:
        "If set, this text will be displayed when the user has not selected a subscription selling plan (when one-time purchase is selected).",
    },
    _includeOneTimePurchase: {
      type: "boolean",
      name: "Include One-time Purchase",
      description:
        "If enabled, an option to buy a one-time purchase of the product will be included in the dynamic list.",
      defaultValue: true,
    },
  };
}

export const styleElements = {
  root: {
    defaultStyles: {
      cursor: "pointer",
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderRightWidth: 0,
      borderLeftWidth: 0,
      // Note (Noah, 2023-03-12): Other select dropdowns in the runtime apply this appearance style
      // conditionally based on a custom prop, but it's almost always not what you want, and we've
      // changed default templates for those other dropdowns to always have the custom prop off since
      // users were complaining about it not showing up on safari, mobile, etc. Our default template
      // just includes an overlaid arrow icon, which is simpler.
      appearance: "none",
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: true,
    canvasIndicatorDragDirections: [],
    ancestorAllow: {
      ancestorTypes: ["product"],
      message:
        "Selling Plan Dropdown components may only be placed inside a Product box.",
    },
    ancestorDisallowList: [
      {
        ancestorTypes: ["marquee"],
        message:
          "Selling Plan Dropdown components cannot be nested inside tickers.",
      },
    ],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;

export enum DynamicDataTargetType {
  // TODO (Noah, 2021-07-28): This can't just be "product" because we do startsWith
  // in many places and it conflicts with productVariant
  PRODUCT = "productOnly",
  PRODUCT_VARIANT = "productVariant",
  SELLING_PLAN = "sellingPlan",
  TEXT = "text",
  TEXT_COLOR = "text/color",
  URL = "text/url",
  ANY_LIST = "list",
  INTEGER = "text/integer",
}

import * as React from "react";
import { noop } from "replo-utils/lib/misc";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { DataTable, ReploElementType } from "schemas/element";
import type { UploadedFont } from "schemas/project";
import type { ReploSymbol } from "schemas/symbol";

import type { ComponentInventory } from "../store/componentInventory";
import type {
  RENDER_ENV_EDITOR,
  RENDER_ENV_PAGE_PREVIEW,
  RENDER_ENV_PAGE_PUBLISHED,
  RuntimeRenderEnvironment,
} from "./render-environment";
import type {
  OkendoNamespace,
  ProductMetafieldMapping,
  ProductRef,
  RenderPreviewEnvironment as RuntimePreviewEnvironment,
  RuntimeFeatureFlags,
  SharedStatePayload,
  StoreProduct as ShopifyStoreProduct,
  Swatch,
  VariantMetafieldMapping,
} from "./types";
import type { GlobalWindow } from "./Window";

// NOTE (Chance 2024-03-13): Context objects that are not relevant outside of
// the editor (like editor history) still need to be accessible in other
// environments. Those can get a default value so we don't necessarily need to
// include a provider in those environments.

// #region --- Canvas-level context objects ---
//
// NOTE (Chance 2024-03-13): This context is provided at the top level of the
// canvas (or in the case of publishing, the top level of the page). They are
// split up because runtime components shouldn't need to subscribe to every
// potential value change. This will essentially replace most things that are
// being passed via render extras and used as render dependencies.

// #region ReploEditorCanvasContext
export interface ReploEditorCanvasContextValue {
  editorCanvasYOffset: number;
  editorCanvasScale: number;
}
export const ReploEditorCanvasContext =
  createContext<ReploEditorCanvasContextValue>("ReploEditorCanvas", {
    editorCanvasYOffset: 0,
    editorCanvasScale: 1,
  });
// #endregion

// #region ReploEditorActiveCanvasContext
export interface ReploEditorActiveCanvasContextValue {
  activeCanvas: EditorCanvas;
}
export const ReploEditorActiveCanvasContext =
  createContext<ReploEditorActiveCanvasContextValue>(
    "ReploEditorActiveCanvas",
    { activeCanvas: "desktop" },
  );
// #endregion

// #region ReploElementContext
export interface ReploElementContextValue {
  elementType: ReploElementType;
  useSectionSettings: boolean | undefined;
}
export const ReploElementContext =
  createContext<ReploElementContextValue>("ReploElement");
// #endregion

// #region RenderEnvironmentContext
interface _RenderEnvironmentContextValue {
  renderEnvironment: RuntimeRenderEnvironment;
  previewEnvironment: RuntimePreviewEnvironment | null;
  isEditorApp: boolean;
  isPublishing: boolean;
  isPublishedPage: boolean;
  isElementPreview: boolean;
}

interface RenderEnvironmentContextPagePreview
  extends _RenderEnvironmentContextValue {
  renderEnvironment: typeof RENDER_ENV_PAGE_PREVIEW;
  isEditorApp: false;
  isPublishedPage: false;
  isElementPreview: true;
  isPublishing: boolean;
}

interface RenderEnvironmentPagePublished
  extends _RenderEnvironmentContextValue {
  renderEnvironment: typeof RENDER_ENV_PAGE_PUBLISHED;
  isEditorApp: false;
  isPublishedPage: true;
  isElementPreview: false;
  isPublishing: boolean;
}

export interface RenderEnvironmentContextEditor
  extends _RenderEnvironmentContextValue {
  renderEnvironment: typeof RENDER_ENV_EDITOR;
  isEditorApp: true;
  isPublishedPage: false;
  isElementPreview: false;
  isPublishing: false;
}

export type RenderEnvironmentContextValue =
  | RenderEnvironmentContextPagePreview
  | RenderEnvironmentPagePublished
  | RenderEnvironmentContextEditor;

export const RenderEnvironmentContext =
  createContext<RenderEnvironmentContextValue>("RenderEnvironment");
// #endregion

// #region FeatureFlagsContext
export interface FeatureFlagsContextValue {
  featureFlags: RuntimeFeatureFlags;
}
export const FeatureFlagsContext =
  createContext<FeatureFlagsContextValue>("FeatureFlags");
// #endregion

// #region SelectedRevisionContext
export interface SelectedRevisionContextValue {
  selectedRevisionId: string | null;
}
export const SelectedRevisionContext =
  createContext<SelectedRevisionContextValue>("SelectedRevision", {
    selectedRevisionId: null,
  });
// #endregion

// #region CanvasElementsLoadingStateContext
export interface CanvasElementsLoadingStateContextValue {
  state: "loading" | "loaded" | "error";
}
export const CanvasElementsLoadingStateContext =
  createContext<CanvasElementsLoadingStateContextValue>(
    "CanvasElementsLoadingState",
    { state: "loaded" },
  );

// #endregion

// #region DraftElementContext
export interface DraftElementContextValue {
  draftElementId: string | null;
  draftElementComponentId: string | null;
  draftRepeatedIndex: string | null;
  draftSymbolInstanceId: string | null;
  draftSymbolId: string | null;
}
export const DraftElementContext = createContext<DraftElementContextValue>(
  "DraftElement",
  {
    draftElementId: null,
    draftElementComponentId: null,
    draftRepeatedIndex: null,
    draftSymbolInstanceId: null,
    draftSymbolId: null,
  },
);
// #endregion

// NOTE (Matt 2024-04-18): While this is called the DynamicDataStore,
// this is more of a placeholder for now. We need a way to access
// sectionSettings via context, and it makes sense for this to exist
// in the store. However, because of the current GoPuff integration
// we need to keep the rest of what we consider to be "dynamic data"
// (ie products, productMetafields, variantMetafields) on the window
// object. This store will later be built out to include those attributes.
// #region DynamicDataStoreContext
export interface DynamicDataStore {
  sectionSettings?: {
    [key: string]: string;
  };
}
export interface DynamicDataStoreContextValue {
  store: DynamicDataStore;
  setStore?:
    | React.Dispatch<React.SetStateAction<DynamicDataStore>>
    | ((newStore: DynamicDataStore) => void);
}
export const DynamicDataStoreContext =
  createContext<DynamicDataStoreContextValue>("DynamicDataStore");
// #endregion

// #region ShopifyStoreContextValue
export interface ShopifyStoreContextValue {
  storeId: string | undefined;
  storeUrl: string | undefined;
  templateProduct: ShopifyStoreProduct | null;
  products: ShopifyStoreProduct[];
  fakeProducts: ShopifyStoreProduct[] | null;
  productMetafieldValues: ProductMetafieldMapping;
  variantMetafieldValues: VariantMetafieldMapping;
  activeLanguage: string;
  activeCurrency: string;
  activeShopifyUrlRoot: string;
  moneyFormat: string | null;
}
export const ShopifyStoreContext =
  createContext<ShopifyStoreContextValue>("ShopifyStore");
// #endregion

// #region RenderedLiquidContext
export interface RenderedLiquidContextValue {
  cache: Record<string, string>;
  requestsInProgress: Record<string, boolean>;
  requestRenderLiquid: (liquidSource: string) => void;
}
export const RenderedLiquidContext = createContext<RenderedLiquidContextValue>(
  "RenderedLiquid",
  { cache: {}, requestsInProgress: {}, requestRenderLiquid: noop },
);
// #endregion

// #region SwatchesContext
export interface SwatchesContextValue {
  swatches: Swatch[];
}
export const SwatchesContext = createContext<SwatchesContextValue>("Swatches");
// #endregion

// #region DataTablesContext
export interface DataTablesContextValue {
  mapping: Record<string, DataTable>;
}
export const DataTablesContext =
  createContext<DataTablesContextValue>("DataTables");
// #endregion

// #region TemplateEditorProductContext
export interface TemplateEditorProductContextValue {
  templateEditorProduct: ProductRef | null;
}
export const TemplateEditorProductContext =
  createContext<TemplateEditorProductContextValue>("TemplateEditorProduct", {
    templateEditorProduct: null,
  });
// #endregion

// #region ReploSymbolsContext
export interface ReploSymbolsContextValue {
  symbols: ReploSymbol[];
}
export const ReploSymbolsContext =
  createContext<ReploSymbolsContextValue>("ReploSymbols");
// #endregion

// #region EditorSelectionContext
export interface EditorSelectionContextValue {
  selectedIds: string[];
  lastSelectedId: string | null;
}
export const EditorSelectionContext =
  createContext<EditorSelectionContextValue>("EditorSelection", {
    selectedIds: [],
    lastSelectedId: null,
  });
// #endregion

// #region ComponentErrorContext
export interface ComponentErrorContextValue {
  onComponentError: (
    componentId: string,
    error: unknown,
    info: React.ErrorInfo,
  ) => void;
}
export const ComponentErrorContext = createContext<ComponentErrorContextValue>(
  "ComponentError",
  { onComponentError: noop },
);
// #endregion

// #region ComponentInventoryContext
export interface ComponentInventoryContextValue {
  componentInventory: ComponentInventory;
}
export const ComponentInventoryContext =
  createContext<ComponentInventoryContextValue>("ComponentInventory");
// #endregion

// #region ComponentUpdateContext
export interface ComponentUpdateContextValue {
  isContentEditing: boolean;
  onSubmitContentEditableTextUpdate: (
    componentId: string,
    htmlContent: string,
  ) => void;
}
export const ComponentUpdateContext =
  createContext<ComponentUpdateContextValue>("ComponentUpdate", {
    onSubmitContentEditableTextUpdate: noop,
    isContentEditing: false,
  });
// #endregion

// #region CustomFontsContext
export interface CustomFontsContextValue {
  uploadedFonts: UploadedFont[];
}
export const CustomFontsContext = createContext<CustomFontsContextValue>(
  "CustomFonts",
  { uploadedFonts: [] },
);
// #endregion

// #region SharedStateContext
export interface SharedStateContextValue {
  sharedState: any;
  setSharedState: (payload: SharedStatePayload) => void;
  setEditorReadableState: (payload: SharedStatePayload) => void;
}
export const SharedStateContext = createContext<SharedStateContextValue>(
  "SharedState",
  {
    sharedState: undefined,
    setSharedState: noop,
    setEditorReadableState: noop,
  },
);
// #endregion

// #region ExtraContext
export interface ExtraContextValue {
  overrideProductLiquidOff: boolean;
  okendoNamespace: OkendoNamespace | null;
  runtimeVersion: string | null;
}
export const ExtraContext = createContext<ExtraContextValue>("Extra");
// #endregion

// #region EditorMediaUploadContext
export interface EditorMediaUploadContextValue {
  editorMediaUploadingComponentIds: Array<string>;
}
export const EditorMediaUploadContext =
  createContext<EditorMediaUploadContextValue>("EditorMediaUpload");
// #endregion

// #region GlobalWindowContext
export type GlobalWindowContextValue = GlobalWindow;
export const GlobalWindowContext =
  createContext<GlobalWindowContextValue | null>("GlobalWindow");
// #endregion

// #region EditorCanvasContext
export type EditorCanvasContextValue = EditorCanvas;
export const EditorCanvasContext =
  createContext<EditorCanvasContextValue | null>("EditorCanvas");
// #endregion

// #region RuntimeHooksContext
export type RuntimeHooksContextValue = {
  useEditorOverrideActiveVariantId: (componentId: string) => string | null;
  useEditorOverrideTextValue: (componentId: string) => string | null;
  useIsEditorEditModeRenderEnvironment: () => boolean;
  useIsLabelledByOtherComponent: (componentId: string) => boolean;
};
export const RuntimeHooksContext =
  createContext<RuntimeHooksContextValue | null>("RuntimeHooks");
// #endregion

export type RuntimeContext =
  | typeof CanvasElementsLoadingStateContext
  | typeof ComponentErrorContext
  | typeof ComponentInventoryContext
  | typeof ComponentUpdateContext
  | typeof CustomFontsContext
  | typeof DataTablesContext
  | typeof DraftElementContext
  | typeof DynamicDataStoreContext
  | typeof EditorSelectionContext
  | typeof ExtraContext
  | typeof FeatureFlagsContext
  | typeof RenderedLiquidContext
  | typeof RenderEnvironmentContext
  | typeof ReploEditorCanvasContext
  | typeof ReploEditorActiveCanvasContext
  | typeof ReploElementContext
  | typeof ReploSymbolsContext
  | typeof SelectedRevisionContext
  | typeof SharedStateContext
  | typeof ShopifyStoreContext
  | typeof SwatchesContext
  | typeof TemplateEditorProductContext
  | typeof EditorMediaUploadContext
  | typeof GlobalWindowContext
  | typeof EditorCanvasContext
  | typeof RuntimeHooksContext;

export type RuntimeContextValue =
  | CanvasElementsLoadingStateContextValue
  | ComponentErrorContextValue
  | ComponentInventoryContextValue
  | ComponentUpdateContextValue
  | CustomFontsContextValue
  | DataTablesContextValue
  | DraftElementContextValue
  | DynamicDataStoreContextValue
  | EditorSelectionContextValue
  | ExtraContextValue
  | FeatureFlagsContextValue
  | RenderedLiquidContextValue
  | RenderEnvironmentContextValue
  | ReploEditorCanvasContextValue
  | ReploEditorActiveCanvasContextValue
  | ReploElementContextValue
  | ReploSymbolsContextValue
  | SelectedRevisionContextValue
  | SharedStateContextValue
  | ShopifyStoreContextValue
  | SwatchesContextValue
  | TemplateEditorProductContextValue
  | EditorMediaUploadContextValue
  | GlobalWindowContextValue
  | EditorCanvasContextValue
  | RuntimeHooksContextValue;

// #endregion

export function useRuntimeContext<T extends RuntimeContextValue>(
  Context: React.Context<T | null>,
): T;
export function useRuntimeContext<T extends RuntimeContextValue>(
  Context: React.Context<T>,
): T;
export function useRuntimeContext<T extends RuntimeContextValue>(
  Context: React.Context<T | null> | React.Context<T>,
): T {
  const ctx = React.useContext(Context as any) as unknown as T;
  if (ctx === undefined) {
    const displayName = Context.displayName || "RuntimeContext";
    // TODO (Chance 2024-03-13): Switch between dev and prod for customer-facing
    // errors
    throw new Error(
      `[REPLO] use${displayName} must only be called from inside a Replo runtime component. If this error is unexpected, you may have forgotten to wrap the component tree in the context's provider.`,
    );
  }
  return ctx;
}

export function RuntimeContextProvider<T extends RuntimeContext>(props: {
  context: T;
  value?: T extends React.Context<infer V> ? V | null : never;
  allowNull?: boolean;
  children: React.ReactNode;
}) {
  let { context: Context, value, allowNull = false } = props;
  const _value = React.useContext(Context as React.Context<any>);
  value = value ?? _value;
  if (!value && !allowNull) {
    const displayName = Context.displayName || "RuntimeContext";
    // TODO (Chance 2024-03-13): Switch between dev and prod for customer-facing
    // errors
    throw new Error(
      `[REPLO] ${displayName}Provider must have access to a value. A value prop can only be omitted if the provider is rendered below the same provider deeper in the render tree.`,
    );
  }
  return (
    <Context.Provider value={value as any}>{props.children}</Context.Provider>
  );
}

export interface RuntimeContextProvidersProps
  extends RuntimeContextNullableValueMap {
  children: React.ReactNode;
}

export function RuntimeContextProviders({
  children,
  canvasElementsLoadingState,
  componentError,
  componentInventory,
  componentUpdate,
  customFonts,
  dataTables,
  draftElement,
  dynamicDataStore,
  editorSelection,
  extraContext,
  featureFlags,
  renderedLiquid,
  renderEnvironment,
  reploEditorCanvas,
  reploEditorActiveCanvas,
  reploElement,
  reploSymbols,
  selectedRevision,
  sharedState,
  shopifyStore,
  swatches,
  templateEditorProduct,
  editorMediaUpload,
  globalWindow,
  editorCanvas,
  runtimeHooks,
}: RuntimeContextProvidersProps) {
  return (
    <RuntimeContextProvider
      context={CanvasElementsLoadingStateContext}
      value={canvasElementsLoadingState}
    >
      <RuntimeContextProvider
        context={ComponentErrorContext}
        value={componentError}
      >
        <RuntimeContextProvider
          context={ComponentUpdateContext}
          value={componentUpdate}
        >
          <RuntimeContextProvider
            context={ComponentInventoryContext}
            value={componentInventory}
          >
            <RuntimeContextProvider
              context={CustomFontsContext}
              value={customFonts}
            >
              <RuntimeContextProvider
                context={DataTablesContext}
                value={dataTables}
              >
                <RuntimeContextProvider
                  context={DraftElementContext}
                  value={draftElement}
                >
                  <RuntimeContextProvider
                    context={DynamicDataStoreContext}
                    value={dynamicDataStore}
                  >
                    <RuntimeContextProvider
                      context={EditorSelectionContext}
                      value={editorSelection}
                    >
                      <RuntimeContextProvider
                        context={ExtraContext}
                        value={extraContext}
                      >
                        <RuntimeContextProvider
                          context={FeatureFlagsContext}
                          value={featureFlags}
                        >
                          <RuntimeContextProvider
                            context={RenderedLiquidContext}
                            value={renderedLiquid}
                          >
                            <RuntimeContextProvider
                              context={RenderEnvironmentContext}
                              value={renderEnvironment}
                            >
                              <RuntimeContextProvider
                                context={ReploEditorCanvasContext}
                                value={reploEditorCanvas}
                              >
                                <RuntimeContextProvider
                                  context={ReploEditorActiveCanvasContext}
                                  value={reploEditorActiveCanvas}
                                >
                                  <RuntimeContextProvider
                                    context={ReploElementContext}
                                    value={reploElement}
                                  >
                                    <RuntimeContextProvider
                                      context={ReploSymbolsContext}
                                      value={reploSymbols}
                                    >
                                      <RuntimeContextProvider
                                        context={SelectedRevisionContext}
                                        value={selectedRevision}
                                      >
                                        <RuntimeContextProvider
                                          context={SharedStateContext}
                                          value={sharedState}
                                        >
                                          <RuntimeContextProvider
                                            context={ShopifyStoreContext}
                                            value={shopifyStore}
                                          >
                                            <RuntimeContextProvider
                                              context={SwatchesContext}
                                              value={swatches}
                                            >
                                              <RuntimeContextProvider
                                                context={
                                                  TemplateEditorProductContext
                                                }
                                                value={templateEditorProduct}
                                              >
                                                <RuntimeContextProvider
                                                  context={
                                                    EditorMediaUploadContext
                                                  }
                                                  value={editorMediaUpload}
                                                >
                                                  <RuntimeContextProvider
                                                    context={
                                                      GlobalWindowContext
                                                    }
                                                    allowNull
                                                    value={globalWindow}
                                                  >
                                                    <RuntimeContextProvider
                                                      context={
                                                        EditorCanvasContext
                                                      }
                                                      allowNull
                                                      value={editorCanvas}
                                                    >
                                                      <RuntimeContextProvider
                                                        context={
                                                          RuntimeHooksContext
                                                        }
                                                        value={runtimeHooks}
                                                      >
                                                        {children}
                                                      </RuntimeContextProvider>
                                                    </RuntimeContextProvider>
                                                  </RuntimeContextProvider>
                                                </RuntimeContextProvider>
                                              </RuntimeContextProvider>
                                            </RuntimeContextProvider>
                                          </RuntimeContextProvider>
                                        </RuntimeContextProvider>
                                      </RuntimeContextProvider>
                                    </RuntimeContextProvider>
                                  </RuntimeContextProvider>
                                </RuntimeContextProvider>
                              </RuntimeContextProvider>
                            </RuntimeContextProvider>
                          </RuntimeContextProvider>
                        </RuntimeContextProvider>
                      </RuntimeContextProvider>
                    </RuntimeContextProvider>
                  </RuntimeContextProvider>
                </RuntimeContextProvider>
              </RuntimeContextProvider>
            </RuntimeContextProvider>
          </RuntimeContextProvider>
        </RuntimeContextProvider>
      </RuntimeContextProvider>
    </RuntimeContextProvider>
  );
}

// #endregion

function createContext<T>(displayName: string, value: T): React.Context<T>;
function createContext<T>(
  displayName: string,
  value?: T | null,
): React.Context<T | null>;

function createContext<T>(displayName: string, value?: T | null) {
  const Context = React.createContext(value ?? null);
  Context.displayName = `Runtime${displayName}Context`;
  return Context;
}

interface RuntimeContextMap {
  canvasElementsLoadingState: typeof CanvasElementsLoadingStateContext;
  componentError: typeof ComponentErrorContext;
  componentInventory: typeof ComponentInventoryContext;
  componentUpdate: typeof ComponentUpdateContext;
  customFonts: typeof CustomFontsContext;
  dataTables: typeof DataTablesContext;
  draftElement: typeof DraftElementContext;
  dynamicDataStore: typeof DynamicDataStoreContext;
  editorSelection: typeof EditorSelectionContext;
  extraContext: typeof ExtraContext;
  featureFlags: typeof FeatureFlagsContext;
  renderedLiquid: typeof RenderedLiquidContext;
  renderEnvironment: typeof RenderEnvironmentContext;
  reploEditorCanvas: typeof ReploEditorCanvasContext;
  reploEditorActiveCanvas: typeof ReploEditorActiveCanvasContext;
  reploElement: typeof ReploElementContext;
  reploSymbols: typeof ReploSymbolsContext;
  selectedRevision: typeof SelectedRevisionContext;
  sharedState: typeof SharedStateContext;
  shopifyStore: typeof ShopifyStoreContext;
  swatches: typeof SwatchesContext;
  templateEditorProduct: typeof TemplateEditorProductContext;
  editorMediaUpload: typeof EditorMediaUploadContext;
  globalWindow: typeof GlobalWindowContext;
  editorCanvas: typeof EditorCanvasContext;
  runtimeHooks: typeof RuntimeHooksContext;
}

export type RuntimeContextValueMap = {
  [Key in keyof RuntimeContextMap]: RuntimeContextMap[Key] extends React.Context<
    infer V
  >
    ? // TODO (Noah, 2024-07-03, REPL-12467): Simplify the types to make null make sense here
      Key extends "globalWindow" | "editorCanvas"
      ? V
      : NonNullable<V>
    : never;
};

// NOTE (Chance 2024-03-29): This type maps contexts that have a default value
// to a nullable version of the value. If the context value itself might be
// null, it's non-nullable here. This is useful because in some environments,
// certain context objects aren't necessary and we can omit them from the
// provider tree, or render a provider without a value. In those cases the
// default value means that using said context will never result in a runtime
// error, so we can use this type to allow passing `null` in cases where the
// context is not strictly needed.
export type RuntimeContextNullableValueMap = {
  [Key in keyof RuntimeContextMap]: RuntimeContextMap[Key] extends React.Context<
    infer V | null
  >
    ? V
    : RuntimeContextMap[Key] extends React.Context<infer V>
      ? V | null
      : never;
};

import { useCookies } from "react-cookie";

const LATEST_PROJECT_COOKIE_KEY = "replo_latest_project_id";

const useGetProjectUtils = () => {
  const [cookies, setCookie] = useCookies([LATEST_PROJECT_COOKIE_KEY]);

  const getLatestProjectIdFromCookies = () => {
    return cookies.replo_latest_project_id;
  };

  const setLatestProjectIdOnCookies = (id: string) => {
    return setCookie(LATEST_PROJECT_COOKIE_KEY, id, {
      path: "/",
      maxAge: 60 * 10, // 10 minutes
    });
  };

  return { getLatestProjectIdFromCookies, setLatestProjectIdOnCookies };
};

export default useGetProjectUtils;

import { z } from "zod";

export const variationSchema = z.object({
  id: z.string().uuid(),
  slug: z.string(),
  target: z.string().url(),
  allocationPercent: z.number().int().min(0).max(100),
  isWinner: z.boolean().nullish(),
});

export type Variation = z.infer<typeof variationSchema>;

import Badge from "@editor/components/common/designSystem/Badge";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Selectable from "@editor/components/common/designSystem/Selectable";
import Slider from "@editor/components/common/designSystem/Slider";
import Tooltip from "@editor/components/common/designSystem/Tooltip";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import {
  selectCursor,
  selectDraftComponentId,
  selectDraftComponentType,
  selectOpacity,
  selectZoom,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { DraggingTypes } from "@editor/utils/editor";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import ModifierGroup from "@editorExtras/ModifierGroup";
import LengthInputModifier from "@editorModifiers/LengthInputModifier";
import { isEmptyOrCssDefault } from "@editorModifiers/utils";
import * as React from "react";
import type { IconType } from "react-icons";
import { BiPlus } from "react-icons/bi";
import {
  BsArrows,
  BsArrowsVertical,
  BsDash,
  BsHandIndex,
  BsInputCursorText,
  BsXCircle,
} from "react-icons/bs";
import { FaMousePointer } from "react-icons/fa";
import { RiDropLine, RiZoomInLine } from "react-icons/ri";

const EffectsModifier = () => {
  const opacity = useEditorSelector(selectOpacity);
  const zoom = useEditorSelector(selectZoom);
  const cursor = useEditorSelector(selectCursor);

  return (
    <ModifierGroup
      title="Effects"
      isDefaultOpen={
        !isEmptyOrCssDefault(
          opacity ? String(opacity) : null,
          styleAttributeToEditorData.opacity.defaultValue,
        ) ||
        !isEmptyOrCssDefault(
          zoom ? String(zoom) : null,
          styleAttributeToEditorData.__zoom.defaultValue,
        ) ||
        !isEmptyOrCssDefault(cursor, "default") // NOTE (Sebas, 2022-08-01): The "default" value is hardcoded because the initial value is "auto".
      }
    >
      <div className="flex flex-col gap-2">
        <OpacityControl />
        <ZoomControl />
        <CursorControl />
      </div>
    </ModifierGroup>
  );
};

const OpacityControl: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const opacity = useEditorSelector(selectOpacity);
  const draftComponentId = useEditorSelector(selectDraftComponentId);

  const onChange = (newValue: string) => {
    applyComponentAction({
      componentId: draftComponentId,
      type: "setStyles",
      value: { opacity: newValue },
    });
  };

  const opacityDefaultValue = `${
    Number.parseInt(styleAttributeToEditorData.opacity.defaultValue) * 100
  }%`;

  return (
    <LabeledControl label="Opacity" size="sm">
      <div className="flex w-full items-center gap-2">
        <div className="w-1/2">
          <LengthInputModifier
            startEnhancer={() => (
              <Tooltip inheritCursor content="Adjust Opacity" triggerAsChild>
                <span tabIndex={0}>
                  <RiDropLine size={12} className="text-slate-400" />
                </span>
              </Tooltip>
            )}
            draggingType={DraggingTypes.Vertical}
            menuOptions={["0%", "50%", "100%"].map((v) => ({
              label: v,
              value: v,
            }))}
            placeholder={opacityDefaultValue}
            anchorValue="50%"
            resetValue={opacityDefaultValue}
            metrics={["%"]}
            field="style.opacity"
            onChange={onChange}
            minValues={{ "%": 0 }}
            maxValues={{ "%": 100 }}
            minDragValues={{ "%": 0 }}
            maxDragValues={{ "%": 100 }}
            previewProperty="opacity"
          />
        </div>
        <Slider
          value={
            opacity ? Number.parseInt(String(opacity).replace("%", "")) : 100
          }
          debounce
          minimum={0}
          maximum={100}
          onChange={(v) => {
            onChange(`${v}%`);
          }}
          className="w-1/2"
        />
      </div>
    </LabeledControl>
  );
};

const ZoomControl: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const draftComponentType = useEditorSelector(selectDraftComponentType);

  if (draftComponentType !== "image") {
    return null;
  }
  const zoomDefaultValue = styleAttributeToEditorData.__zoom.defaultValue;
  return (
    <LabeledControl label="Zoom" size="sm">
      <div className="flex w-full">
        <LengthInputModifier
          startEnhancer={() => (
            <Tooltip inheritCursor content="Adjust Zoom" triggerAsChild>
              <span tabIndex={0}>
                <RiZoomInLine size={12} className="text-slate-400" />
              </span>
            </Tooltip>
          )}
          draggingType={DraggingTypes.Vertical}
          placeholder={zoomDefaultValue}
          anchorValue={zoomDefaultValue}
          resetValue={zoomDefaultValue}
          metrics={["%"]}
          field="style.__zoom"
          onChange={(newValue) => {
            applyComponentAction({
              type: "setStyles",
              value: { __zoom: newValue },
            });
          }}
          className="w-full"
          menuOptions={["Reset", "100%", "110%", "120%"].map((v) => ({
            label: v,
            value: v === "Reset" ? zoomDefaultValue : v,
          }))}
          minValues={{ "%": 1 }}
          minDragValues={{ "%": 1 }}
        />
      </div>
    </LabeledControl>
  );
};

const CursorLabel: React.FC<{
  text: string;
  icon: IconType;
}> = ({ text, icon }) => {
  const IconComponent = icon;

  return (
    <span className="flex items-center px-2 text-xs">
      <Badge
        isFilled
        backgroundColor="bg-blue-600"
        className="mr-2 h-[18px] w-[18px]"
      >
        <IconComponent size={12} className="text-white" />
      </Badge>
      {text}
    </span>
  );
};

const cursorSelectableItems = [
  {
    label: <CursorLabel text="Default" icon={FaMousePointer} />,
    value: "default",
    isDisabled: false,
  },
  {
    label: <CursorLabel text="Pointer" icon={BsHandIndex} />,
    value: "pointer",
    isDisabled: false,
  },
  {
    label: <CursorLabel text="Crosshair" icon={BiPlus} />,
    value: "crosshair",
    isDisabled: false,
  },
  {
    label: <CursorLabel text="Not Allowed" icon={BsXCircle} />,
    value: "not-allowed",
    isDisabled: false,
  },
  {
    label: <CursorLabel text="Input" icon={BsInputCursorText} />,
    value: "text",
    isDisabled: false,
  },
  {
    label: <CursorLabel text="Arrows" icon={BsArrows} />,
    value: "ew-resize",
    isDisabled: false,
  },
  {
    label: <CursorLabel text="Vertical Arrows" icon={BsArrowsVertical} />,
    value: "ns-resize",
    isDisabled: false,
  },
  {
    label: <CursorLabel text="No Cursor" icon={BsDash} />,
    value: "none",
    isDisabled: false,
  },
];

const CursorControl: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const value = useEditorSelector(selectCursor);

  return (
    <LabeledControl label="Cursor" size="sm">
      <div className="flex w-full">
        <Selectable
          value={value && value !== "auto" ? value : "default"}
          options={cursorSelectableItems}
          onSelect={(newValue) => {
            applyComponentAction({
              type: "setStyles",
              value: { cursor: newValue },
            });
          }}
          className="w-full"
        />
      </div>
    </LabeledControl>
  );
};

export default EffectsModifier;

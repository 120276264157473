import { Range, Root, Thumb, Track } from "@radix-ui/react-slider";
import classNames from "classnames";
import clamp from "lodash-es/clamp";
import debounce from "lodash-es/debounce";
import * as React from "react";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";

type SliderProps = {
  value: number;
  minimum: number;
  maximum: number;
  step?: number;
  onChange?(value: number): void;
  onConfirm?(value: number): void;
  isDisabled?: boolean;
  debounce?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

const Slider = ({
  maximum,
  minimum,
  value: originalValue = 0,
  className,
  debounce: hasDebounce = false,
  isDisabled = false,
  onChange,
  onConfirm,
  step = 1,
  style,
}: SliderProps) => {
  const debouncedOnChange = React.useMemo(() => {
    return debounce((value: number) => onChange?.(value), 300);
  }, [onChange]);
  const sliderValueRef = React.useRef(0);
  const onValueChange = hasDebounce ? debouncedOnChange : onChange;

  function _handleOnConfirm(value: string) {
    const numericValue = Number.parseInt(value, 10);
    if (!isDisabled && sliderValueRef.current !== numericValue) {
      onConfirm?.(numericValue);
    }
    sliderValueRef.current = numericValue;
  }

  const [value, setValue] = useOverridableState(
    clamp(originalValue, minimum, maximum),
    (value) => {
      onValueChange?.(value);
    },
  );

  return (
    <Root
      value={[Number.isNaN(value) ? 0 : value]}
      onValueChange={(value) => setValue(value[0]!)}
      min={minimum}
      max={maximum}
      step={step}
      disabled={isDisabled}
      asChild
    >
      <div
        className={classNames(
          "relative flex touch-none items-center",
          { "w-44": !className && !style?.width },
          className,
        )}
        style={style}
      >
        <Track asChild>
          <div
            className={classNames(
              "relative h-[2px] flex-grow rounded-full",
              !isDisabled ? "bg-gray-200" : "bg-gray-100",
            )}
          >
            <Range asChild>
              <div
                className={classNames(
                  "absolute h-full rounded-full",
                  !isDisabled ? "bg-blue-600" : "bg-gray-100",
                )}
              />
            </Range>
          </div>
        </Track>
        <Thumb
          className={classNames(
            "block rounded-full p-2 shadow border-0.5 border-slate-100",
            !isDisabled ? "bg-white" : "bg-gray-200",
          )}
          onClick={({ currentTarget: { ariaValueNow } }) => {
            if (ariaValueNow) {
              _handleOnConfirm(ariaValueNow);
            }
          }}
        />
      </div>
    </Root>
  );
};

export default Slider;

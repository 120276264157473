import Modal from "@common/designSystem/Modal";
import Button from "@editor/components/common/designSystem/Button";
import Input from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Selectable from "@editor/components/common/designSystem/Selectable";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useGetProjectUtils from "@editor/hooks/useGetProjectUtils";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import useUserProjects from "@editor/hooks/useUserProjects";
import {
  publisherApi,
  useGetUserWorkspaceDetailsQuery,
} from "@editor/reducers/api-reducer";
import { useEditorDispatch } from "@editor/store";
import { getProjectName } from "@editor/utils/project-utils";
import { generateEditorPathname, routes } from "@editor/utils/router";
import { mapShopifyIntegrationCapacityReasonToError } from "@editor/utils/shopifyIntegrationCapacity";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import type { ShopifyIntegrationCapacityStatus } from "schemas/billing";
import type { ReploSimpleProject } from "schemas/project";
import { isShopifyIntegrationEnabled } from "schemas/utils";

const buttonClassName =
  "border-2 rounded hover:border-blue-600 grid place-content-center flex-1 p-5 text-sm";
const selectedButtonClassName = "border-blue-600";

type NewProjectFlowView =
  | "pickStoreSetup"
  | "pickNameAndOrg"
  | "pickProject"
  | null;

type NewProjectPayload = {
  name?: string;
  workspaceId?: string | null;
  shopifyIntegrationId?: string | null;
  useShopifyStore?: boolean;
  pendingAppInstallationId?: string;
};

type NewProjectFlowContextProps = {
  view: NewProjectFlowView;
  initialWorkspaceId?: string;
  onChangeView: (view: NewProjectFlowView) => void;
  project: NewProjectPayload;
  onChangeProject: (project: NewProjectPayload) => void;
  canAddShopifyIntegration?: ShopifyIntegrationCapacityStatus;
};

const NewProjectFlowContext = React.createContext<
  Partial<NewProjectFlowContextProps>
>({});

function useNewProjectFlow() {
  return React.useContext(NewProjectFlowContext);
}

export const NEW_SHOPIFY_INTEGRATION_ID = "NEW_STORE_INTEGRATION";
export const NEW_WORKSPACE_ID = "NEW_WORKSPACE_ID";

const NewProjectFlow: React.FC = () => {
  const { initialWorkspaceId } = useUserWorkspaces();
  const { pendingAppInstallationId, shopifyIntegrationId } =
    useNewProjectFlowParams();
  const [view, setView] = useNewProjectFlowCurrentView();
  const navigate = useNavigate();
  const [project, setProject] = React.useState<NewProjectPayload>({
    name: "",
    workspaceId: initialWorkspaceId,
    useShopifyStore: false,
    pendingAppInstallationId,
    shopifyIntegrationId,
  });

  const { workspaces, workspaceWhichOwnsIntegrationFromParams } =
    useUserWorkspaces();
  const workspace = workspaces?.find(
    (workspace) => workspace.id === project.workspaceId,
  );

  // NOTE (Matt 2024-07-06): If there is a shopifyIntegrationId url parameter
  // and the user does not have access to the integration's owner workspace,
  // then this user does not have access to the corresponding Shopify integration.
  const userDoesNotHaveAccessToOwningWorkspace = Boolean(
    shopifyIntegrationId && !workspaceWhichOwnsIntegrationFromParams,
  );

  const { data } = trpc.integration.get.useQuery(
    shopifyIntegrationId ? { id: shopifyIntegrationId } : skipToken,
  );
  const shopifyUrl = data?.integration?.shopifyIntegrationConfig?.shopifyUrl;

  if (!view) {
    return null;
  }

  return (
    <NewProjectFlowContext.Provider
      value={{
        view,
        onChangeView: setView,
        project,
        initialWorkspaceId,
        onChangeProject: setProject,
        canAddShopifyIntegration: workspace?.canAddShopifyIntegration,
      }}
    >
      <Modal
        isOpen
        className="w-[500px]"
        onRequestClose={() => {
          navigate(
            initialWorkspaceId
              ? `/workspace/${initialWorkspaceId}/projects`
              : "/home",
          );
        }}
      >
        <div className="flex flex-col gap-4">
          <NewProjectFlowHeader />
          {view === "pickStoreSetup" && (
            <>
              <ShopifyStoreToggle />
              <ProjectFields />
            </>
          )}
          {view === "pickNameAndOrg" && (
            <>
              <p className="text-sm text-gray-700">
                {pendingAppInstallationId || shopifyIntegrationId
                  ? "Create a project to connect your Shopify store to."
                  : "Add your project name and select the workspace it belongs to."}
              </p>
              <ProjectFields />
            </>
          )}
          {view === "pickProject" && <ProjectSelector />}
          {userDoesNotHaveAccessToOwningWorkspace && shopifyUrl && (
            <p className="text-xs text-red-600">
              The Shopify Store {shopifyUrl} is connected to a workspace which
              you do not have access to. Connecting this store to a project may
              not work. Reach out to support@replo.app if you have questions.
            </p>
          )}
        </div>
      </Modal>
    </NewProjectFlowContext.Provider>
  );
};

function NewProjectFlowHeader() {
  const { view } = useNewProjectFlow();
  const { isOnboarding } = useNewProjectFlowParams();

  return (
    <h1 className="text-lg font-semibold">
      {view === "pickProject"
        ? "Thanks for connecting your store"
        : // eslint-disable-next-line unicorn/no-nested-ternary
          isOnboarding
          ? "Welcome to Replo! Let’s get started"
          : "New Replo Project"}
    </h1>
  );
}

function ShopifyStoreToggle() {
  const { isOnboarding } = useNewProjectFlowParams();
  const { project, onChangeProject } = useNewProjectFlow();

  if (!project) {
    return null;
  }

  return (
    <>
      {isOnboarding && (
        <div className="flex flex-col gap-2 text-sm text-gray-700">
          <p>
            If you have a Shopify store, you can connect your store and access
            live product data directly in Replo immediately.
          </p>
          <p>
            No Shopify store? No problem. Just create a project without
            connecting for now.
          </p>
        </div>
      )}
      <div className="flex flex-col gap-2">
        <h2>Do you want to connect a Shopify Store?</h2>
        <div role="group" className="flex gap-4">
          <button
            type="button"
            className={classNames(
              buttonClassName,
              !project.useShopifyStore && selectedButtonClassName,
            )}
            onClick={() => {
              onChangeProject?.({
                ...project,
                useShopifyStore: false,
              });
            }}
            aria-pressed={!project.useShopifyStore}
          >
            <p>
              Create project without <br /> Shopify store
            </p>
            <p className="text-gray-400 text-xs">
              (You can connect a store later)
            </p>
          </button>
          <button
            type="button"
            className={classNames(
              buttonClassName,
              project.useShopifyStore && selectedButtonClassName,
            )}
            onClick={() => {
              onChangeProject?.({
                ...project,
                useShopifyStore: true,
              });
            }}
            aria-pressed={project.useShopifyStore}
          >
            Connect Shopify store
          </button>
        </div>
      </div>
    </>
  );
}

function ProjectFields() {
  const { user } = useCurrentUser();
  const { project, onChangeProject, canAddShopifyIntegration, view } =
    useNewProjectFlow();
  const { initialWorkspaceId } = useUserWorkspaces();
  const { pendingAppInstallationId, shopifyIntegrationId } =
    useNewProjectFlowParams();
  const { setLatestProjectIdOnCookies } = useGetProjectUtils();
  const analytics = useLogAnalytics();
  const dispatch = useEditorDispatch();
  const { mutate: createProject, isPending: isCreatingProject } =
    trpc.project.create.useMutation({
      onSuccess: ({ project: newProject }) => {
        analytics("project.create", {
          withShopify: Boolean(project?.useShopifyStore),
          createdFrom: initialWorkspaceId ? "orgPage" : "allProjects",
        });
        void trpcUtils.workspace.getUserWorkspacesList.invalidate();
        void trpcUtils.project.findByUserId.invalidate();
        // TODO (Sebas, 2024-07-15): Remove old invalidations once they are migrated to
        // TRPC.
        dispatch(publisherApi.util.invalidateTags(["workspaces", "projects"]));
        if (!newProject) {
          return;
        }
        // Note (Evan, 2024-04-15): The "pickNameAndOrg" view shows when the Shopify app has already been installed,
        // so don't redirect to the Shopify app store in that case.
        if (
          project?.useShopifyStore &&
          view !== "pickNameAndOrg" &&
          shopifyIntegrationId === NEW_SHOPIFY_INTEGRATION_ID
        ) {
          setLatestProjectIdOnCookies(newProject.id);
          window.open("https://replo.app/install", "_blank");
        } else {
          navigate(
            generatePath(routes.editor.project, {
              projectId: newProject.id,
            }),
          );
        }
      },
    });
  const navigate = useNavigate();

  if (!project) {
    return null;
  }

  // Note (Evan, 2024-04-11): i.e., the reason a shopify integration cannot be added
  // (or null if one can be added)
  const shopifyIntegrationReason =
    project.useShopifyStore && canAddShopifyIntegration?.hasCapacity === false
      ? canAddShopifyIntegration.reason
      : null;

  // NOTE (Matt 2024-07-11): The only time we should not show the workspace selector
  // is when there is a shopifyIntegrationId- because in that instance there is already
  // a workspace associated.
  const shouldShowWorkspaceSelector = !shopifyIntegrationId;
  const isFormValid =
    project?.name &&
    (shouldShowWorkspaceSelector ? project?.workspaceId : true);

  const shouldShowShopifyStoreSelector =
    project.useShopifyStore &&
    !(pendingAppInstallationId || shopifyIntegrationId);

  async function onSubmit() {
    const {
      name,
      workspaceId,
      pendingAppInstallationId,
      useShopifyStore,
      shopifyIntegrationId,
    } = project!;

    const isNewStore = shopifyIntegrationId == NEW_SHOPIFY_INTEGRATION_ID;

    createProject({
      name: name ?? `${user?.name}’s project`,
      workspaceId: workspaceId !== NEW_WORKSPACE_ID ? workspaceId : null,
      pendingAppInstallationId,
      shopifyIntegrationId:
        useShopifyStore && !isNewStore ? shopifyIntegrationId : null,
    });
  }
  const preventAddingShopifyIntegration = Boolean(
    shopifyIntegrationReason &&
      project.shopifyIntegrationId === NEW_SHOPIFY_INTEGRATION_ID,
  );
  // TODO (Martin, 2024-01-18): consider using a <form> here so that
  // we can use FormData rather than React state for values. For that
  // to happen, we would need to refactor Selectable to use Radix's
  // Select since that one uses a hidden input for the selected value.
  return (
    <>
      <LabeledControl label="Project Name" id="project-name">
        <Input
          autoFocus
          value={project?.name}
          id="project-name"
          placeholder="Project Name"
          onChange={(e) => {
            const newValue = e.target.value;
            onChangeProject?.({
              ...project,
              name: newValue,
            });
          }}
          autoComplete="off"
          size="base"
        />
      </LabeledControl>
      {shouldShowWorkspaceSelector && (
        <LabeledControl label="Workspace">
          <WorkspaceSelectable />
        </LabeledControl>
      )}
      {shouldShowShopifyStoreSelector && (
        <LabeledControl label="Shopify Integration">
          <ShopifyIntegrationSelectable
            workspaceId={project?.workspaceId ?? initialWorkspaceId}
            selectedIntegrationId={project?.shopifyIntegrationId}
            handleChangeSelection={(id) =>
              onChangeProject?.({ ...project, shopifyIntegrationId: id })
            }
          />
        </LabeledControl>
      )}
      <div className="text-xs text-red-600">
        {preventAddingShopifyIntegration &&
          shopifyIntegrationReason &&
          mapShopifyIntegrationCapacityReasonToError({
            reason: shopifyIntegrationReason,
            redirectToBilling: () => {
              // Note (Evan, 2024-04-11): This should always be defined
              if (project.workspaceId) {
                navigate(`/workspace/${project.workspaceId}/billing`);
              }
            },
          })}
      </div>

      <div className="flex justify-between">
        <Button
          type="primary"
          size="lg"
          className="px-12 ml-auto w-48"
          isDisabled={
            !isFormValid || isCreatingProject || preventAddingShopifyIntegration
          }
          isLoading={isCreatingProject}
          onClick={() => {
            void onSubmit();
          }}
        >
          {isCreatingProject ? "Creating" : "Create"} Project
        </Button>
      </div>
    </>
  );
}

export function ShopifyIntegrationSelectable({
  workspaceId,
  selectedIntegrationId,
  handleChangeSelection,
  isDisabled = false,
  className = "",
}: {
  workspaceId?: string | null;
  selectedIntegrationId?: string | null;
  handleChangeSelection: (id: string | null) => void;
  isDisabled?: boolean;
  className?: string;
}) {
  const { data } = trpc.integration.getWorkspaceShopifyIntegrationData.useQuery(
    workspaceId ? { workspaceId } : skipToken,
  );
  const existingShopifyIntegrations = data?.integrations;
  const shopifyIntegrations = React.useMemo(
    () => [
      ...(existingShopifyIntegrations ?? []),
      {
        id: NEW_SHOPIFY_INTEGRATION_ID,
        shopifyIntegrationConfig: {
          url: "+ Add a new Shopify Store",
        },
      },
    ],
    [existingShopifyIntegrations],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies(workspaceId): We actually want this
  React.useEffect(() => {
    if (
      selectedIntegrationId &&
      !shopifyIntegrations.find(({ id }) => id == selectedIntegrationId)
    ) {
      handleChangeSelection(null);
    }
  }, [
    shopifyIntegrations,
    workspaceId,
    selectedIntegrationId,
    handleChangeSelection,
  ]);
  return (
    <Selectable
      size="sm"
      placeholder="Choose Shopify Store"
      className={`${className} bg-slate-100`}
      options={shopifyIntegrations.map((integration) => ({
        label: integration.shopifyIntegrationConfig?.url ?? "",
        value: integration.id,
      }))}
      value={selectedIntegrationId}
      onSelect={handleChangeSelection}
      isDisabled={isDisabled}
    />
  );
}

function WorkspaceSelectable({ isDisabled = false }: { isDisabled?: boolean }) {
  const { project, onChangeProject } = useNewProjectFlow();
  const { workspaces } = useUserWorkspaces();
  const { pendingAppInstallationId } = useNewProjectFlowParams();
  const filteredOptions = React.useMemo(() => {
    if (!workspaces) {
      return [];
    }
    const filteredWorkspaces = pendingAppInstallationId
      ? workspaces.filter(
          ({ canAddShopifyIntegration: { hasCapacity } }) => hasCapacity,
        )
      : workspaces;
    const options = [
      ...filteredWorkspaces.map((workspace) => ({
        label: workspace.name,
        value: workspace.id,
      })),
    ];
    if (pendingAppInstallationId) {
      return [
        ...options,
        {
          label: "+ Add a new Workspace",
          value: NEW_WORKSPACE_ID,
        },
      ];
    }
    return options;
  }, [workspaces, pendingAppInstallationId]);
  return (
    <Selectable
      size="sm"
      placeholder="Choose Workspace"
      className="bg-slate-100"
      options={filteredOptions}
      value={project?.workspaceId}
      onSelect={(value) => {
        onChangeProject?.({
          ...project,
          workspaceId: value,
        });
      }}
      isDisabled={isDisabled}
    />
  );
}

function ProjectSelector() {
  const { projects } = useUserProjectsNotConnectedToShopify();
  const { onChangeView, onChangeProject, project } = useNewProjectFlow();
  const { pendingAppInstallationId, shopifyIntegrationId } =
    useNewProjectFlowParams();
  const dispatch = useDispatch();
  const { getLatestProjectIdFromCookies } = useGetProjectUtils();
  const [projectId, setProjectId] = React.useState<string | null>(
    getLatestProjectIdFromCookies(),
  );
  const analytics = useLogAnalytics();

  const { mutate: connectProject, isPending: isConnectingProject } =
    trpc.project.connectToPendingInstallation.useMutation({
      onSuccess: () => {
        void trpcUtils.workspace.getUserWorkspacesList.invalidate();
        // TODO (Sebas, 2024-07-15): Remove once we migrate all the workspace related
        // endpoints to TRPc.
        dispatch(publisherApi.util.invalidateTags(["workspaces"]));
        navigate(
          generateEditorPathname(routes.editor.project, {
            projectId: projectId!,
          }),
        );
      },
    });
  const { mutate: connectIntegration, isPending: isConnectingIntegration } =
    trpc.project.connectToIntegration.useMutation({
      onSuccess: () => {
        void trpcUtils.workspace.getUserWorkspacesList.invalidate();
        dispatch(publisherApi.util.invalidateTags(["workspaces"]));
        navigate(
          generateEditorPathname(routes.editor.project, {
            projectId: projectId!,
          }),
        );
      },
    });

  const { workspaceWhichOwnsIntegrationFromParams } = useUserWorkspaces();

  const filteredProjects = React.useMemo(() => {
    if (!shopifyIntegrationId) {
      return projects;
    }
    // NOTE (Matt 2024-07-06): If there is a shopifyIntegrationId in the url params
    // and we have not found an workspaceWhichOwnsIntegrationFromParams, then this user does not
    // have access to the workspace associated with this integration and should
    // not be able to select a project.
    return workspaceWhichOwnsIntegrationFromParams
      ? projects.filter(
          ({ ownerWorkspace }) =>
            ownerWorkspace?.id === workspaceWhichOwnsIntegrationFromParams.id,
        )
      : [];
  }, [projects, shopifyIntegrationId, workspaceWhichOwnsIntegrationFromParams]);

  React.useEffect(() => {
    // NOTE (Matt 2024-07-02): If there is an existing shopifyIntegrationId,
    // then we have set filteredProjects to be just projects that are associated
    // with that integration's workspace. This check is important to ensure the
    // projectId that is coming from the user's cookies corresponds to one of those
    // filtered projects.
    const matchingProject = filteredProjects.find(({ id }) => id === projectId);
    if (!matchingProject && projectId) {
      setProjectId(null);
    }
  }, [projectId, filteredProjects]);

  const isConnectingStore = isConnectingProject || isConnectingIntegration;

  const navigate = useNavigate();

  const isFormValid = projectId;

  async function onSubmit() {
    if (shopifyIntegrationId) {
      connectIntegration({
        projectId: projectId!,
        integrationId: shopifyIntegrationId,
      });
    } else {
      connectProject({
        projectId: projectId!,
        shopify: {
          pendingAppInstallationId: pendingAppInstallationId!,
        },
      });
    }
  }

  // TODO (Martin, 2024-01-18): consider using a <form> here so that
  // we can use FormData rather than React state for values. For that
  // to happen, we would need to refactor Selectable to use Radix's
  // Select since that one uses a hidden input for the selected value.
  return (
    <>
      <p className="text-sm text-gray-700">
        Select a project you wish to connect to your Shopify store. If you don’t
        see the project you want to connect to, you can create a new one.
      </p>
      <LabeledControl label="Project">
        <Selectable
          size="sm"
          placeholder="Choose Project"
          options={
            filteredProjects.map((project) => ({
              label: getProjectName(project),
              value: project.id,
            })) ?? []
          }
          value={projectId}
          onSelect={(value) => {
            setProjectId(value);
          }}
        />
      </LabeledControl>
      <div className="flex justify-between">
        <Button
          type="secondary"
          size="lg"
          className="px-12"
          onClick={() => {
            onChangeView?.("pickNameAndOrg");
            if (project) {
              onChangeProject?.({ ...project, useShopifyStore: true });
            }
            analytics("project.create", {
              withShopify: true,
              createdFrom: "reverseShopifyConnect",
            });
          }}
        >
          New Replo Project
        </Button>
        <Button
          type="primary"
          size="lg"
          className="px-12"
          isDisabled={!isFormValid || isConnectingStore}
          isLoading={isConnectingStore}
          onClick={() => {
            analytics("project.shopify.associate", {});
            void onSubmit();
          }}
        >
          {isConnectingStore ? "Connecting" : "Connect"} Project
        </Button>
      </div>
    </>
  );
}

// Hook to figure out possible search params for the new project flow
function useNewProjectFlowParams() {
  const [searchParams] = useSearchParams();
  const { workspaceId } = useParams();
  const isOnboarding = searchParams.get("onboarding") === "true";
  const pendingAppInstallationId =
    searchParams.get("pendingAppInstallationId") ?? undefined;
  const shopifyIntegrationId =
    searchParams.get("shopifyIntegrationId") ?? undefined;

  return React.useMemo(() => {
    return {
      isOnboarding,
      pendingAppInstallationId,
      shopifyIntegrationId,
      workspaceId,
    };
  }, [
    shopifyIntegrationId,
    isOnboarding,
    pendingAppInstallationId,
    workspaceId,
  ]);
}

function useNewProjectFlowCurrentView() {
  const { pendingAppInstallationId, shopifyIntegrationId } =
    useNewProjectFlowParams();
  const { projects, isLoading } = useUserProjectsNotConnectedToShopify();

  const pickView = React.useCallback(
    (projects: ReploSimpleProject[]) => {
      if (pendingAppInstallationId || shopifyIntegrationId) {
        return projects?.length > 0 ? "pickProject" : "pickNameAndOrg";
      }
      return "pickStoreSetup";
    },
    [pendingAppInstallationId, shopifyIntegrationId],
  );

  const [view, setView] = React.useState<NewProjectFlowView>(
    pickView(projects),
  );

  React.useEffect(() => {
    setView(pickView(projects));
  }, [pickView, projects]);

  return [
    pendingAppInstallationId && isLoading ? null : view,
    setView,
  ] as const;
}

function useUserWorkspaces() {
  const { workspaceId } = useParams();
  const [searchParams] = useSearchParams();
  const shopifyIntegrationId = searchParams.get("shopifyIntegrationId");
  const { user } = useCurrentUser();
  const { data } = useGetUserWorkspaceDetailsQuery();
  const workspaces = data?.workspaces;

  const workspaceWhichOwnsIntegrationFromParams = shopifyIntegrationId
    ? workspaces?.find(({ integrations }) =>
        integrations?.some(({ id }) => id === shopifyIntegrationId),
      )
    : null;

  // Validate that the workspaceId or shopifyIntegrationId that we got from the URL is valid
  const initialWorkspaceId = workspaces?.find((workspace) => {
    if (!workspaceId && shopifyIntegrationId) {
      return workspace.integrations?.some(
        ({ id }) => id === shopifyIntegrationId,
      );
    }
    return workspace.id === workspaceId;
  })?.id;

  const personalWorkspaceId = workspaces?.find(
    (workspace) => workspace.domain === user?.email,
  )?.id;

  return {
    workspaces,
    initialWorkspaceId,
    personalWorkspaceId,
    workspaceWhichOwnsIntegrationFromParams,
  };
}

function useUserProjectsNotConnectedToShopify() {
  const { data, isPending: isLoading } = useUserProjects();
  return React.useMemo(() => {
    const projects = data?.allProjects ?? [];
    return {
      projects: projects.filter(
        (project) => !isShopifyIntegrationEnabled(project),
      ),
      isLoading,
    };
  }, [data, isLoading]);
}

export default NewProjectFlow;

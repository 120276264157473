import { useOpenModal } from "@editor/hooks/useModal";
import { getTargetFrameWindow } from "@editor/hooks/useTargetFrame";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  setLocaleData,
  setOkendoWidgetNamespace,
  setShopifyUrlRoot,
} from "@editor/reducers/commerce-reducer";
import { useEditorDispatch } from "@editor/store";
import * as React from "react";
import {
  DEFAULT_ACTIVE_CURRENCY,
  DEFAULT_ACTIVE_LANGUAGE,
  DEFAULT_ACTIVE_SHOPIFY_URL_ROOT,
  DEFAULT_MONEY_FORMAT,
  DEFAULT_OKENDO_NAMESPACE,
  getActiveCurrency,
  getActiveLanguage,
  getActiveShopifyUrlRoot,
  getOkendoNamespace,
  getShopifyMoneyFormat,
} from "replo-runtime/shared/liquid";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";

import { setLoadingType, setTargetFrame } from "./canvas-reducer";

type FrameRefs = React.MutableRefObject<
  Map<EditorCanvas, { element: HTMLIFrameElement | null; isLoaded: boolean }>
>;

/**
 * Hook for having a battle-tested iframe's onload callback.
 * Includes a workaround for Safari, that doesn't run default onLoad
 * event when certain assets don't get resolved. REPL-6065
 */
export function useFrameOnLoad(frameRefs: FrameRefs) {
  const isMultipleCanvasesEnabled = isFeatureEnabled("multiple-canvases");
  const dispatch = useEditorDispatch();
  const openModal = useOpenModal();

  const handleFrameLoad = React.useCallback(
    (canvas: EditorCanvas, currentTargetFrame: HTMLIFrameElement | null) => {
      const ref = frameRefs.current.get(canvas);
      if (!ref || ref.isLoaded) {
        return;
      }

      if (!currentTargetFrame) {
        alert("Failed to load canvas, contact support@replo.app");
        return;
      }

      const targetFrameWindow = getTargetFrameWindow(currentTargetFrame);
      // Note (Noah, 2023-04-14): If we're trying to paint and we have a target iframe (which
      // means the iframe element has loaded) but we don't have a content window, that means
      // that the iframe has been redirected to a different page and we can't access its
      // window (which means we won't be able to paint). Throw an error to show to the user
      // instead of crashing.
      if (!targetFrameWindow) {
        openModal({
          type: "fullPageErrorModal",
          props: {
            details: {
              header: "Failed to Load Page",
              subheader: "Shopify Theme Redirect",
              message:
                "Code in the Shopify theme triggered a page redirect, which means Replo can't access the theme page. Please reach out to support@replo.app and we can help resolve this issue.",
            },
          },
        });
        return;
      }

      frameRefs.current.set(canvas, {
        element: ref.element ?? currentTargetFrame,
        isLoaded: true,
      });

      dispatch(setTargetFrame({ canvas, targetFrame: currentTargetFrame }));

      // NOTE (Chance 2024-06-11): If this is the first canvas to load, we'll
      // set up Shopify store values parsed from the window's Shopify script. If
      // it's the last frame to load, set the loading type to "loaded". This
      // could probably be handled better in the reducer itself and stored as a
      // derived state value. Both conditions are true if multi-canvas is not
      // enabled.
      let isFirstToLoad = true;
      let isDoneLoading = true;
      if (isMultipleCanvasesEnabled) {
        for (const [key, { isLoaded }] of frameRefs.current) {
          if (key !== canvas) {
            // If another canvas is loaded, we know this canvas is not the first
            // to load
            if (isLoaded) {
              isFirstToLoad = false;
            }
            isDoneLoading = isDoneLoading && isLoaded;
          }
        }
      }

      if (isFirstToLoad) {
        const activeLanguage =
          getActiveLanguage(targetFrameWindow) ?? DEFAULT_ACTIVE_LANGUAGE;
        const moneyFormat =
          getShopifyMoneyFormat(targetFrameWindow) ?? DEFAULT_MONEY_FORMAT;
        const activeCurrency =
          getActiveCurrency(targetFrameWindow) ?? DEFAULT_ACTIVE_CURRENCY;
        const okendoNamespace =
          getOkendoNamespace(targetFrameWindow) ?? DEFAULT_OKENDO_NAMESPACE;
        const shopifyUrlRoot =
          getActiveShopifyUrlRoot(targetFrameWindow) ??
          DEFAULT_ACTIVE_SHOPIFY_URL_ROOT;
        dispatch(
          setLocaleData({ activeCurrency, activeLanguage, moneyFormat }),
        );
        dispatch(setOkendoWidgetNamespace(okendoNamespace));
        dispatch(setShopifyUrlRoot(shopifyUrlRoot));
      }
      if (isDoneLoading) {
        dispatch(setLoadingType("loaded"));
      }
    },
    [frameRefs, openModal, dispatch, isMultipleCanvasesEnabled],
  );

  // Effect to check if the iframe has loaded which serves as the
  // workaround for Safari.
  React.useEffect(() => {
    const timers: number[] = [];

    for (const [canvas, ref] of frameRefs.current) {
      if (!isMultipleCanvasesEnabled && canvas !== "desktop") {
        continue;
      }
      const timer = window.setInterval(() => {
        if (ref.isLoaded) {
          window.clearInterval(timer);
          return;
        }

        const targetFrameDocument = ref.element
          ? getTargetFrameWindow(ref.element)?.document
          : null;
        if (
          targetFrameDocument &&
          ["complete", "interactive"].includes(targetFrameDocument.readyState)
        ) {
          handleFrameLoad(canvas, ref.element);
          window.clearInterval(timer);
        }
      }, 1000);
      timers.push(timer);
    }

    return () => {
      for (const timer of timers) {
        window.clearInterval(timer);
      }
    };
  }, [isMultipleCanvasesEnabled, handleFrameLoad, frameRefs]);
  return handleFrameLoad;
}

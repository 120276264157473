import { isTailwindClassName } from "@editor/utils/tailwind-classname";
import classNames from "classnames";
import isString from "lodash-es/isString";
import * as React from "react";
import type { SolidOrGradient } from "replo-runtime/shared/types";
import { gradientToCssGradient } from "replo-runtime/shared/utils/gradient";

type BadgeProps = {
  children?: React.ReactNode;
  isFilled?: boolean;
  foregroundColor?: string;
  backgroundColor?: string | SolidOrGradient;
  className?: string;
  style?: React.CSSProperties;
};

const Badge = ({
  children,
  isFilled = false,
  foregroundColor = "black",
  backgroundColor = "transparent",
  className,
  style,
}: BadgeProps) => {
  function getBackgroundColor() {
    if (isString(backgroundColor)) {
      if (isFilled) {
        return !isTailwindClassName(backgroundColor, "bg")
          ? backgroundColor
          : undefined;
      }
      return "transparent";
    }

    if (backgroundColor.type === "gradient") {
      return gradientToCssGradient(backgroundColor.gradient);
    }
    return backgroundColor.color;
  }
  return (
    <div
      className={classNames(
        "flex items-center justify-center rounded",
        { "h-[18px] w-[18px]": !className },
        className,
        isString(backgroundColor) && {
          [backgroundColor]:
            isFilled && isTailwindClassName(backgroundColor, "bg"),
        },
        {
          [foregroundColor]: isTailwindClassName(foregroundColor, "text"),
        },
      )}
      style={{
        ...style,
        background: getBackgroundColor() ?? undefined,
        color: !isTailwindClassName(foregroundColor, "text")
          ? foregroundColor
          : undefined,
      }}
    >
      {children}
    </div>
  );
};

export default Badge;

import { FlowProgress } from "@components/flows/components/FlowProgress";
import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import { useGetCurrentFlow } from "@components/flows/hooks/useGetCurrentFlow";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { ReploLogoBlack } from "@svg/replo-logo-black";
import * as React from "react";
import { useEffectEvent } from "replo-utils/react/use-effect-event";
import type { FlowStepDataValueOf } from "schemas/flow";

type OnboardingStepsLayoutProps = {
  rightPanelContent: React.ReactNode;
};

const OnboardingStepsLayout: React.FC<
  React.PropsWithChildren<OnboardingStepsLayoutProps>
> = ({ rightPanelContent, children }) => {
  const analytics = useLogAnalytics();
  const { currentStep } = useReploFlowsStepContext();
  const { currentInstance } = useGetCurrentFlow();

  const onBeforeUnload = useEffectEvent(() => {
    if (currentStep && currentInstance) {
      analytics("onboarding.flow.abandoned", {
        lastStepCompleted: currentStep?.id,
        stepsData: currentInstance?.stepResults,
        heardFrom:
          (
            currentInstance?.stepResults[
              "onboarding.user.how-did-you-hear-about-us"
            ]
              ?.data as FlowStepDataValueOf<"onboarding.user.how-did-you-hear-about-us">
          )?.heardFrom ?? [],
        whatToDo:
          (
            currentInstance?.stepResults[
              "onboarding.user.what-do-you-want-to-do-in-replo"
            ]
              ?.data as FlowStepDataValueOf<"onboarding.user.what-do-you-want-to-do-in-replo">
          )?.whatToDo ?? [],
      });
    }
  });

  React.useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [onBeforeUnload]);

  return (
    <div className="bg-gray-100 w-screen h-screen grid grid-cols-12 grid-rows-1">
      <div className="col-span-12 lg:col-span-5 xl:col-span-4 bg-white flex flex-col justify-between overflow-auto no-scrollbar">
        <div className="flex flex-col gap-24 px-14 pt-14">
          <ReploLogoBlack />
          {children}
        </div>
        <div className="p-14">
          <FlowProgress entityType="user" />
        </div>
      </div>
      <div className="col-span-12 lg:col-span-7 xl:col-span-8 overflow-hidden">
        {rightPanelContent}
      </div>
    </div>
  );
};

export default OnboardingStepsLayout;

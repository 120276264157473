import * as React from "react";

import type { RenderComponentProps } from "../../../shared/types";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { prependProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedLiquidReviewIntegrations } from "../SharedLiquidReviewIntegrations";

function FeraProductReviewsWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const product = useProductFromProps(component.props, context);
  const liquidSource =
    '<div data-fera-container="product_reviews" data-product-id="{{ product.id }}"></div>';
  const liquid = prependProductAssignmentToLiquidSource({
    product,
    liquidSource,
    context: props.context,
  });
  return (
    <SharedLiquidReviewIntegrations
      attributes={componentAttributes}
      component={component}
      liquidSource={liquid}
      placeholder="Fera Product Reviews Widget will appear here"
      shouldHavePlaceHolder={!product}
      appName="Fera.ai"
      context={context}
    />
  );
}

export default FeraProductReviewsWidget;

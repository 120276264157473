import Button from "@common/designSystem/Button";
import PageElementEditor from "@components/editor/elementEditors/PageElementEditor";
import ProductTemplateElementEditor from "@components/editor/elementEditors/ProductTemplateElementEditor";
import { SectionElementEditor } from "@components/editor/elementEditors/SectionElementEditor";
import { updateAndSaveElement } from "@editor/actions/core-actions";
import { ElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import {
  useElementEditorErrorContext,
  withElementEditorErrorContext,
} from "@editor/contexts/ElementEditorErrorContext";
import { useElementValidation } from "@editor/hooks/element";
import { useModal } from "@editor/hooks/useModal";
import { analytics } from "@editor/infra/analytics";
import {
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectElementIsLoading,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import type {
  ElementContent,
  ReploEditableElement,
  ReploEditableElementWithId,
} from "@editor/utils/element";
import {
  elementFormHasErrors,
  getFormattedElementName,
} from "@editor/utils/element";
import classNames from "classnames";
import * as React from "react";
import {
  isPageOrShopifyArticleType,
  type ReploElementType,
} from "schemas/element";

import type { UpdateElementModalProps } from "../AppModalTypes";
import { ElementEditorModalForm } from "../editor/elementEditors/ElementEditorModalForm";
import { ElementEditorTitle } from "../editor/elementEditors/ElementEditorTitle";

const UPDATE_ELEMENT_TYPE_CONTENT: Record<ReploElementType, ElementContent> = {
  page: {
    header: "Page Settings",
    submitText: "Save Page Settings",
  },
  shopifyArticle: {
    header: "Edit Blog Post",
    submitText: "Update Post",
  },
  shopifyProductTemplate: {
    header: "Edit Product",
    submitText: "Update Template",
  },
  shopifySection: {
    header: "Edit Section",
    submitText: "Update Section",
  },
};

export const UpdateElementModal: React.FC<UpdateElementModalProps> =
  withElementEditorErrorContext(({ element: initialElement }) => {
    const {
      element,
      onChangeElement,
      updateElement,
      validateElement,
      isUpdatingElement,
    } = useEditableElement({
      element: initialElement,
    });

    const modal = useModal();
    const { errorMapping, setErrors, clearErrors } =
      useElementEditorErrorContext();

    const content = UPDATE_ELEMENT_TYPE_CONTENT[element.type];
    const isPageOrShopifyArticle = isPageOrShopifyArticleType(element.type);

    function onSubmit() {
      clearErrors("path", "allErrorsFromAllFormKeys");

      const { isValid, errors: validationErrors } = validateElement(element);
      if (!isValid) {
        if (validationErrors.title.length > 0) {
          setErrors("title", validationErrors.title);
        }
        if (validationErrors.path.length > 0) {
          setErrors("path", validationErrors.path);
        }
        return;
      }

      updateElement(element as ReploEditableElementWithId);
    }

    return (
      <ElementEditorDataContext.Provider value={{ element, onChangeElement }}>
        <ElementEditorModalForm
          testId="update-element-form"
          onSubmit={(e) => {
            e.preventDefault();
            if (isUpdatingElement) {
              return;
            }
            onSubmit();
          }}
          onCloseModal={() => {
            modal.closeModal({ type: "updateElementModal" });
          }}
        >
          <div className="no-scrollbar max-h-[85vh] overflow-y-auto">
            <div
              className={classNames({
                "h-[47rem]": isPageOrShopifyArticle,
              })}
            >
              <ElementEditorTitle>{content.header}</ElementEditorTitle>
              {(() => {
                switch (element.type) {
                  case "page":
                  case "shopifyArticle":
                    return <PageElementEditor requestType="update" />;
                  case "shopifyProductTemplate":
                    return <ProductTemplateElementEditor />;
                  case "shopifySection":
                    return <SectionElementEditor requestType="update" />;
                  default:
                    return null;
                }
              })()}
            </div>
          </div>
          <div className="mt-8 flex flex-row justify-end gap-x-2">
            <Button
              size="lg"
              type="primary"
              htmlType="submit"
              isLoading={isUpdatingElement}
              data-testid="page-submit-button"
              isDisabled={elementFormHasErrors(errorMapping)}
            >
              {content?.submitText}
            </Button>
          </div>
        </ElementEditorModalForm>
      </ElementEditorDataContext.Provider>
    );
  });

/**
 * This hook handles update, validation and the state of a Replo element.
 */
function useEditableElement({
  element: initialElement,
}: UpdateElementModalProps) {
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const originalElement = initialElement ?? draftElement;
  const [element, setElement] = React.useState<
    ReploEditableElement | ReploEditableElementWithId
  >(originalElement);

  const validateElement = useElementValidation(element.type);
  const initialIsHomepage = React.useRef(initialElement?.isHomepage ?? false);
  const dispatch = useEditorDispatch();
  const isElementLoading = useEditorSelector(selectElementIsLoading);

  const onUpdateElement = React.useCallback(
    function onUpdateElementFn(element: ReploEditableElementWithId) {
      const isSettingHomepage =
        element.isHomepage && !initialIsHomepage.current;
      analytics.logEvent("element.update", {
        type: element.type,
        elementId: element.id,
      });

      return dispatch(
        updateAndSaveElement(
          element.id,
          {
            ...element,
            name: getFormattedElementName(element),
          },
          isSettingHomepage,
        ),
      );
    },
    [dispatch],
  );

  return {
    element,
    onChangeElement: setElement,
    updateElement: onUpdateElement,
    validateElement,
    isUpdatingElement: isElementLoading,
  };
}

import { storeProductRequestLimits } from "@editor/hooks/useStoreProducts";
import { getTokenFromStorage } from "@editor/infra/auth";
import type { UploadResult } from "@editor/reducers/commerce-reducer";
import { setProductData } from "@editor/reducers/commerce-reducer";
import type { EditorRootState } from "@editor/store";
import { selectDraftElementId, selectStoreUrl } from "@reducers/core-reducer";
import { closeModal } from "@reducers/modals-reducer";
import { replaceCdnUrls } from "@reducers/utils/api-reducer-utils";
import { setHeaderToken } from "@reducers/utils/set-header-token";
import type { FetchArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { getPublisherUrl } from "replo-runtime/shared/config";
import type { StoreProduct } from "replo-runtime/shared/types";
import type { ShopifyIntegrationCapacityStatus } from "schemas/billing";
import type { ReploElement } from "schemas/element";
import type { StoreProductSummary } from "schemas/product";
import type { ReploProject } from "schemas/project";
import type { Workspace, WorkspaceRole } from "schemas/workspace";

import {
  setCanvasHtml,
  setLoadingType,
} from "@/features/canvas/canvas-reducer";

export type ElementPayload = {
  data: { element: ReploElement };
};

// TODO (gabe, 2023-05-05): move this into shared package
export type PageInfo = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  prevPage: string | null;
  nextPage: string | null;
};

type GetShopifyProductsRawResult = {
  products: StoreProduct[];
  pageInfo: PageInfo;
  success: boolean;
};

type GetShopifyProductsSummaryRawResult = {
  products: StoreProductSummary[];
  pageInfo: PageInfo;
  success: boolean;
};

export type BillingPlanUpgradeSource =
  | "unknown"
  | "publish.productTemplate"
  | "publish.pageOrDefaultElementType"
  | "publish.section"
  | "ai.credits"
  | "projectMemberships.add"
  | "direct.canvasControls"
  | "direct.header"
  | "direct.pdpBanner"
  | "activate.experiments"
  | "direct.billingDashboard"
  | "integrationHub.shopify";

/**
 * API to use for fetching product data from shopify via our backend.
 *
 * Note (Noah, 2022-07-19): This is a separate createApi definition because
 * we want different retry mechanics for this API, since it's more likely
 * to get throttled by Shopify and if that happens we'd really like to retry
 * because the alternative would be to not have product data available in the
 * editor.
 */
export const productsApi = createApi({
  reducerPath: "products",
  baseQuery: retry(async (args: FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl: `${getPublisherUrl()}`,
      prepareHeaders: setHeaderToken,
    })(args, api, extraOptions);
    // NOTE (Evan, 7/12/23) If the request fails with code 401 (unauthorized), we want to skip
    // retrying and immediately throw an error (to show the modal)
    if (result.error?.status === 401) {
      retry.fail(result.error);
    }

    return result;
  }),
  keepUnusedDataFor: 120,
  endpoints: (builder) => ({
    getShopifyProducts: builder.query<
      { products: StoreProduct[]; pageInfo: PageInfo },
      {
        storeId: string;
        pageSize: number;
        nextPage?: string;
        query: string;
      }
    >({
      query: ({ storeId, pageSize, nextPage = "", query }) => {
        const path = `/api/v1/shopify/products`;
        const params = new URLSearchParams({
          storeId,
          pageSize: pageSize.toString(),
          nextPage,
          q: query,
        });
        return `${path}?${params.toString()}`;
      },
      transformResponse: (rawResult: GetShopifyProductsRawResult) => {
        return { products: rawResult.products, pageInfo: rawResult.pageInfo };
      },
      async onQueryStarted(_draft, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;

        if (data?.products) {
          dispatch(setProductData(data.products));
        }
      },
    }),
    getShopifyProductsSummary: builder.query<
      { products: StoreProductSummary[]; pageInfo: PageInfo },
      {
        storeId: string;
        pageSize: number;
        nextPage?: string;
        query?: string;
      }
    >({
      query: ({ storeId, pageSize, nextPage = "", query = "" }) => {
        const path = `/api/v1/shopify/products/summary`;
        const params = new URLSearchParams({
          storeId,
          pageSize: pageSize.toString(),
          nextPage,
          q: query,
        });
        return `${path}?${params.toString()}`;
      },
      transformResponse: (rawResult: GetShopifyProductsSummaryRawResult) => {
        return { products: rawResult.products, pageInfo: rawResult.pageInfo };
      },
    }),
  }),
});

export const publisherApi = createApi({
  reducerPath: "publisherApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${getPublisherUrl()}/api/v1`,
    prepareHeaders: setHeaderToken,
  }),
  tagTypes: [
    "designSystems",
    "experiments",
    "workspaces",
    "workspace",
    "flows",
    "projects",
    "project",
    "element",
  ],
  endpoints: (builder) => ({
    getUserWorkspaceDetails: builder.query<
      {
        // TODO (Fran 2024-03-13): Move this types to schemas package - REPL-10873
        workspaces: (Workspace & {
          workspaceMemberships: { role: WorkspaceRole }[];
          canAddShopifyIntegration: ShopifyIntegrationCapacityStatus;
          hasShopifyActiveSubscription: boolean;
        })[];
      },
      void
    >({
      query: () => {
        return "/workspace/list/details";
      },
      providesTags: ["workspaces"],
    }),
    getProjectsByWorkspaceId: builder.query<
      {
        name: Workspace["name"];
        projects: ReploProject[];
      },
      string
    >({
      query: (workspaceId) => {
        return `/workspace/${workspaceId}/projects`;
      },
      providesTags: ["projects"],
    }),
    getCanvasDocument: builder.query<
      {
        html: string;
        status: string;
        type: string | null;
        error?: Record<string, string>;
      },
      {
        storeId?: string;
        draftElementId?: string;
        storePassword?: string | null;
      }
    >({
      query: ({ storeId, storePassword, draftElementId }) => {
        const params: Record<string, string> = {
          storeId: storeId ?? "",
        };

        if (storePassword) {
          params.storePassword = storePassword;
        }

        if (draftElementId) {
          params.elementId = draftElementId;
        }
        const qs = new URLSearchParams(params);

        return `/mirror?${qs.toString()}`;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        dispatch(setLoadingType("fetchingContent"));

        const queriedElementId = selectDraftElementId(
          getState() as EditorRootState,
        );
        const storeUrl = selectStoreUrl(getState() as EditorRootState);

        const { data } = await queryFulfilled;
        const currentDraftElementId = selectDraftElementId(
          getState() as EditorRootState,
        );

        // Note (Ovishek, 2022-09-09): We should not care if we get data back for a query for an element which
        // is not currently the draft element - this means user moved to another element, so we can ignore the response
        if (queriedElementId !== currentDraftElementId) {
          return;
        }

        if (data.html) {
          dispatch(closeModal({ type: "storePasswordRequiredModal" }));
          dispatch(setLoadingType("initiallyPainting"));
          dispatch(
            setCanvasHtml(
              storeUrl ? replaceCdnUrls(data.html, storeUrl) : data.html,
            ),
          );
        }
      },
    }),
  }),
});

export const {
  useGetShopifyProductsQuery,
  useLazyGetShopifyProductsQuery,
  useGetShopifyProductsSummaryQuery,
  useLazyGetShopifyProductsSummaryQuery,
} = productsApi;

export const assetsApi = createApi({
  reducerPath: "assetsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${getPublisherUrl()}`,
    // Note (Evan, 2024-01-16): We don't add content-type: JSON here
    // because the updateOrCreateAsset is not JSON (it is multipart)
    prepareHeaders: (headers) => {
      const token = getTokenFromStorage();
      if (token) {
        headers.set("Authorization", `Token ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    updateOrCreateAsset: builder.mutation<
      UploadResult,
      {
        file: any;
        storeId: string;
        sourceType: "theme" | "files";
        filename: string;
        displayName?: string;
      }
    >({
      query: ({ file, storeId, sourceType, filename, displayName }) => {
        const body = new FormData();
        body.append("file", file);
        body.append("storeId", storeId);
        body.append("filename", filename);
        body.append("sourceType", sourceType);
        if (displayName) {
          body.append("displayName", displayName);
        }
        return {
          url: `/api/v1/shopify/assets`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const { useUpdateOrCreateAssetMutation } = assetsApi;

export const {
  useGetUserWorkspaceDetailsQuery,
  useGetCanvasDocumentQuery: useGetCanvasDocumentPublisherQuery,
  useGetProjectsByWorkspaceIdQuery,
} = publisherApi;

export const selectAnyProducts = (state: EditorRootState) => {
  return state.core.project?.id
    ? productsApi.endpoints.getShopifyProducts.select({
        storeId: state.core.project.id,
        pageSize: storeProductRequestLimits.defaultForAnyProducts,
        query: "",
      })(state).data?.products || []
    : [];
};

export const getCanvasDocumentPublisherQuery =
  publisherApi.endpoints.getCanvasDocument.initiate;

export const useLazyCanvasDocumentPublisherQuery =
  publisherApi.endpoints.getCanvasDocument.useLazyQuery;

export const fetchProducts = productsApi.endpoints.getShopifyProducts.initiate;

import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import RenderComponentPlaceholder from "../RenderComponentPlaceholder";

const alchemyGoogleMapsEmbedApiKey = "AIzaSyAwkDMT0B6J-3sx7HTXqwi-GybfxK4t_x8";

const GoogleMaps: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
}) => {
  const query = component.props._query;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);

  return (
    <div {...componentAttributes}>
      {query ? (
        <iframe
          width="100%"
          height="100%"
          src={`https://www.google.com/maps/embed/v1/place?key=${alchemyGoogleMapsEmbedApiKey}&q=${encodeURIComponent(
            query,
          )}`}
        />
      ) : (
        isEditorApp && (
          <RenderComponentPlaceholder title="Once you set the address to display in this map, the map will appear here." />
        )
      )}
    </div>
  );
};

export default GoogleMaps;

import * as React from "react";

import type { RenderComponentProps } from "../../../shared/types";
import { SharedLooxReviews } from "../SharedLooxReviews";

const LooxReviewsWidget: React.FC<RenderComponentProps> = (props) => {
  const liquidSourceWidget = `
  <div id="looxReviews" data-product-id="{{product.id}}" class="loox-reviews-default"></div>
    `;

  return (
    <SharedLooxReviews
      component={props.component}
      context={props.context}
      attributes={props.componentAttributes}
      liquidSource={liquidSourceWidget}
      reviewsComponentType="Reviews"
    />
  );
};

export default LooxReviewsWidget;

import type { CustomComponentPropType } from "replo-runtime/shared/enums";

type CustomPropTypeRenderData = {
  excludeFromDynamicData: boolean;
};
export const customPropTypeToRenderData: Record<
  CustomComponentPropType,
  CustomPropTypeRenderData
> = {
  component: {
    excludeFromDynamicData: true,
  },
  timeInterval: {
    excludeFromDynamicData: false,
  },
  date: {
    excludeFromDynamicData: false,
  },
  seconds: {
    excludeFromDynamicData: true,
  },
  products: {
    excludeFromDynamicData: false,
  },
  product: {
    excludeFromDynamicData: false,
  },
  product_variant: {
    excludeFromDynamicData: false,
  },
  boolean: {
    excludeFromDynamicData: false,
  },
  multiline: {
    excludeFromDynamicData: false,
  },
  string: {
    excludeFromDynamicData: false,
  },
  inlineItems: {
    excludeFromDynamicData: true,
  },
  htmlAttributes: {
    excludeFromDynamicData: true,
  },
  dynamicItems: {
    excludeFromDynamicData: true,
  },
  anyDynamicValue: {
    excludeFromDynamicData: true,
  },
  collection: {
    excludeFromDynamicData: true,
  },
  location: {
    excludeFromDynamicData: false,
  },
  hashmark: {
    excludeFromDynamicData: true,
  },
  float: {
    excludeFromDynamicData: false,
  },
  image: {
    excludeFromDynamicData: false,
  },
  productOptions: {
    excludeFromDynamicData: false,
  },
  productVariants: {
    excludeFromDynamicData: false,
  },
  liquidCodeEditor: {
    excludeFromDynamicData: false,
  },
  htmlCodeEditor: {
    excludeFromDynamicData: false,
  },
  cssCodeEditor: {
    excludeFromDynamicData: false,
  },
  integer: {
    excludeFromDynamicData: true,
  },
  color: {
    excludeFromDynamicData: true,
  },
  ratingBalancer: {
    excludeFromDynamicData: true,
  },
  pixels: {
    excludeFromDynamicData: true,
  },
  pixelsIncludingNegativeValues: {
    excludeFromDynamicData: false,
  },
  selectable: {
    excludeFromDynamicData: true,
  },
  shopifyThemeSections: {
    excludeFromDynamicData: true,
  },
  swatches: {
    excludeFromDynamicData: true,
  },
  productFromPropsOrFromContext: {
    excludeFromDynamicData: false,
  },
  productSellingPlan: {
    excludeFromDynamicData: false,
  },
  percentage: {
    excludeFromDynamicData: true,
  },
};

import * as React from "react";

import {
  SharedStateContext,
  useRuntimeContext,
} from "../../shared/runtime-context";

/**
 * Hook for setting state for runtime use only
 */
export function useEditorReadableState<T>(
  key: string | string[],
  defaultValue: T | null = null,
) {
  const finalKey = Array.isArray(key) ? key.join(".") : key;
  const { setEditorReadableState } = useRuntimeContext(SharedStateContext);

  React.useEffect(() => {
    // NOTE (Chance 2024-04-12): This is a no-op if the context is not set, so
    // we don't need to check the render environment before calling.
    // `setEditorReadableState` should be stable between renders.
    setEditorReadableState({ key: finalKey, value: defaultValue });
  }, [finalKey, defaultValue, setEditorReadableState]);
}

export default useEditorReadableState;

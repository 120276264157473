import ButtonGroupComponent from "@editor/components/common/designSystem/ButtonGroup";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Popover from "@editor/components/common/designSystem/Popover";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import Slider from "@editor/components/common/designSystem/Slider";
import ToggleGroup from "@editor/components/common/designSystem/ToggleGroup";
import {
  useApplyComponentAction,
  type UseApplyComponentActionType,
} from "@editor/hooks/useApplyComponentAction";
import {
  selectDraftComponentId,
  selectDraftComponentPositionKey,
  selectRotation,
  selectTransform,
  selectTransformOrigin,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import { DraggingTypes } from "@editor/utils/editor";
import { getIntegerValueFromString } from "@editor/utils/getIntegerValueFromString";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import ModifierGroup from "@editorExtras/ModifierGroup";
import { LengthInputSelector } from "@editorModifiers/LengthInputModifier";
import { transformDefaultValues } from "@editorModifiers/utils";
import isEqual from "lodash-es/isEqual";
import merge from "lodash-es/merge";
import * as React from "react";
import { AiOutlineRotateLeft, AiOutlineRotateRight } from "react-icons/ai";
import { BsArrowsMove, BsGrid3X3 } from "react-icons/bs";
import { FiMinus, FiPlus, FiRotateCw } from "react-icons/fi";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { RiCloseFill } from "react-icons/ri";
import type { PreviewableSubProperty } from "replo-runtime/shared/utils/preview";
import type { TransformObject } from "replo-runtime/shared/utils/transform";
import { getTransformStyleString } from "replo-runtime/shared/utils/transform";
import {
  CSS_ANGLE_TYPES,
  CSS_LENGTH_TYPES,
} from "replo-runtime/shared/utils/units";
import { filterNulls } from "replo-utils/lib/array";

/**
 * Converts a CSS transform value into a human-readable format.
 * @param transformValue The CSS transform value as a string.
 * @returns The human-readable transform value.
 */
const getReadableTransformValue = (transformValue: string): string => {
  const transformDefinitions: [RegExp, string][] = [
    [/translate3d\((.+?)\)/, "Translate"],
    [/scale3d\((.+?)\)/, "Scale"],
    [/rotateX\((.+?)\)/, "Rotate X"],
    [/rotateY\((.+?)\)/, "Rotate Y"],
    [/rotateZ\((.+?)\)/, "Rotate Z"],
    [/skew\((.+?)\)/, "Skew"],
  ];

  const readableTransforms = transformDefinitions.map(([regex, label]) => {
    const match = transformValue.match(regex);

    if (!match || !match[1]) {
      return null;
    }

    const values = match[1].split(",").map((val) => val.trim());

    const hasIdenticalValues = values.every((val) => val === values[0]);

    if (hasIdenticalValues) {
      return `${label} ${values[0]}`;
    }
    return `${label} (${values.join(", ")})`;
  });

  const filteredTransforms = filterNulls(readableTransforms);
  return filteredTransforms.join(" ");
};

type Transform = "move" | "scale" | "rotate" | "skew";
type PopoverType = "origin" | "content";

type ToggleGroupInput = {
  startEnhancer: React.ReactNode;
  resetValue: string;
  anchorValue: string;
  placeholder: string;
  onChange(value: string): void;
  value: string | null;
  menuOptions?: { label: string; value: string }[];
  sliderValues: { min: number; max: number };
  previewSubProperty?: PreviewableSubProperty;
  autofocus?: boolean;
};

type ToggleGroupOption<Value extends string> = {
  label: string;
  value: Value;
  hasSingleInput: boolean;
  metrics: string[];
  inputs: ToggleGroupInput[];
  singleInput?: Partial<ToggleGroupInput> & {
    buttons: { label: React.ReactNode; onClick: () => void }[];
  };
};

type TransformOriginObject = {
  transformOriginX: string;
  transformOriginY: string;
  transformOriginZ: string;
};

const Transform2d3dModifier = () => {
  const applyComponentAction = useApplyComponentAction();
  const [popover, setPopover] = React.useState<{
    isOpen: boolean;
    type: PopoverType;
  }>({
    isOpen: false,
    type: "content",
  });
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const transformObjectOrNull = useEditorSelector(selectTransform);
  const draftElementTransform = merge(
    {},
    transformDefaultValues,
    transformObjectOrNull,
  );
  const draftElementAlchemyRotation = useEditorSelector(selectRotation);

  if (draftElementAlchemyRotation) {
    draftElementTransform.rotateZ = draftElementAlchemyRotation;
  }

  const draftElementTransformOrigin =
    useEditorSelector(selectTransformOrigin) ?? "";
  const draftElementTransformOriginObject = getTransformOriginObject(
    String(draftElementTransformOrigin),
  );

  const transformTitle = getReadableTransformValue(
    getTransformStyleString(draftElementTransform),
  );
  const handleApplyTransform = (value: TransformObject) => {
    const actions: UseApplyComponentActionType[] = [
      {
        type: "setStyles",
        componentId: draftComponentId,
        value: { __transform: value },
      },
    ];
    // Note (Sebas, 2022-07-20): The __alchemyRotation value is not necessary anymore.
    // If it exists we need to reset it to null
    if (draftElementAlchemyRotation) {
      actions.push({
        componentId: draftComponentId,
        type: "setStyles",
        value: {
          __alchemyRotation:
            styleAttributeToEditorData.__alchemyRotation.defaultValue,
        },
      });
    }
    applyComponentAction({
      componentId: draftComponentId,
      type: "applyCompositeAction",
      value: actions,
    });
  };

  const handleApplyTransformOrigin = (value: string | null) => {
    applyComponentAction({
      componentId: draftComponentId,
      type: "setStyles",
      value: {
        transformOrigin: value,
      },
    });
  };
  const handleOpenPopover = (type: PopoverType) => {
    setPopover({ isOpen: !popover.isOpen, type });
  };

  const handleChange = (value: string | TransformObject, type: PopoverType) => {
    if (type === "origin" && typeof value === "string") {
      handleApplyTransformOrigin(value);
    } else if (typeof value !== "string") {
      handleApplyTransform(value);
    }
  };

  return (
    <ModifierGroup
      title="2D/3D Transform"
      isDefaultOpen={
        !isEqual(draftElementTransform, transformDefaultValues) ||
        draftElementTransformOrigin !== ""
      }
    >
      <div className="flex flex-col gap-1">
        <LabeledControl label="Transform" size="sm">
          <SelectionIndicator
            title={transformTitle}
            onClick={() => handleOpenPopover("content")}
            onReset={() => handleApplyTransform(transformDefaultValues)}
            startEnhancer={
              <BsArrowsMove size={12} className="text-slate-400" />
            }
            endEnhancer={
              transformTitle.length > 0 && (
                <RiCloseFill
                  size={12}
                  className="cursor-pointer text-slate-400"
                  onClick={() => handleApplyTransform(transformDefaultValues)}
                />
              )
            }
          />
        </LabeledControl>
        <TransformPopover
          type={popover.type}
          isOpen={popover.isOpen}
          setIsOpen={(isOpen: boolean) =>
            setPopover({ isOpen, type: popover.type })
          }
          handleChange={handleChange}
          transformOrigin={draftElementTransformOriginObject}
          transform={draftElementTransform}
        />
        <LabeledControl label="Origin" size="sm">
          <SelectionIndicator
            title={draftElementTransformOrigin}
            onClick={() => handleOpenPopover("origin")}
            onReset={() => handleApplyTransformOrigin(null)}
            startEnhancer={<BsGrid3X3 size={12} className="text-slate-400" />}
            endEnhancer={
              draftElementTransformOrigin !== "" && (
                <RiCloseFill
                  size={12}
                  className="cursor-pointer text-slate-400"
                  onClick={() => handleApplyTransformOrigin(null)}
                />
              )
            }
          />
        </LabeledControl>
      </div>
    </ModifierGroup>
  );
};

const TransformPopover: React.FC<
  React.PropsWithChildren<{
    type: "origin" | "content";
    isOpen: boolean;
    setIsOpen(isOpen: boolean): void;
    handleChange(value: string | TransformObject, type: PopoverType): void;
    transformOrigin: TransformOriginObject;
    transform: TransformObject;
  }>
> = ({
  type,
  isOpen = false,
  setIsOpen,
  transformOrigin,
  transform,
  handleChange,
}) => {
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);

  return (
    <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
      <Popover.Content
        title={type === "content" ? "Transform" : "Transform Origin"}
        shouldPreventDefaultOnInteractOutside={areModalsOpen}
      >
        {type === "content" ? (
          <TransformContentPopover
            values={transform}
            handleChange={handleChange}
          />
        ) : (
          <TransformOriginPopover
            values={transformOrigin}
            handleChange={handleChange}
          />
        )}
      </Popover.Content>
      <Popover.Anchor className="relative top-0 left-0" />
    </Popover>
  );
};

const TransformContentPopover: React.FC<
  React.PropsWithChildren<{
    values: TransformObject;
    handleChange(value: TransformObject, type: PopoverType): void;
  }>
> = ({ values, handleChange }) => {
  const [verticalPosition, horizontalPosition] = useEditorSelector(
    selectDraftComponentPositionKey,
  );
  const shouldDisableTranslateTab =
    verticalPosition === "center" || horizontalPosition === "center";
  const activeTabInitialValue = shouldDisableTranslateTab ? "scale" : "move";
  const [activeTab, setActiveTab] = React.useState<Transform>(
    activeTabInitialValue,
  );

  const onPropertyChange = (value: string, type: string) => {
    const updatedProps = { ...values, [type]: value };
    handleChange(updatedProps, "content");
  };

  const onMultiplePropertyChange = (types: string[], value: string) => {
    const newValues = types.reduce(
      (accum: Record<string, string>, currentValue: string) => {
        accum[currentValue] = value;
        return accum;
      },
      {},
    );
    handleChange({ ...values, ...newValues }, "content");
  };

  const calculateValue = (
    value: string,
    operation: "add" | "substract" | "multiply",
    operand: number,
    unit?: string,
  ) => {
    const operations = {
      add: `${(Number.parseInt(value, 10) + operand).toString()}`,
      substract: `${(Number.parseInt(value, 10) - operand).toString()}`,
      multiply: `${(Number.parseInt(value, 10) * operand).toString()}`,
    };
    return `${operations[operation]}${unit ?? ""}`;
  };

  const scaleSharedMenuOptions = [
    { label: "110%", value: "110%" },
    { label: "120%", value: "120%" },
    { label: "130%", value: "130%" },
  ];

  const rotateSharedMenuOptions = [
    { label: "90deg", value: "90deg" },
    { label: "180deg", value: "180deg" },
    { label: "270deg", value: "270deg" },
  ];

  const getScaleSimplifiedValue = () => {
    if (values.scaleX !== values.scaleY) {
      return "100%";
    } else if (values.scaleX.includes("%")) {
      return values.scaleX;
    }
    return calculateValue(values.scaleX, "multiply", 100);
  };

  const toggleGroupOptions = [
    {
      label: "Move",
      value: "move",
      hasSingleInput: false,
      metrics: CSS_LENGTH_TYPES,
      inputs: [
        {
          startEnhancer: "X",
          resetValue: transformDefaultValues.translateX,
          anchorValue: transformDefaultValues.translateX,
          placeholder: transformDefaultValues.translateX,
          onChange: (v) => onPropertyChange(v, "translateX"),
          value: values.translateX,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.translateX },
          ],
          sliderValues: { min: -100, max: 100 },
          previewSubProperty: "translateX",
          autofocus: activeTab === "move",
        },
        {
          startEnhancer: "Y",
          resetValue: transformDefaultValues.translateY,
          anchorValue: transformDefaultValues.translateY,
          placeholder: transformDefaultValues.translateY,
          onChange: (v) => onPropertyChange(v, "translateY"),
          value: values.translateY,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.translateY },
          ],
          sliderValues: { min: -100, max: 100 },
          previewSubProperty: "translateY",
        },
        {
          startEnhancer: "Z",
          resetValue: transformDefaultValues.translateZ,
          anchorValue: transformDefaultValues.translateZ,
          placeholder: transformDefaultValues.translateZ,
          onChange: (v) => onPropertyChange(v, "translateZ"),
          value: values.translateZ,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.translateZ },
          ],
          sliderValues: { min: -100, max: 100 },
          previewSubProperty: "translateZ",
        },
      ],
    },
    {
      label: "Scale",
      value: "scale",
      metrics: ["%"],
      hasSingleInput: true,
      singleInput: {
        startEnhancer: (
          <MdOutlineZoomOutMap size={12} className="text-slate-400" />
        ),
        resetValue: transformDefaultValues.scaleX,
        anchorValue: transformDefaultValues.scaleX,
        placeholder: transformDefaultValues.scaleX,
        onChange: (v) => onMultiplePropertyChange(["scaleX", "scaleY"], v),
        value: getScaleSimplifiedValue(),
        menuOptions: [
          { label: "Reset", value: transformDefaultValues.scaleX },
          ...scaleSharedMenuOptions,
        ],
        previewSubProperty: "scaleXY",
        buttons: [
          {
            label: <FiMinus size={12} className="text-slate-600" />,
            onClick: () =>
              onMultiplePropertyChange(
                ["scaleX", "scaleY"],
                calculateValue(values.scaleX, "substract", 5, "%"),
              ),
          },
          {
            label: <FiPlus size={12} className="text-slate-600" />,
            onClick: () =>
              onMultiplePropertyChange(
                ["scaleX", "scaleY"],
                calculateValue(values.scaleX, "add", 5, "%"),
              ),
          },
        ],
      },
      inputs: [
        {
          startEnhancer: "X",
          resetValue: transformDefaultValues.scaleX,
          anchorValue: transformDefaultValues.scaleX,
          placeholder: transformDefaultValues.scaleX,
          onChange: (v) => onPropertyChange(v, "scaleX"),
          value: values.scaleX.includes("%")
            ? values.scaleX
            : calculateValue(values.scaleX, "multiply", 100),
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.scaleX },
            ...scaleSharedMenuOptions,
          ],
          sliderValues: { min: 0, max: 200 },
          previewSubProperty: "scaleX",
        },
        {
          startEnhancer: "Y",
          resetValue: transformDefaultValues.scaleY,
          anchorValue: transformDefaultValues.scaleY,
          placeholder: transformDefaultValues.scaleY,
          onChange: (v) => onPropertyChange(v, "scaleY"),
          value: values.scaleY.includes("%")
            ? values.scaleY
            : calculateValue(values.scaleY, "multiply", 100),
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.scaleY },
            ...scaleSharedMenuOptions,
          ],
          sliderValues: { min: 0, max: 200 },
          previewSubProperty: "scaleY",
        },
        {
          startEnhancer: "Z",
          resetValue: transformDefaultValues.scaleZ,
          anchorValue: transformDefaultValues.scaleZ,
          placeholder: transformDefaultValues.scaleZ,
          onChange: (v) => onPropertyChange(v, "scaleZ"),
          value: values.scaleZ.includes("%")
            ? values.scaleZ
            : calculateValue(values.scaleZ, "multiply", 100),
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.scaleZ },
            ...scaleSharedMenuOptions,
          ],
          sliderValues: { min: 0, max: 200 },
          previewSubProperty: "scaleZ",
        },
      ],
    },
    {
      label: "Rotate",
      value: "rotate",
      hasSingleInput: true,
      metrics: ["deg", "rad", "turn"],
      singleInput: {
        startEnhancer: <FiRotateCw size={12} className="text-slate-400" />,
        resetValue: transformDefaultValues.rotateZ,
        anchorValue: transformDefaultValues.rotateZ,
        placeholder: transformDefaultValues.rotateZ,
        onChange: (v) => onPropertyChange(v, "rotateZ"),
        value: values.rotateZ,
        menuOptions: [
          { label: "Reset", value: transformDefaultValues.rotateZ },
          ...rotateSharedMenuOptions,
        ],
        sliderValues: { min: 0, max: 360 },
        previewSubProperty: "rotateZ",
        buttons: [
          {
            label: <AiOutlineRotateLeft size={12} className="text-slate-600" />,
            onClick: () =>
              onPropertyChange(
                calculateValue(values.rotateZ, "substract", 90, "deg"),
                "rotateZ",
              ),
          },
          {
            label: (
              <AiOutlineRotateRight size={12} className="text-slate-600" />
            ),
            onClick: () =>
              onPropertyChange(
                calculateValue(values.rotateZ, "add", 90, "deg"),
                "rotateZ",
              ),
          },
        ],
      },
      inputs: [
        {
          startEnhancer: "X",
          resetValue: transformDefaultValues.rotateX,
          anchorValue: transformDefaultValues.rotateX,
          placeholder: transformDefaultValues.rotateX,
          onChange: (v) => onPropertyChange(v, "rotateX"),
          value: values.rotateX,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.rotateX },
            ...rotateSharedMenuOptions,
          ],
          sliderValues: { min: 0, max: 360 },
          previewSubProperty: "rotateX",
        },
        {
          startEnhancer: "Y",
          resetValue: transformDefaultValues.rotateY,
          anchorValue: transformDefaultValues.rotateY,
          placeholder: transformDefaultValues.rotateY,
          onChange: (v) => onPropertyChange(v, "rotateY"),
          value: values.rotateY,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.rotateY },
            ...rotateSharedMenuOptions,
          ],
          sliderValues: { min: 0, max: 360 },
          previewSubProperty: "rotateY",
        },
        {
          startEnhancer: "Z",
          resetValue: transformDefaultValues.rotateZ,
          anchorValue: transformDefaultValues.rotateZ,
          placeholder: transformDefaultValues.rotateZ,
          onChange: (v) => onPropertyChange(v, "rotateZ"),
          value: values.rotateZ,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.rotateZ },
            ...rotateSharedMenuOptions,
          ],
          sliderValues: { min: 0, max: 360 },
          previewSubProperty: "rotateZ",
        },
      ],
    },
    {
      label: "Skew",
      value: "skew",
      metrics: CSS_ANGLE_TYPES,
      hasSingleInput: false,
      inputs: [
        {
          startEnhancer: "X",
          resetValue: transformDefaultValues.skewX,
          anchorValue: transformDefaultValues.skewX,
          placeholder: transformDefaultValues.skewX,
          onChange: (v) => onPropertyChange(v, "skewX"),
          value: values.skewX,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.skewX },
          ],
          sliderValues: { min: 0, max: 45 },
          previewSubProperty: "skewX",
          autofocus: activeTab === "skew",
        },
        {
          startEnhancer: "Y",
          resetValue: transformDefaultValues.skewY,
          anchorValue: transformDefaultValues.skewY,
          placeholder: transformDefaultValues.skewY,
          onChange: (v) => onPropertyChange(v, "skewY"),
          value: values.skewY,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.skewY },
          ],
          sliderValues: { min: 0, max: 45 },
          previewSubProperty: "skewY",
        },
      ],
    },
  ] satisfies ToggleGroupOption<Transform>[];

  const activeGroupOption = toggleGroupOptions.find(
    (option) => option.value === activeTab,
  );

  return (
    <div className="flex flex-col gap-2">
      <ToggleGroup
        allowsDeselect={false}
        type="single"
        options={toggleGroupOptions}
        value={activeTab}
        onChange={(value) => {
          setActiveTab(value);
        }}
        style={{ width: "100%" }}
      />
      {activeGroupOption?.hasSingleInput && (
        <SimplifiedInput
          key={activeGroupOption.label}
          input={activeGroupOption.singleInput!}
          metrics={activeGroupOption.metrics}
        />
      )}
      <InputGroup hasSingleInput={activeGroupOption!.hasSingleInput}>
        <div className="flex flex-col gap-2">
          {activeGroupOption?.inputs.map((input, idx) => (
            <InputWithSlider
              key={idx}
              input={input}
              metrics={activeGroupOption.metrics}
            />
          ))}
        </div>
      </InputGroup>
    </div>
  );
};

const InputWithSlider: React.FC<{
  input: ToggleGroupInput;
  metrics: string[];
}> = ({
  input: {
    anchorValue,
    menuOptions,
    onChange,
    placeholder,
    resetValue,
    startEnhancer,
    value,
    sliderValues,
    previewSubProperty,
    autofocus,
  },
  metrics,
}) => (
  <div className="flex gap-2">
    <LengthInputSelector
      metrics={metrics}
      className="col-span-1"
      startEnhancer={() => <span className="text-xs">{startEnhancer!}</span>}
      field="blur"
      resetValue={resetValue}
      anchorValue={anchorValue}
      placeholder={placeholder}
      draggingType={DraggingTypes.Vertical}
      value={value}
      onChange={(value: string) => onChange(value)}
      menuOptions={menuOptions}
      allowsNegativeValue
      previewProperty="transform"
      previewSubProperty={previewSubProperty}
      autofocus={autofocus}
    />
    <Slider
      value={getIntegerValueFromString(value)}
      debounce
      minimum={sliderValues.min}
      maximum={sliderValues.max}
      onChange={(value: number) => onChange(`${value.toString()}${metrics[0]}`)}
    />
  </div>
);

const TransformOriginPopover: React.FC<
  React.PropsWithChildren<{
    values: TransformOriginObject;
    handleChange(value: string, type: PopoverType): void;
  }>
> = ({ values, handleChange }) => {
  const transformOriginDefaultValues =
    styleAttributeToEditorData.transformOrigin.defaultValue.split(" ");
  const transformOriginX = transformOriginDefaultValues[0]!;
  const transformOriginY = transformOriginDefaultValues[1]!;
  const transformOriginZ = transformOriginDefaultValues[2]!;
  const transformOriginInputs = [
    {
      title: "transformOriginX" as const,
      resetValue: transformOriginX,
      anchorValue: transformOriginY,
      placeholder: transformOriginZ,
      startEnhancer: "X",
      metrics: CSS_LENGTH_TYPES,
      menuOptions: [
        {
          label: "Reset",
          value: transformOriginX,
        },
        {
          label: "Left",
          value: "0%",
        },
        {
          label: "Center",
          value: "50%",
        },
        {
          label: "Right",
          value: "100%",
        },
      ],
      autofocus: true,
    },
    {
      title: "transformOriginY" as const,
      resetValue: transformOriginY,
      anchorValue: transformOriginY,
      placeholder: transformOriginY,
      startEnhancer: "Y",
      metrics: CSS_LENGTH_TYPES,
      menuOptions: [
        {
          label: "Reset",
          value: transformOriginY,
        },
        {
          label: "Top",
          value: "0%",
        },
        {
          label: "Center",
          value: "50%",
        },
        {
          label: "Bottom",
          value: "100%",
        },
      ],
    },
    {
      title: "transformOriginZ" as const,
      resetValue: transformOriginZ,
      anchorValue: transformOriginZ,
      placeholder: transformOriginZ,
      startEnhancer: "Z",
      metrics: CSS_LENGTH_TYPES,
      menuOptions: [
        {
          label: "Reset",
          value: transformOriginZ,
        },
      ],
    },
  ];

  const internalOnChange = (
    value: string,
    inputName: "transformOriginX" | "transformOriginY" | "transformOriginZ",
  ) => {
    const newValues = { ...values, [inputName]: value };
    handleChange(getTransformOriginString(newValues), "origin");
  };

  return (
    <div className="flex flex-col gap-2">
      {transformOriginInputs.map(
        ({
          title,
          anchorValue,
          placeholder,
          resetValue,
          startEnhancer,
          menuOptions,
          metrics,
          autofocus = false,
        }) => (
          <LengthInputSelector
            key={title}
            metrics={metrics}
            className="col-span-1"
            startEnhancer={() => (
              <span className="text-xs">{startEnhancer}</span>
            )}
            field={title}
            resetValue={resetValue}
            anchorValue={anchorValue}
            placeholder={placeholder}
            draggingType={DraggingTypes.Vertical}
            value={values[title]}
            onChange={(value: string) => internalOnChange(value, title)}
            menuOptions={menuOptions}
            previewProperty="transformOrigin"
            previewSubProperty={title}
            autofocus={autofocus}
          />
        ),
      )}
    </div>
  );
};

const InputGroup: React.FC<
  React.PropsWithChildren<{ hasSingleInput: boolean }>
> = ({ children, hasSingleInput }) => {
  if (hasSingleInput) {
    return (
      <ModifierGroup
        title="Advanced"
        titleClassName="font-regular text-slate-600"
      >
        {children}
      </ModifierGroup>
    );
  }
  return <>{children}</>;
};

const SimplifiedInput: React.FC<{
  input: Partial<ToggleGroupInput> & {
    buttons: { label: React.ReactNode; onClick: () => void }[];
  };
  metrics: string[];
}> = ({
  input: {
    anchorValue,
    buttons,
    onChange,
    placeholder,
    resetValue,
    startEnhancer,
    value,
    menuOptions,
    previewSubProperty,
  },
  metrics,
}) => {
  return (
    <div className="flex flex-row items-end justify-between">
      <div className="mr-1">
        <LengthInputSelector
          metrics={metrics}
          className="col-span-1"
          startEnhancer={() => <span className="text-xs">{startEnhancer}</span>}
          field=""
          resetValue={resetValue}
          anchorValue={anchorValue}
          placeholder={placeholder}
          draggingType={DraggingTypes.Vertical}
          value={value!}
          onChange={onChange!}
          menuOptions={menuOptions}
          previewProperty="transform"
          previewSubProperty={previewSubProperty}
          autofocus
        />
      </div>
      <ButtonGroupComponent options={buttons} />
    </div>
  );
};

const getTransformOriginObject = (value: string) => {
  const stringArray = value.split(" ");
  const [transformOriginX, transformOriginY, transformOriginZ] = stringArray;
  const transformOriginDefaultValues =
    styleAttributeToEditorData.transformOrigin.defaultValue.split(" ");
  const defaultTransformOriginX = transformOriginDefaultValues[0]!;
  const defaultTransformOriginY = transformOriginDefaultValues[1]!;
  const defaultTransformOriginZ = transformOriginDefaultValues[2]!;
  return {
    transformOriginX: transformOriginX || defaultTransformOriginX,
    transformOriginY: transformOriginY || defaultTransformOriginY,
    transformOriginZ: transformOriginZ || defaultTransformOriginZ,
  };
};

const getTransformOriginString = (values: TransformOriginObject) => {
  return Object.values(values).join(" ").trim();
};

export default Transform2d3dModifier;

import * as React from "react";

import {
  RenderedLiquidContext,
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../shared/runtime-context";
import type { ShopifyComponentCommonProps } from "../../shared/types";
import { getAlchemyGlobalPaintContext } from "../../shared/Window";
import RenderComponentPlaceholder from "./RenderComponentPlaceholder";
import ReploLiquidChunk from "./ReploLiquid/ReploLiquidChunk";

interface SharedShopifyLiquidProps extends ShopifyComponentCommonProps {
  liquidSource: string | null;
  placeholder?: string;
  repeatedIndexPath?: string;
  forceEditorPlaceholder: boolean;
  placeholderStyles?: React.CSSProperties;
}

export const SharedShopifyLiquid = React.forwardRef(
  function SharedShopifyLiquidWithRef(
    {
      liquidSource,
      componentId,
      componentAttributes,
      isLiquidSupported,
      placeholder = "Your custom Shopify Liquid content will appear here.",
      repeatedIndexPath,
      forceEditorPlaceholder,
      placeholderStyles,
    }: React.PropsWithChildren<SharedShopifyLiquidProps>,
    ref: React.Ref<HTMLDivElement>,
  ) {
    const {
      cache: renderedLiquidCache,
      requestRenderLiquid,
      requestsInProgress: liquidRequestsInProgress,
    } = useRuntimeContext(RenderedLiquidContext);
    const { isEditorApp, isPublishing } = useRuntimeContext(
      RenderEnvironmentContext,
    );

    // Note (Fran, 2023-03-21): We need to add the repeated index here to keep all the components
    // and not hydrate any of the repeated components
    const prerenderedNodeId = repeatedIndexPath
      ? `${componentId}-${repeatedIndexPath}`
      : componentId;
    const globalContext = getAlchemyGlobalPaintContext();
    const [initialLiquid] = React.useState(liquidSource);

    React.useEffect(() => {
      // NOTE (Martin, 2023-02-10): If there is liquid source already, lets render it.
      if (liquidSource) {
        requestRenderLiquid?.(liquidSource);
      }
    }, [liquidSource, requestRenderLiquid]);

    if (isLiquidSupported === false) {
      return (
        <div {...componentAttributes}>
          <RenderComponentPlaceholder
            style={placeholderStyles}
            title="Shopify Liquid content is not enabled for Shopify Articles"
          />
        </div>
      );
    }

    const prerenderedNodeExists = Boolean(
      globalContext?.prerenderedNodes?.[prerenderedNodeId],
    );
    const isEditorAndForcingPlaceholder = isEditorApp && forceEditorPlaceholder;
    const shouldShowPlaceholder =
      isEditorApp &&
      !isPublishing &&
      (!prerenderedNodeExists || initialLiquid !== liquidSource);

    if (shouldShowPlaceholder || isEditorAndForcingPlaceholder) {
      const renderedLiquid =
        liquidSource != null && renderedLiquidCache[liquidSource];
      const isLoading =
        liquidSource != null && liquidRequestsInProgress[liquidSource];
      if (renderedLiquid && !isEditorAndForcingPlaceholder) {
        return (
          <div
            {...componentAttributes}
            ref={ref}
            data-replo-editor-liquid={true}
            dangerouslySetInnerHTML={{ __html: renderedLiquid }}
          />
        );
      }

      return (
        <div {...componentAttributes}>
          <RenderComponentPlaceholder
            style={placeholderStyles}
            isLoading={isLoading}
            title={placeholder}
          />
        </div>
      );
    }

    let content = (
      <div
        style={{ width: "100%", height: "100%" }}
        data-alchemy-prerendered-placeholder
        dangerouslySetInnerHTML={{
          __html: isPublishing && liquidSource ? liquidSource : "",
        }}
      />
    );

    // NOTE (Matt 2024-07-02): We only wrap in a liquid chunk when publishing,
    // because that is the only time we'll be using the liquidSource.
    // If we have gotten to this point in the function, then we are either
    // generating the pre-hydrated html/liquid code, or we are hydrating
    // the element on the published page.
    if (isPublishing) {
      content = <ReploLiquidChunk>{content}</ReploLiquidChunk>;
    }

    return (
      <div
        {...componentAttributes}
        data-alchemy-prerendered-component-id={prerenderedNodeId}
        ref={ref}
      >
        {content}
      </div>
    );
  },
);

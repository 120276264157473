// TODO (Noah, 2022-02-24): These constants are used for Canvas offset coordinate
// transforms, but it's kind of hacky that we need them at all - I think we should
// probably just be able to use the Canvas's offsetTop/Left, or something
export const leftBarWidth = 300;
export const minimumLeftBarWidth = 275;
export const rightBarWidth = 250;
export const headerHeight = 60;
export const componentPreviewPad = 20;
// NOTE (Juan, 2023-06-06) This zIndex number ensures that other browser extensions, like Loom, are not being blocked by Replo modals.
export const maxZIndex = 2_147_482_645;

// Useful for when we need stable references to empty non-primitive values for
// when over-rendering is a problem
export const emptyObject = {};
export const emptyArray = [];

// NOTE (Fran 2024-04-22): This is the maximum length of an input string, check this will avoid
// the editor freeze because of a huge string. Example: a huge svg source.
export const MAX_STYLE_PROPERTY_VALUE_LENGTH = 10_000;

export const TIPTAP_EDITOR_SCROLLABLE_DIV_ID = "tipTapScrollableDiv";

export const NEW_STATE_NAME = "New State";

import * as React from "react";
import { Svg, type SvgProps } from "replo-utils/react/components";

export const SvgLogoBadge = (props: SvgProps) => (
  <Svg width="1em" height="1em" viewBox="0 0 225 225" fill="none" {...props}>
    <rect width={225} height={225} fill="currentColor" rx={46} />
    <path
      fill="#fff"
      d="M62.816 162.7h49.943v-49.943a49.893 49.893 0 0 0-35.306 14.624A50.005 50.005 0 0 0 62.816 162.7ZM62.816 112.757V62.815h49.943a50.134 50.134 0 0 1-14.636 35.337 49.6 49.6 0 0 1-16.192 10.841 49.55 49.55 0 0 1-19.115 3.764ZM112.76 112.758h49.943V62.815a49.92 49.92 0 0 0-35.3 14.625 50.005 50.005 0 0 0-14.643 35.318Z"
    />
  </Svg>
);
export default SvgLogoBadge;

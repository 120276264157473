import Border from "@common/icons/Border";
import BorderLeft from "@common/icons/BorderLeft";
import Badge from "@editor/components/common/designSystem/Badge";
import Selectable from "@editor/components/common/designSystem/Selectable";
import ToggleGroup from "@editor/components/common/designSystem/ToggleGroup";
import Tooltip from "@editor/components/common/designSystem/Tooltip";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useModal } from "@editor/hooks/useModal";
import {
  selectActiveBorderSideColor,
  selectActiveBorderSideStyle,
  selectActiveBorderSideWidth,
  selectBorderColor,
  selectBorderRadius,
  selectBorderWidth,
  selectDraftComponentId,
  selectedDraftComponentIsRoot,
  selectHasBorderStyles,
  selectInitialBorderSide,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DraggingDirections, DraggingTypes } from "@editor/utils/editor";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import ModifiersInfo from "@editorComponents/ModifiersInfo";
import ModifierGroup from "@editorExtras/ModifierGroup";
import { DynamicColorSelector } from "@editorModifiers/DynamicColorModifier";
import LengthInputModifier, {
  LengthInputSelector,
} from "@editorModifiers/LengthInputModifier";
import RadiusCornerIcon from "@svg/corner-rounding";
import InsetIcon from "@svg/inset";
import startCase from "lodash-es/startCase";
import * as React from "react";
import {
  BsBorder,
  BsBorderWidth,
  BsDashLg,
  BsFullscreen,
  BsThreeDots,
} from "react-icons/bs";
import { CgBorderStyleDashed } from "react-icons/cg";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { usePrevious } from "replo-runtime/shared/hooks/usePrevious";
import type { BorderSuffix } from "replo-runtime/shared/styleAttribute";
import { allCSSSideNames } from "replo-runtime/shared/utils/css";
import type { PreviewableProperty } from "replo-runtime/shared/utils/preview";
import { CSS_LENGTH_TYPES } from "replo-runtime/shared/utils/units";

export type BorderProperties = "Color" | "Width" | "Style";

const BorderModifier = () => {
  const hasBorderStyles = useEditorSelector(selectHasBorderStyles);
  const borderRadiusValues = useEditorSelector(selectBorderRadius);

  const hasBorderRadius = borderRadiusValues.some((borderRadius) => {
    return borderRadius !== null && borderRadius !== "0px";
  });

  return (
    <ModifierGroup
      title="Border"
      titleEnhancer={<ModifiersInfo documentationType="border" />}
      isDefaultOpen={hasBorderStyles || hasBorderRadius}
    >
      <BorderControls />
    </ModifierGroup>
  );
};

const iconSize = 14;

const radiusOptions = [
  {
    value: "",
    label: "Reset",
  },
  {
    value: "4px",
    label: "4px",
  },
  {
    value: "8px",
    label: "8px",
  },
  {
    value: "12px",
    label: "12px",
  },
];

const widthOptions = [
  {
    value: "",
    label: "Reset",
  },
  {
    value: "1px",
    label: "1px",
  },
  {
    value: "2px",
    label: "2px",
  },
];

const sideOptions = [
  {
    value: "",
    label: <Border size={iconSize} className="rounded-sm text-slate-200" />,
    tooltipContent: "All Sides",
  },
  {
    value: "Left",
    label: <BorderLeft size={iconSize} className="rounded-sm text-slate-200" />,
    tooltipContent: "Left Border",
  },
  {
    value: "Top",
    label: (
      <BorderLeft
        size={iconSize}
        className="rotate-90 rounded-sm text-slate-200"
      />
    ),
    tooltipContent: "Top Border",
  },
  {
    value: "Right",
    label: (
      <BorderLeft
        size={iconSize}
        className="rotate-180 rounded-sm text-slate-200"
      />
    ),
    tooltipContent: "Right Border",
  },
  {
    value: "Bottom",
    label: (
      <BorderLeft
        size={iconSize}
        className="rotate-[270deg] rounded-sm text-slate-200"
      />
    ),
    tooltipContent: "Bottom Border",
  },
];

const borderOptions = [
  {
    value: "solid",
    label: getCursorLabel("Solid", <BsDashLg size={12} />),
  },
  {
    value: "dashed",
    label: getCursorLabel("Dashed", <CgBorderStyleDashed size={12} />),
  },
  {
    value: "dotted",
    label: getCursorLabel("Dotted", <BsThreeDots size={12} />),
  },
  {
    value: "double",
    label: getCursorLabel("Double", <HiOutlineMenuAlt4 size={12} />),
  },
  {
    value: "inset",
    label: getCursorLabel("Inset", <InsetIcon style={{ fontSize: 12 }} />),
  },
  {
    value: "none",
    label: getCursorLabel("None", <BsBorder size={12} />),
  },
];

const borderRadiusIndividualOptions = [
  {
    key: "borderTopLeftRadius",
    enhancerClassName: "-rotate-90",
  },
  {
    key: "borderTopRightRadius",
    enhancerClassName: "",
  },
  {
    key: "borderBottomLeftRadius",
    enhancerClassName: "-rotate-180",
  },
  {
    key: "borderBottomRightRadius",
    enhancerClassName: "rotate-90",
  },
];

function createAllSidesBorderProperty(
  property: BorderProperties,
  value: string | null,
) {
  const style: Record<string, string | null> = {};

  allCSSSideNames("border", property, { includeShorthand: false }).forEach(
    (key) => {
      style[key] = value;
    },
  );
  // Note (Noah, 2022-08-08): There's an issue with borderStyle where many
  // components on production have borderStyle set, which overrides the
  // indivdual border side properties. We should run some sort of migration
  // to remove these, but for now we always set the shorthand property to
  // none when we set all sides.
  style[`border${startCase(property)}`] = null;

  return style;
}

const prepareBorderStyles = (
  activeSide: BorderSuffix | null,
  width: string | null = styleAttributeToEditorData.borderWidth.defaultValue,
  color: string | null = styleAttributeToEditorData.borderColor.defaultValue,
  borderStyle: string | null = styleAttributeToEditorData.borderStyle
    .defaultValue,
) => {
  const borderStyles =
    activeSide !== ""
      ? {
          [`border${activeSide}Width`]: width,
          [`border${activeSide}Color`]: color,
          [`border${activeSide}Style`]: borderStyle,
        }
      : {
          ...createAllSidesBorderProperty("Width", width),
          ...createAllSidesBorderProperty("Color", color),
          ...createAllSidesBorderProperty("Style", borderStyle),
        };

  return borderStyles;
};

const BorderControls: React.FC = () => {
  const initialBorderSide = useEditorSelector(selectInitialBorderSide);
  const [activeSide, setActiveSide] = useOverridableState(initialBorderSide);
  const [isExpandedBorderRadius, setIsExpandedBorderRadius] =
    React.useState(false);
  const isRoot = useEditorSelector(selectedDraftComponentIsRoot);

  return (
    <>
      <BorderSideToggles
        value={activeSide}
        onChange={(newValue: BorderSuffix | null) => {
          setActiveSide(newValue || "");
        }}
      />
      <BorderStyleSelectable activeSide={activeSide} />
      <BorderColorControl activeSide={activeSide} />
      <div className="mt-1 flex w-full flex-row">
        <BorderWidthControl activeSide={activeSide} />
        {/* Note (Noah, 2023-02-16, REPL-5519): Don't allow setting border radius on the root component,
        because it sets overflow to hidden and thus does not allow sticky positioning to work */}
        {!isRoot && (
          <BorderRadiusControls
            isExpandedBorderRadius={isExpandedBorderRadius}
            setIsExpandedBorderRadius={setIsExpandedBorderRadius}
          />
        )}
      </div>
      {!isRoot && isExpandedBorderRadius && <BorderRadiusIndividualControls />}
    </>
  );
};

const BorderSideToggles: React.FC<{
  value: BorderSuffix | null;
  onChange: (value: BorderSuffix | null) => void;
}> = ({ value, onChange }) => {
  return (
    <div className="mt-2">
      <ToggleGroup
        type="single"
        style={{ width: "100%" }}
        options={sideOptions}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

const BorderColorControl: React.FC<{
  activeSide: BorderSuffix | null;
}> = ({ activeSide }) => {
  const applyComponentAction = useApplyComponentAction();
  const modal = useModal();
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const value = useEditorSelector((state) => {
    return selectActiveBorderSideColor(state, activeSide);
  });
  const activeBorderSideWidth = useEditorSelector((state) => {
    return selectActiveBorderSideWidth(state, activeSide);
  });
  const activeBorderSideStyle = useEditorSelector((state) => {
    return selectActiveBorderSideStyle(state, activeSide);
  });
  const field = `style.border${activeSide}Color`;

  if (!draftComponentId) {
    return null;
  }

  const onChange = (value: string | null) => {
    const width = value ? activeBorderSideWidth || "1px" : null;
    const borderStyle = value ? activeBorderSideStyle || "solid" : "none";
    const styles = prepareBorderStyles(activeSide, width, value, borderStyle);

    applyComponentAction({
      type: "setStyles",
      value: styles,
    });
  };

  return (
    <div className="modifier mt-2 flex flex-col items-center justify-center">
      <DynamicColorSelector
        allowsGradientSelection={false}
        value={value ?? null}
        hasDynamicData
        field={field}
        popoverTitle="Border Color"
        onChange={onChange}
        openDynamicData={() => {
          modal.openModal({
            type: "dynamicDataModal",
            props: {
              requestType: "prop",
              targetType: DynamicDataTargetType.TEXT_COLOR,
              referrerData: {
                type: "callback",
                onChange: (value: string) => {
                  onChange(value);
                },
              },
              initialPath: value ? getPathFromVariable(value) : undefined,
            },
          });
        }}
        onRemove={() => onChange(null)}
        componentId={draftComponentId}
      />
    </div>
  );
};

function getCursorLabel(text: string, label: React.ReactNode) {
  return (
    <span className="flex items-center w-full gap-2 text-xs">
      <Tooltip content="Border Style" triggerAsChild>
        <div tabIndex={0}>
          <Badge
            isFilled
            backgroundColor="bg-blue-600"
            className="h-[18px] w-[18px]"
            foregroundColor="white"
          >
            {label}
          </Badge>
        </div>
      </Tooltip>
      {text}
    </span>
  );
}

const BorderWidthControl: React.FC<{
  activeSide: BorderSuffix | null;
}> = ({ activeSide }) => {
  const applyComponentAction = useApplyComponentAction();
  const value = useEditorSelector((state) => {
    return selectActiveBorderSideWidth(state, activeSide);
  });
  const activeBorderSideColor = useEditorSelector((state) => {
    return selectActiveBorderSideColor(state, activeSide);
  });
  const activeBorderSideStyle = useEditorSelector((state) => {
    return selectActiveBorderSideStyle(state, activeSide);
  });

  const onChange = (value: string | null) => {
    const width = value === "reset" ? "" : value;
    const color = activeBorderSideColor || "#000";
    const borderStyle = value ? activeBorderSideStyle || "solid" : "none";
    const styles = prepareBorderStyles(
      activeSide,
      width ?? null,
      color,
      borderStyle,
    );

    applyComponentAction({
      type: "setStyles",
      value: styles,
    });
  };

  return (
    <LengthInputSelector
      className="mr-1 w-28 flex-1"
      startEnhancer={() => (
        <Tooltip
          inheritCursor
          content={`Border ${activeSide} Width`}
          triggerAsChild
        >
          <span tabIndex={0}>
            <BsBorderWidth size={12} />
          </span>
        </Tooltip>
      )}
      draggingType={DraggingTypes.Vertical}
      minDragValues={{ px: 0 }}
      resetValue=""
      placeholder="auto"
      key={`border${activeSide}Width`}
      onChange={onChange}
      field={`style.border${activeSide}Width`}
      value={value}
      menuOptions={widthOptions}
      metrics={CSS_LENGTH_TYPES}
      previewProperty={`border${activeSide}Width` as PreviewableProperty}
    />
  );
};

const BorderStyleSelectable: React.FC<{
  activeSide: BorderSuffix | null;
}> = ({ activeSide }) => {
  const applyComponentAction = useApplyComponentAction();
  const value = useEditorSelector((state) => {
    return selectActiveBorderSideStyle(state, activeSide);
  });

  const activeBorderWidth = useEditorSelector((state) =>
    selectActiveBorderSideWidth(state, activeSide),
  );
  const activeBorderColor = useEditorSelector((state) =>
    selectActiveBorderSideColor(state, activeSide),
  );

  const borderColor = useEditorSelector(selectBorderColor);
  const borderWidth = useEditorSelector(selectBorderWidth);

  const previousBorderColor = usePrevious(borderColor);
  const previousBorderWidth = usePrevious(borderWidth);

  const handleChangeStyle = (value: string) => {
    if (value === "none") {
      applyComponentAction({
        type: "setStyles",
        value: {
          ...prepareBorderStyles(activeSide, "0px", null, value),
        },
      });
    } else {
      const borderColorToApply =
        activeBorderColor ??
        previousBorderColor?.[activeSide || "Top"] ??
        "#000000";
      const borderWidthToApply =
        activeBorderWidth ??
        previousBorderWidth?.[activeSide || "Top"] ??
        "1px";

      applyComponentAction({
        type: "setStyles",
        value: prepareBorderStyles(
          activeSide,
          String(borderWidthToApply),
          borderColorToApply,
          value,
        ),
      });
    }
  };

  return (
    <Selectable
      className="mt-2 flex-1"
      options={borderOptions}
      onSelect={handleChangeStyle}
      value={value ?? "none"}
      onRemove={() => {
        handleChangeStyle("none");
      }}
    />
  );
};

const BorderRadiusControls: React.FC<{
  setIsExpandedBorderRadius: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedBorderRadius: boolean;
}> = ({ setIsExpandedBorderRadius, isExpandedBorderRadius }) => {
  return (
    <div className="flex w-7/12 flex-row">
      <BorderRadiusSingleControl isDisabled={isExpandedBorderRadius} />
      <Tooltip content="Set Individual Corner Rounding" triggerAsChild>
        {/* TODO (Sebas, 2024-09-06): Change this to use the button component from Replo Design System */}
        <button
          type="button"
          className="text-subtle rounded grid transition ml-2 px-2 bg-subtle"
          onClick={() => setIsExpandedBorderRadius(!isExpandedBorderRadius)}
        >
          <BsFullscreen
            className="cursor-pointer place-self-center"
            size={12}
          />
        </button>
      </Tooltip>
    </div>
  );
};

const BorderRadiusSingleControl: React.FC<{ isDisabled?: boolean }> = ({
  isDisabled,
}) => {
  const applyComponentAction = useApplyComponentAction();

  return (
    <LengthInputModifier
      className="flex-1"
      isDisabled={isDisabled}
      placeholder="auto"
      // TODO (Noah, 2021-06-27): We use borderTopLeftRadius here because
      // this is the one that shows before you expand all the individual border
      // radii inputs. If we just use `borderRadius`, it gets reset since the
      // actual onchange sets the radii individually, as it should. However,
      // when the radii are open, this box doesn't make sense since its value
      // reflects only the top left radius. Maybe there's a better way to handle
      // this UX
      field="style.borderTopLeftRadius"
      draggingType={DraggingTypes.Vertical}
      allowsNegativeValue={false}
      minValues={{ px: 0 }}
      minDragValues={{ px: 0 }}
      resetValue="0px"
      startEnhancer={() => (
        <Tooltip inheritCursor content="Corner Rounding" triggerAsChild>
          <span tabIndex={0}>
            <RadiusCornerIcon />
          </span>
        </Tooltip>
      )}
      onChange={(newValue: string) => {
        applyComponentAction({
          type: "setStyles",
          value: {
            borderTopLeftRadius: newValue,
            borderTopRightRadius: newValue,
            borderBottomLeftRadius: newValue,
            borderBottomRightRadius: newValue,
          },
        });
      }}
      menuOptions={radiusOptions}
      metrics={CSS_LENGTH_TYPES}
      previewProperty="borderRadius"
    />
  );
};

const BorderRadiusIndividualControls: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  return (
    <div className="mt-2 grid grid-cols-2 grid-rows-2 gap-2">
      {borderRadiusIndividualOptions.map((option) => (
        <LengthInputModifier
          key={option.key}
          metrics={CSS_LENGTH_TYPES}
          placeholder="0"
          field={`style.${option.key}`}
          dragTrigger="startEnhancer"
          draggingDirection={DraggingDirections.Positive}
          draggingType={DraggingTypes.Vertical}
          resetValue="0px"
          allowsNegativeValue={false}
          minValues={{ px: 0 }}
          minDragValues={{ px: 0 }}
          startEnhancer={() => (
            <RadiusCornerIcon
              className={`text-xs text-gray-200 ${option.enhancerClassName}`}
            />
          )}
          onChange={(newValue) => {
            applyComponentAction({
              type: "setStyles",
              value: {
                [option.key]: newValue === "reset" ? null : newValue,
              },
            });
          }}
          menuOptions={radiusOptions}
          previewProperty={option.key as PreviewableProperty}
        />
      ))}
    </div>
  );
};

export default BorderModifier;

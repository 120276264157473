import type { EditorRootState } from "@editor/store";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { SharedStatePayload } from "replo-runtime/shared/types";
import { isFunction } from "replo-utils/lib/type-check";

export type PaintState = {
  sharedState: Record<string, any>;
  /**
   * This editorReadableState state is only used for editor control box purpose,
   * so this state should not be involved in painting, thus changing this state never should
   * cause a re-render
   */
  editorReadableState: Record<string, any>;
};

const initialState: PaintState = {
  sharedState: {},
  editorReadableState: {},
};

const paintSlice = createSlice({
  name: "paint",
  initialState,
  reducers: {
    setSharedState: (state, action: PayloadAction<SharedStatePayload>) => {
      const { key, value } = action.payload;
      const currentValue = state.sharedState[key];
      const next = isFunction(value) ? value(currentValue) : value;
      if (currentValue !== next) {
        state.sharedState[key] = next;
      }
    },
    setEditorReadableState: (
      state,
      action: PayloadAction<SharedStatePayload>,
    ) => {
      const { key, value } = action.payload;
      const currentValue = state.editorReadableState[key];
      const next = isFunction(value) ? value(currentValue) : value;
      if (currentValue !== next) {
        state.editorReadableState[key] = next;
      }
    },
  },
});

export const selectSharedState = (state: EditorRootState) =>
  state.paint.sharedState;

const { actions, reducer } = paintSlice;

export const { setSharedState, setEditorReadableState } = actions;
export default reducer;

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const shopifyProductRatingWidget: ComponentTemplate = {
  id: "ae5b4ad3-38d3-48af-835c-72f5bb93b4af",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Shopify Product Rating",
  leftBarCategory: { main: "productIntegrations", subCategory: "shopify" },
  preview: "integrations/shopify-product-rating.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  tags: ["reviews", "star", "widget"],
  template: {
    id: "$uid",
    type: "shopifyProductRatingWidget",
    props: { style: { alignSelf: "stretch" } },
  },
};

import { errorToast, successToast } from "@common/designSystem/Toast";
import type { DeleteElementConfirmationModalProps } from "@editor/components/AppModalTypes";
import DeleteConfirmationModal from "@editor/components/common/DeleteConfirmationModal";
import { elementTypeToEditorData } from "@editor/components/editor/element";
import { useModal } from "@editor/hooks/useModal";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import {
  deleteElement as deleteElementAction,
  selectDraftElementId,
  selectDraftElementProjectId,
  selectDraftElementType,
  selectElementsMapping,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import type { EditorRoute } from "@editor/utils/router";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";
import values from "lodash-es/values";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";

export const DeleteElementConfirmationModal = (
  props: DeleteElementConfirmationModalProps,
) => {
  const pageToDelete = props.element.id;
  const modal = useModal();
  const {
    mutate: deleteElement,
    data,
    isPending: isLoading,
    error,
  } = trpc.element.delete.useMutation();
  const draftElementId = useEditorSelector(selectDraftElementId);
  const draftElementProjectId = useEditorSelector(selectDraftElementProjectId);
  const draftElementType = useEditorSelector(selectDraftElementType);
  const allElements = useEditorSelector(selectElementsMapping);
  const setDraftElement = useSetDraftElement();

  const dispatch = useEditorDispatch();
  const navigate = useNavigate();

  const handleDeletion = React.useCallback(() => {
    if (props.type === "unpublish") {
      props.onDelete();
    } else if (props.type === "delete" && pageToDelete) {
      void deleteElement(pageToDelete);
    }
  }, [deleteElement, pageToDelete, props]);

  const pageEditorData = elementTypeToEditorData[props.element?.type];

  // biome-ignore lint/correctness/useExhaustiveDependencies: This is weird, no idea why we're doing these toasts inside an effect
  React.useEffect(() => {
    if (
      props.type === "delete" &&
      pageToDelete &&
      data?.elementId &&
      pageToDelete === data.elementId
    ) {
      const element = values(allElements).find(
        (element) => element.id !== pageToDelete,
      );
      dispatch(deleteElementAction(pageToDelete));
      successToast(
        `${pageEditorData.singularDisplayName} Deleted`,
        `${pageEditorData.singularDisplayName} deleted successfully! 🎉`,
      );
      modal.closeModal({});
      // redirect to another page or article if it's deleting the current draft one
      if (
        draftElementId === data.elementId &&
        draftElementProjectId &&
        props.elements.length > 1
      ) {
        const currentIndex = props.elements.findIndex(
          (p) => p.id === pageToDelete,
        );
        const nextIndex =
          currentIndex === 0 ? currentIndex + 1 : currentIndex - 1;
        const newPageId = props.elements[nextIndex]!.id;
        setDraftElement({ id: newPageId });
        navigate(
          generatePath(routes.editor.element, {
            projectId: draftElementProjectId,
            elementId: newPageId,
          } as EditorRoute),
        );
      } else if (element) {
        // Note (Sebas 2022-10-07): In case there is no more pages we need to
        // select and redirect to a blog post or section if there is one.
        setDraftElement({ id: element.id });
        navigate(
          generatePath(routes.editor.element, {
            projectId: element.storeId,
            elementId: element.id,
          } as EditorRoute),
        );
      }
    } else if (error) {
      errorToast(
        "Failed Deleting Element",
        `Failed deleting ${pageEditorData.singularDisplayName}. Please try again or reach out to support@replo.app for help.`,
      );
    }
  }, [
    data,
    draftElementId,
    draftElementProjectId,
    draftElementType,
    error,
    pageToDelete,
    dispatch,
    modal,
    navigate,
    pageEditorData,
    allElements,
    props.type,
    props.elements,
    setDraftElement,
  ]);

  return (
    <DeleteConfirmationModal
      onRequestClose={() =>
        modal.closeModal({ type: "deleteElementConfirmationModal" })
      }
      onDelete={handleDeletion}
      assetTypeDisplayName={pageEditorData.singularDisplayName}
      assetName={props.element.name}
      extraMessage={
        props.element.isHomepage
          ? "Because this page is set as your store's homepage, Replo will convert your store's homepage back to the homepage from your Shopify theme."
          : undefined
      }
      isLoading={isLoading}
      confirmationType={props.type}
    />
  );
};

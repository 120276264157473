import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

const styleElements = {
  root: {},
} satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: true,
    allowsLayoutModification: true,
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    canContainChildren: true,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;

export function calculateDelta(
  newValue: number | undefined,
  oldValue: number | undefined,
) {
  if (!oldValue || newValue === undefined) {
    return 0;
  }

  return oldValue === 0 ? newValue : ((newValue - oldValue) / oldValue) * 100;
}

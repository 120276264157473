import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _rebuyWidgetEmbed: {
      type: "string",
      name: "Rebuy Widget Embed Code",
      // Note (Sebas, 2023-04-20): We are disabling the dynamic data feature for this
      // component because the embed code can contain "{{" and we are using this to
      // validate if the value it's dynamic or not. This causes the input to convert
      // into a dynamic data value indicator and we don't want that to happen.
      disableDynamicData: true,
      description:
        "Embed code copied from Rebuy. Find this under Edit Form in the Placement tab.",
      defaultValue: null,
    },
  };
}

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    customProps: convertToLegacyProps(getConfigurableProps()),
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;

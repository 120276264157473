import { Chip } from "@editor/components/common/designSystem/Chip";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useGetAttribute } from "@editor/hooks/useGetAttribute";
import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { selectDraftComponent } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { canvasToStyleMap } from "@editor/utils/editor";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import ModifiersInfo from "@editorComponents/ModifiersInfo";
import ModifierGroup from "@editorExtras/ModifierGroup";
import { getVisibleBreakpoints } from "@editorModifiers/utils";
import * as React from "react";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import { mapNull } from "replo-runtime/shared/utils/optional";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";

const options = [
  {
    value: canvasToStyleMap.mobile,
    label: "Mobile",
    tooltipText: "Toggle mobile visibility",
  },
  {
    value: canvasToStyleMap.tablet,
    label: "Tablet",
    tooltipText: "Toggle tablet visibility",
  },
  {
    value: canvasToStyleMap.desktop,
    label: "Desktop",
    tooltipText: "Toggle desktop visibility",
  },
];

export const VisibilityModifier = () => {
  useVisibilityHotkeys();

  return (
    <ModifierGroup
      title="Visibility"
      titleEnhancer={<ModifiersInfo documentationType="visibility" />}
    >
      <div className="flex w-full flex-row gap-1 rounded-md">
        {options.map((option) => (
          <VisibilityOption key={option.value} {...option} />
        ))}
      </div>
    </ModifierGroup>
  );
};

const VisibilityOption: React.FC<{
  label: string;
  value: string;
  tooltipText: string;
}> = ({ label, value, tooltipText }) => {
  const { visibleBreakpoints, onChangeVisibility } = useVisibleBreakpoints();
  const isSelected = visibleBreakpoints?.includes(value);

  return (
    <Chip
      tooltipText={tooltipText}
      isSelected={isSelected}
      startEnhancer={
        isSelected ? (
          <BsFillCheckCircleFill size={11} />
        ) : (
          <BsFillXCircleFill size={11} />
        )
      }
      title={label}
      className="flex-1 text-xs"
      onClick={() => {
        onChangeVisibility(value);
      }}
    >
      <span data-testid={`visibility-toggle-${label}`}>{label}</span>
    </Chip>
  );
};

function useVisibilityHotkeys() {
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const { onChangeVisibility } = useVisibleBreakpoints();

  const handleToggleVisibility = React.useCallback(() => {
    onChangeVisibility(canvasToStyleMap[activeCanvas]);
  }, [activeCanvas, onChangeVisibility]);

  useReploHotkeys({
    toggleVisibility: handleToggleVisibility,
  });
}

function useVisibleBreakpoints() {
  const draftComponent = useEditorSelector(selectDraftComponent);
  const getAttribute = useGetAttribute();
  const applyComponentAction = useApplyComponentAction();

  const visibleBreakpoints = mapNull(draftComponent, (component) =>
    getVisibleBreakpoints(component, getAttribute),
  );

  const onChangeVisibility = React.useCallback(
    (value: string) => {
      if (visibleBreakpoints) {
        if (!visibleBreakpoints.includes(value)) {
          // Making the component VISIBLE for the selected breakpoint value
          applyComponentAction({
            type: "setProps",
            value: {
              [`${value}`]: {
                display:
                  getAttribute(draftComponent, `props.${value}.__display`)
                    ?.value ||
                  styleAttributeToEditorData["__display"].defaultValue,
              },
            },
          });
        } else {
          const thisDisplayValue =
            getAttribute(draftComponent, `props.${value}.display`)?.value ||
            styleAttributeToEditorData["display"].defaultValue;
          // Making the component HIDDEN for the selected breakpoint value.
          // Set __display correctly, so that display: flex components get
          // reset to display: flex (instead of block) when they're unhidden
          const propsToUpdate: Record<string, any> = {
            [`${value}`]: {
              __display: thisDisplayValue,
              display: "none",
            },
          };

          const valueToSmallerAliases: Record<string, string[]> = {
            style: ["sm", "md"],
            "style@md": ["sm"],
          };

          // If we're setting the desktop style to hidden and we don't have a
          // mobile style set, we need to set the mobile style to block so
          // it will still show up on mobile
          if (valueToSmallerAliases[value]) {
            for (const alias of valueToSmallerAliases[value]!) {
              const smallerDisplayValue = getAttribute(
                draftComponent,
                `props.style@${alias}.display`,
              )?.value;

              if (!smallerDisplayValue) {
                const newValue = smallerDisplayValue || thisDisplayValue;
                propsToUpdate[`style@${alias}`] = {
                  display: newValue,
                  __display: newValue,
                };
              }
            }
          }
          applyComponentAction({
            type: "setProps",
            value: propsToUpdate,
          });
        }
      }
      return null;
    },
    [draftComponent, visibleBreakpoints, applyComponentAction, getAttribute],
  );

  return {
    visibleBreakpoints,
    onChangeVisibility,
  };
}

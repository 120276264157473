import HowDidYouHearAboutUsStep from "@components/flows/onboardingSteps/HowDidYouHearAboutUsStep";
import StartFrom from "@components/flows/onboardingSteps/StartFrom";
import UserFullNameStep from "@components/flows/onboardingSteps/UserFullNameStep";
import WhatDoYouWantToDoInReplo from "@components/flows/onboardingSteps/WhatDoYouWantToDoInReplo";
import WhatIsYourExperienceLevelWithNoCodePageBuilders from "@components/flows/onboardingSteps/WhatIsYourExperienceLevelWithNoCodePageBuilders";
import WhoAreYouBuildingPagesFor from "@components/flows/onboardingSteps/WhoAreYouBuildingPagesForStep";
import { ReploFlowsStepContext } from "@editor/components/flows/context/ReploFlowsStepContext";
import useExecuteFlowCallback from "@editor/components/flows/hooks/useExecuteFlowCallback";
import { useGetCurrentFlow } from "@editor/components/flows/hooks/useGetCurrentFlow";
import useGetFlowActions from "@editor/components/flows/hooks/useGetFlowActions";
import AreYouUsingReploSoloOrWithATeam from "@editor/components/flows/onboardingSteps/AreYouUsingReploSoloOrWithATeam";
import WhatIsYourYearlyRevenue from "@editor/components/flows/onboardingSteps/WhatIsYourYearlyRevenue";
import WhichBestDescribesYourIndustry from "@editor/components/flows/onboardingSteps/WhichBestDescribesYourIndustry";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import type { Flow, FlowStep } from "schemas/flow";

type FlowsProps = {
  entityType: Flow["type"];
  /** This is used for routing purposes */
  nextStepFromRoute?: FlowStep | null;
};

const Flows: React.FC<FlowsProps> = ({ entityType, nextStepFromRoute }) => {
  const [searchParams] = useSearchParams();
  const isDebug = Boolean(searchParams.get("debug"));
  const { currentFlow, nextStep } = useGetCurrentFlow({
    entityType,
    isDebug,
  });
  const currentStep = nextStepFromRoute ?? nextStep ?? null;
  const { submitStep, skipStep, goBack, isSubmitting } = useGetFlowActions(
    entityType,
    currentStep,
  );

  // NOTE (Sebas, 2024-02-16): This is in charge of executing different actions when
  // the flow component is rendered, depending on the flow slug.
  useExecuteFlowCallback(currentFlow?.slug);

  if (currentStep === null) {
    return null;
  }

  // TODO (Fran 2024-01-19): Implement Modal flows - REPL-10073
  const StepWrapper = React.Fragment;

  return (
    <ReploFlowsStepContext.Provider
      value={{
        currentStep,
        goBack,
        submitStep,
        skipStep,
        isSubmitting,
      }}
    >
      <StepWrapper>{getStepComponent(currentStep)}</StepWrapper>
    </ReploFlowsStepContext.Provider>
  );
};

function getStepComponent(step: FlowStep) {
  return exhaustiveSwitch({ type: step.type })({
    // ---------- Onboarding steps ----------
    "onboarding.user.full-name": () => <UserFullNameStep />,
    "onboarding.user.how-did-you-hear-about-us": () => (
      <HowDidYouHearAboutUsStep />
    ),
    "onboarding.user.who-are-you-building-pages-for": () => (
      <WhoAreYouBuildingPagesFor />
    ),
    "onboarding.user.what-do-you-want-to-do-in-replo": () => (
      <WhatDoYouWantToDoInReplo />
    ),
    "onboarding.user.are-you-using-solo-or-team": () => (
      <AreYouUsingReploSoloOrWithATeam />
    ),
    "onboarding.user.experience-level-with-nocode-builders": () => (
      <WhatIsYourExperienceLevelWithNoCodePageBuilders />
    ),
    "onboarding.user.do-you-want-to-start-from-template": () => <StartFrom />,
    "onboarding.user.who-are-you-building-pages-for.revenue": () => (
      <WhatIsYourYearlyRevenue />
    ),
    "onboarding.user.which-best-describes-your-industry": () => (
      <WhichBestDescribesYourIndustry />
    ),
    // ---------- End Onboarding steps ----------
    // ---------- Editor Tour steps ----------
    "tour.editor.generic": () => null,
    // ---------- End Editor Tour steps ----------
  });
}

export default Flows;

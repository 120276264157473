import * as Tabs from "@radix-ui/react-tabs";
import * as React from "react";
import { createContext } from "replo-utils/react/context";

import type { Component } from "../../../shared/Component";
import type { RenderComponentProps } from "../../../shared/types";
import { findComponentByTypeAndMaxLengthBFS } from "../../../shared/utils/component";
import { mergeContext } from "../../../shared/utils/context";
import {
  type RenderChildren,
  useRenderChildren,
} from "../../../shared/utils/renderComponents";
import { useEditorReadableState } from "../../hooks/useEditorReadableState";
import { useSharedState } from "../../hooks/useSharedState";
import { isItemsDynamic, type ItemsConfig } from "../../utils/items";
import { ReploComponent } from "../ReploComponent";

const TabsV2Context = createContext<{
  listItems: RenderChildren;
  contentItems: RenderChildren;
} | null>("TabsV2ContextValue", null);

function useTabsV2Context() {
  return React.useContext(TabsV2Context);
}

export const TabsBlockV2: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
  context,
}) => {
  const customProps = React.useMemo(
    () => getCustomProps(component.props),
    [component.props],
  );

  const shouldStopSearchingForComponent = React.useCallback(
    (c: Component) => {
      return c.id !== component.id && c.type === "tabsV2__block";
    },
    [component.id],
  );

  const tabListComponent = React.useMemo(() => {
    return findComponentByTypeAndMaxLengthBFS(
      component,
      "tabsV2__list",
      shouldStopSearchingForComponent,
    ).component;
  }, [component, shouldStopSearchingForComponent]);

  const tabContentsWrapperComponent = React.useMemo(() => {
    return findComponentByTypeAndMaxLengthBFS(
      component,
      "tabsV2__panelsContent",
      shouldStopSearchingForComponent,
    ).component;
  }, [component, shouldStopSearchingForComponent]);

  const { items, defaultTab } = customProps;

  const tabListItems = useRenderChildren(tabListComponent, {
    itemsConfig: items,
    context,
    alwaysPreferChildComponent: true,
  });

  const tabContentItems = useRenderChildren(tabContentsWrapperComponent, {
    itemsConfig: items,
    context,
    alwaysPreferChildComponent: true,
  });

  useEditorReadableState([component.id, "totalItems"], tabListItems.length);

  const [activeTabIndex, setActiveTabIndex] = useSharedState(
    [component.id, "activeTabIndex"],
    Number(defaultTab ?? 0),
  );

  const activeTabItem = tabListItems[activeTabIndex];
  const activeTabId = activeTabItem?.component.id;

  // Note (Noah, 2022-09-08, REPL-4044): We need mergeReplacingArrays here because
  // we want the tabListItems to override any existing tabListItems in the context
  // (which could be set, if this tabs component is inside another tabs component)
  const nextContext = React.useMemo(() => {
    return mergeContext(context, {
      state: {
        tabsV2Block: {
          activeTabIndex,
          activeTabId,
          isDynamic: isItemsDynamic(items),
        },
      },
      attributeKeyToComponentId: {
        _currentItem: component.id,
      },
      actionHooks: {
        setActiveTabIndex(index: number) {
          setActiveTabIndex(index);
        },
      },
    });
  }, [
    activeTabId,
    activeTabIndex,
    component.id,
    context,
    items,
    setActiveTabIndex,
  ]);

  return (
    <div {...componentAttributes}>
      <TabsV2Context.Provider
        value={{
          listItems: tabListItems,
          contentItems: tabContentItems,
        }}
      >
        <Tabs.Root
          orientation="vertical"
          value={`${activeTabId}${activeTabIndex}`}
          style={{ display: "contents" }}
        >
          {(component.children || []).map((child) => {
            return (
              <ReploComponent
                key={child.id}
                context={nextContext}
                component={child}
                repeatedIndexPath={context.repeatedIndexPath ?? ".0"}
              />
            );
          })}
        </Tabs.Root>
      </TabsV2Context.Provider>
    </div>
  );
};

export const TabsListV2: React.FC<RenderComponentProps> = (props) => {
  const { component, componentAttributes } = props;

  // Note (Noah, 2024-01-12, USE-661): If we somehow find ourselves outside
  // of a tabsV2 block, Radix is going to error. Preempt this and just return
  // null instead.
  const tabsV2Context = useTabsV2Context();
  if (!tabsV2Context) {
    return null;
  }

  const { listItems } = tabsV2Context;

  if (listItems.length === 0) {
    return null;
  }

  return (
    <div {...componentAttributes}>
      <Tabs.List style={{ display: "contents" }}>
        {listItems.map((listItem, index) => {
          // NOTE (Martin, 2024-09-10): because there can be multiple TabsListV2
          // components inside a single TabsBlockV2, we need to get the current
          // component children here and we cannot use the component result from
          // useRenderChildren because it can be innacurate.
          const listItemComponent =
            component.children?.[index] ?? component.children?.[0];

          if (!listItemComponent) {
            return null;
          }

          return (
            <TabsListV2Item
              key={listItem.component.id}
              index={index}
              context={props.context}
              extraAttributes={props.extraAttributes}
              listItem={{
                ...listItem,
                component: listItemComponent,
              }}
            />
          );
        })}
      </Tabs.List>
    </div>
  );
};

const TabsListV2Item: React.FC<{
  context: RenderComponentProps["context"];
  extraAttributes: RenderComponentProps["extraAttributes"];
  listItem: RenderChildren[number];
  index: number;
}> = (props) => {
  const { listItem, index, context, extraAttributes } = props;
  const nextContent = React.useMemo(() => {
    return mergeContext(context, {
      attributes: listItem.context.attributes,
      state: {
        tabsV2Block: {
          currentTabsListItem: listItem.component,
          currentTabIndex: index,
        },
      },
      variantTriggers: {
        "state.tabsV2Block.isCurrentTab": true,
      },
    });
  }, [context, listItem, index]);

  const currentTabValue = generateTabValue(listItem.component.id, index);

  return (
    <Tabs.Trigger
      key={currentTabValue}
      value={currentTabValue}
      style={{ display: "contents" }}
      data-replo-tabs-trigger
    >
      <ReploComponent
        context={nextContent}
        component={listItem.component}
        extraAttributes={extraAttributes}
        defaultActions={{
          actions: {
            onClick: [
              {
                id: "alchemy:tabsV2ItemClick",
                type: "setActiveTabIndex",
                value: { index },
              },
            ],
          },
        }}
        repeatedIndexPath={`${props.context.repeatedIndexPath}.${listItem.item ? index : 0}`}
      />
    </Tabs.Trigger>
  );
};

export const TabsPanelV2: React.FC<RenderComponentProps> = (props) => {
  const { component, componentAttributes, context, extraAttributes } = props;

  // Note (Noah, 2024-01-12, USE-661): If we somehow find ourselves outside
  // of a tabsV2 block, Radix is going to error. Preempt this and just return
  // null instead.
  const tabsV2Context = useTabsV2Context();
  if (!tabsV2Context) {
    return null;
  }

  const { contentItems } = tabsV2Context;

  if (contentItems.length === 0) {
    return null;
  }

  return (
    <div {...componentAttributes}>
      {contentItems.map((contentItem, index) => {
        // NOTE (Martin, 2024-09-10): because there can be multiple TabsPanelV2
        // components inside a single TabsBlockV2, we need to get the current
        // component children here and we cannot use the component result from
        // useRenderChildren because it can be innacurate.
        const contentItemComponent =
          component.children?.[index] ?? component.children?.[0];

        if (!contentItemComponent) {
          return null;
        }

        return (
          <TabsPanelV2Item
            key={contentItem.component.id}
            index={index}
            context={context}
            extraAttributes={extraAttributes}
            contentItem={{ ...contentItem, component: contentItemComponent }}
          />
        );
      })}
    </div>
  );
};

const TabsPanelV2Item: React.FC<{
  context: RenderComponentProps["context"];
  extraAttributes: RenderComponentProps["extraAttributes"];
  contentItem: RenderChildren[number];
  index: number;
}> = (props) => {
  const { contentItem, index, context, extraAttributes } = props;

  const nextContext = React.useMemo(() => {
    return mergeContext(context, contentItem.context);
  }, [context, contentItem.context]);

  const { listItems } = useTabsV2Context()!;
  const currentListItem = listItems[index];

  if (!currentListItem) {
    return null;
  }

  const currentTabValue = generateTabValue(currentListItem.component.id, index);
  const activeTabIndex = context.state?.tabsV2Block?.activeTabIndex;

  // Note (Fran, 2023-03-22): We should mount all the tabs because the
  // pre-render components will not work otherwise
  return (
    <Tabs.Content
      key={currentTabValue}
      value={currentTabValue}
      style={{
        display: index === activeTabIndex ? "contents" : "none",
      }}
      forceMount
    >
      <ReploComponent
        context={nextContext}
        component={contentItem.component}
        repeatedIndexPath={`${context.repeatedIndexPath}.${index}`}
        extraAttributes={extraAttributes}
      />
    </Tabs.Content>
  );
};

function getCustomProps(componentProps: Component["props"]) {
  return Object.fromEntries(
    Object.entries(componentProps).map(([key, value]) => [
      key.startsWith("_") ? key.slice(1) : key,
      value,
    ]),
  ) as {
    items: ItemsConfig;
    defaultTab: string;
  };
}

function generateTabValue(id: string, index: number) {
  return `${id}${index}`;
}

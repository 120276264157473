// Note (Reinaldo, 2022-08-25): This is mostly from https://mantine.dev/hooks/use-hotkeys/
// with a few modifications to accommodate our use cases
import type { HotkeyAction } from "@editor/utils/hotkeys";
import { getHotkeyEditorData } from "@editor/utils/hotkeys";
import * as React from "react";

function shouldFireEventBasedOnElement(event: KeyboardEvent) {
  if (event.target instanceof HTMLElement) {
    return (
      !event.target.isContentEditable &&
      !["INPUT", "TEXTAREA", "SELECT"].includes(event.target.tagName)
    );
  }
  return true;
}

export function useHotkeys(hotkeys: HotkeyItem[], target?: any) {
  const listener = React.useCallback(
    (event: KeyboardEvent) => {
      hotkeys.forEach(([hotkeyAction, handler, options]) => {
        const { controlStrings, ignoresModifiersOnKeyup, triggerInTextInputs } =
          getHotkeyEditorData(hotkeyAction);
        controlStrings.forEach((controlString) => {
          if (
            getHotkeyMatcher(controlString, ignoresModifiersOnKeyup)(event) &&
            (triggerInTextInputs || shouldFireEventBasedOnElement(event)) &&
            (options?.filter?.(event) ?? true) &&
            // by default ignore keyup and runs on keydown
            (event.type === "keydown"
              ? options?.keydown ?? true
              : options?.keyup ?? false)
          ) {
            event.preventDefault();
            handler(event);
          }
        });
      });
    },
    [hotkeys],
  );

  React.useEffect(() => {
    const targetElement = target ?? document.documentElement;
    targetElement.addEventListener("keydown", listener);
    targetElement.addEventListener("keyup", listener);

    return () => {
      targetElement.removeEventListener("keydown", listener);
      targetElement.removeEventListener("keyup", listener);
    };
  }, [listener, target]);
}

export type Hotkeys = {
  [key in HotkeyAction]?:
    | ((event: React.KeyboardEvent<HTMLElement> | KeyboardEvent) => void)
    | [
        (event: React.KeyboardEvent<HTMLElement> | KeyboardEvent) => void,
        HotkeyItemOptions,
      ];
};

export function useReploHotkeys(hotkeys: Hotkeys, target?: any) {
  const _hotkeys = Object.keys(hotkeys).map((hotkey) => {
    const hotkeyAction = hotkeys[hotkey as HotkeyAction];

    const fn = Array.isArray(hotkeyAction) ? hotkeyAction[0] : hotkeyAction;
    const options = Array.isArray(hotkeyAction) ? hotkeyAction[1] : undefined;
    return [hotkey, fn, options] as HotkeyItem;
  });

  useHotkeys(_hotkeys, target);
}

export type KeyboardModifiers = {
  alt: boolean;
  ctrl: boolean;
  meta: boolean;
  mod: boolean;
  shift: boolean;
};

export type Hotkey = KeyboardModifiers & {
  key?: string;
};

type CheckHotkeyMatch = (event: KeyboardEvent) => boolean;

export function parseHotkey(hotkey: string): Hotkey {
  const keys = hotkey
    .toLowerCase()
    .split("+")
    .map((part) => part.trim());

  const modifiers: KeyboardModifiers = {
    alt: keys.includes("alt"),
    ctrl: keys.includes("ctrl"),
    meta: keys.includes("meta"),
    mod: keys.includes("mod"),
    shift: keys.includes("shift"),
  };

  const reservedKeys = new Set(["alt", "ctrl", "meta", "shift", "mod"]);

  const freeKey = keys.find((key) => !reservedKeys.has(key));

  return {
    ...modifiers,
    key: freeKey,
  };
}

// NOTE (Sebas, 2024-06-18): This is a mapping for converting combined keys to their
// respective pressed key. For example, if the user presses "¡", the keydown event will
// have the key "1", so we need to convert "¡" to "1" to match the hotkey.
const optionSymbolKeyToNumberKey = {
  "¡": "1",
  "™": "2",
  "£": "3",
  "¢": "4",
  "∞": "5",
  "§": "6",
  "≤": ",",
  "≥": ".",
  "¯": ",",
  "˘": ".",
  ø: "o",
};

/**
 * Return whether the given event represents the given hotkey. The event could be
 * any keyboard event, but if ignoresModifiersOnKeyup is passed, then if the event
 * is a keyup, this function returns true even if the modifiers don't match the hotkey.
 * This is useful for hotkeys that are uses as "toggles", where keydown enables something
 * and keyup disables it - in this case, we want to ignore the modifiers on keyup so
 * that things like pressing shift before lifting the key still disables.
 */

function isExactHotkey(
  hotkey: Hotkey,
  ignoresModifiersOnKeyup: boolean,
  event: KeyboardEvent,
): boolean {
  const {
    alt: expectedAlt,
    ctrl: expectedCtrl,
    meta: expectedMeta,
    mod: expectedMod,
    shift: expectedShift,
    key: expectedKey,
  } = hotkey;
  const { altKey, ctrlKey, metaKey, shiftKey, key, type } = event;

  const isKeyup = type === "keyup";
  const shouldIgnoreModifiers = isKeyup && ignoresModifiersOnKeyup;

  const pressedKey =
    optionSymbolKeyToNumberKey[
      key as keyof typeof optionSymbolKeyToNumberKey
    ] ?? key;

  const isAltMatch = expectedAlt === altKey || shouldIgnoreModifiers;
  let isCtrlMatch = true;
  let isMetaMatch = true;
  if (!shouldIgnoreModifiers) {
    if (expectedMod) {
      // If we're looking to match "mod", we'll match the hotkey if either ctrl or meta is pressed,
      // since different operating systems implement mod with either ctrl or meta
      isCtrlMatch = ctrlKey || metaKey;
      isMetaMatch = ctrlKey || metaKey;
    } else {
      isCtrlMatch = ctrlKey === expectedCtrl;
      isMetaMatch = metaKey === expectedMeta;
    }
  }
  const isShiftMatch = expectedShift === shiftKey || shouldIgnoreModifiers;
  const normalizedKeyCode = event.code?.replace("Key", "") ?? "";
  if (!expectedKey || !pressedKey) {
    return false;
  }

  const keyPropertyMatches =
    pressedKey.toLowerCase() === expectedKey.toLowerCase();
  const normalizedKeyCodeMatches =
    normalizedKeyCode.toLowerCase() === expectedKey.toLowerCase();

  const isKeyMatch = keyPropertyMatches || normalizedKeyCodeMatches;

  return isAltMatch && isCtrlMatch && isMetaMatch && isShiftMatch && isKeyMatch;
}

export function getHotkeyMatcher(
  hotkey: string,
  ignoresModifiersOnKeyup: boolean,
): CheckHotkeyMatch {
  return (event) =>
    isExactHotkey(parseHotkey(hotkey), ignoresModifiersOnKeyup, event);
}

export type HotkeyItemOptions = {
  filter?: (event: KeyboardEvent) => boolean;
  keyup?: boolean;
  keydown?: boolean;
};

export type HotkeyItem = [
  HotkeyAction,
  (event: React.KeyboardEvent<HTMLElement> | KeyboardEvent) => void,
  HotkeyItemOptions?,
];

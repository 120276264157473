import type { ComponentTemplate } from "@editor/types/component-template";
import { getCanBeAddedFunction } from "@editor/utils/template";
import { Env } from "replo-runtime/shared/enums";

export const variantList: ComponentTemplate = {
  id: "904e831d-9f70-44b6-8fdc-42310d600e0b",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Variant List",
  leftBarCategory: { main: "product", subCategory: "variants" },
  tags: ["product", "product variant list"],
  preview: "product/list-basic.svg",
  template: {
    id: "948138ce-d616-4bd3-8b8f-3d06159752d2",
    name: "Variant List",
    type: "variantSelect",
    props: {
      style: {
        display: "flex",
        __flexGap: "12px",
        flexDirection: "row",
      },
    },
    children: [
      {
        id: "71c93412-5f66-4573-8c43-94a95f23e771",
        name: "Container",
        type: "container",
        props: {
          style: {
            alignItems: "center",
            paddingTop: "4px",
            paddingLeft: "8px",
            paddingRight: "8px",
            flexDirection: "column",
            paddingBottom: "4px",
            justifyContent: "center",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            __animateVariantTransitions: true,
          },
        },
        children: [
          {
            id: "ef0ec33c-06c1-4530-9b7a-fe371c0386f4",
            type: "text",
            props: {
              text: "<p>{{attributes._currentVariant.title}}</p>",
              style: {
                fontSize: "16px",
                fontWeight: 300,
                lineHeight: "24px",
              },
            },
          },
        ],
        variants: [
          {
            id: "01872a86-66d6-43b3-836b-139e3faa6e88",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "ce0eb2e5-d8f5-4460-b765-ad89520c9afd",
            name: "Selected",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "8e1821de-3d2f-4bea-87fb-91b41957b391",
                  field: "state.product.selectedVariant",
                },
              ],
            },
          },
        ],
        variantOverrides: {
          "ce0eb2e5-d8f5-4460-b765-ad89520c9afd": {
            componentOverrides: {
              "71c93412-5f66-4573-8c43-94a95f23e771": {
                props: {
                  style: {
                    backgroundColor: "#000000FF",
                  },
                },
              },
              "ef0ec33c-06c1-4530-9b7a-fe371c0386f4": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};

export const variantSelectDropdown: ComponentTemplate = {
  id: "7908f513-0245-4102-9246-abf26399139b",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Variant Dropdown",
  leftBarCategory: { main: "product", subCategory: "variants" },
  tags: ["product"],
  preview: "product/variant-dropdown.svg",
  canBeAddedAsChild: getCanBeAddedFunction(
    "product",
    "Variant Dropdowns can only be added inside Product boxes.",
  ),
  template: {
    id: "11f91d59-ef2f-4fbe-a541-de921f70122b",
    type: "container",
    props: {
      style: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        maxWidth: "100%",
        __flexGap: "24px",
        alignSelf: "auto",
      },
    },
    children: [
      {
        id: "75a9c60c-833d-49d1-94dc-9b359e9ecacf",
        type: "variantSelectDropdown",
        props: {
          style: {
            zIndex: "2",
            fontSize: "16px",
            fontWeight: 700,
            paddingTop: "12px",
            borderColor: "rgb(0, 0, 0)",
            borderTopStyle: "solid",
            borderLeftStyle: "solid",
            borderBottomStyle: "solid",
            borderRightStyle: "solid",
            borderWidth: "1px",
            paddingLeft: "12px",
            paddingRight: "24px",
            paddingBottom: "12px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          },
          _hideDefaultArrowDisplay: true,
        },
        name: "Dropdown",
      },
      {
        id: "0d8f04be-084f-40c7-b7d4-f1d6744d091c",
        type: "icon",
        props: {
          style: {
            top: "50%",
            right: "2px",
            width: "24px",
            height: "24px",
            zIndex: 1,
            position: "absolute",
            __transform: {
              skewX: "0deg",
              skewY: "0deg",
              scaleX: "100%",
              scaleY: "100%",
              scaleZ: "100%",
              rotateX: "0deg",
              rotateY: "0deg",
              rotateZ: "0deg",
              translateX: "0px",
              translateY: "-50%",
              translateZ: "0px",
            },
          },
          iconName: "chevron-down",
        },
        name: "Arrow",
      },
    ],
    name: "Variant Dropdown",
  },
};

export const variantListWithRadio: ComponentTemplate = {
  id: "d41688c8-b7cf-4f37-9755-83a68aa5798f",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Variant List with Radio",
  leftBarCategory: { main: "product", subCategory: "variants" },
  tags: ["product", "product variant radio", "radio"],
  preview: "product/list-radio.svg",
  template: {
    id: "26fdec47-d11e-43be-a4dd-0cf9a4e92ed4",
    name: "Variant List with Radio 2",
    type: "variantSelect",
    props: {
      style: {
        display: "flex",
        __flexGap: "15px",
        alignSelf: "stretch",
        flexDirection: "column",
      },
    },
    children: [
      {
        id: "575dce2d-2e3a-4193-9c7c-a9c8efa58c24",
        name: "Variant Container",
        type: "container",
        props: {
          style: {
            cursor: "pointer",
            display: "flex",
            __flexGap: "15px",
            alignItems: "center",
            paddingTop: "5px",
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingBottom: "5px",
            justifyContent: "flex-start",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            __animateVariantTransitions: true,
          },
          onClick: [],
        },
        children: [
          {
            id: "d78fe1d0-d064-4ce7-b585-113da1a260e1",
            name: "Variant Radio",
            type: "container",
            props: {
              style: {
                width: "20px",
                height: "20px",
                __width: "20px",
                display: "flex",
                __height: "20px",
                alignItems: "center",
                borderTopColor: "#000000",
                borderTopStyle: "solid",
                borderTopWidth: "1px",
                justifyContent: "center",
                borderLeftColor: "#000000",
                borderLeftStyle: "solid",
                borderLeftWidth: "1px",
                borderRightColor: "#000000",
                borderRightStyle: "solid",
                borderRightWidth: "1px",
                borderBottomColor: "#000000",
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
                borderTopLeftRadius: "50%",
                borderTopRightRadius: "50%",
                borderBottomLeftRadius: "50%",
                borderBottomRightRadius: "50%",
              },
              _accessibilityRole: "radio",
              _accessibilityChecked: false,
            },
            children: [
              {
                id: "bea85ed5-0bc7-4340-9003-7b6d503f4438",
                name: "Variant Radio Indicator",
                type: "container",
                props: {
                  style: {
                    width: "18px",
                    height: "18px",
                    display: "flex",
                    opacity: "0%",
                    alignItems: "flex-start",
                    backgroundColor: "#000000FF",
                    borderTopLeftRadius: "50%",
                    borderTopRightRadius: "50%",
                    borderBottomLeftRadius: "50%",
                    borderBottomRightRadius: "50%",
                  },
                },
                children: [],
              },
            ],
            variants: [
              {
                id: "5be93005-e56e-4ec3-b39a-b4251bd4409f",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "97ade264-88a1-41a6-a373-6570b53cedc9",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "e4a4b396-827e-4cc7-a4dd-cef3bcd9a212",
                      field: "state.product.selectedVariant",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "97ade264-88a1-41a6-a373-6570b53cedc9": {
                componentOverrides: {
                  "bea85ed5-0bc7-4340-9003-7b6d503f4438": {
                    props: {
                      style: {
                        width: "15px",
                        height: "15px",
                        __width: "15px",
                        opacity: "100%",
                        __height: "15px",
                        flexGrow: "unset",
                        backgroundColor: "#FFFFFFFF",
                      },
                    },
                  },
                  "d78fe1d0-d064-4ce7-b585-113da1a260e1": {
                    props: {
                      style: {
                        width: "20px",
                        height: "20px",
                        __width: "20px",
                        display: "flex",
                        __height: "20px",
                        flexGrow: "unset",
                        alignSelf: "auto",
                        marginTop: "0px",
                        borderColor: null,
                        borderStyle: null,
                        borderWidth: null,
                        flexDirection: "column",
                        borderTopColor: "#FFFFFFFF",
                        borderTopStyle: "solid",
                        borderTopWidth: "1px",
                        borderLeftColor: "#FFFFFFFF",
                        borderLeftStyle: "solid",
                        borderLeftWidth: "1px",
                        borderRightColor: "#FFFFFFFF",
                        borderRightStyle: "solid",
                        borderRightWidth: "1px",
                        borderBottomColor: "#FFFFFFFF",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "fe8b71d2-f415-4f62-af32-4d7f073d438e",
            name: "Variant Information",
            type: "container",
            props: {
              style: {
                display: "flex",
                flexGrow: 1,
                maxWidth: "100%",
                alignSelf: "stretch",
                alignItems: "center",
                justifyContent: "space-between",
              },
            },
            children: [
              {
                id: "74f7455d-cef2-47ae-a62b-2cef94b5bdc3",
                type: "text",
                props: {
                  text: "<p>{{attributes._currentVariant.title}}</p>",
                  style: {
                    fontSize: "16px",
                    fontWeight: 300,
                    lineHeight: "24px",
                  },
                },
              },
            ],
          },
        ],
        variants: [
          {
            id: "d8484647-18c3-4ed4-bb37-7afc18081d9a",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "d6856ec4-b4d5-4d84-a52a-3a7c59796f62",
            name: "Selected",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "8e1821de-3d2f-4bea-87fb-91b41957b391",
                  field: "state.product.selectedVariant",
                },
              ],
            },
          },
        ],
        variantOverrides: {
          "d6856ec4-b4d5-4d84-a52a-3a7c59796f62": {
            componentOverrides: {
              "575dce2d-2e3a-4193-9c7c-a9c8efa58c24": {
                props: {
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#000000FF",
                  },
                  onClick: [],
                },
              },
              "74f7455d-cef2-47ae-a62b-2cef94b5bdc3": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                  },
                },
              },
              "a9cb57d5-adc5-4399-895b-6eb7de71accf": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                  },
                },
              },
              "d8949bdb-76cd-4720-b30a-cfbf7a9df1e6": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                    fontSize: "16px",
                    lineHeight: "24px",
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};

import {
  selectColor,
  selectDraftComponentType,
  selectedDraftComponentIsRoot,
  selectNonTextComponentText,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { componentTypeToModifierGroups } from "@editor/utils/modifierGroups";
import { ModifierGroups } from "@editorComponents/modifierGroups";
import StickyBanners from "@editorComponents/StickyBanners";
import * as React from "react";
import { isFunction } from "replo-utils/lib/type-check";
import { type ReploComponentType } from "schemas/component";

const DesignTab: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="relative flex flex-col gap-4">
      <StickyBanners />
      <ComponentModifiers />
    </div>
  );
};

const ComponentModifiers: React.FC = () => {
  const isPageRootComponent = useEditorSelector(selectedDraftComponentIsRoot);
  const type = useEditorSelector(selectDraftComponentType);
  const colorValue = useEditorSelector(selectColor);
  // NOTE (gabe, 05-03-2023): We are only interested in the text value of
  // non-text components (legacy buttons) because using selectText causes
  // unnecessary re-renders of ModifierGroups on text changes.
  const nonTextComponentText = useEditorSelector(selectNonTextComponentText);

  const modifierGroupsOrFunction =
    componentTypeToModifierGroups[type as ReploComponentType];
  if (!modifierGroupsOrFunction) {
    return null;
  }

  let modifierGroups = isFunction(modifierGroupsOrFunction)
    ? modifierGroupsOrFunction({
        textValue: nonTextComponentText,
        colorValue: colorValue ?? null,
      })
    : modifierGroupsOrFunction;

  if (!modifierGroups) {
    return null;
  }

  if (isPageRootComponent) {
    modifierGroups = modifierGroups.filter(
      (modifier) => modifier.type !== "visibility",
    );
  }

  return <ModifierGroups groupTypes={modifierGroups} />;
};

export default DesignTab;

import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { prependProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedLiquidReviewIntegrations } from "../SharedLiquidReviewIntegrations";

function JudgeProductReviewsWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const product = useProductFromProps(component.props, context);
  const liquidSource = `<!-- Start of Judge.me code -->
  <div style='clear:both'></div>
  <div id='judgeme_product_reviews' class='jdgm-widget jdgm-review-widget' data-id='{{ product.id }}'>
      {{ product.metafields.judgeme.widget }}
  </div>
<!-- End of Judge.me code -->`;
  const liquid = prependProductAssignmentToLiquidSource({
    product,
    liquidSource,
    context,
  });
  return (
    <SharedLiquidReviewIntegrations
      attributes={componentAttributes}
      component={component}
      liquidSource={liquid}
      placeholder="Judge Product Reviews Widget will appear here"
      shouldHavePlaceHolder={isEditorApp || !product}
      appName="Judge.me"
      context={context}
    />
  );
}

export default JudgeProductReviewsWidget;

import { useEditorDispatch, useEditorSelector } from "@editor/store";
import type { DragType } from "@editor/types/drag-type";
import {
  selectCurrentDraggingIdentifier,
  selectCurrentDragType,
  setCurrentDragTypeAndIdentifier,
} from "@reducers/drag-and-drop-reducer";

export default function useCurrentDragType() {
  const currentDragType = useEditorSelector(selectCurrentDragType);
  const currentDragIdentifier = useEditorSelector(
    selectCurrentDraggingIdentifier,
  );
  const dispatch = useEditorDispatch();

  return {
    currentDragType,
    currentDragIdentifier,
    setCurrentDragTypeAndIdentifier: (
      currentDragType: DragType | null,
      identifier: string | null,
    ) =>
      dispatch(
        setCurrentDragTypeAndIdentifier({
          dragType: currentDragType,
          identifier,
        }),
      ),
  };
}

import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _tabsConfig: {
      type: "inlineItems",
      name: "Tab Items",
      defaultValue: null,
      description:
        "Select names for each of the tabs. These will be repeated and their content will be editable inside the Tabs Block.",
    },
  };
}

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

export const listStyleElements = {
  root: {
    defaultStyles: {
      display: "flex",
      flexDirection: "row",
    },
  },
} as const satisfies StyleElements;

export const panelStyleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    allowsLayoutModification: true,
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    canContainChildren: true,
    styleElements,
    ancestorDisallowList: [
      {
        ancestorTypes: ["button"],
        message: "Tabs cannot be nested inside buttons.",
      },
    ],
  },
  children: {
    onePanelContent: {
      renderData: {
        newInstancesUseNumbering: false,
        allowsLayoutModification: true,
        acceptsArbitraryChildren: () => false,
        isAlwaysDynamic: false,
        canvasIndicatorDragDirections: [],
        canContainChildren: true,
      },
    },
    panelsContent: {
      renderData: {
        newInstancesUseNumbering: false,
        allowsLayoutModification: true,
        acceptsArbitraryChildren: () => false,
        isAlwaysDynamic: false,
        canvasIndicatorDragDirections: [],
        canContainChildren: true,
        styleElements: panelStyleElements,
      },
    },
    list: {
      renderData: {
        newInstancesUseNumbering: false,
        allowsLayoutModification: true,
        acceptsArbitraryChildren: () => false,
        isAlwaysDynamic: false,
        canvasIndicatorDragDirections: [],
        canContainChildren: true,
        styleElements: listStyleElements,
      },
    },
  },
} satisfies ComponentConfig;

export default config;

import type { BorderProps } from "@editor/components/common/icons/Border";
import * as React from "react";

// Note (Yuxin) Converted via https://react-svgr.com/playground/
// https://thenounproject.com/term/corners/185187/
const BorderLeft = (props: BorderProps) => {
  return (
    <svg
      {...props}
      width={props.size}
      height={props.size}
      className={`${props.className}`}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" rx="1" fill="currentColor" />
      <mask id="path-2-inside-1" fill="white">
        <rect width="2.25" height="18" rx="1" />
      </mask>
      <rect
        width="2.25"
        height="18"
        rx="1"
        fill="black"
        stroke="black"
        strokeWidth="2.25"
        mask="url(#path-2-inside-1)"
      />
    </svg>
  );
};

export default BorderLeft;

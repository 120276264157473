import Button from "@common/designSystem/Button";
import Modal from "@common/designSystem/Modal";
import ResponsiveToggle from "@common/designSystem/ResponsiveToggle";
import Switch from "@common/designSystem/Switch";
import { ModalLayout } from "@common/ModalLayout";
import { elementTypeToEditorData } from "@components/editor/element";
import { Loader } from "@components/marketplace/Loader";
import { getMarkdownComponents } from "@components/marketplace/TemplateCollectionsPageModal";
import toast from "@editor/components/common/designSystem/Toast";
import ProjectItem from "@editor/components/dashboard/projects/ProjectItem";
import BackButton from "@editor/components/marketplace/BackButton";
import { useWorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import useOnSubmitTemplateAction from "@editor/hooks/useOnSubmitTemplateAction";
import {
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftElementId,
  selectIsShopifyIntegrationEnabled,
} from "@editor/reducers/core-reducer";
import { setLastMarketplacePath } from "@editor/reducers/marketplace-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import type { ComponentTemplate } from "@editor/types/component-template";
import { generateEditorPathname, routes } from "@editor/utils/router";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import * as React from "react";
import { BsArrowRight } from "react-icons/bs";
import ReactMarkdown from "react-markdown";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { SHOPIFY_APP_LISTING_URL } from "replo-runtime/shared/config";
import type { MarketplaceModalRequestType } from "replo-runtime/shared/types";
import type { ReploElementType } from "schemas/element";
import type { ReploProject } from "schemas/project";

import type { TemplateDetailsModalProps } from "../AppModalTypes";

type UseTemplateDetailsSwitchProps = {
  onChange(shouldUseStoreDesignSystem: boolean): void;
};

export const TemplateDetailsModal: React.FC<TemplateDetailsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [shouldUseStoreDesignSystem, setShouldUseStoreDesignSystem] =
    React.useState<boolean>(false);
  const { state } = useLocation();
  const modalRequestType: MarketplaceModalRequestType =
    state?.marketplaceModalRequestType ?? "browse";
  const projectId = useCurrentProjectId();
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const navigate = useNavigate();
  const params = useParams();
  const draftElementId = useEditorSelector(selectDraftElementId);
  const templateId = params?.templateId;
  const elementType = state?.elementType as ReploElementType;

  const { data: componentTemplate, isLoading } =
    trpc.componentTemplates.get.useQuery(
      templateId
        ? {
            templateId,
          }
        : skipToken,
    );

  const handleClose = () => {
    if (modalRequestType === "share") {
      return navigate("/home");
    }
    onClose({ projectId: projectId ?? "", draftElementId });
  };
  const { onSubmit: onSubmitTemplateAction, isLoadingSubmitTemplate } =
    useOnSubmitTemplateAction();

  const onSubmit = async () => {
    if (componentTemplate) {
      await onSubmitTemplateAction(
        componentTemplate,
        elementType,
        projectId && shouldUseStoreDesignSystem
          ? { shouldUseStoreDesignSystem: true, projectId }
          : null,
      );
      onClose({ projectId: projectId ?? "", draftElementId });
    }
  };

  const getComponentTemplateLabel = () => {
    const getDisplayName = (
      defaultType: ReploElementType,
      type?: ReploElementType,
    ) => {
      if (type === "shopifySection") {
        return "Shopify Section";
      }

      return (
        elementTypeToEditorData[
          type ?? defaultType ?? componentTemplate?.type ?? "page"
        ]?.singularDisplayName ?? "Shopify Section"
      );
    };

    if (modalRequestType === "create") {
      return `Create New ${getDisplayName(elementType)}`;
    } else if (modalRequestType === "share") {
      return "Use";
    }
    return `Add to ${getDisplayName(elementType, draftElement?.type)}`;
  };

  const shouldShowBackButton = modalRequestType !== "share";

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} className="w-auto p-0">
      <ModalLayout
        height="90vh"
        width="85vw"
        wrapperClassnames="rounded bg-stone-50"
        layoutClassnames="p-0"
        mainContentClassnames="mb-0"
        mainContent={() => (
          <div className="flex w-full flex-col items-stretch bg-stone-100">
            {shouldShowBackButton && !isLoading && (
              <BackButton
                collectionName={componentTemplate?.collection?.name}
                classname="absolute top-10 left-10 z-10"
              />
            )}
            {isLoading && <Loader label="Loading Template" />}
            {!isLoading && componentTemplate && (
              <TemplateDetailsModalContent
                buttonLabel={getComponentTemplateLabel()}
                componentTemplate={componentTemplate}
                onSubmit={() => void onSubmit()}
                marketplaceModalRequestType={modalRequestType}
                shouldUseStoreDesignSystem={shouldUseStoreDesignSystem}
                setShouldUseStoreDesignSystem={setShouldUseStoreDesignSystem}
                isLoadingSubmitTemplate={isLoadingSubmitTemplate}
                isDetailsRoute={location.pathname.includes("/home/details")}
              />
            )}
          </div>
        )}
      />
    </Modal>
  );
};

const TemplateDetailsModalContent: React.FC<{
  componentTemplate: ComponentTemplate;
  onSubmit(): void;
  buttonLabel: string;
  marketplaceModalRequestType?: MarketplaceModalRequestType;
  setShouldUseStoreDesignSystem: React.Dispatch<React.SetStateAction<boolean>>;
  shouldUseStoreDesignSystem: boolean;
  isLoadingSubmitTemplate: boolean;
  isDetailsRoute: boolean;
}> = ({
  componentTemplate,
  onSubmit,
  buttonLabel,
  marketplaceModalRequestType,
  setShouldUseStoreDesignSystem,
  shouldUseStoreDesignSystem,
  isLoadingSubmitTemplate,
  isDetailsRoute,
}) => {
  const [selectedDevice, setSelectedDevice] = React.useState<
    "mobile" | "desktop"
  >("desktop");
  const [isCopied, setIsCopied] = React.useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const { state, pathname } = useLocation();
  const modal = useModal();
  const dispatch = useEditorDispatch();
  const analytics = useLogAnalytics();

  const shouldShowActionButtons = marketplaceModalRequestType !== "share";

  const onCopyUrl = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsCopied(true);
    const baseUrl = `https://dashboard.replo.app/?templateId=${componentTemplate?.id}`;
    void navigator.clipboard.writeText(baseUrl);
    analytics("editor.componentTemplate.share", {
      shareUrl: baseUrl,
      componentTemplateId: componentTemplate?.id,
      componentTemplateName: componentTemplate?.name,
      from: "componentTemplateDetails",
    });
    toast({
      header: "Template Link Copied",
      message: "The template link has been copied to your clipboard.",
    });
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const onOrganizationNameClick = () => {
    const newState = {
      ...state,
      componentTemplateName: componentTemplate?.name,
      fromPath: pathname,
    };
    dispatch(setLastMarketplacePath(pathname));

    modal.closeModal({});

    navigate(
      generateEditorPathname(routes.marketplaceModalCollection, {
        projectId: params.projectId ?? "",
        elementId: params.elementId,
        collectionId: componentTemplate?.collection?.id,
      }),
      {
        state: newState,
      },
    );
  };

  const shouldShowProjectList = marketplaceModalRequestType === "share";
  const shouldCenterOnInit = componentTemplate.type !== "page";

  return (
    <div className="flex w-full h-full">
      <div className="group relative flex w-full cursor-grab justify-center bg-stone-200 active:cursor-grabbing">
        <TransformWrapper
          limitToBounds
          maxScale={2}
          panning={{ lockAxisX: true }}
          wheel={{ disabled: true }}
          centerOnInit={shouldCenterOnInit}
        >
          <TransformComponent
            wrapperStyle={{
              height: "100%",
            }}
          >
            <div className="pt-20 p-8 px-24">
              <img
                src={componentTemplate?.desktopPreviewUrl}
                width={600}
                height={600}
                className={classNames(
                  "h-full object-cover object-top lg:w-full shadow-lg",
                  { hidden: selectedDevice === "mobile" },
                )}
              />
              <img
                src={componentTemplate?.mobilePreviewUrl}
                width={600}
                height={600}
                className={classNames(
                  "h-full object-cover object-top lg:w-full shadow-lg",
                  { hidden: selectedDevice === "desktop" },
                )}
              />
            </div>
          </TransformComponent>
        </TransformWrapper>
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
          <ResponsiveToggle
            setValue={setSelectedDevice}
            value={selectedDevice}
          />
        </div>
      </div>
      <div className="flex w-full flex-col px-8 pt-20 pb-5 justify-between bg-stone-100">
        <div className="flex flex-col gap-10 overflow-y-hidden">
          <div className="flex items-center gap-4">
            {componentTemplate?.collection?.profileImageUrl && (
              <img
                className="h-12 w-12 rounded-full object-cover"
                src={componentTemplate?.collection?.profileImageUrl}
                alt={componentTemplate?.collection?.name}
              />
            )}
            <div className="flex flex-1 items-center justify-between lg:gap-4 xl:gap-6">
              <div className="flex flex-col">
                <p className="font-semibold text-xl">
                  {componentTemplate?.name}
                </p>
                <p
                  className={classNames("text-blue-600", {
                    "cursor-pointer": marketplaceModalRequestType !== "share",
                  })}
                  onClick={
                    marketplaceModalRequestType !== "share"
                      ? onOrganizationNameClick
                      : undefined
                  }
                >
                  {componentTemplate?.collection?.name}
                </p>
              </div>
              {shouldShowActionButtons && (
                <div className="flex flex-col gap-4">
                  <div className="flex gap-2 xl:gap-6 justify-between">
                    <Button
                      type="secondary"
                      size="base"
                      className="bg-slate-200 bg-opacity-50 text-black px-2"
                      textClassNames="font-medium"
                      onClick={onCopyUrl}
                      isRounded
                      hasMinDimensions={false}
                    >
                      {isCopied ? "Copied!" : "Copy Share URL"}
                    </Button>
                    <Button
                      type="primary"
                      size="base"
                      textClassNames="font-medium"
                      onClick={onSubmit}
                      isRounded
                      isLoading={isLoadingSubmitTemplate}
                    >
                      {buttonLabel}
                    </Button>
                  </div>
                  <label className="flex gap-2 pt-2 items-center text-sm font-semibold self-end">
                    Use Template Defaults
                    <UseTemplateDefaultsSwitch
                      onChange={setShouldUseStoreDesignSystem}
                    />
                  </label>
                </div>
              )}
            </div>
          </div>
          {componentTemplate?.collection?.descriptionMarkdown && (
            <div className="flex flex-col gap-2">
              <div className="text-lg font-semibold">About This Collection</div>
              <ReactMarkdown
                components={getMarkdownComponents}
                className="text-default"
              >
                {componentTemplate?.collection.descriptionMarkdown}
              </ReactMarkdown>
            </div>
          )}
          {shouldShowProjectList && (
            <Memberships
              componentTemplate={componentTemplate}
              shouldUseStoreDesignSystem={shouldUseStoreDesignSystem}
              isDetailsRoute={isDetailsRoute}
            />
          )}
        </div>
        {!shouldShowActionButtons && (
          <label className="text-sm items-center pt-2 flex gap-4 font-semibold">
            Use Template Defaults
            <UseTemplateDefaultsSwitch
              onChange={setShouldUseStoreDesignSystem}
            />
          </label>
        )}
      </div>
    </div>
  );
};

function UseTemplateDefaultsSwitch({
  onChange,
}: UseTemplateDetailsSwitchProps) {
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );

  return (
    <Switch
      backgroundOnColor="bg-blue-600"
      tooltipText={
        !isShopifyIntegrationEnabled
          ? "Add Shopify as an integration to use template defaults"
          : undefined
      }
      isDisabled={!isShopifyIntegrationEnabled}
      onChange={isShopifyIntegrationEnabled ? onChange : undefined}
    />
  );
}

const Memberships: React.FC<{
  componentTemplate: ComponentTemplate;
  shouldUseStoreDesignSystem: boolean;
  isDetailsRoute: boolean;
}> = ({ componentTemplate, shouldUseStoreDesignSystem, isDetailsRoute }) => {
  const { user, isAuthenticated } = useCurrentUser();
  const { data: allProjectsData } = trpc.project.findByUserId.useQuery(
    isAuthenticated && user?.id ? user.id : skipToken,
  );

  const getTemplateById = trpcUtils.componentTemplates.get.fetch;

  const modal = useModal();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { onSubmit: onSubmitTemplateAction } = useOnSubmitTemplateAction();

  const onClickProjectMembership = async (projectId: ReploProject["id"]) => {
    const template = await getTemplateById({
      templateId: componentTemplate.id,
      storeId: projectId,
      shouldUseStoreDesignSystem,
    });
    const elementType = state?.elementType as ReploElementType;

    if (!template) {
      toast({
        header: "Error using the template",
        message:
          "An error occurred while trying to use the template. Please try again or contact support if the issue persists.",
      });
      return;
    }
    modal.closeModal({});
    await onSubmitTemplateAction(componentTemplate, elementType, {
      shouldUseStoreDesignSystem,
      projectId,
    });

    navigate(
      {
        pathname: generateEditorPathname("", {
          projectId: projectId ?? "",
        }),
      },
      {
        state: {
          ...state,
          shouldUseStoreDesignSystem,
        },
      },
    );

    modal.openModal({
      type: "createElementModal",
      props: {
        initialElementType:
          componentTemplate.type === "page" ? "page" : "shopifySection",
        initialTemplate: template,
      },
    });
  };

  const projects = allProjectsData?.allProjects;
  const hasProjectMembership = projects && projects.length > 0;

  if (allProjectsData?.allProjects && hasProjectMembership) {
    return (
      <ProjectMembershipList
        projects={allProjectsData.allProjects}
        onClick={(projectId) => void onClickProjectMembership(projectId)}
        isDetailsRoute={isDetailsRoute}
      />
    );
  }

  return <EmptyProjectMembershipList />;
};

const ProjectMembershipList: React.FC<{
  projects: ReploProject[];
  onClick(projectId: ReploProject["id"]): void;
  isDetailsRoute: boolean;
}> = ({ projects, onClick, isDetailsRoute }) => {
  const { setWorkspaceId } = useWorkspaceDashboardContext();
  const params = useParams();

  return (
    <div className="flex flex-col gap-4 overflow-hidden">
      <p className="text-xl font-semibold">
        Where would you like to try it out?
      </p>
      <div className="flex flex-col border-y-2 border-slate-200 py-2 overflow-scroll no-scrollbar">
        {projects.map((project) => (
          <ProjectItem
            key={project.id}
            project={project}
            shouldShowCreatedAt={false}
            className="p-4 rounded-lg hover:bg-slate-200"
            onSelectProject={() => {
              setWorkspaceId(
                project.ownerWorkspace?.id ?? params.workspaceId ?? null,
              );
              onClick(project.id);
            }}
            hideEllipsisButton={isDetailsRoute}
          />
        ))}
      </div>
    </div>
  );
};

const EmptyProjectMembershipList: React.FC = () => {
  return (
    <div className="flex flex-col gap-4">
      <p className="text-xl font-semibold">
        Connect your Shopify store to try it out
      </p>
      <hr className="bg-slate-200 h-0.5" />
      <Button
        size="lg"
        className="group"
        onClick={() => window?.open(SHOPIFY_APP_LISTING_URL)}
        type="primary"
        isFullWidth
      >
        <div className="flex flex-row items-center gap-2">
          Connect with Shopify
          <BsArrowRight
            size={16}
            color="white"
            className="group-hover:translate-x-0.5"
          />
        </div>
      </Button>
    </div>
  );
};

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

const product: ComponentTemplate = {
  id: "d2a3dcab-afdb-43e8-b83f-6a66e5a955cb",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Product",
  leftBarCategory: { main: "product", subCategory: "productLayouts" },
  preview: "product/single-product.png",
  template: {
    id: "$uid",
    name: "Product 2",
    type: "product",
    props: {
      style: {
        display: "flex",
        __flexGap: "36px",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
      },
      "style@sm": {
        display: "flex",
        flexDirection: "column",
        __flexGap: "0px",
      },
    },
    children: [
      {
        id: "$uid",
        name: "Image 32",
        type: "image",
        props: {
          style: {
            height: "500px",
            __width: "500px",
            objectFit: "contain",
            flexBasis: 0,
            width: "500px",
            __height: "500px",
            __imageSource: "{{attributes._product.featured_image}}",
          },
          "style@sm": {
            flexGrow: 1,
            flexBasis: "auto",
            objectFit: "contain",
            alignSelf: "stretch",
            __width: "362px",
            width: "auto",
          },
          "style@md": {
            height: "500px",
            __height: "500px",
            flexGrow: "unset",
            flexBasis: 0,
            __width: "362px",
            objectFit: "contain",
            width: "362px",
          },
        },
      },
      {
        id: "$uid",
        name: "Container 381",
        type: "container",
        props: {
          style: {
            display: "flex",
            maxWidth: "800px",
            __flexGap: "24px",
            alignSelf: "stretch",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "center",
            flexGrow: "1",
            flexBasis: 0,
            paddingTop: "24px",
            paddingBottom: "24px",
            paddingLeft: "24px",
            paddingRight: "24px",
            color: "#000000FF",
          },
          "style@sm": {
            flexGrow: 1,
            flexBasis: "auto",
          },
          "style@md": {
            flexGrow: "1",
            flexBasis: 0,
            maxWidth: "auto",
          },
        },
        children: [
          {
            id: "$uid",
            type: "text",
            props: {
              text: "<p>{{attributes._product.title}}</p>",
              style: {
                fontSize: "48px",
                fontWeight: "400",
                lineHeight: "48px",
              },
            },
          },
          {
            id: "$uid",
            type: "text",
            props: {
              text: "{{attributes._product.description}}",
              style: {
                fontSize: "20px",
                lineHeight: "24px",
              },
            },
          },
          {
            id: "$uid",
            type: "text",
            props: {
              text: "<p>{{attributes._variant.displayPrice}}</p>",
              style: {
                fontSize: "32px",
                fontWeight: 300,
                lineHeight: "48px",
              },
            },
          },
          {
            id: "$uid",
            type: "button",
            children: [
              {
                id: "4f7fc3a8-6cb1-4cc2-8de2-5e9e119c2b67",
                type: "text",
                props: {
                  text: "<p>Add To Cart</p>",
                  style: {
                    letterSpacing: "2px",
                    color: "#FFFFFF",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  },
                },
              },
            ],
            props: {
              style: {
                color: "#FFFFFF",
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "20px",
                paddingTop: "20px",
                paddingLeft: "32px",
                paddingRight: "32px",
                letterSpacing: "2px",
                paddingBottom: "20px",
                backgroundColor: "#000000FF",
              },
              onClick: [
                {
                  id: "$uid",
                  type: "addProductVariantToCart",
                  value: {
                    product: {
                      ref: "attributes._variant",
                      type: "contextRef",
                    },
                    redirectToCart: true,
                    redirectToCheckout: false,
                  },
                },
              ],
            },
          },
        ],
      },
    ],
  },
};

export default product;

import { savePendingElements } from "@actions/core-actions";
import { API_ACTIONS } from "@constants/action-types";
import type { CoreState } from "@editor/types/core-state";
import type { Middleware } from "redux";

const autosaveMiddleware: Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (
      action.type === API_ACTIONS.CREATE_OR_UPDATE_ELEMENT.success ||
      // Note (Noah, 2022-08-16): Even if we get back an error, try to save
      // the pending element update if there is one. This avoids the case where
      // the network times out and the user happens to refresh the page before
      // making another edit, and loses changes.
      action.type === API_ACTIONS.CREATE_OR_UPDATE_ELEMENT.error
    ) {
      // Note (Noah, 2022-01-28): In the case of making many updates at once,
      // we save a pending state to make sure only one request is ever in flight
      // - if we don't wait for the redux state to be updated before firing off
      // another request for the pending update, the pending update will get sent
      // with an element that has its numeric version off by one (which will get
      // rejected by the backend). We always autosave the pending element after
      // redux has been updated with the new element version to avoid this.
      const result = next(action);
      const coreState: CoreState = getState().core;
      // If there are still pending elements, kick off another request
      if (coreState.pendingElementUpdates.length > 0) {
        // @ts-ignore
        void dispatch(savePendingElements());
      }
      return result;
    }
    return next(action);
  };

export default autosaveMiddleware;

import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _seconds: {
      name: "Seconds per rotation",
      type: "seconds",
      description:
        "Number of seconds it takes the marquee to slide past one repetition of its components",
      defaultValue: "10s",
    },
    _rightDirection: {
      name: "Scroll to the right",
      type: "boolean",
      description: "If enabled, the ticker will scroll to the right.",
      defaultValue: false,
    },
    /**
     * Note (Noah, 2023-06-14, USE-203): When the marquee component was first
     * designed we had it duplicate its children 20x so that it could scroll
     * infinitely and look "correct" even with a small number of child
     * components. However, this causes massive performance problems. For most
     * marquee components, the correct answer to this should be to just
     * duplicate the child components enough times that they cover the whole
     * screen, but updating this might break existing marquees, so instead of
     * having a MarqueeV2, we have this internal prop which allows opting into
     * the new, more performant behavior when you add a new template.
     *
     * As long as all our templates have this internal prop set, all new marquee
     * components shouldn't have this perf issue.
     */
    _internalUseRepetition: {
      name: "[Admin Only] Use default repetition",
      type: "boolean",
      description:
        "Internal use only. If you're seeing this, contact support@replo.app.",
      defaultValue: true,
      shouldDisplay: ({ userIsSuperuser }) => userIsSuperuser,
    },
  };
}

export const customProperties = {
  marqueeRepetitions: "--replo-marquee-repetitions",
};

export const styleElements = {
  root: {
    defaultStyles: {
      overflowX: "hidden",
      [customProperties.marqueeRepetitions]: String(2),
    },
    overrideStyles({ component }) {
      const useRepetition = component.props._internalUseRepetition ?? true;
      const numberOfRepetitions = useRepetition ? 20 : 2;
      return {
        [customProperties.marqueeRepetitions]: String(numberOfRepetitions),
      };
    },
    childrenOverrideStyles: {
      flexShrink: 0,
      whiteSpace: "normal",
      // Note (Noah, 2023-11-09, USE-402): For some reason that only god and
      // Tim Cook know, Safari has issues with translating elements which have
      // images inside them. Setting this transform causes Safari to not
      // flicker when resetting the transform animation that we use to animate
      // the marquee. See e.g.
      // https://stackoverflow.com/questions/25010353/safari-css-transition-flickering,
      // https://developer.apple.com/forums/thread/705172,
      // https://github.com/nolimits4web/swiper/issues/3527, etc
      WebkitTransform: "translateZ(0)",
    },
  },
  inner: {
    defaultStyles: {
      display: "flex",
      flexDirection: "row",
      whiteSpace: "nowrap",
      willChange: "transform",
      overflow: "visible",
      flexShrink: 0,
      width: "auto",
    },
    overrideStyles({ component }) {
      const seconds = component.props._seconds;
      const rightDirection = component.props._rightDirection;

      return {
        animationName: "replo-marquee",
        animationDuration: seconds,
        animationTimingFunction: "linear",
        animationIterationCount: "infinite",
        animationDirection: rightDirection ? "reverse" : undefined,
      };
    },
  },
} satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: true,
    customProps: convertToLegacyProps(getConfigurableProps()),
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: true,
    showComponentControlsFromChildren: true,
    styleElements,
    ancestorDisallowList: [
      {
        ancestorTypes: ["marquee"],
        message: "Tickers cannot be nested inside tickers.",
      },
    ],
  },
} satisfies ComponentConfig;

export default config;

import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import SelectableButtons from "@common/designSystem/SelectableButtons";
import Textarea from "@common/designSystem/Textarea";
import * as ProductSelectionPopover from "@components/editor/page/ProductSelectionPopover";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import type { Goal, PageTopic, Tone, Topic } from "@editor/reducers/ai-reducer";
import { imgOrPlaceholder } from "@editor/utils/image";
import classNames from "classnames";
import * as React from "react";
import { RiCloseFill } from "react-icons/ri";
import type { ProductRef } from "replo-runtime/shared/types";

const selectedClassName =
  "bg-gradient-to-r from-[#F46B45] to-[#EEA849] bg-orange-100 hover:bg-orange-100";

const unselectedClassName = "bg-slate-100 hover:bg-orange-100";
const unselectedToneClassName = (value: Tone) => {
  if (value === "other") {
    return "border-dashed border-2 -py-2 border-slate-100 bg-white text-slate-400 hover:bg-slate-100";
  }
  return "bg-slate-100 hover:bg-orange-100";
};

type TextTopicProps = {
  type: "text";
  topicOptions: { value: Topic; label: string; tooltip?: string }[];
  selectedTopic: Topic | null;
  setTopic: (topic: Topic | null) => void;
};

type PageTopicProps = {
  type: "page";
  topicOptions: { value: PageTopic; label: string; tooltip?: string }[];
  selectedTopic: PageTopic | null;
  setTopic: (topic: PageTopic | null) => void;
};

type AIModalContentProps = (TextTopicProps | PageTopicProps) & {
  toneOptions: { value: Tone; label: string }[];
  selectedTone: Tone | null;
  setTone: (tone: Tone | null) => void;
  customTone: string | null;
  setCustomTone: (customTone: string | null) => void;
  goalOptions: { value: Goal; label: string }[];
  selectedGoal: Goal | null;
  setGoal: (goal: Goal | null) => void;
  selectedProductRef: ProductRef | null;
  setSelectedProductRef: (productRef: ProductRef | null) => void;
  additionalContext: string | null;
  setAdditionalContext: (additionalContext: string | null) => void;
};

const AIModalContent: React.FC<AIModalContentProps> = ({
  type,
  topicOptions,
  selectedTopic,
  setTopic,
  toneOptions,
  selectedTone,
  setTone,
  customTone,
  setCustomTone,
  goalOptions,
  selectedGoal,
  setGoal,
  selectedProductRef,
  setSelectedProductRef,
  additionalContext,
  setAdditionalContext,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  return (
    <>
      <LabeledControl label="Topic">
        <SelectableButtons
          className="mt-3"
          multiSelect={false}
          options={topicOptions}
          selectedClassName={selectedClassName}
          unselectedClassName={unselectedClassName}
          value={selectedTopic ? [selectedTopic] : []}
          onChange={(topics) => {
            if (type === "text") {
              const newTopic = (topics[0] ?? null) as Topic | null;
              setTopic(newTopic);
            } else {
              const newTopic = (topics[0] ?? null) as PageTopic | null;
              setTopic(newTopic);
            }
          }}
        />
      </LabeledControl>
      <LabeledControl label="Choose a Tone">
        <SelectableButtons
          className="mt-3"
          multiSelect={false}
          options={toneOptions}
          selectedClassName={selectedClassName}
          unselectedClassName={unselectedToneClassName}
          value={selectedTone ? [selectedTone] : []}
          onChange={(tones) => {
            setTone(tones[0] ?? null);
          }}
        />
        {selectedTone === "other" && (
          <Input
            size="base"
            unsafe_className="mt-4 w-96"
            placeholder={`e.g. "In the style of Morgan Freeman"`}
            value={customTone ?? undefined}
            onChange={(e) => {
              setCustomTone(e.target.value);
            }}
          />
        )}
      </LabeledControl>
      <LabeledControl label="Choose a Goal">
        <SelectableButtons
          className="mt-3"
          multiSelect={false}
          options={goalOptions}
          selectedClassName="bg-gradient-to-r from-[#F46B45] to-[#EEA849] bg-orange-100 hover:bg-orange-100"
          unselectedClassName="bg-slate-100 hover:bg-orange-100"
          value={selectedGoal ? [selectedGoal] : []}
          onChange={(goals) => {
            setGoal(goals[0] ?? null);
          }}
        />
      </LabeledControl>
      <LabeledControl label="Choose a Product">
        <ProductSelectionPopover.Root
          value={selectedProductRef ? [selectedProductRef] : undefined}
          productRequestType="anyProducts"
          onSubmit={(value) => {
            setSelectedProductRef(value);
            setIsPopoverOpen(false);
          }}
          isControlledOpen={isPopoverOpen}
          onOpenChangeCallback={setIsPopoverOpen}
          className="mt-3 w-fit"
        >
          <ProductSelectionPopover.Content />
          <ProductSelectionPopover.Trigger
            render={({ label, onClearSelection, selectedProducts }) => (
              <SelectionIndicator
                className={classNames(
                  "h-8 w-full cursor-pointer rounded border border-dashed border-[#F46B45] bg-white pl-2 pr-4",
                  {
                    "border-0 bg-orange-100 bg-gradient-to-r from-[#F46B45] to-[#EEA849] pl-[10px] pr-1":
                      selectedProducts.length > 0,
                  },
                )}
                startEnhancer={imgOrPlaceholder(
                  selectedProducts[0]?.featuredImage,
                  "rounded-[2px] h-4 w-4 object-contain",
                  "bg-gray-200",
                )}
                endEnhancer={
                  selectedProducts.length === 0 ? null : (
                    <RiCloseFill
                      size={12}
                      className={classNames(
                        "mr-2 ml-2 cursor-pointer text-white",
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClearSelection?.();
                      }}
                    />
                  )
                }
                title={selectedProducts.length > 0 ? label : undefined}
                placeholder={selectedProducts.length === 0 ? label : undefined}
                labelClassname={classNames(
                  "text-sm font-medium ml-[2px] flex grow bg-gradient-to-r from-[#F46B45] to-[#EEA849] bg-clip-text font-bold text-transparent",
                  { "text-white": selectedProducts.length > 0 },
                )}
              />
            )}
          />
        </ProductSelectionPopover.Root>
      </LabeledControl>
      <LabeledControl label="Additional Context">
        <Textarea
          debounce
          className="mt-2 mb-8 h-24 w-1/2 border-none text-sm transition-none focus:ring-transparent"
          value={additionalContext ?? undefined}
          onChange={(value) => {
            setAdditionalContext(value);
          }}
          placeholder="Info about your brand, etc."
          maxLength={500}
        />
      </LabeledControl>
      <div className="text-xs text-gray-400">
        By using Replo AI, you agree that the data provided in this form will be
        sent to OpenAI and is subject to their{" "}
        <a
          className="underline"
          href="https://openai.com/policies/terms-of-use"
        >
          Terms of Use
        </a>
        . Replo and Replo AI do not guarantee any results, conversion rates, or
        sales.
      </div>
    </>
  );
};

export default AIModalContent;

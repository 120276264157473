import { HotkeyIndicator } from "@common/HotkeyIndicator";
import Input from "@editor/components/common/designSystem/Input";
import Popover from "@editor/components/common/designSystem/Popover";
import Switch from "@editor/components/common/designSystem/Switch";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@editor/components/common/designSystem/Tabs";
import Textarea from "@editor/components/common/designSystem/Textarea";
import { infoToast, toast } from "@editor/components/common/designSystem/Toast";
import useGetStoreNameAndUrl from "@editor/hooks/useGetStoreNameAndUrl";
import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { analytics } from "@editor/infra/analytics";
import type { AIStreamingOperation } from "@editor/providers/AIStreamingProvider";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  selectDraftComponentId,
  selectDraftComponentOrDescendantMeetsCondition,
  selectLoadableProject,
  selectRootComponentId,
  selectStreamingUpdateId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import * as Progress from "@radix-ui/react-progress";
import { Button } from "@replo/design-system/components/button";
import { Spinner } from "@replo/design-system/components/spinner";
import classNames from "classnames";
import * as React from "react";
import {
  BsArrowReturnLeft,
  BsArrowsCollapse,
  BsCheckCircleFill,
  BsGlobe,
  BsHandThumbsDownFill,
  BsHandThumbsUpFill,
  BsMagic,
  BsPencilSquare,
  BsPhone,
} from "react-icons/bs";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { aiProjectContextSchema } from "schemas/ai";

const AI_POPOVER_ALIGN_OFFSET = -20;

const AIMenuWrapper: React.FC<{
  controlsWidth: number;
  isDisabled: boolean;
}> = ({ isDisabled, controlsWidth }) => {
  const {
    isMenuOpen,
    isTextSelected,
    setIsMenuOpen: setMenuOpen,
    status,
  } = useAIStreaming();

  const isDisplayingGenerationBar = [
    "generationInitialized",
    "generating",
    "finishedGenerating",
  ].includes(status);

  return (
    <Popover
      isOpen={isMenuOpen}
      onOpenChange={(val) => setMenuOpen(val, "previewMenu")}
    >
      <Popover.Trigger asChild>
        <Button
          id="ai-button"
          variant="no-style"
          icon={
            <BsMagic size={20} className="text-orange-600 relative -top-0.5" />
          }
          className={classNames(
            "text-slate-400 p-2 rounded-md",
            !isDisabled && "hover:bg-slate-100",
          )}
          tooltipCustomContent={
            isMenuOpen ? null : <HotkeyIndicator hotkey="toggleAIMenu" />
          }
          isDisabled={isDisabled}
          autoFocus={false}
        />
      </Popover.Trigger>

      <Popover.Content
        // Note (Evan, 2024-07-19): i.e., if generation has already started, don't
        // close the popover on outside interaction
        shouldPreventDefaultOnInteractOutside={
          status !== "preGeneration" || isTextSelected
        }
        side="top"
        style={{
          width:
            isDisplayingGenerationBar || isTextSelected
              ? controlsWidth
              : undefined,
        }}
        sideOffset={16}
        className={classNames(
          "w-unset h-unset py-2 font-normal rounded overflow-hidden",

          isDisplayingGenerationBar && "p-0",
        )}
        hideCloseButton
        stayInPosition
        disableTriggerFocusOnClose
        align="end"
        alignOffset={AI_POPOVER_ALIGN_OFFSET}
      >
        <AIMenu />
      </Popover.Content>
    </Popover>
  );
};

type AIMenuOption = {
  key: AIStreamingOperation;
  title: string;
  subtitle?: string;
  icon: React.ReactNode;
  shortcut: string;
};

const menuOptions: AIMenuOption[] = [
  {
    key: "textV2",
    title: "Update Text",
    subtitle: "Rewrite, translate, shorten",
    icon: <BsPencilSquare size={12} />,
    shortcut: "t",
  },
  {
    key: "mobileResponsive",
    title: "Optimize for Mobile",
    subtitle: "Update responsiveness",
    icon: <BsPhone size={12} />,
    shortcut: "r",
  },
];

const AIMenu: React.FC = () => {
  const { menuState, isTextSelected, status } = useAIStreaming();

  if (status === "generating" || status === "generationInitialized") {
    return <AIMenuGenerating />;
  }

  if (status === "finishedGenerating") {
    return <AIMenuFinishedGenerating />;
  }

  if (isTextSelected) {
    return <AITextInterface />;
  }
  if (menuState === "template") {
    return <AITemplateMenu />;
  }
  return <AIMenuSelect />;
};

const AIMenuSelect: React.FC<{}> = () => {
  const draftComponentId = useEditorSelector(selectDraftComponentId);

  const { setMenuState, initiateGeneration } = useAIStreaming();
  const handleSelect = (key: AIStreamingOperation) => {
    exhaustiveSwitch({ type: key })({
      textV2: () => {
        setMenuState("text");
      },
      mobileResponsive: () => {
        if (!draftComponentId) {
          infoToast(
            "No component selected",
            "To optimize for mobile, please select a component.",
          );
          return;
        }
        void initiateGeneration({ type: "mobileResponsive" });
      },
    });
  };

  // TODO (Gabe 2024-08-12): Update once we have a better way to define one of
  // hotkeys.
  useReploHotkeys({
    selectAIText: () => handleSelect("textV2"),
    selectAIMobileResponsive: () => handleSelect("mobileResponsive"),
  });
  return (
    <div className="w-[240px] overflow-hidden flex flex-col text-xs">
      <div>Replo AI</div>
      <div className="flex flex-col pt-1">
        {menuOptions.map((menuOption, index) => (
          <AIMenuEntry
            {...menuOption}
            key={menuOption.key}
            autoFocus={index === 0}
            onSelect={() => handleSelect(menuOption.key)}
          />
        ))}
      </div>
    </div>
  );
};

const AIMenuEntry: React.FC<
  AIMenuOption & {
    autoFocus?: boolean;
    onSelect: () => void;
  }
> = ({ title, subtitle, icon, autoFocus, onSelect, shortcut }) => {
  return (
    <Button
      onClick={onSelect}
      // Note (Evan, 2024-07-19): Without this, there will be a flash when clicking where
      // first the focus shifts on mouse down (causing the styles to change), but the
      // click handler only runs on mouse up.
      onMouseDown={(event) => {
        event.preventDefault();
      }}
      variant="no-style"
      className="group focus:bg-slate-100 hover:bg-slate-50 focus:outline-none w-full py-2 rounded"
      onKeyDown={(event) => {
        const { key } = event;
        if (key === "ArrowUp") {
          event.preventDefault();
          event.stopPropagation();
          const previous = event.currentTarget
            .previousElementSibling as HTMLElement | null;
          previous?.focus();
        }
        if (key === "ArrowDown") {
          event.preventDefault();
          event.stopPropagation();
          const next = event.currentTarget
            .nextElementSibling as HTMLElement | null;
          next?.focus();
        }
      }}
      autoFocus={autoFocus}
    >
      <div className="flex flex-row px-2 text-slate-600 justify-between">
        <div className="flex flex-row gap-2 items-center">
          {icon}
          <div className="flex flex-col items-start">
            <span className="text-black">{title}</span>
            <span className="text-[10px]">{subtitle}</span>
          </div>
        </div>
        <div className="flex items-center">
          <div className="bg-slate-200  flex gap-1 rounded items-center py-1 px-1.5 text-[10px]">
            <span>{shortcut.toUpperCase()}</span>
          </div>
        </div>
      </div>
    </Button>
  );
};

const useAIProjectContext = () => {
  const { project } = useEditorSelector(selectLoadableProject);

  // Note (Evan, 2024-06-12): Initialize the existing site URL to the value from Redux,
  // but allow it to be updated
  const { storeUrl: storeUrlFromRedux } = useGetStoreNameAndUrl();
  return useLocalStorageState(
    project ? `replo.ai.context.${project.id}` : null,
    {
      existingSiteUrl: storeUrlFromRedux,
    },
    {
      schema: aiProjectContextSchema,
    },
  );
};

const AITemplateMenu: React.FC = () => {
  const [projectContext] = useAIProjectContext();

  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const rootComponentId = useEditorSelector(selectRootComponentId);
  const setDraftElement = useSetDraftElement();

  const {
    initiateGeneration,
    setMenuState,
    setIsMenuOpen: setMenuOpen,
  } = useAIStreaming();

  const handleConfirm = () => {
    if (!draftComponentId) {
      setDraftElement({ componentId: rootComponentId });
    }
    if (projectContext.whatBusinessSells || projectContext.whoIsCustomer) {
      void initiateGeneration({
        type: "textV2",
        userPrompt: "Rewrite text",
        projectContext,
      });
    } else {
      setMenuState("text.context");
    }
  };
  const handleDeny = () => {
    setMenuOpen(false);
  };
  useReploHotkeys({
    enter: handleConfirm,
    escape: handleDeny,
  });
  return (
    <div className="text-sm text-default flex flex-col gap-2 w-[196px]">
      <div className="font-semibold ">Update Template Text?</div>
      <div>Update your template with content tailored to your store.</div>
      <div className="flex flex-row gap-2 justify-end">
        <Button variant="secondary" onClick={handleDeny}>
          No <span className="text-slate-600 font-normal">ESC</span>
        </Button>
        <Button onClick={handleConfirm}>
          Yes <BsArrowReturnLeft />
        </Button>
      </div>
    </div>
  );
};

const AITextInterface: React.FC = () => {
  const {
    initiateGeneration,
    setIsMenuOpen: setMenuOpen,
    menuState,
  } = useAIStreaming();

  const initialTabIsContext = menuState.endsWith("context");
  const [projectContext, setProjectContext] = useAIProjectContext();

  const [prompt, setPrompt] = React.useState(
    initialTabIsContext ? "Rewrite " : "",
  );
  const setPromptAndFocus = (value: string) => {
    setPrompt(value);
    // TODO (Gabe 2024-07-29): For some reason the ref passed into Textarea is
    // not attaching.
  };

  const draftComponentId = useEditorSelector(selectDraftComponentId);

  const store = useEditorStore();

  const handleSubmit = () => {
    // NOTE (Gabe 2024-08-06): We only use this selector in the callback because
    // it's expensive. We don't want to run it on every render.
    const draftComponentOrDescendantIsText =
      selectDraftComponentOrDescendantMeetsCondition(store.getState(), (c) =>
        Boolean(
          c.type === "text" &&
            c.props.text &&
            c.props.text.includes("{{") === false,
        ),
      );

    if (!draftComponentId || !draftComponentOrDescendantIsText) {
      // Note (Evan, 2024-06-18): Show a toast if the modal can't be opened specifically
      // because no valid component is selected (i.e., don't show a modal if
      // a global modal is open)
      infoToast(
        "No text selected",
        "To generate AI copy, please select a component that contains (non-dynamic) text.",
      );
      return;
    }

    void initiateGeneration({
      type: "textV2",
      projectContext,
      userPrompt: prompt,
    });
  };

  const handleTextChange = (value: string) => {
    if (prompt !== "") {
      setPrompt(value);
    } else if (value === "0") {
      setPrompt("Translate to ");
    } else if (value === "1") {
      setPrompt("Rewrite ");
    } else if (value === "2") {
      setPrompt("Shorten ");
    } else {
      setPrompt(value);
    }
  };

  return (
    <div className="w-full">
      <Tabs
        defaultValue={initialTabIsContext ? "context" : "prompt"}
        className="flex flex-col gap-3"
      >
        <div>
          <TabsContent
            value="context"
            className="text-slate-600 text-xs flex flex-col gap-3 font-normal mt-0"
          >
            What you enter here will be saved in this project for next time
            <div className="flex flex-row items-center gap-3">
              <Switch
                backgroundOnColor="bg-blue-600"
                isOn={projectContext.useExistingSite}
                onChange={(value) =>
                  setProjectContext((existing) => ({
                    ...existing,
                    useExistingSite: value,
                  }))
                }
              />
              Match tone and voice from an existing site
            </div>
            {projectContext.useExistingSite && (
              <Input
                startEnhancer={() => "https://"}
                unsafe_inputClassName="pl-0"
                value={projectContext.existingSiteUrl}
                onChange={(e) =>
                  setProjectContext((existing) => ({
                    ...existing,
                    existingSiteUrl: e.target.value,
                  }))
                }
              />
            )}
            What does your business sell?
            <Textarea
              value={projectContext.whatBusinessSells}
              className="w-full"
              onChange={(value) =>
                setProjectContext((existing) => ({
                  ...existing,
                  whatBusinessSells: value,
                }))
              }
              placeholder="We sell handmade purses made from recycled concert tshirts"
            />
            Who do you sell to?
            <Textarea
              value={projectContext.whoIsCustomer}
              className="w-full"
              onChange={(value) =>
                setProjectContext((existing) => ({
                  ...existing,
                  whoIsCustomer: value,
                }))
              }
              placeholder="Our customers are usually Gen Z teenagers who love TikTok"
            />
          </TabsContent>
          <TabsContent
            value="prompt"
            className="flex flex-col pt-1 mt-0 relative"
          >
            <Textarea
              className="w-full pb-12 overflow-hidden text-sm"
              value={prompt}
              onChange={handleTextChange}
              onEnter={handleSubmit}
              placeholder="What copy do you want to write?"
              autoFocus
            />

            <div
              className={classNames(
                "absolute bottom-0 flex flex-row bg-slate-100 px-[10px] py-2 text-sm gap-2 rounded-b-md",
                "transition-opacity",
                {
                  "opacity-0 pointer-events-none": Boolean(prompt),
                  "opacity-100": !prompt,
                },
              )}
            >
              <TextSuggestionButton
                shortcut="0"
                onClick={() => setPromptAndFocus("Translate to ")}
              >
                <BsGlobe />
                Translate to
              </TextSuggestionButton>
              <TextSuggestionButton
                shortcut="1"
                onClick={() => setPromptAndFocus("Rewrite")}
              >
                <BsPencilSquare />
                Rewrite
              </TextSuggestionButton>
              <TextSuggestionButton
                shortcut="2"
                onClick={() => setPromptAndFocus("Shorten")}
              >
                <BsArrowsCollapse />
                Shorten
              </TextSuggestionButton>
            </div>
          </TabsContent>
        </div>
        <div className="flex flex-row items-center gap-2">
          <TabsList className="grow flex flex-row justify-start">
            <TabsTrigger value="prompt">Prompt</TabsTrigger>
            <TabsTrigger value="context">Context</TabsTrigger>
          </TabsList>
          <Button variant="secondary" onClick={() => setMenuOpen(false)}>
            Cancel
            <span className="text-slate-600 font-normal pl-1">ESC</span>
          </Button>
          <Button variant="primary" onClick={handleSubmit} isDisabled={!prompt}>
            Enter
            <BsArrowReturnLeft />
          </Button>
        </div>
      </Tabs>
    </div>
  );
};

// TODO (Gabe 2024-08-12): Update this to use the design system Button
const TextSuggestionButton: React.FC<
  React.PropsWithChildren<{
    onClick: () => void;
    shortcut: string;
  }>
> = ({ children, onClick, shortcut }) => {
  return (
    <Button variant="no-style" onClick={onClick}>
      <div className="flex flex-row gap-2 items-center border-dashed border-slate-200 border-2 px-2 py-1 rounded">
        {children}
        <div className="bg-white px-1 border-slate-200 border-1 rounded-sm">
          {shortcut}
        </div>
      </div>
    </Button>
  );
};

const AIMenuGenerating: React.FC = () => {
  const { abort, completionPercentage } = useAIStreaming();

  // TODO (Evan, 2024-07-22): This should be specific to whether or not actions have been received
  // (via useAIStatus()) and the type of generation (REPL-12785)
  const loadingText = "Generating...";

  useReploHotkeys({
    escape: abort,
  });

  return (
    <div className="h-12 overflow-hidden flex flex-col px-4 w-full">
      <div className="grow flex flex-row justify-between items-center mt-1">
        <div className="flex flex-row items-center gap-3">
          <Spinner size={16} width={2} color="#FB923C" speed={0.7} />
          <span className="text-sm text-slate-600 font-normal">
            {loadingText}
          </span>
        </div>
        <Button variant="secondary" size="sm" onClick={abort}>
          Cancel
          <span className="text-slate-600 font-normal pl-1">ESC</span>
        </Button>
      </div>
      <AIProgressIndicator completionPercentage={completionPercentage} />
    </div>
  );
};
const AIProgressIndicator: React.FC<{ completionPercentage?: number }> = ({
  completionPercentage = 0,
}) => {
  return (
    <Progress.Root value={completionPercentage} max={100} className="h-1 -mx-3">
      <Progress.Indicator
        className="bg-[#FB923C] h-1 transition-all"
        style={{
          transform: `translateX(-${100 - completionPercentage}%)`,
        }}
      />
    </Progress.Root>
  );
};

const AIMenuFinishedGenerating = () => {
  const { applyChanges, discardChanges, generationMode } = useAIStreaming();
  const [isApplyingChanges, setIsApplyingChanges] = React.useState(false);
  const handleApplyChanges = () => {
    setIsApplyingChanges(true);
    // HACK (Gabe 2024-08-13): Applying this many action at once has a tendency
    // to lock up rendering so we must do it in a setTimeout so that the isBusy
    // Button can be rendered.
    setTimeout(() => {
      applyChanges();
    }, 0);
  };
  useReploHotkeys({
    enter: handleApplyChanges,
    escape: discardChanges,
  });

  const streamingUpdateId = useEditorSelector(selectStreamingUpdateId);

  const [feedbackPopoverOpen, setFeedbackPopoverOpen] = React.useState(false);
  const [feedback, setFeedback] = React.useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false);

  const submitFeedback = (isPositive: boolean) => {
    setFeedbackPopoverOpen(false);
    if (streamingUpdateId && generationMode) {
      analytics.logEvent("ai.feedback.submitted", {
        feedback,
        isPositive,
        streamingUpdateId,
        generationType: generationMode,
      });
    }
    setFeedbackSubmitted(true);
    toast({
      header: "Feedback submitted",
      message: "Thank you!",
    });
  };

  return (
    <div className="text-sm flex flex-col px-3 w-full">
      {!feedbackSubmitted && (
        <div className="border-b-1 border-slate-100 px-6 py-2 -mx-4 text-slate-600 flex flex-row gap-2 items-center">
          <div className="flex-grow ">
            This feature is in beta. Is this what you expected?
          </div>
          <button onClick={() => submitFeedback(true)}>
            <BsHandThumbsUpFill
              size={14}
              className={classNames(
                "stroke-slate-600 text-white hover:text-slate-400 active:text-slate-600 stroke-1 overflow-visible",
              )}
            />
          </button>
          <Popover
            isOpen={feedbackPopoverOpen}
            onOpenChange={(val) => setFeedbackPopoverOpen(val)}
          >
            <Popover.Trigger asChild>
              <button>
                <BsHandThumbsDownFill
                  size={14}
                  className={classNames(
                    "stroke-slate-600 stroke-1 overflow-visible",
                    feedbackPopoverOpen
                      ? "text-slate-600"
                      : "text-white hover:text-slate-400 active:text-slate-600",
                  )}
                />
              </button>
            </Popover.Trigger>
            <Popover.Content
              side="top"
              sideOffset={16}
              align="end"
              alignOffset={AI_POPOVER_ALIGN_OFFSET}
              className="p-2 rounded font-normal text-sm w-[306px] flex flex-col items-end gap-3"
              hideCloseButton
              shouldPreventDefaultOnInteractOutside={false}
            >
              <Textarea
                value={feedback}
                placeholder="How could this output be improved?"
                onChange={(value) => setFeedback(value)}
                onEnter={() => submitFeedback(false)}
                className="w-full h-14"
                autoFocus
              />
              <Button
                variant="primary"
                size="sm"
                onClick={() => submitFeedback(false)}
              >
                Submit
                <BsArrowReturnLeft />
              </Button>
            </Popover.Content>
          </Popover>
        </div>
      )}
      <div className="flex flex-row justify-between items-center px-3 w-full py-2">
        <div className="flex flex-row items-center gap-3">
          <BsCheckCircleFill size={16} className="text-orange-600" />
          <span className="text-slate-600 font-medium">
            Generation Completed!
          </span>
        </div>
        <div className="flex flex-row gap-1.5">
          <Button variant="secondary" size="sm" onClick={discardChanges}>
            Cancel
            <span className="text-slate-600 font-normal pl-1">ESC</span>
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={handleApplyChanges}
            isBusy={isApplyingChanges}
          >
            Accept
            <BsArrowReturnLeft />
          </Button>
        </div>
      </div>
      <AIProgressIndicator completionPercentage={100} />
    </div>
  );
};

export default AIMenuWrapper;

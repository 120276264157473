import classNames from "classnames";
import * as React from "react";
import { BsMagic } from "react-icons/bs";
import { twMerge } from "tailwind-merge";

const AskAIHeader: React.FC<{
  whiteOnHover?: boolean;
  content: string;
  className?: string;
  endEnhancer?: React.ReactNode;
  showIcon?: boolean;
  isDisabled?: boolean;
}> = (props) => {
  return (
    <div className="flex flex-row gap-1">
      {props.showIcon && (
        <div
          className={twMerge(
            "flex items-center justify-center text-orange-600",
            classNames({ "text-gray-400": props.isDisabled }),
          )}
        >
          <BsMagic />
        </div>
      )}
      <div
        className={twMerge(
          classNames(
            "flex grow bg-gradient-to-r from-[#F46B45] to-[#EEA849] bg-clip-text font-bold text-transparent",
            { "hover:text-white": props.whiteOnHover },
            { "font-normal text-gray-400": props.isDisabled },
          ),
          props.className,
        )}
      >
        {props.content}
      </div>
      {props.endEnhancer}
    </div>
  );
};

export default AskAIHeader;

import Tooltip from "@editor/components/common/designSystem/Tooltip";
import * as React from "react";
import { BsInfoCircleFill } from "react-icons/bs";

type DocumentationType =
  | "background"
  | "border"
  | "spacing"
  | "positioning"
  | "visibility";

type ModifiersInfoProps = {
  documentationType: DocumentationType;
};

const buildDocumentationLink = (documentationType: DocumentationType) => {
  return `https://docs.replo.app/docs/editing/${documentationType}`;
};

const ModifiersInfo = ({ documentationType }: ModifiersInfoProps) => {
  return (
    <Tooltip content="Learn More" triggerAsChild>
      <a
        href={buildDocumentationLink(documentationType)}
        target="_blank"
        rel="noreferrer"
        className="text-xs text-slate-400 pr-1.5"
      >
        <span className="sr-only">Learn More</span>
        <BsInfoCircleFill aria-hidden />
      </a>
    </Tooltip>
  );
};

export default ModifiersInfo;

import type { DeleteSwatchConfirmationModalProps } from "@editor/components/AppModalTypes";
import DeleteConfirmationModal from "@editor/components/common/DeleteConfirmationModal";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useModal } from "@editor/hooks/useModal";
import { trpc } from "@editor/utils/trpc";
import * as React from "react";

export const DeleteSwatchConfirmationModal = ({
  swatchName,
  swatchId,
  onDelete,
}: DeleteSwatchConfirmationModalProps) => {
  const trpcUtils = trpc.useUtils();
  const modal = useModal();
  const projectId = useCurrentProjectId()!;

  const { mutateAsync: deleteSwatchAsync, status } =
    trpc.swatch.delete.useMutation({
      onSuccess: async () => {
        void trpcUtils.swatch.list.invalidate({ projectId });
      },
    });

  const handleDelete = () => {
    void deleteSwatchAsync({ swatchId, projectId });
    modal.closeModal({});
    onDelete?.();
  };

  return (
    <DeleteConfirmationModal
      onRequestClose={() =>
        modal.closeModal({ type: "deleteSwatchConfirmationModal" })
      }
      onDelete={handleDelete}
      assetTypeDisplayName="Swatch"
      assetName={swatchName}
      isLoading={status === "pending"}
    />
  );
};

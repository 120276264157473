import get from "lodash-es/get";
import type { ReploElementType } from "schemas/element";

export const getElementTypeEditorData = (t: ReploElementType): any => {
  return get(elementTypeToEditorData, t, null);
};

type ElementTypeEditorData = {
  displayName: string;

  /**
   * Render type of this page for rendering this element. This is used by canvas to
   * determine whether we need to re-request the page from mirror when editing this element.
   */
  pageRenderType: "fullPage" | "articleContent";
  singularDisplayName: string;
  templateSelectorDisplayName: string;
  creationDisplayName: string;
  path?: string;
};
export const elementTypeToEditorData: Record<
  ReploElementType,
  ElementTypeEditorData
> = {
  page: {
    displayName: "Shopify Pages",
    pageRenderType: "fullPage",
    singularDisplayName: "Page",
    templateSelectorDisplayName: "Landing Pages",
    creationDisplayName: "New Page",
    path: "/pages/",
  },
  shopifyProductTemplate: {
    displayName: "Product Templates",
    pageRenderType: "fullPage",
    singularDisplayName: "Product Template",
    templateSelectorDisplayName: "Product Templates",
    creationDisplayName: "New Product Template",
  },
  shopifyArticle: {
    displayName: "Blog Post Content",
    pageRenderType: "articleContent",
    singularDisplayName: "Blog Post",
    templateSelectorDisplayName: "Blog Post Template",
    creationDisplayName: "New Blog Post",
    path: "/blogs/",
  },
  shopifySection: {
    displayName: "Shopify Sections",
    pageRenderType: "fullPage",
    singularDisplayName: "Section",
    templateSelectorDisplayName: "Shopify Sections",
    creationDisplayName: "New Shopify Section",
  },
};

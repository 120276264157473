import { filterDOMProps } from "@react-aria/utils";
import classNames from "classnames";
import * as React from "react";
import type { HoverEvent } from "react-aria";
import { warning } from "replo-utils/lib/misc";

import type {
  AriaLabelProps,
  DataAttributeProps,
  SafeStyleProps,
  UnsafeStyleProps,
} from "../../utils/props";
import { mergeProps } from "../../utils/props";
import { withTooltip } from "../tooltip/with-tooltip";
import type {
  ButtonCornerStyle,
  ButtonFillStyle,
  ButtonGroupVariant,
  ButtonLinkCommonProps,
  ButtonSize,
  ButtonVariant,
} from "./button-shared";
import {
  ButtonGroupProvider,
  DEFAULT_CORNER_STYLE,
  DEFAULT_FILL_STYLE,
  DEFAULT_SIZE,
  DEFAULT_VARIANT,
  useButtonGroupContext,
  useButtonLinkProps,
} from "./button-shared";

interface ButtonGroupOwnProps {
  children: React.ReactNode;
  fillStyle?: ButtonFillStyle;
  id?: string;
  size?: ButtonSize;
  variant?: ButtonGroupVariant;
  buttonVariant?: Exclude<ButtonVariant, "no-style">;
  cornerStyle?: ButtonCornerStyle;
  isDisabled?: boolean;
  isBusy?: boolean;
}

export interface ButtonGroupProps
  extends UnsafeStyleProps,
    ButtonGroupOwnProps {}

/**
 * ButtonGroup is a container for rendering a group of buttons with logical
 * relationships. It is not necessary for all cases but it can be useful for
 * maintaining consistent styles and behaviors. It is also useful for rendering
 * "segmented" button groups where the buttons are visually connected without
 * space between them.
 */
export const ButtonGroup = React.forwardRef<HTMLDivElement, ButtonGroupProps>(
  function ButtonGroup(
    {
      unsafe_className,
      unsafe_style,
      buttonVariant = DEFAULT_VARIANT,
      size = DEFAULT_SIZE,
      variant: buttonGroupVariant = "spaced",
      children,
      isDisabled = false,
      isBusy = false,
      fillStyle = DEFAULT_FILL_STYLE,
      cornerStyle = DEFAULT_CORNER_STYLE,
      ...props
    },
    ref,
  ) {
    return (
      <div
        ref={ref}
        {...filterDOMProps(props)}
        className={classNames(
          unsafe_className,
          "replo--button-group",
          `variant--${buttonGroupVariant}`,
          `button-variant--${buttonVariant}`,
        )}
        style={unsafe_style}
      >
        <ButtonGroupProvider
          value={{
            buttonGroupVariant,
            buttonVariant,
            size,
            isDisabled,
            isBusy,
            fillStyle,
            cornerStyle,
          }}
        >
          {children}
        </ButtonGroupProvider>
      </div>
    );
  },
);

export interface ButtonOwnProps {
  htmlType?: "submit" | "reset" | "button";
}

export type ButtonProps = ButtonOwnProps &
  ButtonSharedProps<HTMLButtonElement> &
  AriaLabelProps;

const ButtonImpl = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button({ htmlType = "button", ...props }, forwardedRef) {
    let tabIndex = props.tabIndex;
    if (props.isDisabled && (props.tooltipText || props.tooltipCustomContent)) {
      // Disabled buttons with tooltips should still be focusable and tabbable so
      // that the tooltip content is accessible
      tabIndex = 0;
    }

    return (
      <ButtonBase
        ref={forwardedRef}
        as="button"
        {...props}
        type={htmlType}
        disabled={props.isDisabled || undefined}
        tabIndex={tabIndex}
      />
    );
  },
);

/**
 * The button component for the Replo design system. This component will always
 * render a button element and should be used for interactive elements with
 * click handlers.
 *
 * If you need a non-interactive element that looks like a button, use
 * `ButtonFake` instead. This is useful if that element is nested inside of its
 * own button where interactions are handled.
 *
 * If you need a button that links to an external page, use `ButtonAnchor`
 * instead. You can also use `ButtonLink` from either `button-next` or
 * `button-react-router` depending on the app's framework.
 */
export const Button = withTooltip(ButtonImpl, "Button");

export type ButtonFakeProps = ButtonSharedProps<HTMLDivElement> &
  AriaLabelProps &
  UnsafeStyleProps;

const ButtonFakeImpl = React.forwardRef<HTMLDivElement, ButtonFakeProps>(
  function ButtonFake(props, forwardedRef) {
    React.useEffect(() => {
      warning(
        props.onClick === undefined,
        "ButtonFake should not include the `onClick` prop or otherwise be interactive. Use `Button` instead.",
      );
    }, [props.onClick]);

    let tabIndex = props.tabIndex;
    if (props.tooltipText || props.tooltipCustomContent) {
      // Non-interactive buttons with tooltips should always be focusable and
      // tabbable so that the tooltip content is accessible.
      tabIndex = 0;
    }
    return (
      <ButtonBase ref={forwardedRef} as="div" {...props} tabIndex={tabIndex} />
    );
  },
);

/**
 * A "fake" button component for the Replo design system. This component will
 * always render a div element and should only be used for non-interactive
 * elements. This is useful if that element is nested inside of its own button
 * where interactions are handled.
 */
export const ButtonFake = withTooltip(ButtonFakeImpl, "ButtonFake");

export interface ButtonAnchorOwnProps extends ButtonLinkCommonProps {
  href: string;
}

export type ButtonAnchorProps = ButtonAnchorOwnProps &
  ButtonSharedProps<HTMLAnchorElement> &
  AriaLabelProps &
  UnsafeStyleProps;

const ButtonAnchorImpl = React.forwardRef<HTMLAnchorElement, ButtonAnchorProps>(
  function ButtonAnchor(props, forwardedRef) {
    const normalizedProps = useButtonLinkProps(props);
    return <ButtonBase ref={forwardedRef} as="a" {...normalizedProps} />;
  },
);

/**
 * A button component for the Replo design system for linking to another page.
 * This component will always render an anchor (`a`) element and will trigger a
 * page navigation when clicked.
 *
 * If you need a router-specific link for SPA navigations, use `ButtonLink` from
 * either `button-next` or `button-react-router` depending on the app's
 * framework.
 */
export const ButtonAnchor = withTooltip(ButtonAnchorImpl, "ButtonAnchor");

/** @internal */
export const ButtonBase = React.forwardRef<
  any,
  ButtonSharedProps<any> & {
    as: any;
    type?: string;
  } & Record<string, unknown>
>(function ButtonBase({ children, as: Component, ...props }, forwardedRef) {
  const groupContext = useButtonGroupContext();
  const {
    iconStart,
    icon,
    iconEnd,
    variant: variantProp,
    fillStyle: fillStyleProp,
    fullWidth,
    cornerStyle: cornerStyleProp,
    size: sizeProp,
    isDisabled: isDisabledProp,
    isBusy: buttonIsBusy,
    onClick: onClickProp,
    unsafe_className,
    unsafe_style,
    onHoverChange,
    onHoverEnd,
    onHoverStart,
    tooltipText,
    tooltipCustomContent,
    ...passthroughProps
  } = props;

  // All buttons in a group must be the same size
  const size = groupContext?.size ?? sizeProp ?? DEFAULT_SIZE;

  // Buttons inside of segmented groups must have the same fill style, variant,
  // and corner radius
  const isInSegmentedGroup = groupContext?.buttonGroupVariant === "segmented";
  const variant = variantProp ?? groupContext?.buttonVariant ?? DEFAULT_VARIANT;
  const fillStyle = isInSegmentedGroup
    ? groupContext.fillStyle
    : fillStyleProp ?? groupContext?.fillStyle ?? DEFAULT_FILL_STYLE;
  const cornerStyle = isInSegmentedGroup
    ? groupContext.cornerStyle
    : cornerStyleProp ?? groupContext?.cornerStyle ?? DEFAULT_CORNER_STYLE;

  const noStyles = variant === "no-style";

  const groupIsBusy = groupContext?.isBusy;
  const isBusy = groupIsBusy || buttonIsBusy;

  // NOTE (Chance 2024-04-03): `||` is intentional here because there may be a
  // group that is explicitly *not* disabled but the button is.
  const isDisabled = groupContext?.isDisabled || isDisabledProp;
  const isIconButton = icon != null;
  const hasIconsWithChildren =
    children != null && (iconStart != null || iconEnd != null);

  // NOTE (Chance 2024-05-10): For buttons with defined styles, className and
  // style props are prefixed with `unsafe_`. Otherwise they'll accept the
  // unprefixed props.
  if (variant !== "no-style") {
    passthroughProps.className = unsafe_className;
    passthroughProps.style = unsafe_style;
  }

  return (
    <Component
      ref={forwardedRef}
      {...mergeProps(passthroughProps)}
      onClick={(event: React.MouseEvent) => {
        // NOTE (Chance 2024-02-25): We don't rely on the `disabled` attribute
        // for this it won't work for links. We also don't want to disable all
        // pointer events with CSS because it will prevent tooltips from showing
        // on disabled buttons.
        if (!isDisabled && !event.defaultPrevented) {
          onClickProp?.(event);
        }
      }}
      className={classNames(
        passthroughProps.className,
        !noStyles && [
          "replo--button",
          `variant--${variant}`,
          `fill--${fillStyle}`,
          `size--${size}`,
          `corner--${cornerStyle}`,
          fullWidth && "full-width",
          isIconButton && "icon-only",
        ],
      )}
      style={props.style}
      data-disabled={isDisabled || undefined}
    >
      <span
        className={classNames(
          "replo--button__inner",
          isBusy && "opacity-0",
          passthroughProps.innerStyle,
        )}
      >
        {icon ? (
          React.cloneElement(icon, {
            // NOTE (Chance 2024-05-14): Cloning the element to ensure that SVGs
            // without `height` and `width` props are sized according to the
            // parent's font size. This could be done in CSS but this makes it
            // easier for consumers to override the size without worrying about
            // CSS conflicts, since thos height/width styles will override the
            // style attribute.
            height: icon.props?.height ?? "1em",
            width: icon.props?.width ?? "1em",
            className: icon.props?.className ?? "text-inherit",
          })
        ) : // eslint-disable-next-line unicorn/no-nested-ternary
        hasIconsWithChildren ? (
          <>
            {iconStart != null ? (
              <span>
                {React.cloneElement(iconStart, {
                  height: iconStart.props?.height ?? "1em",
                  width: iconStart.props?.width ?? "1em",
                  className: iconStart.props?.className ?? "text-inherit",
                })}
              </span>
            ) : null}
            {!isIconButton ? <span>{children}</span> : null}
            {iconEnd != null ? (
              <span>
                {React.cloneElement(iconEnd, {
                  height: iconEnd.props?.height ?? "1em",
                  width: iconEnd.props?.width ?? "1em",
                  className: iconEnd.props?.className ?? "text-inherit",
                })}
              </span>
            ) : null}
          </>
        ) : (
          children
        )}
      </span>
      {isBusy ? (
        <span className="replo--busy-indicator">
          <span className="animate-spin">
            <BusyIcon
              size={size === "sm" ? 10 : 14}
              className="replo--busy-indicator__icon"
            />
          </span>
        </span>
      ) : null}
    </Component>
  );
});

type ButtonRenderChildrenProps =
  | {
      icon?: never;
      children: React.ReactNode;
      iconStart?: React.ReactElement;
      iconEnd?: React.ReactElement;
    }
  | {
      icon: React.ReactElement;
      children?: never;
      iconStart?: never;
      iconEnd?: never;
    };

type ButtonVariantProps =
  | ({
      variant?: Exclude<ButtonVariant, "no-style">;
      className?: never;
      style?: never;
    } & UnsafeStyleProps)
  | ({
      variant: "no-style";
      unsafe_className?: never;
      unsafe_style?: never;
    } & SafeStyleProps);

export type ButtonSharedProps<Elem extends HTMLElement> = DataAttributeProps &
  ButtonVariantProps &
  ButtonRenderChildrenProps & {
    "aria-describedby"?: string;
    role?: string;
    autoFocus?: boolean;
    cornerStyle?: ButtonCornerStyle;
    fillStyle?: ButtonFillStyle;
    fullWidth?: boolean;
    id?: string;
    isDisabled?: boolean;
    onBlur?: React.FocusEventHandler<Elem>;
    onClick?: React.MouseEventHandler<Elem>;
    onFocus?: React.FocusEventHandler<Elem>;
    onHoverChange?: (isHovering: boolean) => void;
    onHoverEnd?: (event: HoverEvent) => void;
    onHoverStart?: (event: HoverEvent) => void;
    onKeyDown?: React.KeyboardEventHandler<Elem>;
    onKeyUp?: React.KeyboardEventHandler<Elem>;
    onMouseDown?: React.MouseEventHandler<Elem>;
    size?: ButtonSize;
    tabIndex?: number;
    tooltipText?: string;
    tooltipCustomContent?: React.ReactNode;
    isBusy?: boolean;
    innerStyle?: string;
  };

/**
 * TODO (Chance 2024-02-27): This should be moved into a separate DS component.
 * This is only used internally at the moment for the button's busy indicator.
 */
function BusyIcon({ size, className }: { size: number; className?: string }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9 8.58563e-10C7.22661 -2.44997e-05 5.48535 0.524239 3.99992 1.51675C2.26863 2.67355 0.992002 4.39486 0.387558 6.38739C-0.216886 8.37992 -0.111747 10.5204 0.685061 12.4441C1.48187 14.3678 2.92104 15.9557 4.75737 16.9373C6.5937 17.9188 8.71355 18.2333 10.7557 17.8271C12.7979 17.4209 14.6361 16.3192 15.957 14.7096C17.278 13.1001 18 11.0823 18 9.00009H16C15.9999 10.6196 15.4382 12.1889 14.4108 13.4407C13.3833 14.6925 11.9536 15.5493 10.3652 15.8652C8.77679 16.181 7.12803 15.9363 5.69983 15.1728C4.27162 14.4093 3.15234 13.1742 2.53268 11.678C1.91302 10.1817 1.83134 8.51692 2.30154 6.9672C2.77174 5.41748 3.76474 4.07874 5.11134 3.17908C6.26661 2.40725 7.62082 1.99956 9 1.99958V8.58563e-10Z"
        className="opacity-60"
      />
      <path
        d="M9 0C11.3869 0 13.6761 0.948211 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9H16C16 7.14348 15.2625 5.36301 13.9497 4.05025C12.637 2.7375 10.8565 2 9 2V0Z"
        className="opacity-90"
      />
    </svg>
  );
}

import ChevronMenuIndicator from "@common/designSystem/ChevronMenuIndicator";
import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import LabeledControl from "@common/designSystem/LabeledControl";
import Selectable from "@common/designSystem/Selectable";
import { LengthInputSelector } from "@editor/components/editor/page/element-editor/components/modifiers/LengthInputModifier";
import type {
  ObjectPositionXType,
  ObjectPositionYType,
} from "@editor/types/object-fit";
import { DraggingTypes } from "@editor/utils/editor";
import startCase from "lodash-es/startCase";
import * as React from "react";
import { AiOutlineColumnHeight, AiOutlineColumnWidth } from "react-icons/ai";
import useMeasure from "react-use-measure";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import {
  CSS_LENGTH_TYPES_WITH_COMPUTED,
  CSS_LENGTH_TYPES_WITH_PERCENTAGE,
} from "replo-runtime/shared/utils/units";

export type ObjectSizeType = "auto" | "contain" | "cover" | "custom" | string;

export type ObjectRepeatType = "no-repeat" | "repeat" | "repeat-x" | "repeat-y";

type BackgroundAssetPickerProps = {
  url?: string;
  emptyTitle: string;
  selectAssetTitle: string;
  changeAssetTitle: string;
  backgroundPositionValue?: { x?: string; y?: string };
  onChangeBackgroundPositionX?(value: ObjectPositionXType): void;
  onChangeBackgroundPositionY?(value: ObjectPositionYType): void;
  backgroundSizeValue?: ObjectSizeType;
  onChangeBackgroundSize?(value: ObjectSizeType): void;
  backgroundRepeatValue?: ObjectRepeatType;
  onChangeBackgroundRepeat?(value: ObjectRepeatType): void;
  onClickSelectAsset(): void;
  allowsSettingDynamicData?: boolean;
  onClickDynamicDataForUrl?(): void;
};

const getBackgroundSizeValues = (
  bgSizeValue?: string,
): { size: string; width: string; height: string } => {
  if (!bgSizeValue) {
    return { size: "auto", width: "auto", height: "auto" };
  }
  if (["auto", "cover", "contain"].includes(bgSizeValue)) {
    return { size: bgSizeValue, width: "auto", height: "auto" };
  }
  const [width, height] = bgSizeValue.split(" ");
  return { size: "custom", width: width ?? "100%", height: height ?? "100%" };
};

const BackgroundAssetPicker = ({
  url,
  emptyTitle,
  selectAssetTitle,
  onClickSelectAsset,
  changeAssetTitle,
  backgroundRepeatValue,
  onChangeBackgroundRepeat,
  backgroundPositionValue,
  backgroundSizeValue,
  onChangeBackgroundSize,
  onChangeBackgroundPositionX,
  onChangeBackgroundPositionY,
  allowsSettingDynamicData = false,
  onClickDynamicDataForUrl,
}: BackgroundAssetPickerProps) => {
  const [backgroundPositionXValue, setBackgroundPositionXValue] =
    useOverridableState<ObjectPositionXType | undefined>(
      backgroundPositionValue?.x,
    );
  const [backgroundPositionYValue, setBackgroundPositionYValue] =
    useOverridableState<ObjectPositionYType | undefined>(
      backgroundPositionValue?.y,
    );
  const [backgroundSize, setBackgroundSize] = useOverridableState<
    ObjectPositionYType | undefined
  >(getBackgroundSizeValues(backgroundSizeValue).size);
  const [backgroundSizeWidth, setBackgroundSizeWidth] =
    useOverridableState<string>(
      getBackgroundSizeValues(backgroundSizeValue).width,
    );
  const [backgroundSizeHeight, setBackgroundSizeHeight] =
    useOverridableState<string>(
      getBackgroundSizeValues(backgroundSizeValue).height,
    );
  const [selectableRef, { width }] = useMeasure({ offsetSize: true });

  const backgroundSizeOptions = [
    { value: "auto", label: "Default" },
    { value: "contain", label: "Contain" },
    { value: "cover", label: "Cover" },
    { value: "custom", label: "Custom" },
  ];

  const backgroundRepeatOptions = [
    { label: "Tile", value: "repeat" },
    { label: "No Repeat", value: "no-repeat" },
    { label: "Repeat X", value: "repeat-x" },
    { label: "Repeat Y", value: "repeat-y" },
  ];

  function _onSelectX(value: ObjectPositionXType) {
    setBackgroundPositionXValue(value);
    onChangeBackgroundPositionX?.(value);
  }

  function _onSelectY(value: ObjectPositionYType) {
    setBackgroundPositionYValue(value);
    onChangeBackgroundPositionY?.(value);
  }

  function _onChangeWidthSize(value: string) {
    setBackgroundSizeWidth(value);
    const bgSizeValue = `${value} ${backgroundSizeHeight ?? "100%"}`;
    onChangeBackgroundSize?.(bgSizeValue);
  }

  function _onChangeHeightSize(value: string) {
    setBackgroundSizeHeight(value);
    const bgSizeValue = `${backgroundSizeWidth ?? "100%"} ${value}`;
    onChangeBackgroundSize?.(bgSizeValue);
  }

  const backgroundSizeDimensionsOptions = [
    {
      id: "auto",
      type: "leaf" as const,
      value: "100%",
      title: "Reset",
    },
    {
      id: "50%",
      type: "leaf" as const,
      value: "50%",
      title: "50%",
    },
    {
      id: "100%",
      type: "leaf" as const,
      value: "100%",
      title: "100%",
    },
  ];

  const backgroundPositionXOptions = [
    {
      id: "left",
      type: "leaf" as const,
      value: "left",
      title: "Left",
      onSelect: () => _onSelectX("left"),
    },
    {
      id: "center",
      type: "leaf" as const,
      value: "center",
      title: "Center",
      onSelect: () => _onSelectX("center"),
    },
    {
      id: "right",
      type: "leaf" as const,
      value: "right",
      title: "Right",
      onSelect: () => _onSelectX("right"),
    },
  ];

  const backgroundPositionYOptions = [
    {
      id: "top",
      type: "leaf" as const,
      title: "Top",
      onSelect: () => _onSelectY("top"),
    },
    {
      id: "center",
      type: "leaf" as const,
      title: "Center",
      onSelect: () => _onSelectY("center"),
    },
    {
      id: "bottom",
      type: "leaf" as const,
      title: "Bottom",
      onSelect: () => _onSelectY("bottom"),
    },
  ];

  function getElement() {
    if (url) {
      return (
        <div
          className="h-full w-full rounded object-cover object-center"
          style={{
            backgroundImage: `url(${url})`,
            backgroundSize:
              backgroundSize === "custom"
                ? `${backgroundSizeWidth} ${backgroundSizeHeight}`
                : backgroundSize,
            backgroundPositionX: backgroundPositionXValue,
            backgroundPositionY: backgroundPositionYValue,
            backgroundRepeat: backgroundRepeatValue,
          }}
        />
      );
    }
    return (
      <div className="flex h-full w-full items-center justify-center rounded bg-slate-50">
        <span className="text-xs text-slate-400">{emptyTitle}</span>
      </div>
    );
  }

  return (
    <div>
      <div
        className="mb-2 flex h-[150px] cursor-pointer"
        onClick={onClickSelectAsset}
      >
        {getElement()}
      </div>
      <div className="flex h-6 gap-2">
        <div
          onClick={onClickSelectAsset}
          className="flex w-full cursor-pointer items-center justify-center rounded bg-slate-50"
        >
          <span className="text-xs text-default">
            {url ? changeAssetTitle : selectAssetTitle}
          </span>
        </div>
        {allowsSettingDynamicData && (
          <DynamicDataButton onClick={() => onClickDynamicDataForUrl?.()} />
        )}
      </div>
      <div className="mb-2 w-full">
        <LabeledControl label="Size" size="sm">
          <Selectable
            placeholder="Choose Size"
            options={backgroundSizeOptions}
            value={backgroundSize}
            defaultValue="cover"
            onSelect={(value: ObjectSizeType) => {
              let bgSizeValue = value;
              setBackgroundSize(value);
              if (value === "custom") {
                bgSizeValue = `${backgroundSizeWidth ?? "100%"} ${
                  backgroundSizeHeight ?? "100%"
                }`;
              }
              return onChangeBackgroundSize?.(bgSizeValue);
            }}
            isDisabled={!url}
          />
        </LabeledControl>
        <div className="flex gap-2">
          <div className="w-1/2">
            <LabeledControl label="Width" size="sm">
              <LengthInputSelector
                value={backgroundSizeWidth}
                onChange={_onChangeWidthSize}
                startEnhancer={() => <AiOutlineColumnWidth size={16} />}
                endEnhancer={() => (
                  <ChevronMenuIndicator
                    items={backgroundSizeDimensionsOptions.map((option) => ({
                      ...option,
                      onSelect: () => _onChangeWidthSize(option.value),
                    }))}
                    menuWidth={width}
                  />
                )}
                draggingType={DraggingTypes.Vertical}
                field="BackgroundSizeWidth"
                placeholder="auto"
                metrics={CSS_LENGTH_TYPES_WITH_PERCENTAGE}
                isDisabled={!url || backgroundSize !== "custom"}
                autofocus={Boolean(url)}
                resetValue="auto"
                previewProperty="backgroundSize"
                previewSubProperty="backgroundSizeWidth"
              />
            </LabeledControl>
          </div>
          <div className="w-1/2">
            <LabeledControl label="Height" size="sm">
              <LengthInputSelector
                value={backgroundSizeHeight}
                onChange={_onChangeHeightSize}
                startEnhancer={() => <AiOutlineColumnHeight size={16} />}
                endEnhancer={() => (
                  <ChevronMenuIndicator
                    items={backgroundSizeDimensionsOptions.map((option) => ({
                      ...option,
                      onSelect: () => _onChangeHeightSize(option.value),
                    }))}
                    menuWidth={width}
                  />
                )}
                draggingType={DraggingTypes.Vertical}
                field="BackgroundSizeHeight"
                placeholder="auto"
                metrics={CSS_LENGTH_TYPES_WITH_PERCENTAGE}
                isDisabled={!url || backgroundSize !== "custom"}
                resetValue="auto"
                previewProperty="backgroundSize"
                previewSubProperty="backgroundSizeHeight"
              />
            </LabeledControl>
          </div>
        </div>
        <LabeledControl label="Repeat" size="sm">
          <Selectable
            placeholder="-"
            options={backgroundRepeatOptions}
            value={backgroundRepeatValue}
            defaultValue="repeat"
            onSelect={(value: ObjectRepeatType) =>
              onChangeBackgroundRepeat?.(value)
            }
            isDisabled={!url}
          />
        </LabeledControl>
        <div className="flex gap-2">
          <div className="w-1/2" ref={selectableRef}>
            <LabeledControl label="X Position" size="sm">
              <LengthInputSelector
                value={
                  backgroundPositionXValue?.includes("px") ||
                  backgroundPositionXValue?.includes("%")
                    ? backgroundPositionXValue
                    : startCase(backgroundPositionXValue)
                }
                onChange={_onSelectX}
                endEnhancer={() => (
                  <ChevronMenuIndicator
                    items={backgroundPositionXOptions}
                    menuWidth={width}
                  />
                )}
                draggingType={DraggingTypes.Vertical}
                dragTrigger="entireInput"
                field="PositionX"
                placeholder="50%"
                metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
                isDisabled={!url}
                autofocus={Boolean(url)}
                allowedNonUnitValues={["left", "center", "right"]}
                previewProperty="backgroundPositionX"
                resetValue="center"
              />
            </LabeledControl>
          </div>
          <div className="w-1/2">
            <LabeledControl label="Y Position" size="sm">
              <LengthInputSelector
                value={
                  backgroundPositionYValue?.includes("px") ||
                  backgroundPositionYValue?.includes("%")
                    ? backgroundPositionYValue
                    : startCase(backgroundPositionYValue)
                }
                onChange={_onSelectY}
                endEnhancer={() => (
                  <ChevronMenuIndicator
                    items={backgroundPositionYOptions}
                    menuWidth={width}
                  />
                )}
                draggingType={DraggingTypes.Vertical}
                dragTrigger="entireInput"
                field="PositionY"
                placeholder="50%"
                metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
                isDisabled={!url}
                allowedNonUnitValues={["left", "center", "right"]}
                previewProperty="backgroundPositionY"
                resetValue="center"
              />
            </LabeledControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackgroundAssetPicker;

import { initUserBasedAnalytics } from "@editor/infra/analytics";
import { storeToken } from "@editor/reducers/utils/store-token";
import { trpc } from "@editor/utils/trpc";
import * as React from "react";

let userInitialized = false;

export default function useCurrentUser() {
  const {
    data: user,
    isLoading,
    isError,
    isSuccess,
    isFetching,
    refetch,
  } = trpc.user.get.useQuery({});

  const isAuthenticated = Boolean(user?.id);

  React.useEffect(() => {
    // Note (Noah, 2024-08-21): Only initialize the user-based analytics once
    // throughout the lifetime of the page, since we don't want to re-initialize
    // if multiple components are using this hook.
    if (isSuccess && user && !userInitialized) {
      initUserBasedAnalytics(user);
      userInitialized = true;
    }
  }, [isSuccess, user]);

  React.useEffect(() => {
    if (!isSuccess && !isError && !isLoading) {
      void refetch();
    }
  }, [isError, isLoading, isSuccess, refetch]);

  React.useEffect(() => {
    if (isError) {
      void storeToken(null);
    }
  }, [isError]);

  return {
    isLoading,
    isAuthenticated,
    isFetching,
    isError,
    user: isAuthenticated ? user : null,
  };
}

import * as React from "react";

import type { RenderComponentProps } from "../../../shared/types";
import { ReploComponent } from "../ReploComponent";

const PlayIcon = (props: RenderComponentProps) => {
  const isPlaying = props.context.state?.isPlaying;
  return (
    <ReploComponent
      context={props.context}
      component={props.component}
      repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
      extraAttributes={props.extraAttributes}
      overrideComponentType="icon"
      overrideComponentProps={React.useMemo(
        () => ({ iconName: isPlaying ? "pause" : "play" }),
        [isPlaying],
      )}
      // @ts-expect-error: TODO (Noah, 2024-01-01, REPL-9866): These are errors
      // because the togglePlay action doesn't have a value, but that's only the
      // case because our types
      defaultActions={React.useMemo(
        () => ({
          actions: {
            onClick: [{ id: "alchemy:togglePlay", type: "togglePlay" }],
          },
          placement: "after",
        }),
        [],
      )}
    />
  );
};

export default PlayIcon;

import { useEditorSelector } from "@editor/store";
import { isSafeWindow } from "replo-runtime/shared/Window";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";

import { selectCanvasIFrame } from "@/features/canvas/canvas-reducer";

/**
 * @returns The `document` object of the targeted editor canvas iframe; this
 * will be `null` on the server and before the iframe has loaded.
 */
export function useTargetFrameDocument(canvas: EditorCanvas) {
  const targetFrame = useEditorSelector((state) =>
    selectCanvasIFrame(state, canvas),
  );
  return targetFrame ? getTargetFrameDocument(targetFrame) : null;
}

/**
 * Given a target iframe, return the contentWindow if we're allowed to access
 * the iframe, or return null if we're not allowed to access due to CORS.
 */
export function getTargetFrameDocument(targetFrame: HTMLIFrameElement) {
  return targetFrame.contentDocument ?? null;
}

/**
 * Given a target iframe, return the contentWindow if we're allowed to access
 * the iframe, or return null if we're not allowed to access due to CORS.
 */
export function getTargetFrameWindow(targetFrame: HTMLIFrameElement) {
  if (!targetFrame.contentWindow || !isSafeWindow(targetFrame.contentWindow)) {
    return null;
  }

  return targetFrame.contentWindow;
}

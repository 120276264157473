// TODO (Noah, 2024-02-03, REPL-10280):
// For some reason, we need to specifically reference DOM.Iterable
// here, or else we get type errors. I'm not sure why this is the case,
// but it only happens for this file.
/// <reference lib="DOM.Iterable" />
import { mapLiquidProductToStoreProduct } from "replo-runtime/shared/mappers/product";
import type {
  LiquidProduct,
  OkendoNamespace,
  ProductMetafieldMapping,
  StoreProduct,
  VariantMetafieldMapping,
} from "replo-runtime/shared/types";
import { filterNulls } from "replo-utils/lib/array";

// NOTE (Chance 2024-05-31): The functions in this file are used to extract
// Shopify store data from scripts on the page--either scripts added by Shopify
// itself (on the Shopify object) or parsed from liquid values and injected by
// us. In the editor we will save this data to the Redux store when the canvas
// loads, and elsewhere it should be set in a global context at initial runtime.
//
// Ideally these functions should not be called elsewhere. At best this leads to
// over-parsing, which can make certain operations slow. At worst we could end
// up with inconsistent data or runtime errors if called in the wrong context.

export const DEFAULT_ACTIVE_LANGUAGE = "en-US";
export const DEFAULT_ACTIVE_CURRENCY = "USD";
export const DEFAULT_MONEY_FORMAT = null;
export const DEFAULT_ACTIVE_SHOPIFY_URL_ROOT = "/";
export const DEFAULT_OKENDO_NAMESPACE = null;

/**
 * When there's an error in liquid, in at least the case of using a `{{ product_data }} | json`
 * block, shopify will return an error in this form. Typing it here so we can use it elsewhere,
 * and maybe narrow down the type.
 *
 * @author Ben 2024-01-22
 */
export type ShopifyLiquidError = {
  error: any;
};

/**
 * To resolve products in the runtime/editor, we use shopify's `| json` on the product, which
 * gives us a JSON representation of the product that we can use, or an error, which we can't.
 * This wraps the LiquidProduct with some other data, allowing us access to at least some
 * diagnostic information about the product in the event of an error.
 *
 * @author Ben 2024-01-22
 */
export type LiquidProductResult = {
  id: number;
  handle: string;
  data: LiquidProduct | ShopifyLiquidError;
};

export type StoreDataType = {
  store: {
    products: StoreProduct[];
    productMetafields: ProductMetafieldMapping;
    variantMetafields: VariantMetafieldMapping;
  };
};

export function getFeatures(globalWindow: Window) {
  return {
    store: {
      templateProduct: getTemplateProduct(globalWindow),
      products: getProducts(globalWindow),
      productMetafields: getProductMetafields(globalWindow),
      variantMetafields: getVariantMetafields(globalWindow),
    },
  };
}

export function getActiveCurrency(globalWindow: Window, code?: string) {
  const currencyCode = globalWindow.Shopify?.currency?.active ?? code;
  return currencyCode ?? DEFAULT_ACTIVE_CURRENCY;
}

/**
 * Try to get the active language for a Shopify store, by trying these strategies in order
 * - Shopify locale and country separated by hyphen
 * - Language derived from the shopify locale,
 * - The navigator language from the target window.
 * - Just use "en-US".
 *
 * NOTE Ben 2024-01-29: The Shopify.locale field seems to have few guarentees - it may be a full
 * language code on its own, and there's nothing stopping a user or script from overriding it.
 *
 * Helpful links:
 * - https://community.shopify.com/c/hydrogen-headless-and-storefront/official-shopify-documentation-for-window-shopify-object/m-p/1577149
 * - https://datatracker.ietf.org/doc/html/rfc5646
 * - https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language
 * - https://developer.mozilla.org/en-US/docs/Web/API/Navigator/languages
 * - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl
 * - https://en.wikipedia.org/wiki/ISO_639
 * - https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
 */
export function getActiveLanguage(globalWindow: Window): string {
  const defaultNavigatorLang = globalWindow.navigator.language;
  const shopifyLocale = globalWindow.Shopify?.locale;
  const shopifyCountry = globalWindow.Shopify?.country;
  if (shopifyLocale && shopifyCountry) {
    try {
      return new Intl.Locale(`${shopifyLocale}-${shopifyCountry}`).language;
    } catch {
      try {
        return new Intl.Locale(shopifyLocale).language;
      } catch {
        try {
          return new Intl.Locale(defaultNavigatorLang).language;
        } catch {
          return DEFAULT_ACTIVE_LANGUAGE;
        }
      }
    }
  }
  return DEFAULT_ACTIVE_LANGUAGE;
}

// NOTE (Matt 2024-01-08): This function returns a store's default money format using 'amount'
// (or some variation) as a placeholder. Ex: `${{ amount }}`. This is pulled in from liquid
// using `shop.money_format`. We use this because the money_format may not match the output
// of `Number.prototype.toLocaleString`, given that a store can customize this format.
// docs: https://help.shopify.com/en/manual/markets/pricing/currency-formatting
export function getShopifyMoneyFormat(globalWindow: Window): string | null {
  const moneyFormatScript = globalWindow.document?.getElementById(
    "replo-deps-shopify-store",
  );
  if (moneyFormatScript) {
    if (moneyFormatScript.innerHTML.includes("Liquid error")) {
      console.warn(
        "[Replo] Unknown Liquid error reading Shop Money Format. This page may look incorrect, please contact support@replo.app.",
      );
    } else {
      try {
        const {
          shop: { moneyFormat },
        } = JSON.parse(moneyFormatScript.innerHTML);
        return typeof moneyFormat === "string"
          ? moneyFormat
          : DEFAULT_MONEY_FORMAT;
      } catch {
        console.warn(
          "[Replo] Error parsing Shop Money Format. This page may look incorrect, please contact support@replo.app.",
        );
      }
    }
  }
  return DEFAULT_MONEY_FORMAT;
}

/**
 * @returns The active root url for the Shopify store. This helps support stores
 * with Shopify Markets enabled, where the root url may be dependent on what market
 * the user is viewing (e.g. mystore.com/en-gb/ for the UK market)
 */
export function getActiveShopifyUrlRoot(globalWindow: Window) {
  return globalWindow.Shopify?.routes?.root &&
    globalWindow.Shopify.routes.root !== DEFAULT_ACTIVE_SHOPIFY_URL_ROOT
    ? globalWindow.Shopify.routes.root
    : DEFAULT_ACTIVE_SHOPIFY_URL_ROOT;
}

export function getOkendoNamespace(
  globalWindow: Window,
): OkendoNamespace | null {
  let okendoNamespace: OkendoNamespace | null = DEFAULT_OKENDO_NAMESPACE;
  if (globalWindow.okendoReviews) {
    okendoNamespace = "okendoReviews";
  }

  if (globalWindow.okeWidgetApi) {
    okendoNamespace = "okeWidgetApi";
  }

  return okendoNamespace;
}

function getTemplateProduct(globalWindow: Window): StoreProduct | null {
  const templateProductScriptTag = globalWindow.document.querySelector(
    "#replo-deps-template-product",
  );
  if (templateProductScriptTag) {
    try {
      if (templateProductScriptTag.innerHTML.includes("Liquid error")) {
        console.warn(
          "[Replo] Unknown liquid error while parsing template product data. This page may look incorrect, please contact support@replo.app",
        );
      } else {
        const productJson = JSON.parse(templateProductScriptTag.innerHTML);
        return mapLiquidProductToStoreProduct(
          productJson.data as LiquidProduct,
        );
      }
    } catch {
      console.warn(
        "[Replo] Error parsing template product data. This page may look incorrect, please contact support@replo.app.",
      );
    }
  }
  return null;
}

function getProducts(globalWindow: Window): StoreProduct[] {
  // NOTE (Matt 2023-08-30): It is possible for there to be more than one `script#replo-deps-products` elements
  // on the page, if a user has 2 replo sections on the same page that each have product dependencies.
  const innerHTMLStrings = [
    ...globalWindow.document.querySelectorAll("script#replo-deps-products"),
  ]
    .map((scriptTag) => scriptTag.innerHTML)
    // Note (Evan, 2024-05-01): For PDPs, we rely on the assumption that the template product is
    // at index 0. By reversing this array, we ensure that the HTML string from the PDP will occur
    // first, preserving that assumption (see PR #8334 for details)
    .reverse();

  if (
    innerHTMLStrings.some((innerHTML) =>
      innerHTML.includes(
        "Exceeded maximum number of unique handles for all_products",
      ),
    )
  ) {
    console.warn(
      "[Replo] Liquid error fetching product data, more than 20 individual products were specified. This page may look incorrect, please contact support@replo.app",
    );
    return [];
  } else if (
    innerHTMLStrings.some((innerHTML) => innerHTML.includes("Liquid error"))
  ) {
    console.warn(
      "[Replo] Unknown liquid error while fetching product data. This page may look incorrect, please contact support@replo.app",
    );
    return [];
  }
  let products: (LiquidProductResult | ShopifyLiquidError)[] = [];
  try {
    /**
     * NOTE Ben 2024-01-24: We recently made a change to how we write product data from liquid
     * to the "replo-deps-products" scripts, which makes their old format incompatible with the
     * new one. The trouble is that some existing shopify sections use the old format. To solve
     * for this, we check if the "data" field exists in the deserialized
     */
    products = innerHTMLStrings.flatMap((innerHTML) => JSON.parse(innerHTML));
    products = products.map(
      (product: LiquidProductResult | LiquidProduct | ShopifyLiquidError) => {
        if ("data" in product) {
          return product as LiquidProductResult;
        }
        if ("error" in product) {
          return product as ShopifyLiquidError;
        }
        const plainProduct = product as LiquidProduct;
        return {
          id: plainProduct.id,
          handle: plainProduct.handle,
          data: plainProduct,
        };
      },
    );
  } catch {
    console.warn(
      "[Replo] Error parsing product data. This page may look incorrect, please contact support@replo.app",
    );
    return [];
  }

  return filterNulls(
    products.map((product) => {
      if ("error" in product) {
        console.warn(
          "[Replo] Liquid error fetching product data. Products may have moved to a draft state, or may not be available in the Online Store sales channel. Please contact support@replo.app",
          "error:",
          product,
        );
        return null;
      }
      if ("data" in product && "error" in product.data) {
        console.warn(
          "[Replo] Liquid error fetching product data. Products may have moved to a draft state, or may not be available in the Online Store sales channel. Please contact support@replo.app",
          "error:",
          product.data.error,
          `handle=${product.handle} id=${product.id}`,
        );
        return null;
      }
      try {
        return mapLiquidProductToStoreProduct(product.data as LiquidProduct);
      } catch {
        // Note (Noah, 2022-11-21, REPL-5177): for some reason there can sometimes be issues where
        console.warn(
          "[Replo] Unknown error fetching product data. Please contact support@replo.app",
        );
        return null;
      }
    }),
  );
}

// Note (Evan, 2024-03-14): For some godforsaken reason, Shopify allows random Unicode
// control characters to be part of multiline text metafields. These break JSON parsing, so
// we have to strip them out. Here we remove the following:
//
// U+0000: Null character (NUL)
// U+0001: Start of Heading (SOH)
// U+0002: Start of Text (STX)
// U+0003: End of Text (ETX)
// U+0004: End of Transmission (EOT)
// U+0005: Enquiry (ENQ)
// U+0006: Acknowledge (ACK)
// U+0007: Bell (BEL)
// U+0008: Backspace (BS)
//
// This is not a complete list, we may have to augment this regex if other characters cause problems down the line.
function removeControlCharacters(s: string) {
  // Note (Evan, 2024-03-14): We also have to disable this lint rule, which exists because
  // "These characters are rarely used in JavaScript strings so a regular expression containing elements
  // that explicitly match these characters is most likely a mistake" - smh Shopify.
  // biome-ignore lint/suspicious/noControlCharactersInRegex: allow regex
  return s.replace(/[\u0000-\u0008]/g, "");
}

function getProductMetafields(globalWindow: Window): ProductMetafieldMapping {
  // Note (Evan, 2024-05-01): Just like with products, it is possible to have more than one products-metafields script,
  // e.g. if there are multiple Replo sections on a page
  const innerHTMLStrings = [
    ...globalWindow.document.querySelectorAll(
      "script#replo-deps-products-metafields",
    ),
  ].map((scriptTag) => scriptTag.innerHTML);

  let productMetafields = {};

  try {
    for (const innerHtml of innerHTMLStrings) {
      productMetafields = {
        ...productMetafields,
        ...JSON.parse(removeControlCharacters(innerHtml)),
      };
    }
  } catch {
    console.warn(
      "[Replo] Error parsing product metafield data. This page may look incorrect, please contact support@replo.app",
    );
  }

  return productMetafields;
}

function getVariantMetafields(globalWindow: Window): VariantMetafieldMapping {
  // Note (Evan, 2024-05-01): Just like with products, it is possible to have more than one variant-metafields script,
  // e.g. if there are multiple Replo sections on a page
  const innerHTMLStrings = [
    ...globalWindow.document.querySelectorAll(
      "script#replo-deps-variant-metafields",
    ),
  ].map((scriptTag) => scriptTag.innerHTML);

  let variantMetafields = {};

  try {
    for (const innerHtml of innerHTMLStrings) {
      variantMetafields = {
        ...variantMetafields,
        ...JSON.parse(removeControlCharacters(innerHtml)),
      };
    }
  } catch {
    console.warn(
      "[Replo] Error parsing variant metafield data. This page may look incorrect, please contact support@replo.app",
    );
  }
  return variantMetafields;
}

export function getSectionSettings(
  targetDocument: Document,
  sectionId: string,
) {
  const element = targetDocument.querySelector(
    `script#replo-deps-section-settings[data-section-id="${sectionId}"]`,
  );
  if (element) {
    try {
      return JSON.parse(element.innerHTML);
    } catch {
      console.warn(
        "[Replo] Error parsing section settings data. This page may look incorrect, please contact support@replo.app",
      );
    }
  }
  return {};
}

/**
 * Given a StoreProduct, return an equivalent LiquidProduct. This is useful for
 * serializing StoreProducts to the same shape as we could get if we rendered them
 * from liquid, for example when we want to include product data on non-liquid elements
 * like blog posts.
 */
export function mapStoreProductToLiquidProduct(
  storeProduct: StoreProduct,
): LiquidProductResult {
  return {
    id: Number(storeProduct.id),
    handle: storeProduct.handle,
    data: {
      ...storeProduct,
      id: Number(storeProduct.id),
      variants: storeProduct.variants.map((variant) => {
        const variantPrice = Number(variant.price);
        const compareAtPrice = Number(variant.compare_at_price);
        return {
          ...variant,
          price: (variantPrice * 100).toString(),
          compare_at_price: compareAtPrice
            ? (compareAtPrice * 100).toString()
            : null,
        };
      }),
    },
  };
}

function formatWithDelimiters(
  price: number,
  args: {
    showCents: boolean;
    thousands: string;
    decimal: string;
  },
): string {
  const { showCents, thousands, decimal } = args;
  const number = price.toFixed(showCents ? 2 : 0);

  const parts = number.split(".");
  const dollars = parts[0] ?? "";
  const cents = parts[1] ?? "";

  let formattedDollars = "";
  let count = 0;
  // loop through the dollars string in reverse and add thousands separator
  // every three digits to build our formatted string
  for (let i = dollars.length - 1; i >= 0; i--) {
    formattedDollars = dollars[i] + formattedDollars;
    count++;
    if (count % 3 === 0 && i !== 0) {
      formattedDollars = thousands + formattedDollars;
    }
  }
  return formattedDollars + (cents ? decimal + cents : "");
}

/**
 * Rounds a number to a specified precision. If the next decimal after the precision
 * is exactly 5, the function rounds down (truncates) instead of rounding up. This Function
 * will allow us to match the Shopify's behaivor rounding numbers.
 *
 * We are not using `toFixed` because is too unstable with floats.
 *
 * @example
 * customRound(62.155, 2); // returns 62.15
 * customRound(62.185, 2); // returns 62.18
 * customRound(62.182, 2); // returns 62.18
 * customRound(62.188, 2); // returns 62.19
 */
function customRound(num: number, precision = 2) {
  // NOTE (Fran 2024-09-11): Shift the decimal to the right by (precision + 1) places
  const shiftFactor = Math.pow(10, precision + 1);

  // NOTE (Fran 2024-09-11): Find the digit just after the precision we're interested in
  const nextDecimal = Math.floor(num * shiftFactor) % 10;

  // NOTE (Fran 2024-09-11): Shift the number for normal rounding at the desired precision
  const roundingFactor = Math.pow(10, precision);

  if (nextDecimal === 5) {
    // NOTE (Fran 2024-09-11): If the next decimal is 5, round down by truncating the number
    return Math.floor(num * roundingFactor) / roundingFactor;
  }
  // NOTE (Fran 2024-09-11): Otherwise, round normally
  return Math.round(num * roundingFactor) / roundingFactor;
}

/**
 * Formats a number as a currency string using a liquid money format string.
 *
 * NOTE (Chance 2024-05-05): This function should only be used when we need
 * currency to be formatted using a liquid money format string. For all other
 * cases we should use `Intl.NumberFormat` or `Number.prototype.toLocaleString`,
 * either of which will be more performant and reliable. We do not use those
 * internally here because there have been cases where the string returned on
 * the server via liquid does not match client-side formatting.
 * @see https://help.shopify.com/en/manual/markets/pricing/currency-formatting
 */
export function formatCurrencyWithShopifyMoneyFormat(
  value: number | string,
  options: {
    currencyCode: string;
    hideSymbol?: boolean;
    language: string;
    moneyFormat: string | null;
    showCents?: boolean;
    zeroString?: string;
  },
) {
  const {
    currencyCode,
    hideSymbol,
    language,
    moneyFormat,
    showCents,
    zeroString = "Free",
  } = options;
  const fractionalPrecision = showCents !== false ? 2 : 0;
  value = Number(value);
  if (Number.isNaN(value)) {
    console.warn("Incorrect value format");
    // TODO (Chance 2024-05-05): Consider throwing if numberOfUnits is NaN
    return "-";
  }

  // NOTE (Fran 2024-09-11): Number.toLocaleString() rounds different from Shopify when the last
  // decimal is "5" (e.g., 62.955 becomes 62.96). To match Shopify's behavior, we pre-round using
  // toFixed() before formatting.
  // USE https://linear.app/replo/issue/USE-1208/replo-product-price-is-off-by-1-cent-on-previewlive-page
  value = Number(customRound(value));

  if (value === 0) {
    return zeroString;
  }
  if (moneyFormat) {
    const placeholderRegex = /{{\s*(\w+)\s*}}/;
    const matches = moneyFormat.match(placeholderRegex);
    if (matches) {
      let formatted: string | undefined;
      switch (matches[1]) {
        case "amount":
          formatted = formatWithDelimiters(value, {
            showCents: showCents ?? true,
            thousands: ",",
            decimal: ".",
          });
          break;
        case "amount_no_decimals":
          formatted = formatWithDelimiters(value, {
            showCents: showCents ?? false,
            thousands: ",",
            decimal: ".",
          });
          break;
        case "amount_with_comma_separator":
          formatted = formatWithDelimiters(value, {
            showCents: showCents ?? true,
            thousands: ".",
            decimal: ",",
          });
          break;
        case "amount_no_decimals_with_comma_separator":
          formatted = formatWithDelimiters(value, {
            showCents: showCents ?? false,
            thousands: ".",
            decimal: ",",
          });
          break;
        default:
          break;
      }
      if (formatted) {
        return hideSymbol
          ? formatted
          : moneyFormat.replace(placeholderRegex, formatted);
      }
    }
  }

  if (hideSymbol) {
    return value.toLocaleString(language, {
      minimumFractionDigits: fractionalPrecision,
      maximumFractionDigits: fractionalPrecision,
    });
  }

  return value.toLocaleString(language, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: fractionalPrecision,
    maximumFractionDigits: fractionalPrecision,
  });
}

import "./index.scss";

import AuthPage from "@components/account/AuthPage";
import AccountDashboard from "@components/account/Dashboard";
import ProfileBoard from "@components/account/Dashboard/ProfileBoard";
import SecurityBoard from "@components/account/Dashboard/SecurityBoard";
import InitResetPassword from "@components/account/InitResetPassword";
import LoginForm from "@components/account/LoginForm";
import ResetPassord from "@components/account/ResetPasswordForm";
import SignupForm from "@components/account/SignupForm";
import AuthDiscourseSso from "@components/AuthDiscourseSso";
import { FullScreenLoader } from "@components/common/FullScreenLoader";
import SentryBoundary from "@components/common/SentryBoundary";
import { setLibraryTemplateDataToLocalStorage } from "@components/common/utils";
import ShopifyIntegrationSettings from "@components/dashboard/integrations/ShopifyIntegrationSettings";
import WorkspaceIntegrations from "@components/dashboard/integrations/WorkspaceIntegrations";
import { PartnerProgramLPContent } from "@components/dashboard/partner/partner-program-v2-lp-components/PartnerProgramLP";
import { PartnershipReferralBoard } from "@components/dashboard/partner/PartnershipReferralBoard";
import PartnershipDashboard from "@components/dashboard/PartnershipDashboard";
import NewProjectFlow from "@components/dashboard/projects/NewProjectFlow";
import ProjectDashboard from "@components/dashboard/projects/ProjectDashboard";
import FlowOrchestrator from "@components/flows/FlowOrchestrator";
import useExecuteFlowCallback from "@components/flows/hooks/useExecuteFlowCallback";
import { useGetCurrentFlow } from "@components/flows/hooks/useGetCurrentFlow";
import RouterFlows from "@components/flows/RouterFlows";
import MarketplaceModal from "@components/marketplace/MarketplaceModal";
import TemplateCollectionsPageModal from "@components/marketplace/TemplateCollectionsPageModal";
import { TemplateDetailsModal } from "@components/marketplace/TemplateDetailsModal";
import DesignSystemTab from "@components/projectDashboard/DesignSystemTab";
import { ExperimentDetailsTab } from "@components/projectDashboard/experiments/ExperimentDetailsTab";
import { ExperimentEditTab } from "@components/projectDashboard/experiments/ExperimentEditTab";
import { ExperimentsTab } from "@components/projectDashboard/experiments/ExperimentsTab";
import ProjectDashboardLayout from "@components/projectDashboard/ProjectDashboardLayout";
import { ProjectIntegrationSettingsTab } from "@components/projectDashboard/ProjectIntegrationSettingsTab";
import { ProjectSettingsTab } from "@components/projectDashboard/ProjectSettingsTab";
import QuickStartModal from "@components/QuickStartModal";
import ProtectedRoute from "@components/router/ProtectedRoute";
import ReferralCodeRoute from "@components/router/ReferralCodeRoute";
import RouteWithModals from "@components/router/RouteWithModals";
import WorkspaceContext from "@components/WorkspaceContext";
import EditorApp from "@editor/EditorApp";
import { ProvideTrpcClient, trpc } from "@editor/utils/trpc";
import useCurrentUser from "@hooks/useCurrentUser";
import useCurrentWorkspaceId from "@hooks/useCurrentWorkspaceId";
import { LocalStorageProvider, useLocalStorage } from "@hooks/useLocalStorage";
import useLoginSupportChatUser from "@hooks/useLoginSupportChatUser";
import { useUserHasConnectedStore } from "@hooks/useUserHasConnectedStore";
import { IconSprite } from "@replo/design-system/components/icon";
import { skipToken } from "@tanstack/react-query";
import { generateEditorPathname, routes } from "@utils/router";
import * as React from "react";
import { CookiesProvider } from "react-cookie";
import type { NavigateFunction } from "react-router-dom";
import {
  createBrowserRouter,
  createRoutesFromElements,
  generatePath,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import Analytics from "@/features/analytics/Analytics";
import ComingSoon from "@/features/analytics/ComingSoon";
import Overview from "@/features/analytics/Overview";
import Billing from "@/features/workspace/Billing";
import BillingRedirect from "@/features/workspace/BillingRedirect";
import Members from "@/features/workspace/Members";
import WorkspaceSettings from "@/features/workspace/Settings";
import WorkspaceDashboard from "@/features/workspace/WorkspaceDashboard";
import WorkspaceProjects from "@/features/workspace/WorkspaceProjects";

import CurrentProjectProvider from "./contexts/CurrentProjectContext";
import { AIStreamingProvider } from "./providers/AIStreamingProvider";

const ActivePartnershipRequired = ({ children }: { children: JSX.Element }) => {
  const workspaceId = useCurrentWorkspaceId();

  const { data, isPending: isLoadingWorkspace } =
    trpc.workspace.getById.useQuery(
      workspaceId ? { id: workspaceId } : skipToken,
    );

  if (!isLoadingWorkspace && !data?.workspace?.isOnboarded) {
    return (
      <Navigate
        to={
          workspaceId
            ? generatePath("/workspace/:workspaceId/partner/program", {
                workspaceId,
              })
            : // NOTE (Fran 2023-11-21): Fallback to home if user doesn't have a workspace
              routes.dashboard
        }
        replace
      />
    );
  }

  return children;
};

function App() {
  return (
    <SentryBoundary>
      <ProvideTrpcClient>
        <InnerApp />
      </ProvideTrpcClient>
      <IconSprite />
    </SentryBoundary>
  );
}

function InnerApp() {
  const { isLoading, isAuthenticated } = useCurrentUser();
  const { isLoading: isLoadingUserHasConnectedStore } =
    useUserHasConnectedStore();
  // NOTE (Fran 2024-03-21): We need to fetch the workspaces before redirecting to the correct route
  // This endpoint should only get the list of the workspaces so is not an expensive one.
  const { isLoading: isLoadingWorkspaces } =
    trpc.workspace.getUserWorkspacesList.useQuery(
      isAuthenticated ? undefined : skipToken,
    );

  // Note (Noah, 2024-08-18): jss checks for this csp-nonce meta property any
  // time a stylesheet is created, memoizing the result. Adding in the nonce
  // here, even though it's a dummy value, means that jss won't do an expensive
  // querySelector every time a new stylesheet is added, which would adversely
  // affect performance. See
  // https://github.com/cssinjs/jss/blob/901882a894c7e802450696ebe2ea633ae63c5977/packages/jss/src/DomRenderer.js#L219
  React.useEffect(() => {
    const nonce = document.createElement("meta");
    nonce.setAttribute("property", "csp-nonce");
    nonce.setAttribute("content", "replo-jss-nonce");
    document.head.append(nonce);
  }, []);

  return (
    <FlowOrchestrator>
      {isLoading || isLoadingUserHasConnectedStore || isLoadingWorkspaces ? (
        <FullScreenLoader />
      ) : (
        <RouterProvider router={router} />
      )}
    </FlowOrchestrator>
  );
}

// NOTE (Chance 2023-12-06): This was extracted from the ReploRoutes component
// because it relies on localStorage state, but we really don't want to
// re-render the entire route tree every time we update localStorage somewhere.
// This sits on top of that and we memoize ReploRoutes.
function ReploTemplateDataManager({ children }: React.PropsWithChildren) {
  // Note (Sebas, 2023-07-05): This is the hook that initializes the support chat user. It needs to be called in the ReploRouter
  // because it uses the useLocation hook from react-router-dom which can only be used
  // in a component that is a child of a <Router> component.
  useLoginSupportChatUser();
  const localStorage = useLocalStorage();

  const [searchParams] = useSearchParams();
  const libraryItemSlug = searchParams.get("libraryItemSlug");
  const templateId = searchParams.get("templateId");
  const libraryItemName = searchParams.get("libraryItemName");
  const type = searchParams.get("type");

  // biome-ignore lint/correctness/useExhaustiveDependencies: localStorage here is weird, I'm pretty sure we don't need this
  React.useEffect(() => {
    if (templateId) {
      setLibraryTemplateDataToLocalStorage({
        templateId,
        libraryItemSlug: libraryItemSlug ?? undefined,
        libraryItemName: libraryItemName ?? undefined,
        type: type ?? undefined,
      });
    }
  }, [libraryItemName, libraryItemSlug, localStorage, templateId, type]);

  return <div>{children}</div>;
}

function Root() {
  // NOTE (Fran 2024-02-22): We should check if the user has an uncompleted flow and redirect to the
  // next step.
  const { currentFlow } = useGetCurrentFlow();
  useExecuteFlowCallback(currentFlow?.slug);

  return (
    <SentryBoundary>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <LocalStorageProvider>
          <ReploTemplateDataManager>
            <WorkspaceContext>
              <CurrentProjectProvider>
                <AIStreamingProvider>
                  <Outlet />
                </AIStreamingProvider>
              </CurrentProjectProvider>
            </WorkspaceContext>
          </ReploTemplateDataManager>
        </LocalStorageProvider>
      </CookiesProvider>
    </SentryBoundary>
  );
}

type RouteNavigatorValue = {
  navigate: NavigateFunction;
  toParentRoute: () => void;
  fromInternalMarketplaceModal: (props: {
    projectId: string;
    draftElementId: string | undefined;
  }) => void;
};

// NOTE (Chance 2024-02-08): For routes that render modals, we need to provide
// navigation functions to the modal's close callback props. Doing this from
// within the modal component itself is a bit of a footgun because the same
// modal may be rendered in different route contexts, and we are trying to get
// rid of a bunch of complex branching logic in our modals to switch behavior
// based on the current route.
//
// This component allows us to wrap up a few navigation functions that are
// provided as render props to a child function that can wrap our route
// elements. This is a bit cleaner because navigation functions are defined
// inline with the routing logic, so we should understand clearly where the user
// for every modal interaction.
function RouteNavigator({
  children,
}: {
  children: (props: { navigator: RouteNavigatorValue }) => React.ReactElement;
}) {
  const navigate = useNavigate();
  const navigator: RouteNavigatorValue = React.useMemo(() => {
    return {
      navigate,
      toParentRoute: () => {
        navigate("..", {
          relative: "path",
        });
      },
      fromInternalMarketplaceModal: ({ projectId, draftElementId }) => {
        navigate(
          generateEditorPathname("", {
            projectId,
            elementId: draftElementId,
          }),
        );
      },
    };
  }, [navigate]);
  return children({ navigator });
}

function RouteWithUserState({
  children,
}: {
  children: (props: {
    userState: ReturnType<typeof useCurrentUser>;
  }) => React.ReactElement;
}) {
  const userState = useCurrentUser();
  return children({ userState });
}

function RouteWithRootRedirectPath({
  children,
}: {
  children: (props: { rootRedirectPath: string }) => React.ReactElement;
}) {
  const userState = useCurrentUser();
  const [searchParams] = useSearchParams();
  const { data } = trpc.workspace.getUserWorkspacesList.useQuery();
  const userHasOnlyOneWorkspace = (data?.workspaces ?? []).length === 1;

  let rootRedirectPath;
  if (!userState.isAuthenticated && !userState.isLoading) {
    rootRedirectPath = "/auth/signup";
  } else if (userHasOnlyOneWorkspace && data?.workspaces?.[0]?.id) {
    rootRedirectPath = generatePath("/workspace/:workspaceId/projects", {
      workspaceId: data.workspaces[0].id,
    });
  } else {
    rootRedirectPath = routes.dashboard;
  }

  const referral = searchParams.get("referral");
  if (referral) {
    rootRedirectPath += `?referral=${referral}`;
  }

  return children({ rootRedirectPath });
}

function RouteWithWorkspaceRedirection({
  children,
}: {
  children: (props: { rootRedirectPath: string }) => React.ReactElement;
}) {
  const params = useParams();
  if (!params.workspaceId) {
    return children({ rootRedirectPath: routes.dashboard });
  }
  return children({
    rootRedirectPath: `/workspace/${params.workspaceId}/projects`,
  });
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      {/* Begin Auth Routes */}
      <Route path="/auth" element={<AuthPage />}>
        <Route path="login" element={<LoginForm />} />
        <Route path="signup" element={<SignupForm />} />
        <Route path="password/reset/init" element={<InitResetPassword />} />
        <Route path="password/reset" element={<ResetPassord />} />
      </Route>
      <Route
        path="discourse/sso"
        element={
          <ProtectedRoute>
            <AuthDiscourseSso />
          </ProtectedRoute>
        }
      />
      <Route path="/signup/:referralCode" element={<ReferralCodeRoute />} />
      {/* End Auth Routes */}
      {/* Begin Dashboard Routes */}
      <Route
        path="/project"
        element={
          <ProtectedRoute>
            <RouteWithModals>
              <ProjectDashboard />
            </RouteWithModals>
          </ProtectedRoute>
        }
      >
        <Route path="new" element={<NewProjectFlow />} />
      </Route>
      <Route
        path={routes.dashboard}
        element={
          <ProtectedRoute>
            <RouteWithModals>
              <ProjectDashboard />
            </RouteWithModals>
          </ProtectedRoute>
        }
      >
        <Route
          path="details/:templateId"
          element={
            <ProtectedRoute>
              <RouteNavigator>
                {({ navigator }) => (
                  <TemplateDetailsModal
                    isOpen
                    onClose={navigator.fromInternalMarketplaceModal}
                  />
                )}
              </RouteNavigator>
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <RouteWithModals>
              <AccountDashboard />
            </RouteWithModals>
          </ProtectedRoute>
        }
      >
        <Route path="profile" element={<ProfileBoard />} />
        <Route path="security" element={<SecurityBoard />} />
      </Route>

      {/*
        NOTE (Sebas, 2023-12-11): This is in charge of redirecting to the new partner program
        page if the user has an workspace. If they don't have an workspace, they are
        redirected to the home page.
      */}
      <Route
        path="/partner/program"
        element={
          <RouteWithUserState>
            {({ userState }) => (
              <Navigate
                to={
                  userState.user?.workspace?.id
                    ? generatePath("/workspace/:workspaceId/partner/program", {
                        workspaceId: userState.user.workspace.id,
                      })
                    : // NOTE (Fran 2023-11-21): Fallback to home if user doesn't have a workspace
                      routes.dashboard
                }
                replace
              />
            )}
          </RouteWithUserState>
        }
      />
      {/* End Old Settings Routes */}

      {/* Begin Workspaces Routes */}
      <Route path="/workspace/billing" element={<BillingRedirect />} />
      <Route
        path="/workspace/:workspaceId"
        element={
          <ProtectedRoute>
            <RouteWithModals>
              <WorkspaceDashboard />
            </RouteWithModals>
          </ProtectedRoute>
        }
      >
        {/*
          NOTE (Fran 2024-03-22): We should redirect to /workspace/:workspaceId/projects if the user tries to
          access /workspace/:workspaceId. Otherwise will render a blank page.
        */}
        <Route
          path=""
          element={
            <RouteWithWorkspaceRedirection>
              {({ rootRedirectPath }) => (
                <Navigate to={rootRedirectPath} replace />
              )}
            </RouteWithWorkspaceRedirection>
          }
        />
        <Route path="projects" element={<WorkspaceProjects />} />
        <Route
          path="project"
          element={
            <ProtectedRoute>
              <RouteWithModals>
                <WorkspaceProjects />
              </RouteWithModals>
            </ProtectedRoute>
          }
        >
          <Route path="new" element={<NewProjectFlow />} />
        </Route>
        <Route path="members" element={<Members />} />
        <Route path="billing" element={<Billing />} />
        <Route path="partner" element={<PartnershipDashboard />}>
          <Route path="program" element={<PartnerProgramLPContent />} />

          <Route
            path="home"
            element={
              <ActivePartnershipRequired>
                <PartnershipReferralBoard />
              </ActivePartnershipRequired>
            }
          />
        </Route>
        <Route path="integrations" element={<WorkspaceIntegrations />} />
        <Route
          path="integrations/shopify"
          element={<ShopifyIntegrationSettings />}
        />
        <Route path="analytics/overview" element={<Analytics />} />
        <Route path="analytics/coming-soon" element={<ComingSoon />} />
        <Route path="analytics/overview" element={<Overview />} />
        <Route path="settings" element={<WorkspaceSettings />} />
      </Route>
      {/* End Workspace Routes */}

      {/* End Dashboard Routes */}
      {/* Begin Editor Routes */}
      <Route
        path={routes.editor.project}
        element={
          <RouteWithModals>
            <EditorApp />
          </RouteWithModals>
        }
      >
        <Route
          path={routes.marketplaceModal}
          element={
            <RouteNavigator>
              {({ navigator }) => (
                <MarketplaceModal isOpen onClose={navigator.toParentRoute} />
              )}
            </RouteNavigator>
          }
        />
        <Route
          path={`${routes.marketplaceModal}/category/:categorySlug`}
          element={
            <RouteNavigator>
              {({ navigator }) => (
                <MarketplaceModal isOpen onClose={navigator.toParentRoute} />
              )}
            </RouteNavigator>
          }
        />
        <Route
          path={`${routes.marketplaceModal}/collections`}
          element={
            <RouteNavigator>
              {({ navigator }) => (
                <TemplateCollectionsPageModal
                  isOpen
                  onClose={navigator.fromInternalMarketplaceModal}
                />
              )}
            </RouteNavigator>
          }
        />
        <Route
          path={`${routes.marketplaceModal}/collection/:collectionId`}
          element={
            <RouteNavigator>
              {({ navigator }) => (
                <TemplateCollectionsPageModal
                  isOpen
                  onClose={navigator.fromInternalMarketplaceModal}
                />
              )}
            </RouteNavigator>
          }
        />
        <Route
          path={`${routes.marketplaceModal}/details/:templateId`}
          element={
            <RouteNavigator>
              {({ navigator }) => (
                <TemplateDetailsModal
                  isOpen
                  onClose={navigator.fromInternalMarketplaceModal}
                />
              )}
            </RouteNavigator>
          }
        />
        <Route
          path="add"
          element={
            <RouteNavigator>
              {({ navigator }) => (
                <QuickStartModal isOpen onClose={navigator.toParentRoute} />
              )}
            </RouteNavigator>
          }
        />
      </Route>

      <Route
        path={routes.editor.project}
        element={
          <RouteWithModals>
            <ProjectDashboardLayout />
          </RouteWithModals>
        }
      >
        <Route path="project-settings" element={<ProjectSettingsTab />} />
        <Route
          path="integrations"
          element={<ProjectIntegrationSettingsTab />}
        />
        <Route path="template-defaults" element={<DesignSystemTab />} />
        <Route path="experiments" element={<ExperimentsTab />} />
        <Route
          path="experiments/:experimentId"
          element={<ExperimentDetailsTab />}
        />
        <Route
          path="experiments/:experimentId/edit"
          element={<ExperimentEditTab />}
        />
      </Route>

      <Route
        path={routes.editor.element}
        element={
          <RouteWithModals>
            <EditorApp />
          </RouteWithModals>
        }
      >
        <Route
          path={routes.marketplaceModal}
          element={
            <RouteNavigator>
              {({ navigator }) => (
                <MarketplaceModal isOpen onClose={navigator.toParentRoute} />
              )}
            </RouteNavigator>
          }
        />
        <Route
          path={`${routes.marketplaceModal}/category/:categorySlug`}
          element={
            <RouteNavigator>
              {({ navigator }) => (
                <MarketplaceModal isOpen onClose={navigator.toParentRoute} />
              )}
            </RouteNavigator>
          }
        />
        <Route
          path={`${routes.marketplaceModal}/collections`}
          element={
            <RouteNavigator>
              {({ navigator }) => (
                <TemplateCollectionsPageModal
                  isOpen
                  onClose={navigator.fromInternalMarketplaceModal}
                />
              )}
            </RouteNavigator>
          }
        />
        <Route
          path={`${routes.marketplaceModal}/collection/:collectionId`}
          element={
            <RouteNavigator>
              {({ navigator }) => (
                <TemplateCollectionsPageModal
                  isOpen
                  onClose={navigator.fromInternalMarketplaceModal}
                />
              )}
            </RouteNavigator>
          }
        />
        <Route
          path={`${routes.marketplaceModal}/details/:templateId`}
          element={
            <RouteNavigator>
              {({ navigator }) => (
                <TemplateDetailsModal
                  isOpen
                  onClose={navigator.fromInternalMarketplaceModal}
                />
              )}
            </RouteNavigator>
          }
        />
        <Route
          path="add"
          element={
            <RouteNavigator>
              {({ navigator }) => (
                <QuickStartModal isOpen onClose={navigator.toParentRoute} />
              )}
            </RouteNavigator>
          }
        />
      </Route>

      <Route
        path={routes.editor.product}
        element={
          <RouteWithModals>
            <EditorApp />
          </RouteWithModals>
        }
      />
      {/* End Editor Routes */}

      {/* Begin Flows Routes. All elements should be wrapped in `FlowRoute` */}
      <Route
        path="/flows/:flowSlug"
        element={
          <ProtectedRoute>
            <RouterFlows />
          </ProtectedRoute>
        }
      >
        <Route
          path=":flowStepId"
          element={
            <ProtectedRoute>
              <RouterFlows />
            </ProtectedRoute>
          }
        />
      </Route>

      {/* End Flows Routes */}

      <Route
        path="/"
        element={
          <RouteWithRootRedirectPath>
            {({ rootRedirectPath }) => (
              <Navigate to={rootRedirectPath} replace />
            )}
          </RouteWithRootRedirectPath>
        }
      />

      {/*  404 route redirects to home  */}
      <Route path="*" element={<Navigate to={routes.dashboard} replace />} />
    </Route>,
  ),
);

export default App;

import DashboardLayout from "@editor/components/layouts/DashboardLayout";
import * as React from "react";
import { Outlet } from "react-router-dom";

import { WorkspaceProvider } from "@/features/workspace/WorkspaceProvider";

const WorkspaceDashboard: React.FC = () => {
  return (
    <WorkspaceProvider>
      <DashboardLayout>
        <div className="bg-white no-scrollbar col-span-9 mt-8 flex flex-grow flex-col items-start gap-y-2 overflow-scroll">
          <Outlet />
        </div>
      </DashboardLayout>
    </WorkspaceProvider>
  );
};

export default WorkspaceDashboard;

import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";
import { getComponentRedirectAction } from "../../utils/component";
import { hasAnchorTag } from "./utils";

export const styleElements = {
  root: {
    defaultStyles({ component }) {
      const {
        props: { onClick },
      } = component;

      // Note (Chance, 2023-06-08) The branching here is a bit weird but I
      // needed to pull the styling logic out of ContentEditable to ensure that
      // the default text alignment is overridable by the user. This condition
      // matches the conditional rendering logic for button links, but it can
      // and should definitely be cleaned up as this is bug prone.
      //
      // Not blocking, but in a follow-up ticket I'd like to decouple some of
      // the editor-specific logic here from ContentEditable so that I can clean
      // this up and dedupe efforts.
      const buttonHasAnchorTag = hasAnchorTag(component);
      const onClickActions = onClick ?? [];
      const buttonHasMultipleActions = onClickActions.length > 1;
      const redirectAction = getComponentRedirectAction(component);

      if (!(buttonHasAnchorTag || buttonHasMultipleActions) && redirectAction) {
        return {
          // Note (Sebas, 2023-01-26): We need to center align the text to
          // emulate the default user-agent stylesheets of most browsers, which
          // set buttons to be center-aligned by default. If not, the text would
          // be aligned to the left.
          textAlign: "center",
        };
      }

      return {};
    },
    overrideStyles({ component }) {
      return component.props.textStyle;
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    acceptsArbitraryChildren: (config) => !config.hasTextProp,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: ({ textValue }) => !Boolean(textValue),
    canContainChildren: false,
    styleElements,
    editorProps: {
      interactions: {
        type: "interactions",
        title: "No interactions",
        description: "No Interaction. Quick add interactions here.",
        defaultValue: false,
      },
    },
    ancestorDisallowList: [
      {
        ancestorTypes: ["button"],
        message: "Buttons cannot be nested inside other buttons.",
      },
      {
        ancestorTypes: ["tabs__list", "tabsV2__list"],
        message: "Buttons cannot be nested inside Tabs.",
      },
    ],
  },
} satisfies ComponentConfig;

export default config;

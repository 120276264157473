import Button from "@common/designSystem/Button";
import Modal from "@common/designSystem/Modal";
import { successToast } from "@common/designSystem/Toast";
import { ModalLayout } from "@common/ModalLayout";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import type { RemoveMemberModalProps } from "../AppModalTypes";

export const RemoveMemberModal = ({
  workspaceId,
  user,
}: RemoveMemberModalProps) => {
  const { user: currentUser } = useCurrentUser();

  const { closeModal } = useModal();
  const logEvent = useLogAnalytics();
  const navigate = useNavigate();

  const { mutate: removeWorkspaceMembership, isPending: isLoading } =
    trpc.workspace.removeMember.useMutation({
      onError: () => {
        closeModal({ type: "removeMemberModal" });
      },
      onSuccess: () => {
        void trpcUtils.workspace.getWorkspaceAndProjectMembers.invalidate();
        void trpcUtils.project.membership.list.invalidate();
        successToast("User Removed", "The user was removed successfully.");
        logEvent("workspace.member.removed", {
          workspaceId,
          memberRemoved: user.email,
          removedBy: currentUser?.email,
        });

        closeModal({ type: "removeMemberModal" });

        if (currentUser?.id === user.id) {
          navigate("/home");
        }
      },
    });

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        closeModal({ type: "removeMemberModal" });
      }}
      className="w-auto"
    >
      <ModalLayout
        height="auto"
        width="25vw"
        minWidth={400}
        mainContent={() => (
          <div className="flex flex-col gap-6 p-2 w-full">
            <h2 className="text-xl font-medium">Remove Member</h2>
            <p className="text-sm text-default">
              {user.firstName
                ? `Are you sure you want to remove ${user.firstName} (${user.email}) from this workspace?`
                : "Are you sure you want to remove the user from this workspace?"}
            </p>
            <div className="flex gap-2 self-end">
              <Button
                type="secondary"
                size="base"
                textClassNames="text-xs"
                onClick={() => closeModal({ type: "removeMemberModal" })}
              >
                Cancel
              </Button>
              <Button
                type="danger"
                size="base"
                textClassNames="text-xs"
                onClick={() =>
                  void removeWorkspaceMembership({
                    userId: user.id,
                    workspaceId,
                  })
                }
                isLoading={isLoading}
              >
                Remove
              </Button>
            </div>
          </div>
        )}
      />
    </Modal>
  );
};

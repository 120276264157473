import * as React from "react";

import type { RenderComponentProps } from "../../../shared/types";
import { useComponentClassNames } from "../../../shared/utils/renderComponents";

const Spacer: React.FC<RenderComponentProps> = ({
  componentAttributes,
  context,
  component,
}) => {
  const classNameMap = useComponentClassNames("spacer", component, context);
  return <div {...componentAttributes} className={classNameMap?.root} />;
};

export default Spacer;

import {
  selectComponentDataMapping,
  selectComponentIdToDraftVariantId,
  selectIsPreviewMode,
  selectStreamingUpdateTextMap,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import * as React from "react";
import type { ComponentDataMapping } from "replo-runtime/shared/Component";
import { create } from "zustand";

type RuntimeState = {
  componentIdToDraftVariantId: Record<string, string | undefined>;
  isPreviewMode: boolean;
  streamingUpdateTextMap: Record<string, string> | undefined;
  componentDataMapping: ComponentDataMapping;
  actions: {
    setIsPreviewMode: (isPreviewMode: boolean) => void;
    setComponentIdToDraftVariantId: (
      componentIdToDraftVariantId: Record<string, string | undefined>,
    ) => void;
    setStreamingUpdateTextMap: (
      textMap: Record<string, string> | undefined,
    ) => void;
    setComponentDataMapping: (
      componentDataMapping: ComponentDataMapping,
    ) => void;
  };
};

/**
 * Independent store which holds all state which runtime components might subscribe
 * to in the editor.
 *
 * The reason this is necessary and we can't just use redux is that since redux is a
 * global store, every action must call every subscription (that is, any time any action
 * is dispatched, every useEditorSelector() function will be called). This is not ideal
 * for the runtime components, since there may be 1000s of them and they all might
 * subscribe to N different slices of state. This means that any time we dispatch
 * a redux action, even if it's not related to the runtime at all, we'd be calling N * 1000s
 * of functions, which is slow (even if each individual function is fast).
 *
 * This issue is especially apparent when dispatch() is called frequently, for example
 * when scrolling the canvas frames in edit mode.
 *
 * Instead, we use zustand to create a smaller store and define a hook to subscribe this
 * store to the redux state. This way, the runtime components can subscribe to this store
 * instead of the redux store, which means redux actions which aren't related to the runtime
 * won't call the 1000s of subscription functions.
 */
const useRuntimeStore = create<RuntimeState>((set) => {
  return {
    componentIdToDraftVariantId: {},
    isPreviewMode: false,
    streamingUpdateTextMap: undefined,
    componentDataMapping: {},
    actions: {
      setIsPreviewMode: (isPreviewMode) => {
        set({ isPreviewMode });
      },
      setComponentIdToDraftVariantId: (componentIdToDraftVariantId) => {
        set({ componentIdToDraftVariantId });
      },
      setStreamingUpdateTextMap: (textMap) => {
        set({ streamingUpdateTextMap: textMap });
      },
      setComponentDataMapping: (componentDataMapping) => {
        set({ componentDataMapping });
      },
    },
  };
});

export const useRuntimeOverrideVariantId = (componentId: string) => {
  const isPreviewMode = useRuntimeStore((state) => state.isPreviewMode);
  const editorOverrideVariantId = useRuntimeStore(
    (state) => state.componentIdToDraftVariantId[componentId],
  );
  // Note (Noah, 2024-08-02): If we're in preview mode, we want the variants to be
  // activated based on their event listeners, no overrides.
  if (isPreviewMode) {
    return null;
  }
  return editorOverrideVariantId;
};

export const useIsPreviewMode = () => {
  return useRuntimeStore((state) => state.isPreviewMode);
};

export const useEditorOverrideText = (componentId: string) => {
  return useRuntimeStore(
    (state) => state.streamingUpdateTextMap?.[componentId],
  );
};

export const useIsLabelledByOtherComponent = (componentId: string) => {
  return useRuntimeStore((state) => {
    return (
      state.componentDataMapping[componentId]?.isLabelledByOtherComponent ??
      false
    );
  });
};

export const useRuntimeStoreActions = () => {
  return useRuntimeStore((state) => state.actions);
};

/**
 * Hook which registers effects to sync the runtime store with a slice of the editor state.
 */
export function useSyncRuntimeStoreWithEditorState() {
  const actions = useRuntimeStoreActions();
  const componentIdToDraftVariantId = useEditorSelector(
    selectComponentIdToDraftVariantId,
  );
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const textMap = useEditorSelector(selectStreamingUpdateTextMap);
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);

  React.useEffect(() => {
    actions.setComponentIdToDraftVariantId(componentIdToDraftVariantId);
  }, [componentIdToDraftVariantId, actions]);

  React.useEffect(() => {
    actions.setIsPreviewMode(isPreviewMode);
  }, [isPreviewMode, actions]);

  React.useEffect(() => {
    actions.setStreamingUpdateTextMap(textMap);
  }, [textMap, actions]);

  React.useEffect(() => {
    actions.setComponentDataMapping(componentDataMapping);
  }, [componentDataMapping, actions]);
}

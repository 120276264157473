import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _products: {
      type: "products",
      name: "Products",
      description: "A list of Shopify products to include.",
      defaultValue: [],
    },
  };
}

export const styleElements = {
  root: {
    defaultStyles: {
      display: "flex",
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    allowsLayoutModification: true,
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: true,
    canvasIndicatorDragDirections: [],
    canContainChildren: true,
    showComponentControlsFromChildren: true,
    dynamicItemsPropName: "_products",
    styleElements,
  },
} satisfies ComponentConfig;

export default config;

import { trpc } from "@editor/utils/trpc";
import * as React from "react";
import { Navigate } from "react-router-dom";

const BillingRedirect: React.FC = () => {
  const { data: workspaceId, isLoading } =
    trpc.workspace.getUserDefaultWorkspaceId.useQuery();

  if (isLoading) {
    return null;
  }
  return (
    <Navigate
      to={workspaceId ? `/workspace/${workspaceId}/billing` : "/home"}
    />
  );
};
export default BillingRedirect;

import EffectsModifier from "@editor/components/editor/page/element-editor/components/modifiers/EffectsModifier";
import { ForegroundColorModifier } from "@editor/components/editor/page/element-editor/components/modifiers/ForegroundColorModifier";
import PositioningModifier from "@editor/components/editor/page/element-editor/components/modifiers/PositioningModifier";
import TextOutlineModifier from "@editor/components/editor/page/element-editor/components/modifiers/TextOutlineModifier";
import type { ModifierGroupType } from "@editor/types/modifier-group";
import { BackgroundModifier } from "@editorModifiers/BackgroundModifier";
import BorderModifier from "@editorModifiers/BorderModifier";
import BoxShadowModifier from "@editorModifiers/BoxShadowModifier";
import { ColorModifier } from "@editorModifiers/ColorModifier";
import DimensionModifier from "@editorModifiers/DimensionModifier";
import { FlexboxModifier } from "@editorModifiers/FlexboxModifier";
import FontModifier from "@editorModifiers/FontModifier";
import { IconModifier } from "@editorModifiers/IconModifier";
import ImageSourceModifier from "@editorModifiers/ImageSourceModifier";
import ItemsModifier from "@editorModifiers/ItemsModifier";
import { RowColumnSpanModifier } from "@editorModifiers/RowColumnSpanModifier";
import TextShadowModifier from "@editorModifiers/TextShadowModifier";
import TextStyleModifier from "@editorModifiers/TextStyleModifier";
import Transform2d3dModifier from "@editorModifiers/Transform2D3DModifier";
import VideoSourceModifier from "@editorModifiers/VideoSourceModifier";
import { VisibilityModifier } from "@editorModifiers/VisibilityModifier";
import * as React from "react";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

export const ModifierGroups: React.FC<
  React.PropsWithChildren<{ groupTypes: ModifierGroupType[] }>
> = ({ groupTypes }) => (
  <>
    {groupTypes.map((groupType) => (
      <React.Fragment key={groupType.type}>
        {exhaustiveSwitch(groupType)({
          rowColumnSpans: <RowColumnSpanModifier />,
          dimension: <DimensionModifier />,
          visibility: <VisibilityModifier />,
          textStyle: <TextStyleModifier />,
          foregroundColor: ({ allowsGradientSelection }) => (
            <ForegroundColorModifier
              allowsGradientSelection={allowsGradientSelection}
            />
          ),
          textShadow: <TextShadowModifier />,
          textOutline: <TextOutlineModifier />,
          positioning: <PositioningModifier />,
          color: <ColorModifier />,
          border: <BorderModifier />,
          background: <BackgroundModifier />,
          items: ({
            allowsDynamicDataWhenAvailable,
            field,
            title,
            allowedItemTypes,
          }) => (
            <ItemsModifier
              allowsDynamicDataWhenAvailable={allowsDynamicDataWhenAvailable}
              allowedItemTypes={allowedItemTypes}
              title={title}
              field={field}
            />
          ),
          font: <FontModifier />,
          flexboxAlignment: ({ includeAlignmentModifier }) => (
            <FlexboxModifier
              includeAlignmentModifier={includeAlignmentModifier}
            />
          ),
          effects: <EffectsModifier />,
          icon: <IconModifier />,
          imageSource: <ImageSourceModifier />,
          videoSource: <VideoSourceModifier />,
          transform2d3d: <Transform2d3dModifier />,
          boxShadow: <BoxShadowModifier />,
        })}
      </React.Fragment>
    ))}
  </>
);

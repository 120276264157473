import * as React from "react";
import { useCookies } from "react-cookie";

const LATEST_WORKSPACE_COOKIE_KEY =
  "replo.integrations.selectedWorkspaceIdForShopifyIntegration";

const useGetWorkspaceUtils = () => {
  const [cookies, setCookie] = useCookies([LATEST_WORKSPACE_COOKIE_KEY]);

  const getLatestWorkspaceIdFromCookies = React.useCallback(() => {
    return cookies[LATEST_WORKSPACE_COOKIE_KEY];
  }, [cookies]);

  const setLatestWorkspaceIdOnCookies = React.useCallback(
    (id: string) => {
      return setCookie(LATEST_WORKSPACE_COOKIE_KEY, id, {
        path: "/",
        maxAge: 60 * 10, // 10 minutes
      });
    },
    [setCookie],
  );

  return { getLatestWorkspaceIdFromCookies, setLatestWorkspaceIdOnCookies };
};

export default useGetWorkspaceUtils;

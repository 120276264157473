import { trackError } from "@editor/infra/analytics";
import { isDevelopment } from "@editor/utils/env";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { ErrorBoundaryDefaultFallback } from "replo-runtime/shared/ErrorBoundary";

const SentryBoundary: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <div className="p-4 font-sans text-sm">
          <ErrorBoundaryDefaultFallback />
        </div>
      }
      showDialog
      dialogOptions={{
        title: "Oh no! Looks like something went wrong.",
        subtitle:
          "Our team has been notified using automatic error " +
          "reporting. If this error persists, feel free to reach " +
          "out at support@replo.app.",
        subtitle2:
          "If you'd like to help us investigate this issue, let us know" +
          " what you were doing when this error occurred.",
      }}
      onError={(error, componentStack, eventId) => {
        if (isDevelopment) {
          console.error("[REPLO] Tracking Sentry error", {
            error,
            componentStack,
            eventId,
          });
        }
        trackError(error);
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryBoundary;

import AllProjects from "@editor/components/dashboard/projects/AllProjects";
import DashboardLayout from "@editor/components/layouts/DashboardLayout";
import useGetProjectUtils from "@editor/hooks/useGetProjectUtils";
import useGetWorkspaceUtils from "@editor/hooks/useGetWorkspaceUtils";
import useUserHasNewAnnouncements from "@editor/hooks/useUserHasNewAnnouncements";
import { trpc } from "@editor/utils/trpc";
import * as React from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

export default function ProjectDashboard() {
  useUserHasNewAnnouncements();
  useConnectShopifyIntegrationNavigator();

  return (
    <DashboardLayout>
      <div className="bg-white no-scrollbar col-span-9 mt-8 flex flex-grow flex-col items-start gap-y-2 overflow-scroll">
        <AllProjects />
      </div>
      <Outlet />
    </DashboardLayout>
  );
}

/**
 * NOTE (Matt 2024-07-05): When a user installs Replo on their shopify store
 * or simply navigates to the Replo app through their already-installed shopify
 * store, they will be redirected to the project creation page.
 * If there is a pendingAppInstallationId or shopifyIntegrationId in the searchParams,
 * then that is how the user navigated to this point.
 * If there is a projectId or workspaceId in their cookies, then they were
 * prompted to install their Shopify store from a project or workspace dashboard.
 * This hook is responsible for navigating the user to the correct page based on these
 * four parameters.
 */
function useConnectShopifyIntegrationNavigator() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pendingAppInstallationId = searchParams.get("pendingAppInstallationId");
  const shopifyIntegrationId = searchParams.get("shopifyIntegrationId");
  const { getLatestProjectIdFromCookies } = useGetProjectUtils();
  const { getLatestWorkspaceIdFromCookies } = useGetWorkspaceUtils();
  const projectFromCookies = getLatestProjectIdFromCookies();
  const workspaceFromCookies = getLatestWorkspaceIdFromCookies();

  const {
    mutate: connectIntegration,
    isPending,
    isError,
  } = trpc.integration.convertPendingAppInstallationToIntegration.useMutation({
    onSuccess: () => {
      navigate(`/workspace/${workspaceFromCookies}/integrations/shopify`);
    },
  });

  React.useEffect(() => {
    // NOTE (Matt 2024-07-05): If there is a pendingAppInstallationId and a workspaceFromCookies
    // we need to immediately connect the pending app install to the workspace,
    // then redirect the user back where they came from: /workspace/:workspaceId/integrations/shopify
    const shouldConnectWorkspaceToPendingInstall =
      !isPending &&
      !isError &&
      !projectFromCookies &&
      !shopifyIntegrationId &&
      pendingAppInstallationId &&
      workspaceFromCookies;

    if (shouldConnectWorkspaceToPendingInstall) {
      connectIntegration({
        workspaceId: workspaceFromCookies,
        pendingAppInstallationId,
      });
    } else if (
      !isPending &&
      (pendingAppInstallationId || shopifyIntegrationId)
    ) {
      navigate({
        pathname: "/project/new",
        search: searchParams.toString(),
      });
    }
  }, [
    projectFromCookies,
    shopifyIntegrationId,
    pendingAppInstallationId,
    workspaceFromCookies,
    connectIntegration,
    isPending,
    isError,
    navigate,
    searchParams,
  ]);
}

import { warningToast } from "@editor/components/common/designSystem/Toast";
import { selectIsTextDynamicData } from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorStore } from "@editor/store";
import * as React from "react";

import { setCanvasInteractionMode } from "@/features/canvas/canvas-reducer";

export const useEnableNonDynamicTextEditing = () => {
  const store = useEditorStore();
  const dispatch = useEditorDispatch();
  const isTextDynamicData = selectIsTextDynamicData(store.getState());

  return React.useCallback(() => {
    if (isTextDynamicData) {
      warningToast(
        "Can't edit dynamic data",
        "This component cannot be edited since it contains dynamic data. Remove dynamic data to edit.",
      );
    } else {
      dispatch(setCanvasInteractionMode("content-editing"));
    }
  }, [dispatch, isTextDynamicData]);
};

import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { prependProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedLiquidReviewIntegrations } from "../SharedLiquidReviewIntegrations";

function ShopifyProductReviewsWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const product = useProductFromProps(component.props, context);
  const liquidSource = `<div id="shopify-product-reviews" data-id="{{product.id}}">{{ product.metafields.spr.reviews }}</div>`;
  const liquid = prependProductAssignmentToLiquidSource({
    product,
    liquidSource,
    context: props.context,
  });
  return (
    <SharedLiquidReviewIntegrations
      attributes={componentAttributes}
      component={component}
      liquidSource={liquid}
      placeholder="Shopify Product Reviews Widget will appear here"
      shouldHavePlaceHolder={isEditorApp || !product}
      appName="Shopify Product Reviews"
      context={context}
    />
  );
}

export default ShopifyProductReviewsWidget;

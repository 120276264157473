import { useEditorDispatch, useEditorSelector } from "@editor/store";
import * as React from "react";
import { flushSync } from "react-dom";
import { getAlchemyEditorWindow } from "replo-runtime/shared/Window";

import { handleTransformWillChange } from "./canvas-actions";
import {
  selectCanvasArea,
  selectCanvasInteractionMode,
  selectCanvasScale,
  zoomCanvas,
} from "./canvas-reducer";
import { clientPosToTranslatedPos, scaleFromPoint } from "./canvas-utils";
import { useSetDeltaXY } from "./useSetDeltaXY";

export function useCanvasZoom() {
  const canvasArea = useEditorSelector(selectCanvasArea);
  const canvasScale = useEditorSelector(selectCanvasScale);
  const canvasInteractionMode = useEditorSelector(selectCanvasInteractionMode);
  const setDeltaXY = useSetDeltaXY();
  const dispatch = useEditorDispatch();

  const handleCanvasZoom = React.useCallback(
    (newScale: number) => {
      // Note (Noah, 2022-08-04): We need to wrap this whole thing in
      // flushSync because React 18's state batching can cause the scale
      // calculation to work incorrectly (because each successive scale
      // operation depends on the last scale value, batching them causes
      // issues)
      flushSync(() => {
        if (canvasInteractionMode === "locked") {
          return;
        }

        dispatch(handleTransformWillChange());

        dispatch(zoomCanvas(newScale));
        setDeltaXY((prevState) => {
          if (!canvasArea) {
            return prevState;
          }

          const referentialPos = clientPosToTranslatedPos(
            canvasArea,
            {
              x: canvasArea.offsetWidth / 2,
              y: canvasArea.offsetHeight / 2,
            },
            { x: prevState.deltaX, y: prevState.deltaY },
          );
          const editorWindow = getAlchemyEditorWindow();
          if (editorWindow) {
            editorWindow.alchemyEditor.canvasScale = newScale;
            editorWindow.alchemyEditor.canvasYOffset = prevState.deltaY;
          }
          return scaleFromPoint(
            canvasScale,
            newScale,
            prevState.deltaX,
            prevState.deltaY,
            referentialPos,
          );
        });
      });
    },
    [canvasArea, canvasScale, dispatch, setDeltaXY, canvasInteractionMode],
  );

  const handleCanvasZoomIn = React.useCallback(() => {
    handleCanvasZoom(canvasScale * 1.1);
  }, [canvasScale, handleCanvasZoom]);

  const handleCanvasZoomOut = React.useCallback(() => {
    handleCanvasZoom(canvasScale / 1.1);
  }, [canvasScale, handleCanvasZoom]);

  return { handleCanvasZoom, handleCanvasZoomIn, handleCanvasZoomOut };
}

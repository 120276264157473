import { giveNameToAllChildren } from "@components/editor/component";
import {
  horizontalStack,
  verticalStack,
} from "@components/editor/templates/stacks";
import type {
  ComponentTemplate,
  ComponentTemplateTransform,
  ComponentTemplateTransformExtras,
} from "@editor/types/component-template";
import { canComponentAcceptArbitraryChildren } from "@utils/component";
import type { Component } from "replo-runtime/shared/Component";
import type { ProductRef } from "replo-runtime/shared/types";
import {
  forEachComponentAndDescendants,
  getCustomPropDefinitions,
} from "replo-runtime/shared/utils/component";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import type { Context } from "replo-runtime/store/AlchemyVariable";
import type { ProductResolutionDependencies } from "replo-runtime/store/ReploProduct";
import { getProduct, isContextRef } from "replo-runtime/store/ReploProduct";
import { refreshComponentIds } from "replo-shared/refreshComponentIds";
import type { ReploElement, ReploPartialElement } from "schemas/element";

/**
 * Given a component, go through it and all of its descendants and reset any product/products props
 * so that they come from the given list of products. For products props (multiple products) this tries
 * to use the first N products, of if there are not enough, duplicates the first one N times.
 */
export const normalizeProductProps = (
  component: Component,
  productResolutionDependencies: ProductResolutionDependencies,
  context: Context | null,
) => {
  forEachComponentAndDescendants(component, (component) => {
    if (
      productResolutionDependencies.products &&
      productResolutionDependencies.products.length > 0
    ) {
      const propDefinitions = getCustomPropDefinitions(component);
      for (const definition of propDefinitions) {
        if (definition.type === "product") {
          const productRef = component.props[
            definition.id
          ] as ProductRef | null;
          if (
            !productRef ||
            (!isContextRef(productRef) &&
              !getProduct(productRef, context, {
                productMetafieldValues: {},
                variantMetafieldValues: {},
                products: productResolutionDependencies.products,
                currencyCode: productResolutionDependencies.currencyCode,
                moneyFormat: productResolutionDependencies.moneyFormat,
                language: productResolutionDependencies.language,
                templateProduct: productResolutionDependencies.templateProduct,
              })) ||
            (isContextRef(productRef) &&
              productRef.ref === "attributes._templateProduct" &&
              context?.elementType !== "shopifyProductTemplate")
          ) {
            const product = getProduct(
              productRef ?? { productId: 0, variantId: 0, id: 0 },
              context,
              {
                productMetafieldValues: {},
                variantMetafieldValues: {},
                products: productResolutionDependencies.products,
                currencyCode: productResolutionDependencies.currencyCode,
                moneyFormat: productResolutionDependencies.moneyFormat,
                language: productResolutionDependencies.language,
                fallbackStrategy: { type: "nthProduct", defaultIndex: 0 },
                templateProduct: productResolutionDependencies.templateProduct,
              },
            );
            component.props[definition.id] = {
              productId: product?.id,
              variantId:
                product?.variants?.length ?? 0 > 0
                  ? product?.variants[0]!.id
                  : null,
            };
          }
        }
        if (definition.type === "products") {
          const productRefsFromProps = component.props[definition.id] as
            | ProductRef[]
            | null;
          const productRefs = productRefsFromProps?.map((productRef, index) => {
            if (
              !productRef ||
              (!isContextRef(productRef) &&
                !getProduct(productRef, context, {
                  productMetafieldValues: {},
                  variantMetafieldValues: {},
                  products: productResolutionDependencies.products,
                  currencyCode: productResolutionDependencies.currencyCode,
                  moneyFormat: productResolutionDependencies.moneyFormat,
                  language: productResolutionDependencies.language,
                  templateProduct:
                    productResolutionDependencies.templateProduct,
                }))
            ) {
              const product = getProduct(
                productRef ?? { productId: 0, variantId: 0 },
                context,
                {
                  productMetafieldValues: {},
                  variantMetafieldValues: {},
                  products: productResolutionDependencies.products,
                  currencyCode: productResolutionDependencies.currencyCode,
                  moneyFormat: productResolutionDependencies.moneyFormat,
                  language: productResolutionDependencies.language,
                  fallbackStrategy: { type: "nthProduct", defaultIndex: index },
                  templateProduct:
                    productResolutionDependencies.templateProduct,
                },
              );
              return {
                productId: product?.id,
                variantId:
                  product?.variants?.length ?? 0 > 0
                    ? product?.variants[0]!.id
                    : null,
              };
            }
            return productRef;
          });
          component.props[definition.id] = productRefs;
        }
      }
    }
  });

  return component;
};

const replaceProductPropsWithTemplateProduct = (component: Component) => {
  forEachComponentAndDescendants(component, (component) => {
    if (component.type === "product") {
      // Note (Evan, 2023-11-03): We don't want to overwrite existing dynamic refs, that would cause
      // e.g. product collections to get all assigned to the template product, which is silly.
      const existingRef = component.props._product;
      const existingRefIsDynamic = existingRef && isContextRef(existingRef);
      if (!existingRefIsDynamic) {
        component.props = {
          ...component.props,
          _product: {
            ref: "attributes._templateProduct",
            type: "contextRef",
          },
        };
      }
    }
  });
  return component;
};

const defaultTransforms: ComponentTemplateTransform[] = [
  (component, parent, _, extras) => {
    // Note (Noah, 2022-01-03): By default, all containers expand out as far
    // as they can to fill their parent (unless later a given height/width is
    // set by the user). To enable this, set both flex-grow and align-self stretch
    if (
      canComponentAcceptArbitraryChildren(component, {
        movementSource: "transformAfterDrop",
      })
    ) {
      if (!component.props) {
        component.props = {};
      }
      if (!component.props.style) {
        component.props.style = {};
      }

      // Only set flex-grow when parent flex-direction is row and the component has not a width set
      if (parent) {
        const parentFlexDirection = extras.getAttribute(
          parent,
          "style.flexDirection",
        );
        const componentHasWidthSet = component.props.style.width;
        if (parentFlexDirection.value === "row" && !componentHasWidthSet) {
          component.props.style.flexGrow = "1";
        }
      }

      // Note (Martin, 2022-08-22): Only default align-self to strech if it's not set already
      const alignSelf = extras.getAttribute(component, "style.alignSelf");
      if (!alignSelf.value) {
        component.props.style.alignSelf = "stretch";
      }
    }
    return component;
  },
  (component, _, element, extras): Component => {
    // NOTE (Evan, 8/31/23) Using element.type instead of extras.context.isInsideTemplateProductComponent because it seems like
    // we're passing context: null a lot of the time. We may want to change this if we want to do anything fancier
    // than "swap out every product for the template product"
    if (element?.type === "shopifyProductTemplate") {
      return replaceProductPropsWithTemplateProduct(component);
    }
    return normalizeProductProps(
      component,
      extras.productResolutionDependencies,
      extras.context,
    );
  },
];

/**
 * Takes an existing component and returns a new copy with refreshed IDs.
 *
 * ID rules:
 * - All existing UIDs will be replaced with new ones
 * - For a given UID, all instances of that UID will be replaced with the new one
 *   so that variant overrides etc are consistent
 * - Each instance of the special "$uid" value will be replaced with a new unique uid
 */
export const prepareComponentTemplate = (
  template: ComponentTemplate,
  parent: Component | null,
  // Note (Noah, 2022-03-30): Element may be null in the case of a new store without
  // any current pages created
  element: ReploElement | ReploPartialElement | null,
  extras: ComponentTemplateTransformExtras | null,
): Component => {
  if (!template.template) {
    throw new Error(
      "The component does not have a template, please ensure that the component is properly defined.",
    );
  }
  let result = refreshComponentIds(template.template).component;

  if (element?.component) {
    giveNameToAllChildren(result, element.component);
  }
  if (extras) {
    if (template.transforms) {
      for (const transform of template.transforms) {
        result = transform(result, parent, element, extras);
      }
    }
    for (const transform of defaultTransforms) {
      result = transform(result, parent, element, extras);
    }
  }

  return result;
};

export const HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE =
  horizontalStack as ComponentTemplate;
export const VERTICAL_CONTAINER_COMPONENT_TEMPLATE =
  verticalStack as ComponentTemplate;

export const transformReviewsTemplateToUseProductForContextIfNecessary: ComponentTemplateTransform =
  (component, parent) => {
    if (!parent) {
      return component;
    }

    // NOTE (Fran 2024-05-21): If the component is inside a product context, we want to use the product
    // from the context instead of the one from the component.
    const context = getCurrentComponentContext(parent.id, 0);
    if (context?.attributes?.["_product"]) {
      component.props["_product"] = {
        type: "contextRef",
        ref: "attributes._product",
      };
    }

    if (context?.attributes?.["_templateProduct"]) {
      component.props["_templateProduct"] = {
        type: "contextRef",
        ref: "attributes._templateProduct",
      };
    }

    return component;
  };

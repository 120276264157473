import ReferralLinkItem from "@components/dashboard/partner/ReferralLinkItem";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useModal } from "@editor/hooks/useModal";
import { trpc } from "@editor/utils/trpc";
import { Button } from "@replo/design-system/components/button";
import { skipToken } from "@tanstack/react-query";
import * as React from "react";
import { BsPlusLg } from "react-icons/bs";

export const ReferralLinkCallout = () => {
  const { openModal } = useModal();
  const workspaceId = useCurrentWorkspaceId();

  const { data } = trpc.workspace.getById.useQuery(
    workspaceId ? { id: workspaceId } : skipToken,
  );

  const referralCodes = data?.workspace?.referralCodes ?? [];
  const shouldDisableButton = referralCodes.length >= 5;

  return (
    <div className="flex flex-col gap-y-4">
      <div>
        <div className="flex flex-row gap-x-1 items-center">
          <h3 className="text-lg font-medium">
            {`Your Custom Referral ${referralCodes.length > 1 ? "Codes" : "Code"}`}
          </h3>
          <Button
            variant="tertiary"
            icon={<BsPlusLg />}
            isDisabled={shouldDisableButton}
            onClick={() => openModal({ type: "addReferralCodeModal" })}
            tooltipText={
              shouldDisableButton
                ? "Only 5 custom referral codes are supported. Please contact support@replo.app if you need more."
                : undefined
            }
          />
        </div>
        <span className="text-sm font-normal text-slate-400">
          {referralCodes.length === 0
            ? "You do not have any custom referral codes yet. Press + icon to create a new referral code."
            : "Your custom referral codes"}
        </span>
      </div>
      {referralCodes.length > 0 ? (
        <div className="flex flex-col gap-y-2">
          {referralCodes.map((referralCode) => (
            <ReferralLinkItem
              key={referralCode.id}
              referralCode={referralCode}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

import InlinePopover from "@common/designSystem/InlinePopover";
import { Menu, MenuTrigger } from "@common/designSystem/Menu";
import Selectable from "@common/designSystem/Selectable";
import Separator from "@common/designSystem/Separator";
import { HotkeyIndicator } from "@common/HotkeyIndicator";
import { LengthInputSelector } from "@components/editor/page/element-editor/components/modifiers/LengthInputModifier";
import TourStepTrigger from "@components/flows/TourStepTrigger";
import LabeledIconButton from "@components/header/LabeledIconButton";
import { useSubscriptionTier } from "@editor/hooks/subscription";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  redoOperation,
  selectEditorMode,
  selectIsPreviewMode,
  selectValidNextOperation,
  selectValidPreviousOperation,
  setEditorMode,
  undoOperation,
} from "@editor/reducers/core-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { DraggingDirections, DraggingTypes } from "@editor/utils/editor";
import type { HotkeyAction } from "@editor/utils/hotkeys";
import { Button } from "@replo/design-system/components/button";
import classNames from "classnames";
import * as React from "react";
import { BiChevronLeft } from "react-icons/bi";
import {
  BsChevronDown,
  BsChevronUp,
  BsFillAspectRatioFill,
  BsFillTabletFill,
} from "react-icons/bs";
import { IoTabletLandscape } from "react-icons/io5";
import {
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiComputerFill,
  RiEditFill,
  RiEyeLine,
  RiPlayFill,
} from "react-icons/ri";
import useMeasure from "react-use-measure";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import { parseFloat } from "replo-utils/lib/math";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import { capitalizeFirstLetter } from "replo-utils/lib/string";
import { BillingTiers } from "schemas/billing";

import AIMenu from "@/features/canvas/AIMenu";

import { CANVAS_DATA } from "./canvas-constants";
import {
  selectActiveCanvas,
  selectActiveCanvasWidth,
  selectCanvases,
  selectCanvasIsLoading,
  selectCanvasScale,
  selectPreviewWidth,
  setPreviewWidth,
} from "./canvas-reducer";
import type { CanvasData } from "./canvas-types";
import { getCanvasData, getPresets } from "./canvas-utils";
import { useCanvasZoom } from "./useCanvasZoom";
import { useDeviceControls } from "./useDeviceControls";

const SCALE_OPTIONS = [
  { label: "50%", value: "0.5" },
  { label: "75%", value: "0.75" },
  { label: "100%", value: "1.0" },
  { label: "125%", value: "1.25" },
];

const SCALE_ACTIONS = [
  {
    label: "Zoom In",
    value: "zoomIn",
  },
  {
    label: "Zoom Out",
    value: "zoomOut",
  },
];

export const CanvasControls: React.FC = () => {
  const [isScaleMenuVisible, setIsScaleMenuVisible] = React.useState(false);

  const store = useEditorStore();
  const editorMode = useEditorSelector(selectEditorMode);
  const canvasScale = useEditorSelector(selectCanvasScale);
  const hasPreviousOperation = useEditorSelector(selectValidPreviousOperation);
  const hasNextOperation = useEditorSelector(selectValidNextOperation);

  const modal = useModal();
  const dispatch = useEditorDispatch();
  const logEvent = useLogAnalytics();
  const subscriptionTier = useSubscriptionTier();
  const workspaceId = useCurrentWorkspaceId();
  const isWorkspaceOwner = useIsWorkspaceOwner(workspaceId);
  const { abort: abortAIStream } = useAIStreaming();
  const [controlsRef, { width: controlsWidth }] = useMeasure();
  const { handleCanvasZoom, handleCanvasZoomIn, handleCanvasZoomOut } =
    useCanvasZoom();

  const isEditMode = editorMode === EditorMode.edit;
  const formattedCanvasScale = `${(canvasScale * 100).toFixed(0)}%`;
  const isMultipleCanvasesEnabled = isFeatureEnabled("multiple-canvases");

  function handleEditorModeChange() {
    const activeCanvasWidth = selectActiveCanvasWidth(store.getState());

    // Note (Evan, 2024-06-14): Cancel AI streaming when switching back to edit mode
    if (editorMode === EditorMode.aiGeneration) {
      abortAIStream();
    }
    if (isEditMode) {
      logEvent("canvas.preview", { source: "toolbar" });
      dispatch(setPreviewWidth(activeCanvasWidth));
    }
    dispatch(setEditorMode(isEditMode ? EditorMode.preview : EditorMode.edit));
  }

  function handleUndo() {
    dispatch(undoOperation());
  }

  function handleRedo() {
    dispatch(redoOperation());
  }

  function handleScaleChange(value: string) {
    if (value === "zoomIn") {
      handleCanvasZoomIn();
    } else if (value === "zoomOut") {
      handleCanvasZoomOut();
    } else {
      const scale = parseFloat(value);
      if (Number.isNaN(scale)) {
        return;
      }

      handleCanvasZoom(scale);
    }
  }

  const scaleItems = [
    ...SCALE_OPTIONS.map(({ label, value }, idx) => ({
      type: "leaf" as const,
      id: idx.toString(),
      title: label,
      onSelect: () => handleScaleChange(value),
    })),
    {
      type: "section" as const,
      items: SCALE_ACTIONS.map(({ label, value }, idx) => ({
        type: "leaf" as const,
        id: idx.toString(),
        title: label,
        onSelect: () => handleScaleChange(value),
        endEnhancer: () => (
          <HotkeyIndicator
            hotkey={value as HotkeyAction}
            title=""
            badgeBackgroundColor="bg-transparent"
          />
        ),
      })),
    },
  ];
  const areControlsDisabled = editorMode === EditorMode.aiGeneration;
  return (
    <aside
      aria-label="Canvas Controls"
      className="fixed bottom-8 left-1/2 flex -translate-y-1/2 -translate-x-1/2 flex-row items-center gap-4 bg-white py-2 px-5 shadow-lg rounded"
      ref={controlsRef}
    >
      {isMultipleCanvasesEnabled ? (
        <Button
          id={isEditMode ? "preview-button" : "edit-button"}
          variant="no-style"
          icon={
            isEditMode ? (
              <RiPlayFill className="text-2xl" />
            ) : (
              <BiChevronLeft className="text-2xl" />
            )
          }
          isDisabled={areControlsDisabled}
          className={classNames(
            "text-slate-400 p-2 rounded-md",
            !areControlsDisabled && "hover:text-default hover:bg-slate-100",
          )}
          tooltipCustomContent={<HotkeyIndicator hotkey="togglePreviewMode" />}
          onClick={handleEditorModeChange}
        />
      ) : (
        <LabeledIconButton
          icon={
            isEditMode ? (
              <RiEyeLine className="text-lg" />
            ) : (
              <RiEditFill className="text-lg" />
            )
          }
          className="text-slate-400"
          tooltipContent={<HotkeyIndicator hotkey="togglePreviewMode" />}
          onClick={handleEditorModeChange}
          data-testid={isEditMode ? "preview-button" : "edit-button"}
        >
          {isEditMode ? "Preview" : "Edit"}
        </LabeledIconButton>
      )}
      <Separator orientation="vertical" className="h-6" />
      <TourStepTrigger step="step-4">
        <DeviceControls />
      </TourStepTrigger>
      {isEditMode && (
        <>
          <Separator orientation="vertical" className="h-6" />
          {isMultipleCanvasesEnabled ? (
            <>
              <Button
                variant="no-style"
                icon={<RiArrowGoBackLine className="text-2xl" />}
                className={classNames(
                  "text-slate-400 p-2 rounded-md",
                  hasPreviousOperation &&
                    "text-slate-600 hover:text-default hover:bg-slate-100",
                )}
                tooltipCustomContent={<HotkeyIndicator hotkey="undo" />}
                onClick={handleUndo}
                isDisabled={!hasPreviousOperation}
              />
              <Button
                variant="no-style"
                icon={<RiArrowGoForwardLine className="text-2xl" />}
                className={classNames(
                  "text-slate-400 p-2 rounded-md -ml-4",
                  hasNextOperation &&
                    "text-slate-600 hover:text-default hover:bg-slate-100",
                )}
                tooltipCustomContent={<HotkeyIndicator hotkey="redo" />}
                onClick={handleRedo}
                isDisabled={!hasNextOperation}
              />
            </>
          ) : (
            <>
              <LabeledIconButton
                icon={<RiArrowGoBackLine className="text-lg" />}
                tooltipContent={<HotkeyIndicator hotkey="undo" />}
                onClick={handleUndo}
                isDisabled={!hasPreviousOperation}
                className="-mr-2"
              >
                Undo
              </LabeledIconButton>
              <LabeledIconButton
                icon={<RiArrowGoForwardLine className="text-lg" />}
                tooltipContent={<HotkeyIndicator hotkey="redo" />}
                onClick={handleRedo}
                isDisabled={!hasNextOperation}
                className="-ml-2"
              >
                Redo
              </LabeledIconButton>
            </>
          )}
          {(isMultipleCanvasesEnabled || isEditMode) && (
            <Separator orientation="vertical" className="h-6" />
          )}
          <Menu
            items={scaleItems}
            customWidth={180}
            align="center"
            onRequestClose={() => {
              setIsScaleMenuVisible(false);
            }}
            onRequestOpen={() => {
              setIsScaleMenuVisible(true);
            }}
            trigger={
              <MenuTrigger asChild>
                <button
                  type="button"
                  className="flex h-6 w-20 items-center justify-between gap-2 rounded bg-slate-100 px-3 py-1 text-sm text-slate-600"
                >
                  {formattedCanvasScale}
                  <BsChevronUp
                    size={12}
                    className={classNames("text-slate-600 transition-all", {
                      "rotate-180": isScaleMenuVisible,
                    })}
                  />
                </button>
              </MenuTrigger>
            }
          />
        </>
      )}
      {!isMultipleCanvasesEnabled && (
        <>
          <Separator orientation="vertical" className="h-6" />
          {subscriptionTier === BillingTiers.FREE && (
            <Button
              variant="primary"
              size="base"
              isDisabled={!isWorkspaceOwner}
              tooltipText={
                !isWorkspaceOwner
                  ? "You cannot upgrade this workspace as you are not the admin."
                  : undefined
              }
              onClick={() => {
                modal.openModal({
                  type: "billingModal",
                  props: { source: "direct.canvasControls" },
                });
              }}
            >
              Upgrade
            </Button>
          )}
          <Button
            variant="secondary"
            size="base"
            unsafe_className="flex-shrink-0"
            onClick={() => {
              logEvent("user.help.requested", {
                billingPlan: subscriptionTier,
                from: "button",
              });
              modal.openModal({
                type: "supportModal",
              });
            }}
          >
            Get Help
          </Button>
        </>
      )}
      {isFeatureEnabled("ai-menu") && (
        <>
          <Separator orientation="vertical" className="h-6" />
          <AIMenu
            controlsWidth={controlsWidth}
            isDisabled={areControlsDisabled}
          />
        </>
      )}
    </aside>
  );
};

const DeviceControls: React.FC = () => {
  const editorMode = useEditorSelector(selectEditorMode);
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const { handleDeviceChange } = useDeviceControls();
  const isMultipleCanvasesEnabled = isFeatureEnabled("multiple-canvases");
  const isEditMode = editorMode === EditorMode.edit;

  return (
    <div
      className={classNames(
        "flex flex-row items-center",
        isMultipleCanvasesEnabled ? "gap-2" : "gap-5",
      )}
    >
      {Object.values(CANVAS_DATA).map((canvasData) => {
        const Icon = getCanvasIconComponent(canvasData.canvasName);
        return isMultipleCanvasesEnabled ? (
          <MultiCanvasDeviceControls
            key={canvasData.canvasName}
            canvasData={canvasData}
          />
        ) : (
          <LabeledIconButton
            key={canvasData.canvasName}
            icon={
              <Icon
                {...(canvasData.canvasName === "desktop" && {
                  className: "text-lg",
                })}
              />
            }
            className={classNames({
              "text-subtle": activeCanvas !== canvasData.canvasName,
            })}
            onClick={() => {
              handleDeviceChange(canvasData);
            }}
            id={`viewport-toggle-${canvasData.canvasName}`}
            data-testid={`viewport-toggle-${canvasData.canvasName}`}
          >
            {canvasData.canvasName}
          </LabeledIconButton>
        );
      })}
      {(!isMultipleCanvasesEnabled || !isEditMode) && (
        <>
          <Separator orientation="vertical" className="h-6" />
          <CustomViewportControl />
        </>
      )}
    </div>
  );
};

const MultiCanvasDeviceControls: React.FC<{
  canvasData: CanvasData;
}> = ({ canvasData }) => {
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const canvasIsLoading = useEditorSelector(selectCanvasIsLoading);
  const previewWidth = useEditorSelector(selectPreviewWidth);
  const { handleDeviceChange } = useDeviceControls();
  const Icon = getCanvasIconComponent(canvasData.canvasName);

  let isActive = activeCanvas === canvasData.canvasName;
  if (isPreviewMode) {
    const previewData = getCanvasData(previewWidth);
    isActive = previewData?.canvasName === canvasData.canvasName;
  }

  return (
    <Button
      key={canvasData.canvasName}
      id="page-settings-button"
      variant="no-style"
      tooltipText={capitalizeFirstLetter(canvasData.canvasName)}
      icon={<Icon className="text-2xl" />}
      className={classNames(
        "p-2 rounded-md hover:text-default hover:bg-slate-100",
        !canvasIsLoading && isActive ? "text-default" : "text-subtle",
      )}
      onClick={() => {
        handleDeviceChange(canvasData);
      }}
      isDisabled={canvasIsLoading}
    />
  );
};

export const CustomViewportControl: React.FC<{
  canvas?: EditorCanvas;
}> = ({ canvas }) => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const canvases = useEditorSelector(selectCanvases);
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const previewWidth = useEditorSelector(selectPreviewWidth);

  const { handleViewportChange, handlePresetDeviceChange } =
    useDeviceControls();

  const currentCanvas = canvas ?? activeCanvas;
  const currentCanvasState = canvases[currentCanvas];
  const presets = getPresets(canvas ?? undefined);
  const canvasData = getFromRecordOrNull(CANVAS_DATA, canvas);

  const isOnCanvasNavbar = Boolean(canvas);

  return (
    <InlinePopover
      triggerAsChild
      shouldPreventDefaultOnInteractOutside={false}
      onOpenChange={(isOpen) => setIsPopoverOpen(isOpen)}
      style={{ marginTop: isOnCanvasNavbar ? 8 : undefined }}
      content={
        <div className="flex flex-col gap-1">
          <h4 className="text-xs font-normal text-subtle">Set Preview Width</h4>
          <LengthInputSelector
            field="default"
            startEnhancer={() => <BsFillAspectRatioFill />}
            value={`${isPreviewMode ? previewWidth : currentCanvasState.canvasWidth}px`}
            metrics={["px"]}
            onChange={(value) => {
              handleViewportChange(value, canvas);
            }}
            draggingType={DraggingTypes.Vertical}
            draggingDirection={DraggingDirections.Positive}
            minDragValues={{ px: canvasData?.range[0] ?? 0 }}
            minValues={{ px: canvasData?.range[0] ?? 0 }}
            maxDragValues={{
              px: canvasData?.range[1] ?? Number.POSITIVE_INFINITY,
            }}
            maxValues={{ px: canvasData?.range[1] ?? Number.POSITIVE_INFINITY }}
            allowsNegativeValue={false}
            dragTrigger="startEnhancer"
          />
          <h4 className="text-xs font-normal text-subtle">By Device</h4>
          <Selectable
            placeholder="Select..."
            isDisabled={false}
            onSelect={(value) => {
              if (!value) {
                return;
              }
              handlePresetDeviceChange(value, canvas);
            }}
            defaultValue={String(
              isPreviewMode ? previewWidth : currentCanvasState.canvasWidth,
            )}
            valueIndicator={({ value }) => {
              const preset = presets.find(
                (preset) => String(preset.value) === value,
              );

              if (!preset) {
                return "Select...";
              }

              const Icon = getCanvasIconComponent(preset.canvas);
              return (
                <div className="flex w-full items-center px-2 gap-2 py-[6.5px] text-xs text-default text-normal">
                  <Icon className="text-subtle" />
                  <span className="truncate">{preset.label}</span>
                </div>
              );
            }}
            options={presets.map(({ canvas, label, value }) => ({
              label: () => {
                const Icon = getCanvasIconComponent(canvas);
                return (
                  <div className="flex w-full shrink-0 items-center p-1 text-xs text-default gap-2">
                    <Icon className="text-subtle" />
                    <span className="font-normal truncate">{label}</span>
                    <span className="text-subtle">({value}px)</span>
                  </div>
                );
              },
              value: String(value),
            }))}
          />
        </div>
      }
      title="Preview Dimensions"
      className="w-60 py-4"
      stayInPosition
      side="bottom"
      align={isOnCanvasNavbar ? "start" : "center"}
      alignOffset={isOnCanvasNavbar ? -10 : 0}
    >
      <div
        className={classNames(
          "flex h-6 cursor-pointer items-center justify-center gap-2 rounded py-1 px-2 text-sm text-slate-500",
          !isOnCanvasNavbar && "bg-slate-100 text-slate-400",
          !isOnCanvasNavbar &&
            currentCanvasState.canvasWidth !==
              CANVAS_DATA[currentCanvas].defaultFrameWidth &&
            "text-default",
        )}
      >
        {isPreviewMode ? previewWidth : currentCanvasState.canvasWidth}px
        {isOnCanvasNavbar ? (
          <BsChevronDown
            size={12}
            className={classNames("text-slate-600 transition-all", {
              "rotate-180": isPopoverOpen,
            })}
          />
        ) : (
          <BsChevronUp
            size={12}
            className={classNames("text-slate-600 transition-all", {
              "rotate-180": isPopoverOpen,
            })}
          />
        )}
      </div>
    </InlinePopover>
  );
};

function getCanvasIconComponent(canvas: EditorCanvas) {
  if (canvas === "mobile") {
    return BsFillTabletFill;
  }
  if (canvas === "tablet") {
    return IoTabletLandscape;
  }
  return RiComputerFill;
}

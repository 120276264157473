import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import Input from "@common/designSystem/Input";
import Tooltip from "@common/designSystem/Tooltip";
import { getAutoCompletedColor } from "@common/designSystem/utils/colors";
import { getFormattedColor } from "@editor/utils/colors";
import HashIcon from "@svg/hash";
import * as React from "react";
import { RiCloseFill } from "react-icons/ri";

type ColorHexSelectorProps = {
  className?: string;
  value?: string | null;
  setValue(value: string | null): void;
  onChange(color: string | null): void;
  handleColorRemove?(): void;
  isDisabled?: boolean;
  allowsSettingDynamicData?: boolean;
  onClickDynamicData?(): void;
  autofocus?: boolean;
};

const ColorHexSelector = ({
  className,
  value,
  setValue,
  onChange,
  handleColorRemove,
  isDisabled,
  allowsSettingDynamicData = false,
  onClickDynamicData,
  autofocus = false,
}: ColorHexSelectorProps) => {
  const [lastValidColor, setLastValidColor] = React.useState(value);
  const handleColorChange = () => {
    //  Note (Sebas, 2022-05-29): Check if the user enters a value
    // or if the value is the same as the last valid color to prevent
    // the border from updating if it is not needed.
    if (value !== null && value === lastValidColor) {
      return;
    }
    const completedColor = getAutoCompletedColor(value);
    if (completedColor) {
      const hexColor = getFormattedColor(completedColor);
      setLastValidColor(hexColor);
      onChange(hexColor);
    } else if (lastValidColor) {
      setValue(lastValidColor);
      onChange(lastValidColor);
    } else {
      setValue("");
    }
  };

  return (
    <div className="flex gap-2">
      <Input
        unsafe_className={className}
        value={value?.replace("#", "") ?? undefined}
        placeholder="000000"
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
        startEnhancer={() => (
          <Tooltip content={isDisabled ? null : "Set Hex Color"} triggerAsChild>
            <div tabIndex={isDisabled ? -1 : 0}>
              <HashIcon />
            </div>
          </Tooltip>
        )}
        onOptionClick={handleColorRemove}
        onBlur={handleColorChange}
        onEnter={handleColorChange}
        isDisabled={isDisabled}
        endEnhancer={() =>
          handleColorRemove &&
          value && (
            <div
              className="h-3 w-3 cursor-pointer text-slate-400"
              onClick={() => {
                setLastValidColor(null);
                handleColorRemove?.();
              }}
            >
              <RiCloseFill size={12} />
            </div>
          )
        }
        autoFocus={autofocus}
      />
      {allowsSettingDynamicData && (
        <div className="flex">
          <DynamicDataButton
            tooltipText="Add Dynamic Data"
            onClick={(e) => {
              e.stopPropagation();
              onClickDynamicData?.();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ColorHexSelector;

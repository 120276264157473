import * as React from "react";

import type { RuntimeStyleProperties } from "../styleAttribute";

interface IframeProcessingType {
  embedHtml: string;
  hideControls: boolean;
  forceMute?: boolean;
}

type ValidIframeAttribute = (typeof VALID_IFRAME_ATTRIBUTES)[number];
type ValidIframeProp =
  (typeof IFRAME_ATTRIBUTES_TO_PROPS)[ValidIframeAttribute];
type ValidatedIframeProps = Pick<
  React.ComponentProps<"iframe">,
  ValidIframeProp
>;

const VALID_IFRAME_ATTRIBUTES = [
  "src",
  "frameborder",
  "allow",
  "allowfullscreen",
] as const;
const IFRAME_ATTRIBUTES_TO_PROPS = {
  src: "src",
  frameborder: "frameBorder",
  allow: "allow",
  allowfullscreen: "allowFullScreen",
} as const satisfies {
  [key in ValidIframeAttribute]: keyof React.ComponentProps<"iframe">;
};

type IframeProcessingData = {
  iframeProps: ValidatedIframeProps;
  aspectRatio: number;
  iframeWidth: string;
  iframeHeight: string;
};

const defaultValues: IframeProcessingData = {
  iframeProps: {},
  aspectRatio: 0,
  iframeWidth: "auto",
  iframeHeight: "auto",
};

export const useAlchemyIframeProcessing = (props: IframeProcessingType) => {
  const { embedHtml, hideControls, forceMute = false } = props;

  const [values, setValues] = React.useState(defaultValues);

  React.useEffect(() => {
    if (!embedHtml) {
      return;
    }

    const parser = new DOMParser();
    const embeddedDocument = parser.parseFromString(embedHtml, "text/html");
    const iframe = embeddedDocument.querySelector("iframe");

    if (!iframe) {
      return;
    }

    const ratio =
      Number.parseInt(iframe.height) / (Number.parseInt(iframe.width) || 1);
    const iframeWidth = `${iframe.width}px`;
    const iframeHeight = `${iframe.height}px`;
    const aspectRatio = ratio;

    const iframeProps: ValidatedIframeProps = {};
    const attributes = Array.from(iframe.attributes).filter(
      (attribute): attribute is Attr & { name: ValidIframeAttribute } =>
        VALID_IFRAME_ATTRIBUTES.includes(
          attribute.name as ValidIframeAttribute,
        ),
    );
    attributes.forEach((attribute) => {
      const prop = IFRAME_ATTRIBUTES_TO_PROPS[attribute.name];
      if (prop == "allowFullScreen") {
        // Note (Chance 2023-08-02) `allowFullScreen` should be a boolean, not
        // an arbitrary string like the others.
        iframeProps.allowFullScreen = attribute.value === "true";
      } else {
        iframeProps[prop] = attribute.value;
      }
    });

    if (iframeProps.src) {
      if (hideControls) {
        iframeProps.src = urlAddingParams(iframeProps.src, {
          controls: "0",
        });
      }
      if (forceMute) {
        const muteProp = isYoutubeUrl(iframeProps.src) ? "mute" : "muted";
        iframeProps.src = urlAddingParams(iframeProps.src, {
          [muteProp]: "1",
        });
      }
    }

    setValues({
      iframeProps,
      iframeWidth,
      iframeHeight,
      aspectRatio,
    } satisfies IframeProcessingData);
  }, [embedHtml, hideControls, forceMute]);

  return values;
};

interface EmbedDimensionsType {
  componentWidth: RuntimeStyleProperties["width"];
  componentHeight: RuntimeStyleProperties["height"];
  wrapperWidth: number;
  iframeWidth: string;
  iframeHeight: string;
  aspectRatio: number;
  embedAlignment: RuntimeStyleProperties["alignSelf"];
  embedParent: HTMLElement;
  initialParentHeight: number;
}

const getNumber = (value: string): number => {
  if (!value) {
    return 0;
  }
  const match = value.match(/(\d)/g);
  if (!match) {
    return 0;
  }
  return Number.parseInt(match.join(""));
};

export function resolveComponentDimensions(props: EmbedDimensionsType) {
  let { componentWidth, componentHeight } = props;
  const {
    wrapperWidth,
    iframeWidth,
    embedAlignment,
    iframeHeight,
    aspectRatio,
    embedParent,
    initialParentHeight,
  } = props;

  const {
    flexDirection: embedParentDirection,
    paddingBottom,
    paddingTop,
  } = embedParent?.style || {};

  if (componentWidth === "auto") {
    componentWidth = iframeWidth;
  }
  if (componentHeight === "auto") {
    componentHeight = iframeHeight;
  }
  if (embedAlignment === "stretch") {
    if (embedParentDirection === "column") {
      componentWidth = "100%";
    } else {
      componentHeight = `${
        initialParentHeight - getNumber(paddingTop) - getNumber(paddingBottom)
      }px`;
    }
  }

  let width = componentWidth || iframeWidth;
  if (!componentWidth && componentHeight) {
    width = `calc(${componentHeight} / ${aspectRatio})`;
  }
  const height = componentHeight || `${wrapperWidth * aspectRatio}px`;

  return { width, height };
}

function isYoutubeUrl(url: string) {
  try {
    return ["youtube.com", "youtu.be"].includes(new URL(url).host);
  } catch {
    return false;
  }
}

/**
 * Given a URL (as string) and params to add, return the a new URL string with
 * params set. This takes care of generating the query string, parsing the
 * original URL to avoid duplicated params, etc.
 *
 * @example
 * urlAddingParams("https://google.com?a=1", { c: 3 }) === "https://google.com?a=1&c=3"
 */
function urlAddingParams(
  urlString: string,
  paramsToAdd: Record<string, string>,
) {
  try {
    const url = new URL(urlString);
    const params = new URLSearchParams(url.search);
    for (const [key, value] of Object.entries(paramsToAdd)) {
      params.set(key, value);
    }
    url.search = params.toString();
    return url.toString();
  } catch {
    // NOTE (Chance 2023-08-02) This function may throw if the URL is invalid.
    // In that case we can just return the original value provided since an
    // invalid URL can't have params anyway.
    return urlString;
  }
}

import type { ComponentTemplate } from "@editor/types/component-template";
import { getCanBeAddedFunction } from "@editor/utils/template";
import { Env } from "replo-runtime/shared/enums";
import type { ProductRef } from "replo-runtime/shared/types";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { getProduct } from "replo-runtime/store/ReploProduct";
import { v4 as uuidv4 } from "uuid";

export const addToCartButton: ComponentTemplate = {
  id: "7aba1703-e35d-4861-b2f5-65fe96b11fbb",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Add To Cart Button",
  leftBarCategory: { main: "product", subCategory: "buttons" },
  tags: ["product", "product add to cart button"],
  preview: "product/add-to-cart.svg",
  transforms: [
    (component, parent, _, extras) => {
      if (!parent) {
        return component;
      }
      const context = getCurrentComponentContext(parent.id, 0);
      const productRef: ProductRef =
        component.props["_product"] || context?.attributes?.["_product"];
      const product = getProduct(productRef, context ?? null, {
        productMetafieldValues: {},
        variantMetafieldValues: {},
        products: extras.productResolutionDependencies.products,
        currencyCode: extras.productResolutionDependencies.currencyCode,
        moneyFormat: extras.productResolutionDependencies.moneyFormat,
        language: extras.productResolutionDependencies.language,
        templateProduct: extras.productResolutionDependencies.templateProduct,
      });
      if (context?.attributes?.["_product"]) {
        const productHasSellingPlans =
          product?.sellingPlanGroups && product.sellingPlanGroups.length > 0;
        component.props.onClick = [
          {
            id: uuidv4(),
            type: "addProductVariantToCart",
            value: {
              redirectToCart: true,
              redirectToCheckout: false,
              product: {
                type: "contextRef",
                ref: "attributes._variant",
              },
              sellingPlanId: productHasSellingPlans
                ? "{{attributes._selectedSellingPlan}}"
                : null,
            },
          },
        ];
      }
      return component;
    },
  ],
  template: {
    id: "ddf4b769-ddf4-4251-aa7f-05a6f3de7df8",
    type: "button",
    props: {
      style: {
        color: "#FFFFFF",
        fontSize: "20px",
        fontWeight: "700",
        lineHeight: "20px",
        paddingTop: "20px",
        paddingLeft: "32px",
        paddingRight: "32px",
        letterSpacing: "2px",
        paddingBottom: "20px",
        backgroundColor: "#000000FF",
      },
    },
    children: [
      {
        id: "423a5ce0-0ee1-4ce9-9452-ff3a65908fb7",
        type: "text",
        props: {
          text: "<p>Add To Cart</p>",
          style: {
            letterSpacing: "2px",
            color: "#FFFFFF",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "20px",
          },
        },
      },
    ],
  },
  canBeAddedAsChild: getCanBeAddedFunction(
    "product",
    "Product Add To Cart Buttons can only be added inside Product boxes.",
  ),
};

import * as React from "react";

type MutationObserverConfig = MutationObserverInit &
  ({ attributes: true } | { childList: true } | { characterData: true });

/**
 *
 * @param target The target DOM node to observe
 * @param callback The callback to call when a mutation occurs.
 *
 * NOTE (Chance 2024-01-19) This callback is no longer memoized by default.
 * Consumers should decide on memoization and dependencies needed on a
 * case-by-case basis. To avoid calling, disconnecting and reconnecting on every
 * render, use `useCallback` to memoize the callback or `useEffectEvent` to save
 * a reference to the callback without re-triggering the effect each render
 * cycle.
 * @param options Allows for setting mutation observation options via object
 * members.
 *
 * NOTE (Chance 2024-01-19): From MDN: "At a minimum, one of `childList`,
 * `attributes`, and/or `characterData` must be `true` when you call
 * `observe()`"
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe#options
 */
export function useMutationObserver<T extends Node>(
  target: T | null | undefined,
  callback: (mutations: MutationRecord[]) => void,
  options: MutationObserverConfig,
) {
  const { attributes, childList, subtree } = options;
  React.useEffect(() => {
    const config = { attributes, childList, subtree };
    if (target) {
      const observer = new MutationObserver(callback);
      observer.observe(target, config);
      callback([]);
      return () => {
        observer.disconnect();
      };
    }
  }, [target, attributes, childList, subtree, callback]);
}

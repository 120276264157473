import type * as CSS from "csstype";
import type {
  GradientStop,
  StringableRecord,
} from "replo-runtime/shared/types";
import type { Nullable } from "replo-utils/lib/types";

export type BorderSide = "Top" | "Right" | "Bottom" | "Left";
export type BorderSuffix = "" | BorderSide;

export type DimensionStyleProperties = Nullable<{
  __hasWidth: boolean;
  __hasHeight: boolean;
  __parentHasWidth: boolean;
  __parentHasHeight: boolean;
  __parentFlexDirection: string | undefined;
  __parentDisplay: string | undefined;
  __parentJustifyContent: string | undefined;
  __parentAlignItems: string | undefined;
}>;

export type ReploStyleProperties = Nullable<
  {
    __alchemyGradient__backgroundColor__stops: GradientStop[] | undefined;
    __alchemyGradient__backgroundColor__tilt: string | number;
    __alchemyGradient__color__stops: GradientStop[] | number;
    __alchemyGradient__color__tilt: string | number;
    __alchemyRotation: string | number;
    __animateVariantTransitions: boolean;
    __animation: {
      "--offset"?: string;
      "--final-opacity"?: string;
      "--initial-opacity"?: string;
      "--initial-scale"?: string;
      "--final-scale"?: string;
      "--initial-angle"?: string;
      "--final-angle"?: string;
      "--angle"?: string;
      animationName?: string;
      animationDelay?: string;
      animationDuration?: string;
      animationFillMode?: "none" | "forwards" | "backwards" | "both";
      animationDirection?:
        | "normal"
        | "reverse"
        | "alternate"
        | "alternate-reverse";
      animationTimingFunction?: string;
      transformStyle?: string;
    } | null;
    __display: string | number;
    __flexGap: string | number;
    __height: string | number;
    __iconAltText: string;
    __imageAltText: string;
    __imageSource: string;
    __numberOfColumns: string | number;
    __overflow: CSS.Properties["overflow"];
    __textStroke: string;
    __transform: any;
    __width: string | number;
    __zoom: string | number;
    "--rmw": string | number;
    "--rmh": string | number;
    __flexShrink: string | number;
  } & DimensionStyleProperties
>;

export type SupportedCssProperties = Pick<
  CSS.Properties<string | number>,
  | "alignItems"
  | "alignSelf"
  | "animationDelay"
  | "animationDirection"
  | "animationDuration"
  | "animationFillMode"
  | "animationName"
  | "animationTimingFunction"
  | "appearance"
  | "backgroundColor"
  | "backgroundImage"
  | "backgroundPositionX"
  | "backgroundPositionY"
  | "backgroundRepeat"
  | "backgroundSize"
  | "borderBottomLeftRadius"
  | "borderBottomRightRadius"
  | "borderTopLeftRadius"
  | "borderTopRightRadius"
  | "bottom"
  | "boxShadow"
  | "color"
  | "columnGap"
  | "cursor"
  | "display"
  | "fill"
  | "flexBasis"
  | "flexDirection"
  | "flexGrow"
  | "flexShrink"
  | "flexWrap"
  | "fontFamily"
  | "fontSize"
  | "fontStyle"
  | "fontWeight"
  | "gap"
  | "gridColumnEnd"
  | "gridRowEnd"
  | "gridTemplateColumns"
  | "height"
  | "inset"
  | "justifyContent"
  | "left"
  | "letterSpacing"
  | "lineHeight"
  | "margin"
  | "marginBottom"
  | "marginLeft"
  | "marginRight"
  | "marginTop"
  | "maxHeight"
  | "maxWidth"
  | "minHeight"
  | "minWidth"
  | "objectFit"
  | "objectPosition"
  | "opacity"
  | "overflow"
  | "overflowY"
  | "overflowX"
  | "padding"
  | "paddingBottom"
  | "paddingLeft"
  | "paddingRight"
  | "paddingTop"
  | "position"
  | "right"
  | "rowGap"
  | "textAlign"
  | "textAlignLast"
  | "textDecoration"
  | "textTransform"
  | "textShadow"
  | "top"
  | "transform"
  | "transformOrigin"
  | "transition"
  | "width"
  | "zIndex"
  | `border${BorderSide}Color`
  | `border${BorderSide}Style`
  | `border${BorderSide}Width`
  | "borderColor"
  | "borderStyle"
  | "borderWidth"
>;

const dimensionPropertiesDefaults: DimensionStyleProperties = {
  __hasWidth: null,
  __hasHeight: null,
  __parentHasWidth: null,
  __parentHasHeight: null,
  __parentFlexDirection: null,
  __parentDisplay: null,
  __parentJustifyContent: null,
  __parentAlignItems: null,
};

export const styleAttributeToDefaultStyle = {
  ...dimensionPropertiesDefaults,
  __alchemyGradient__backgroundColor__stops: null,
  __alchemyGradient__backgroundColor__tilt: null,
  __alchemyGradient__color__stops: null,
  __alchemyGradient__color__tilt: null,
  __alchemyRotation: null,
  __animateVariantTransitions: null,
  __display: "inline",
  __flexGap: "0px",
  __height: null,
  __iconAltText: null,
  __imageAltText: null,
  __imageSource: null,
  __numberOfColumns: 1,
  __overflow: null,
  __textStroke: "0 currentcolor",
  __transform: "",
  __width: null,
  __zoom: "100%",
  "--rmw": null,
  "--rmh": null,
  appearance: "auto",
  alignItems: "start",
  alignSelf: "auto",
  animationDelay: "0ms",
  animationDuration: "0ms",
  backgroundColor: "transparent",
  backgroundImage: "none",
  backgroundPositionX: "0%",
  backgroundPositionY: "0%",
  backgroundRepeat: "repeat",
  backgroundSize: "auto",
  borderBottomColor: "currentcolor",
  borderBottomLeftRadius: "0px",
  borderBottomRightRadius: "0px",
  borderBottomStyle: "none",
  borderBottomWidth: "0px",
  borderColor: "currentcolor",
  borderLeftColor: "currentcolor",
  borderLeftStyle: "none",
  borderLeftWidth: "0px",
  borderRightColor: "currentcolor",
  borderRightStyle: "none",
  borderRightWidth: "0px",
  borderStyle: "none",
  borderTopColor: "currentcolor",
  borderTopLeftRadius: "0px",
  borderTopRightRadius: "0px",
  borderTopStyle: "none",
  borderTopWidth: "0px",
  borderWidth: "0px",
  bottom: "auto",
  boxShadow: "inherit",
  color: "canvastext",
  columnGap: "1px",
  cursor: "auto",
  display: "inline",
  fill: "currentcolor",
  flexBasis: "auto",
  flexDirection: "row",
  flexGrow: "0",
  flexShrink: 1,
  flexWrap: "nowrap",
  fontFamily: "inherit",
  fontSize: "medium",
  fontStyle: "normal",
  fontWeight: "normal",
  gridColumnEnd: "auto",
  gridRowEnd: "auto",
  gridTemplateColumns: "none",
  height: "auto",
  inset: "auto",
  justifyContent: "start",
  left: "auto",
  letterSpacing: "",
  lineHeight: "normal",
  margin: "0px",
  marginBottom: "0px",
  marginLeft: "0px",
  marginRight: "0px",
  marginTop: "0px",
  maxHeight: "none",
  maxWidth: "none",
  minHeight: "auto",
  minWidth: "auto",
  objectFit: "none",
  objectPosition: "50% 50%",
  opacity: "100%",
  overflow: "visible",
  padding: "0px",
  paddingBottom: "0px",
  paddingLeft: "0px",
  paddingRight: "0px",
  paddingTop: "0px",
  position: "static",
  right: "auto",
  rowGap: "1px",
  textAlign: "start",
  textDecoration: "none",
  textShadow: "none",
  textTransform: "none",
  top: "auto",
  transform: "none",
  transformOrigin: "50% 50% 0%",
  transition: "none",
  width: "auto",
  zIndex: "0",
} satisfies Partial<ReploStyleProperties & SupportedCssProperties>;

export type RuntimeStyleAttribute =
  | keyof ReploStyleProperties
  | keyof SupportedCssProperties;

// TODO (Gabe 2023-06-14): The Stringable type is so that we can use the string
// values passed to React onChange handlers to setStyles. We should update these
// handlers to use more specific types.
export type RuntimeStyleProperties = Partial<ReploStyleProperties> &
  StringableRecord<Nullable<SupportedCssProperties>>;

import * as React from "react";
import type { RectReadOnly } from "react-use-measure";
import useMeasure from "react-use-measure";
import {
  RenderEnvironmentContext,
  ReploEditorActiveCanvasContext,
  RuntimeHooksContext,
  useRuntimeContext,
} from "replo-runtime/shared/runtime-context";
import {
  mediaQueries,
  mediaSizes,
} from "replo-runtime/shared/utils/breakpoints";
import type { GlobalWindow } from "replo-runtime/shared/Window";
import { getOwnerWindowSafe } from "replo-runtime/shared/Window";
import {
  resetAnimationAndSetRunningState,
  slidingAnimations,
} from "replo-runtime/store/utils/animations";

import type { Animation } from "../../shared/types";

interface ComponentAnimationWrapperProps {
  children: (
    measureRef: React.RefCallback<HTMLOrSVGElement>,
  ) => React.ReactNode;
  animations: Animation[];
  animationRef: React.RefObject<HTMLDivElement>;
  componentRef: React.RefObject<HTMLElement | null>;
}

export type RenderAnimationWrapper = (
  rect: Partial<RectReadOnly>,
) => React.ReactNode;

const ComponentAnimationWrapper = ({
  children,
  animations,
  animationRef,
  componentRef,
}: ComponentAnimationWrapperProps) => {
  const [measureRef, rect] = useMeasure({
    debounce: 200,
    offsetSize: true,
  });

  useComponentAnimationRuntime(componentRef, animations, animationRef);

  const slidingAnimation = animations?.find((a) =>
    slidingAnimations.includes(a.type),
  );

  return (
    <>
      {children(measureRef)}
      {slidingAnimation && (
        <div
          ref={animationRef}
          style={{
            top: 0,
            left: 0,
            width: rect.width,
            height: rect.height,
            position: "absolute",
            pointerEvents: "none",
            // Note (Noah, 2022-06-14, REPL-1871): Always set this to display:
            // block because on some themes, including Dawn, divs that are empty
            // (`div:empty` in CSS) are set to display: none. This could cause the
            // animation to not render properly, because this is the div we pass
            // to the IntersectionObserver to figure out when to play the
            // animation.
            display: "block",
          }}
        />
      )}
    </>
  );
};

/**
 * This function returns the mediaSize by running matchMedia but doesn't cause a react state update
 * when it changes, it waits for a re-render to get the new value, should be useful for some
 * feature which works on first reload or something like animation
 */
function getCurrentTickMediaSize(targetWindow: GlobalWindow | null) {
  for (const mediaSize of mediaSizes) {
    if (targetWindow?.matchMedia(mediaQueries[mediaSize])?.matches) {
      return mediaSize;
    }
  }
  return "lg";
}

const useComponentAnimationRuntime = (
  componentRef: React.RefObject<HTMLElement | null>,
  animations: Animation[],
  animationRef: React.RefObject<HTMLDivElement>,
) => {
  const animationsThatAlreadyRan = React.useRef<string[]>([]);
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const { activeCanvas } = useRuntimeContext(ReploEditorActiveCanvasContext);

  React.useEffect(() => {
    // Note (Noah, 2024-07-24): If we're in the editor, we want to make sure
    // that we always restart the animation. However, in the published page
    // we don't want to restart the animation because it probably already started.
    // If there's a trigger to start the animation again, like the component entering
    // the viewport, that's handled by the intersection observer below.
    if (!isEditorApp) {
      return;
    }
    if (componentRef.current && animations.length > 0) {
      const window = getOwnerWindowSafe(componentRef.current);
      const mediaSize = getCurrentTickMediaSize(window);
      animations.forEach((animation) => {
        if (
          !animation.runOnlyOnce ||
          !animationsThatAlreadyRan.current.includes(animation.id)
        ) {
          const includeMediaSize = mediaSize
            ? animation.devices?.includes(mediaSize)
            : false;

          const shouldRun =
            includeMediaSize &&
            // Note (Noah, 2022-02-14): Run only in preview mode, or on a live page,
            // or if we're publishing (so pages get hydrated correctly)
            !isEditorCanvas;
          const animationName = animation.value?.styles?.animationName;
          const currentNode = componentRef.current as HTMLElement;

          resetAnimationAndSetRunningState(
            currentNode,
            shouldRun && animationName ? animationName : "none",
            "running",
          );
        }
      });
    }
  }, [componentRef, animations, isEditorCanvas, isEditorApp]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: activeCanvas extra dep
  React.useEffect(
    function handleViewportEnterAnimations() {
      const componentElement = componentRef.current;
      if (
        !componentElement ||
        !animations.some((a) => a.trigger.type === "onViewportEnter")
      ) {
        return;
      }

      const onAnimationEnd = () => {
        componentElement.style.animationPlayState = "paused";
      };

      const observer = new IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            if (entry.isIntersecting) {
              // TODO (Reinaldo, 2022-01-27): Make sure to get all animations here
              const animation = animations.find(
                (a) => a?.trigger?.type === "onViewportEnter",
              );

              if (!animation) {
                continue;
              }

              const alreadyRan = animationsThatAlreadyRan.current.includes(
                animation.id,
              );

              if (
                (animation.runOnlyOnce && !alreadyRan) ||
                !animation.runOnlyOnce
              ) {
                if (!animation.runOnlyOnce) {
                  componentElement.addEventListener(
                    "animationend",
                    onAnimationEnd,
                  );
                }

                resetAnimationAndSetRunningState(
                  componentElement,
                  componentElement.style.animationName,
                  "running",
                );

                animationsThatAlreadyRan.current.push(animation.id);
              }
            }
          }
        },
        { rootMargin: "0px" },
      );

      const animationElement = animationRef.current ?? componentElement;
      observer.observe(animationElement);
      return () => {
        observer.disconnect();
        componentElement.removeEventListener("animationend", onAnimationEnd);
      };
    },
    [animations, componentRef, activeCanvas, animationRef],
  );
};

export default ComponentAnimationWrapper;

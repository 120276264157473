import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const h1: ComponentTemplate = {
  id: "85f6a63a-e57b-424b-919f-4f633dfb3045",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Basic/h1",
  leftBarCategory: { main: "basic", subCategory: "text" },
  preview: "basic/h1.svg",
  transforms: [],
  tags: ["heading", "text", "title"],
  template: {
    id: "$uid",
    type: "text",
    props: {
      text: "<h1>Add new heading here</h1>",
      style: {
        fontSize: "40px",
        lineHeight: "44px",
      },
    },
  },
};

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const marquee: ComponentTemplate = {
  id: "dbd865a6-4325-4364-9ce2-320c93e22cb3",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Component/Ticker",
  leftBarCategory: { main: "interactive", subCategory: "animations" },
  preview: "component/marquee.svg",
  template: {
    id: "74586d30-629e-4aab-8459-efb2000d8901",
    type: "marquee",
    props: {
      style: {
        width: "100%",
        __width: "100%",
        display: "flex",
        paddingTop: "12px",
        paddingBottom: "12px",
      },
      _seconds: "20s",
      _internalUseRepetition: false,
    },
    children: [
      {
        id: "648e352c-34f9-49dc-8f85-712013a96acd",
        type: "container",
        props: {
          style: {
            display: "flex",
            paddingRight: "12px",
            __flexGap: "12px",
            paddingLeft: "12px",
          },
        },
        children: [
          {
            id: "46fc7539-2124-4fe7-9b9b-c60249244cc5",
            type: "text",
            props: {
              text: "<p>Repeat + Scroll #1</p>",
              style: {
                color: "black",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "24px",
              },
            },
          },
          {
            id: "497805e4-e5ca-4bdd-9628-54b1b04dad26",
            type: "text",
            props: {
              text: "<p>Repeat + Scroll #2</p>",
              style: {
                color: "black",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "24px",
              },
            },
          },
          {
            id: "08daba5c-b806-43ac-bb80-3e12c57782ce",
            type: "text",
            props: {
              text: "<p>Repeat + Scroll #3</p>",
              style: {
                color: "black",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "24px",
              },
            },
          },
          {
            id: "713ee575-7a80-45d5-8c39-b23719a7eef2",
            type: "text",
            props: {
              text: "<p>Repeat + Scroll #4</p>",
              style: {
                color: "black",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "24px",
              },
            },
          },
          {
            id: "ecfa61e7-7f93-40da-8c66-b246af6200b8",
            type: "text",
            props: {
              text: "<p>Repeat + Scroll #5</p>",
              style: {
                color: "black",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "24px",
              },
            },
          },
          {
            id: "ecfa61e7-7f93-40da-8c66-b246af6200b8",
            type: "text",
            props: {
              text: "<p>Repeat + Scroll #6</p>",
              style: {
                color: "black",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "24px",
              },
            },
          },
          {
            id: "ecfa61e7-7f93-40da-8c66-b246af6200b8",
            type: "text",
            props: {
              text: "<p>Repeat + Scroll #7</p>",
              style: {
                color: "black",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "24px",
              },
            },
          },
          {
            id: "ecfa61e7-7f93-40da-8c66-b246af6200b8",
            type: "text",
            props: {
              text: "<p>Repeat + Scroll #8</p>",
              style: {
                color: "black",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "24px",
              },
            },
          },
        ],
        name: "Container 2085",
      },
    ],
    name: "Ticker 1",
  },
};

export const getWrapWithTickerTemplate = (): ComponentTemplate => {
  const marqueeTemplate = marquee.template!;
  return {
    ...marquee,
    template: {
      ...marqueeTemplate,
      // Note (Evan, 2024-08-12): Remove the default padding when turning into
      // a ticker
      props: {
        ...marqueeTemplate.props,
        style: {
          ...marqueeTemplate.props.style,
          paddingTop: undefined,
          paddingBottom: undefined,
        },
      },
      children: [],
    },
  };
};

import { setStorageItem } from "@editor/hooks/useLocalStorage";
import type { ElementGenericMetafield } from "replo-runtime/shared/types";

const getValue = (rawValue: string) => {
  if (!rawValue) {
    return "";
  }
  if (rawValue?.endsWith("inherit")) {
    return "";
  }
  if (rawValue?.endsWith("%")) {
    return rawValue?.slice(0, -1);
  }
  if (rawValue?.endsWith("rem")) {
    return rawValue?.slice(0, -3);
  }
  return rawValue?.slice(0, -2);
};
const getMetric = (rawValue: string) => {
  if (!rawValue) {
    return "";
  }
  if (rawValue?.endsWith("inherit")) {
    return "inherit";
  }

  if (rawValue?.endsWith("%")) {
    return "%";
  }

  if (rawValue?.endsWith("rem")) {
    return "rem";
  }

  return rawValue?.slice(-2);
};

const debounce = (func: Function, timeout = 300) => {
  let timer: any;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

const reploLibraryRedirectionKey = "replo-library-redirection";

export const getLibraryTemplateData = (storage: Storage) => {
  const objectString = storage.getItem(reploLibraryRedirectionKey);
  let parsedJson = null;
  try {
    if (objectString) {
      parsedJson = JSON.parse(objectString);
    }
    const timestamp = parsedJson.expiredAt as number;
    const currentDateTime = Date.now();
    const timeStampDate = new Date(timestamp);
    if (currentDateTime.valueOf() > timeStampDate.valueOf()) {
      parsedJson = null;
    }
  } catch {}
  return parsedJson as {
    templateId: string;
    libraryItemSlug?: string;
    libraryItemName?: string;
    kind?: string;
    timestamp: number;
  } | null;
};

export const getTemplateSnapshotImageURL = (slug: string) => {
  return `https://andytown-public.s3.us-west-1.amazonaws.com/library/v2/preview/${slug}%3Fwidth%3D1400.jpg`;
};

export const setLibraryTemplateDataToLocalStorage = ({
  libraryItemSlug,
  templateId,
  libraryItemName,
  type,
}: {
  libraryItemSlug?: string;
  templateId?: string;
  libraryItemName?: string;
  type?: string;
}) => {
  setStorageItem(
    reploLibraryRedirectionKey,
    JSON.stringify({
      libraryItemSlug,
      templateId,
      libraryItemName,
      type,
      // current time + 10 minutes
      expiredAt: Date.now() + 10 * 60 * 1000,
    }),
  );
};

export const clearTemplateLibraryRedirection = (storage: Storage) => {
  storage.removeItem(reploLibraryRedirectionKey);
};

export const cloneMetafieldsRemovingIds = (
  shopifyMetafields: ElementGenericMetafield[] | undefined,
) => {
  return (
    shopifyMetafields?.map((metafield) => ({
      key: metafield.key,
      value: metafield.value,
      namespace: metafield.namespace,
      type: metafield.type,
    })) ?? []
  );
};

export { getValue, getMetric, debounce };

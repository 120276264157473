import {
  selectDraftElementId,
  setElementRevisions,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";
import { skipToken } from "@tanstack/react-query";
import * as React from "react";
import type { ReploElementVersionRevision } from "schemas/element";

import { useOnScreen } from "./useOnScreen";

/**
 * Hook to fetch element revisions
 */
export default function useElementVersioning() {
  const dispatch = useEditorDispatch();
  const draftElementId = useEditorSelector(selectDraftElementId);
  const { data, status, fetchNextPage, isFetchingNextPage, hasNextPage } =
    trpc.element.findRevisions.useInfiniteQuery(
      draftElementId
        ? {
            elementId: draftElementId,
            limit: 25,
          }
        : skipToken,
      {
        initialCursor: 0,
        getNextPageParam: (lastPage) => lastPage.nextCursor,
      },
    );

  const revisions = React.useMemo(() => {
    return data?.pages.map((group) => group.elementRevisions).flat();
  }, [data]);

  const loadMoreRef = useOnScreen(() => {
    if (hasNextPage && !isFetchingNextPage) {
      void fetchNextPage();
    }
  });

  React.useEffect(() => {
    if (revisions && draftElementId) {
      dispatch(
        setElementRevisions({
          [draftElementId]: revisions as ReploElementVersionRevision[],
        }),
      );
    }
  }, [revisions, draftElementId, dispatch]);

  return {
    versions: revisions ?? [],
    isLoading: status === "pending",
    isFetchingNextPage,
    loadMoreRef,
  };
}

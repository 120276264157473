import Tooltip from "@common/designSystem/Tooltip";
import classNames from "classnames";
import * as React from "react";
import { twMerge } from "tailwind-merge";

type TextTabProps = {
  options: {
    label: string;
    value: string;
    isVisible?: boolean;
    isDisabled?: boolean;
    tooltipContent?: string;
  }[];
  selectedValue?: string;
  onChange?(value: string): void;
  className?: string;
  containerClassName?: string;
};

export const TextTab: React.FC<TextTabProps> = ({
  options,
  selectedValue,
  onChange,
  className,
  containerClassName,
}) => {
  const containerRef = React.useRef<HTMLUListElement>(null);
  const selectedIndex = options.findIndex(
    (option) => option.value === selectedValue,
  );
  React.useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const selectedItem = container.children[selectedIndex];
    if (!selectedItem) {
      return;
    }
    // NOTE (Evan, 7/25/23) We only want to scroll if the new element is
    // out of view (to avoid unnecessary motion)
    const { left: containerLeft, width: containerWidth } =
      container.getBoundingClientRect();
    const { left: itemLeft, width: itemWidth } =
      selectedItem.getBoundingClientRect();
    if (
      itemLeft < containerLeft ||
      itemLeft + itemWidth > containerLeft + containerWidth
    ) {
      selectedItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [selectedIndex]);

  return (
    <ul
      className={twMerge(
        "flex gap-3 overflow-x-auto no-scrollbar",
        containerClassName,
      )}
      ref={containerRef}
    >
      {options?.map(
        (
          {
            label,
            value,
            isVisible = true,
            isDisabled = false,
            tooltipContent,
          },
          index,
        ) => {
          const Wrapper = tooltipContent ? Tooltip : React.Fragment;
          const wrapperProps = (
            tooltipContent
              ? { content: tooltipContent, triggerAsChild: true }
              : {}
          ) as any;
          return (
            isVisible && (
              <Wrapper {...wrapperProps} key={index}>
                <li
                  key={value}
                  id={`text-tab-${value}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isDisabled) {
                      onChange?.(value);
                    }
                  }}
                  role="button"
                  className={classNames(
                    twMerge(
                      "cursor-pointer text-xs transition shrink-0",
                      className,
                    ),
                    value === selectedValue
                      ? "font-bold text-black"
                      : "font-normal text-slate-400 hover:text-slate-600",
                    isDisabled && "opacity-50",
                  )}
                >
                  {label}
                </li>
              </Wrapper>
            )
          );
        },
      )}
    </ul>
  );
};

import {
  setEditorReadableState,
  setSharedState,
} from "@editor/reducers/paint-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import * as React from "react";
import type { SharedStatePayload } from "replo-runtime/shared/types";

/**
 * Hook for generating paint shared state. This bridges state between
 * the editor and the runtime. It should be used by runtime components
 * anytime they are in need of internal state that can persist
 * in between mounts. This is particularly necessary for us because
 * anytime a component is reparented (like when moving it on the editor),
 * React will remount no matter what.
 */
export default function usePaintSharedState() {
  const sharedState = useEditorSelector((state) => state.paint.sharedState);
  const dispatch = useEditorDispatch();

  return {
    sharedState,
    setSharedState: React.useCallback(
      (setSharedStatePayload: SharedStatePayload) =>
        dispatch(setSharedState(setSharedStatePayload)),
      [dispatch],
    ),
  };
}

export function useEditorReadableState() {
  const editorReadableState = useEditorSelector(
    (state) => state.paint.editorReadableState,
  );
  const dispatch = useEditorDispatch();

  return {
    editorReadableState,
    setEditorReadableState: React.useCallback(
      (setSharedStatePayload: SharedStatePayload) =>
        dispatch(setEditorReadableState(setSharedStatePayload)),
      [dispatch],
    ),
  };
}

export function useEditorReadableStateValue(key: string) {
  const { editorReadableState, setEditorReadableState } =
    useEditorReadableState();

  return [
    editorReadableState[key] as unknown,
    React.useCallback(
      (value: unknown) => setEditorReadableState({ key, value }),
      [key, setEditorReadableState],
    ),
  ] as const;
}

export function usePaintSharedStateValue(key: string) {
  const { sharedState, setSharedState } = usePaintSharedState();

  return [
    sharedState[key] as unknown,
    React.useCallback(
      (value: unknown) => setSharedState({ key, value }),
      [key, setSharedState],
    ),
  ] as const;
}

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const tabsV2Top: ComponentTemplate = {
  id: "0942601f-ee4c-4d6d-834f-717c30f4107e",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Component/Tabs (Top)",
  leftBarCategory: { main: "interactive", subCategory: "toggles" },
  preview: "component/tabs-top.svg",
  template: {
    id: "e3d65dd4-02e7-4658-aa71-f4cb7911aaec",
    name: "Tabs Block 1",
    type: "tabsV2__block",
    props: {
      style: {
        display: "flex",
        alignSelf: "stretch",
        paddingTop: "12px",
        paddingLeft: "12px",
        paddingRight: "12px",
        flexDirection: "column",
        paddingBottom: "12px",
      },
    },
    children: [
      {
        id: "07966d26-fe89-4683-a3cd-feeaff1b6aa6",
        type: "tabsV2__list",
        props: {
          style: {
            display: "flex",
            alignSelf: "stretch",
          },
        },
        children: [
          {
            id: "7673c0a6-0db0-4c79-907b-4f127c79b62c",
            name: "Container Tab 1",
            type: "container",
            props: {
              style: {
                display: "flex",
                flexGrow: 1,
                __flexGap: "12px",
                paddingTop: "12px",
                paddingLeft: "40px",
                paddingRight: "40px",
                paddingBottom: "12px",
                backgroundColor: "rgb(252, 252, 252)",
                __animateVariantTransitions: false,
              },
            },
            children: [
              {
                id: "bea47c8c-c5f2-4a00-b85d-04f2e2974194",
                type: "text",
                props: {
                  text: "<p>Tab 1</p>",
                  style: {
                    flexGrow: 1,
                    fontSize: "20px",
                    alignSelf: "center",
                    lineHeight: "24px",
                  },
                },
              },
            ],
            variants: [
              {
                id: "00c6a819-d9cd-4a07-87cf-08f211a61975",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "2ca8d3cd-b02c-4f00-9943-a26a23f733f3",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "1271e6a0-8076-4240-bd01-ed5e356da502",
                      field: "state.tabsV2Block.isCurrentTab",
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "2ca8d3cd-b02c-4f00-9943-a26a23f733f3": {
                componentOverrides: {
                  "7673c0a6-0db0-4c79-907b-4f127c79b62c": {
                    props: {
                      style: {
                        backgroundColor: "rgb(239, 239, 239)",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "84049e18-cfff-4608-92f0-a7d87dbd9e81",
            name: "Container Tab 2",
            type: "container",
            props: {
              style: {
                display: "flex",
                flexGrow: 1,
                __flexGap: "12px",
                paddingTop: "12px",
                paddingLeft: "40px",
                paddingRight: "40px",
                paddingBottom: "12px",
                backgroundColor: "rgb(252, 252, 252)",
                __animateVariantTransitions: false,
              },
            },
            children: [
              {
                id: "bc9c0b5d-2e3f-45f4-ab83-551e012a294f",
                type: "text",
                props: {
                  text: "<p>Tab 2</p>",
                  style: {
                    flexGrow: 1,
                    fontSize: "20px",
                    alignSelf: "center",
                    lineHeight: "24px",
                  },
                },
              },
            ],
            variants: [
              {
                id: "aec2d97a-35f0-4c0f-9f59-7be5d2faea21",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "d951252b-18f3-4544-8721-6e9cdd814514",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "1271e6a0-8076-4240-bd01-ed5e356da502",
                      field: "state.tabsV2Block.isCurrentTab",
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "d951252b-18f3-4544-8721-6e9cdd814514": {
                componentOverrides: {
                  "84049e18-cfff-4608-92f0-a7d87dbd9e81": {
                    props: {
                      style: {
                        backgroundColor: "rgb(239, 239, 239)",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "8282307e-54cf-4b79-9306-1a827676296e",
            name: "Container Tab 3",
            type: "container",
            props: {
              style: {
                display: "flex",
                flexGrow: 1,
                __flexGap: "12px",
                paddingTop: "12px",
                paddingLeft: "40px",
                paddingRight: "40px",
                paddingBottom: "12px",
                backgroundColor: "rgb(252, 252, 252)",
                __animateVariantTransitions: false,
              },
            },
            children: [
              {
                id: "35577af8-e554-4276-b55a-92607e5b47d0",
                type: "text",
                props: {
                  text: "<p>Tab 3</p>",
                  style: {
                    flexGrow: 1,
                    fontSize: "20px",
                    alignSelf: "center",
                    lineHeight: "24px",
                  },
                },
              },
            ],
            variants: [
              {
                id: "00b604dc-4247-4ce8-b4db-550188107b95",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "130a2009-794e-4c5e-9144-d36c3f4cb3fc",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "1271e6a0-8076-4240-bd01-ed5e356da502",
                      field: "state.tabsV2Block.isCurrentTab",
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "130a2009-794e-4c5e-9144-d36c3f4cb3fc": {
                componentOverrides: {
                  "8282307e-54cf-4b79-9306-1a827676296e": {
                    props: {
                      style: {
                        backgroundColor: "rgb(239, 239, 239)",
                      },
                    },
                  },
                },
              },
            },
          },
        ],
      },
      {
        id: "068a4b0b-2d45-442a-b8ee-0fc8885a6ee8",
        type: "tabsV2__panelsContent",
        props: {
          style: {
            display: "flex",
            flexDirection: "column",
          },
        },
        children: [
          {
            id: "be5ef3d5-27ad-4ae6-be45-6b33574b74ae",
            name: "Container Tab 1",
            type: "container",
            props: {
              style: {
                display: "flex",
                __flexGap: "12px",
                alignSelf: "stretch",
                alignItems: "center",
                paddingTop: "15px",
                paddingLeft: "15px",
                paddingRight: "15px",
                flexDirection: "column",
                paddingBottom: "15px",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "cd311fb8-1706-41bb-b2f5-abb3d9ea0581",
                type: "text",
                props: {
                  text: '<p data-style-id="1">This is the content for tab panel #1. You can replace this content with whatever you\'d like inside the first panel.</p>',
                  style: {
                    width: "auto",
                    __width: "auto",
                    fontSize: "20px",
                    alignSelf: "auto",
                    textAlign: "center",
                    lineHeight: "24px",
                  },
                },
              },
            ],
          },
          {
            id: "62a4192f-d1a2-4fec-a26b-515ba8790b23",
            name: "Container Tab 2",
            type: "container",
            props: {
              style: {
                display: "flex",
                __flexGap: "12px",
                alignSelf: "stretch",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                paddingTop: "15px",
                paddingBottom: "15px",
                paddingLeft: "15px",
                paddingRight: "15px",
              },
            },
            children: [
              {
                id: "04620da9-8431-4243-82b8-2d1e168332c8",
                type: "text",
                props: {
                  text: '<p data-style-id="1">This is the content for tab panel #2. You can replace this content with whatever you\'d like inside the second panel.</p>',
                  style: {
                    width: "auto",
                    __width: "auto",
                    fontSize: "20px",
                    alignSelf: "auto",
                    textAlign: "center",
                    lineHeight: "24px",
                  },
                },
              },
            ],
          },
          {
            id: "4613f5d5-a49b-4f34-9abd-e7c6d21a6587",
            name: "Container Tab 3",
            type: "container",
            props: {
              style: {
                display: "flex",
                __flexGap: "12px",
                alignSelf: "stretch",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                paddingTop: "15px",
                paddingBottom: "15px",
                paddingLeft: "15px",
                paddingRight: "15px",
              },
            },
            children: [
              {
                id: "c1b9e69f-d561-4c4e-af2a-878f7bc1ca99",
                type: "text",
                props: {
                  text: '<p data-style-id="1">This is the content for tab panel #3. You can replace this content with whatever you\'d like inside the third panel.</p>',
                  style: {
                    width: "auto",
                    __width: "auto",
                    fontSize: "20px",
                    alignSelf: "auto",
                    textAlign: "center",
                    lineHeight: "24px",
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

export const tabsV2Left: ComponentTemplate = {
  id: "b303deff-e5fb-490a-82b4-3beb2527258e",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Component/Tabs (Left)",
  leftBarCategory: { main: "interactive", subCategory: "toggles" },
  preview: "component/tabs-left.svg",
  template: {
    id: "db12bc61-396f-4176-acbb-bff75e1a6296",
    name: "Tabs Block 2",
    type: "tabsV2__block",
    props: {
      style: {
        display: "flex",
        alignSelf: "stretch",
        paddingTop: "12px",
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingBottom: "12px",
      },
    },
    children: [
      {
        id: "e2b0ab88-036b-4d55-896e-4bce36a69ee5",
        type: "tabsV2__list",
        props: {
          style: {
            display: "flex",
            flexDirection: "column",
          },
        },
        children: [
          {
            id: "8c753f0a-7b82-464b-a2be-a2f3eeb943eb",
            name: "Container Tab 1",
            type: "container",
            props: {
              style: {
                display: "flex",
                flexGrow: "1",
                __flexGap: "12px",
                alignSelf: "stretch",
                flexBasis: 0,
                paddingTop: "12px",
                paddingLeft: "40px",
                paddingRight: "40px",
                paddingBottom: "12px",
                backgroundColor: "rgb(252, 252, 252)",
                __animateVariantTransitions: false,
              },
            },
            children: [
              {
                id: "3e76cf29-00b7-4b8c-948e-ca4bc49ca4f7",
                type: "text",
                props: {
                  text: "<p>Tab 1</p>",
                  style: {
                    flexGrow: 1,
                    fontSize: "20px",
                    lineHeight: "24px",
                  },
                },
              },
            ],
            variants: [
              {
                id: "2c186581-dd97-46ca-be84-9a197a766d89",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "b75e882b-6a9e-4869-acf4-9d447328ec0d",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "1271e6a0-8076-4240-bd01-ed5e356da502",
                      field: "state.tabsV2Block.isCurrentTab",
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "b75e882b-6a9e-4869-acf4-9d447328ec0d": {
                componentOverrides: {
                  "8c753f0a-7b82-464b-a2be-a2f3eeb943eb": {
                    props: {
                      style: {
                        backgroundColor: "rgb(239, 239, 239)",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "5e1d5fd8-f561-422d-939c-6ac37406caf6",
            name: "Container  Tab 2",
            type: "container",
            props: {
              style: {
                display: "flex",
                flexGrow: "1",
                __flexGap: "12px",
                alignSelf: "stretch",
                flexBasis: 0,
                paddingTop: "12px",
                paddingLeft: "40px",
                paddingRight: "40px",
                paddingBottom: "12px",
                backgroundColor: "rgb(252, 252, 252)",
                __animateVariantTransitions: false,
              },
            },
            children: [
              {
                id: "ce972e17-3255-424d-a278-22e4ec5fb323",
                type: "text",
                props: {
                  text: "<p>Tab 2</p>",
                  style: {
                    flexGrow: 1,
                    fontSize: "20px",
                    lineHeight: "24px",
                  },
                },
              },
            ],
            variants: [
              {
                id: "b7478409-f22a-4848-9c51-217eeb8835df",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "80881109-282c-41e1-8863-f1fede5f7b76",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "1271e6a0-8076-4240-bd01-ed5e356da502",
                      field: "state.tabsV2Block.isCurrentTab",
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "80881109-282c-41e1-8863-f1fede5f7b76": {
                componentOverrides: {
                  "5e1d5fd8-f561-422d-939c-6ac37406caf6": {
                    props: {
                      style: {
                        backgroundColor: "rgb(239, 239, 239)",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "7553d433-41f9-4240-ab10-4264bc6af90b",
            name: "Container Tab 3",
            type: "container",
            props: {
              style: {
                display: "flex",
                flexGrow: "1",
                __flexGap: "12px",
                alignSelf: "stretch",
                flexBasis: 0,
                paddingTop: "12px",
                paddingLeft: "40px",
                paddingRight: "40px",
                paddingBottom: "12px",
                backgroundColor: "rgb(252, 252, 252)",
                __animateVariantTransitions: false,
              },
            },
            children: [
              {
                id: "d2cc0498-f18b-405e-967b-09b842221758",
                type: "text",
                props: {
                  text: "<p>Tab 3</p>",
                  style: {
                    flexGrow: 1,
                    fontSize: "20px",
                    lineHeight: "24px",
                  },
                },
              },
            ],
            variants: [
              {
                id: "ad20daaa-0bdc-472a-ab7d-39d3a48d1eb9",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "a135d949-fe06-48c3-8939-64359ec37dc4",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "1271e6a0-8076-4240-bd01-ed5e356da502",
                      field: "state.tabsV2Block.isCurrentTab",
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "a135d949-fe06-48c3-8939-64359ec37dc4": {
                componentOverrides: {
                  "7553d433-41f9-4240-ab10-4264bc6af90b": {
                    props: {
                      style: {
                        backgroundColor: "rgb(239, 239, 239)",
                      },
                    },
                  },
                },
              },
            },
          },
        ],
      },
      {
        id: "00607557-ef7b-4c1b-a3f4-0583d62af952",
        type: "tabsV2__panelsContent",
        props: {
          style: {
            display: "flex",
            flexGrow: 1,
            alignSelf: "stretch",
            flexDirection: "column",
          },
        },
        children: [
          {
            id: "ec16f191-d867-4f58-8e77-5884f23c3ef0",
            name: "Container Tab 1",
            type: "container",
            props: {
              style: {
                display: "flex",
                flexGrow: 1,
                __flexGap: "12px",
                alignSelf: "stretch",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "d5e829d3-94f7-4791-aa6e-12c9cee32e3d",
                type: "text",
                props: {
                  text: '<p data-style-id="1">This is the content for tab panel #1. You can replace this content with whatever you\'d like inside the first panel.</p>',
                  style: {
                    width: "auto",
                    __width: "auto",
                    fontSize: "20px",
                    alignSelf: "auto",
                    textAlign: "center",
                    lineHeight: "24px",
                  },
                },
              },
            ],
          },
          {
            id: "43a136f8-d450-4b9b-85d6-3cfb4a6d88d7",
            name: "Container Tab 2",
            type: "container",
            props: {
              style: {
                display: "flex",
                __flexGap: "12px",
                alignSelf: "stretch",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                flexGrow: 1,
              },
            },
            children: [
              {
                id: "bcda1e0c-c341-433a-9b4a-01bdf7244fb6",
                type: "text",
                props: {
                  text: '<p data-style-id="1">This is the content for tab panel #2. You can replace this content with whatever you\'d like inside the second panel.</p>',
                  style: {
                    width: "auto",
                    __width: "auto",
                    fontSize: "20px",
                    alignSelf: "auto",
                    textAlign: "center",
                    lineHeight: "24px",
                  },
                },
              },
            ],
          },
          {
            id: "3b82553d-f151-4337-83ee-94a0ef1944b9",
            name: "Container Tab 3",
            type: "container",
            props: {
              style: {
                display: "flex",
                __flexGap: "12px",
                alignSelf: "stretch",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                flexGrow: 1,
              },
            },
            children: [
              {
                id: "a3db672d-089a-4296-a9fb-230ce7476052",
                type: "text",
                props: {
                  text: '<p data-style-id="1">This is the content for tab panel #3. You can replace this content with whatever you\'d like inside the third panel.</p>',
                  style: {
                    width: "auto",
                    __width: "auto",
                    fontSize: "20px",
                    alignSelf: "auto",
                    textAlign: "center",
                    lineHeight: "24px",
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

import classNames from "classnames";
import { composeEventHandlers } from "replo-utils/react/use-composed-event-handlers";

export function isEventHandlerProp(propName: string) {
  return (
    // NOTE (Chance 2024-02-24): Testing for the `on` prefix, this is a lot
    // faster than a regexp
    propName[0] === "o" &&
    propName[1] === "n" &&
    propName.charCodeAt(2) >= /* 'A' */ 65 &&
    propName.charCodeAt(2) <= /* 'Z' */ 90
  );
}

export function getEventHandlerProps<Props extends Record<string, any>>(
  props: Props,
): OnlyEventHandlers<Props> {
  const _props: any = {};
  for (const prop in props) {
    if (typeof props[prop] === "function" && isEventHandlerProp(prop)) {
      _props[prop] = props[prop];
    }
  }
  return _props;
}

type OnlyEventHandlers<T> = {
  [K in keyof T as K extends `on${Capitalize<string>}${string}`
    ? K
    : never]: T[K];
};

// NOTE (Chance 2024-02-26): Adapted from React Aria:
// https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/utils/src/mergeProps.ts
// We don't need to use the same strategy for merging various props but it's a
// useful starting point for dealing with event handlers and class names.
interface Props {
  [key: string]: any;
}

type PropsArg = Props | null | undefined;

type TupleTypes<T> = { [P in keyof T]: T[P] } extends { [key: number]: infer V }
  ? NullToObject<V>
  : never;
type NullToObject<T> = T extends null | undefined ? {} : T;
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;

/**
 * Merges multiple props objects together. Event handlers are chained and
 * classNames are combined. For all other props, the last prop object overrides
 * all previous ones.
 */
export function mergeProps<T extends PropsArg[]>(
  ...propsObjects: T
): UnionToIntersection<TupleTypes<T>> {
  // Start with a base clone of the first argument. This is a lot faster than
  // starting with an empty object and adding properties as we go.
  const result: Props = { ...propsObjects[0] };
  for (let i = 1; i < propsObjects.length; i++) {
    const props = propsObjects[i];
    for (const key in props) {
      const a = result[key];
      const b = props[key];

      // Compose event handlers by chaining them into a single function. If a
      // function calls preventDefault, subsequent handlers in the chain will
      // not be called.
      if (
        isEventHandlerProp(key) &&
        typeof a === "function" &&
        typeof b === "function"
      ) {
        result[key] = composeEventHandlers(a, b);

        // Merge classnames and styles. Sometimes classNames are empty string
        // which eval to false, so we just need to do a type check.
      } else if (
        (key === "className" || key === "unsafe_className") &&
        typeof a === "string" &&
        typeof b === "string"
      ) {
        result[key] = classNames(a, b);
      } else if (
        (key === "style" || key === "unsafe_style") &&
        a != null &&
        typeof a === "object" &&
        b != null &&
        typeof b === "object"
      ) {
        result[key] = { ...a, ...b };
      } else {
        result[key] = b !== undefined ? b : a;
      }
    }
  }
  return result as UnionToIntersection<TupleTypes<T>>;
}

export type AriaLabelProps =
  | { "aria-label": string; "aria-labelledby"?: never }
  | { "aria-label"?: never; "aria-labelledby": string }
  | { "aria-label"?: string; "aria-labelledby"?: string };

export interface UnsafeStyleProps {
  /**
   * **IMPORTANT** (Chance 2023-11-10): Adding classnames to design system
   * components should be considered an escape hatch and avoided if possible.
   * Things like spacing and layout can generally be handled with wrapper layout
   * components. Design variations should be considered in the design system and
   * not in individual instances.
   */
  unsafe_className?: string;
  /**
   * **IMPORTANT** (Chance 2023-11-10): Adding inline styles to design system
   * components should be considered an escape hatch and avoided if possible.
   * Things like spacing and layout can generally be handled with wrapper layout
   * components. Design variations should be considered in the design system and
   * not in individual instances.
   */
  unsafe_style?: React.CSSProperties;
}

export interface SafeStyleProps {
  className?: string;
  style?: React.CSSProperties;
}

export interface DataAttributeProps {
  [key: `data-${string}`]: string;
}

import clsxMerge from "@replo/design-system/components/shadcn/utils/cn-merge";
import classNames from "classnames";
import * as React from "react";
import { BsThreeDots } from "react-icons/bs";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ICON_DIMENSIONS = "h-4 w-4";

const Pagination = ({ className, ...props }: React.ComponentProps<"nav">) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={clsxMerge("", className)}
    {...props}
  />
);
Pagination.displayName = "Pagination";

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<"ul">
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={clsxMerge(
      "flex flex-row items-center gap-1 justify-end",
      className,
    )}
    {...props}
  />
));
PaginationContent.displayName = "PaginationContent";

const PaginationItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<"li">
>(({ className, ...props }, ref) => (
  <li ref={ref} className={clsxMerge("text-slate-500", className)} {...props} />
));
PaginationItem.displayName = "PaginationItem";

type PaginationLinkProps = {
  isActive?: boolean;
  isNavigationButton: boolean;
  size?: string;
  isDisabled?: boolean;
} & React.ComponentProps<"a">;

const PaginationLink = ({
  className,
  isActive,
  isNavigationButton,
  isDisabled = false,
  ...props
}: PaginationLinkProps) => (
  <a
    aria-current={isActive ? "page" : undefined}
    className={clsxMerge(
      classNames(
        "inline-flex items-center justify-center rounded-md text-sm font-medium",
        {
          "text-blue-600 border border-input border-blue-600":
            isActive && !isNavigationButton,
          "h-10 w-10": !isNavigationButton,
          "hover:bg-blue-50": !isActive && !isNavigationButton,
          "cursor-not-allowed": isDisabled,
          "hover:text-blue-600": !isDisabled,
        },
      ),
      className,
    )}
    {...props}
  />
);
PaginationLink.displayName = "PaginationLink";

const PaginationNavigationButton = ({
  direction,
  className,
  ...props
}: {
  direction: "previous" | "next";
} & React.ComponentProps<typeof PaginationLink>) => {
  const isPrevious = direction === "previous";

  const label = isPrevious ? "Previous" : "Next";

  const Icon = isPrevious ? FaChevronLeft : FaChevronRight;

  const padding = "2.5";
  const gapClass = isPrevious ? `pl-${padding}` : `pr-${padding}`;

  return (
    <PaginationLink
      aria-label={`Go to ${label.toLowerCase()} page`}
      className={clsxMerge(
        `flex items-center justify-center gap-3 ${gapClass}`,
        className,
      )}
      {...props}
    >
      {isPrevious && <Icon className={ICON_DIMENSIONS} />}
      <span>{label}</span>
      {!isPrevious && <Icon className={ICON_DIMENSIONS} />}
    </PaginationLink>
  );
};

PaginationNavigationButton.displayName = "PaginationNavigationButton";

const PaginationPrevious = (
  props: React.ComponentProps<typeof PaginationLink>,
) => <PaginationNavigationButton direction="previous" {...props} />;
PaginationPrevious.displayName = "PaginationPrevious";

const PaginationNext = (props: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationNavigationButton direction="next" {...props} />
);
PaginationNext.displayName = "PaginationNext";

const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<"span">) => (
  <span
    aria-hidden
    className={clsxMerge("flex h-9 w-9 items-center justify-center", className)}
    {...props}
  >
    <BsThreeDots className={ICON_DIMENSIONS} />
    <span className="sr-only">More pages</span>
  </span>
);
PaginationEllipsis.displayName = "PaginationEllipsis";

export {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
};

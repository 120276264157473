import ButtonGroupComponent from "@editor/components/common/designSystem/ButtonGroup";
import { DraggingTypes } from "@editor/utils/editor";
import type { InputOption } from "@editorModifiers/LengthInputModifier";
import LengthInputModifier from "@editorModifiers/LengthInputModifier";
import * as React from "react";
import { FiMinus, FiPlus } from "react-icons/fi";

type StepperProps = {
  onChange(value: number): void;
  placeholder?: string;
  value: number;
  testIdPlus?: string;
  testIdMinus?: string;
  field: string;
  startEnhancer?(): React.ReactNode;
  menuOptions?: InputOption[];
  toolTipPlusButton?: string;
  toolTipMinusButton?: string;
  resetValue?: string;
  anchorValue?: string;
  minDragValue?: number;
  minValue?: number;
};

const options = [{ label: "Reset", value: "0" }];

const Stepper: React.FC<StepperProps> = (props) => {
  return (
    <div className="flex flex-row items-end justify-between gap-2">
      <LengthInputModifier
        startEnhancer={props.startEnhancer}
        value={String(props.value)}
        placeholder={props.placeholder ?? "0"}
        key={props.field}
        field={props.field}
        onChange={(value) => {
          props.onChange(Number(value));
        }}
        minDragValues={{ "": props.minDragValue ?? 0 }}
        minValues={{ "": props.minValue ?? 0 }}
        dragTrigger="startEnhancer"
        metrics={[""]}
        resetValue={props.resetValue ?? "0"}
        anchorValue={props.anchorValue ?? "0"}
        allowsNegativeValue={true}
        menuOptions={props.menuOptions ?? options}
        draggingType={DraggingTypes.Vertical}
      />
      <ButtonGroupComponent
        options={[
          {
            label: <FiMinus size={12} data-testid={props.testIdMinus} />,
            onClick: () => {
              props.onChange(
                Math.max(
                  props.value - 1,
                  props.minValue ?? props.minDragValue ?? 0,
                ),
              );
            },
            tooltipText: props.toolTipMinusButton,
          },
          {
            label: <FiPlus size={12} data-testid={props.testIdPlus} />,
            onClick: () => {
              props.onChange(props.value + 1);
            },
            tooltipText: props.toolTipPlusButton,
          },
        ]}
      />
    </div>
  );
};

export default Stepper;

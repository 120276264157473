import * as React from "react";
import { Navigate, useParams } from "react-router-dom";

function ReferralCodeRoute() {
  const params = useParams();
  if (params.referralCode) {
    const referralPath = `?referral=${params.referralCode}`;
    return <Navigate to={`/auth/signup${referralPath}`} replace />;
  }
  return <Navigate to="/auth/signup" replace />;
}

export default ReferralCodeRoute;

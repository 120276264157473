import * as React from "react";

import type { RenderComponentProps } from "../../../shared/types";
import { SharedJunipReviews } from "../SharedJunipReviews";

const JunipReviews: React.FC<RenderComponentProps> = (props) => {
  const liquidSource = `
    <span
      class="junip-product-review"
      data-product-id="{{ product.id }}">
    </span>
  `;
  return (
    <SharedJunipReviews
      attributes={props.componentAttributes}
      component={props.component}
      context={props.context}
      liquidSource={liquidSource}
      reviewsComponentType="Reviews"
    />
  );
};

export default JunipReviews;

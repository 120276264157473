import * as React from "react";

import { useOverridableState } from "../../shared/hooks/useOverridableState";
import {
  RenderEnvironmentContext,
  SharedStateContext,
  useRuntimeContext,
} from "../../shared/runtime-context";

/**
 * Hook for using shared state on runtime, if rendered inside
 * the editor. Otherwise, it will default to React's useState.
 */
export function useSharedState<T>(
  key: string | string[],
  defaultValue: T,
): readonly [T, (value: T) => void] {
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const { sharedState, setSharedState } = useRuntimeContext(SharedStateContext);

  const finalKey = Array.isArray(key) ? key.join(".") : key;
  const sharedStateValue = sharedState?.[finalKey] as T | undefined;

  const [value, setValue] = useOverridableState(
    sharedStateValue ?? defaultValue,
  );

  const editorSetState = React.useCallback(
    (value: T) => {
      setSharedState({ key: finalKey, value });
    },
    [finalKey, setSharedState],
  );

  return [value, isEditorApp ? editorSetState : setValue];
}

export default useSharedState;

import Button from "@editor/components/common/designSystem/Button";
import type { CodeEditorLanguage } from "@editor/components/modals/CodeEditorModal";
import { useModal } from "@editor/hooks/useModal";
import * as React from "react";

export const CodeEditorCustomPropModifier = (props: {
  value: string | null;
  onChange: (value: string) => void;
  language: CodeEditorLanguage;
}) => {
  const { openModal } = useModal();
  const { language, value, onChange } = props;

  return (
    <Button
      type="secondary"
      isFullWidth
      onClick={() =>
        openModal({
          type: "codeEditorModal",
          props: {
            language,
            value,
            onChange,
          },
        })
      }
    >
      Launch Code Editor
    </Button>
  );
};

import Flows from "@editor/components/flows/Flows";
import { useGetCurrentFlow } from "@editor/components/flows/hooks/useGetCurrentFlow";
import * as React from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

const RouterFlows = () => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const isDebug = Boolean(searchParams.get("debug"));
  const { currentFlow } = useGetCurrentFlow({ isDebug });

  // NOTE (Fran 2024-02-06): We should redirect to the home if there is no current flow or is trying
  // to access to a different flow.
  if (!currentFlow) {
    return <Navigate to="/home" />;
  }
  const flowStep = currentFlow?.steps.find(
    (step) => step.id === params.flowStepId,
  );

  const nextStepFromRoute = flowStep ?? null;

  return (
    <Flows
      entityType={currentFlow.type}
      nextStepFromRoute={nextStepFromRoute}
    />
  );
};

export default RouterFlows;

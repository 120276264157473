import type { ComponentTemplate } from "@editor/types/component-template";
import { getCanBeAddedFunction } from "@editor/utils/template";
import { Env } from "replo-runtime/shared/enums";

const compareAtPrice: ComponentTemplate = {
  id: "421f60e1-d278-47b1-84d6-4e5d89017f40",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Compare At Price",
  leftBarCategory: { main: "product", subCategory: "productData" },
  tags: ["product", "product compare at price"],
  preview: "product/compare-at-price.svg",
  template: {
    id: "e3f13ba8-a0f1-4dc9-b136-db66c1568c11",
    type: "text",
    props: {
      text: "{{attributes._variant.compareAtDisplayPrice}}",
      style: { fontSize: "20px", lineHeight: "24px" },
    },
  },
  canBeAddedAsChild: getCanBeAddedFunction(
    "product",
    "Product Compare At Prices can only be added inside Product boxes.",
  ),
};

export default compareAtPrice;

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const dynamicCheckoutButtons: ComponentTemplate = {
  id: "0c535bed-fb96-4e33-a67b-12b39c82d122",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Dynamic Checkout Buttons",
  leftBarCategory: { main: "product", subCategory: "buttons" },
  preview: "product/pay-button.svg",
  tags: ["product", "product dynamic checkout buttons"],
  transforms: [],
  template: {
    id: "$uid",
    type: "dynamicCheckoutButtons",
    props: {},
  },
};

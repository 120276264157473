import type { ComponentActionType } from "@editor/types/component-action-type";
import type {
  CoreState,
  ElementsState,
  HistoryStateType,
} from "@editor/types/core-state";
import type { GetAttributeFunction } from "@editor/types/get-attribute-function";
import { findAncestorComponentOrSelf, isModal } from "@editor/utils/component";
import { objectId } from "@editor/utils/objectId";
import type { CommerceState } from "@reducers/commerce-reducer";
import type { LiquidRendererState } from "@reducers/liquid-renderer-reducer";
import { applyComponentAction } from "@reducers/utils/component-actions";
import { enablePatches, produceWithPatches } from "immer";
import isEqual from "lodash-es/isEqual";
import type {
  Component,
  ComponentMapping,
} from "replo-runtime/shared/Component";
import type {
  ProductRef,
  StoreProduct,
  Swatch,
} from "replo-runtime/shared/types";
import { forEachComponentAndDescendants } from "replo-runtime/shared/utils/component";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import type { DataTable, ReploElement } from "schemas/element";

enablePatches();

/**
 * Takes an action and applies it to a component.
 *
 * Does not handle creating a component
 */
export const getNextElements = ({
  action,
  state,
}: {
  action: { payload: ComponentActionType };
  state: CoreState;
}) => {
  const op = action.payload;
  const elements = state.elements.mapping;
  let updatedComponentIds: string[] = [];

  const [nextState, patches, inversePatches] = produceWithPatches(
    elements,
    (elements) => {
      updatedComponentIds = applyComponentAction({
        action: { ...op, analyticsExtras: action.payload.analyticsExtras },
        elements,
        state,
      });
    },
  );
  return {
    elements: nextState,
    patches,
    inversePatches,
    updatedComponentIds,
  };
};

export const getHistoryAfterPatch = ({
  action,
  history,
  patches,
  inversePatches,
  elementId,
  componentIds,
}: {
  action: { type: any };
  history: HistoryStateType;
  patches: any;
  inversePatches: any;
  elementId: string;
  componentIds: string[];
}) => {
  const next = history.index + 1;
  history.operations[next] = {
    type: action.type,
    redo: patches,
    undo: inversePatches,
    elementId,
    componentIds,
  };

  /* Delete one version in advance so we cannot redo */
  delete history.operations[next + 1];
  /* Delete anything that's more than the number of supported versions */
  delete history.operations[history.index - history.maxSize];

  return {
    ...history,
    operations: history.operations,
    index: next,
  };
};

export const getAncestorAlchemyModal = (
  draftElement: ReploElement | null,
  draftComponentId: Component["id"] | null,
): Component | null => {
  if (draftElement && draftComponentId) {
    const modalAncestor = findAncestorComponentOrSelf(
      draftElement,
      draftComponentId,
      (component) => isModal(component.type),
    );
    return modalAncestor;
  }
  return null;
};

export type PaintTypeResult =
  | { paintType: "none" }
  | { paintType: "all"; forceRemount: boolean }
  | { paintType: "components" };

export type EditorRepaintDependencies = {
  renderedLiquidCache: LiquidRendererState["renderedLiquidCache"];
  liquidRequestsInProgress: LiquidRendererState["requestsInProgress"];
  dataTables: { mapping: Record<string, DataTable> };
  productMetafieldValues: CommerceState["productMetafieldValues"];
  variantMetafieldValues: CommerceState["variantMetafieldValues"];
  isContentEditing: boolean;
  elements: Omit<
    ElementsState,
    | "componentIdToDraftVariantId"
    | "draftElementColors"
    | "draftElementFontFamilies"
  >;
  historyIndex: number;
  selectedRevisionId: string | null;
  storeUrl: string | undefined;
  storeId: string | undefined;
  sharedState: Record<string, any>;
  productIds: number[];
  swatches: Swatch[];
  templateEditorProduct: ProductRef | null;
  elementId: string | null;
  editorMediaUploadingComponentIds: string[];
};

export const editorNeedsRepaint = (
  prevDependencies: EditorRepaintDependencies | null,
  currentDependencies: EditorRepaintDependencies,
): PaintTypeResult => {
  if (!prevDependencies) {
    return {
      paintType: "all",
      forceRemount: true,
    };
  }
  const previousAncestorModal = getAncestorModalFromElementsState(
    prevDependencies.elements,
  );
  const currentAncestorModal = getAncestorModalFromElementsState(
    currentDependencies.elements,
  );

  const isNewModal =
    (currentAncestorModal && !previousAncestorModal) ||
    (previousAncestorModal && !currentAncestorModal) ||
    (previousAncestorModal &&
      currentAncestorModal &&
      previousAncestorModal.id !== currentAncestorModal.id);

  const isElementInit =
    prevDependencies.historyIndex === -1 &&
    !prevDependencies.elements.draftComponentId &&
    !currentDependencies.elements.draftComponentId;

  if (!isEqual(prevDependencies.elementId, currentDependencies.elementId)) {
    return {
      paintType: "all",
      forceRemount:
        prevDependencies.elementId !== currentDependencies.elementId,
    };
  }

  const previousSaveVersion = getFromRecordOrNull(
    prevDependencies.elements.versionMapping,
    prevDependencies.elements.draftElementId,
  );
  const currentSaveVersion = getFromRecordOrNull(
    currentDependencies.elements.versionMapping,
    currentDependencies.elements.draftElementId,
  );
  const previousElement = getFromRecordOrNull(
    prevDependencies.elements.mapping,
    prevDependencies.elements.draftElementId,
  );
  const currentElement = getFromRecordOrNull(
    currentDependencies.elements.mapping,
    currentDependencies.elements.draftElementId,
  );

  const isFirstOccurrenceOfRootComponent =
    previousElement?.id === currentElement?.id &&
    !previousElement?.component &&
    Boolean(currentElement?.component);

  // Note (Noah, 2022-08-13): We have the following cases to consider when the
  // element version updates (aka when an element update request comes back).
  //
  // 1. The user has made an update to the element which succeeded, and we
  //    don't want to repaint because it would be unnecessary (we already
  //    repainted when the element update was processed locally by immer).
  // 2. The user has made an update to the element, but we've rejected
  //    the update and returned the most up to date version. We DO want to
  //    repaint, because the user needs to see the latest version of the element.
  //
  // In case (1), the current element version will be exactly equal to the previous
  // element version + 1, and the previous element will be exactly the same
  // object in-memory as the current element.
  //
  // In case (2), the the previous element will be a different object in-memory
  // than the current element (since in core-reducer, we reset the element mapping to
  // have the new element from the server).
  //
  // So, if we have a different in-memory element, we need to repaint. Otherwise we might not.
  const needsUpdateFromElementSaveVersion =
    previousSaveVersion &&
    currentSaveVersion &&
    previousElement &&
    currentElement &&
    objectId(previousElement) !== objectId(currentElement);
  if (
    // Repaint when shared state changes
    !isEqual(prevDependencies.sharedState, currentDependencies.sharedState) ||
    // Repaint when the rendered liquid cache updates, because we
    // might have new rendered liquid to display
    !isEqual(
      prevDependencies.renderedLiquidCache,
      currentDependencies.renderedLiquidCache,
    ) ||
    // Repaint when data tables update, because we want the components
    // that use data tables to see their new dynamic data values
    !isEqual(prevDependencies.dataTables, currentDependencies.dataTables) ||
    // Repaint when swatches update, because we want the components
    // that use swatches to see their new dynamic data values
    !isEqual(prevDependencies.swatches, currentDependencies.swatches) ||
    // Repaint when product or variant metafield values change, since
    // components may want to display new metafield values
    !isEqual(
      prevDependencies.productMetafieldValues,
      currentDependencies.productMetafieldValues,
    ) ||
    !isEqual(
      prevDependencies.variantMetafieldValues,
      currentDependencies.variantMetafieldValues,
    ) ||
    // Repaint when the current element's save version changes since this indicates
    // that a remote user updated the element and the local user doesn't have the updates
    // prevDependencies.draftElementVersion !== currentDependencies.draftElementVersion
    needsUpdateFromElementSaveVersion ||
    prevDependencies.selectedRevisionId !==
      currentDependencies.selectedRevisionId ||
    // Repaint when a modal is selected since we need to show the modal
    isNewModal ||
    // Repaint at least once when the page is initially loaded (this ensures we
    // paint at least once with newest runtime components)
    isElementInit ||
    isFirstOccurrenceOfRootComponent ||
    // Repaint if products have changed, because it may mean that we selected a
    // different product which we previously had not fetched.
    !isEqual(prevDependencies.productIds, currentDependencies.productIds) ||
    previousElement?.hideDefaultFooter !== currentElement?.hideDefaultFooter ||
    previousElement?.hideDefaultHeader !== currentElement?.hideDefaultHeader ||
    previousElement?.hideShopifyAnnouncementBar !==
      currentElement?.hideShopifyAnnouncementBar ||
    // Repaint when template editor product changes
    prevDependencies?.templateEditorProduct !==
      currentDependencies?.templateEditorProduct ||
    // Repaint when we've applied a streaming action (AI generation)
    prevDependencies.elements.streamingUpdate?.repaintKey !==
      currentDependencies.elements.streamingUpdate?.repaintKey
  ) {
    return { paintType: "all", forceRemount: false };
  }

  if (
    prevDependencies.isContentEditing !== currentDependencies.isContentEditing
  ) {
    return {
      paintType: "components",
    };
  } else if (
    prevDependencies.historyIndex === currentDependencies.historyIndex
  ) {
    if (
      !isEqual(
        prevDependencies.editorMediaUploadingComponentIds,
        currentDependencies.editorMediaUploadingComponentIds,
      )
    ) {
      return {
        paintType: "components",
      };
    }

    return {
      paintType: "none",
    };
  }
  return {
    paintType: "components",
  };
};

function getAncestorModalFromElementsState(
  elements: EditorRepaintDependencies["elements"],
) {
  const draftElement = getFromRecordOrNull(
    elements.mapping,
    elements.draftElementId,
  );
  const draftComponentId = elements.draftComponentId;
  return getAncestorAlchemyModal(draftElement, draftComponentId);
}

export function getComponentMappingFromElement(
  draftElement: ReploElement | null,
) {
  if (!draftElement) {
    return {};
  }
  const mapping: ComponentMapping = {};
  forEachComponentAndDescendants(
    draftElement.component,
    (component, parentComponent) => {
      mapping[component.id] = {
        component,
        parentComponent,
      };
    },
  );
  return mapping;
}

export function findApplicableProduct(
  products: StoreProduct[],
  productTemplateSlug: string,
) {
  let applicableProduct: StoreProduct | null = null;
  const templateSuffix = `alchemy.${productTemplateSlug || "unknown"}`;
  if (products.length > 0) {
    applicableProduct =
      products.find((p: any) => p.templateSuffix === templateSuffix) ||
      products[0]!;
  }
  if (!applicableProduct) {
    applicableProduct = products.length > 0 ? products[0]! : null;
  }
  return applicableProduct;
}

export function getFieldMapping<Key extends string, T extends Record<Key, any>>(
  iterable: Array<T>,
  field: Key,
): Record<T[Key], T> {
  const mapping: Record<string, T> = {};
  iterable.forEach((item) => {
    mapping[String(item[field])] = item;
  });
  return mapping;
}

export const orderOfCategories: Record<string, number> = {
  Basic: 1,
  Layout: 2,
  Product: 3,
  Video: 4,
  Form: 5,
  Map: 6,
  Icon: 7,
  Component: 8,
  "Custom Code": 9,
  CMS: 10,
};

export type PositionAttribute = "top" | "bottom" | "left" | "right";

export function getDefaultPositionAttribute(
  positions: PositionAttribute[],
  draftComponent: Component,
  getAttribute: GetAttributeFunction,
): PositionAttribute | "center" {
  for (const position of positions) {
    const { value } = getAttribute(draftComponent, `style.${position}`);

    if (value === "50%") {
      return "center";
    }
    // Note (Noah, 2021-08-11): Unfortunately it seems as if some components have
    // acquired values of NaNpx for their positioning values. We should probably
    // write a migration to remove these but for now we just handle them the same
    // as null
    if (value !== null && value !== "auto" && value !== "NaNpx") {
      return position;
    }
  }
  return positions[0]!;
}

export const hardcodedActions = [
  "redirect",
  "addProductVariantToCart",
  "clearCart",
  "redirectToProductPage",
  "applyDiscountCode",
  "executeJavascript",
  "scrollToUrlHashmark",
] as const;

/**
 * Note (Chance 2023-07-11) This function should only return an updated element
 * state reference if the mapping or versionMapping have changed, based on the
 * new element and version. This will prevent over-calling effects that depend
 * on the elements state.
 */
export function mergeElementsStateWithNewElement(
  elements: ElementsState,
  element: ReploElement & { version: number },
): ElementsState {
  const mapping = Object.is(elements.mapping[element.id], element)
    ? elements.mapping
    : { ...elements.mapping, [element.id]: element };
  const versionMapping =
    elements.versionMapping[element.id] === element.version
      ? elements.versionMapping
      : { ...elements.versionMapping, [element.id]: element.version };

  if (
    Object.is(elements.mapping, mapping) &&
    Object.is(elements.versionMapping, versionMapping)
  ) {
    return elements;
  }

  return { ...elements, mapping, versionMapping };
}

/**
 * Note (Chance 2023-07-11) This function should only return an updated elements
 * state reference if the draft element exists and needs to be updated with a
 * new component reference. This will prevent over-calling effects that depend
 * on the elements state.
 *
 * Note (Evan, 2024-07-18): We parameterize the properties of the elements state actually
 * used in the calculation to avoid triggering recalculation when irrelevant properties
 * (particularly in the streamingUpdate) change. See selectElementWithRevisionState in
 * core-reducer.ts for more details.
 */
export function updateElementMappingWithRevision({
  draftElementId,
  elementRevisions,
  selectedRevisionId,
  elementMapping,
  streamingDraftElementComponent,
}: {
  draftElementId: string | null;
  elementRevisions: ElementsState["elementRevisions"];
  selectedRevisionId: string | null;
  elementMapping: ElementsState["mapping"];
  streamingDraftElementComponent: Component | undefined;
}): ElementsState["mapping"] {
  if (!draftElementId) {
    return elementMapping;
  }
  const draftElement = elementMapping[draftElementId];
  if (!draftElement) {
    return elementMapping;
  }

  const updatedDraftElementComponentFromRevision = elementRevisions[
    draftElementId
  ]?.find((revision) => revision.id === selectedRevisionId)?.component;

  const updatedDraftElementComponentFromStreaming =
    draftElement.component.id === streamingDraftElementComponent?.id &&
    streamingDraftElementComponent;

  const updatedDraftElementComponent =
    updatedDraftElementComponentFromRevision ??
    updatedDraftElementComponentFromStreaming;

  if (
    !updatedDraftElementComponent ||
    Object.is(updatedDraftElementComponent, draftElement.component)
  ) {
    return elementMapping;
  }

  return {
    ...elementMapping,
    [draftElementId]: {
      ...draftElement,
      component: updatedDraftElementComponent,
    },
  };
}

export function getElementWithRevisionState(elements: ElementsState) {
  if (!elements.selectedRevisionId && !elements.streamingUpdate) {
    return elements;
  }

  const {
    draftElementId,
    elementRevisions,
    selectedRevisionId,
    mapping: elementMapping,
    streamingUpdate,
  } = elements;

  const newMapping = updateElementMappingWithRevision({
    draftElementId,
    elementRevisions,
    selectedRevisionId,
    elementMapping,
    streamingDraftElementComponent: streamingUpdate?.draftElementComponent,
  });
  return {
    ...elements,
    mapping: newMapping,
  };
}

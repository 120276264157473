import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { prependProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedLiquidReviewIntegrations } from "../SharedLiquidReviewIntegrations";

function StampedProductRatingWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const product = useProductFromProps(component.props, context);
  const liquidSource = `<span
    class="stamped-product-reviews-badge stamped-main-badge"
    data-id="{{ product.id }}"
    data-product-sku="{{ product.handle }}"
    data-product-title="{{product.title}}"
    data-product-type="{{product.type}}"
    style="display: block;">
    {{product.metafields.stamped.badge}}
    </span>`;
  const liquid = prependProductAssignmentToLiquidSource({
    product,
    liquidSource,
    context: props.context,
  });

  return (
    <SharedLiquidReviewIntegrations
      attributes={componentAttributes}
      component={component}
      liquidSource={liquid}
      isProductRating
      placeholder="Stamped Product Rating Widget will appear here"
      shouldHavePlaceHolder={isEditorApp || !product}
      appName="Stamped"
      context={context}
    />
  );
}

export default StampedProductRatingWidget;

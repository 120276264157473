import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { selectIsPreviewMode } from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import classNames from "classnames";
import * as React from "react";
import { Resizable } from "react-resizable";
import { clamp } from "replo-utils/lib/math";
import { useRequiredContext } from "replo-utils/react/context";

import { CANVAS_DATA } from "./canvas-constants";
import { CanvasContext } from "./canvas-context";
import {
  selectActiveCanvasWidth,
  selectCanvasDeltaXY,
  selectCanvasIsLoading,
  selectCanvasScale,
  selectPrimaryCanvasHeight,
  setCanvasInteractionMode,
  setCanvasWidth,
  setPreviewWidth,
} from "./canvas-reducer";
import { ALLOWED_RANGE, getCanvasData } from "./canvas-utils";
import useSetActiveCanvas from "./useSetActiveCanvas";
import { useSetDeltaXY } from "./useSetDeltaXY";
import { useUpdateCanvasHeight } from "./useUpdateCanvasHeight";
import { useUpdateFramePositionStyles } from "./useUpdateFramePositionStyles";

type Orientation = "left" | "right";
const DRAG_HANDLE_WIDTH = 5;

export function CanvasResizer() {
  const canvasIsLoading = useEditorSelector(selectCanvasIsLoading);
  if (canvasIsLoading) {
    return null;
  }

  return (
    <>
      {["left", "right"].map((orientation) => {
        return isFeatureEnabled("multiple-canvases") ? (
          <MultiCanvasSingleResizer
            key={orientation}
            orientation={orientation as Orientation}
          />
        ) : (
          <CanvasSingleResizer
            key={orientation}
            orientation={orientation as Orientation}
          />
        );
      })}
    </>
  );
}

const CanvasSingleResizer: React.FC<{
  orientation: Orientation;
}> = ({ orientation }) => {
  let canvasScale = useEditorSelector(selectCanvasScale);
  const [isDragging, setIsDragging] = React.useState(false);
  const primaryCanvasHeight = useEditorSelector(selectPrimaryCanvasHeight);

  let { deltaX, deltaY } = useEditorSelector(selectCanvasDeltaXY);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const setActiveCanvas = useSetActiveCanvas();
  const activeCanvasWidth = useEditorSelector(selectActiveCanvasWidth);

  if (isPreviewMode) {
    deltaX = window.innerWidth / 2 - activeCanvasWidth / 2;
    deltaY = 0;
    canvasScale = 1;
  }

  const isLeft = orientation === "left";

  const activeCanvasWidthOnResizeStart =
    React.useRef<number>(activeCanvasWidth);
  const dispatch = useEditorDispatch();
  const setDraftElement = useSetDraftElement();
  const logAnalytics = useLogAnalytics();
  const setDeltaXY = useSetDeltaXY();
  const updateCanvasHeight = useUpdateCanvasHeight();

  const onResizeStart = React.useCallback(() => {
    activeCanvasWidthOnResizeStart.current = activeCanvasWidth;
    setDraftElement({ componentId: null });
    dispatch(setCanvasInteractionMode("resizing"));
  }, [setDraftElement, dispatch, activeCanvasWidth]);

  const onResizeEnd = React.useCallback(() => {
    dispatch(setCanvasInteractionMode("edit"));
    const canvasData = getCanvasData(activeCanvasWidth);
    if (canvasData) {
      logAnalytics("canvas.frame.resize", {
        method: "drag",
        previousSize: activeCanvasWidthOnResizeStart.current,
        size: activeCanvasWidth,
      });
      setActiveCanvas({
        canvas: canvasData.canvasName,
        width: activeCanvasWidth,
        source: "resize",
      });
    }
  }, [dispatch, activeCanvasWidth, logAnalytics, setActiveCanvas]);

  const onResize = (width: number, deltaX: number) => {
    updateCanvasHeight("desktop");
    const canvasData = getCanvasData(width);
    if (canvasData) {
      const { canvasName: canvas } = canvasData;
      if (canvasData) {
        setActiveCanvas({ canvas, width, source: "resize" });
      }
      if (!isPreviewMode) {
        setDeltaXY({ deltaX });
      }
    }
  };

  const onDragStart = () => {
    onResizeStart();
    setIsDragging(true);
  };

  const onDrag = (currentWidth: number) => {
    const canvasData = getCanvasData(currentWidth);
    if (!canvasData) {
      return;
    }

    const width = Math.max(
      currentWidth + (currentWidth - activeCanvasWidth),
      ALLOWED_RANGE.minValue,
    );

    const currentDeltaX =
      deltaX - ((width - activeCanvasWidth) / 2) * canvasScale;
    onResize(width, currentDeltaX);
  };

  const onDragEnd = () => {
    onResizeEnd();
    setIsDragging(false);

    const canvasData = getCanvasData(activeCanvasWidth);
    if (!canvasData) {
      return;
    }
    setActiveCanvas({
      canvas: canvasData.canvasName,
      width: activeCanvasWidth,
      source: "resize",
    });
  };

  const left = isLeft
    ? deltaX - DRAG_HANDLE_WIDTH
    : deltaX + activeCanvasWidth * canvasScale;
  const top = deltaY;
  const scaledWidth = activeCanvasWidth * canvasScale;
  const scaledHeight = primaryCanvasHeight * canvasScale;

  return (
    <Resizable
      width={scaledWidth}
      height={0}
      axis="x"
      handle={
        <div
          style={{
            position: "absolute",
            left,
            top,
            width: DRAG_HANDLE_WIDTH,
            height: scaledHeight,
            cursor: "ew-resize",
          }}
          className={classNames(
            "bg-transparent hover:bg-blue-600",
            isDragging && "bg-blue-600",
          )}
        />
      }
      onResize={(_e, { size }) => {
        let width = size.width / canvasScale;
        if (isLeft) {
          width = activeCanvasWidth - (width - activeCanvasWidth);
        }

        onDrag(Number(width.toFixed(0)));
      }}
      onResizeStop={() => {
        onDragEnd();
      }}
      onResizeStart={onDragStart}
    >
      <div />
    </Resizable>
  );
};

const MultiCanvasSingleResizer: React.FC<{
  orientation: Orientation;
}> = ({ orientation }) => {
  const { canvas, canvasWidth, canvasHeight } =
    useRequiredContext(CanvasContext);

  const [isDragging, setIsDragging] = React.useState(false);
  const canvasWidthOnResizeStart = React.useRef<number>(canvasWidth);

  const { deltaX } = useEditorSelector(selectCanvasDeltaXY);
  const canvasScale = useEditorSelector(selectCanvasScale);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const dispatch = useEditorDispatch();
  const setDraftElement = useSetDraftElement();
  const setDeltaXY = useSetDeltaXY();
  const logAnalytics = useLogAnalytics();

  const isLeft = orientation === "left";

  function handleResizeStart() {
    setIsDragging(true);
    dispatch(setCanvasInteractionMode("resizing"));
    if (!isPreviewMode) {
      setDraftElement({ componentId: null });
      canvasWidthOnResizeStart.current = canvasWidth;
    }
  }

  function handleResizeStop(newWidth: number) {
    setIsDragging(false);
    dispatch(setCanvasInteractionMode("edit"));
    dispatch(
      isPreviewMode
        ? setPreviewWidth(newWidth)
        : setCanvasWidth({
            canvas,
            width: newWidth,
          }),
    );
    if (!isPreviewMode) {
      logAnalytics("canvas.frame.resize", {
        method: "drag",
        previousSize: canvasWidthOnResizeStart.current,
        size: newWidth,
      });
    }
  }

  function handleResize(newWidth: number) {
    const minWidth = CANVAS_DATA[isPreviewMode ? "mobile" : canvas].range[0];
    const maxWidth = CANVAS_DATA[isPreviewMode ? "desktop" : canvas].range[1];
    const width = clamp(newWidth, minWidth, maxWidth);
    const newDeltaX = deltaX - ((width - canvasWidth) / 2) * canvasScale;

    dispatch(
      isPreviewMode
        ? setPreviewWidth(width)
        : setCanvasWidth({
            canvas,
            width,
          }),
    );

    setDeltaXY({ deltaX: newDeltaX });
  }

  function getNewWidth(width: number) {
    return Math.round(isLeft ? canvasWidth - (width - canvasWidth) : width);
  }

  return (
    <Resizable
      width={canvasWidth}
      height={0}
      axis="x"
      handle={
        <div
          style={{
            position: "absolute",
            width: DRAG_HANDLE_WIDTH,
            left: isLeft ? -DRAG_HANDLE_WIDTH : canvasWidth,
            top: 0,
            height: canvasHeight,
            cursor: "ew-resize",
          }}
          className={classNames(
            "hover:bg-blue-600",
            isDragging ? "bg-blue-600" : "bg-transparent",
          )}
        />
      }
      onResize={(_e, { size }) => {
        handleResize(getNewWidth(size.width));
      }}
      onResizeStart={handleResizeStart}
      onResizeStop={(_e, { size }) => {
        handleResizeStop(getNewWidth(size.width));
      }}
    >
      <div />
    </Resizable>
  );
};

interface UpdateFramePositionStylesLegacyProps {
  children: React.ReactNode;
  frameRef: React.RefObject<HTMLDivElement>;
}

export function UpdateFramePositionStylesLegacy({
  children,
  frameRef,
}: UpdateFramePositionStylesLegacyProps) {
  const activeCanvasWidth = useEditorSelector(selectActiveCanvasWidth);
  const frameHeight = useEditorSelector(selectPrimaryCanvasHeight);
  useUpdateFramePositionStyles(frameRef, {
    width: activeCanvasWidth,
    height: frameHeight,
  });
  return children;
}

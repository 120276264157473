import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const junipProductRating: ComponentTemplate = {
  id: "f30fcfdf-751c-4e70-a5d7-1b3a37ed6fb0",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Junip Product Rating",
  leftBarCategory: { main: "productIntegrations", subCategory: "junip" },
  preview: "integrations/junip-product-rating.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  tags: ["reviews", "star"],
  template: {
    id: "$uid",
    type: "junipProductRating",
    props: {},
  },
};

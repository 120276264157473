import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@replo/design-system/components/shadcn/core/card";
import type { ChartConfig } from "@replo/design-system/components/shadcn/core/chart";
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@replo/design-system/components/shadcn/core/chart";
import * as React from "react";
import { Label, Pie, PieChart } from "recharts";

const chartData = [
  { device: "desktop", visitors: 1678, fill: "#2563EB" },
  { device: "mobile", visitors: 789, fill: "#0891B2" },
  { device: "tablet", visitors: 345, fill: "#16A34A" },
];

const chartConfig = {
  visitors: {
    label: "Visitors",
  },
  desktop: {
    label: "Desktop",
    color: "#2563EB",
  },
  mobile: {
    label: "Mobile",
    color: "#0891B2",
  },
  tablet: {
    label: "Tablet",
    color: "#16A34A",
  },
} satisfies ChartConfig;

type ReploPieChartProps = {
  title?: string;
  skeleton?: React.ReactNode;
};

const ReploPieChart: React.FC<ReploPieChartProps> = ({ title, skeleton }) => {
  const totalVisitors = React.useMemo(() => {
    return chartData.reduce((acc, curr) => acc + curr.visitors, 0);
  }, []);

  return (
    <Card className="flex flex-col">
      <CardHeader className="pb-0">
        <CardTitle>{skeleton ?? title}</CardTitle>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square min-h-[21rem]"
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Pie
              data={chartData}
              dataKey="visitors"
              nameKey="device"
              innerRadius={80}
              strokeWidth={5}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-3xl font-bold"
                        >
                          {skeleton ?? totalVisitors.toLocaleString()}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          {skeleton ?? "Total Visitors"}
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
            <ChartLegend content={<ChartLegendContent />} />
          </PieChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};

export default ReploPieChart;

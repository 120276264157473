import ShopifyIntegrationCard from "@editor/components/dashboard/integrations/ShopifyIntegrationCard";
import useGetWorkspaceUtils from "@editor/hooks/useGetWorkspaceUtils";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import { Button } from "@replo/design-system/components/button";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import * as React from "react";
import { BsArrowLeft, BsArrowUpRight } from "react-icons/bs";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { SHOPIFY_APP_LISTING_URL } from "replo-runtime/shared/config";

const ShopifyIntegrationSettings = () => {
  const { workspaceId } = useParams();
  const modal = useModal();
  const navigate = useNavigate();
  const analytics = useLogAnalytics();
  const { data: integrationsData } =
    trpc.integration.getWorkspaceShopifyIntegrationData.useQuery(
      workspaceId ? { workspaceId } : reactQuerySkipToken,
    );

  const { setLatestWorkspaceIdOnCookies } = useGetWorkspaceUtils();

  async function handleAddShopifyIntegration() {
    const canAddShopifyIntegration =
      await trpcUtils.workspace.canAddShopifyIntegration.fetch(workspaceId!);
    if (!canAddShopifyIntegration.hasCapacity) {
      if (canAddShopifyIntegration.reason === "shopifyWorkspace") {
        modal.openModal({
          type: "fullPageErrorModal",
          props: {
            details: {
              header: "Cannot add more Shopify Integrations",
              message:
                "This workspace is billed through Shopify, so it cannot add more Shopify integrations. To learn more about how to change your billing method, please contact support@replo.app.",
              callToAction: {
                type: "link",
                name: "Learn More",
                to: "https://replo.help.usepylon.com/articles/7579785312-how-do-i-migrate-to-payment-method-to-stripe",
                target: "_blank",
              },
            },
          },
        });
      } else {
        modal.openModal({
          type: "billingModal",
          props: {
            source: "integrationHub.shopify",
            billingPlanMessageKey:
              "billingPlan.entitlements.insufficient.integrations.shopify",
          },
        });
      }
      return;
    }
    analytics("integration.shopify.add.initiate", {});
    analytics("shopify.connect", {
      source: "integrationHub",
    });

    if (workspaceId) {
      setLatestWorkspaceIdOnCookies(workspaceId);
    }
    window.location.href = SHOPIFY_APP_LISTING_URL;
  }
  if (workspaceId && integrationsData) {
    const { integrations } = integrationsData;
    return (
      <div className="flex flex-col px-6 w-full max-w-screen-xl">
        <button
          className="flex items-center gap-1 font-medium text-sm text-blue-600 mb-3"
          onClick={() =>
            navigate(
              generatePath("/workspace/:workspaceId/integrations", {
                workspaceId,
              }),
            )
          }
        >
          <BsArrowLeft size={18} />
          Back to Integrations
        </button>
        <div className="flex items-center mb-3">
          <img
            src="/images/integration-logos/shopify.png"
            className="w-9 h-9 mr-2"
          />
          <h2 className="text-3xl font-bold mr-auto">Shopify</h2>
          <Button variant="primary" onClick={handleAddShopifyIntegration}>
            Add Shopify Integration
          </Button>
        </div>
        <a
          className="flex items-center gap-1 font-medium text-sm text-blue-600 py-2 mr-auto"
          href="https://support.replo.app/articles/1713040192-integrating-replo-with-your-shopify-store"
        >
          Learn More
          <BsArrowUpRight size={18} />
        </a>
        <div className="my-8 w-[600px]">
          <h3 className="text-base font-semibold text-slate-600 mb-4">
            Added Integrations
          </h3>
          {integrations.length > 0 ? (
            <div>
              {integrations.map((integration, index) => (
                <ShopifyIntegrationCard
                  key={integration.id}
                  number={index + 1}
                  integration={integration}
                />
              ))}
            </div>
          ) : (
            <p className="bg-slate-50 p-4 rounded-lg inline-block text-base medium">
              To add a Shopify integration, select the button in the upper right
            </p>
          )}
        </div>
      </div>
    );
  }
};

export default ShopifyIntegrationSettings;

import {
  leftBarWidth,
  rightBarWidth,
} from "@editor/components/editor/constants";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import type { EditorRootState } from "@editor/store";
import { sum } from "@editor/utils/array";
import { getEditorComponentNode } from "@editor/utils/component";
import {
  processHtmlForCanvas,
  processHtmlForCanvasWithoutThemeScripts,
} from "@editor/utils/html";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import mapValues from "lodash-es/mapValues";
import pickBy from "lodash-es/pickBy";
import { shallowEqual } from "react-redux";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import { isFunction } from "replo-utils/lib/type-check";

import {
  CANVAS_DATA,
  CANVAS_FRAME_GAP,
  CANVAS_HORIZONTAL_SCROLL_TOLERANCE,
  CANVAS_NAVBAR_VERTICAL_SPACING,
  CANVAS_VERTICAL_SCROLL_TOLERANCE,
  DEFAULT_DELTA_Y,
  DEFAULT_FRAME_HEIGHT,
  INITIAL_CANVAS_HEIGHT,
  INITIAL_CANVAS_SCALE,
  INITIAL_CANVAS_WILL_CHANGE,
} from "./canvas-constants";
import type {
  Canvases,
  CanvasInteractionMode,
  CanvasLoadingType,
  CanvasState,
  IndividualCanvasState,
  PartialCanvases,
  PersistedCanvasState,
  SetActiveCanvasSource,
  SetDeltaXYPayload,
  WritableDraft,
} from "./canvas-types";

const initialState: CanvasState = {
  srcDoc: "",
  scale: INITIAL_CANVAS_SCALE,
  interactionMode: "edit",
  deltaX: 50,
  deltaY: 0,
  loadingType: null,
  activeCanvas: "desktop",
  previewWidth: CANVAS_DATA.desktop.defaultFrameWidth,
  paintVersion: 0,
  canvasArea: null,
  willChange: INITIAL_CANVAS_WILL_CHANGE,
  canvases: {
    desktop: {
      canvasHeight: INITIAL_CANVAS_HEIGHT,
      canvasWidth: CANVAS_DATA.desktop.defaultFrameWidth,
      isVisible: true,
      targetFrame: null,
    },
    tablet: {
      canvasHeight: INITIAL_CANVAS_HEIGHT,
      canvasWidth: CANVAS_DATA.tablet.defaultFrameWidth,
      isVisible: false,
      targetFrame: null,
    },
    mobile: {
      canvasHeight: INITIAL_CANVAS_HEIGHT,
      canvasWidth: CANVAS_DATA.mobile.defaultFrameWidth,
      isVisible: true,
      targetFrame: null,
    },
  },
};

const canvasSlice = createSlice({
  name: "canvas",
  initialState,
  reducers: {
    setTargetFrame: (
      state,
      action: PayloadAction<{
        canvas: EditorCanvas;
        targetFrame: HTMLIFrameElement;
      }>,
    ) => {
      const { canvas, targetFrame } = action.payload;
      if (!Object.is(targetFrame, state.canvases[canvas].targetFrame)) {
        state.canvases[canvas].targetFrame =
          // NOTE (Chance 2024-05-16) We have to cast here because of readonly
          // properties on the iframe element.
          targetFrame as unknown as WritableDraft<HTMLIFrameElement>;
      }
    },
    zoomCanvas: (state, action: PayloadAction<number>) => {
      state.scale = action.payload;
    },
    setCanvasInteractionMode: (
      state,
      action: PayloadAction<CanvasInteractionMode>,
    ) => {
      state.interactionMode = action.payload;
    },
    setDeltaXY: (state, action: PayloadAction<SetDeltaXYPayload>) => {
      handleSetDeltaXY(state, action.payload);
    },
    setCanvasArea: (state, action: PayloadAction<HTMLDivElement | null>) => {
      if (!action.payload) {
        return;
      }
      // NOTE (Martin, 2024-08-15) We have to cast here because of readonly
      // properties on the HTMLDivElement element.
      state.canvasArea =
        action.payload as unknown as WritableDraft<HTMLDivElement>;
    },
    resetFrameYPosition: (state) => {
      handleResetFrameYPosition(state);
    },
    resetFrameXPosition: (
      state,
      action: PayloadAction<{ isRightBarVisible: boolean }>,
    ) => {
      handleResetFrameXPosition(state, action.payload);
    },
    setCanvasHeight: (
      state,
      action: PayloadAction<{
        canvas: EditorCanvas;
        height: number | ((canvasHeight: number) => number);
      }>,
    ) => {
      const { canvas, height: heightAction } = action.payload;
      const payload = isFunction(heightAction)
        ? heightAction(state.canvases[canvas].canvasHeight)
        : heightAction;
      if (state.canvases[canvas].canvasHeight === payload) {
        return;
      }
      state.canvases[canvas].canvasHeight = payload;
    },
    setCanvasWidth: (
      state,
      action: PayloadAction<{
        canvas: EditorCanvas;
        width: number | ((canvasWidth: number) => number);
      }>,
    ) => {
      const { canvas, width: widthAction } = action.payload;
      const payload = isFunction(widthAction)
        ? widthAction(state.canvases[canvas].canvasWidth)
        : widthAction;
      if (state.canvases[canvas].canvasWidth === payload) {
        return;
      }
      state.canvases[canvas].canvasWidth = payload;
    },
    setPreviewWidth: (state, action: PayloadAction<number>) => {
      state.previewWidth = action.payload;
    },
    setLoadingType: (
      state,
      action: PayloadAction<CanvasLoadingType | null>,
    ) => {
      state.loadingType = action.payload;
    },
    setActiveCanvas: (
      state,
      action: PayloadAction<{
        canvas: EditorCanvas;
        width?: number;
        isRightBarVisible: boolean;
        source: SetActiveCanvasSource;
      }>,
    ) => {
      const { canvas, width, isRightBarVisible, source } = action.payload;

      state.activeCanvas = canvas;
      state.canvases[canvas].canvasWidth =
        width ??
        state.canvases[canvas].canvasWidth ??
        CANVAS_DATA[canvas].defaultFrameWidth;
      state.canvases[canvas].isVisible = true;

      // Note (Noah, 2024-07-19): In certain cases we want to reset the
      // scroll position of the frame when setting the active canvas,
      // for example when the user clicks the buttons in the toolbar, the
      // new active frame should be centered
      const shouldResetFramePositions = exhaustiveSwitch({
        type: source,
      })({
        toolbar: true,
        component: false,
        resize: true,
        frameHeader: true,
        deviceControls: true,
        ai: true,
        reset: true,
      });

      const shouldResetFramePositionsForSource = source === "reset";
      // Reset vertical position if the current one is too far away
      // TODO (Noah, 2024-07-19): this logic is not really sound, it's just
      // comparing to constants. What we should really do is see if the
      // new canvas height is smaller than the current offset and see if
      // we can reset the position to a "closer" part of the canvas in that case
      const shouldResetFramePositionsForDeltaY =
        Math.abs(state.deltaY) > DEFAULT_FRAME_HEIGHT + DEFAULT_DELTA_Y;

      if (
        shouldResetFramePositions &&
        (shouldResetFramePositionsForSource ||
          shouldResetFramePositionsForDeltaY)
      ) {
        handleResetFrameYPosition(state);
      }
      if (shouldResetFramePositions) {
        handleResetFrameXPosition(state, {
          isRightBarVisible,
        });
      }
    },
    showCanvas: (
      state,
      action: PayloadAction<
        EditorCanvas | { canvas: EditorCanvas; width: number }
      >,
    ) => {
      const isMultipleCanvasesEnabled = isFeatureEnabled("multiple-canvases");
      if (!isMultipleCanvasesEnabled) {
        return;
      }

      const { canvas, width } =
        typeof action.payload === "object"
          ? action.payload
          : {
              canvas: action.payload,
              width: state.canvases[action.payload].canvasWidth,
            };

      state.canvases[canvas].isVisible = true;
      state.canvases[canvas].canvasWidth = width;
    },
    hideCanvas: (state, action: PayloadAction<EditorCanvas>) => {
      const isMultipleCanvasesEnabled = isFeatureEnabled("multiple-canvases");
      if (!isMultipleCanvasesEnabled) {
        return;
      }

      const canvas = action.payload;
      if (!state.canvases[canvas].isVisible) {
        // noop
        return;
      }

      state.canvases[canvas].isVisible = false;
      return ensureVisibleActiveCanvas(state);
    },
    setStateFromLocalStorage: (
      state,
      action: PayloadAction<PersistedCanvasState>,
    ) => {
      for (const [key, canvas] of Object.entries(action.payload)) {
        state.canvases[key as EditorCanvas].isVisible = canvas.isVisible;
        state.canvases[key as EditorCanvas].canvasWidth = canvas.canvasWidth;
      }
      return ensureVisibleActiveCanvas(state);
    },
    setCanvasHtml: (state, action: PayloadAction<string>) => {
      state.srcDoc = action.payload;
    },
    setWillChangeStatus: (
      state,
      action: PayloadAction<"auto" | "transform">,
    ) => {
      state.willChange.status = action.payload;
    },
    setWillChangeTimeoutId: (state, action: PayloadAction<number | null>) => {
      state.willChange.timeoutId = action.payload;
    },
    scrollToComponentId: (state, action: PayloadAction<string>) => {
      const targetFrame = state.canvases[state.activeCanvas].targetFrame;
      if (!targetFrame?.contentDocument) {
        return;
      }
      const componentElement = getEditorComponentNode(
        // @ts-ignore
        targetFrame.contentDocument,
        null,
        action.payload,
      );
      if (!componentElement) {
        return;
      }

      // NOTE (Gabe 2024-07-18): If the componentElement has no offSetParent and
      // is not fixed then we shouldn't attempt to scroll to it. If it is fixed,
      // because we're not in preview mode, we do need to scroll to it.
      const componentElementStyle =
        targetFrame.contentWindow?.getComputedStyle(componentElement);
      if (
        !componentElement.offsetParent &&
        componentElementStyle?.position !== "fixed"
      ) {
        return;
      }

      // NOTE (Gabe 2024-07-16): The Header is 60px tall, so we need to subtract
      // that from the viewport height to get the height of the canvas.
      const heightOfCanvasViewport = window.innerHeight - 60;
      // NOTE (Gabe 2024-07-16): These have been converted to screen coordinates
      // so that we can compare against the element position.
      const topOfCanvasWindow = (-1 * state.deltaY) / state.scale;
      const bottomOfCanvasWindow = topOfCanvasWindow + heightOfCanvasViewport;

      const topOfElement = componentElement.getBoundingClientRect().top;
      const bottomOfElement = componentElement.getBoundingClientRect().bottom;
      const centerOfElement = (topOfElement + bottomOfElement) / 2;

      if (
        topOfElement > bottomOfCanvasWindow ||
        bottomOfElement < topOfCanvasWindow
      ) {
        const centerOfCanvasViewportShouldBe =
          -1 * centerOfElement * state.scale;

        state.deltaY =
          centerOfCanvasViewportShouldBe +
          (heightOfCanvasViewport * state.scale) / 2;
      }
    },
    increasePaintVersion: (state) => {
      state.paintVersion += 1;
    },
  },
});

const { actions, reducer } = canvasSlice;

export const {
  setTargetFrame,
  zoomCanvas,
  setCanvasInteractionMode,
  setDeltaXY,
  setCanvasHeight,
  setCanvasWidth,
  setPreviewWidth,
  setLoadingType,
  setActiveCanvas,
  setCanvasHtml,
  setCanvasArea,
  setWillChangeStatus,
  setWillChangeTimeoutId,
  showCanvas,
  hideCanvas,
  setStateFromLocalStorage,
  scrollToComponentId,
  resetFrameXPosition,
  resetFrameYPosition,
  increasePaintVersion,
} = actions;

export default reducer;

// #region Basic selectors
export const selectCanvases = (state: EditorRootState) => state.canvas.canvases;
export const selectPreviewWidth = (state: EditorRootState) =>
  state.canvas.previewWidth;
export const selectActiveCanvas = (state: EditorRootState) =>
  state.canvas.activeCanvas;
export const selectCanvas = (state: EditorRootState) => state.canvas;
export const selectCanvasScale = (state: EditorRootState) => state.canvas.scale;
export const selectCanvasInteractionMode = (state: EditorRootState) =>
  state.canvas.interactionMode;
export const selectCanvasDeltaX = (state: EditorRootState) =>
  state.canvas.deltaX;
export const selectCanvasDeltaY = (state: EditorRootState) =>
  state.canvas.deltaY;
export const selectCanvasRawHtml = (state: EditorRootState) =>
  state.canvas.srcDoc;
export const selectCanvasArea = (state: EditorRootState) =>
  state.canvas.canvasArea;

export const selectPrimaryCanvasHeight = (state: EditorRootState) =>
  state.canvas.canvases.desktop.canvasHeight;
export const selectCanvasWillChangeStatus = (state: EditorRootState) =>
  state.canvas.willChange.status;
export const selectCanvasWillChangeTimeoutId = (state: EditorRootState) =>
  state.canvas.willChange.timeoutId;
export const selectCanvasLoadingType = (state: EditorRootState) =>
  state.canvas.loadingType;
export const selectCanvasIsLoading = (state: EditorRootState) =>
  ["fetchingContent", "initiallyPainting"].includes(
    state.canvas.loadingType ?? "",
  );
export const selectPaintVersion = (state: EditorRootState) =>
  state.canvas.paintVersion;
// #endregion

// #region Memoized selectors
export const selectCanvasesKeys = createSelector(
  selectCanvases,
  (canvases) => {
    return Object.keys(canvases);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
);

export const selectCanvasHtml = createSelector(
  selectCanvasRawHtml,
  (rawHTML) => {
    if (rawHTML) {
      return isFeatureEnabled("no-theme-in-editor")
        ? processHtmlForCanvasWithoutThemeScripts({ rawHTML })
        : processHtmlForCanvas({ rawHTML });
    }
    return null;
  },
);

export const selectCanvasDeltaXY = createSelector(
  selectCanvasDeltaX,
  selectCanvasDeltaY,
  (deltaX, deltaY) => {
    return {
      deltaX,
      deltaY,
    };
  },
);

export const selectVisibleCanvases = createSelector(
  (state: EditorRootState) => state.core.isPreviewMode,
  selectCanvases,
  (isPreviewMode, canvases) => {
    return getVisibleCanvases(canvases, isPreviewMode);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
);

export const selectVisibleCanvasesHeights = createSelector(
  selectVisibleCanvases,
  (canvases) => {
    return mapValues(canvases, (canvas) => canvas?.canvasHeight ?? 0);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
);

export const selectVisibleCanvasesTotalWidth = createSelector(
  selectVisibleCanvases,
  (canvases) => {
    return sum([
      ...Object.values(canvases).map((canvas) => canvas.canvasWidth),
      CANVAS_FRAME_GAP * (Object.keys(canvases).length - 1),
    ]);
  },
);

export const selectLargestVisibleCanvasHeight = createSelector(
  selectVisibleCanvasesHeights,
  (canvasHeights) => {
    return Math.max(
      ...Object.values(canvasHeights).map((height) => height ?? 0),
    );
  },
);

export const selectActiveCanvasFrame = createSelector(
  selectCanvases,
  selectActiveCanvas,
  (canvases, activeCanvas) => {
    return canvases[activeCanvas].targetFrame;
  },
);

export const selectActiveCanvasWidth = createSelector(
  selectCanvases,
  selectActiveCanvas,
  (canvases, activeCanvas) => {
    return canvases[activeCanvas].canvasWidth;
  },
);

export const selectCanvasesIFrames = createSelector(
  selectCanvases,
  (canvases) => {
    return mapValues(canvases, (canvas) => canvas.targetFrame);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
);

export const selectCanvasIFrame = createSelector(
  selectCanvasesIFrames,
  (_: EditorRootState, canvas: EditorCanvas) => canvas,
  (iframes, canvas) => {
    return iframes[canvas];
  },
);
// #endregion

// #region Helper functions
function handleResetFrameXPosition(
  state: WritableDraft<CanvasState>,
  payload: { isRightBarVisible: boolean },
) {
  const xOffset = getCanvasXOffset(state);
  const rightBarContribution = payload.isRightBarVisible ? rightBarWidth : 0;
  const activeCanvasWidth = state.canvases[state.activeCanvas].canvasWidth;
  const canvasScale = state.scale;
  const deltaX =
    (window.innerWidth - leftBarWidth - rightBarContribution) / 2 -
    (activeCanvasWidth * canvasScale) / 2 -
    xOffset * canvasScale;
  handleSetDeltaXY(state, {
    deltaX,
    frameWidth: state.canvases[state.activeCanvas].canvasWidth,
  });
}

function handleSetDeltaXY(
  state: WritableDraft<CanvasState>,
  payload: SetDeltaXYPayload,
) {
  const { frameWidth, deltaX, deltaY } = isFunction(payload)
    ? payload({ deltaX: state.deltaX, deltaY: state.deltaY })
    : payload;

  const visibleCanvases = getVisibleCanvases(state.canvases, false);
  const totalWidth = isFeatureEnabled("multiple-canvases")
    ? Object.values(visibleCanvases).reduce((total, canvas) => {
        return total + canvas.canvasWidth;
      }, 0) +
      CANVAS_FRAME_GAP * (Object.keys(visibleCanvases).length - 1)
    : frameWidth;

  if (
    deltaY != null &&
    !shouldPreventZoomOrScrollY(
      Math.max(
        ...Object.values(visibleCanvases).map(
          (canvas) => canvas.canvasHeight ?? 0,
        ),
      ),
      deltaY,
      state.scale,
    )
  ) {
    state.deltaY = deltaY;
  }

  if (
    deltaX != null &&
    !shouldPreventZoomOrScrollX(totalWidth, deltaX, state.scale)
  ) {
    state.deltaX = deltaX;
  }
}

function getCanvasXOffset(state: WritableDraft<CanvasState>) {
  if (!isFeatureEnabled("multiple-canvases")) {
    return 0;
  }

  let xOffset = 0;
  for (const [key, canvas] of Object.entries(
    getVisibleCanvases(state.canvases, false),
  )) {
    if (key === state.activeCanvas) {
      break;
    }
    xOffset += canvas.canvasWidth + CANVAS_FRAME_GAP;
  }
  return xOffset;
}

function getVisibleCanvases(
  canvases: Canvases,
  isPreviewMode: boolean,
): PartialCanvases {
  return pickBy(canvases, (canvas: IndividualCanvasState, key) => {
    return !isFeatureEnabled("multiple-canvases") || isPreviewMode
      ? key === "desktop"
      : canvas.isVisible;
  });
}

function handleResetFrameYPosition(state: WritableDraft<CanvasState>) {
  const isMultipleCanvasesEnabled = isFeatureEnabled("multiple-canvases");
  handleSetDeltaXY(state, {
    deltaY:
      DEFAULT_DELTA_Y +
      (isMultipleCanvasesEnabled ? CANVAS_NAVBAR_VERTICAL_SPACING : 0),
    frameWidth: state.canvases[state.activeCanvas].canvasWidth,
  });
}

function shouldPreventZoomOrScrollX(
  totalCanvasesWidth: number,
  deltaX: number,
  scale: number,
) {
  const actualWidth = totalCanvasesWidth * scale;
  const limitLeft =
    window.innerWidth - CANVAS_HORIZONTAL_SCROLL_TOLERANCE - leftBarWidth;
  const limitRight = -(actualWidth - CANVAS_HORIZONTAL_SCROLL_TOLERANCE);
  return deltaX > limitLeft || deltaX < limitRight;
}

function shouldPreventZoomOrScrollY(
  canvasHeight: number,
  deltaY: number,
  scale: number,
) {
  const actualHeight = canvasHeight * scale;
  const limitTop = window.innerHeight - CANVAS_VERTICAL_SCROLL_TOLERANCE;
  const limitBottom = -(actualHeight - CANVAS_HORIZONTAL_SCROLL_TOLERANCE);

  return deltaY > limitTop || deltaY < limitBottom;
}

function ensureVisibleActiveCanvas<
  State extends CanvasState | WritableDraft<CanvasState>,
>(state: State): State {
  let nextActiveCanvas: EditorCanvas | undefined;
  const isActiveCanvasHidden = !state.canvases[state.activeCanvas].isVisible;
  if (isActiveCanvasHidden) {
    // NOTE (Chance 2024-06-12): Unsetting the current canvas, so we should
    // tee-up another visible canvas to be the active one after this state
    // update. This is intentionally verbose so that it's deterministic and
    // ordered by a given priority.
    if (state.canvases.desktop.isVisible) {
      nextActiveCanvas = "desktop";
    } else if (state.canvases.tablet.isVisible) {
      nextActiveCanvas = "tablet";
    } else if (state.canvases.mobile.isVisible) {
      nextActiveCanvas = "mobile";
    } else {
      // If somehow no canvases are visible, force the desktop canvas to be
      // visible. All canvases should never be hidden.
      nextActiveCanvas = "desktop";
      state.canvases.desktop.isVisible = true;
    }
  }
  if (nextActiveCanvas) {
    state.activeCanvas = nextActiveCanvas;
  }
  return state;
}
// #endregion

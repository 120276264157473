import { setDraftElement } from "@editor/actions/core-actions";
import { getEditorComponentNode } from "@utils/component";
import type { Component } from "replo-runtime/shared/Component";
import {
  findComponentByTypeDepthFirst,
  getChildren,
} from "replo-runtime/shared/utils/component";
import { isItemsDynamic } from "replo-runtime/store/utils/items";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

export const controlOnChange = (
  targetDocument: Document | null,
  draftElementId: string,
  component: Component,
  dispatch: any,
  value: any,
) => {
  return exhaustiveSwitch(component)({
    button: null,
    buyWithPrimeButton: null,
    carousel: null,
    carouselV2: null,
    carouselV2__indicator: null,
    carouselV2__panels: null,
    carouselPanelsCount: null,
    carouselV3: () => {
      const slideListComponent = findComponentByTypeDepthFirst(
        component,
        "carouselV3Slides",
      );

      const carouselHasDynamicData = isItemsDynamic(component?.props._items);

      if (!slideListComponent) {
        return;
      }

      const slideListComponentNode = targetDocument
        ? getEditorComponentNode(
            targetDocument,
            draftElementId,
            slideListComponent?.id,
          )
        : null;

      const repeatedIndex = slideListComponentNode?.getAttribute(
        "data-replo-repeated-index",
      );

      const slideChildren = getChildren(slideListComponent);

      const componentId = carouselHasDynamicData
        ? slideChildren[0]?.id
        : slideChildren[value]?.id;

      dispatch(
        setDraftElement({
          componentId,
          // Note (Ovishek, 2023-02-03): Here we need an extra .0 added in the middle of repeated index b/c
          // there is always ReploComponent in between two store component render, like SlidesComponent calls ->
          // ReploComponent calls -> Side 1 Component, and we are always adding and extra ".0" in
          // ReploComponent
          repeatedIndex: repeatedIndex ? `${repeatedIndex}.0.${value}` : null,
          selectedIds: componentId ? [componentId] : [],
        }),
      );
    },
    carouselV3Control: null,
    carouselV3Indicators: null,
    carouselV3Slides: null,
    circle: null,
    collapsible: null,
    collapsibleV2: null,
    collapsibleV2Header: null,
    collapsibleV2Content: null,
    collection: null,
    collectionSelect: null,
    collectionV2: null,
    container: null,
    countdownTimer: null,
    dropdown: null,
    googleMapsEmbed: null,
    spinner: null,
    h1: null,
    h2: null,
    h3: null,
    icon: null,
    image: null,
    klaviyoEmbed: null,
    marquee: null,
    modal: null,
    optionSelect: null,
    optionSelectDropdown: null,
    player: null,
    player__fullScreenIcon: null,
    player__muteIcon: null,
    player__playIcon: null,
    product: null,
    productCollection: null,
    quantitySelector: null,
    rawHtmlContent: null,
    rechargeSubscriptionWidget: null,
    staySubscriptionWidget: null,
    shopifyRawLiquid: null,
    shopifyAppBlocks: null,
    shopifySection: null,
    slidingCarousel: null,
    spacer: null,
    starRating: null,
    symbolRef: null,
    tabsBlock: null,
    tabsV2__block: null,
    tabsV2__list: null,
    tabsV2__panelsContent: null,
    tabs__list: null,
    tabs__onePanelContent: null,
    tabs__panelsContent: null,
    temporaryCart: null,
    temporaryCartItems: null,
    text: null,
    tikTokEmbed: null,
    toggleContainer: null,
    toggleIndicator: null,
    tooltip: null,
    tooltipContent: null,
    variantSelect: null,
    variantSelectDropdown: null,
    sellingPlanSelect: null,
    vimeoEmbed: null,
    vimeoEmbedV2: null,
    youtubeEmbed: null,
    youtubeEmbedV2: null,
    okendoProductRatingSummary: null,
    okendoReviewsWidget: null,
    junipProductRating: null,
    junipReviews: null,
    yotpoProductRating: null,
    yotpoReviews: null,
    looxProductRating: null,
    looxReviews: null,
    knoCommerceWidget: null,
    reviewsIoProductRating: null,
    reviewsIoReviews: null,
    dynamicCheckoutButtons: null,
    sellingPlanSelectDropdown: null,
    accordionBlock: null,
    rebuyWidget: null,
    stampedProductReviewsWidget: null,
    stampedProductRatingWidget: null,
    feraProductRatingWidget: null,
    feraProductReviewsWidget: null,
    feraStoreReviewsWidget: null,
    feraMediaGalleryWidget: null,
    shopifyProductReviewsWidget: null,
    shopifyProductRatingWidget: null,
    judgeProductRatingWidget: null,
    judgeProductReviewsWidget: null,
    infiniteOptionsWidget: null,
    postscriptSignupForm: null,
    subscribeAndSave: null,
    beforeAfterSlider: null,
    beforeAfterSliderThumb: null,
    beforeAfterSliderBeforeContent: null,
    beforeAfterSliderAfterContent: null,
    selectionList: null,
  });
};

export const getSharedStateKey = (
  component: Component,
  id: string,
  config: {
    hasAccordionAncestor?: boolean;
    accordionId?: string;
  },
) => {
  return exhaustiveSwitch(component)({
    button: `${component.id}.${id}`,
    carousel: `${component.id}.${id}`,
    carouselV2: `${component.id}.${id}`,
    carouselV2__indicator: `${component.id}.${id}`,
    carouselV2__panels: `${component.id}.${id}`,
    carouselV3: `${component.id}.${id}`,
    carouselV3Control: `${component.id}.${id}`,
    carouselV3Indicators: `${component.id}.${id}`,
    carouselV3Slides: `${component.id}.${id}`,
    carouselPanelsCount: `${component.id}.${id}`,
    circle: `${component.id}.${id}`,
    collapsible: `${component.id}.${id}`,
    collapsibleV2: () => {
      if (config.hasAccordionAncestor) {
        return `${config.accordionId}.accordionOpenItems`;
      }
      return `${component.id}.${id}`;
    },
    collapsibleV2Header: `${component.id}.${id}`,
    collapsibleV2Content: `${component.id}.${id}`,
    collection: `${component.id}.${id}`,
    collectionSelect: `${component.id}.${id}`,
    collectionV2: `${component.id}.${id}`,
    container: `${component.id}.${id}`,
    countdownTimer: `${component.id}.${id}`,
    dropdown: `${component.id}.${id}`,
    googleMapsEmbed: `${component.id}.${id}`,
    spinner: `${component.id}.${id}`,
    h1: `${component.id}.${id}`,
    h2: `${component.id}.${id}`,
    h3: `${component.id}.${id}`,
    icon: `${component.id}.${id}`,
    image: `${component.id}.${id}`,
    klaviyoEmbed: `${component.id}.${id}`,
    marquee: `${component.id}.${id}`,
    modal: `${component.id}.${id}`,
    optionSelect: `${component.id}.${id}`,
    optionSelectDropdown: `${component.id}.${id}`,
    player: `${component.id}.${id}`,
    player__fullScreenIcon: `${component.id}.${id}`,
    player__muteIcon: `${component.id}.${id}`,
    player__playIcon: `${component.id}.${id}`,
    product: `${component.id}.${id}`,
    productCollection: `${component.id}.${id}`,
    quantitySelector: `${component.id}.${id}`,
    rawHtmlContent: `${component.id}.${id}`,
    rechargeSubscriptionWidget: `${component.id}.${id}`,
    staySubscriptionWidget: `${component.id}.${id}`,
    shopifyRawLiquid: `${component.id}.${id}`,
    shopifyAppBlocks: `${component.id}.${id}`,
    shopifySection: `${component.id}.${id}`,
    slidingCarousel: `${component.id}.${id}`,
    spacer: `${component.id}.${id}`,
    starRating: `${component.id}.${id}`,
    symbolRef: `${component.id}.${id}`,
    tabsBlock: `${component.id}.${id}`,
    tabsV2__block: `${component.id}.${id}`,
    tabsV2__list: `${component.id}.${id}`,
    tabsV2__panelsContent: `${component.id}.${id}`,
    tabs__list: `${component.id}.${id}`,
    tabs__onePanelContent: `${component.id}.${id}`,
    tabs__panelsContent: `${component.id}.${id}`,
    temporaryCart: `${component.id}.${id}`,
    temporaryCartItems: `${component.id}.${id}`,
    text: `${component.id}.${id}`,
    tikTokEmbed: `${component.id}.${id}`,
    toggleContainer: `${component.id}.${id}`,
    toggleIndicator: `${component.id}.${id}`,
    tooltip: `${component.id}.${id}`,
    tooltipContent: `${component.id}.${id}`,
    variantSelect: `${component.id}.${id}`,
    variantSelectDropdown: `${component.id}.${id}`,
    sellingPlanSelect: `${component.id}.${id}`,
    vimeoEmbed: `${component.id}.${id}`,
    vimeoEmbedV2: `${component.id}.${id}`,
    youtubeEmbed: `${component.id}.${id}`,
    youtubeEmbedV2: `${component.id}.${id}`,
    okendoProductRatingSummary: `${component.id}.${id}`,
    okendoReviewsWidget: `${component.id}.${id}`,
    junipProductRating: `${component.id}.${id}`,
    junipReviews: `${component.id}.${id}`,
    yotpoProductRating: `${component.id}.${id}`,
    yotpoReviews: `${component.id}.${id}`,
    looxProductRating: `${component.id}.${id}`,
    looxReviews: `${component.id}.${id}`,
    knoCommerceWidget: `${component.id}.${id}`,
    reviewsIoProductRating: `${component.id}.${id}`,
    reviewsIoReviews: `${component.id}.${id}`,
    dynamicCheckoutButtons: `${component.id}.${id}`,
    sellingPlanSelectDropdown: `${component.id}.${id}`,
    accordionBlock: `${component.id}.${id}`,
    rebuyWidget: `${component.id}.${id}`,
    buyWithPrimeButton: `${component.id}.${id}`,
    stampedProductReviewsWidget: `${component.id}.${id}`,
    stampedProductRatingWidget: `${component.id}.${id}`,
    feraProductRatingWidget: `${component.id}.${id}`,
    feraProductReviewsWidget: `${component.id}.${id}`,
    feraStoreReviewsWidget: `${component.id}.${id}`,
    feraMediaGalleryWidget: `${component.id}.${id}`,
    shopifyProductReviewsWidget: `${component.id}.${id}`,
    shopifyProductRatingWidget: `${component.id}.${id}`,
    judgeProductRatingWidget: `${component.id}.${id}`,
    judgeProductReviewsWidget: `${component.id}.${id}`,
    infiniteOptionsWidget: `${component.id}.${id}`,
    postscriptSignupForm: `${component.id}.${id}`,
    subscribeAndSave: `${component.id}.${id}`,
    beforeAfterSlider: `${component.id}.${id}`,
    beforeAfterSliderThumb: `${component.id}.${id}`,
    beforeAfterSliderBeforeContent: `${component.id}.${id}`,
    beforeAfterSliderAfterContent: `${component.id}.${id}`,
    selectionList: `${component.id}.${id}`,
  });
};

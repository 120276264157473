import type { Component } from "replo-runtime/shared/Component";
import { z } from "zod";

export const ReploComponentTypes = [
  "container",
  "symbolRef",
  "text",
  "button",
  "spacer",
  "circle",
  "icon",
  "carousel",
  "modal",
  "image",
  "accordionBlock",
  "subscribeAndSave",
  "collapsible",
  "slidingCarousel",
  "player",
  "player__playIcon",
  "player__muteIcon",
  "player__fullScreenIcon",
  "collectionSelect",
  "product",
  "productCollection",
  "quantitySelector",
  "dropdown",
  "variantSelect",
  "optionSelect",
  "variantSelectDropdown",
  "optionSelectDropdown",
  "sellingPlanSelect",
  "sellingPlanSelectDropdown",
  "collection",
  "collectionV2",
  "googleMapsEmbed",
  "klaviyoEmbed",
  "temporaryCart",
  "temporaryCartItems",
  "vimeoEmbed",
  "vimeoEmbedV2",
  "rebuyWidget",
  "buyWithPrimeButton",
  "youtubeEmbed",
  "youtubeEmbedV2",
  "carouselV2",
  "carouselV2__panels",
  "carouselV2__indicator",
  "carouselV3",
  "carouselV3Slides",
  "carouselV3Control",
  "carouselV3Indicators",
  "carouselPanelsCount",
  "shopifySection",
  "shopifyAppBlocks",
  "shopifyRawLiquid",
  "collapsibleV2",
  "collapsibleV2Header",
  "collapsibleV2Content",
  "tabsBlock",
  "tabs__list",
  "tabs__panelsContent",
  "tabs__onePanelContent",
  "tabsV2__block",
  "tabsV2__list",
  "tabsV2__panelsContent",
  "marquee",
  "rawHtmlContent",
  "starRating",
  "tikTokEmbed",
  "rechargeSubscriptionWidget",
  "staySubscriptionWidget",
  "okendoReviewsWidget",
  "okendoProductRatingSummary",
  "junipProductRating",
  "junipReviews",
  "yotpoProductRating",
  "yotpoReviews",
  "looxProductRating",
  "looxReviews",
  "reviewsIoProductRating",
  "reviewsIoReviews",
  "h1",
  "h2",
  "h3",
  "spinner",
  "dynamicCheckoutButtons",
  "countdownTimer",
  "judgeProductRatingWidget",
  "judgeProductReviewsWidget",
  "feraProductRatingWidget",
  "feraProductReviewsWidget",
  "feraStoreReviewsWidget",
  "feraMediaGalleryWidget",
  "shopifyProductReviewsWidget",
  "shopifyProductRatingWidget",
  "stampedProductReviewsWidget",
  "stampedProductRatingWidget",
  "knoCommerceWidget",
  "infiniteOptionsWidget",
  "postscriptSignupForm",
  "staySubscriptionWidget",
  "toggleContainer",
  "toggleIndicator",
  "tooltip",
  "tooltipContent",
  "beforeAfterSlider",
  "beforeAfterSliderThumb",
  "beforeAfterSliderBeforeContent",
  "beforeAfterSliderAfterContent",
  "selectionList",
] as const;

export type ReploComponentType = (typeof ReploComponentTypes)[number];

const reploComponentPropsStylesSchema = z
  .object({
    backgroundColor: z.string().nullish(),
    backgroundImage: z.string().nullish(),
    color: z.string().nullish(),
    __imageSource: z.string().nullish(),
    borderTopColor: z.string().nullish(),
    borderLeftColor: z.string().nullish(),
    borderBottomColor: z.string().nullish(),
    borderRightColor: z.string().nullish(),
    width: z.union([z.number(), z.string()]).nullish(),
  })
  .passthrough();

const reploComponentPropsSchema = z
  .object({
    style: reploComponentPropsStylesSchema.optional(),
    "style@md": reploComponentPropsStylesSchema.optional(),
    "style@sm": reploComponentPropsStylesSchema.optional(),
    text: z.string().optional(),
    src: z.string().optional(),
  })
  .catchall(z.any());

export const reploComponentSchemaBase = z.object({
  id: z.string(),
  name: z.string().optional(),
  type: z.enum(ReploComponentTypes),
  props: reploComponentPropsSchema,
  children: z.optional(z.array(z.any())),
  variants: z.any(),
  variantOverrides: z.any(),
});

export const reploComponentSchema = reploComponentSchemaBase
  .extend({})
  .superRefine((component, ctx) => {
    const flatComponents = reploComponentToFlatComponents(component);
    const result = reploFlatComponentArraySchema.safeParse(flatComponents);
    if (!result.success) {
      result.error.issues.forEach((issue) => ctx.addIssue(issue));
    }
  });

export type ReploComponent = Component;

// TODO: Add more specific schemas for components, we should also improve the schemas for the props and styles.
// We have the types Component and RuntimeStyleProperties in replo-runtime so we need to convert that to a schema.
// Type Component /replo-runtime/shared/Component.ts
// Type RuntimeStyleProperties /replo-runtime/shared/styleAttribute.ts

export const reploFlatComponentSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  type: z.enum(ReploComponentTypes),
  props: reploComponentPropsSchema,
  children: z.optional(z.array(z.string().uuid())),
});

export type ReploFlatComponent = {
  id: string;
  name?: string | undefined;
  type: ReploComponentType;
  props: Component["props"];
  children?: string[] | undefined;
};

type ReploComponentInner = {
  id: string;
  name?: string | undefined;
  type: ReploComponentType;
  props: Component["props"];
  children?: any[] | undefined;
};

export const reploFlatComponentArraySchema = z.array(reploFlatComponentSchema);

/**
 * Transform a deeply nested replo component to a flat replo component array.
 */
export function reploComponentToFlatComponents(
  component: Component,
): ReploFlatComponent[] {
  const components: Array<ReploFlatComponent> = [];
  const stack: Array<ReploComponentInner> = [component];
  while (stack.length > 0) {
    const currentComponent = stack.pop();
    if (currentComponent) {
      const flat: ReploFlatComponent = { ...currentComponent };
      if (currentComponent.children) {
        flat.children = currentComponent.children.map((child) => child.id);
      }
      components.push(flat);
      stack.push(...(currentComponent.children || []));
    }
  }
  return components;
}

import * as React from "react";

export const WorkspaceDashboardContext = React.createContext<{
  setWorkspaceId: (id: string | null) => void;
  workspaceId: string | null;
}>({
  setWorkspaceId: () => null,
  workspaceId: null,
});

export const useWorkspaceDashboardContext = () =>
  React.useContext(WorkspaceDashboardContext);

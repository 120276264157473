import Tooltip from "@common/designSystem/Tooltip";
import {
  selectDraftElementIsPublished,
  selectDraftElementPublishedAt,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import classNames from "classnames";
import { formatDistanceToNowStrict } from "date-fns";
import * as React from "react";
import { BsFillCircleFill } from "react-icons/bs";
import { twMerge } from "tailwind-merge";

const PublishedStatus = () => {
  const isPublished = useEditorSelector(selectDraftElementIsPublished);
  const publishedAt = useEditorSelector(selectDraftElementPublishedAt);

  const difference = publishedAt
    ? formatDistanceToNowStrict(new Date(publishedAt))
    : null;

  const label =
    isPublished && difference
      ? `Last Published ${difference} ago`
      : "Not Published";

  return (
    <div
      className={twMerge(
        classNames(
          "flex items-center gap-2 px-2 text-sm font-medium text-slate-400",
          {
            "text-green-400": isPublished,
          },
        ),
      )}
    >
      <Tooltip content={label} triggerAsChild>
        <span tabIndex={0}>
          <BsFillCircleFill size={12} />
        </span>
      </Tooltip>
    </div>
  );
};

export default PublishedStatus;

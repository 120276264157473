import * as React from "react";

import type { RenderComponentProps } from "../../../shared/types";
import { SharedJunipReviews } from "../SharedJunipReviews";

const JunipProductRating: React.FC<RenderComponentProps> = (props) => {
  const liquidSource = `
    <span
      class="junip-product-summary"
      data-product-id="{{ product.id }}"
      data-product-rating-count="{{ product.metafields.junip.rating_count }}"
      data-product-rating-average="{{ product.metafields.junip.rating_average }}">
    </span>
  `;

  return (
    <SharedJunipReviews
      attributes={props.componentAttributes}
      component={props.component}
      context={props.context}
      liquidSource={liquidSource}
      reviewsComponentType="Product Rating"
    />
  );
};

export default JunipProductRating;

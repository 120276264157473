import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { selectProjectId } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import useCurrentUser from "@hooks/useCurrentUser";
import isEmpty from "lodash-es/isEmpty";
import * as React from "react";
import { useParams } from "react-router-dom";
import { isNotNullish } from "replo-utils/lib/misc";

export function useCurrentProjectId() {
  const { user } = useCurrentUser();
  const { projectId: projectIdFromParams } = useParams();
  const projectIdFromState = useEditorSelector(selectProjectId);

  const projectId = projectIdFromParams ?? projectIdFromState;

  const { project, refetch } = useCurrentProjectContext();

  React.useEffect(() => {
    /** Fetch the project if the ID has changed or project doesn't exist yet */
    if (
      user?.id &&
      isNotNullish(projectId) &&
      (isEmpty(project) || (isNotNullish(project) && project?.id !== projectId))
    ) {
      void refetch();
    }
  }, [refetch, project, projectId, user?.id]);

  return project?.id === projectId ? projectId : undefined;
}

export default useCurrentProjectId;

import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { selectIsPreviewMode } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import * as React from "react";

import { CANVAS_DATA } from "./canvas-constants";
import {
  selectActiveCanvasWidth,
  selectCanvasDeltaXY,
  selectCanvasIsLoading,
  selectCanvasScale,
} from "./canvas-reducer";

/**
 * NOTE (Chance 2024-03-20): Previously we were assigning transform/width/height
 * values as inline styles. After doing a little research, I found an article
 * that showed some benchmarks indicating that setting CSS variables using
 * Element.style.setProperty performs quite a bit better than updating inline
 * styles directly. This sent me down a rabbit hole and in doing some benchmarks
 * of my own, I found that the fastest method seems to be using the style
 * attribute directly and assigning all of the values via a single style string.
 * my own testing.
 *
 * @see https://jsperf.app/vewuxa
 */
export function useUpdateFramePositionStyles(
  frameRef: React.RefObject<HTMLDivElement>,
  props: {
    width: number;
    height: number;
  },
) {
  const canvasScale = useEditorSelector(selectCanvasScale);
  const activeCanvasWidth = useEditorSelector(selectActiveCanvasWidth);
  const { deltaX, deltaY } = useEditorSelector(selectCanvasDeltaXY);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const canvasIsLoading = useEditorSelector(selectCanvasIsLoading);

  React.useEffect(() => {
    const frameElement = frameRef.current;
    if (!frameElement) {
      return;
    }

    let style: string;
    if (isPreviewMode) {
      const frameWidth = isFeatureEnabled("multiple-canvases")
        ? props.width
        : activeCanvasWidth;
      const innerWidth = window.innerWidth;
      let translate: string;
      if (innerWidth < frameWidth) {
        translate = "0px";
      } else {
        translate = `calc(50vw - ${Math.floor(frameWidth / 2)}px)`;
      }

      const width = `${frameWidth}px`;
      const transform = `translate3d(${translate}, 0, 0px)`;
      style = `height: 100%; width: ${width}; transform: ${transform}`;
    } else {
      const width = canvasIsLoading
        ? `${CANVAS_DATA.desktop.defaultFrameWidth}px`
        : `${props.width}px`;
      const height = `${props.height}px`;
      const transform = `translate3d(${deltaX}px, ${deltaY}px, 0px) scale(${canvasScale})`;
      style = `width: ${width}; height: ${height}; transform: ${transform}`;
    }

    // While technically `style` is a readonly property, you can still assign
    // to it with a string value. It overrides all styles so this could be
    // error prone if we update the styles elsewhere, so we just need to be
    // careful here.
    // @ts-expect-error
    frameElement.style = style;
  }, [
    props.height,
    props.width,
    canvasScale,
    activeCanvasWidth,
    deltaX,
    deltaY,
    isPreviewMode,
    frameRef,
    canvasIsLoading,
  ]);
}

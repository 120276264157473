import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const videoPlayer: ComponentTemplate = {
  id: "videoPlayer",
  env: Env.PRODUCTION,
  storeId: null,
  scope: "left-bar",
  type: "component",
  name: "Video/Video File",
  leftBarCategory: { main: "media", subCategory: "video" },
  tags: ["embed", "video"],
  preview: "video/video.svg",
  template: {
    id: "7b088a09-8bab-4d6a-ad01-70a75e5a8cf0",
    type: "player",
    props: {
      // @ts-ignore
      url: null,
      style: {
        width: "400px",
        overflow: "hidden",
        position: "relative",
        marginTop: "auto",
      },
      _autoplay: false,
      _defaultMuted: true,
    },
    children: [
      {
        id: "314a84ae-f884-4399-9b26-972d2ab432b5",
        type: "container",
        props: {
          style: {
            width: "100%",
            bottom: "0px",
            height: "36px",
            display: "flex",
            position: "absolute",
            __display: "flex",
            alignItems: "center",
            paddingLeft: "10px",
            paddingRight: "10px",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#ffffff",
          },
          "style@sm": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
        },
        children: [
          {
            id: "b19a6a1e-c1e6-4034-8c83-538025957c4f",
            type: "container",
            props: {
              style: {
                alignItems: "center",
                flexDirection: "row",
              },
            },
            children: [
              {
                id: "d5a434ad-c5ec-48b4-a1a1-1da56554ec0c",
                type: "player__playIcon",
                props: {
                  size: "24px",
                  style: {
                    color: "black",
                  },
                  "style@sm": {
                    paddingRight: "0px",
                  },
                },
              },
            ],
          },
          {
            id: "068012f9-561b-46c3-b28f-8a793197258b",
            type: "container",
            props: {
              style: {
                flexDirection: "row",
                __flexGap: "12px",
              },
            },
            children: [
              {
                id: "2a0759f3-50ea-48b1-a39f-6e961715977f",
                type: "player__muteIcon",
                props: {
                  size: "24px",
                  style: {
                    color: "black",
                  },
                },
              },
              {
                id: "d2163580-2ce2-476e-ac65-abb52c56034f",
                type: "player__fullScreenIcon",
                props: {
                  size: "24px",
                  style: {
                    color: "black",
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

import { shouldLogAnalytics } from "@editor/infra/analytics";
import { faro } from "@grafana/faro-react";
import * as React from "react";
import { createContext, useRequiredContext } from "replo-utils/react/context";

const EditorPerformanceContext = createContext<{
  setWasCandidateBoxJustClicked: () => void;
  leftBarElementRef: React.RefObject<HTMLDivElement>;
  rightBarElementRef: React.RefObject<HTMLDivElement>;
}>("EditorPerformanceContext", {
  setWasCandidateBoxJustClicked: () => {
    throw new Error("setIsCandidateBoxClicked not implemented");
  },
  leftBarElementRef: React.createRef<HTMLDivElement>(),
  rightBarElementRef: React.createRef<HTMLDivElement>(),
});

export function EditorPerformanceProvider({
  children,
}: React.PropsWithChildren) {
  const candidateBoxClickedRef = React.useRef<boolean>(false);
  const perfObserverRef = React.useRef<PerformanceObserver | null>(null);
  const leftBarElementRef = React.useRef<HTMLDivElement | null>(null);
  const rightBarElementRef = React.useRef<HTMLDivElement | null>(null);

  const perfObserver = React.useCallback(
    (list: PerformanceObserverEntryList) => {
      list.getEntries().forEach((entry) => {
        if (entry.name === "click") {
          if (candidateBoxClickedRef.current) {
            candidateBoxClickedRef.current = false;
            faro.api.pushMeasurement({
              type: "candidate_box_click",
              values: {
                execution_time_ms: entry.duration,
              },
            });
          } else if (
            leftBarElementRef.current?.contains(
              (entry as PerformanceEventTiming).target,
            )
          ) {
            faro.api.pushMeasurement({
              type: "left_bar_click",
              values: {
                execution_time_ms: entry.duration,
              },
            });
          } else if (
            rightBarElementRef.current?.contains(
              (entry as PerformanceEventTiming).target,
            )
          ) {
            faro.api.pushMeasurement({
              type: "right_bar_click",
              values: {
                execution_time_ms: entry.duration,
              },
            });
          }
        }
      });
    },
    [],
  );

  React.useEffect(() => {
    if (!shouldLogAnalytics()) {
      return;
    }

    if (perfObserverRef.current) {
      perfObserverRef.current.disconnect();
    }

    perfObserverRef.current = new PerformanceObserver(perfObserver);
    perfObserverRef.current.observe({ entryTypes: ["event"] });

    return () => {
      perfObserverRef.current?.disconnect();
    };
  }, [perfObserver]);

  return (
    <EditorPerformanceContext.Provider
      value={{
        setWasCandidateBoxJustClicked: () => {
          candidateBoxClickedRef.current = true;
        },
        leftBarElementRef,
        rightBarElementRef,
      }}
    >
      {children}
    </EditorPerformanceContext.Provider>
  );
}

export const useEditorPerformanceContext = () =>
  useRequiredContext(EditorPerformanceContext);

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const shopifySection: ComponentTemplate = {
  id: "e2d54616-730d-4f7e-bc90-8abc61954b01",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  tags: ["shopify"],
  name: "Shopify/Section",
  leftBarCategory: { main: "pageIntegrations", subCategory: "shopify" },
  preview: "common/section.svg",
  template: {
    id: "e1f896a9-eef6-4078-b622-80c4cebfd2f2",
    type: "shopifySection",
    props: {
      style: {
        flexGrow: 1,
        alignSelf: "stretch",
      },
    },
  },
};

export const rawLiquid: ComponentTemplate = {
  id: "5901b03c-d262-4189-9721-6508caff3ba3",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Custom Code/Liquid",
  leftBarCategory: { main: "customCode", subCategory: "other" },
  preview: "common/liquid.svg",
  template: {
    id: "d0f3ce3d-8c0e-4717-912a-ef877000a280",
    type: "shopifyRawLiquid",
    props: {
      style: {},
    },
  },
};

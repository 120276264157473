import Spinner from "@common/designSystem/Spinner";
import Tooltip from "@common/designSystem/Tooltip";
import classNames from "classnames";
import * as React from "react";
import type { RelativeRoutingType, To } from "react-router-dom";
import { Link } from "react-router-dom";
import { isFunction, isString } from "replo-utils/lib/type-check";
import { useComposedEventHandlers } from "replo-utils/react/use-composed-event-handlers";
import { twMerge } from "tailwind-merge";

export type ButtonType =
  | "primary"
  | "secondary"
  | "tertiary"
  | "danger"
  | "secondaryDanger"
  | "inherit";
export type ButtonSize = "sm" | "base" | "lg";
export type ButtonHtmlType = "button" | "reset" | "submit";

interface DataAttributeProps {
  [key: `data-${string}`]: string;
}

interface AriaProps {
  "aria-label"?: string;
  "aria-labelledby"?: string;
}

export interface ButtonSharedProps extends DataAttributeProps {
  children?: React.ReactNode;
  className?: string;
  endEnhancer?: (() => React.ReactNode) | React.ReactElement;
  hasMinDimensions?: boolean;
  icon?: React.ReactNode;
  id?: string;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  isRounded?: boolean;
  size?: ButtonSize;
  spinnerSize?: number;
  startEnhancer?: (() => React.ReactNode) | React.ReactElement;
  style?: React.CSSProperties;
  textClassNames?: string;
  tooltipText?: string | null;
  type: ButtonType;
  unsafe_className?: string;
}

export interface ButtonProps extends ButtonSharedProps, AriaProps {
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  htmlType?: ButtonHtmlType;
  tabIndex?: number;
}

export interface ButtonLinkProps extends ButtonSharedProps, AriaProps {
  onClick?(event: React.MouseEvent<HTMLAnchorElement>): void;
  rel?: React.HTMLProps<HTMLAnchorElement>["rel"];
  target?: React.HTMLAttributeAnchorTarget;
  tabIndex?: number;
  // NOTE (Chance 2024-01-24): These are specific to the Link component in React
  // Router. I intentionally did not import the type directly as there may be
  // props (including those marked as `unstable_`) we may don't want to expose
  // at this level.
  to: To;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
  reloadDocument?: boolean;
  replace?: boolean;
  state?: any;
}

export type ButtonPhonyProps = ButtonSharedProps;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(_props, ref) {
    const props = getPropsWithDefaults(_props);

    // TOTO (Chance 2024-01-24): These are here for backwards compatibility.
    // They should surface as type errors so we should be able to delete this.
    if ("isPhonyButton" in props && props.isPhonyButton) {
      // @ts-expect-error
      return <ButtonPhony ref={ref} {...props} />;
    }
    if ("href" in props && props.href) {
      return (
        <ButtonLink
          // @ts-expect-error
          ref={ref}
          {...props}
          to={props.href}
          // @ts-expect-error
          target={props.target ?? "_blank"}
        />
      );
    }

    const {
      children,
      htmlType = "button",
      id,
      isDisabled,
      onClick,
      tabIndex,
      tooltipText,
    } = props;
    const { className, style } = getButtonDesignProps(props);
    const ariaProps = getAriaProps(props);
    const dataAttributes = getDataAttributeProps(props);

    return (
      <ButtonTooltip isDisabled={isDisabled} tooltipText={tooltipText}>
        <button
          type={htmlType}
          className={className}
          disabled={isDisabled || undefined}
          id={id}
          onClick={onClick}
          ref={ref}
          style={style}
          tabIndex={tabIndex}
          {...ariaProps}
          {...dataAttributes}
        >
          <ButtonChildren {...props} width={style?.width}>
            {children}
          </ButtonChildren>
        </button>
      </ButtonTooltip>
    );
  },
);

const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  function ButtonLink(_props, ref) {
    const props = getPropsWithDefaults(_props);
    const {
      children,
      id,
      isDisabled,
      onClick,
      preventScrollReset,
      relative,
      reloadDocument,
      replace,
      state,
      tabIndex,
      target,
      to,
      tooltipText,
    } = props;
    const { className, style } = getButtonDesignProps(props);
    let rel: React.HTMLProps<HTMLAnchorElement>["rel"] | undefined = props.rel;
    if (!rel && target === "_blank") {
      rel = "noreferrer";
    }
    const ariaProps = getAriaProps(props);
    const dataAttributes = getDataAttributeProps(props);
    const handleClick = useComposedEventHandlers(
      onClick,
      React.useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
          if (isDisabled) {
            event.preventDefault();
          }
        },
        [isDisabled],
      ),
    );

    return (
      <ButtonTooltip isDisabled={isDisabled} tooltipText={tooltipText}>
        <Link
          className={classNames(className, {
            "pointer-events-none cursor-default": isDisabled,
          })}
          id={id}
          onClick={handleClick}
          preventScrollReset={preventScrollReset}
          ref={ref}
          rel={rel}
          relative={relative}
          reloadDocument={reloadDocument}
          replace={replace}
          state={state}
          style={style}
          target={target}
          to={to}
          aria-disabled={isDisabled || undefined}
          tabIndex={isDisabled ? -1 : tabIndex}
          {...ariaProps}
          {...dataAttributes}
        >
          <ButtonChildren {...props} width={style?.width}>
            {children}
          </ButtonChildren>
        </Link>
      </ButtonTooltip>
    );
  },
);

const ButtonPhony = React.forwardRef<HTMLDivElement, ButtonPhonyProps>(
  function ButtonPhony(_props, ref) {
    const props = getPropsWithDefaults(_props);
    const { children, id, isDisabled, tooltipText } = props;
    const { className, style } = getButtonDesignProps(props);
    const dataAttributes = getDataAttributeProps(props);

    return (
      <ButtonTooltip
        isDisabled={isDisabled}
        tooltipText={tooltipText}
        {...dataAttributes}
      >
        <div className={className} id={id} ref={ref} style={style}>
          <ButtonChildren {...props} width={style?.width}>
            {children}
          </ButtonChildren>
        </div>
      </ButtonTooltip>
    );
  },
);

export { Button, ButtonLink, ButtonPhony };
export default Button;

function ButtonChildren({
  isLoading,
  children,
  textClassNames,
  icon,
  type,
  spinnerSize,
  size,
  startEnhancer,
  endEnhancer,
  width,
}: ButtonSharedProps & {
  width: React.CSSProperties["width"];
}) {
  const textClassName = twMerge(
    "flex leading-3 text-center font-medium",
    classNames(
      {
        "text-xs": size === "sm",
        "text-sm": size === "base" || size === "lg",
        "ml-1": Boolean(startEnhancer),
        "mr-1": Boolean(endEnhancer),
      },
      textClassNames,
    ),
  );

  return (
    <>
      {isFunction(startEnhancer) ? startEnhancer() : startEnhancer}
      <span
        className={classNames("flex items-center relative")}
        style={{ width }}
      >
        {isLoading && (
          <div className="absolute left-1/2">
            <Spinner
              className="relative -left-1/2"
              type={
                (
                  {
                    primary: "primary",
                    secondary: "secondary",
                    tertiary: "secondary",
                    danger: "danger",
                    secondaryDanger: "danger",
                    inherit: "primary",
                  } as const
                )[type]
              }
              size={spinnerSize}
            />
          </div>
        )}
        {children && (
          <span
            className={classNames(textClassName, {
              invisible: isLoading,
            })}
          >
            {children}
          </span>
        )}
        {!isLoading && icon}
      </span>
      {isFunction(endEnhancer) ? endEnhancer() : endEnhancer}
    </>
  );
}

function ButtonTooltip({
  children,
  isDisabled,
  tooltipText,
}: {
  children: React.ReactNode;
  isDisabled: boolean | undefined;
  tooltipText: string | null | undefined;
}) {
  if (!tooltipText) {
    return children;
  }
  return (
    <Tooltip content={tooltipText} triggerAsChild>
      <span
        style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
        // Note (Chance, 2023-09-16) Ensure that disabled buttons have a
        // focusable wrapper so that tooltip content can be accessible via
        // keyboard.
        //
        // TODO Ensure that the inner content makes it clear when the child is
        // disabled.
        tabIndex={isDisabled ? 0 : undefined}
      >
        {children}
      </span>
    </Tooltip>
  );
}

function getButtonDesignProps(props: ButtonSharedProps & { size: ButtonSize }) {
  const {
    hasMinDimensions = true,
    icon,
    isFullWidth,
    isRounded,
    size,
    type,
  } = props;
  const className = twMerge(
    isFullWidth ? "w-full" : "w-fit",
    "flex items-center justify-center font-normal cursor-pointer transition duration-300 disabled:pointer-events-none whitespace-nowrap font-sans",
    classNames({
      "p-1.5 h-6": size === "sm",
      "p-2 px-3 h-8": size === "base",
      "p-2 px-3 h-10": size === "lg",
    }),
    getColorClassNames(type),
    isRounded ? "rounded-full" : "rounded",
    props.className,
    props.unsafe_className,
  );

  let style = props.style;
  if (hasMinDimensions) {
    style = !icon
      ? { minWidth: "4.75rem", ...style }
      : {
          minHeight: "1.5rem",
          minWidth: "1.5rem",
          ...style,
        };
  }

  return {
    className,
    style,
  };
}

function getPropsWithDefaults<Props extends ButtonSharedProps>(props: Props) {
  const { size = "sm", ...rest } = props;
  return { size, ...rest } satisfies ButtonSharedProps;
}

/**
 * This function returns different styles depending on the provided button type
 */
function getColorClassNames(type: ButtonType) {
  const typeToClassNames: Record<ButtonType, string> = {
    primary:
      "bg-blue-600 hover:bg-blue-500 text-white disabled:bg-gray-200 disabled:text-gray-400",
    secondary:
      "bg-slate-100 hover:bg-slate-200 text-slate-600 disabled:bg-gray-200 disabled:text-gray-400",
    tertiary:
      "bg-transparent text-slate-600 disabled:text-gray-400 hover:bg-slate-100 hover:text-default",
    danger:
      "bg-red-600 hover:bg-red-500 text-white disabled:bg-gray-200 disabled:text-gray-400",
    secondaryDanger:
      "text-red-600 bg-red-100 disabled:text-gray-400 hover:bg-gray-200",
    inherit: "bg-inherit text-inherit m-0 p-0",
  };
  return typeToClassNames[type];
}

function getAriaProps(props: ButtonSharedProps & AriaProps) {
  const ariaLabelledBy = props["aria-labelledby"];
  const ariaLabel =
    // NOTE (Chance 2023-12-04): If `aria-labelledby` is provided, then we
    // shouldn't provide `aria-label` as well. This is confusing and won't be
    // reliably read as expected.
    ariaLabelledBy != null
      ? undefined
      : props["aria-label"] ?? props.tooltipText ?? undefined;

  return {
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
  };
}

function getDataAttributeProps(props: Record<string, unknown>) {
  const dataAttributes: Record<`data-${string}`, string> = {};
  for (const key of Object.keys(props)) {
    if (key.startsWith("data-")) {
      const value = props[key];
      dataAttributes[key as `data-${string}`] = isString(value)
        ? value
        : String(value);
    }
  }
  return dataAttributes;
}

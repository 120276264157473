import useCurrentUser from "@editor/hooks/useCurrentUser";
import { addAuthorizationHeaders } from "@editor/infra/auth";
import * as React from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { getConfig } from "replo-runtime/shared/config";

/**
 * Our Discourse (currently running on https://replo.discourse.group) uses SSO so a
 * user can click "login" on that site, and it redirects to the dashboard with
 * some query params. We use those query params to make an API request to publisher
 * where we verify the user, and respond with a URL to redirect back to discourse,
 * letting them know who the user is.
 *
 * TODO: Ben 2023-07-28 - In the future we should use cookies for this, and redirect
 * to publisher directly.
 */
export default function AuthDiscourseSso() {
  const { isAuthenticated, isLoading } = useCurrentUser();
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const sso = params.get("sso") ?? "";
  const sig = params.get("sig") ?? "";

  React.useEffect(() => {
    const apiUrl = getConfig("publisherUrl");
    const url = new URL(`${apiUrl}/api/v1/auth/discourse/sso`);
    url.searchParams.append("sso", sso);
    url.searchParams.append("sig", sig);
    void fetch(url.href, {
      headers: addAuthorizationHeaders(),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.redirectUrl) {
          window.location.href = data.redirectUrl;
        }
      });
  }, [sso, sig]);

  // If the user is not authenticated, and we're not loading, authenticate and come back.
  if (!isAuthenticated && !isLoading) {
    return (
      <Navigate
        to={`/auth/login?next=${pathname}${params.toString()}`}
        replace
      />
    );
  }

  return (
    <div>
      <div className="flex w-full h-screen flex-col justify-center items-center">
        Authenticating with your Replo account...
      </div>
    </div>
  );
}

import type { ComponentTemplate } from "@editor/types/component-template";
import { getCanBeAddedFunction } from "@editor/utils/template";
import { Env } from "replo-runtime/shared/enums";

export const sellingPlanList: ComponentTemplate = {
  id: "6d26d985-3d53-4d95-8f5f-7c9a30e161e0",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Selling Plan List",
  leftBarCategory: { main: "product", subCategory: "sellingPlans" },
  preview: "product/list-basic.svg",
  tags: ["product", "product selling plan list"],
  template: {
    id: "948138ce-d616-4bd3-8b8f-3d06159752d2",
    name: "Selling Plan List",
    type: "sellingPlanSelect",
    props: {
      style: {
        display: "flex",
        __flexGap: "12px",
        flexDirection: "row",
      },
    },
    children: [
      {
        id: "71c93412-5f66-4573-8c43-94a95f23e771",
        name: "Container",
        type: "container",
        props: {
          style: {
            alignItems: "center",
            paddingTop: "4px",
            paddingLeft: "8px",
            paddingRight: "8px",
            flexDirection: "column",
            paddingBottom: "4px",
            justifyContent: "center",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            __animateVariantTransitions: true,
          },
        },
        children: [
          {
            id: "ef0ec33c-06c1-4530-9b7a-fe371c0386f4",
            type: "text",
            props: {
              text: "{{attributes._currentSellingPlan.name}}",
              style: {
                fontSize: "16px",
                fontWeight: 300,
                lineHeight: "24px",
              },
            },
          },
        ],
        variants: [
          {
            id: "01872a86-66d6-43b3-836b-139e3faa6e88",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "ce0eb2e5-d8f5-4460-b765-ad89520c9afd",
            name: "Selected",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "8e1821de-3d2f-4bea-87fb-91b41957b391",
                  field: "state.product.selectedSellingPlan",
                },
              ],
            },
          },
        ],
        variantOverrides: {
          "ce0eb2e5-d8f5-4460-b765-ad89520c9afd": {
            componentOverrides: {
              "71c93412-5f66-4573-8c43-94a95f23e771": {
                props: {
                  style: {
                    backgroundColor: "#000000FF",
                  },
                },
              },
              "ef0ec33c-06c1-4530-9b7a-fe371c0386f4": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};

export const sellingPlanListWithRadio: ComponentTemplate = {
  id: "6d26d985-3d53-4d95-8f5f-7c9a30e161e1",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Selling Plan List with Radio",
  leftBarCategory: { main: "product", subCategory: "sellingPlans" },
  preview: "product/list-radio.svg",
  tags: ["product", "product selling plan list", "radio"],
  template: {
    id: "75ffcbd0-3c25-424e-b1f6-f052e6309afc",
    name: "Selling Plans List with Radio",
    type: "sellingPlanSelect",
    props: {
      style: {
        display: "flex",
        __flexGap: "15px",
        alignSelf: "stretch",
        flexDirection: "column",
      },
    },
    children: [
      {
        id: "96011233-a8bc-4db8-9523-e225ec61dfc5",
        name: "Selling Plan Container",
        type: "container",
        props: {
          style: {
            cursor: "pointer",
            display: "flex",
            __flexGap: "15px",
            alignItems: "center",
            paddingTop: "5px",
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingBottom: "5px",
            justifyContent: "flex-start",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            __animateVariantTransitions: true,
          },
          onClick: [],
        },
        children: [
          {
            id: "39643f56-57ff-4a10-b210-3cc2dc188521",
            name: "Selling Plan Radio",
            type: "container",
            props: {
              style: {
                width: "22px",
                height: "22px",
                __width: "22px",
                display: "flex",
                __height: "22px",
                alignItems: "center",
                borderTopColor: "#000000",
                borderTopStyle: "solid",
                borderTopWidth: "1px",
                justifyContent: "center",
                borderLeftColor: "#000000",
                borderLeftStyle: "solid",
                borderLeftWidth: "1px",
                borderRightColor: "#000000",
                borderRightStyle: "solid",
                borderRightWidth: "1px",
                borderBottomColor: "#000000",
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
                borderTopLeftRadius: "50%",
                borderTopRightRadius: "50%",
                borderBottomLeftRadius: "50%",
                borderBottomRightRadius: "50%",
              },
              _accessibilityRole: "radio",
              _accessibilityChecked: false,
            },
            children: [
              {
                id: "27d99d32-4ed2-4216-8966-e3e07cf2760c",
                name: "Selling Plan Radio Indicator",
                type: "container",
                props: {
                  style: {
                    width: "10px",
                    height: "10px",
                    __width: "10px",
                    display: "flex",
                    opacity: "0%",
                    __height: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#000000FF",
                    borderTopLeftRadius: "50%",
                    borderTopRightRadius: "50%",
                    borderBottomLeftRadius: "50%",
                    borderBottomRightRadius: "50%",
                  },
                },
                children: [],
              },
            ],
            variants: [
              {
                id: "c0104d9f-7f76-47c2-a27b-0f51a4bda605",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "df8f7299-2cb0-4e73-bfea-8022b4bc6a61",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "e4a4b396-827e-4cc7-a4dd-cef3bcd9a212",
                      field: "state.product.selectedSellingPlan",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "df8f7299-2cb0-4e73-bfea-8022b4bc6a61": {
                componentOverrides: {
                  "39643f56-57ff-4a10-b210-3cc2dc188521": {
                    props: {
                      style: {
                        width: "20px",
                        height: "20px",
                        __width: "20px",
                        display: "flex",
                        __height: "20px",
                        flexGrow: "unset",
                        alignSelf: "auto",
                        marginTop: "0px",
                        borderColor: null,
                        borderStyle: null,
                        borderWidth: null,
                        flexDirection: "column",
                        borderTopColor: "#FFFFFFFF",
                        borderTopStyle: "solid",
                        borderTopWidth: "1px",
                        borderLeftColor: "#FFFFFFFF",
                        borderLeftStyle: "solid",
                        borderLeftWidth: "1px",
                        borderRightColor: "#FFFFFFFF",
                        borderRightStyle: "solid",
                        borderRightWidth: "1px",
                        borderBottomColor: "#FFFFFFFF",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",
                      },
                    },
                  },
                  "27d99d32-4ed2-4216-8966-e3e07cf2760c": {
                    props: {
                      style: {
                        width: "15px",
                        height: "15px",
                        __width: "15px",
                        opacity: "100%",
                        __height: "15px",
                        flexGrow: "unset",
                        backgroundColor: "#FFFFFFFF",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "9e432e70-39fd-4674-b267-8425ef998296",
            name: "Selling Plan Information",
            type: "container",
            props: {
              style: {
                display: "flex",
                flexGrow: 1,
                maxWidth: "100%",
                alignSelf: "stretch",
                alignItems: "center",
                justifyContent: "space-between",
              },
            },
            children: [
              {
                id: "96eefce8-36d4-4030-9f2a-f13d9ae4d5aa",
                name: "Selling Plan Name",
                type: "text",
                props: {
                  text: "{{attributes._currentSellingPlan.name}}",
                  style: {
                    fontSize: "16px",
                    fontWeight: 300,
                    lineHeight: "24px",
                  },
                },
              },
            ],
          },
        ],
        variants: [
          {
            id: "64e004e2-3d70-4d88-bd89-92192cd6e05f",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "ad8009c6-b63a-4758-aea0-2e9d1c8498c0",
            name: "Selected",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "8e1821de-3d2f-4bea-87fb-91b41957b391",
                  field: "state.product.selectedSellingPlan",
                },
              ],
            },
          },
        ],
        variantOverrides: {
          "ad8009c6-b63a-4758-aea0-2e9d1c8498c0": {
            componentOverrides: {
              "0e97acae-2fcf-4dd7-b2af-d839b51c4777": {
                props: {
                  style: {
                    color: null,
                    marginLeft: "0px",
                  },
                },
              },
              "96eefce8-36d4-4030-9f2a-f13d9ae4d5aa": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                  },
                },
              },
              "4d8e483e-45f7-49a2-b72e-364721a76549": {
                props: {
                  style: {
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    justifyContent: "center",
                    backgroundColor: "#00A872",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  },
                },
              },
              "96011233-a8bc-4db8-9523-e225ec61dfc5": {
                props: {
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#000000FF",
                  },
                  onClick: [],
                },
              },
              "d8949bdb-76cd-4720-b30a-cfbf7a9df1e6": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                    fontSize: "16px",
                    lineHeight: "24px",
                  },
                },
              },
              "17f75ced-2f15-4c69-8320-a265cafb8578": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};

export const sellingPlanDropdown: ComponentTemplate = {
  id: "60fa9cac-9613-4e76-9ca2-525a6873c112",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Selling Plan Dropdown",
  leftBarCategory: { main: "product", subCategory: "sellingPlans" },
  tags: ["product"],
  preview: "product/selling-plan-dropdown.svg",
  canBeAddedAsChild: getCanBeAddedFunction(
    "product",
    "Selling Plan Dropdowns can only be added inside Product boxes.",
  ),
  template: {
    id: "11f91d59-ef2f-4fbe-a541-de921f70122b",
    type: "container",
    props: {
      style: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        maxWidth: "100%",
        __flexGap: "24px",
        alignSelf: "auto",
      },
    },
    children: [
      {
        id: "75a9c60c-833d-49d1-94dc-9b359e9ecacf",
        type: "sellingPlanSelectDropdown",
        props: {
          style: {
            zIndex: "2",
            fontSize: "16px",
            fontWeight: 700,
            paddingTop: "12px",
            borderColor: "rgb(0, 0, 0)",
            borderTopStyle: "solid",
            borderLeftStyle: "solid",
            borderBottomStyle: "solid",
            borderRightStyle: "solid",
            borderWidth: "1px",
            paddingLeft: "12px",
            paddingRight: "24px",
            paddingBottom: "12px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          },
          _hideDefaultArrowDisplay: true,
          _includeOneTimePurchase: true,
        },
        name: "Selling Plan Dropdown",
      },
      {
        id: "0d8f04be-084f-40c7-b7d4-f1d6744d091c",
        type: "icon",
        props: {
          style: {
            top: "50%",
            right: "2px",
            width: "24px",
            height: "24px",
            zIndex: 1,
            position: "absolute",
            __transform: {
              skewX: "0deg",
              skewY: "0deg",
              scaleX: "100%",
              scaleY: "100%",
              scaleZ: "100%",
              rotateX: "0deg",
              rotateY: "0deg",
              rotateZ: "0deg",
              translateX: "0px",
              translateY: "-50%",
              translateZ: "0px",
            },
          },
          iconName: "chevron-down",
        },
        name: "Arrow",
      },
    ],
    name: "Selling Plan List Dropdown Container",
  },
};

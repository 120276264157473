import Tooltip from "@common/designSystem/Tooltip";
import classNames from "classnames";
import * as React from "react";

type ButtonGroupOption = {
  label: React.ReactNode;
  tooltipText?: string;
  onClick(): void;
  isDisabled?: boolean;
};

type ButtonGroupComponentProps = {
  options: ButtonGroupOption[];
  className?: string;
  style?: React.CSSProperties;
};

const ButtonItem = ({
  option,
  withoutWidth,
  isDisabled = false,
}: {
  option: ButtonGroupOption;
  withoutWidth: boolean;
  isDisabled?: boolean;
}) => {
  function _renderButton() {
    return (
      <button
        disabled={isDisabled}
        style={{
          flexShrink: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        className={classNames(
          "flex h-[1.375rem] cursor-pointer items-center justify-center text-subtle outline-none",
          {
            "py-1 px-2": withoutWidth,
            "flex-1": !withoutWidth,
          },
        )}
        onClick={(e) => {
          // TODO (Noah, 2022-11-19): preventDefault is necessary to keep focus from
          // moving to this button since that would close any active popover. There's
          // probably some better way to get around this, I don't think we're configuring
          // popover focus correctly
          e.preventDefault();
          option.onClick();
        }}
      >
        {option.label}
      </button>
    );
  }

  return option.tooltipText ? (
    <Tooltip content={option.tooltipText} triggerAsChild>
      {_renderButton()}
    </Tooltip>
  ) : (
    _renderButton()
  );
};

const ButtonGroupComponent = ({
  options,
  className,
  style,
}: ButtonGroupComponentProps) => {
  return (
    <div
      style={style}
      className={classNames(
        className,
        "flex h-6 flex-row items-center rounded-sm bg-subtle p-0.5",
      )}
    >
      {options.map((option, index) => {
        return (
          <React.Fragment key={index.toString()}>
            {index !== 0 && (
              <div className="h-4 self-center border-r border-slate-300" />
            )}
            <ButtonItem option={option} withoutWidth={!style?.width} />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ButtonGroupComponent;

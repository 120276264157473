import ColorHexSelector from "@editor/components/common/designSystem/ColorHexSelector";
import ColorPicker from "@editor/components/common/designSystem/ColorPicker";
import ColorSwatch from "@editor/components/common/designSystem/ColorSwatch";
import Popover from "@editor/components/common/designSystem/Popover";
import Tooltip from "@editor/components/common/designSystem/Tooltip";
import { getAutoCompletedColor } from "@editor/components/common/designSystem/utils/colors";
import {
  selectDraftComponentId,
  selectDraftElementColors,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import {
  getFormattedColor,
  getFormattedColorWithoutOpacity,
} from "@editor/utils/colors";
import { getHotKey } from "@editor/utils/getHotKey";
import { hasDynamicData } from "@editorModifiers/utils";
import debounce from "lodash-es/debounce";
import * as React from "react";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import type { SolidOrGradient } from "replo-runtime/shared/types";
import { mapNull } from "replo-runtime/shared/utils/optional";

type Props = {
  value: SolidOrGradient | null;
  onPreviewChange?(value: SolidOrGradient | null): void;
  onChange(value: SolidOrGradient | null): void;
  onRemove?(): void;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  openDynamicData?(): void;
  popoverTitle?: string;
  isDisabled?: boolean;
};

const GradientSelector: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  onChange,
  onRemove,
  onPreviewChange,
  onDragEnd,
  onDragStart,
  openDynamicData,
  popoverTitle = "Color",
  isDisabled,
}) => {
  const [inputValue, setInputValue] = useOverridableState(value);
  const [lastValidColorValue, setLastValidColorValue] = React.useState(value);
  const debounceOnChange = debounce((value) => onChange(value), 300);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const dynamicData =
    hasDynamicData(draftComponentId) && openDynamicData !== undefined;
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const elementColors = useEditorSelector(selectDraftElementColors);

  const getInputTextValue = (color: SolidOrGradient | null): string => {
    if (!color) {
      return "";
    }
    if (color.type === "solid") {
      return getFormattedColorWithoutOpacity(color.color) || "";
    }
    return "Gradient";
  };

  const inputValueText = getInputTextValue(inputValue);

  const handleChangeComplete = (value: SolidOrGradient) => {
    if (value.type === "solid") {
      setInputValue(value);
      setLastValidColorValue(value);
      onPreviewChange?.(value);
      debounceOnChange(value);
    } else {
      setInputValue(value);
      onPreviewChange?.(value);
      debounceOnChange(value);
    }
  };

  const handleColorInputChange = (value: string) => {
    setInputValue({ type: "solid", color: getFormattedColor(value) });
  };

  const handleColorChange = () => {
    if (inputValue?.type === "solid") {
      const color = getAutoCompletedColor(inputValue.color);
      if (color) {
        // Always convert to uppercase for display in the input field
        const hexColor = getFormattedColor(color);
        setInputValue({
          type: "solid",
          color: hexColor,
        });
        setLastValidColorValue({ type: "solid", color: hexColor });
        onChange({ type: "solid", color: hexColor });
      } else if (color === "") {
        setLastValidColorValue({ type: "solid", color });
        setInputValue({ type: "solid", color });
        onChange(null);
      } else {
        setInputValue(lastValidColorValue);
      }
    }
  };

  const handleColorRemove = () => {
    if (onRemove) {
      onRemove();
    } else {
      setInputValue({ type: "solid", color: "" });
      onChange({ type: "solid", color: "" });
    }
  };

  // Note (Sebas 2022-11-14): In case we want to reset the value with opt + click
  // we need to prevent the popover from opening
  const onTriggerClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const hotkey = getHotKey(e);
    if (hotkey === "altKey") {
      e.preventDefault();
    }
  };

  if (isDisabled) {
    return (
      <div className="modifier flex flex-grow flex-row justify-items-start gap-2">
        <div>
          <ColorSwatch
            data-testid={`${popoverTitle.replace(" ", "")}-colorSwatch`}
            value={mapNull(inputValue, (inputValue) =>
              inputValue.type === "solid"
                ? { type: "color", color: inputValue.color }
                : { type: "gradient", gradient: inputValue.gradient },
            )}
          />
        </div>
        <div className="flex flex-grow flex-row justify-start">
          <ColorHexSelector
            className="flex-grow"
            onChange={() => null}
            value={inputValueText || null}
            setValue={handleColorInputChange}
            isDisabled
          />
        </div>
      </div>
    );
  }

  return (
    <div className="modifier flex flex-grow flex-row justify-items-start gap-2">
      <Popover>
        <Popover.Content
          title={popoverTitle}
          shouldPreventDefaultOnInteractOutside={areModalsOpen}
          data-testid={`${popoverTitle.replace(" ", "")}-colorPicker`}
        >
          <ColorPicker
            value={inputValue!}
            allowsGradientSelection
            onChange={handleChangeComplete}
            onPreviewChange={onPreviewChange}
            documentColors={elementColors}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
          />
        </Popover.Content>
        <Tooltip content={popoverTitle} triggerAsChild>
          <Popover.Trigger asChild>
            <button type="button" onClick={onTriggerClick}>
              <ColorSwatch
                data-testid={`${popoverTitle.replace(" ", "")}-colorSwatch`}
                value={mapNull(inputValue, (inputValue) =>
                  inputValue.type === "solid"
                    ? { type: "color", color: inputValue.color }
                    : { type: "gradient", gradient: inputValue.gradient },
                )}
                onOptionClick={handleColorRemove}
              />
            </button>
          </Popover.Trigger>
        </Tooltip>
      </Popover>
      <div className="flex flex-grow flex-row justify-start">
        <ColorHexSelector
          className="flex-grow"
          onChange={handleColorChange}
          handleColorRemove={handleColorRemove}
          value={inputValueText || null}
          setValue={handleColorInputChange}
          isDisabled={inputValueText === "Gradient"}
          allowsSettingDynamicData={dynamicData}
          onClickDynamicData={openDynamicData}
        />
      </div>
    </div>
  );
};

export default GradientSelector;

import { trpc } from "@editor/utils/trpc";
import { skipToken } from "@tanstack/react-query";

export const useUserHasWorkspaceMembership = (
  projectId: string | undefined,
) => {
  // NOTE (Fran 2024-08-29): We don't want to use the current project context here because this will
  // depend on which project the user clicks on in the dashboard. So, we only want to know if the user
  // has memberships for specific projects.
  const { data: project } = trpc.project.get.useQuery(projectId ?? skipToken);
  const { data: userWorkspaces } =
    trpc.workspace.getUserWorkspacesList.useQuery();
  const workspace = userWorkspaces?.workspaces.find(
    (workspace) => workspace.id === project?.ownerWorkspaceId,
  );

  return Boolean(workspace);
};

import { isNotNullish } from "replo-utils/lib/misc";
import { isString } from "replo-utils/lib/type-check";

import type { RuntimeStyleProperties } from "../../shared/styleAttribute";
import { getExpectedFlexBasis } from "../../shared/utils/component";

/**
 * This function swaps style values between flexGrow and alignSelf
 * Caution: This function modifies the style values inline for performance reasons.
 */
export const flexGrowSwapper = (
  evaluatedStyles: Record<string, unknown> | undefined,
  styles: Record<string, unknown> | undefined,
) => {
  if (!styles) {
    return;
  }

  const newStyles: Record<string, unknown> = {};

  if (evaluatedStyles?.alignSelf === "stretch") {
    newStyles.flexGrow = 1;
    newStyles.flexShrink = 1;
    newStyles.flexBasis = getExpectedFlexBasis({
      hasFlexGrow: true,
      parentFlexDirection: "row",
      parentHasDefinedWidth: evaluatedStyles.__parentHasWidth === true,
      parentHasDefinedHeight: evaluatedStyles.__parentHasHeight === true,
    });
  }

  if (String(evaluatedStyles?.flexGrow) === "1") {
    newStyles.alignSelf = "stretch";
  }

  Object.keys(newStyles).forEach((key) => {
    styles[key] = newStyles[key];
  });
};

/** Simple function that check whether the value contains percentage character */
const hasPercentageValue = (value: string | undefined) => value?.includes("%");

/**
 * Gets styles for the GridWrapper component of a particular component.
 */
export function getGridWrapperStyles(styles: Record<string, unknown>) {
  if (styles.display !== "grid" || !isParentFlexColumn(styles)) {
    return { display: "contents" };
  }

  const outputStyles: Record<string, unknown> = { display: "flex" };

  for (const property of ["flexBasis", "flexGrow", "flexShrink", "alignSelf"]) {
    if (isNotNullish(styles[property])) {
      outputStyles[property] = styles[property];
    }
  }

  for (const property of [
    "width",
    "height",
    "minWidth",
    "maxWidth",
    "minHeight",
    "maxHeight",
  ]) {
    const value = styles[property];
    if (isString(value) && hasPercentageValue(value)) {
      // Note (Ovishek, 2023-03-15): We should do 100% in the wrapper component if
      // the grid component have any percentage value in dimension fields, percentages only work with
      // the parents, so wrapper was creating a problem there. also if we do 100% in the
      // wrapper, and thus we should also copy the justify content / align items value from the parent
      // and swap them otherwise auto layout placement won't work, by layout placement we mean https://ibb.co/2v2Hjyg section
      outputStyles[property] = "100%";
    }
  }

  outputStyles.justifyContent = styles.__parentAlignItems;
  outputStyles.alignItems = styles.__parentJustifyContent;

  return outputStyles;
}

/**
 * Gets styles that affect the component but are strictly related to the
 * grid wrapper div that it might have around it.
 * NOTE: This is an improved version of getRelevantGridWrapperStyles where
 * we don't mutate params, a thing we should NEVER do.
 */
export function getGridWrapperRelatedStyles(styles: Record<string, unknown>) {
  if (styles.display !== "grid" || !isParentFlexColumn(styles)) {
    return {};
  }

  const newStyles: Record<string, unknown> = {};

  if (styles.alignSelf === "stretch") {
    newStyles.flexGrow = 1;
    newStyles.flexBasis = getExpectedFlexBasis({
      hasFlexGrow: true,
      parentFlexDirection: "row",
      parentHasDefinedWidth: styles.__parentHasWidth === true,
      parentHasDefinedHeight: styles.__parentHasHeight === true,
    });
    newStyles.flexShrink = 1;
  }

  if (String(styles.flexGrow) === "1") {
    newStyles.alignSelf = "stretch";
  }

  return newStyles;
}

function isParentFlexColumn(styles: RuntimeStyleProperties | undefined) {
  return (
    styles?.__parentDisplay === "flex" &&
    (styles?.__parentFlexDirection === "column" ||
      styles?.__parentFlexDirection === "column-reverse")
  );
}

import * as coreActions from "@actions/core-actions";
import { useTargetFrameDocument } from "@editor/hooks/useTargetFrame";
import { analytics } from "@editor/infra/analytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  useAiMobileResponsiveMutation,
  useAiTextV2Mutation,
} from "@editor/reducers/ai-reducer";
import {
  selectDraftComponent,
  selectDraftElementId,
  selectEditorMode,
  selectProjectId,
  selectStreamingUpdateActions,
  selectStreamingUpdateId,
  selectStreamingUpdateIsStreaming,
  setEditorMode,
  setStreamingUpdate,
} from "@editor/reducers/core-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { getEditorComponentNode } from "@editor/utils/component";
import * as React from "react";
import type { Component, StoreProduct } from "replo-runtime";
import { forEachComponentAndDescendants } from "replo-runtime";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import type { AiProjectContext } from "schemas/ai";

import {
  selectActiveCanvas,
  setCanvasInteractionMode,
  zoomCanvas,
} from "@/features/canvas/canvas-reducer";
import useSetActiveCanvas from "@/features/canvas/useSetActiveCanvas";

type AIStreamingParams =
  | {
      type: "textV2";
      userPrompt: string;
      projectContext: AiProjectContext;
      product?: StoreProduct;
    }
  | {
      type: "mobileResponsive";
    };

export type AIStreamingOperation = AIStreamingParams["type"];

type MenuTriggerSource = "pill" | "previewMenu" | "shortCut" | "rightClick";
type SetIsMenuOpenFn = (
  ...[args]:
    | [isOpen: true, source: MenuTriggerSource]
    | [isOpen: false, source?: MenuTriggerSource]
) => void;

interface AIStreamingContextType {
  menuState: AIMenuState;
  setMenuState: React.Dispatch<React.SetStateAction<AIMenuState>>;
  isMenuOpen: boolean;
  setIsMenuOpen: SetIsMenuOpenFn;
  isTextSelected: boolean;
  generationMode: AIStreamingOperation | null;
  initiateGeneration: (params: AIStreamingParams) => Promise<void>;
  abort: () => void;
  isFinished: boolean;
  applyChanges: () => void;
  discardChanges: () => void;
  status: AIStatus;
  completionPercentage?: number;
}
type AIStatus =
  | "preGeneration"
  | "generationInitialized"
  | "generating"
  | "finishedGenerating";

type AIMenuState = "closed" | "select" | "template" | "text" | "text.context";

const AIStreamingContext = React.createContext<
  AIStreamingContextType | undefined
>(undefined);
AIStreamingContext.displayName = "AIStreamingContext";

export const useAIStreaming = (): AIStreamingContextType => {
  const context = React.useContext(AIStreamingContext);
  if (context === undefined) {
    throw new Error(
      "useAIStreaming must be used within an AIStreamingProvider",
    );
  }
  return context;
};

export const AIStreamingProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const dispatch = useEditorDispatch();
  const store = useEditorStore();

  const draftComponent = useEditorSelector(selectDraftComponent);
  const elementId = useEditorSelector(selectDraftElementId);
  const isStreaming = useEditorSelector(selectStreamingUpdateIsStreaming);
  const isStreamingFinished = isStreaming === false;
  const projectId = useEditorSelector(selectProjectId);

  const [menuState, setMenuState] = React.useState<AIMenuState>("closed");
  const isMenuOpen = menuState !== "closed";
  const isTextSelected = menuState.startsWith("text");

  const [generationMode, setGenerationMode] =
    React.useState<AIStreamingOperation | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies(isMenuOpen): We actually want this dep
  React.useEffect(() => {
    setGenerationMode(null);
  }, [isMenuOpen]);

  const setIsMenuOpen: SetIsMenuOpenFn = React.useCallback(
    (...[val, source]) => {
      if (val) {
        analytics.logEvent("ai.menu.triggered", {
          elementId,
          source,
        });
      }
      setMenuState(val ? "select" : "closed");
    },
    [elementId],
  );

  const [triggerAiTextMutation] = useAiTextV2Mutation();
  const [triggerAiMobileResponsiveMutation] = useAiMobileResponsiveMutation();

  const [componentOrderMap, setComponentOrderMap] = React.useState<
    Map<string, number> | undefined
  >();

  const abortRef = React.useRef<(() => void) | null>(null);

  const measureTextComponentDimensions =
    useMeasureDraftComponentAndChildrenDimensions(
      (component) => component.type === "text",
    );
  const setActiveCanvas = useSetActiveCanvas();

  const editorMode = useEditorSelector(selectEditorMode);
  const actions = useEditorSelector(selectStreamingUpdateActions);

  const status: AIStatus = React.useMemo(() => {
    if (editorMode === EditorMode.aiGeneration) {
      if (isStreaming === false) {
        return "finishedGenerating";
      } else if (actions?.length) {
        return "generating";
      }
      return "generationInitialized";
    }
    return "preGeneration";
  }, [editorMode, isStreaming, actions?.length]);

  const initiateGeneration = async (params: AIStreamingParams) => {
    // NOTE (Gabe 2024-08-01): If another generation has already been initiated,
    // this function is a no-op.
    if (status !== "preGeneration") {
      return;
    }

    // NOTE (Gabe 2024-08-01): This shouldn't happen but the check is here to
    // make the types happy.
    if (!draftComponent || !projectId) {
      return;
    }

    dispatch(setEditorMode(EditorMode.aiGeneration));
    dispatch(setCanvasInteractionMode("locked"));
    setGenerationMode(params.type);

    setComponentOrderMap(buildComponentOrderMap(draftComponent));
    setCompletionPercentage(0);

    dispatch(zoomCanvas(0.5));

    await exhaustiveSwitch(params)({
      textV2: async (params) => {
        const { userPrompt, product, projectContext } = params;
        const textComponentDimensions = measureTextComponentDimensions();

        const { abort } = triggerAiTextMutation({
          component: draftComponent,
          userPrompt,
          projectContext: projectContext ?? {},
          textComponentDimensions,
          projectId,
          product,
        });
        abortRef.current = abort;
      },
      mobileResponsive: async () => {
        const { abort } = triggerAiMobileResponsiveMutation({
          component: draftComponent,
          projectId,
        });
        abortRef.current = abort;

        setActiveCanvas({ canvas: "mobile", source: "ai" });
      },
    });
  };

  const apply = React.useCallback(() => {
    const actions = selectStreamingUpdateActions(store.getState());
    const streamingUpdateId = selectStreamingUpdateId(store.getState());
    if (!actions || !streamingUpdateId) {
      return;
    }
    analytics.logEvent("ai.changes.approved", {
      elementId,
      streamingUpdateId,
      model: isFeatureEnabled("ai-claude") ? "claude-3.5-sonnet" : "gpt-4o",
      generationType: generationMode!,
    });
    dispatch(
      coreActions.applyComponentAction({
        type: "applyCompositeAction",
        value: actions,
        activeCanvas: selectActiveCanvas(store.getState()),
      }),
    );

    dispatch(setStreamingUpdate(null));
    dispatch(setEditorMode(EditorMode.edit));
  }, [store, elementId, generationMode, dispatch]);

  const discard = React.useCallback(() => {
    const streamingUpdateId = selectStreamingUpdateId(store.getState());
    if (!streamingUpdateId) {
      return;
    }
    analytics.logEvent("ai.changes.rejected", {
      elementId,
      streamingUpdateId,
      model: isFeatureEnabled("ai-claude") ? "claude-3.5-sonnet" : "gpt-4o",
      generationType: generationMode!,
    });
    dispatch(setStreamingUpdate(null));
    dispatch(setEditorMode(EditorMode.edit));
  }, [dispatch, elementId, store, generationMode]);

  const abort = () => {
    analytics.logEvent("ai.action.canceled", {
      elementId,
      generationType: generationMode!,
    });
    abortRef.current?.();
  };

  const [completionPercentage, setCompletionPercentage] =
    React.useState<number>(0);

  // NOTE (Gabe 2024-07-26): Because there isn't always a 1:1 mapping between
  // (easily countable) components and actions, we instead use the position in
  // the component tree of the latest action as a proxy for completion
  // percentage.
  const latestActionCompletionPercentage = React.useMemo(() => {
    const latestAction = actions?.[actions.length - 1];

    const numComponents = componentOrderMap?.size;
    const lastActionComponentPosition =
      latestAction?.componentId &&
      componentOrderMap?.get(latestAction.componentId);
    if (numComponents && lastActionComponentPosition) {
      return Math.round((lastActionComponentPosition / numComponents) * 100);
    }
    return 0;
  }, [actions, componentOrderMap]);

  // NOTE (Gabe 2024-08-07): Ensure that completion percentage is always
  // increasing (until it gets reset to 0).
  React.useEffect(() => {
    if (latestActionCompletionPercentage > 0) {
      setCompletionPercentage((val) =>
        Math.max(val, latestActionCompletionPercentage),
      );
    }
  }, [latestActionCompletionPercentage]);

  return (
    <AIStreamingContext.Provider
      value={{
        menuState,
        setMenuState,
        isMenuOpen,
        setIsMenuOpen,
        isTextSelected,
        generationMode,
        initiateGeneration,
        abort,
        isFinished: isStreamingFinished,
        applyChanges: () => {
          setComponentOrderMap(undefined);
          setIsMenuOpen(false);
          apply();
        },
        discardChanges: () => {
          setComponentOrderMap(undefined);
          setIsMenuOpen(false);
          discard();
        },
        status,
        completionPercentage,
      }}
    >
      {children}
    </AIStreamingContext.Provider>
  );
};

/**
 * @param filter Optional function that returns whether a component
 * should be measured
 * @returns A mapping from component id to width and height
 */
function useMeasureDraftComponentAndChildrenDimensions(
  filter?: (component: Component) => boolean,
) {
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const targetDocument = useTargetFrameDocument(activeCanvas);
  const draftElementId = useEditorSelector(selectDraftElementId);
  const draftComponent = useEditorSelector(selectDraftComponent);
  return React.useCallback(() => {
    if (!draftElementId || !draftComponent) {
      return {};
    }

    const result: Record<string, { height: number; width: number }> = {};

    forEachComponentAndDescendants(draftComponent, (component) => {
      if (filter === undefined || filter(component)) {
        const node = targetDocument
          ? getEditorComponentNode(targetDocument, draftElementId, component.id)
          : null;
        if (node) {
          const rect = node.getBoundingClientRect();
          const { height, width } = rect;
          result[component.id] = { width, height };
        }
      }
    });

    return result;
  }, [targetDocument, draftComponent, draftElementId, filter]);
}

function buildComponentOrderMap(
  component: Component | null,
): Map<string, number> {
  const map = new Map<string, number>();
  let count = 0;
  forEachComponentAndDescendants(component, (c) => {
    map.set(c.id, count++);
  });
  return map;
}

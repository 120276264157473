import * as React from "react";
import {
  RuntimeHooksContext,
  useRuntimeContext,
} from "replo-runtime/shared/runtime-context";
import useForceRerenderOnMount from "replo-runtime/store/hooks/useForceRerenderOnMount";
import { useInterval } from "replo-utils/react/use-interval";

import type { RenderComponentProps } from "../../../shared/types";
import { mergeContext } from "../../../shared/utils/context";
import Container from "../../components/Container";

type TimeComponents = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const defaultTimerLimit = {
  hours: 0,
  minutes: 15,
  seconds: 0,
  days: 0,
} satisfies TimeComponents;

const CountdownTimer: React.FC<RenderComponentProps> = (props) => {
  const { component, componentAttributes: attributes, context } = props;
  const endTime = component.props._endTime ?? new Date().toISOString();
  const timerType =
    (component.props._timerType as "date" | "afterLoad") ?? "date";
  const timerLimit =
    (component.props._timerLimit as TimeComponents) ?? defaultTimerLimit;

  const endDate = React.useMemo(() => {
    if (timerType === "date") {
      return new Date(endTime);
    }
    const { hours, minutes, seconds, days } = timerLimit;
    return new Date(
      Date.now() +
        seconds * 1000 +
        minutes * 1000 * 60 +
        hours * 1000 * 60 * 60 +
        days * 1000 * 60 * 60 * 24,
    );
  }, [timerType, timerLimit, endTime]);

  const { secondsUntilEnd, hoursUntilEnd, daysUntilEnd, minutesUntilEnd, key } =
    useDateComponentsUntilEndDate(endDate);

  return (
    <Container
      // Note (Noah, 2022-07-11, REPL-2912): We need to pass in a different key here when
      // the component is mounted in order to ensure that react updates the DOM nodes to
      // reflect the date components which are relative to the current time on page load.
      // If we don't update the key after mount, react will hydrate the page but not update
      // the DOM nodes, which means that until they change, any components using the date
      // components will display the component they were published with, not the component
      // that is relative to the current time. (This is because react builds up a virtual
      // DOM on hydrate, but it assumes that the current DOM matches the virtual DOM, and
      // will not update the current DOM until the virtual DOM changes.) This creates "drift",
      // where the wrong number of hours or minutes will be displayed, and the difference will
      // get larger as time goes on, unless you happen to be looking at the page at the exact
      // moment that a minute, hour, etc component changes. To fix this, we force the component
      // to update by giving it a different key (this creates a difference in the virtual DOM,
      // and forces react to update the real DOM).
      key={key}
      component={component}
      componentAttributes={attributes}
      context={mergeContext(context, {
        attributes: {
          secondsUntilEnd: formatNumberComponent(secondsUntilEnd, {
            padZeros: 2,
          }),
          minutesUntilEnd: formatNumberComponent(minutesUntilEnd, {
            padZeros: 2,
          }),
          hoursUntilEnd: formatNumberComponent(hoursUntilEnd, {
            padZeros: 2,
          }),
          daysUntilEnd: formatNumberComponent(daysUntilEnd, { padZeros: 1 }),
        },
      })}
    />
  );
};

export default CountdownTimer;

/**
 * Return the components of the date-difference between now and the endTime, refreshed
 * every second (which means any component using this hook will rerender once per second).
 */
function useDateComponentsUntilEndDate(endDate: Date) {
  const [referenceDate, setReferenceDate] = React.useState(new Date());
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  useInterval(
    () => {
      setReferenceDate(new Date());
    },
    // Note (Noah, 2024-08-18, REPL-13236): Don't update the date components on an
    // interval in edit mode, since we don't want to trigger unnecessary mutation
    // observers for bounding boxes, etc
    isEditorCanvas ? null : 1000,
  );

  const didMount = useForceRerenderOnMount();

  // Note (Noah, 2022-06-24): We're doing a lot of manual calculation here because
  // we need to generate several "components" of the date difference between now and
  // the end-date. I.e., if there are 65 seconds until the end date, we actually want
  // 5 (the seconds component of the date difference) and 1 (the minutes component of
  // the date difference.)
  //
  // I tried to use date-fns to do these calculations but it doesn't really support
  // date difference components that well (it can do differences, but not get the
  // seconds COMPONENT of a difference, for example). So, we have do these manually!
  const differenceInMillisconds = Math.max(
    endDate.getTime() - referenceDate.getTime(),
    0,
  );
  const daysUntilEnd = differenceInMillisconds / 1000 / 60 / 60 / 24;
  const hoursUntilEnd =
    differenceInMillisconds / 1000 / 60 / 60 - Math.trunc(daysUntilEnd) * 24;
  const minutesUntilEnd =
    differenceInMillisconds / 1000 / 60 -
    Math.trunc(daysUntilEnd) * 24 * 60 -
    Math.trunc(hoursUntilEnd) * 60;
  const secondsUntilEnd =
    differenceInMillisconds / 1000 -
    Math.trunc(daysUntilEnd) * 24 * 60 * 60 -
    Math.trunc(hoursUntilEnd) * 60 * 60 -
    Math.trunc(minutesUntilEnd) * 60;

  return {
    secondsUntilEnd,
    minutesUntilEnd,
    hoursUntilEnd,
    daysUntilEnd,
    key: String(didMount),
  };
}

function formatNumberComponent(number: number, config: { padZeros: number }) {
  return String(Math.trunc(number).toFixed(0)).padStart(config.padZeros, "0");
}

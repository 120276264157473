import { getSellingPlanOptionsFromProduct } from "@editor/utils/sellingPlans";
import type { Option } from "@editorComponents/Lists";
import * as React from "react";
import type { ReploShopifyProduct } from "replo-runtime/shared/types";
import type { SelectedSellingPlanIdOrOneTimePurchase } from "replo-runtime/store/utils/product";
import { isOneTimePurchase } from "replo-runtime/store/utils/product";

import SelectablePopover from "../page/element-editor/components/SelectablePopover";

const REPLO_ONE_TIME_PURCHASE_OPTION_VALUE = "replo:one-time-purchase";

export default function SellingPlanSelector(props: {
  product: ReploShopifyProduct;
  allowAllPlans: boolean;
  selectedSellingPlanId: SelectedSellingPlanIdOrOneTimePurchase | null;
  onChange: (newValue: SelectedSellingPlanIdOrOneTimePurchase | null) => void;
}) {
  const { product, selectedSellingPlanId, onChange, allowAllPlans } = props;
  const sellingPlanOptions = getSellingPlanOptionsFromProduct(
    product,
    allowAllPlans,
  );
  const selectedSellingPlan = sellingPlanOptions.find(
    (option) => Number(option.value) === Number(selectedSellingPlanId),
  );
  const isOneTimePurchaseSelected = isOneTimePurchase(
    selectedSellingPlanId ?? null,
  );
  const selectedSellingPlanName = isOneTimePurchaseSelected
    ? "One-time purchase"
    : selectedSellingPlan?.label ?? "First available selling plan";

  let selectedItems: (string | number)[] = [];
  if (selectedSellingPlan?.value) {
    selectedItems = [selectedSellingPlan.value];
  }
  if (isOneTimePurchaseSelected) {
    selectedItems = [REPLO_ONE_TIME_PURCHASE_OPTION_VALUE];
  }

  return (
    <SelectablePopover
      title="Selling Plans"
      itemSize={40}
      itemsOnViewCount={5}
      selectedItems={selectedItems}
      options={
        [
          {
            label: "One-time purchase",
            isSelectable: true,
            value: REPLO_ONE_TIME_PURCHASE_OPTION_VALUE,
          },
          ...sellingPlanOptions,
        ] as Option[]
      }
      isRemovable={true}
      onSelect={(newSelectedValue) => {
        const newValue =
          newSelectedValue === REPLO_ONE_TIME_PURCHASE_OPTION_VALUE
            ? { __reploOneTimePurchase: true }
            : newSelectedValue;
        onChange(newValue as SelectedSellingPlanIdOrOneTimePurchase | null);
      }}
    >
      <span className="text-slate-600 h-6 p-1 truncate">
        {selectedSellingPlanName
          ? selectedSellingPlanName
          : "Select Selling Plan"}
      </span>
    </SelectablePopover>
  );
}

import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import type { ObjectFitType } from "@editor/types/object-fit";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import ImageSourceSelector from "@editorComponents/ImageSourceSelector";
import * as React from "react";
import type { CustomPropDefinition } from "replo-runtime/shared/Component";

export const ImageCustomPropModifier = (props: {
  value?: { src: string; style: { objectFit?: ObjectFitType } };
  customPropId: string;
  definition: CustomPropDefinition;
}) => {
  const applyComponentAction = useApplyComponentAction();

  const _removeImageSrc = () => {
    applyComponentAction({
      type: "deleteProps",
      propName: props.customPropId,
    });
  };

  const allowsChangingObjectFit =
    props.definition.allowsSettingObjectFit ?? true;

  return (
    <div className="flex flex-col">
      <ImageSourceSelector
        src={props.value?.src}
        objectFitValue={
          props.value?.style?.objectFit ??
          styleAttributeToEditorData.objectFit.defaultValue
        }
        onChangeObjectFit={
          allowsChangingObjectFit
            ? (value: ObjectFitType) => {
                applyComponentAction({
                  type: "setProps",
                  value: {
                    [`${props.customPropId}`]: {
                      style: {
                        objectFit: value,
                      },
                    },
                  },
                });
              }
            : undefined
        }
        onChangeImageSource={(value: string) => {
          applyComponentAction({
            type: "setProps",
            value: {
              [`${props.customPropId}`]: {
                src: value,
              },
            },
          });
        }}
        onRemove={_removeImageSrc}
      />
    </div>
  );
};

import * as React from "react";

import type { RenderComponentProps } from "../../../shared/types";
import { SharedLooxReviews } from "../SharedLooxReviews";

const LooxProductRatingSummary: React.FC<RenderComponentProps> = (props) => {
  const showAllProductRatings = props.component.props._showAllRatings;
  const allowEmptyStars = props.component.props._allowEmptyStars;
  const liquidSourceWidget = `
    {% assign avg_rating = product.metafields.loox.avg_rating %}
    {% assign num_reviews = product.metafields.loox.num_reviews %}
    {% if ${Boolean(
      showAllProductRatings,
    )} and shop.metafields.loox["global_stats"] != blank %}
      {% assign looxstats = shop.metafields.loox["global_stats"] | split: ',' %}
      {% assign avg_rating = looxstats[0] %}
      {% assign num_reviews = looxstats[1] %}
    {% endif %}
    <div
      class="loox-rating"
      data-id="{{ product.id }}"
      data-show-empty-stars="${Boolean(allowEmptyStars)}"
      data-rating="{{ avg_rating }}"
      data-raters="{{ num_reviews }}"></div>`;

  return (
    <SharedLooxReviews
      attributes={props.componentAttributes}
      component={props.component}
      context={props.context}
      liquidSource={liquidSourceWidget}
      reviewsComponentType="Product Rating"
    />
  );
};

export default LooxProductRatingSummary;

import type { EditorRootState } from "@editor/store";
import { isArrayUnOrderlyEqual } from "@editor/utils/tree-utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export type SelectionState = {
  selectedIds: string[];
  lastSelectedId: string | null;
};

const initialState: SelectionState = {
  selectedIds: [],
  lastSelectedId: null,
};

const selectionSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    setMultipleSelectedIds: (state, action: PayloadAction<string[]>) => {
      if (!isArrayUnOrderlyEqual(state.selectedIds, action.payload)) {
        state.selectedIds = action.payload;
      }
    },
    setLastSelectedId: (state, action: PayloadAction<string | null>) => {
      state.lastSelectedId = action.payload;
    },
  },
});

export const selectSelectedIds = (state: EditorRootState) =>
  state.selection.selectedIds;

export const selectLastSelectedId = (state: EditorRootState) =>
  state.selection.lastSelectedId;

export const selectIsSelected = (id: string) => {
  return (state: EditorRootState) => state.selection.selectedIds.includes(id);
};

const { actions, reducer } = selectionSlice;

export const { setMultipleSelectedIds, setLastSelectedId } = actions;
export default reducer;

import * as React from "react";

import { usePreloadImagesFromDataTable } from "../../../shared/hooks/usePreloadImagesFromDataTable";
import {
  DataTablesContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { mergeContext } from "../../../shared/utils/context";
import { getRowObjectsFromDataTable } from "../../../store/AlchemyDataTable";
import { ReploComponent } from "../ReploComponent";

const Collection: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
}) => {
  const { repeatedIndexPath = ".0" } = context;
  const { mapping: dataTablesMapping } = useRuntimeContext(DataTablesContext);
  const {
    products,
    activeCurrency: currencyCode,
    activeLanguage: language,
    moneyFormat,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);

  const template = component.children?.length && component.children[0];
  const itemsConfig = component.props.items;
  usePreloadImagesFromDataTable(itemsConfig?.id, { skipPreload: !template });
  if (!template) {
    return null;
  }

  const items =
    getRowObjectsFromDataTable(itemsConfig?.id, dataTablesMapping, {
      products,
      currencyCode,
      moneyFormat,
      language,
      templateProduct,
    }) || [];

  return (
    <div {...componentAttributes}>
      {items?.map((item, index) => (
        <ReploComponent
          key={index}
          component={template}
          context={mergeContext(context, {
            attributes: { _currentItem: item },
          })}
          repeatedIndexPath={`${repeatedIndexPath}.${index}`}
        />
      ))}
    </div>
  );
};

export default Collection;

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const productSelection: ComponentTemplate = {
  id: "1a120962-5394-4e77-9653-73a58778d194",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Basic/Product Selection",
  leftBarCategory: { main: "product", subCategory: "productData" },
  preview: "selection-list.svg",
  template: {
    id: "318b35f9-0cb2-40de-8f0d-c66e608a754e",
    name: "Product Selection List",
    type: "selectionList",
    props: {
      style: {
        __width: "50%",
        display: "flex",
        __flexGap: "10px",
        alignItems: "center",
        justifyContent: "space-between",
        alignSelf: "stretch",
      },
      _accessibilitySelection: true,
    },
    children: [
      {
        id: "092c5842-6785-46db-9140-5912f1c3e411",
        type: "container",
        props: {
          style: {
            color: "#000000FF",
            display: "flex",
            paddingTop: "20px",
            paddingLeft: "32px",
            paddingRight: "32px",
            paddingBottom: "20px",
            borderTopColor: "#000000",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            backgroundColor: "#FFFFFFFF",
            borderLeftColor: "#000000",
            borderLeftStyle: "solid",
            borderLeftWidth: "1px",
            borderRightColor: "#000000",
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderBottomColor: "#000000",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
          },
          onClick: [
            {
              id: "c4307fb0-9d93-448e-8b92-aea47e38fcc7",
              type: "updateCurrentProduct",
              value: {
                productId: 7_731_722_191_085,
              },
            },
          ],
        },
        children: [
          {
            id: "a1c87bd1-ab27-4614-a48b-c82f6f6487e1",
            type: "text",
            props: {
              text: "<p>Product One</p>",
              style: {
                flexGrow: 1,
                fontSize: "20px",
                lineHeight: "20px",
                letterSpacing: "2px",
              },
            },
          },
        ],
        variants: [
          {
            id: "f6d8c30d-82d1-4e88-804e-69497d2d7e59",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "b36d2c37-a185-415e-94d1-af1e02cd2479",
            name: "Selected Product",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  field: "state.product.selectedProductEquals",
                  id: "fde26c5f-3ebe-4607-965b-f0e4aa350826",
                  operator: "eq",
                  value: [
                    {
                      id: 7_939_417_123,
                      title: "Replo Crewneck",
                      productId: 7_731_722_191_085,
                      variantId: 43_258_720_059_629,
                    },
                  ],
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "b36d2c37-a185-415e-94d1-af1e02cd2479": {
            componentOverrides: {
              "092c5842-6785-46db-9140-5912f1c3e411": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                    borderColor: null,
                    borderStyle: null,
                    borderWidth: null,
                    borderTopColor: null,
                    borderTopStyle: "none",
                    borderTopWidth: "0px",
                    backgroundColor: "#000000FF",
                    borderLeftColor: null,
                    borderLeftStyle: "none",
                    borderLeftWidth: "0px",
                    borderRightColor: null,
                    borderRightStyle: "none",
                    borderRightWidth: "0px",
                    borderBottomColor: null,
                    borderBottomStyle: "none",
                    borderBottomWidth: "0px",
                  },
                },
              },
            },
          },
        },
        name: "Product One",
      },
      {
        id: "3ec8e586-d7a2-4496-891f-ed08c72eb732",
        type: "container",
        props: {
          style: {
            color: "#000000FF",
            display: "flex",
            paddingTop: "20px",
            paddingLeft: "32px",
            paddingRight: "32px",
            paddingBottom: "20px",
            borderTopColor: "#000000",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            backgroundColor: "#FFFFFFFF",
            borderLeftColor: "#000000",
            borderLeftStyle: "solid",
            borderLeftWidth: "1px",
            borderRightColor: "#000000",
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderBottomColor: "#000000",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
          },
          onClick: [
            {
              id: "f17bac48-0960-4bb5-8feb-1d4054ae403e",
              type: "updateCurrentProduct",
              value: {
                productId: 7_912_740_716_781,
              },
            },
          ],
        },
        children: [
          {
            id: "a1177738-3e91-4ad1-abe7-8965b2fa6f6e",
            type: "text",
            props: {
              text: "<p>Product Two</p>",
              style: {
                flexGrow: 1,
                fontSize: "20px",
                lineHeight: "20px",
                letterSpacing: "2px",
              },
            },
          },
        ],
        variants: [
          {
            id: "d9c0ff28-84da-4a5f-b906-744ea84d5775",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "6a8b8ac8-2216-4152-b56a-7e9d88f89863",
            name: "Selected Product",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  field: "state.product.selectedProductEquals",
                  id: "fde26c5f-3ebe-4607-965b-f0e4aa350826",
                  operator: "eq",
                  value: [
                    {
                      id: 7_434_717_606,
                      title: "Replo Cap",
                      productId: 7_912_740_716_781,
                      variantId: 43_258_711_408_877,
                    },
                  ],
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "6a8b8ac8-2216-4152-b56a-7e9d88f89863": {
            componentOverrides: {
              "3ec8e586-d7a2-4496-891f-ed08c72eb732": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                    borderColor: null,
                    borderStyle: null,
                    borderWidth: null,
                    borderTopColor: null,
                    borderTopStyle: "none",
                    borderTopWidth: "0px",
                    backgroundColor: "#000000FF",
                    borderLeftColor: null,
                    borderLeftStyle: "none",
                    borderLeftWidth: "0px",
                    borderRightColor: null,
                    borderRightStyle: "none",
                    borderRightWidth: "0px",
                    borderBottomColor: null,
                    borderBottomStyle: "none",
                    borderBottomWidth: "0px",
                  },
                },
              },
            },
          },
        },
        name: "Product Two",
      },
      {
        id: "4ed2008d-095c-49fd-9c7a-bbdf0e6d22fe",
        type: "container",
        props: {
          style: {
            color: "#000000FF",
            display: "flex",
            paddingTop: "20px",
            paddingLeft: "32px",
            paddingRight: "32px",
            paddingBottom: "20px",
            borderTopColor: "#000000",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            backgroundColor: "#FFFFFFFF",
            borderLeftColor: "#000000",
            borderLeftStyle: "solid",
            borderLeftWidth: "1px",
            borderRightColor: "#000000",
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderBottomColor: "#000000",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
          },
          onClick: [
            {
              id: "a6ced7a8-c752-4da5-a94c-fb77ff12f0d8",
              type: "updateCurrentProduct",
              value: {
                productId: 7_912_923_562_221,
              },
            },
          ],
        },
        children: [
          {
            id: "f11b1609-42da-4099-9110-73c000e37cfb",
            type: "text",
            props: {
              text: "<p>Product Three</p>",
              style: {
                flexGrow: 1,
                fontSize: "20px",
                lineHeight: "20px",
                letterSpacing: "2px",
              },
            },
          },
        ],
        variants: [
          {
            id: "64feab8a-1f8a-4aef-97b9-e5d175b10bb0",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "6a3ba89d-73a9-471f-963a-ee3bf198f8c8",
            name: "Selected Product",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  field: "state.product.selectedProductEquals",
                  id: "fde26c5f-3ebe-4607-965b-f0e4aa350826",
                  operator: "eq",
                  value: [
                    {
                      id: 5_659_173_338,
                      title: "Replo Beanie",
                      productId: 7_912_923_562_221,
                      variantId: 43_258_891_469_037,
                    },
                  ],
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "6a3ba89d-73a9-471f-963a-ee3bf198f8c8": {
            componentOverrides: {
              "4ed2008d-095c-49fd-9c7a-bbdf0e6d22fe": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                    borderColor: null,
                    borderStyle: null,
                    borderWidth: null,
                    borderTopColor: null,
                    borderTopStyle: "none",
                    borderTopWidth: "0px",
                    backgroundColor: "#000000FF",
                    borderLeftColor: null,
                    borderLeftStyle: "none",
                    borderLeftWidth: "0px",
                    borderRightColor: null,
                    borderRightStyle: "none",
                    borderRightWidth: "0px",
                    borderBottomColor: null,
                    borderBottomStyle: "none",
                    borderBottomWidth: "0px",
                  },
                },
              },
            },
          },
        },
        name: "Product Three",
      },
    ],
  },
};

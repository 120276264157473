import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const homePageCrossSell: ComponentTemplate = {
  id: "3104c785-2a86-42e9-8efb-1e7bca01ad86",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Rebuy/Rebuy Home Page Cross-sell",
  leftBarCategory: { main: "pageIntegrations", subCategory: "rebuy" },
  preview: "rebuy/home-page-cross-sell.svg",
  transforms: [],
  template: {
    id: "$uid",
    type: "rebuyWidget",
    props: {
      style: {
        alignSelf: "stretch",
        flexGrow: 1,
      },
    },
  },
};

import { z } from "zod";

const metricNameSchema = z.enum([
  "conversions",
  "views",
  "unique_sessions",
  "conversion_rates",
  "revenue",
  "revenue_per_session",
  "average_order_value",
]);

export type MetricName = z.infer<typeof metricNameSchema>;

/**
 * --------------------------------
 * Schemas for AnalyticsReadResponse
 * --------------------------------
 */

const rangeResultMetadataSchema = z.object({
  elementId: z.string().uuid().nullable(),
  title: z.string(),
  url: z.string(),
});

const rangeResultMetricsSchema = z.record(
  metricNameSchema,
  z.array(z.number()),
);

const rangeResultSchema = z.object({
  urlPath: z.string(),
  metadata: rangeResultMetadataSchema,
  rangeId: z.string().uuid(),
  metrics: rangeResultMetricsSchema,
});

export type RangeResult = z.infer<typeof rangeResultSchema>;

const totalRowsCountSchema = z.number().min(0);

export const analyticsReadResponseSchema = z.object({
  totalRowsCount: totalRowsCountSchema,
  rangeResults: z.array(rangeResultSchema),
});
export type AnalyticsReadResponse = z.infer<typeof analyticsReadResponseSchema>;

/**
 * --------------------------------
 * Schemas for AnalyticsReadQuery
 * --------------------------------
 */

const orderSchema = z.enum(["ASC", "DESC"]);

export type Order = z.infer<typeof orderSchema>;

export enum ConditionOperatorEnum {
  EQUALS = "equals",
  CONTAINS = "contains",
  DOES_NOT_CONTAIN = "does_not_contain",
}

const filterConditionSchema = z.discriminatedUnion("operator", [
  z.object({
    operator: z.literal(ConditionOperatorEnum.EQUALS),
    value: z.string(),
  }),
  z.object({
    operator: z.literal(ConditionOperatorEnum.CONTAINS),
    value: z.string(),
  }),
  z.object({
    operator: z.literal(ConditionOperatorEnum.DOES_NOT_CONTAIN),
    value: z.string(),
  }),
]);

export type FilterCondition = z.infer<typeof filterConditionSchema>;

const filterConditionsSchema = z.array(filterConditionSchema);

const filterSchema = z.object({
  urlPath: filterConditionsSchema,
  device: filterConditionsSchema,
  utm_name: filterConditionsSchema,
});

export type FilterName = keyof z.infer<typeof filterSchema>;

export const rangeSchema = z.object({
  id: z.string().uuid(),
  startDatetime: z.number().positive(),
  endDatetime: z.number().positive(),
  interval: z.number().min(10),
});

export const rangesSchema = z.object({
  mainRange: rangeSchema,
  compareAtRanges: z.array(rangeSchema),
});

export type Range = z.infer<typeof rangeSchema>;

export const analyticsReadQuerySchema = z
  .object({
    /**
     * Workspace is always required. This is how we
     * check the individual paths to be sure we're looking up the correct ones for the workspace.
     */
    urlHosts: z.array(z.string()),
    /**
     * The metrics that are directly stored in ClickHouse's timeseries_records table, and/or metrics
     * that need to be calculated from metrics that are stored in ClickHouse. E.g. "conversions",
     * "views", "conversion_rates".
     */
    metrics: z.array(metricNameSchema).min(1),
    /**
     * Sorting order for the sorting metric.
     */
    order: orderSchema,
    /**
     * Sort based on a given metric name.
     */
    sortMetric: metricNameSchema,
    /**
     * The range being considered, including the interval (in MINUTES) of the buckets that we want to retrieve. E.g. if interval = 30, then we're
     * aggregating the metric calculation in buckets of 30min.
     */
    ranges: rangesSchema,
    /**
     * Offset used in pagination.
     */
    offset: z.number().min(0),
    /**
     * Limit of the results per page.
     */
    limit: z.number().positive().max(100),
    filters: filterSchema,
  })
  .superRefine((input, ctx) => {
    if (!input.metrics.includes(input.sortMetric)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Sort value must be one of of the specified metrics.",
        path: ["sort"],
      });
    }
  });
export type AnalyticsReadQuery = z.infer<typeof analyticsReadQuerySchema>;
export type AnalyticsReadQueryWithoutRanges = Omit<
  AnalyticsReadQuery,
  "ranges"
>;

import {
  createElement as createElementReduxAction,
  setElementsLoading,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch } from "@editor/store";
import { trpc } from "@editor/utils/trpc";
import type { ReploPartialElement } from "schemas/element";

export const useCreateElement = () => {
  const dispatch = useEditorDispatch();
  const { mutateAsync: createElementMutation, isPending: isLoading } =
    trpc.element.create.useMutation({
      onMutate: () => {
        dispatch(setElementsLoading(true));
      },
      onSuccess: ({ element }) => {
        if (element) {
          dispatch(createElementReduxAction({ element }));
        }
      },
      onSettled: () => {
        dispatch(setElementsLoading(false));
      },
    });

  const createElement = async (element: ReploPartialElement) => {
    return await createElementMutation({ element });
  };

  return { createElement, isLoading };
};

import { NavigationTabs } from "@editor/components/dashboard/NavigationTabs";
import DashboardLayout from "@editor/components/layouts/DashboardLayout";
import * as React from "react";
import { Outlet } from "react-router-dom";

export default function AccountDashboard() {
  const links = [
    {
      path: "/settings/profile",
      text: "Profile",
    },
    {
      path: "/settings/security",
      text: "Security",
    },
  ];

  return (
    <DashboardLayout>
      <div className="flex w-full flex-col px-6 pt-9">
        <NavigationTabs
          header="Your Profile"
          subtitle="Manage your profile and preferences"
          links={links}
        />
        <Outlet />
      </div>
    </DashboardLayout>
  );
}

import ProjectsTable from "@editor/components/dashboard/projects/ProjectsTable";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { trpc } from "@editor/utils/trpc";
import { skipToken } from "@tanstack/react-query";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const SharedProjects: React.FC = () => {
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const { data, isLoading } = trpc.project.findByUserId.useQuery(
    user?.id ? user.id : skipToken,
  );

  return (
    <ProjectsTable
      title="All Projects"
      subtitle="These are all the projects shared with you across all workspaces"
      projects={data?.allProjects}
      isLoading={isLoading}
      buttonLabel="New Replo Project"
      onButtonClick={() => {
        navigate("../project/new");
      }}
      showWorkspaceColumn
    />
  );
};

export default SharedProjects;

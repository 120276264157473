import Button from "@common/designSystem/Button";
import ColorPicker from "@common/designSystem/ColorPicker";
import InlinePopover from "@common/designSystem/InlinePopover";
import { selectDraftElementColors } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import type { Editor } from "@tiptap/react";
import classNames from "classnames";
import * as React from "react";
import type { IconType } from "react-icons";
import { usePrevious } from "replo-runtime/shared/hooks/usePrevious";
import tinycolor from "tinycolor2";

const DEFAULT_COLOR = {
  color: tinycolor("black").toRgbString(),
  background: tinycolor("white").toRgbString(),
};

const TipTapToolbarColorPicker: React.FC<
  React.PropsWithChildren<{
    editor: Editor | null;
    attributeName: "color" | "background";
    onUpdateColor(color: string | null): void;
    icon: IconType;
    isActive: boolean;
  }>
> = ({ editor, attributeName, onUpdateColor, icon: Icon, isActive }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const elementColors = useEditorSelector(selectDraftElementColors);

  // Note (Noah, 2022-11-13): Because when we call onUpdateColor, we run the command in
  // TipTap which moves focus to the tiptap editor, onInteractOutside for the popover will
  // always be called, which means the popover will immediately dismiss. To avoid this, we
  // keep a ref which tracks whether an update is in progress, and if it is we preventDefault
  // in the onInteractOutside to keep the popover open. I'm not sure if setTimeout is the
  // best way to do this, but I couldn't figure out a different way
  const isUpdatingColorRef = React.useRef(false);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const onChangeColor = (color: string | null) => {
    isUpdatingColorRef.current = true;
    onUpdateColor(color);
    // Note (Noah, 2022-11-13): If we just updated the color 200ms ago, make sure
    // to clear the pending timeout since we don't want the isUpdatingColorRef to
    // get set to false before this event finishes processing
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      isUpdatingColorRef.current = false;
    }, 200);
  };

  const prevVisible = usePrevious(isVisible);
  const instantColor =
    attributeName === "color"
      ? editor?.getAttributes("textStyle").color
      : editor?.getAttributes("highlight").color;

  const color =
    (isVisible && prevVisible !== isVisible && instantColor) ||
    DEFAULT_COLOR[attributeName];

  const isTextColorPopover = attributeName === "color";
  const title = isTextColorPopover
    ? "Configure Text Color"
    : "Configure Highlight Color";

  return (
    <InlinePopover
      isOpen={isVisible}
      shouldPreventDefaultOnInteractOutside={false}
      onOpenChange={setIsVisible}
      onInteractOutside={(e) => {
        // Note (Noah, 2022-11-13): Don't dismiss the popover if we're currently updating
        // (this onInteractOutside might have been triggered by the onUpdateColor call, which
        // focuses the Tiptap editor)
        if (isUpdatingColorRef.current) {
          e.preventDefault();
        }
      }}
      title={title}
      triggerAsChild
      content={
        <div className="flex flex-col">
          <ColorPicker
            value={color}
            allowsGradientSelection={false}
            onChange={(color) => {
              if (color) {
                onChangeColor(color);
              }
            }}
            documentColors={elementColors}
          />
          <div className="flex justify-end">
            <Button
              type="primary"
              onClick={() => {
                onChangeColor(null);
                setIsVisible(false);
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      }
    >
      <Button
        type="tertiary"
        className={classNames("bg-subtle p-0", {
          "bg-accent text-onEmphasis": isActive,
        })}
        hasMinDimensions={false}
        tooltipText={
          { background: "Background Color", color: "Font Color" }[attributeName]
        }
      >
        <Icon size={16} />
      </Button>
    </InlinePopover>
  );
};

export default TipTapToolbarColorPicker;

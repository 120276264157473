import type { StoreProduct } from "replo-runtime/shared/types";
import { REPLO_UI_PRODUCTS } from "replo-runtime/store/utils/reploUi";

export const fakeProducts: StoreProduct[] = [
  {
    id: 7_731_722_191_085,
    title: "Replo Crewneck",
    type: "",
    description:
      "A smart, organic cotton sweater with a secret edge to keep you cool. We blended the durable, good looks of cotton with recycled polyester, which wicks away moisture from your skin and keeps you dry and comfortable.",
    handle: "airy-plant",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-black-front-630983085a4b2.png?v=1661632758",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-black-product-details-2-630983085a96e.png?v=1661632758",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-black-product-details-630983085a7a6.png?v=1661632758",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-light-pink-front-63098308791a0.png?v=1661632885",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-sport-grey-front-630983087362d.png?v=1661632885",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-military-green-front-63098308695ac.png?v=1661632885",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-light-blue-front-630983086e6c9.png?v=1661632885",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-navy-front-630983085aaff.png?v=1661632885",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-maroon-front-630983085be08.png?v=1661632885",
    ],
    variants: [
      {
        id: 43_258_720_059_629,
        title: "Extra Small / Black",
        name: "Replo Crewneck - Extra Small / Black",
        price: "29.99",
        sku: null,
        compare_at_price: null,
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-black-product-details-630983085a7a6.png?v=1661632758",
        },
        option2: "Black",
        available: true,
      },
      {
        id: 43_258_721_665_261,
        title: "Extra Small / Grey",
        name: "Replo Crewneck - Extra Small / Grey",
        price: "29.99",
        sku: null,
        compare_at_price: null,
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-sport-grey-front-630983087362d.png?v=1661632885",
        },
        option2: "Grey",
        available: true,
      },
      {
        id: 43_258_730_316_013,
        title: "Extra Small / Navy",
        name: "Replo Crewneck - Extra Small / Navy",
        price: "29.99",
        sku: null,
        compare_at_price: null,
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-navy-front-630983085aaff.png?v=1661632885",
        },
        option2: "Navy",
        available: true,
      },
      {
        id: 43_258_721_632_493,
        title: "Extra Small / Maroon",
        name: "Replo Crewneck - Extra Small / Maroon",
        price: "29.99",
        sku: null,
        compare_at_price: null,
        option1: "Extra Small",
        featured_image: null,
        option2: "Maroon",
        available: true,
      },
      {
        id: 43_258_721_698_029,
        title: "Extra Small / Olive",
        name: "Replo Crewneck - Extra Small / Olive",
        price: "29.99",
        sku: null,
        compare_at_price: null,
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-military-green-front-63098308695ac.png?v=1661632885",
        },
        option2: "Olive",
        available: true,
      },
      {
        id: 43_257_492_898_029,
        title: "Extra Small / Light Blue",
        name: "Replo Crewneck - Extra Small / Light Blue",
        price: "29.99",
        sku: null,
        compare_at_price: null,
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-light-blue-front-630983086e6c9.png?v=1661632885",
        },
        option2: "Light Blue",
        available: true,
      },
      {
        id: 43_257_492_930_797,
        title: "Extra Small / Light Pink",
        name: "Replo Crewneck - Extra Small / Light Pink",
        price: "29.99",
        sku: null,
        compare_at_price: null,
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-light-pink-front-63098308791a0.png?v=1661632885",
        },
        option2: "Light Pink",
        available: true,
      },
      {
        id: 43_258_720_092_397,
        title: "Small / Black",
        name: "Replo Crewneck - Small / Black",
        price: "29.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-black-product-details-630983085a7a6.png?v=1661632758",
        },
        option2: "Black",
        available: true,
      },
      {
        id: 43_258_721_763_565,
        title: "Small / Grey",
        name: "Replo Crewneck - Small / Grey",
        price: "29.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: null,
        option2: "Grey",
        available: true,
      },
      {
        id: 43_258_730_348_781,
        title: "Small / Navy",
        name: "Replo Crewneck - Small / Navy",
        price: "29.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: null,
        option2: "Navy",
        available: true,
      },
    ],
    options: ["Size", "Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-black-front-630983085a4b2.png?v=1661632758",
    selling_plan_groups: [],
  },
  {
    id: 7_912_740_716_781,
    title: "Replo Cap",
    type: "",
    description:
      "The Replo Cap features a traditional, structured-front ball-cap fit in a trucker setup, with a recycled polyester mesh back for excellent airflow on hot summer days.",
    handle: "replo-cap",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame2.png?v=1661631744",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame5.png?v=1661631744",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame1.png?v=1661631744",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame3.png?v=1661631744",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame4.png?v=1661631744",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame_2.png?v=1661631744",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame_7.png?v=1661631765",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame_14.png?v=1661631776",
    ],
    variants: [
      {
        id: 43_258_711_408_877,
        title: "Black",
        name: "Replo Cap - Black",
        price: "29.99",
        sku: null,
        compare_at_price: null,
        option1: "Black",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame2.png?v=1661631744",
        },
        available: true,
      },
      {
        id: 43_258_711_441_645,
        title: "Navy",
        name: "Replo Cap - Navy",
        price: "29.99",
        sku: null,
        compare_at_price: null,
        option1: "Navy",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame_7.png?v=1661631765",
        },
        available: true,
      },
      {
        id: 43_258_711_474_413,
        title: "White",
        name: "Replo Cap - White",
        price: "29.99",
        sku: null,
        compare_at_price: null,
        option1: "White",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame_14.png?v=1661631776",
        },
        available: true,
      },
    ],
    options: ["Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame2.png?v=1661631744",
    selling_plan_groups: [],
  },
  {
    id: 7_912_747_860_205,
    title: "Replo Enamel Mug",
    type: "",
    description:
      "Sip hot cocoa at the campground or pour yourself a cup of tea at home with the Replo enamel mug. Each lightweight, coffee mug is dishwasher-safe and coated in a durable, classic speckled enamel finish. They also feature rolled rims for sipping comfort.Best Use CasualLiquid Capacity (L) 0.35 litersLiquid Capacity (fl. oz.) 12 fluid ouncesCap Type No CapMaterial(s) Enamel",
    handle: "replo-enamel-mug",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame19.png?v=1661632258",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame18.png?v=1661632258",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame20.png?v=1661632258",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/enamel-mug-white-12oz-right-630a63c1edd5b.png?v=1661632259",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/enamel-mug-white-12oz-right-630a63c1edddc.png?v=1661632259",
    ],
    variants: [
      {
        id: 43_258_718_617_837,
        title: "Default Title",
        name: "Replo Enamel Mug - Default Title",
        price: "19.99",
        sku: null,
        compare_at_price: null,
        option1: "Default Title",
        featured_image: null,
        available: true,
      },
    ],
    options: ["Title"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame19.png?v=1661632258",
    selling_plan_groups: [],
  },
  {
    id: 7_912_918_188_269,
    title: "Replo Bomber Jacket",
    type: "",
    description:
      "This Replo Bomber Jacket is perfect for adventures in the urban jungle, thanks to its high-twist cotton and relaxed, cropped fit. Pair this streetwear staple with jeans, chinos or a casual shirt-anything goes.",
    handle: "replo-bomber-jacket",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-army-back-630a65c19377b.png?v=1661647551",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-army-front-630a65c1922f1.png?v=1661647551",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-army-front-630a65c19210f.png?v=1661647551",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-army-product-details-2-630a65c193326.png?v=1661647551",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-army-product-details-3-630a65c193428.png?v=1661647551",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-army-product-details-4-630a65c19354e.png?v=1661647551",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-army-product-details-630a65c193201.png?v=1661647551",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-black-back-630a65c191a87.png?v=1661647552",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-black-back-630a65c191b48.png?v=1661647552",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-black-front-630a65c19113f.png?v=1661647552",
    ],
    variants: [
      {
        id: 43_258_870_497_517,
        title: "Small / Army",
        name: "Replo Bomber Jacket - Small / Army",
        price: "99.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-army-front-630a65c19210f.png?v=1661647551",
        },
        option2: "Army",
        available: true,
      },
      {
        id: 43_258_870_530_285,
        title: "Small / Black",
        name: "Replo Bomber Jacket - Small / Black",
        price: "99.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-black-front-630a65c191080.png?v=1661647552",
        },
        option2: "Black",
        available: true,
      },
      {
        id: 43_258_870_563_053,
        title: "Medium / Army",
        name: "Replo Bomber Jacket - Medium / Army",
        price: "99.99",
        sku: null,
        compare_at_price: null,
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-army-front-630a65c19210f.png?v=1661647551",
        },
        option2: "Army",
        available: true,
      },
      {
        id: 43_258_870_595_821,
        title: "Medium / Black",
        name: "Replo Bomber Jacket - Medium / Black",
        price: "99.99",
        sku: null,
        compare_at_price: null,
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-black-front-630a65c191080.png?v=1661647552",
        },
        option2: "Black",
        available: true,
      },
      {
        id: 43_258_870_628_589,
        title: "Large / Army",
        name: "Replo Bomber Jacket - Large / Army",
        price: "99.99",
        sku: null,
        compare_at_price: null,
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-army-front-630a65c19210f.png?v=1661647551",
        },
        option2: "Army",
        available: true,
      },
      {
        id: 43_258_870_661_357,
        title: "Large / Black",
        name: "Replo Bomber Jacket - Large / Black",
        price: "99.99",
        sku: null,
        compare_at_price: null,
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-black-front-630a65c191080.png?v=1661647552",
        },
        option2: "Black",
        available: true,
      },
    ],
    options: ["Size", "Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-army-back-630a65c19377b.png?v=1661647551",
    selling_plan_groups: [],
  },
  {
    id: 7_912_923_562_221,
    title: "Replo Beanie",
    type: "",
    description:
      "A classic style with lots of comfort, this responsibly-made beanie gives new life to plastic waste. Made from 100% recycled polyester and crafted to keep you cozy through the colder months.",
    handle: "replo-beanie",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame21.png?v=1661647995",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame22.png?v=1661647995",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame23.png?v=1661647995",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame24.png?v=1661647995",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame25.png?v=1661647995",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame26.png?v=1661648003",
    ],
    variants: [
      {
        id: 43_258_891_469_037,
        title: "Black",
        name: "Replo Beanie - Black",
        price: "9.99",
        sku: null,
        compare_at_price: "9.99",
        option1: "Black",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame21.png?v=1661647995",
        },
        available: true,
      },
      {
        id: 43_258_891_501_805,
        title: "Light Blue",
        name: "Replo Beanie - Light Blue",
        price: "9.99",
        sku: null,
        compare_at_price: "9.99",
        option1: "Light Blue",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame23.png?v=1661647995",
        },
        available: true,
      },
      {
        id: 43_258_891_534_573,
        title: "Navy",
        name: "Replo Beanie - Navy",
        price: "9.99",
        sku: null,
        compare_at_price: "9.99",
        option1: "Navy",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame24.png?v=1661647995",
        },
        available: true,
      },
      {
        id: 43_258_891_567_341,
        title: "Olive",
        name: "Replo Beanie - Olive",
        price: "9.99",
        sku: null,
        compare_at_price: "9.99",
        option1: "Olive",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame25.png?v=1661647995",
        },
        available: true,
      },
      {
        id: 43_258_891_600_109,
        title: "Neon",
        name: "Replo Beanie - Neon",
        price: "9.99",
        sku: null,
        compare_at_price: "9.99",
        option1: "Neon",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame26.png?v=1661648003",
        },
        available: true,
      },
    ],
    options: ["Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame21.png?v=1661647995",
    selling_plan_groups: [],
  },
  {
    id: 7_912_927_658_221,
    title: "Replo Tote",
    type: "",
    description:
      "Replo's reusable cotton tote bag is a simple essential, upgraded with premium materials and functional design. Made of 100% organic cotton, it perfectly fits a laptop and offers secure storage for wallets, keys and other personal belongings. Our cotton tote bag is ideal for working on the go, as a carry-on bag for travel, and of course as a replacement to disposable shopping bags.",
    handle: "replo-tote",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame28.png?v=1661648447",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame29.png?v=1661648447",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame30.png?v=1661648447",
    ],
    variants: [
      {
        id: 43_258_896_122_093,
        title: "White",
        name: "Replo Tote - White",
        price: "19.99",
        sku: null,
        compare_at_price: null,
        option1: "White",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame28.png?v=1661648447",
        },
        available: true,
      },
      {
        id: 43_258_896_154_861,
        title: "Orange",
        name: "Replo Tote - Orange",
        price: "19.99",
        sku: null,
        compare_at_price: null,
        option1: "Orange",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame30.png?v=1661648447",
        },
        available: true,
      },
      {
        id: 43_258_896_187_629,
        title: "Red",
        name: "Replo Tote - Red",
        price: "19.99",
        sku: null,
        compare_at_price: null,
        option1: "Red",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame29.png?v=1661648447",
        },
        available: true,
      },
    ],
    options: ["Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame28.png?v=1661648447",
    selling_plan_groups: [],
  },
  {
    id: 7_912_932_802_797,
    title: "Replo Towel",
    type: "",
    description:
      "Our exceptionally soft, quick-drying towels are made using innovative Replo technology. Plush and absorbent – these luxe bath linens will leave you feeling fresh and clean. Made of 100% long-staple Turkish cotton. Feature a ribbed dobby design for a clean, modern look. Made in Turkey. Loomed from superior 100% long-staple Turkish cotton at 700 grams per square meter, our bath collection is plush but not overly dense. Our towels are free of harmful synthetic dyes, making them safer for you and healthier for the planet, but more susceptible to discoloration when exposed to certain harsh chemicals. If you’re following a skincare routine that includes ingredients such as benzoyl peroxide, we recommend white towels.",
    handle: "replo-towel",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame35.png?v=1661653301",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame36.png?v=1661653301",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame38.png?v=1661653301",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame39.png?v=1661653301",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame40.png?v=1661653301",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame41.png?v=1661653315",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame42.png?v=1661653315",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame43.png?v=1661653315",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame44.png?v=1661653316",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame45.png?v=1661653316",
    ],
    variants: [
      {
        id: 43_258_905_821_421,
        title: "White / 50cm x 100cm",
        name: "Replo Towel - White / 50cm x 100cm",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "White",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame45.png?v=1661653316",
        },
        option2: "50cm x 100cm",
        available: true,
      },
      {
        id: 43_258_905_854_189,
        title: "White / 100cm x 210cm",
        name: "Replo Towel - White / 100cm x 210cm",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "White",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame36.png?v=1661653301",
        },
        option2: "100cm x 210cm",
        available: true,
      },
      {
        id: 43_258_905_886_957,
        title: "Black / 50cm x 100cm",
        name: "Replo Towel - Black / 50cm x 100cm",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "Black",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame41.png?v=1661653315",
        },
        option2: "50cm x 100cm",
        available: true,
      },
      {
        id: 43_258_905_919_725,
        title: "Black / 100cm x 210cm",
        name: "Replo Towel - Black / 100cm x 210cm",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "Black",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame42.png?v=1661653315",
        },
        option2: "100cm x 210cm",
        available: true,
      },
      {
        id: 43_258_905_952_493,
        title: "Graphite / 50cm x 100cm",
        name: "Replo Towel - Graphite / 50cm x 100cm",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "Graphite",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame43.png?v=1661653315",
        },
        option2: "50cm x 100cm",
        available: true,
      },
      {
        id: 43_258_905_985_261,
        title: "Graphite / 100cm x 210cm",
        name: "Replo Towel - Graphite / 100cm x 210cm",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "Graphite",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame44.png?v=1661653316",
        },
        option2: "100cm x 210cm",
        available: true,
      },
    ],
    options: ["Color", "Size"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame35.png?v=1661653301",
    selling_plan_groups: [],
  },
  {
    id: 7_913_103_130_861,
    title: "Replo Staple Tee",
    type: "",
    description:
      "Featured here is our Replo Staple Tee. It’s made of the same combed cotton as our signature cotton crews and has the same unisex shape, but what sets it apart is an exclusive 100% quarter-circle logomark.",
    handle: "replo-staple-tee",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-black-heather-front-630ad4055cd0d.png?v=1661670147",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-black-heather-front-630ad4055d506.png?v=1661670147",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-black-heather-front-630ad4055d872.png?v=1661670147",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-black-heather-zoomed-in-630ad4055db2c.png?v=1661670147",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-army-zoomed-in-630ad405708ec.png?v=1661670147",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-brown-zoomed-in-630ad40563fb9.png?v=1661670147",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-burnt-orange-zoomed-in-630ad40596be4.png?v=1661670147",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-citron-zoomed-in-630ad4064cd64.png?v=1661670147",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-heather-mint-zoomed-in-630ad40630fae.png?v=1661670147",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-heather-orange-zoomed-in-630ad40586dd9.png?v=1661670147",
    ],
    variants: [
      {
        id: 43_259_003_601_133,
        title: "Small / Black",
        name: "Replo Staple Tee - Small / Black",
        price: "9.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-black-heather-zoomed-in-630ad4055db2c.png?v=1661670147",
        },
        option2: "Black",
        available: true,
      },
      {
        id: 43_259_003_666_669,
        title: "Small / Army",
        name: "Replo Staple Tee - Small / Army",
        price: "9.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-army-zoomed-in-630ad405708ec.png?v=1661670147",
        },
        option2: "Army",
        available: true,
      },
      {
        id: 43_259_003_633_901,
        title: "Small / Brown",
        name: "Replo Staple Tee - Small / Brown",
        price: "9.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-brown-zoomed-in-630ad40563fb9.png?v=1661670147",
        },
        option2: "Brown",
        available: true,
      },
      {
        id: 43_259_003_699_437,
        title: "Small / Burnt Orange",
        name: "Replo Staple Tee - Small / Burnt Orange",
        price: "9.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-burnt-orange-zoomed-in-630ad40596be4.png?v=1661670147",
        },
        option2: "Burnt Orange",
        available: true,
      },
      {
        id: 43_259_003_732_205,
        title: "Small / Citron",
        name: "Replo Staple Tee - Small / Citron",
        price: "9.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-citron-zoomed-in-630ad4064cd64.png?v=1661670147",
        },
        option2: "Citron",
        available: true,
      },
      {
        id: 43_259_012_186_349,
        title: "Small / Heather",
        name: "Replo Staple Tee - Small / Heather",
        price: "9.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-heather-mint-zoomed-in-630ad40630fae.png?v=1661670147",
        },
        option2: "Heather",
        available: true,
      },
      {
        id: 43_259_003_830_509,
        title: "Small / Light Blue",
        name: "Replo Staple Tee - Small / Light Blue",
        price: "9.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-light-blue-zoomed-in-630ad405efb45.png?v=1661670147",
        },
        option2: "Light Blue",
        available: true,
      },
      {
        id: 43_259_003_896_045,
        title: "Small / Maroon",
        name: "Replo Staple Tee - Small / Maroon",
        price: "9.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-maroon-zoomed-in-630ad4056a247.png?v=1661670147",
        },
        option2: "Maroon",
        available: true,
      },
      {
        id: 43_259_003_928_813,
        title: "Small / Mustard",
        name: "Replo Staple Tee - Small / Mustard",
        price: "9.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-mustard-zoomed-in-630ad405a4fea.png?v=1661670147",
        },
        option2: "Mustard",
        available: true,
      },
      {
        id: 43_259_003_994_349,
        title: "Small / Pink",
        name: "Replo Staple Tee - Small / Pink",
        price: "9.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-pink-zoomed-in-630ad405d8917.png?v=1661670147",
        },
        option2: "Pink",
        available: true,
      },
    ],
    options: ["Size", "Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-staple-t-shirt-black-heather-front-630ad4055cd0d.png?v=1661670147",
    selling_plan_groups: [],
  },
  {
    id: 7_913_116_926_189,
    title: "Replo Scrunchie",
    type: "",
    description:
      "Keep flyaways under wraps with this stretchy and functional Replo Scrunchie. She'll love this set for school, practice and exploring the outdoors.",
    handle: "replo-scrunchie",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame46.png?v=1661670652",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame47.png?v=1661670653",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame48.png?v=1661670653",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame49.png?v=1661670653",
    ],
    variants: [
      {
        id: 43_259_017_396_461,
        title: "Default Title",
        name: "Replo Scrunchie - Default Title",
        price: "12.99",
        sku: null,
        compare_at_price: null,
        option1: "Default Title",
        featured_image: null,
        available: true,
      },
    ],
    options: ["Title"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/Frame46.png?v=1661670652",
    selling_plan_groups: [],
  },
  {
    id: 7_913_121_186_029,
    title: "Replo Mug",
    type: "",
    description: "Find a moment of comfort with our naturally stylish mugs.",
    handle: "replo-mug",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/white-ceramic-mug-with-color-inside-black-11oz-left-630b1aa5df677.png?v=1661672151",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/white-ceramic-mug-with-color-inside-black-11oz-front-630b1aa5df5ea.png?v=1661672151",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/white-ceramic-mug-with-color-inside-black-11oz-right-630b1aa5df50a.png?v=1661672151",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/white-ceramic-mug-with-color-inside-orange-11oz-left-630b15f0979ef.png?v=1661672151",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/white-ceramic-mug-with-color-inside-pink-11oz-left-630b15f098430.png?v=1661672150",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/white-ceramic-mug-with-color-inside-yellow-11oz-left-630b15f09859e.png?v=1661672150",
    ],
    variants: [
      {
        id: 43_259_022_639_341,
        title: "Black",
        name: "Replo Mug - Black",
        price: "14.99",
        sku: null,
        compare_at_price: null,
        option1: "Black",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/white-ceramic-mug-with-color-inside-black-11oz-left-630b1aa5df677.png?v=1661672151",
        },
        available: true,
      },
      {
        id: 43_259_017_724_141,
        title: "Orange",
        name: "Replo Mug - Orange",
        price: "14.99",
        sku: null,
        compare_at_price: null,
        option1: "Orange",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/white-ceramic-mug-with-color-inside-orange-11oz-left-630b15f0979ef.png?v=1661672151",
        },
        available: true,
      },
      {
        id: 43_259_017_756_909,
        title: "Pink",
        name: "Replo Mug - Pink",
        price: "14.99",
        sku: null,
        compare_at_price: null,
        option1: "Pink",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/white-ceramic-mug-with-color-inside-pink-11oz-left-630b15f098430.png?v=1661672150",
        },
        available: true,
      },
      {
        id: 43_259_017_789_677,
        title: "Yellow",
        name: "Replo Mug - Yellow",
        price: "14.99",
        sku: null,
        compare_at_price: null,
        option1: "Yellow",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/white-ceramic-mug-with-color-inside-yellow-11oz-left-630b15f09859e.png?v=1661672150",
        },
        available: true,
      },
    ],
    options: ["Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/white-ceramic-mug-with-color-inside-black-11oz-left-630b1aa5df677.png?v=1661672151",
    selling_plan_groups: [],
  },
  {
    id: 7_913_123_971_309,
    title: "Replo Hoodie",
    type: "",
    description:
      "This lightweight sweatshirt is stretchy when you need it but retains fit and shape over time. Buttery soft in all the right places with an athletic fit. Features low profile pockets, crossover collar, and a double-walled hood.",
    handle: "replo-hoodie",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-black-front-630a66b43daa9.png?v=1661671617",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-black-front-630a66b43e3bc.png?v=1661671617",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-black-front-630a66b43e83c.png?v=1661671617",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-black-front-630a66b43ea92.png?v=1661671618",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-black-zoomed-in-630a66b43d7cc.png?v=1661671618",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-black-zoomed-in-630a66b43e5f8.png?v=1661671618",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-carbon-grey-front-630a66b4ccbcf.png?v=1661671618",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-carolina-blue-front-630a66b4b6ab3.png?v=1661671618",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-charcoal-heather-front-630a66b44f857.png?v=1661671618",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-dusty-rose-front-630a66b496df6.png?v=1661671618",
    ],
    variants: [
      {
        id: 43_259_021_033_709,
        title: "Small / Black",
        name: "Replo Hoodie - Small / Black",
        price: "69.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-black-front-630a66b43daa9.png?v=1661671617",
        },
        option2: "Black",
        available: true,
      },
      {
        id: 43_259_021_066_477,
        title: "Small / Carbon Grey",
        name: "Replo Hoodie - Small / Carbon Grey",
        price: "69.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-carbon-grey-front-630a66b4ccbcf.png?v=1661671618",
        },
        option2: "Carbon Grey",
        available: true,
      },
      {
        id: 43_259_021_099_245,
        title: "Small / Carolina Blue",
        name: "Replo Hoodie - Small / Carolina Blue",
        price: "69.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-carolina-blue-front-630a66b4b6ab3.png?v=1661671618",
        },
        option2: "Carolina Blue",
        available: true,
      },
      {
        id: 43_259_021_132_013,
        title: "Small / Charcoal Heather",
        name: "Replo Hoodie - Small / Charcoal Heather",
        price: "69.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-charcoal-heather-front-630a66b44f857.png?v=1661671618",
        },
        option2: "Charcoal Heather",
        available: true,
      },
      {
        id: 43_259_021_164_781,
        title: "Small / Dusty Rose",
        name: "Replo Hoodie - Small / Dusty Rose",
        price: "69.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-dusty-rose-front-630a66b496df6.png?v=1661671618",
        },
        option2: "Dusty Rose",
        available: true,
      },
      {
        id: 43_259_021_197_549,
        title: "Small / Army",
        name: "Replo Hoodie - Small / Army",
        price: "69.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-military-green-front-630a66b4739af.png?v=1661671618",
        },
        option2: "Army",
        available: true,
      },
      {
        id: 43_259_021_230_317,
        title: "Small / Navy Blazer",
        name: "Replo Hoodie - Small / Navy Blazer",
        price: "69.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-navy-blazer-front-630a66b43fe8c.png?v=1661671618",
        },
        option2: "Navy Blazer",
        available: true,
      },
      {
        id: 43_259_021_263_085,
        title: "Small / Sky Blue",
        name: "Replo Hoodie - Small / Sky Blue",
        price: "69.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-sky-blue-front-630a66b4e3ecd.png?v=1661671618",
        },
        option2: "Sky Blue",
        available: true,
      },
      {
        id: 43_259_021_295_853,
        title: "Small / Purple",
        name: "Replo Hoodie - Small / Purple",
        price: "69.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-purple-front-630a66b4655d3.png?v=1661671618",
        },
        option2: "Purple",
        available: true,
      },
      {
        id: 43_259_021_328_621,
        title: "Small / White",
        name: "Replo Hoodie - Small / White",
        price: "69.99",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-white-front-630a66b506f62.png?v=1661671618",
        },
        option2: "White",
        available: true,
      },
    ],
    options: ["Size", "Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-premium-hoodie-black-front-630a66b43daa9.png?v=1661671617",
    selling_plan_groups: [],
  },
  {
    id: 7_913_126_265_069,
    title: "Replo x Andytown Sulawesi",
    type: "",
    description:
      "When the Dutch first brought coffee to Indonesia, it was cultivated on large estates that would later become government owned after independence. The Rantekarua Estate remained mostly abandoned until the government gave cultivation rights to PT Sulotco Jaya in 1987. Since then, the 3000-acre estate has undergone heavy renovation. More than 500 acres has been converted into a natural forest, making the estate a beacon of innovation in matters of land conservation. Coffee is cultivated using organic inputs, including manure from more than 2000 sheep grazing on the estate. During harvest, cherries are picked and taken to the Sulotco processing station in the Bolokan Valley. Cherries are floated in water for 24 hours to sort out the damaged ones. The remaining cherries are then placed on covered, raised beds, slowly dried for at least 2 weeks, and then stored until it’s time to mill and export. This is done in the same processing facility and includes hand-sorting as the final step. This integrated process from estate to export provides a tremendous advantage in managing quality and traceability. We’ve had our eye on this coffee since we tasted it during an origin trip to Sulawesi in 2019, and we reserved several bags as soon as we heard it was on its way! This is a juicy, natural processed coffee that carries some characteristic herbal notes that Indonesian coffees are known for. We taste pomegranate and black cherry up front before it settles into soft herbal flavors that remind us of marjoram. This is a refreshing coffee that is delicious hot but would shine when served iced!",
    handle: "andytown-sulawesi",
    status: "ACTIVE",
    images: [
      "https://andytown-public.s3.us-west-1.amazonaws.com/placeholder-products/sulawesi.jpg",
      "https://andytown-public.s3.us-west-1.amazonaws.com/placeholder-products/sulawesi-back.jpg",
    ],
    variants: [
      {
        id: 43_259_022_508_269,
        title: "Default Title",
        name: "Replo x Andytown Sulawesi - Default Title",
        price: "14.99",
        sku: null,
        compare_at_price: null,
        option1: "Default Title",
        featured_image: null,
        available: true,
      },
    ],
    options: ["Title"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/front.jpg?v=1661671966",
    selling_plan_groups: [
      {
        id: "2701b1b9-bce0-4dad-bdab-2f5b9dc872b5",
        app_id: "replo",
        options: [
          {
            name: "Delivery Every",
            position: 1,
            values: ["Every 30 Days", "Every 60 Days"],
          },
        ],
        selling_plans: [
          {
            id: 64_578_907_986, // Random
            name: "Subscribe and Save 15%",
            description:
              "Every month, get a new bag of coffee delivered to your door.",
            options: [
              {
                name: "Delivery Every",
                position: 1,
                value: "Every 30 Days",
              },
            ],
            price_adjustments: [{ value_type: "percentage", value: 15 }],
          },
          {
            id: 9_087_274_372_688, // Random
            name: "Bi-monthly, Save 20%",
            description:
              "Every 2 months, get a new bag of coffee delivered to your door.",
            options: [
              {
                name: "Delivery Every",
                position: 1,
                value: "Every 60 Days",
              },
            ],
            price_adjustments: [{ value_type: "percentage", value: 20 }],
          },
        ],
      },
    ],
  },
  {
    id: 7_913_128_329_453,
    title: "Replo x Andytown Peru",
    type: "",
    description:
      "Origin: PeruRegion: Satipo, JuninGrower: Edith & Ivan Meza SagarvinagaMASL: 1450-1600Variety: CaturraProcess: WashedFinca Tasta is a farm located in the community of Challhuamayo, Laylla District, in the province of Satipo of the Junin Region of Peru. The farm is owned and operated by two young siblings, Edith & Ivan Meza Sagarvinaga. They are second generation coffee producers, and although Edith & Ivan only started farming specialty coffee nine years ago, they have gained recognition for their high quality coffees.Finca Tasta has become a major source of employment for people from Challhuamayo. The farm has 6 permanent employees year round, and 36 employees during the harvest season. The farm is a 56-acre estate with 19 acres cultivated with coffee and 22 acres left for natural forest preservation. They are against aggressive cultivation, and believe in living in harmony with the forest and animals. In addition to coffee, Edith and Ivan produce plantains, yucca, tomatoes, sugarcane, raspberries, and pumpkins–all of which are sold at organic shops in Lima.During their short time as farmers, Edith and Ivan have started to experiment with different processing methods. Their hope is to continue their processing experiments so that they can diversify their offerings. In our second year of featuring Finca Tasta, we’re highlighting a delightful honey processed coffee. It has the brightness of a mandarin orange; a syrupy, almost custard-y mouthfeel reminiscent of a ripe hachiya persimmon, and a lingering toffee aftertaste. Its fruit notes are more subtle, but it’s incredibly pronounced sweetness is certain to impress coffee aficionados across the flavor spectrum!",
    handle: "andytown-peru",
    status: "ACTIVE",
    images: [
      "https://andytown-public.s3.us-west-1.amazonaws.com/placeholder-products/peru.jpg",
      "https://andytown-public.s3.us-west-1.amazonaws.com/placeholder-products/peru-back.jpg",
    ],
    variants: [
      {
        id: 43_259_022_606_573,
        title: "Default Title",
        name: "Replo x Andytown Peru - Default Title",
        price: "21.99",
        sku: null,
        compare_at_price: null,
        option1: "Default Title",
        featured_image: null,
        available: true,
      },
    ],
    options: ["Title"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/front_641dafd8-fff0-43aa-be55-d34bb92924e5.jpg?v=1661672075",
    selling_plan_groups: [
      {
        id: "2701b1b9-bce0-4dad-bdab-2f5b9dc872b5",
        app_id: "replo",
        options: [
          {
            name: "Delivery Every",
            position: 1,
            values: ["Every 30 Days", "Every 60 Days"],
          },
        ],
        selling_plans: [
          {
            id: 78_904_629_839, // Random
            name: "Subscribe and Save 15%",
            description:
              "Every month, get a new bag of coffee delivered to your door.",
            options: [
              {
                name: "Delivery Every",
                position: 1,
                value: "Every 30 Days",
              },
            ],
            price_adjustments: [{ value_type: "percentage", value: 15 }],
          },
          {
            id: 67_894_233_546, // Random
            name: "Bi-monthly, Save 20%",
            description:
              "Every 2 months, get a new bag of coffee delivered to your door.",
            options: [
              {
                name: "Delivery Every",
                position: 1,
                value: "Every 60 Days",
              },
            ],
            price_adjustments: [{ value_type: "percentage", value: 20 }],
          },
        ],
      },
    ],
  },
  {
    id: 7_913_131_835_629,
    title: "Replo Long Sleeve Shirt",
    type: "",
    description:
      "Simply designed and soft to the touch, the Replo Long Sleeve Shirt uses revolutionary natural materials to keep you feeling fresh all day, any day. Made in Peru.",
    handle: "replo-long-sleeve-shirt",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-black-front-630b1c005c373.png?v=1661672630",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-black-front-630b1c005c582.png?v=1661672630",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-light-pink-front-630b1c005dcd3.png?v=1661672630",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-military-green-front-630b1c005d1fe.png?v=1661672630",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-navy-front-630b1c005ca7b.png?v=1661672630",
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-white-front-630b1c005edd6.png?v=1661672630",
    ],
    variants: [
      {
        id: 43_259_023_261_933,
        title: "Small / Black",
        name: "Replo Long Sleeve Shirt - Small / Black",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-black-front-630b1c005c582.png?v=1661672630",
        },
        option2: "Black",
        available: true,
      },
      {
        id: 43_259_023_294_701,
        title: "Small / Olive",
        name: "Replo Long Sleeve Shirt - Small / Olive",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-military-green-front-630b1c005d1fe.png?v=1661672630",
        },
        option2: "Olive",
        available: true,
      },
      {
        id: 43_259_023_327_469,
        title: "Small / Navy",
        name: "Replo Long Sleeve Shirt - Small / Navy",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-navy-front-630b1c005ca7b.png?v=1661672630",
        },
        option2: "Navy",
        available: true,
      },
      {
        id: 43_259_023_687_917,
        title: "Small / Pink",
        name: "Replo Long Sleeve Shirt - Small / Pink",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-light-pink-front-630b1c005dcd3.png?v=1661672630",
        },
        option2: "Pink",
        available: true,
      },
      {
        id: 43_259_023_360_237,
        title: "Small / White",
        name: "Replo Long Sleeve Shirt - Small / White",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-white-front-630b1c005edd6.png?v=1661672630",
        },
        option2: "White",
        available: true,
      },
      {
        id: 43_259_023_393_005,
        title: "Medium / Black",
        name: "Replo Long Sleeve Shirt - Medium / Black",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-black-front-630b1c005c582.png?v=1661672630",
        },
        option2: "Black",
        available: true,
      },
      {
        id: 43_259_023_425_773,
        title: "Medium / Olive",
        name: "Replo Long Sleeve Shirt - Medium / Olive",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-military-green-front-630b1c005d1fe.png?v=1661672630",
        },
        option2: "Olive",
        available: true,
      },
      {
        id: 43_259_023_458_541,
        title: "Medium / Navy",
        name: "Replo Long Sleeve Shirt - Medium / Navy",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-navy-front-630b1c005ca7b.png?v=1661672630",
        },
        option2: "Navy",
        available: true,
      },
      {
        id: 43_259_023_720_685,
        title: "Medium / Pink",
        name: "Replo Long Sleeve Shirt - Medium / Pink",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-light-pink-front-630b1c005dcd3.png?v=1661672630",
        },
        option2: "Pink",
        available: true,
      },
      {
        id: 43_259_023_491_309,
        title: "Medium / White",
        name: "Replo Long Sleeve Shirt - Medium / White",
        price: "0",
        sku: null,
        compare_at_price: null,
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-white-front-630b1c005edd6.png?v=1661672630",
        },
        option2: "White",
        available: true,
      },
    ],
    options: ["Size", "Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/mens-long-sleeve-shirt-black-front-630b1c005c373.png?v=1661672630",
    selling_plan_groups: [],
  },
  ...REPLO_UI_PRODUCTS,
];

export const fakeProductsMap = fakeProducts.reduce(
  (productsMap, fakeProduct) => ({
    ...productsMap,
    [fakeProduct.id]: fakeProduct,
  }),
  {},
);

import DeltaIndicator from "@editor/components/analytics/DeltaIndicator";
import classNames from "classnames";
import * as React from "react";
import { formatNumberWithCommas, keepTwoDecimals } from "replo-utils/lib/math";

import {
  DEFAULT_CURRENCY,
  METRICS_REQUIRING_CURRENCY,
  METRICS_REQUIRING_PERCENTAGE,
} from "@/features/analytics/constants";

const TableCellContent: React.FC<{
  name: string;
  value: number | undefined;
  delta?: number;
  doesCompareValueExist: boolean;
  className?: string;
}> = ({ name, value, delta, doesCompareValueExist, className }) => {
  const showPercentage = METRICS_REQUIRING_PERCENTAGE.includes(name);
  const showCurrency = METRICS_REQUIRING_CURRENCY.includes(name);

  return (
    <div
      className={classNames(
        "flex flex-row items-center justify-left gap-3",
        className,
      )}
    >
      <div className="text-xs min-w-[40px]">
        {showCurrency && DEFAULT_CURRENCY}
        {formatNumberWithCommas(keepTwoDecimals(value ?? 0))}
        {showPercentage && `%`}
      </div>

      {delta !== undefined && (
        <div
          className={classNames("text-xs", {
            "text-gray-600": delta == 0,
            "text-green-600": delta > 0,
            "text-red-600": delta < 0,
          })}
        >
          <DeltaIndicator
            delta={keepTwoDecimals(delta)}
            doesCompareValueExist={doesCompareValueExist}
          />
        </div>
      )}
    </div>
  );
};

export default TableCellContent;

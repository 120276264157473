import findLast from "lodash-es/findLast";
import { ReploCustomEvents } from "replo-runtime/shared/enums";
import {
  DEFAULT_ACTIVE_CURRENCY,
  DEFAULT_ACTIVE_LANGUAGE,
  DEFAULT_ACTIVE_SHOPIFY_URL_ROOT,
  DEFAULT_MONEY_FORMAT,
  getActiveShopifyUrlRoot,
  getFeatures,
} from "replo-runtime/shared/liquid";
import type {
  Action,
  ProductVariantAddToCart,
  ReploShopifyProduct,
  StoreProduct,
} from "replo-runtime/shared/types";
import { combineActionsIfIsNecessary } from "replo-runtime/shared/utils/combineActions";
import {
  getCurrentComponentContext,
  resolveContextValue,
} from "replo-runtime/shared/utils/context";
import { selectRuntimeElementRootNode } from "replo-runtime/shared/utils/dom";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { firstGroupMatchOfExpression } from "replo-runtime/shared/utils/regex";
import type { TempCartItem } from "replo-runtime/shared/utils/temporaryCart";
import type { Context } from "replo-runtime/store/AlchemyVariable";
import {
  evaluateVariable,
  evaluateVariableAsString,
} from "replo-runtime/store/AlchemyVariable";
import { getProduct } from "replo-runtime/store/ReploProduct";
import type { SelectedSellingPlanIdOrOneTimePurchase } from "replo-runtime/store/utils/product";
import { resolveProductSellingPlans } from "replo-runtime/store/utils/product";
import { replaceLeadingSlashWithRoot } from "replo-runtime/utils/url";
import { scrollToElement } from "replo-utils/dom/scroll";
import { filterNulls } from "replo-utils/lib/array";
import { isNotNullish, isNullish } from "replo-utils/lib/misc";

import type { GlobalWindow } from "../shared/Window";

const asyncUpdateCart = async (
  globalWindow: GlobalWindow | null,
  data: object,
) => {
  await fetch("/cart/update.js", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (globalWindow?.location.pathname.includes("/cart")) {
    globalWindow.location.reload();
  }
};

const asyncAddToCart = async (
  globalWindow: GlobalWindow | null,
  data: object,
) => {
  const res = await fetch("/cart/add.js", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const response = (await res.json()) as any;
  // Note (Fran, 2023-01-30): If the response doesn't have items, it means
  // that the add to cart failed completely
  // https://shopify.dev/api/ajax/reference/cart#post-locale-cart-add-js
  if (!response.items) {
    throw new Error(`${response.message} - ${response.description}`);
  }
  if (globalWindow && globalWindow.location.pathname.includes("/cart")) {
    globalWindow.location.reload();
  }
};

const asyncClearCart = async (globalWindow: GlobalWindow | null) => {
  await fetch("/cart/clear.js", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (globalWindow && globalWindow.location.pathname.includes("/cart")) {
    globalWindow.location.reload();
  }
};

type AddToCartItem = {
  id: string;
  quantity: number;
  properties: Record<string, any>;
  selling_plan?: number | string;
};

export function getItemsToAddToCart(
  products: ProductVariantAddToCart[],
  context: AlchemyActionContext,
) {
  const globalWindow = context.componentContext.globalWindow;
  const productWrapperComponentId =
    context.componentContext.state.productWrapperComponentId;

  // NOTE (Matt 2023-11-06): KachingBundles is a WebComponent widget with a `selectedVariants` fn
  // that returns an array of variants and quantities. If this widget exists within the product
  // container then the users selected variants and quantities must override the ATC variant/quantity.
  const kachingBundles = globalWindow?.document.querySelector(
    `[data-rid="${productWrapperComponentId}"] kaching-bundles-block`,
  ) as
    | (HTMLElement & {
        selectedVariants: () => [{ variantId: string; quantity: number }];
      })
    | null;
  if (kachingBundles && products[0]) {
    const defaultATCSettings = products[0];
    products = kachingBundles
      .selectedVariants()
      .map(({ variantId, quantity }) => ({
        ...defaultATCSettings,
        quantity,
        overridingVariantId: variantId,
      }));
  }

  const items = filterNulls(
    products.map(
      ({
        product,
        sellingPlanId,
        quantity: _quantityOrDynamic,
        allowThirdPartySellingPlan,
        overridingVariantId,
      }) => {
        const resolvedProduct = getProduct(product, context.componentContext, {
          products: context.products,
          // Note (Noah, 2022-09-05): Hardcoding the currency/money
          // format/language is okay since we are not referencing prices
          currencyCode: DEFAULT_ACTIVE_CURRENCY,
          language: DEFAULT_ACTIVE_LANGUAGE,
          moneyFormat: DEFAULT_MONEY_FORMAT,
          productMetafieldValues: {},
          variantMetafieldValues: {},
          // NOTE Ben 2024-02-21: We want to resolve the product ref we're passing off here,
          // _not_ allowing a PDP or product component to override it.
          isInsideTemplateProductComponentOverride: false,
          templateProduct: context.templateProduct,
        })!;
        const productForm: HTMLFormElement | undefined = Array.from(
          globalWindow?.document.querySelectorAll(
            `[data-rid="${productWrapperComponentId}"] product-form form`,
          ) as NodeListOf<HTMLFormElement>,
        ).find(
          (f: HTMLFormElement) =>
            Number(resolvedProduct?.variantId) ===
            Number(new FormData(f).get("id")),
        ) as HTMLFormElement | undefined;

        // Note (Noah, 2023-03-12): If the selling plan id was specified as a static
        // id or a dynamic value, we're going to evaluate it if needed and use it.
        // However, we DO NOT check the current component's state for this selling plan
        // id, because the selected selling plan id is the first selling plan, by default
        // (it needs to be this way, because in order to have OPT/SNS tabs work, we need
        // to have a selling plan selected by default)
        let itemSellingPlanId: number | string | null | undefined =
          sellingPlanId;
        if (
          itemSellingPlanId &&
          typeof itemSellingPlanId === "string" &&
          isDynamicDataValue(itemSellingPlanId)
        ) {
          const sellingPlanResolved = evaluateVariable(
            itemSellingPlanId,
            context.componentContext,
          ) as { id: string | number };
          if (isNotNullish(sellingPlanResolved) && sellingPlanResolved.id) {
            itemSellingPlanId = sellingPlanResolved.id;
          } else {
            // Note (Matt, 2024-03-20): If the selling plan id is dynamic, but we
            // can't resolve it, that indicates that the selected selling plan is null
            // (OTP). If we don't set this here, then resolveProductSellingPlans will
            // set a different plan.
            itemSellingPlanId = null;
          }
        }

        if (
          itemSellingPlanId &&
          !resolvedProduct.variant?.sellingPlanGroupIds.includes(
            String(itemSellingPlanId),
          )
        ) {
          const { selectedSellingPlan } = resolveProductSellingPlans(
            resolvedProduct,
            resolvedProduct.variant,
            itemSellingPlanId as SelectedSellingPlanIdOrOneTimePurchase,
          );
          itemSellingPlanId = selectedSellingPlan?.id;
        }

        // NOTE (Matt 2023-11-06): If an overriding variant Id exists, then we should default to use that.
        // Overriding variant Id only comes into play with specific third party integrations.
        const id = overridingVariantId || resolvedProduct?.variantId;

        const lineItemProperties = {} as any;

        if (productForm) {
          if (!productForm.checkValidity()) {
            productForm.reportValidity();
            throw new Error("Form is invalid");
          }

          const formData = new FormData(productForm);

          // NOTE (Matt 2023-10-26, USE-469): There is now an attribute on the ATC action called allowThirdPartySellingPlan
          // which allows the user to opt-in to a third party selling_plan form input overriding any sellingPlanId.
          // For legacy ATC actions, this would be undefined, in which case we need to always allow the form inputs to override
          // the selected selling plan ID (as this was the logic before the allowThirdPartySellingPlan was implemented).
          if (
            allowThirdPartySellingPlan ||
            allowThirdPartySellingPlan == undefined
          ) {
            const sellingPlanIdFromFormData = formData.get(
              "selling_plan",
            ) as unknown as number | null;
            // Note (Sebas, 2023-05-02): When the user selects a one-time purchase on
            // selling plan using the recharge widget, the selling plan id will be an
            // empty string.
            if (String(sellingPlanIdFromFormData) === "") {
              itemSellingPlanId = null;
            } else if (sellingPlanIdFromFormData) {
              itemSellingPlanId = sellingPlanIdFromFormData;
            }

            // NOTE (Matt 2024-01-29): This code is for the Stay Ai Subscription Widget.
            // If the retextionBuyBox exists with the key of the product id, we can compare
            // its form to the productForm variable to understand if its associated with
            // this action. getSelectedSellingPlan can be NaN, so we check that before
            // overriding the selling plan id.
            if (
              globalWindow?.retextionBuyBox?.[`${resolvedProduct?.productId}--`]
                ?.form == productForm
            ) {
              const stayAISellingPlanId =
                globalWindow?.retextionBuyBox?.[
                  `${resolvedProduct?.productId}--`
                ].getSellingPlanId();
              if (!Number.isNaN(stayAISellingPlanId)) {
                itemSellingPlanId = stayAISellingPlanId;
              }
            }
          }

          // Note (Matt, 2023-08-25): There are some integrations that will add custom form values
          // with line item properties. We need to look at all form elements to see if we need
          // to apply any line item properties. We also need to check the value of `productForm.elements.id`
          // to make sure we're only applying line item properties to the variant from the form.
          if (formData.get("id") == String(id)) {
            [...productForm.elements].forEach((formInput) => {
              const { name, value, type, checked } =
                formInput as HTMLInputElement;
              // Note (Matt, 2023-08-28): This Regex is used to parse a line item property name from the input's name
              // Example: 'properties[My Line Item Property Name]' => 'My Line Item Property Name'
              const propertyName = name
                .match(/\[(.*?)]/)?.[0]
                ?.replace(/[[\]]/g, "");
              if (
                propertyName &&
                value !== undefined &&
                (type !== "radio" || checked)
              ) {
                lineItemProperties[propertyName] = value;
              }
            });
          }
        }

        if (isNullish(id)) {
          return null;
        }

        const evaluated = evaluateVariableAsString(
          String(_quantityOrDynamic),
          context.componentContext,
        );
        const quantity =
          _quantityOrDynamic && evaluated
            ? Number.parseInt(evaluated)
            : context.componentContext.state.product?.quantity ?? 1;

        const item: AddToCartItem = {
          // TODO (Fran, 2022-11-18): id can be number or a string, b/c sometimes from backend we get both
          // type of ids, we should fix it from backend probably
          id: String(id),
          quantity: quantity,
          properties: lineItemProperties,
        };

        if (itemSellingPlanId) {
          item.selling_plan = itemSellingPlanId;
        }
        return item;
      },
    ),
  );

  // Note (Fran/Ovishek, 2022-11-19): So this is about add.js. It doesn't work if we
  // we do more than two identical items with same id and or selling plan id, so we need to merge those
  // items considering id and selling plan id and add up the quantity
  const newItemsMap: Record<string, AddToCartItem> = {};
  items.forEach((item) => {
    const key = `${item.id}${item.selling_plan ?? ""}`;
    if (newItemsMap[key]) {
      newItemsMap[key]!.quantity += item.quantity;
    } else {
      newItemsMap[key] = item;
    }
  });
  return Object.values(newItemsMap);
}

const asyncApplyDiscountCode = (discountCodeUrlEncoded: string) => {
  // Note (Noah, 2021-08-19): I kid you not, the only way to apply a discount code
  // to the current cart in Shopify (as far as I can tell after an hour of scouring
  // forums) is to use the /discount link. This saves the discount code in a cookie
  // called discount_code, which is not visible (usually) until the checkout page.
  // Some themes hack it or read the cookies with js to display on the cart page,
  // but this is not common.
  return fetch(`/discount/${discountCodeUrlEncoded}`, {
    method: "GET",
  });
};

export interface AlchemyActionContext {
  componentContext: Context;
  componentId?: string;
  repeatedIndex?: string | null;
  products: StoreProduct[];
  templateProduct: StoreProduct | null;
}

/**
 * Generic alchemy action function, giving us some control over the action when defining
 * then outside of the main scope of execution.
 *
 * @author Ben 2024-02-22
 */
type AlchemyActionFunction<ActionName, ReturnType = void | null | unknown> = (
  action: Action & { type: ActionName },
  context: AlchemyActionContext,
) => ReturnType | Promise<ReturnType>;

const increaseProductQuantity: AlchemyActionFunction<
  "increaseProductQuantity"
> = (action, context) => {
  return context.componentContext.actionHooks.increaseProductQuantity?.(
    action.value ?? 1,
  );
};

const decreaseProductQuantity: AlchemyActionFunction<
  "decreaseProductQuantity"
> = (action, context) => {
  return context.componentContext.actionHooks.decreaseProductQuantity?.(
    action.value ?? 1,
  );
};

const setProductQuantity: AlchemyActionFunction<"setProductQuantity"> = (
  action,
  context,
) => {
  return context.componentContext.actionHooks.setProductQuantity?.(
    action.value ?? 1,
  );
};

const updateCart: AlchemyActionFunction<"updateCart"> = (action, context) => {
  const productVariant = resolveContextValue(
    action.value,
    context.componentContext,
  ) as ReploShopifyProduct;
  const data = {
    updates: {
      [productVariant.variant.id]: 1,
    },
  };
  return asyncUpdateCart(context.componentContext.globalWindow, data);
};

const addTemporaryCartProductsToCart: AlchemyActionFunction<
  "addTemporaryCartProductsToCart"
> = (_, context) => {
  const items: TempCartItem[] =
    context.componentContext.state.temporaryCart?.items || [];
  if (items.length === 0) {
    return null;
  }
  const lineItems = items
    .map((item) => {
      if (!item.productRef) {
        return null;
      }
      return {
        id: item.productRef.variantId,
        quantity: item.productRef?.quantity || 1,
      };
    })
    .filter(Boolean);
  if (lineItems.length > 0) {
    const data = {
      items: lineItems,
    };
    return asyncAddToCart(context.componentContext.globalWindow, data);
  }
};

const addProductVariantToCart: AlchemyActionFunction<
  "addProductVariantToCart"
> = (action, context) => {
  const globalWindow = context.componentContext.globalWindow as
    | (Window & {
        Rebuy?: { SmartCart?: { skip_open: boolean } };
      })
    | null;
  // TODO (Chance 2024-05-31): We shouldn't have to parse this because it's
  // saved in the commerce state. Leaving for now to avoid larger refactor.
  const root = globalWindow
    ? getActiveShopifyUrlRoot(globalWindow)
    : DEFAULT_ACTIVE_SHOPIFY_URL_ROOT;
  const addToCartItems = getItemsToAddToCart([action.value], context);

  if (!addToCartItems) {
    return null;
  }

  // Note (Sebas, 2023-05-22): This fixes an issue where stores with the
  // Rebuy Smart Cart enabled would open the cart when redirecting to the
  // checkout/cart page.
  if (
    globalWindow?.Rebuy?.SmartCart &&
    (action.value?.redirectToCheckout || action.value?.redirectToCart)
  ) {
    globalWindow.Rebuy.SmartCart.skip_open = true;
  }

  return asyncAddToCart(context.componentContext.globalWindow, {
    items: addToCartItems,
  })
    .then(() => {
      if (action.value?.redirectToCart && globalWindow) {
        globalWindow.location.href = replaceLeadingSlashWithRoot("/cart", root);
        return new Promise(() => {
          // never resolve, because we're redirecting
        });
      }
      if (action.value?.redirectToCheckout && globalWindow) {
        globalWindow.location.href = replaceLeadingSlashWithRoot(
          "/checkout",
          root,
        );

        return new Promise(() => {
          // never resolve, because we're redirecting
        });
      }
      return null;
    })
    .catch((error) => {
      console.error({
        error: "Add to cart failed",
        message: error.stack,
      });
    });
};

const multipleProductVariantsAddToCart: AlchemyActionFunction<
  "multipleProductVariantsAddToCart"
> = (action, context) => {
  const globalWindow = context.componentContext.globalWindow;
  // TODO (Chance 2024-05-31): We shouldn't have to parse this because it's
  // saved in the commerce state. Leaving for now to avoid larger refactor.
  const root = globalWindow
    ? getActiveShopifyUrlRoot(globalWindow)
    : DEFAULT_ACTIVE_SHOPIFY_URL_ROOT;
  const addToCartItems = getItemsToAddToCart(action.value, context);

  if (!addToCartItems) {
    return null;
  }

  return asyncAddToCart(globalWindow, {
    items: addToCartItems,
  })
    .then(() => {
      const shouldRedirectToCart = findLast(
        action.value,
        (action) => action.redirectToCart,
      );

      if (shouldRedirectToCart && globalWindow) {
        globalWindow.location.href = replaceLeadingSlashWithRoot("/cart", root);
        return new Promise(() => {
          // never resolve, because we're redirecting
        });
      }
      const shouldRedirectToCheckout = findLast(
        action.value,
        (action) => action.redirectToCheckout,
      );
      if (shouldRedirectToCheckout && globalWindow) {
        globalWindow.location.href = replaceLeadingSlashWithRoot(
          "/checkout",
          root,
        );
        return new Promise(() => {
          // never resolve, because we're redirecting
        });
      }
      return null;
    })
    .catch((error) => {
      console.error({
        error: "Add to cart failed",
        message: error.stack,
      });
    });
};

const clearCart: AlchemyActionFunction<"clearCart"> = (_action, context) => {
  return asyncClearCart(context.componentContext.globalWindow);
};

const close: AlchemyActionFunction<"close"> = (_, context) => {
  context.componentContext.actionHooks.closeCurrentModal?.();
};

const redirect: AlchemyActionFunction<"redirect"> = (action, context) => {
  const globalWindow = context.componentContext.globalWindow;
  if (globalWindow?.top) {
    // Note (Reinaldo, 2020-03-30): This is for supporting legacy actions that
    // were just strings.
    // TODO (Chance 2024-05-31): We shouldn't have to parse this because it's
    // saved in the commerce state. Leaving for now to avoid larger refactor.
    const root = getActiveShopifyUrlRoot(globalWindow);
    if (typeof action.value === "string") {
      if (globalWindow) {
        const href = evaluateVariableAsString(
          action.value,
          context.componentContext,
        );
        if (href) {
          globalWindow.top.location.href = replaceLeadingSlashWithRoot(
            href,
            root,
          );
        }
      }
    } else if (globalWindow) {
      const url = evaluateVariableAsString(
        action.value.url,
        context.componentContext,
      );
      if (url) {
        globalWindow.top.open(
          replaceLeadingSlashWithRoot(url, root),
          action.value.openNewTab ? "_blank" : "_top",
        );
      }
    }
  }
};

const phoneNumber: AlchemyActionFunction<"phoneNumber"> = (action, context) => {
  const globalWindow = context.componentContext.globalWindow;
  if (globalWindow?.top) {
    const url = `tel:${evaluateVariable(
      action.value.url,
      context.componentContext,
    )}`;
    globalWindow?.top.open(url, "_top");
  }
};

const carouselNext: AlchemyActionFunction<"carouselNext"> = (_, context) => {
  context.componentContext.actionHooks.moveToNextCarouselItem?.();
};

const carouselPrevious: AlchemyActionFunction<"carouselPrevious"> = (
  _,
  context,
) => {
  context.componentContext.actionHooks.moveToPreviousCarouselItem?.();
};

const openModal: AlchemyActionFunction<"openModal"> = (action, context) => {
  context.componentContext.actionHooks.openModal?.(
    action.value.componentId,
    context.repeatedIndex ?? null,
  );
};

const openKlaviyoModal: AlchemyActionFunction<"openKlaviyoModal", void> = (
  action,
  context,
) => {
  // Note (Matt 2023-09-01): We need to use the action.value.componentId to
  // find the targeted Klaviyo Embed component. We then use the klaviyoFormIDRegex
  // to select the form identifier from the embedCode (always resolves to something like
  // 'klaviyo-form-XXXXXXX'). We need the last bit of text from the identifier (the XXXXXX),
  // to pass to the window._klOnsite array.
  const targetContext = getCurrentComponentContext(action.value.componentId, 0);
  const klaviyoFormIDRegex = new RegExp(/(klaviyo-form-[\dA-z].+)"/g);
  const identifier = firstGroupMatchOfExpression(
    klaviyoFormIDRegex,
    targetContext?.klaviyoEmbedCode ?? "",
  );
  const globalWindow = context.componentContext.globalWindow;
  if (globalWindow && identifier) {
    globalWindow._klOnsite = globalWindow._klOnsite ?? [];
    globalWindow._klOnsite.push([
      "openForm",
      identifier.replace("klaviyo-form-", ""),
    ]);
  }
};

const setActiveAlchemyVariant: AlchemyActionFunction<
  "setActiveAlchemyVariant"
> = (action) => {
  const { value } = action;
  const targetContext = getCurrentComponentContext(value.componentId, 0);
  const { setActiveVariantId } =
    targetContext?.actionHooks?.componentIdToVariantSetters?.[
      value.componentId
    ] || {};

  if (setActiveVariantId) {
    setActiveVariantId(value.variantId);
  }
};

const closeModalComponent: AlchemyActionFunction<"closeModalComponent"> = (
  _,
  context,
) => {
  context.componentContext.actionHooks.closeCurrentModal?.();
};

const redirectToProductPage: AlchemyActionFunction<"redirectToProductPage"> = (
  action,
  context,
) => {
  const globalWindow = context.componentContext.globalWindow;
  const productRefFromAction =
    action.value && "product" in action.value
      ? action.value.product
      : action.value;
  const product = getProduct(productRefFromAction, context.componentContext, {
    products: context.products,
    // Note (Noah, 2022-09-05): Hardcoding the currency/money format/language is
    // okay since we only use the product handle
    currencyCode: DEFAULT_ACTIVE_CURRENCY,
    language: DEFAULT_ACTIVE_LANGUAGE,
    moneyFormat: DEFAULT_MONEY_FORMAT,
    productMetafieldValues: {},
    variantMetafieldValues: {},
    // NOTE Ben 2024-02-21: We want to resolve the product ref we're passing off here,
    // _not_ allowing a PDP or product component to override it.
    isInsideTemplateProductComponentOverride: false,
    templateProduct: context.templateProduct,
  });
  if (product) {
    const { handle } = product;
    if (globalWindow) {
      // TODO (Chance 2024-05-31): We shouldn't have to parse this because it's
      // saved in the commerce state. Leaving for now to avoid larger refactor.
      const root = getActiveShopifyUrlRoot(globalWindow);
      if ("openInNewTab" in action.value && action.value.openInNewTab) {
        globalWindow.open(`${root}products/${handle}`, "_blank");
      } else {
        globalWindow.location.href = `${root}products/${handle}`;
      }
    }
  }
};

const scrollToUrlHashmark: AlchemyActionFunction<"scrollToUrlHashmark"> = (
  action,
  context,
) => {
  let hashmark;
  let offset;
  let smoothScroll;
  if (typeof action.value === "string") {
    hashmark = action.value;
    offset = 0;
    smoothScroll = false;
  } else {
    hashmark = action.value.hashmark;
    offset = action.value.offset || 0;
    smoothScroll = action.value.smoothScroll ?? false;
  }

  const globalWindow = context.componentContext.globalWindow;
  const targetDocument = globalWindow?.document;
  const rootElement = targetDocument
    ? selectRuntimeElementRootNode(
        targetDocument,
        context.componentContext.elementId,
      )
    : null;

  let targetElement = rootElement?.querySelector(
    `[data-alchemy-url-hashmark="${hashmark}"]`,
  ) as HTMLElement;

  // Note (Noah, 2023-05-29, USE-136): If we didn't find the hashmark
  // in the current element, try checking the whole document. This is
  // useful for when you have two replo sections on a single page and you
  // want a button in one of them to scroll to the other
  if (!targetElement) {
    targetElement =
      context.componentContext.globalWindow?.document.querySelector(
        `[data-alchemy-url-hashmark="${hashmark}"]`,
      ) as HTMLElement;
  }

  offset =
    offset ||
    Number.parseInt(
      targetElement?.dataset?.alchemyUrlHashmarkOffset as string,
    ) ||
    0;
  if (targetElement) {
    scrollToElement(targetElement, {
      topOffset: offset,
      smoothScroll,
    });
  }
};

const toggleCollapsible: AlchemyActionFunction<"toggleCollapsible"> = (
  _,
  context,
) => {
  context.componentContext.actionHooks.toggleCollapsible?.();
};

const togglePlay: AlchemyActionFunction<"togglePlay"> = (_, context) => {
  context.componentContext.actionHooks.togglePlay?.();
};

const toggleMute: AlchemyActionFunction<"toggleMute"> = (_, context) => {
  context.componentContext.actionHooks.toggleMute?.();
};

const toggleFullScreen: AlchemyActionFunction<"toggleFullScreen"> = (
  _,
  context,
) => {
  context.componentContext.actionHooks.toggleFullScreen?.();
};

const scrollContainerLeft: AlchemyActionFunction<"scrollContainerLeft"> = (
  action,
  context,
) => {
  context.componentContext.actionHooks.scrollContainerLeft?.(
    action.value.pixels,
  );
};

const scrollContainerRight: AlchemyActionFunction<"scrollContainerRight"> = (
  action,
  context,
) => {
  context.componentContext.actionHooks.scrollContainerRight?.(
    action.value.pixels,
  );
};

const setCurrentCollectionSelection: AlchemyActionFunction<
  "setCurrentCollectionSelection"
> = (action: Action, context) => {
  context.componentContext.actionHooks.setCurrentCollectionSelection?.(
    action.value,
  );
};

const toggleDropdown: AlchemyActionFunction<"toggleDropdown"> = (
  _,
  context,
) => {
  context.componentContext.actionHooks.toggleDropdown?.();
};

const setDropdownItem: AlchemyActionFunction<"setDropdownItem"> = (
  action,
  context,
) => {
  context.componentContext.actionHooks.setDropdownSelection?.(action.value);
};

const setActiveVariant: AlchemyActionFunction<"setActiveVariant"> = (
  action,
  context,
) => {
  if (typeof action.value.variantId === "string") {
    action.value.variantId = Number.parseInt(action.value.variantId, 10);
  }
  context.componentContext.actionHooks.setCurrentVariantId?.(
    action.value.variantId,
  );
};

const setActiveOptionValue: AlchemyActionFunction<"setActiveOptionValue"> = (
  action,
  context,
) => {
  context.componentContext.actionHooks.setSelectedOptionValue?.(action.value);
};

const setActiveSellingPlan: AlchemyActionFunction<"setActiveSellingPlan"> = (
  action,
  context,
) => {
  context.componentContext.actionHooks.setSelectedSellingPlan?.(
    action?.value ?? null,
  );
};

const setSelectedListItem: AlchemyActionFunction<"setSelectedListItem"> = (
  _,
  context,
) => {
  context.componentContext.actionHooks.setSelectedListItem?.();
};

const addVariantToTemporaryCart: AlchemyActionFunction<
  "addVariantToTemporaryCart"
> = (action, context) => {
  const productRef = getProduct(
    action.value.product,
    context.componentContext,
    {
      products: context.products,
      // Note (Noah, 2022-09-05): Hardcoding the currency/money format/language
      // is okay since we don't use the price in addVariantToTemporaryCart
      currencyCode: DEFAULT_ACTIVE_CURRENCY,
      language: DEFAULT_ACTIVE_LANGUAGE,
      moneyFormat: DEFAULT_MONEY_FORMAT,
      productMetafieldValues: {},
      variantMetafieldValues: {},
      templateProduct: context.templateProduct,
    },
  );

  if (productRef) {
    context.componentContext.actionHooks.addVariantToTemporaryCart?.(
      productRef,
    );
  }
};

const removeVariantFromTemporaryCart: AlchemyActionFunction<
  "removeVariantFromTemporaryCart"
> = (action, context) => {
  const productRef = getProduct(
    action.value.product,
    context.componentContext,
    {
      products: context.products,
      // Note (Noah, 2022-09-05): Hardcoding the currency/money format/language
      // is okay since we don't use the price in removeVariantFromTemporaryCart
      currencyCode: DEFAULT_ACTIVE_CURRENCY,
      language: DEFAULT_ACTIVE_LANGUAGE,
      moneyFormat: DEFAULT_MONEY_FORMAT,
      productMetafieldValues: {},
      variantMetafieldValues: {},
      templateProduct: context.templateProduct,
    },
  );
  if (productRef) {
    context.componentContext.actionHooks.removeVariantFromTemporaryCart?.(
      productRef,
    );
  }
};

const decreaseVariantCountInTemporaryCart: AlchemyActionFunction<
  "decreaseVariantCountInTemporaryCart"
> = (action, context) => {
  const productRef = getProduct(
    action.value.product,
    context.componentContext,
    {
      products: context.products,
      // Note (Noah, 2022-09-05): Hardcoding the currency/money format/language
      // is okay since we don't use the price in removeVariantFromTemporaryCart
      currencyCode: DEFAULT_ACTIVE_CURRENCY,
      language: DEFAULT_ACTIVE_LANGUAGE,
      moneyFormat: DEFAULT_MONEY_FORMAT,
      productMetafieldValues: {},
      variantMetafieldValues: {},
      templateProduct: context.templateProduct,
    },
  );

  if (productRef) {
    context.componentContext.actionHooks.decreaseVariantCountInTemporaryCart?.(
      productRef,
    );
  }
};

const scrollToPreviousCarouselItem: AlchemyActionFunction<
  "scrollToPreviousCarouselItem"
> = (_, context) => {
  context.componentContext.actionHooks.scrollToPreviousCarouselItem?.();
};
const scrollToNextCarouselItem: AlchemyActionFunction<
  "scrollToNextCarouselItem"
> = (_, context) => {
  context.componentContext.actionHooks.scrollToNextCarouselItem?.();
};

const scrollToSpecificCarouselItem: AlchemyActionFunction<
  "scrollToSpecificCarouselItem"
> = (action, context) => {
  context.componentContext.actionHooks.scrollToSpecificCarouselItem?.(
    action.value.index,
  );
};

const activateTabId: AlchemyActionFunction<"activateTabId"> = (
  action,
  context,
) => {
  context.componentContext.actionHooks.activateTabId?.(action.value.tabItemId);
};

const setActiveTabIndex: AlchemyActionFunction<"setActiveTabIndex"> = (
  action,
  context,
) => {
  context.componentContext.actionHooks.setActiveTabIndex?.(action.value?.index);
};

const goToItem: AlchemyActionFunction<"goToItem"> = (action, context) => {
  context.componentContext.actionHooks.goToItem?.(action.value);
};

const goToNextItem: AlchemyActionFunction<"goToNextItem"> = (_, context) => {
  context.componentContext.actionHooks.goToNextItem?.();
};

const goToPrevItem: AlchemyActionFunction<"goToPrevItem"> = (_, context) => {
  context.componentContext.actionHooks.goToPrevItem?.();
};

const applyDiscountCode: AlchemyActionFunction<"applyDiscountCode"> = (
  action,
) => {
  return asyncApplyDiscountCode(encodeURIComponent(action.value));
};

const executeJavascript: AlchemyActionFunction<"executeJavascript"> = (
  action,
) => {
  // https://esbuild.github.io/content-types/#direct-eval
  // biome-ignore lint/security/noGlobalEval lint/style/noCommaOperator: allow eval and comma operator
  return (0, eval)(action.value ?? "");
};

const updateCurrentProduct: AlchemyActionFunction<"updateCurrentProduct"> = (
  action,
  context,
) => {
  if (action.value) {
    context.componentContext.actionHooks.updateCurrentProduct?.(action.value);
  }
};

// NOTE Ben 2024-02-23: Doing this because using the key to action type like:
// {[K in Action["type"]]: AlchemyActionFunction<K>}
// is too complex for TS.
const allActions: Record<Action["type"], AlchemyActionFunction<any>> = {
  redirect,
  setActiveTabIndex,
  addVariantToTemporaryCart,
  removeVariantFromTemporaryCart,
  decreaseVariantCountInTemporaryCart,
  addProductVariantToCart,
  multipleProductVariantsAddToCart,
  increaseProductQuantity,
  decreaseProductQuantity,
  setProductQuantity,
  updateCart,
  phoneNumber,
  openModal,
  openKlaviyoModal,
  setActiveAlchemyVariant,
  redirectToProductPage,
  scrollToUrlHashmark,
  scrollContainerLeft,
  scrollContainerRight,
  setCurrentCollectionSelection,
  setDropdownItem,
  setActiveVariant,
  setActiveOptionValue,
  scrollToPreviousCarouselItem,
  scrollToNextCarouselItem,
  toggleDropdown,
  toggleMute,
  toggleCollapsible,
  togglePlay,
  addTemporaryCartProductsToCart,
  close,
  closeModalComponent,
  carouselNext,
  carouselPrevious,
  toggleFullScreen,
  goToNextItem,
  goToPrevItem,
  scrollToSpecificCarouselItem,
  activateTabId,
  applyDiscountCode,
  executeJavascript,
  goToItem,
  clearCart,
  setActiveSellingPlan,
  setSelectedListItem,
  updateCurrentProduct,
};

export const executeAction = async (
  action: Action,
  context: AlchemyActionContext,
) => {
  const actionFn = allActions[action.type];
  const globalWindow = context.componentContext.globalWindow;
  if (globalWindow) {
    // TODO (Noah, 2023-06-10): pretty sure this ...getFeatures() is not needed,
    // idk why we'd need to add the features here - context should already have
    // everything we need
    context = {
      ...context,
      ...getFeatures(globalWindow),
    };
  }
  return await actionFn(action, context);
};

let isInteractionExecuting = false;
/* Executes an array of actions */
export const executeActions = async (
  actions: Action[],
  context: AlchemyActionContext,
  componentNode?: HTMLElement | null,
) => {
  if (isInteractionExecuting) {
    return;
  }
  isInteractionExecuting = true;

  if (componentNode) {
    componentNode.dispatchEvent(
      new Event(ReploCustomEvents.ActionStarted, {
        // @ts-ignore
        componentId: context.componentId,
      }),
    );
  }

  const actionsToExecute = combineActionsIfIsNecessary(actions);

  try {
    for (const action of actionsToExecute) {
      await executeAction(action, context);
    }
  } finally {
    if (componentNode) {
      componentNode.dispatchEvent(
        new Event(ReploCustomEvents.ActionEnded, {
          // @ts-ignore
          componentId: context.componentId,
        }),
      );
    }
    isInteractionExecuting = false;
  }
};

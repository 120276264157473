import * as React from "react";

import type { RenderComponentProps } from "../../../shared/types";
import { RenderComponentPlaceholder } from "../RenderComponentPlaceholder";

const RawHTMLContent: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
}) => {
  const htmlContent = component.props._htmlContent;
  return htmlContent ? (
    <div
      {...componentAttributes}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  ) : (
    <div {...componentAttributes}>
      <RenderComponentPlaceholder
        title={
          "Once you set this component's HTML content, the code will be embedded here."
        }
      />
    </div>
  );
};

export default RawHTMLContent;

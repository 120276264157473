import type { ReploShopifyOption } from "replo-runtime/shared/types";
import { isString } from "replo-utils/lib/type-check";

export function isReploShopifyOption(
  value: unknown,
): value is ReploShopifyOption {
  return Boolean(
    value &&
      typeof value === "object" &&
      isString((value as Record<string, any>).key) &&
      isString((value as Record<string, any>).name) &&
      "values" in value
      ? Array.isArray(value.values)
      : true,
  );
}

export function isReploShopifyOptionList(
  value: unknown,
): value is ReploShopifyOption[] {
  return Array.isArray(value) && value.every(isReploShopifyOption);
}

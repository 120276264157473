import type { CustomPropsRecord } from "../../../shared/Component";
import type {
  EditorPropsRecord,
  StyleElements,
} from "../../../shared/utils/renderComponents";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _closeModalOnOutsideClick: {
      type: "boolean",
      name: "Click Outside To Close",
      description:
        "If enabled, clicking outside of the popup's content will close it.",
      defaultValue: false,
    },
    _overlayColor: {
      type: "color",
      name: "Overlay Color",
      description:
        "Set the color and opacity of the popup's background overlay.",
      defaultValue: "#FFFFFFBF",
    },
  };
}

export function getEditorProps(): EditorPropsRecord {
  return {
    isVisible: {
      type: "visibility",
      title: "Is visible",
      description: "If set, the editor will show modal",
      defaultValue: false,
    },
  };
}

export const styleElements = {
  root: {
    defaultStyles: {
      padding: 0,
      zIndex: 10_000_001,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      // Note (Noah, 2022-08-22): A defined width is necessary here, otherwise the modal
      // by default expands to fill the entire width of its parent, the modal content
      // (I think because the modal content has a transform? Or maybe because it's in
      // a StyleRoot?)
      width: "100%",
      height: "100%",
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    editorProps: getEditorProps(),
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: true,
    styleElements,
    shouldBeRootChild: (movementType) => movementType === "leftBarTemplate",
    ancestorDisallowList: [
      {
        ancestorTypes: ["button"],
        message: "Popups cannot be nested inside buttons.",
      },
      {
        ancestorTypes: ["marquee"],
        message: "Popups cannot be nested inside tickers.",
      },
      {
        ancestorTypes: ["carouselV3Slides"],
        message:
          "Popups cannot be directly nested inside carousel slides, drop it into a container.",
        directChildOnly: true,
      },
    ],
  },
} satisfies ComponentConfig;

export default config;

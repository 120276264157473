import { errorToast } from "@editor/components/common/designSystem/Toast";
import { WorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { routes } from "@editor/utils/router";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { validate as isUUIDValid } from "uuid";

const isWorkspacePath = (path: string) => {
  return new RegExp(`\\/workspace\\/.*\\/[a-zA-Z0-9]*`).test(path);
};

const WorkspaceContext: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { user } = useCurrentUser();
  const defaultWorkspaceId = user?.workspace?.id ?? null;

  const { workspaceId: workspaceIdFromParams } = useParams();

  const validatedWorkspaceIdFromParams =
    workspaceIdFromParams && isUUIDValid(workspaceIdFromParams)
      ? workspaceIdFromParams
      : undefined;

  const [workspaceId, setWorkspaceId] = React.useState<string | null>(
    validatedWorkspaceIdFromParams ?? defaultWorkspaceId,
  );

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (validatedWorkspaceIdFromParams) {
      setWorkspaceId(validatedWorkspaceIdFromParams);
    } else if (isWorkspacePath(location.pathname)) {
      setWorkspaceId(defaultWorkspaceId);
      errorToast(
        "Invalid workspace ID",
        "Please try again or reach out to support@replo.app for help.",
      );
      navigate(routes.dashboard);
    }
  }, [
    navigate,
    location.pathname,
    defaultWorkspaceId,
    validatedWorkspaceIdFromParams,
  ]);

  return (
    <WorkspaceDashboardContext.Provider
      value={{
        setWorkspaceId,
        workspaceId,
      }}
    >
      {children}
    </WorkspaceDashboardContext.Provider>
  );
};

export default WorkspaceContext;

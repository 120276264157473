import Input from "@common/designSystem/Input";
import Button from "@editor/components/common/designSystem/Button";
import IconButton from "@editor/components/common/designSystem/IconButton";
import Spinner from "@editor/components/common/designSystem/Spinner";
import toast from "@editor/components/common/designSystem/Toast";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { BsCheckLg, BsLink45Deg, BsPencil, BsTrash } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import type { ReferralCode } from "schemas/referralCode";

type ReferralLinkItemProps = {
  referralCode: Pick<ReferralCode, "code" | "id">;
};

type ReferralLinkItemFormData = {
  newReferralCode: string;
};

const ReferralLinkItem: React.FC<ReferralLinkItemProps> = ({
  referralCode,
}) => {
  const { workspaceId } = useParams<{ workspaceId?: string }>();
  const [inputValue, setInputValue] = useOverridableState<string>(
    getReferralUrl(referralCode?.code),
  );
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);

  const { user } = useCurrentUser();
  const { refetch } = trpc.referralCode.getSummary.useQuery(
    workspaceId ?? skipToken,
  );
  const isWorkspaceOwner = useIsWorkspaceOwner(workspaceId);
  const { mutateAsync: updateReferral, isPending } =
    trpc.referralCode.update.useMutation({
      onSuccess: ({ referral }) => {
        setIsEditMode(false);
        setInputValue(getReferralUrl(referral.code));
        toast({
          header: "Referral Link Updated",
          message: "Your referral link has been updated succesfully.",
        });
        analytics("referralCode.updated", {
          workspaceId: workspaceId ?? "",
          code: referral.code,
          referralCodeId: referralCode.id,
          email: user?.email ?? "",
        });
        void refetch();
        void trpcUtils.workspace.getById.invalidate({
          id: workspaceId,
        });
        void trpcUtils.referralCode.getReferredUsers.invalidate({
          workspaceId,
        });
        void trpcUtils.referralCode.getReferredWorkspaces.invalidate({
          workspaceId,
        });
      },
    });

  const { openModal } = useModal();
  const analytics = useLogAnalytics();

  const {
    setValue,
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<ReferralLinkItemFormData>();

  const onSubmit = handleSubmit(({ newReferralCode }) => {
    void updateReferral({
      previousReferralCode: referralCode.code,
      newReferralCode,
    });
  });

  const onLinkCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsCopied(true);
    void navigator.clipboard.writeText(inputValue);
    toast({
      header: "Referral Link Copied",
      message: "Your referral link has been copied to your clipboard.",
    });

    analytics("referralCode.copied", {
      workspaceId: user?.workspace?.id ?? "",
      code: referralCode.code,
      referralCodeId: referralCode.id,
      email: user?.email ?? "",
    });

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const onDelete = () => {
    openModal({
      type: "deleteReferralCodeModal",
      props: {
        code: referralCode.code,
      },
    });
  };

  const onCancelEdit = () => {
    setIsEditMode(false);
    setValue("newReferralCode", inputValue.split("/").at(-1) ?? "");
    clearErrors("newReferralCode");
  };

  if (isEditMode) {
    return (
      <form
        onSubmit={(data) => {
          void onSubmit(data);
        }}
        className="flex gap-2"
      >
        <div className="flex flex-col px-0.5">
          <Controller
            name="newReferralCode"
            control={control}
            defaultValue={inputValue.split("/").at(-1)}
            rules={{
              required: "This field is required",
              pattern: {
                value: /^[\dA-Za-z-]+$/,
                message:
                  "Please enter only alphanumeric characters or hyphens (-)",
              },
            }}
            render={({ field }) => (
              <div className="mb-1.5 w-96">
                <Input {...field} size="base" autoComplete="off" autoFocus />
              </div>
            )}
          />
          {errors.newReferralCode && (
            <div className="demibold pt-1 text-sm text-red-600">
              {errors.newReferralCode.message}
            </div>
          )}
        </div>
        <Button type="primary" htmlType="submit" size="base">
          {isPending ? <Spinner type="primary" /> : "Save"}
        </Button>
        <Button type="secondary" onClick={onCancelEdit} size="base">
          Cancel
        </Button>
      </form>
    );
  }

  const shouldDisableDeleteButton = !isWorkspaceOwner;

  return (
    <div className="flex gap-2">
      <div className="px-0.5">
        <div className="flex h-8 w-96 items-center whitespace-nowrap rounded border border-black bg-white px-3 text-sm">
          <p className="overflow-hidden text-ellipsis">{inputValue}</p>
        </div>
      </div>

      <div className="h-8 flex items-center gap-0.5">
        <IconButton
          type="tertiary"
          onClick={() => {
            setIsEditMode(true);
          }}
          isDisabled={!isWorkspaceOwner}
          tooltipText={
            isWorkspaceOwner
              ? null
              : "Only Workspace Owners can edit this referral code"
          }
          icon={<BsPencil size={16} className="text-blue-600" />}
          aria-label="Delete Referral Code"
          className="h-full"
        />
        <IconButton
          type="tertiary"
          icon={isCopied ? <BsCheckLg size={16} /> : <BsLink45Deg size={16} />}
          onClick={onLinkCopy}
          aria-label="Copy Referral Link"
          className="h-full"
        />
        <IconButton
          type="tertiary"
          onClick={onDelete}
          isDisabled={shouldDisableDeleteButton}
          tooltipText={getDeleteButtonTooltip(isWorkspaceOwner)}
          icon={<BsTrash size={16} className="text-red-600" />}
          className={classNames("h-full", {
            "cursor-not-allowed opacity-50": shouldDisableDeleteButton,
          })}
        />
      </div>
    </div>
  );
};

const getDeleteButtonTooltip = (isWorkspaceOwner: boolean) => {
  if (!isWorkspaceOwner) {
    return "Only Workspace Owners can delete this referral code";
  }

  return "Delete Referral Code";
};

const getReferralUrl = (referralKey: string) => {
  return `replo.app/signup/${referralKey}`;
};

export default ReferralLinkItem;

import { z } from "zod";

export const billingTierSchema = z.enum([
  "free",
  "basic",
  "standard",
  "growth",
  "enterprise",
  "custom",
]);

export type BillingTier = z.infer<typeof billingTierSchema>;

export const BillingTiers: Record<Uppercase<BillingTier>, BillingTier> = {
  FREE: "free",
  BASIC: "basic",
  STANDARD: "standard",
  GROWTH: "growth",
  ENTERPRISE: "enterprise",
  CUSTOM: "custom",
};

export const paymentProcessorSchema = z.enum(["shopify", "stripe", "none"]);

export type PaymentProcessor = z.infer<typeof paymentProcessorSchema>;

export const subscriptionStatusSchema = z.enum([
  "pending",
  "active",
  "canceled",
  "past_due",
]);
export type SubscriptionStatus = z.infer<typeof subscriptionStatusSchema>;

export const billingPlanSchema = z.object({
  id: z.string().uuid(),
  monthlyAmount: z.number(),
  startedAt: z.date(),
  endedAt: z.date().nullable(),
  name: billingTierSchema,
  paymentProcessor: paymentProcessorSchema,
});

export const subscriptionDetailsSchema = z.object({
  id: z.string().uuid(),
  paymentProcessor: paymentProcessorSchema,
  paymentProcessorSubscriptionId: z.string().nullable(),
  name: billingTierSchema,
  startedAt: z.date(),
  monthlyAmount: z.number(),
  isCustom: z.boolean(),
});

export type SubscriptionDetails = z.infer<typeof subscriptionDetailsSchema>;

export type BillingPlan = z.infer<typeof billingPlanSchema>;

export const PlanDisplayName = {
  Free: "Free",
  Basic: "Basic",
  Standard: "Standard",
  Growth: "Growth",
  Enterprise: "Enterprise",
  Custom: "Custom",
} as const;

export type PlanInfo = {
  tier: BillingTier;
  displayName: PlanDisplayName;
  price: number;
  href: string;
  cta: string;
  isMostPopular: boolean;
  next?: PlanInfo;
  description: string;
  features: string[];
  maxPublishedPages: number | null;
  maxPublishedBlogPosts: number | null;
  maxPublishedSections: number | null;
  maxPublishedProductTemplates: number | null;
  maxProjectMembers: number | null;
  maxShopifyIntegrations: number | null;
  aiCredits: number | null;
  maxExperiments: 0 | null;
  order: number | null;
  showOnPricingPlans: boolean;
  titleColor?: string;
  footerText?: string;
  imgSrc?: string;
  exampleBrandName?: string;
};

export const REGULAR_ENTERPRISE_PLAN_INFO: PlanInfo = {
  displayName: PlanDisplayName.Enterprise,
  tier: "enterprise",
  href: "https://dashboard.replo.app/auth/signup?utm_source=pricing",
  cta: "Book Demo",
  isMostPopular: false,
  price: 0,
  description: "Unlimited everything, dedicated support, and roadmap priority.",
  features: [
    `Everything in ${PlanDisplayName.Growth}`,
    "Custom Integrations",
    "Unlimited Published Pages and Sections",
    "Dedicated Implementation Support",
    "Weekly Strategy Calls",
    "Product Roadmap Priority",
    "Private Slack Support Channel",
  ],
  maxPublishedPages: null,
  maxPublishedBlogPosts: null,
  maxPublishedSections: null,
  maxPublishedProductTemplates: null,
  maxProjectMembers: null,
  maxShopifyIntegrations: null,
  aiCredits: null,
  maxExperiments: null,
  order: 4,
  showOnPricingPlans: true,
};

export const REGULAR_GROWTH_PLAN_INFO: PlanInfo = {
  displayName: PlanDisplayName.Growth,
  tier: "growth",
  isMostPopular: false,
  next: REGULAR_ENTERPRISE_PLAN_INFO,
  href: "https://dashboard.replo.app/auth/signup?utm_source=pricing",
  cta: "Start Free",
  price: 499,
  description:
    "Partner with us, get dedicated support, and early access to features.",
  features: [
    "100 Published Pages",
    "100 Published Sections",
    "Unlimited Product Templates",
    "A/B Testing",
  ],
  maxPublishedPages: 100,
  maxPublishedBlogPosts: 100,
  maxPublishedSections: 100,
  maxPublishedProductTemplates: 25,
  maxProjectMembers: null,
  maxShopifyIntegrations: 5,
  aiCredits: null,
  maxExperiments: null,
  order: 4,
  showOnPricingPlans: true,
  titleColor: "from-orange-500 to-yellow-500",
  footerText: "For globally-renowned brands like",
  imgSrc: "/images/customers/jiff.svg",
  exampleBrandName: "Jif",
};

export const REGULAR_STANDARD_PLAN_INFO: PlanInfo = {
  next: REGULAR_GROWTH_PLAN_INFO,
  displayName: PlanDisplayName.Standard,
  href: "https://dashboard.replo.app/auth/signup?utm_source=pricing",
  price: 249,
  description:
    "Launch your ecommerce ROI into the stratosphere with Replo Standard.",
  cta: "Start Free",
  isMostPopular: true,
  tier: "standard",
  features: [
    "25 Published Pages",
    "25 Published Sections",
    "5 Product Templates",
    "Unlimited Replo AI",
  ],
  maxPublishedPages: 30,
  maxPublishedBlogPosts: 30,
  maxPublishedSections: 30,
  maxPublishedProductTemplates: 5,
  maxProjectMembers: null,
  maxShopifyIntegrations: 3,
  aiCredits: null,
  maxExperiments: 0,
  order: 3,
  showOnPricingPlans: true,
  titleColor: "from-blue-600 to-cyan-400",
  footerText: "For high-growth teams like",
  imgSrc: "/images/customers/jones-road-black.svg",
  exampleBrandName: "Jones Road",
};

export const REGULAR_BASIC_PLAN_INFO: PlanInfo = {
  displayName: PlanDisplayName.Basic,
  tier: "basic",
  isMostPopular: false,
  next: REGULAR_STANDARD_PLAN_INFO,
  href: "https://dashboard.replo.app/auth/signup?utm_source=pricing",
  cta: "Start Free",
  price: 99,
  description:
    "Get started with landing pages on Shopify with access to the Replo Template Library.",
  features: [
    "10 Published Pages",
    "10 Published Sections",
    "500+ Marketplace Templates",
    "Unlimited Collaborators",
  ],
  maxPublishedPages: 10,
  maxPublishedBlogPosts: 10,
  maxPublishedSections: 10,
  maxPublishedProductTemplates: 1,
  maxProjectMembers: null,
  maxShopifyIntegrations: 1,
  aiCredits: 50,
  maxExperiments: 0,
  order: 2,
  showOnPricingPlans: true,
  titleColor: "from-blue-600 to-cyan-400 text-lime-500",
  footerText: "For up and coming brands like",
  imgSrc: "/images/customers/magic-mind.svg",
  exampleBrandName: "Magic Mind",
};

export const REGULAR_FREE_PLAN_INFO: PlanInfo = {
  displayName: PlanDisplayName.Free,
  tier: "free",
  price: 0,
  href: "https://dashboard.replo.app/auth/signup?utm_source=pricing",
  cta: "Start Free",
  isMostPopular: false,
  next: REGULAR_BASIC_PLAN_INFO,
  description: "Create Unlimited Drafts for as long as you need",
  features: ["Create Unlimited Drafts", "Email Support"],
  maxPublishedPages: 0,
  maxPublishedBlogPosts: 0,
  maxPublishedSections: 0,
  maxPublishedProductTemplates: 0,
  maxShopifyIntegrations: 1,
  aiCredits: 50,
  maxExperiments: 0,
  maxProjectMembers: 1,
  order: 1,
  showOnPricingPlans: false,
};

export const CUSTOM_ENTERPRISE_PLAN_INFO: PlanInfo = {
  displayName: PlanDisplayName.Enterprise,
  tier: "enterprise",
  href: "https://dashboard.replo.app/auth/signup?utm_source=pricing",
  cta: "Book Demo",
  isMostPopular: false,
  price: 0,
  description:
    "This account is currently on a Enterprise Replo Plan. Please contact your account manager for more information on your plan.",
  features: [],
  maxPublishedPages: null,
  maxPublishedBlogPosts: null,
  maxPublishedSections: null,
  maxPublishedProductTemplates: null,
  maxProjectMembers: null,
  maxShopifyIntegrations: null,
  aiCredits: null,
  maxExperiments: null,
  order: null,
  showOnPricingPlans: false,
  titleColor: "text-black",
  imgSrc: "/images/steve.png",
};

export const CUSTOM_PLAN_INFO: PlanInfo = {
  displayName: PlanDisplayName.Custom,
  tier: "custom",
  cta: "Book Demo",
  isMostPopular: false,
  href: "https://dashboard.replo.app/auth/signup?utm_source=pricing",
  price: 0,
  description:
    "This workspace is currently on a Custom Replo Plan. Please contact billing@replo.app for more information on your plan.",
  features: [],
  maxPublishedPages: null,
  maxPublishedBlogPosts: null,
  maxPublishedSections: null,
  maxPublishedProductTemplates: null,
  maxProjectMembers: null,
  maxShopifyIntegrations: null,
  aiCredits: null,
  maxExperiments: 0,
  order: null,
  showOnPricingPlans: false,
  titleColor: "text-black",
};
export type PlanDisplayName =
  (typeof PlanDisplayName)[keyof typeof PlanDisplayName];

export const regularBillingPlans = [
  REGULAR_FREE_PLAN_INFO,
  REGULAR_BASIC_PLAN_INFO,
  REGULAR_STANDARD_PLAN_INFO,
  REGULAR_GROWTH_PLAN_INFO,
  REGULAR_ENTERPRISE_PLAN_INFO,
];

export const customBillingPlans = [
  CUSTOM_ENTERPRISE_PLAN_INFO,
  CUSTOM_PLAN_INFO,
];

export const billingPlansByTier: Record<BillingTier, PlanInfo> = {
  free: REGULAR_FREE_PLAN_INFO,
  basic: REGULAR_BASIC_PLAN_INFO,
  standard: REGULAR_STANDARD_PLAN_INFO,
  growth: REGULAR_GROWTH_PLAN_INFO,
  enterprise: CUSTOM_ENTERPRISE_PLAN_INFO,
  custom: CUSTOM_PLAN_INFO,
};

export const BILLING_TIER_INFO_MAP: Record<BillingTier, PlanInfo> = {
  free: REGULAR_FREE_PLAN_INFO,
  basic: REGULAR_BASIC_PLAN_INFO,
  standard: REGULAR_STANDARD_PLAN_INFO,
  growth: REGULAR_GROWTH_PLAN_INFO,
  enterprise: REGULAR_ENTERPRISE_PLAN_INFO,
  custom: CUSTOM_PLAN_INFO,
};

export const BILLING_PLAN_CONFIRMATION_PARAM = "billing-confirmation";
export const BILLING_PLAN_CONFIRMATION_TYPE_PARAM = "confirmation-type";

// Reasons a workspace can't accommodate additional Shopify integrations:
export const shopifyIntegrationCapacityReasons = [
  // It is a shopify-billed workspace (i.e. its preferred payment provider is Shopify,
  // or it has an active billing plan on Shopify)
  "shopifyWorkspace",

  // It is at/over capacity for an additional integration
  "capacityExceeded",
] as const;

const shopifyIntegrationCapacityReasonSchema = z.enum(
  shopifyIntegrationCapacityReasons,
);

export type ShopifyIntegrationCapacityReason = z.infer<
  typeof shopifyIntegrationCapacityReasonSchema
>;

/**
 * Whether or not a given workspace can accommodate an additional Shopify Integration, and why not (if applicable)
 */
export const shopifyIntegrationCapacityStatusSchema = z
  .object({
    hasCapacity: z.literal(false),
    current: z.number(),
    maximum: z.number(),
    reason: shopifyIntegrationCapacityReasonSchema,
  })
  .or(z.object({ hasCapacity: z.literal(true) }));

export type ShopifyIntegrationCapacityStatus = z.infer<
  typeof shopifyIntegrationCapacityStatusSchema
>;

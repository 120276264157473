import ActionsModifier from "@editorModifiers/ActionsModifier";
import AnimationsModifier from "@editorModifiers/AnimationsModifier";
import * as React from "react";

const InteractionsTab: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="relative flex flex-col gap-4">
      <ActionsModifier />
      <AnimationsModifier />
    </div>
  );
};

export default InteractionsTab;

import { ComponentTemplateSnapshot } from "@components/component-template/ComponentTemplateSnapshot";
import type { ComponentPreviewProps } from "@editor/types/component-preview";
import * as React from "react";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { twMerge } from "tailwind-merge";

/**
 * Component to render a preview. It supports three different types of previews:
 * - img: renders an image that automatically resizes to fit the container
 * - html: renders an html string
 * - react: renders a React node
 */
const ComponentPreview = (props: ComponentPreviewProps) => {
  return exhaustiveSwitch(props)({
    img: ({ src, alt, className }) => (
      <img
        // Note (Sebas, 2023-11-30): We add a query param to the src to prevent
        // the browser from using a previous version of the images (cached)
        // format: YYYY-MM-DD
        src={`${src}?date=2024-07-16`}
        alt={alt}
        className={twMerge(
          "h-full w-full object-cover object-center",
          className,
        )}
        style={{ pointerEvents: "none" }}
      />
    ),
    snapshot: ({ componentTemplate }) => {
      return (
        <ComponentTemplateSnapshot componentTemplate={componentTemplate} />
      );
    },
  });
};

export default ComponentPreview;

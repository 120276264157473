import Separator from "@editor/components/common/designSystem/Separator";
import { SimpleSkeletonLoader } from "@editor/components/common/designSystem/SkeletonLoader";
import Header from "@editor/components/dashboard/Header";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { trpc } from "@editor/utils/trpc";
import ReploAreaChart from "@replo/design-system/components/shadcn/AreaChart";
import ReploPieChart from "@replo/design-system/components/shadcn/PieChart";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import * as React from "react";
import { BsTools } from "react-icons/bs";

import PagesOverview from "./PagesOverview";

const ComingSoon: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();

  const { data: workspaceData, isPending: isLoadingWorkspace } =
    trpc.workspace.getById.useQuery(
      workspaceId ? { id: workspaceId } : reactQuerySkipToken,
    );

  return (
    <div className="flex flex-col px-6 w-full relative overflow-hidden">
      <Header
        title={`${workspaceData?.workspace?.name} Analytics`}
        subtitle="Dashboard"
        isLoading={isLoadingWorkspace}
      />
      <Separator className="my-4 col-span-12" />
      <div className="max-h-[80vh]">
        <div className="absolute inset-0 z-10 w-full bg-white bg-opacity-[0.90]">
          <div className="flex h-[70%] w-full justify-center items-center">
            <div className="flex flex-col items-center">
              <BsTools className="h-10 w-10 text-black" />
              <div className="text-lg font-bold leading-7">
                We&apos;re building something special...
              </div>
              <div className="text-base font-medium max-w-[418px] text-center mt-1">
                Analytics Dashboard is on its way — check back soon!
              </div>
              <span className="text-blue-600">Learn more</span>
            </div>
          </div>
        </div>
        <div className="relative max-w-xl xl:max-w-7xl pr-5">
          <div className="flex w-full flex-col">
            <div className="flex flex-row space-x-4">
              <SimpleSkeletonLoader height="14" width="160" />
              <SimpleSkeletonLoader height="14" width="160" />
              <SimpleSkeletonLoader height="14" width="160" />
            </div>
            <div className="text-2xl font-semibold my-8">
              <SimpleSkeletonLoader height="14" width="141" />
            </div>
            <div className="flex gap-5 flex-col min-[950px]:flex-row">
              <ReploAreaChart
                title={<SimpleSkeletonLoader width="141" height="10" />}
              />
              <ReploPieChart
                skeleton={<SimpleSkeletonLoader width="141" height="10" />}
              />
            </div>
            <PagesOverview skeleton={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;

import * as React from "react";
import ContentLoader from "react-content-loader";

export const SkeletonLoaderWrapper = ContentLoader;
export const SkeletonLoaderItem: React.FC<{
  width: string;
  height: string;
  yAxis: string;
  xAxis?: string;
  ry?: string;
  rx?: string;
}> = ({ height, width, yAxis, xAxis = "0", rx = "6", ry = "6" }) => {
  return (
    <rect x={xAxis} y={yAxis} rx={rx} ry={ry} width={width} height={height} />
  );
};

export const SimpleSkeletonLoader: React.FC<{
  width: string;
  height: string;
}> = ({ width, height }) => (
  <SkeletonLoaderWrapper width={width} height={height}>
    <SkeletonLoaderItem width={width} height={height} yAxis="0" xAxis="0" />
  </SkeletonLoaderWrapper>
);

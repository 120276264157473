import useRightBarVisibility from "@editor/hooks/useRightBarVisibility";
import { useEditorDispatch } from "@editor/store";
import * as React from "react";

import { setActiveCanvas } from "./canvas-reducer";
import type { EditorCanvas, SetActiveCanvasSource } from "./canvas-types";

export default function useSetActiveCanvas() {
  const isRightBarVisible = useRightBarVisibility();
  const dispatch = useEditorDispatch();
  return React.useCallback(
    ({
      canvas,
      width,
      source,
    }: {
      canvas: EditorCanvas;
      width?: number;
      source: SetActiveCanvasSource;
    }) => {
      dispatch(
        setActiveCanvas({
          canvas,
          width,
          isRightBarVisible,
          source,
        }),
      );
    },
    [dispatch, isRightBarVisible],
  );
}

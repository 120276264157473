import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const judgeProductReviewsWidget: ComponentTemplate = {
  id: "9964d5ce-3087-4e8a-a928-9a2b805cc451",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Judge Product Reviews",
  leftBarCategory: { main: "productIntegrations", subCategory: "judgeMe" },
  preview: "integrations/judgeme-product-reviews.svg",
  tags: ["widget"],
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  template: {
    id: "$uid",
    type: "judgeProductReviewsWidget",
    props: { style: { alignSelf: "stretch" } },
  },
};

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import ResponsiveToggle from "@editor/components/common/designSystem/ResponsiveToggle";
import SelectableButtons from "@editor/components/common/designSystem/SelectableButtons";
import { StartFromTemplatePreviewSkeleton } from "@editor/components/editor/SkeletonLoaders";
import { useGetCurrentFlow } from "@editor/components/flows/hooks/useGetCurrentFlow";
import useGetCurrentStepResultsData from "@editor/components/flows/hooks/useGetCurrentStepResultsData";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepsLayout from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { trpc } from "@editor/utils/trpc";
import classNames from "classnames";
import * as React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import type {
  FlowStepConfigPropsValueOf,
  FlowStepDataValueOf,
} from "schemas/flow";
import { twMerge } from "tailwind-merge";

type FormValues = {
  startFrom: string;
  templateId?: string;
};

type TemplatesExtraData = {
  id: string;
  order: number;
  matchTo: string[];
};

type ExpectedTemplatePreview = {
  id: string;
  name: string;
  template: string;
  kind: string;
  desktopPreviewUrl: string;
  mobilePreviewUrl: string;
};

const transformTemplatePreviews = (
  data: {
    id: string;
    name: string;
    template: unknown;
    kind: string | null;
    desktopPreviewUrl: string;
    mobilePreviewUrl: string;
  }[],
): ExpectedTemplatePreview[] => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    template: typeof item.template === "string" ? item.template : "",
    kind: item.kind ?? "",
    desktopPreviewUrl: item.desktopPreviewUrl,
    mobilePreviewUrl: item.mobilePreviewUrl,
  }));
};

const StartFrom: React.FC = () => {
  const analytics = useLogAnalytics();
  const stepResultsData =
    useGetCurrentStepResultsData<"onboarding.user.do-you-want-to-start-from-template">();
  const { currentStep, submitStep } = useReploFlowsStepContext();
  const { currentInstance } = useGetCurrentFlow();
  const stepProps =
    currentStep?.props as FlowStepConfigPropsValueOf<"onboarding.user.do-you-want-to-start-from-template">;
  const { submitOrSkipStepCallback: submitStepCallback } =
    useRouterFlowCallbacks();

  const industrySelected =
    (
      currentInstance?.stepResults["which-best-describes-your-industry"]
        ?.data as FlowStepDataValueOf<"onboarding.user.which-best-describes-your-industry">
    )?.industry ?? "other";

  const templates =
    (stepProps.options.find((option) => option.value === "template")?.extraData
      ?.templates as TemplatesExtraData[]) ?? [];
  let filteredTemplatesByIndustry = templates.filter(
    (template) =>
      // NOTE (Fran 2024-07-22): We need to check if the matchTo field is an array to avoid errors with
      // retrocompatibility. This Will be remove in a few days when we know everyone is using the new
      // Onboarding flow.
      Array.isArray(template.matchTo) &&
      template.matchTo.includes(industrySelected),
  );
  // NOTE (Fran 2024-07-19): If there are no templates for the selected industry, we will show the templates
  // that are marked as "other" in the matchTo field.
  filteredTemplatesByIndustry =
    filteredTemplatesByIndustry.length === 0
      ? templates.filter(
          (template) =>
            // NOTE (Fran 2024-07-22): We need to check if the matchTo field is an array to avoid errors with
            // retrocompatibility. This Will be remove in a few days when we know everyone is using the new
            // Onboarding flow.
            Array.isArray(template.matchTo) &&
            template.matchTo.includes("other"),
        )
      : filteredTemplatesByIndustry;

  // NOTE (Sebas, 2024-02-08): We sort the templates here because we cannot use the `orderedTemplatePreviews`
  // prop to get the id from the first template. The reason of this is that it can be undefined at this point.
  const templateIds = [...filteredTemplatesByIndustry]
    .sort((a, b) => a.order - b.order)
    .map((t) => t.id);

  const { data, isPending } = trpc.componentTemplates.getPreviews.useQuery({
    templateIds: templateIds.length > 0 ? templateIds : [],
  });

  const templatePreviews = data?.componentTemplates
    ? transformTemplatePreviews(data?.componentTemplates)
    : [];

  const orderedTemplatePreviews = [...templatePreviews].sort((a, b) => {
    const aOrder =
      templates.find((template) => template.id === a.id)?.order ?? 0;
    const bOrder =
      templates.find((template) => template.id === b.id)?.order ?? 0;
    return aOrder - bOrder;
  });

  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      startFrom: stepResultsData?.startFrom ?? "template",
      templateId: templateIds[0] ?? "",
    },
  });

  const startFromValue = useWatch({ control, name: "startFrom" });
  const selectedTemplateValue = useWatch({ control, name: "templateId" });

  const onSubmit = ({ startFrom, templateId }: FormValues) => {
    if (currentStep) {
      void submitStep(
        currentStep.id,
        currentStep.type,
        {
          startFrom,
          templateId: startFrom === "blank" ? undefined : templateId,
        },
        ({ instance, nextStep }) =>
          submitStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          }),
      );
      const componentTemplateId =
        startFrom === "blank" ? "blank" : currentSelectedTemplate?.id ?? "";
      const componentTemplateName =
        startFrom === "blank" ? "blank" : currentSelectedTemplate?.name ?? "";

      analytics("store.componentTemplate.used", {
        componentTemplateId,
        collectionId: "",
        componentTemplateName,
        componentTemplateType: "page",
        categoryId: "",
        useDesignSystem: false,
        type: "onboarding",
      });

      analytics("onboarding.flow.completed", {
        stepsData: {
          ...currentInstance?.stepResults,
          "onboarding.user.do-you-want-to-start-from-template": {
            completedAt: new Date().toISOString(),
            skipped: false,
            type: "onboarding.user.do-you-want-to-start-from-template",
            data: {
              startFrom,
              templateId,
            },
          },
        },
        heardFrom:
          (
            currentInstance?.stepResults[
              "onboarding.user.how-did-you-hear-about-us"
            ]
              ?.data as FlowStepDataValueOf<"onboarding.user.how-did-you-hear-about-us">
          )?.heardFrom ?? [],
        whatToDo:
          (
            currentInstance?.stepResults[
              "onboarding.user.what-do-you-want-to-do-in-replo"
            ]
              ?.data as FlowStepDataValueOf<"onboarding.user.what-do-you-want-to-do-in-replo">
          )?.whatToDo ?? [],
      });
    }
  };

  const currentSelectedTemplate =
    orderedTemplatePreviews?.find(
      (template) => template.id === selectedTemplateValue,
    ) ?? orderedTemplatePreviews[0];

  return (
    <OnboardingStepsLayout
      // NOTE (Fran 2024-03-27): We should hide the right panel content on mobile. Because the preview
      // is not responsive and it's not possible to see the whole image, also will hide the form on mobile
      rightPanelContent={
        <div className="hidden lg:flex lg:flex-col">
          {startFromValue === "template" ? (
            <TemplatePreview
              desktopPreview={currentSelectedTemplate?.desktopPreviewUrl}
              mobilePreview={currentSelectedTemplate?.mobilePreviewUrl}
            />
          ) : (
            <StepImage src="/images/flows/template.png" />
          )}
        </div>
      }
    >
      <div className="flex flex-col gap-14">
        <div>
          <StepTitle>
            Do you want to start from a template or a blank page?
            <span className="text-red-600">*</span>
          </StepTitle>
        </div>
        <form
          className="flex flex-col gap-16"
          onSubmit={(data) => {
            void handleSubmit(onSubmit)(data);
          }}
        >
          <Controller
            name="startFrom"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectableButtons
                multiSelect={false}
                options={stepProps.options ?? []}
                value={[value]}
                onChange={(value) => onChange(value[0] ?? "")}
                className="my-4"
                textClassName="font-normal"
                unselectedClassName="border border-slate-200 bg-transparent text-default hover:border-blue-600 hover:bg-blue-200 hover:text-blue-600"
                selectedClassName="border border-blue-600 bg-blue-200 text-blue-600 hover:bg-blue-300"
              />
            )}
          />
          {startFromValue === "template" ? (
            <Controller
              name="templateId"
              control={control}
              render={({ field: { onChange, value } }) => {
                if (isPending) {
                  return <StartFromTemplatePreviewSkeleton />;
                }

                return (
                  <SelectableTemplates
                    value={value ?? ""}
                    onChange={onChange}
                    templates={orderedTemplatePreviews}
                  />
                );
              }}
            />
          ) : null}
          <FlowActionButtons
            shouldDisableNextButton={!Boolean(startFromValue)}
            customLabels={{ nextButton: "Let's Build" }}
          />
        </form>
      </div>
    </OnboardingStepsLayout>
  );
};

const SelectableTemplates: React.FC<{
  value: string;
  onChange: (value: string) => void;
  templates?: {
    id: string;
    name: string;
    template: string;
    kind: string;
    desktopPreviewUrl: string;
    mobilePreviewUrl: string;
    brandSlug?: string;
  }[];
}> = ({ onChange, value, templates }) => {
  return (
    <div className="grid grid-cols-3 w-full gap-3">
      {templates?.map((option) => (
        <button
          key={option.id}
          className={twMerge(
            classNames(
              "flex gap-1 col-span-1 overflow-hidden aspect-square border-2 border-gray-100 rounded flex-col p-1",
              { "border-blue-600": option.id === value },
            ),
          )}
          type="button"
          onClick={() => onChange(option.id)}
        >
          {option.brandSlug ? (
            <p className="text-xs text-default capitalize">
              {option.brandSlug.replace("-", " ")}
            </p>
          ) : null}
          <img
            src={option.desktopPreviewUrl}
            className="w-full"
            alt={`Preview for ${option.brandSlug ?? option.name} Template`}
          />
        </button>
      ))}
    </div>
  );
};

const TemplatePreview: React.FC<{
  mobilePreview?: string;
  desktopPreview?: string;
}> = ({ desktopPreview, mobilePreview }) => {
  const [selectedDevice, setSelectedDevice] = React.useState<
    "mobile" | "desktop"
  >("desktop");
  return (
    <div className="relative flex w-full h-screen overflow-hidden cursor-grab justify-center bg-gray-100 active:cursor-grabbing">
      <TransformWrapper
        limitToBounds
        maxScale={2}
        panning={{ lockAxisX: true }}
        wheel={{ disabled: true }}
      >
        <TransformComponent
          wrapperStyle={{
            height: "100%",
          }}
        >
          <div className="pt-40 px-32">
            <img
              src={desktopPreview ?? "/images/flows/template.png"}
              width={600}
              height={600}
              className={classNames(
                "h-full object-cover object-top lg:w-full shadow-lg",
                { hidden: selectedDevice === "mobile" },
              )}
              alt="Desktop preview"
            />
            <img
              src={mobilePreview ?? "/images/flows/template.png"}
              width={600}
              height={600}
              className={classNames(
                "h-full object-cover object-top lg:w-full shadow-lg",
                { hidden: selectedDevice === "desktop" },
              )}
              alt="Mobile preview"
            />
          </div>
        </TransformComponent>
      </TransformWrapper>
      <div className="z-50 absolute bottom-4 left-1/2 transform -translate-x-1/2">
        <ResponsiveToggle setValue={setSelectedDevice} value={selectedDevice} />
      </div>
    </div>
  );
};

export default StartFrom;

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const customHtml: ComponentTemplate = {
  id: "0b45fbfc-ebe1-48ac-9028-267d2328ef9d",
  storeId: null,
  scope: "left-bar",
  type: "component",
  env: Env.PRODUCTION,
  name: "Custom Code/Custom Code",
  leftBarCategory: { main: "customCode" },
  tags: ["html"],
  preview: "common/custom-code.svg",
  template: {
    id: "b8515872-925c-486a-a87b-db82408b0d37",
    type: "rawHtmlContent",
    props: {
      _htmlContent: "",
    },
  },
};

import Separator from "@editor/components/common/designSystem/Separator";
import Header from "@editor/components/dashboard/Header";
import { trpc } from "@editor/utils/trpc";
import { PaginationControl } from "@replo/design-system/components/pagination-control";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import { formatDistanceToNow } from "date-fns";
import orderBy from "lodash-es/orderBy";
import * as React from "react";
import { useParams } from "react-router-dom";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";

import TableHeadTitle from "../../tables/TableHeadTitle";
import ReferredTableEmptyState from "./ReferredTableEmptyState";

type Field = "name" | "subscriptionStatus" | "referralCode.code" | "createdAt";
type Direction = "asc" | "desc";

type OrderState = {
  field: Field;
  direction: Direction;
};

const AMOUNT_TO_SHOW = 10;

const ReferredWorkspacesTable = () => {
  const { workspaceId } = useParams();
  const { data, isPending } = trpc.referralCode.getReferredWorkspaces.useQuery(
    workspaceId
      ? {
          workspaceId,
        }
      : skipToken,
  );
  const referredWorkspaces =
    data?.referredWorkspaces.map((referredWorkspace) => {
      const activeSubscription = referredWorkspace.subscriptions?.find(
        (subscription) => !subscription.endedAt,
      );
      const subscriptionStatus = activeSubscription
        ? "Is Paying"
        : "Not Paying";
      return {
        ...referredWorkspace,
        subscriptionStatus,
      };
    }) ?? [];

  const [order, setOrder] = React.useState<OrderState>({
    field: "name",
    direction: "asc",
  });
  const [referredWorkspacesOrdered] = useOverridableState(
    orderBy(referredWorkspaces, order.field, order.direction),
  );

  const [skip, setSkip] = React.useState(0);

  const handleSortClick = (field: Field) => {
    if (field === order.field) {
      setOrder({
        field: field,
        direction: order.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setOrder({ field: field, direction: "desc" });
    }
  };

  if (isPending) {
    return null;
  }

  const workspacesToShow = referredWorkspacesOrdered?.slice(
    skip,
    AMOUNT_TO_SHOW + skip,
  );

  return (
    <div className="flex flex-col gap-y-4">
      <Header
        title="Workspaces Referred"
        subtitle="Workspaces created using your referral link. They are not guaranteed to
        have connected their Shopify store or converted into paid workspaces."
        isLoading={isPending}
      />
      {referredWorkspaces.length === 0 ? (
        <ReferredTableEmptyState type="workspaces" />
      ) : (
        <div className="mt-6 w-full">
          <div className="grid w-full grid-cols-12 gap-2 text-slate-400">
            <TableHeadTitle
              title="Workspace Name"
              wrapperClassName="col-span-3"
              shouldShowArrow={order.field === "name"}
              onClick={() => handleSortClick("name")}
              arrowDirection={order.direction}
            />
            <TableHeadTitle
              title="Subscription Status"
              wrapperClassName="col-span-2"
              shouldShowArrow={order.field === "subscriptionStatus"}
              onClick={() => handleSortClick("subscriptionStatus")}
              arrowDirection={order.direction}
            />
            <TableHeadTitle
              title="Shopify Stores"
              wrapperClassName="col-span-3"
              shouldShowArrow={false}
            />
            <TableHeadTitle
              title="Referred Code"
              wrapperClassName="col-span-2"
              shouldShowArrow={order.field === "referralCode.code"}
              onClick={() => handleSortClick("referralCode.code")}
              arrowDirection={order.direction}
            />
            <TableHeadTitle
              title="Created"
              wrapperClassName="col-span-2"
              shouldShowArrow={order.field === "createdAt"}
              onClick={() => handleSortClick("createdAt")}
              arrowDirection={order.direction}
            />
          </div>
          <div className="grid-col mt-2 grid gap-2">
            {workspacesToShow?.map((workspace, index) => {
              const shopifyUrls: Set<string> = new Set([]);
              for (const project of workspace.projects) {
                const shopifyIntegration = project.integrationAssignments?.find(
                  (integrationAssignment) =>
                    integrationAssignment.integration.shopifyIntegrationConfig,
                );
                if (
                  shopifyIntegration?.integration.shopifyIntegrationConfig
                    ?.shopifyUrl
                ) {
                  shopifyUrls.add(
                    shopifyIntegration.integration.shopifyIntegrationConfig
                      .shopifyUrl,
                  );
                }
              }

              return (
                <div
                  className="grid w-full grid-cols-12 items-center justify-center gap-2 text-base text-default"
                  key={workspace.id}
                >
                  <div className="col-span-3 flex items-center text-sm font-light">
                    {workspace.name}
                  </div>
                  <div
                    className={classNames(
                      "col-span-2 flex items-center text-xs text-white w-fit px-2 py-0.5 rounded-full",
                      {
                        "bg-green-600":
                          workspace.subscriptionStatus === "Is Paying",
                        "bg-red-600":
                          workspace.subscriptionStatus === "Not Paying",
                      },
                    )}
                  >
                    {workspace.subscriptionStatus}
                  </div>
                  <div className="col-span-3 flex items-center text-sm font-light">
                    <div className="flex flex-col gap-1">
                      {shopifyUrls
                        ? Array.from(shopifyUrls).map((url) => (
                            <span key={url}>{url}</span>
                          ))
                        : null}
                    </div>
                  </div>
                  <div className="col-span-2 flex items-center text-sm font-light">
                    {workspace.referralCode?.code}
                  </div>
                  <div className="col-span-2">
                    {formatDistanceToNow(workspace.createdAt, {
                      addSuffix: true,
                    })}
                  </div>
                  {index !== workspacesToShow.length - 1 && (
                    <Separator className="col-span-12 bg-slate-100" />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {referredWorkspacesOrdered.length > AMOUNT_TO_SHOW && (
        <div className="flex flex-col items-center gap-1">
          <Separator className="w-1/4" />
          <PaginationControl
            total={referredWorkspacesOrdered.length}
            pageSize={AMOUNT_TO_SHOW}
            onChangeIndex={(index) => {
              setSkip(index);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ReferredWorkspacesTable;

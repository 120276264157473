import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import {
  selectComponentDataMapping,
  selectDraftComponentName,
  selectDraftComponentOrAncestorVariants,
  selectDraftComponentOrAncestorWithVariants,
  selectDraftComponentOrDescendantIsOneOfTypes,
  selectDraftComponentType,
  selectRootComponentId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { getContextMenuItems } from "@editor/utils/tree-utils";
import useContextMenuActions from "@hooks/useContextMenuActions";
import useSelection from "@hooks/useSelection";
import * as React from "react";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";

export default function useContextMenuItems(
  source: "canvasRightClickMenu" | "componentTree",
  onRename?: (() => void) | null,
) {
  const rootComponentId = useEditorSelector(selectRootComponentId);
  const contextMenuActions = useContextMenuActions();
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);
  const draftComponentType = useEditorSelector(selectDraftComponentType);
  const { selectedIds } = useSelection();
  const activeVariant = useEditorSelector(
    selectDraftComponentOrAncestorVariants,
  ).find((variant) => variant.isActive);
  const componentWithVariantsId = useEditorSelector(
    selectDraftComponentOrAncestorWithVariants,
  )?.id;
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const isDebugMode = useIsDebugMode();
  const draftComponentName = useEditorSelector(selectDraftComponentName);
  const draftComponentOrDescendantIsText = useEditorSelector((state) =>
    selectDraftComponentOrDescendantIsOneOfTypes(state, ["text"]),
  );
  const contextMenuItems = React.useMemo(
    () =>
      getContextMenuItems({
        contextMenuActions,
        selectedIds,
        activeVariant,
        componentWithVariantsId,
        source,
        rootComponentId,
        onStartRename: onRename ?? null,
        isDebugMode,
        activeCanvas,
        draftComponentName,
        draftComponentOrDescendantIsText,
        componentDataMapping,
        draftComponentType,
      }),
    [
      contextMenuActions,
      selectedIds,
      activeVariant,
      componentWithVariantsId,
      source,
      rootComponentId,
      onRename,
      isDebugMode,
      activeCanvas,
      draftComponentName,
      draftComponentOrDescendantIsText,
      componentDataMapping,
      draftComponentType,
    ],
  );

  return contextMenuItems;
}

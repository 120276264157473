import ToggleGroup from "@common/designSystem/ToggleGroup";
import ResizablePane from "@components/ResizablePane";
import { ButtonPhony } from "@editor/components/common/designSystem/Button";
import DynamicDataButton from "@editor/components/common/designSystem/DynamicDataButton";
import { Menu } from "@editor/components/common/designSystem/Menu";
import Selectable from "@editor/components/common/designSystem/Selectable";
import {
  errorToast,
  successToast,
} from "@editor/components/common/designSystem/Toast";
import Tooltip from "@editor/components/common/designSystem/Tooltip";
import { TIPTAP_EDITOR_SCROLLABLE_DIV_ID } from "@editor/components/editor/constants";
import DropZone from "@editor/components/editor/page/Dropzone";
import { useRichTextComponent } from "@editor/components/RichTextComponentContext";
import TipTapToolbar from "@editor/components/TipTapRichTextToolbar";
import Tiptap, { useTipTapEditor } from "@editor/components/TiptapTextEditor";
import {
  useApplyComponentAction,
  type UseApplyComponentActionType,
} from "@editor/hooks/useApplyComponentAction";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useEnableNonDynamicTextEditing } from "@editor/hooks/useEnableNonDynamicTextEditing";
import {
  usePageFontOptions,
  useShopifyFontOptions,
} from "@editor/hooks/useFontFamilyOptions";
import { useGetAttribute } from "@editor/hooks/useGetAttribute";
import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { useModal } from "@editor/hooks/useModal";
import { useTargetFrameDocument } from "@editor/hooks/useTargetFrame";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import type { UploadResult } from "@editor/reducers/commerce-reducer";
import {
  selectColor,
  selectDraftComponent,
  selectDraftComponentComputedStyleValue,
  selectDraftComponentId,
  selectDraftComponentNodeFromActiveCanvas,
  selectDraftComponentText,
  selectDraftComponentType,
  selectDraftElementId,
  selectDraftRepeatedIndex,
  selectFontFamily,
  selectFontSize,
  selectFontStyle,
  selectFontWeight,
  selectIsShopifyIntegrationEnabled,
  selectLetterSpacing,
  selectLineHeight,
  selectTextAlign,
  selectTextDecoration,
  selectTextTransform,
} from "@editor/reducers/core-reducer";
import {
  setIsRichTextEditorFocused,
  setOpenPopoverId,
} from "@editor/reducers/ui-reducer";
import {
  convertCSSStylesToReploStyles,
  getValidFilteredStyleProps,
} from "@editor/reducers/utils/component-actions";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import type { RichTextEditorTag } from "@editor/types/rich-text-editor";
import { getEditorComponentNode } from "@editor/utils/component";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DraggingTypes } from "@editor/utils/editor";
import {
  filterOutPageFonts,
  FORMATTED_GOOGLE_FONT_OPTIONS,
  GENERIC_FONT_FAMILIES,
  getEndEnhancer,
} from "@editor/utils/font";
import { useInCanvasPreview } from "@editor/utils/preview";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import SelectablePopover from "@editorComponents/SelectablePopover";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";
import ModifierGroup from "@editorExtras/ModifierGroup";
import LengthInputModifier from "@editorModifiers/LengthInputModifier";
import {
  getCurrentTag,
  getUpdatedLineHeightForFontSize,
  normalizeFontFamily,
} from "@editorModifiers/utils";
import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import type { Editor as CoreEditor } from "@tiptap/core";
import debounce from "lodash-es/debounce";
import differenceBy from "lodash-es/differenceBy";
import intersectionBy from "lodash-es/intersectionBy";
import { EditorView } from "prosemirror-view";
import * as React from "react";
import { AiOutlineEllipsis, AiOutlineFontSize } from "react-icons/ai";
import { BsFonts } from "react-icons/bs";
import {
  RiAlignCenter,
  RiAlignJustify,
  RiAlignLeft,
  RiAlignRight,
  RiItalic,
  RiLineHeight,
  RiStrikethrough,
  RiTextSpacing,
  RiUnderline,
} from "react-icons/ri";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { FONT_WEIGHT_OPTIONS } from "replo-runtime/shared/utils/font";
import {
  CSS_LENGTH_TYPES_WITH_COMPUTED,
  parseUnit,
} from "replo-runtime/shared/utils/units";
import { hasOwnProperty, isNotNullish } from "replo-utils/lib/misc";
import { useForceUpdate } from "replo-utils/react/use-force-update";

import {
  selectActiveCanvas,
  selectActiveCanvasFrame,
} from "@/features/canvas/canvas-reducer";

// NOTE (Reinaldo 2022-06-03): Hacky solution to fix editor breaking when selecting a text node
// https://github.com/ueberdosis/tiptap/issues/1451#issuecomment-953348865
EditorView.prototype.updateState = function updateState(state) {
  // @ts-ignore
  if (!this.docView) {
    return;
  } // This prevents the matchesNode error on hot reloads
  // @ts-ignore
  this.updateStateInner(state, this.state.plugins != state.plugins);
};

const TextStyleModifier: React.FC = () => {
  const modal = useModal();
  const text = useEditorSelector(selectDraftComponentText);
  useTextStyleHotkeys();

  const onOpenDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT,
        referrerData: {
          type: "prop",
          propId: "text",
        },
        initialPath: getPathFromVariable(text),
      },
    });
  };

  return (
    <ModifierGroup
      title="Text"
      endEnhancer={
        <div className="flex flex-row gap-2">
          <DynamicDataButton onClick={onOpenDynamicData} />
          <HeaderMenu />
        </div>
      }
    >
      <div id="text-style-modifier" className="grid grid-cols-2 gap-2">
        <TextControl onOpenDynamicData={onOpenDynamicData} />
        <div id="font-family-selectable">
          <FontFamilySelectable />
        </div>
        <FontWeightSelectable />
        <FontSizeInput />
        <LineHeightInput />
        <LetterSpacingInput />
        <TextAlignmentToggle />
        <TextStylesOptions />
        <TextTransformOptions />
      </div>
    </ModifierGroup>
  );
};

const HeaderMenu: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const colorValue = useEditorSelector(selectColor);
  const textValue = useEditorSelector(selectDraftComponentText);
  return (
    <Menu
      items={[
        {
          type: "leaf",
          id: "pasteFromFigma",
          title: "Paste Style From Figma",
          onSelect: () => {
            void navigator.clipboard.readText().then((text) => {
              const styleObject = convertCSSStylesToReploStyles(text, true);
              const { style: textValidStyles } = getValidFilteredStyleProps(
                { style: styleObject },
                "text",
                { colorValue: colorValue ?? null, textValue },
              );
              applyComponentAction({
                type: "setStyles",
                value: textValidStyles ?? {},
              });
            });
          },
        },
      ]}
      customWidth={200}
      trigger={
        <ButtonPhony
          // NOTE (Chance 2023-11-02): `Menu` renders a button by default so this
          // is just for the styles
          type="secondary"
          style={{ minWidth: 0 }}
          className="h-6 w-6 bg-subtle hover:bg-subtle p-0 text-subtle"
        >
          <AiOutlineEllipsis className="h-4 w-4" />
        </ButtonPhony>
      }
    />
  );
};

const TextControl: React.FC<{
  onOpenDynamicData: () => void;
}> = ({ onOpenDynamicData }) => {
  const text = useEditorSelector(selectDraftComponentText);

  return typeof text === "string" && text.includes("{{") ? (
    <DynamicTextControl handleOpen={onOpenDynamicData} />
  ) : (
    <RichTextControl />
  );
};

const DynamicTextControl: React.FC<{
  handleOpen: () => void;
}> = ({ handleOpen }) => {
  const text = useEditorSelector(selectDraftComponentText);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const applyComponentAction = useApplyComponentAction();

  return (
    <div className="col-span-2">
      <DynamicDataValueIndicator
        type="text"
        templateValue={text}
        componentId={draftComponentId ?? undefined}
        onClick={handleOpen}
        onRemove={() => {
          applyComponentAction({
            type: "applyCompositeAction",
            value: [
              {
                type: "setStyles",
                value: {
                  fontWeight: "normal",
                  textDecoration: "none",
                  fontStyle: "normal",
                },
                analyticsExtras: {
                  actionType: "edit",
                  createdBy: "replo",
                },
              },
              {
                type: "setProps",
                value: { text: "Add new text here" },
                analyticsExtras: {
                  actionType: "edit",
                  createdBy: "replo",
                },
              },
            ],
          });
        }}
      />
    </div>
  );
};

const toolbarId = "pane-rte-toolbar";

const RichTextControl: React.FC = () => {
  const dispatch = useEditorDispatch();

  const { applyChanges, setTipTapEditor, setTag, currentTag } =
    useRichTextComponent();
  const onChangeRichTextEditor = React.useMemo(
    () => debounce((newValue: string) => applyChanges(newValue), 300),
    [applyChanges],
  );

  const onBlurRichTextEditor = React.useCallback(() => {
    dispatch(setIsRichTextEditorFocused(false));
  }, [dispatch]);

  const onFocusRichTextEditor = React.useCallback(() => {
    dispatch(setIsRichTextEditorFocused(true));
  }, [dispatch]);

  const onSelectionUpdate = (editor: CoreEditor | null) => {
    setTag(getCurrentTag(editor));
  };

  const onCreate = (editor: CoreEditor | null) => {
    setTag(getCurrentTag(editor));
  };

  const text = useEditorSelector(selectDraftComponentText);
  const tipTapEditor = useTipTapEditor(
    text,
    onChangeRichTextEditor,
    onBlurRichTextEditor,
    onFocusRichTextEditor,
    onSelectionUpdate,
    onCreate,
  );

  const isRichTextEditorFocused = useEditorSelector(
    (state) => state.ui.isRichTextEditorFocused,
  );

  const minEditorHeight = 40;
  const cutoffEditorExpansion = 200;

  const [resizableHeight, setResizableHeight] = React.useState(minEditorHeight);
  const [currentEditorHeight, setCurrentEditorHeight] = React.useState(40);

  // Synchronize text changes to the editor that don't originate from the
  // editor.
  const textRef = React.useRef(text);
  React.useEffect(() => {
    const prevText = textRef.current;
    textRef.current = text;
    if (!isRichTextEditorFocused && tipTapEditor && text !== prevText) {
      tipTapEditor.commands.setContent(text!);
    }
  }, [isRichTextEditorFocused, tipTapEditor, text]);

  React.useEffect(() => {
    // pass this instance of the editor to context so it can be used elsewhere
    if (tipTapEditor) {
      setTipTapEditor(tipTapEditor);
    }
    // Make sure to remove the editor from context when this component is
    // unmounted
    return () => {
      setTipTapEditor(null);
    };
  }, [setTipTapEditor, tipTapEditor]);

  // NOTE (Max 2024-05-08): Listens for when editor's height changes, as we need
  // to update the resizable's component height accordingly. We only allow to
  // automatically increase the height until cutoffEditorExpansion
  React.useEffect(() => {
    const editorDiv = document.getElementById(TIPTAP_EDITOR_SCROLLABLE_DIV_ID);

    if (editorDiv) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { height } = entry.contentRect;
          setCurrentEditorHeight(height);
          if (height <= cutoffEditorExpansion) {
            setResizableHeight(height + 10);
          }
        }
      });

      resizeObserver.observe(editorDiv);
      return () => resizeObserver.disconnect();
    }
  }, []);

  // NOTE (Max 2024-05-08): In the context of the ResizablePane's height being larger than the
  // actual editor's height, we want the user to be able to click on ResizablePane and still
  // trigger the editor. Otherwise, the only place where user could click to start typing
  // would be within the actual editor - not good UX.
  const propagateClick = () => {
    const editorDiv = document.getElementById(
      "in-tiptap-editor",
    ) as HTMLElement | null;
    editorDiv?.focus();
  };

  return (
    <>
      <div
        className="col-span-2"
        data-testid="rich-text-editor"
        // NOTE (Sebas, 2024-06-27): We need to stop propagation of the keydown event
        // to prevent the hotkeys from being triggered.
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div className="rounded bg-subtle px-2 focus-within:outline focus-within:outline-1">
          <div className="py-2">
            <TipTapToolbar editor={tipTapEditor} id={toolbarId} />
          </div>
          <div onClick={propagateClick}>
            <ResizablePane
              isVertical={true}
              minSize={minEditorHeight}
              maxSize={1500}
              size={resizableHeight}
              onResize={(newSize) => {
                setResizableHeight(newSize);
              }}
              contentClassName={`cursor-text ${currentEditorHeight > resizableHeight ? "overflow-auto" : "overflow-none"}`}
            >
              <div
                id={TIPTAP_EDITOR_SCROLLABLE_DIV_ID}
                className="pb-2 text-xs w-full h-max"
              >
                <Tiptap editor={tipTapEditor} />
              </div>
            </ResizablePane>
          </div>
        </div>
      </div>
      <div className="col-span-2">
        <TagSelectable value={currentTag} onChange={(val) => setTag(val)} />
      </div>
    </>
  );
};

const tagOptions: {
  label: string;
  value: RichTextEditorTag;
}[] = [
  {
    label: "Paragraph",
    value: "P",
  },
  {
    label: "Heading 1",
    value: "1",
  },
  {
    label: "Heading 2",
    value: "2",
  },
  {
    label: "Heading 3",
    value: "3",
  },
];

const TagSelectable: React.FC<{
  value?: RichTextEditorTag;
  onChange: (newValue: RichTextEditorTag) => void;
}> = ({ value = "P", onChange }) => {
  return (
    <Selectable
      className="w-full"
      placeholder="Select tag"
      defaultValue="P"
      value={value}
      options={tagOptions}
      onSelect={onChange}
    />
  );
};

const getDisplayName = (
  fontValue: string | null,
  nameToDisplayName: Record<string, string | undefined>,
) => {
  if (!fontValue) {
    return undefined;
  }
  return nameToDisplayName[fontValue];
};

export const FontFamilySelectable: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const fontFamily = useEditorSelector(selectFontFamily) ?? null;
  const normalizedFontFamily = normalizeFontFamily(fontFamily)
    ?.replaceAll("_", " ")
    .replaceAll("-", " ");
  const pageFontOptions = usePageFontOptions();
  const {
    fontOptions: shopifyFontOptions,
    fallbacks,
    refetch,
    nameToDisplayName,
  } = useShopifyFontOptions();

  // NOTE (Gabe 2024-05-15): Only show the fonts that are actually available
  // (via Shopify or Google fonts).
  const filteredPageFonts = intersectionBy(
    pageFontOptions,
    [...shopifyFontOptions, ...FORMATTED_GOOGLE_FONT_OPTIONS],
    (font) => font.value,
  );

  const fontOptions = [
    // Note (Evan, 6/2/23) We add the end enhancers to page fonts here, since we have to know whether they're shopify-uploaded fonts or not
    ...filteredPageFonts.map((font) => ({
      ...font,
      endEnhancer: getEndEnhancer(font, shopifyFontOptions),
      label: getDisplayName(font.value, nameToDisplayName) ?? font.label,
    })),
    // Note (Evan, 6/2/23) Filter out page fonts from shopify/google fonts so that we don't have any duplicates
    ...filterOutPageFonts(shopifyFontOptions, filteredPageFonts).map(
      (font) => ({
        ...font,
        label: getDisplayName(font.value, nameToDisplayName) ?? font.label,
      }),
    ),
    ...filterOutPageFonts(FORMATTED_GOOGLE_FONT_OPTIONS, filteredPageFonts),
  ];

  const draftComponentNode = useEditorSelector(
    selectDraftComponentNodeFromActiveCanvas,
  );

  const rerender = useForceUpdate();
  const computedFontStyle =
    draftComponentNode && getComputedStyle(draftComponentNode).fontFamily;

  const onSelect = (fontValue: string | null) => {
    let sanitizedFont = fontValue;

    // NOTE (Mariano, 2022-09-23): We check for blank spaces in the font name,
    // if we found one then we just wrap the font with quotes to prevent
    // `invalid value` issues when setting the CSS font rule
    if (fontValue && /\s/g.test(fontValue)) {
      sanitizedFont = `"${fontValue}"`;
    }

    applyComponentAction({
      type: "setStyles",
      value: {
        fontFamily: sanitizedFont
          ? `${sanitizedFont}, ${fallbacks.join(",")}`
          : null,
      },
    });
  };

  // NOTE (Sebas, 2023-03-22): When we reset the font to the default value,
  // we need to wait some time to get the updated computed font style.
  // NOTE (Chance, 2024-04-09) This should probably be handled in onSelect
  // instead to reduce complexity.
  const fontFamilyRef = React.useRef(fontFamily);
  React.useEffect(() => {
    const previousFontFamily = fontFamilyRef.current;
    fontFamilyRef.current = fontFamily;
    if (previousFontFamily && !fontFamily) {
      const id = setTimeout(() => {
        rerender();
      }, 500);
      return () => {
        clearTimeout(id);
      };
    }
  }, [rerender, fontFamily]);

  const onFontUploadComplete = async (data: UploadResult) => {
    const newFonts = await refetch();
    const fontName = newFonts?.find((font) =>
      // NOTE (Evan, 7/13/23) We replace spaces with underscores here because
      // we're matching against the Shopify files system, which does the same.
      data.asset.publicUrl.includes(font.name.replace(/ /g, "_")),
    )?.name;
    if (fontName) {
      onSelect(fontName);
    }
  };

  // Note (Evan, 2024-08-07): Attempt to retrieve a display name from
  // nameToDisplayName, checking first for the fontFamily, then the
  // fontFamily after removing any comma-joined fallbacks
  let displayName: string | undefined = undefined;
  if (fontFamily) {
    displayName =
      nameToDisplayName[fontFamily] ??
      nameToDisplayName[fontFamily.split(",")[0] ?? ""];
  }

  const fontDisplayName = displayName ?? normalizedFontFamily;
  return (
    <SelectablePopover
      title="Fonts"
      itemSize={26}
      options={fontOptions}
      fallbackValidValues={GENERIC_FONT_FAMILIES}
      onSelect={onSelect}
      startEnhancer={<BsFonts size={16} />}
      placeholder={normalizeFontFamily(computedFontStyle)}
      extraContent={
        <UploadCustomFont
          onUploadComplete={(data) => {
            void onFontUploadComplete(data);
          }}
        />
      }
      allowNull
      selectedItems={[normalizeFontFamily(fontFamily)]}
      triggerId="font-family-selectable-trigger"
    >
      {fontDisplayName ? fontDisplayName : null}
    </SelectablePopover>
  );
};

const calculateFontWeights = (
  paintableDocument: Document | null,
  fontFamily: string | null,
  isSafeOrNotAvailableFont?: boolean,
) => {
  if (!fontFamily || isSafeOrNotAvailableFont) {
    return FONT_WEIGHT_OPTIONS.map(({ label, value }) => ({
      label,
      value: value.toString(),
    }));
  }
  if (!paintableDocument) {
    return [];
  }
  const editorFontsArray = Array.from(paintableDocument.fonts);
  return FONT_WEIGHT_OPTIONS.filter((fontWeight) =>
    editorFontsArray
      .filter((font) => fontFamily.includes(font.family))
      .find((font) => font.weight === fontWeight.value.toString()),
  ).map(({ label, value }) => ({
    label,
    value: value.toString(),
  }));
};

const useFontWeightOptions = () => {
  const fontFamily = useEditorSelector(selectFontFamily) ?? null;
  // NOTE (Chance 2024-06-24): It shouldn't matter which canvas we use here
  // since the DOM contents should be the same for each. We just need to read
  // the document's `fonts` property.
  const targetFrameDocument = useTargetFrameDocument("desktop");
  // NOTE (Sebas, 2023-03-27): This counter is required to avoid
  // an infinite loop in case the font is not found on the document.
  // This can happen if the user selects a safe font, like Arial,
  // or if the font is not being imported correctly.
  const intervalCounter = React.useRef(0);
  const [options, setOptions] = React.useState(
    calculateFontWeights(targetFrameDocument, fontFamily),
  );
  // NOTE (Sebas, 2023-03-21): We need this effect because we need a timeout in case the
  // user selects a new font we need to wait for the font to be downloaded/added to the
  // DOM and then calculate the available weights for that font.
  React.useEffect(() => {
    const interval = setInterval(() => {
      const newOptions = calculateFontWeights(
        targetFrameDocument,
        fontFamily,
        intervalCounter.current > 5,
      );
      setOptions(newOptions);
      intervalCounter.current += 1;
      if (newOptions.length > 0) {
        clearInterval(interval);
        intervalCounter.current = 0;
      }
    }, 500);
    return () => {
      clearInterval(interval);
      intervalCounter.current = 0;
    };
  }, [fontFamily, targetFrameDocument]);

  return options;
};

function uploadWasSuccessful(
  res:
    | { data: UploadResult }
    | { error: FetchBaseQueryError | SerializedError },
): res is { data: UploadResult } {
  return hasOwnProperty(res, "data");
}

const UploadCustomFont: React.FC<{
  onUploadComplete(data: UploadResult): void;
}> = ({ onUploadComplete }) => {
  const projectId = useCurrentProjectId();
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const onUpload = (
    res:
      | { data: UploadResult }
      | { error: FetchBaseQueryError | SerializedError },
  ) => {
    if (uploadWasSuccessful(res)) {
      successToast("Font Uploaded", "");
      onUploadComplete(res.data);
    } else {
      errorToast(
        "Failed Uploading Font",
        "Please try again or reach out to support@replo.app for help.",
      );
    }
  };

  return (
    <>
      {isShopifyIntegrationEnabled ? (
        <div id="text-style-modifier-font-upload">
          <DropZone
            acceptDropAssetType={[".woff", ".woff2"]}
            onUploadComplete={onUpload}
            projectId={projectId}
            sourceType="files"
            inputSize="small"
            uploadText="Upload Custom Fonts"
          />
        </div>
      ) : (
        <ButtonPhony
          type="secondary"
          style={{ minWidth: 0 }}
          className="bg-transparent text-slate-400 hover:bg-transparent"
          isDisabled={true}
          tooltipText="Connect to Shopify to add custom fonts"
        >
          Upload Custom Fonts
        </ButtonPhony>
      )}
    </>
  );
};

const FontWeightSelectable: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const fontWeight = useEditorSelector(selectFontWeight);
  const options = useFontWeightOptions();

  return (
    <Selectable
      className="w-full"
      placeholder="Weight"
      options={options}
      ignoreValueMismatchError={true}
      value={fontWeight?.toString()}
      onSelect={(newValue: string) =>
        applyComponentAction({
          type: "setStyles",
          value: {
            fontWeight: newValue,
          },
        })
      }
    />
  );
};

const FontSizeInput: React.FC = () => {
  const draftComponent = useEditorSelector(selectDraftComponent);
  const draftElementId = useEditorSelector(selectDraftElementId);
  const lineHeight = useEditorSelector(selectLineHeight);
  const applyComponentAction = useApplyComponentAction();
  const getAttribute = useGetAttribute();
  const {
    setPreviewCSSPropertyValue,
    enableCanvasPreviewCSSProperties,
    disableCanvasPreviewCSSProperties,
  } = useInCanvasPreview();

  const draftRepeatedIndex = useEditorSelector(selectDraftRepeatedIndex);

  const activeCanvasFrame = useEditorSelector(selectActiveCanvasFrame);
  const targetDocument = activeCanvasFrame?.contentDocument;

  if (!draftComponent) {
    return null;
  }

  const fontSizeMenuItems = [
    "Reset",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "24px",
    "32px",
  ].map((v) => ({
    label: v,
    value: v === "Reset" ? null : v,
  }));

  // Note (Noah, 2022-12-09, REPL-5261): Calculate the computed value of the
  // node's font size in order to use it as a placeholder if no value is set. We
  // actually use the parent node's computed value here, to avoid an issue where
  // when you reset the font size, the input will still show the old value as
  // the placeholder because React hasn't yet completed its DOM commits (this is
  // fine, since it's the same value on the parent due to CSS cascading)
  const draftComponentNode = targetDocument
    ? getEditorComponentNode(
        targetDocument,
        draftElementId,
        draftComponent.id,
        draftRepeatedIndex,
      )?.parentElement
    : null;
  const propertyForPlaceholder = draftComponentNode
    ? getComputedStyle(draftComponentNode).fontSize
    : null;

  return (
    <LengthInputModifier
      field="style.fontSize"
      draggingType={DraggingTypes.Vertical}
      dragTrigger="entireInput"
      minDragValues={{ px: 0 }}
      minValues={{ px: 0 }}
      allowsNegativeValue={false}
      anchorValue="16px"
      resetValue={null}
      placeholder={propertyForPlaceholder ?? "Font Size"}
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      startEnhancer={() => (
        <Tooltip inheritCursor content="Font Size" triggerAsChild>
          <span tabIndex={0}>
            <AiOutlineFontSize />
          </span>
        </Tooltip>
      )}
      menuOptions={fontSizeMenuItems}
      onPreviewChange={(value: string) => {
        const lineHeight = getUpdatedLineHeightForFontSize(
          value,
          draftComponent,
          getAttribute,
        );
        setPreviewCSSPropertyValue(["lineHeight"], lineHeight);
      }}
      onDragStart={() => {
        const lineHeightValue = lineHeight ?? "normal";
        enableCanvasPreviewCSSProperties(
          ["lineHeight"],
          String(lineHeightValue),
        );
        setPreviewCSSPropertyValue(["lineHeight"], String(lineHeightValue));
      }}
      onDragEnd={() => {
        disableCanvasPreviewCSSProperties(["lineHeight"]);
      }}
      onChange={(value: string) => {
        const lineHeight = getUpdatedLineHeightForFontSize(
          value,
          draftComponent,
          getAttribute,
        );
        applyComponentAction({
          type: "setStyles",
          value: {
            fontSize: value,
            lineHeight,
          },
        });
      }}
      previewProperty="fontSize"
    />
  );
};

const LineHeightInput: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const fontSize = useEditorSelector(selectFontSize);
  const computedFontSize =
    useEditorSelector((state) =>
      selectDraftComponentComputedStyleValue(state, "fontSize", activeCanvas),
    ) ?? "16px";
  const lineHeightDefaultValue =
    styleAttributeToEditorData.lineHeight.defaultValue;
  return (
    <LengthInputModifier
      startEnhancer={() => (
        <Tooltip inheritCursor content="Line Height" triggerAsChild>
          <span tabIndex={0}>
            <RiLineHeight />
          </span>
        </Tooltip>
      )}
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      field="style.lineHeight"
      anchorValue={fontSize ? String(fontSize) : String(computedFontSize)}
      draggingType={DraggingTypes.Vertical}
      minDragValues={{ px: 0 }}
      minValues={{ px: 0 }}
      resetValue={lineHeightDefaultValue}
      allowsNegativeValue={false}
      previewProperty="lineHeight"
      onChange={(newValue: string) => {
        applyComponentAction({
          type: "setStyles",
          value: {
            lineHeight: newValue === "auto" ? lineHeightDefaultValue : newValue,
          },
        });
      }}
    />
  );
};

const LetterSpacingInput: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const letterSpacingDefaultValue =
    styleAttributeToEditorData.letterSpacing.defaultValue;
  return (
    <LengthInputModifier
      startEnhancer={() => (
        <Tooltip inheritCursor content="Letter Spacing" triggerAsChild>
          <span tabIndex={0}>
            <RiTextSpacing />
          </span>
        </Tooltip>
      )}
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      field="style.letterSpacing"
      resetValue={letterSpacingDefaultValue}
      anchorValue="1px"
      draggingType={DraggingTypes.Vertical}
      onChange={(newValue: string) =>
        applyComponentAction({
          type: "setStyles",
          value: {
            letterSpacing: newValue,
          },
        })
      }
      menuOptions={[
        { label: "Reset", value: "" },
        { label: "1px", value: "1px" },
        { label: "2px", value: "2px" },
        { label: "4px", value: "4px" },
      ]}
      previewProperty="letterSpacing"
    />
  );
};

const normalizeTextDirectionForInput = (
  textDirection?: CSSStyleDeclaration["textAlign"] | null,
) => {
  if (textDirection === "start") {
    return "left";
  } else if (textDirection === "end") {
    return "right";
  }
  return textDirection;
};

const TextAlignmentToggle: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const textAlign = useEditorSelector(selectTextAlign);
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const computedStyleValue =
    normalizeTextDirectionForInput(
      useEditorSelector((state) =>
        selectDraftComponentComputedStyleValue(
          state,
          "textAlign",
          activeCanvas,
        ),
      ),
    ) ?? null;

  return (
    <div className="col-span-2">
      <div>
        <ToggleGroup
          type="single"
          style={{
            height: 26,
            width: "100%",
          }}
          value={textAlign ?? computedStyleValue}
          options={[
            {
              value: "left",
              label: <RiAlignLeft size={16} />,
              tooltipContent: "Align Text Left",
            },
            {
              value: "center",
              label: <RiAlignCenter size={16} />,
              tooltipContent: "Align Text Center",
            },
            {
              value: "right",
              label: <RiAlignRight size={16} />,
              tooltipContent: "Align Text Right",
            },
            {
              value: "justify",
              label: <RiAlignJustify size={16} />,
              tooltipContent: "Align Text Justify",
            },
          ]}
          onChange={(newValue: string) => {
            applyComponentAction({
              type: "setStyles",
              value: { textAlign: newValue },
            });
          }}
        />
      </div>
    </div>
  );
};

const TextStylesOptions: React.FC = () => {
  const textDecoration = useEditorSelector(selectTextDecoration);
  const fontStyle = useEditorSelector(selectFontStyle);

  const { tipTapEditor, queueAction, clearQueuedActions } =
    useRichTextComponent();

  const applyComponentAction = useApplyComponentAction();

  const selectedValues = [textDecoration, fontStyle]
    .filter(isNotNullish)
    .map((value) => String(value));

  function onChange(newValue: string, isSelected: boolean) {
    let chain = tipTapEditor?.chain().selectAll();
    let action: UseApplyComponentActionType | undefined;
    if (newValue !== "italic") {
      let textDecoration;
      if (
        newValue === "line-through" &&
        textDecoration !== newValue &&
        isSelected
      ) {
        textDecoration = "line-through";
      } else if (
        newValue === "underline" &&
        textDecoration !== newValue &&
        isSelected
      ) {
        textDecoration = "underline";
      } else {
        textDecoration = "none";
      }
      action = {
        type: "setStyles",
        value: { textDecoration },
      };
      queueAction(action);
      chain = chain?.unsetStrike().unsetUnderline();
    } else if (newValue === "italic") {
      let fontStyle;
      if (fontStyle !== newValue && isSelected) {
        fontStyle = "italic";
      } else {
        fontStyle = "normal";
      }
      action = {
        type: "setStyles",
        value: { fontStyle },
      };
      queueAction(action);
      chain = chain?.unsetItalic();
    }
    const prevHtml = tipTapEditor?.getHTML();
    chain?.run();
    const postHtml = tipTapEditor?.getHTML();
    // if the html is the same, then we need to manually apply the changes
    if (prevHtml === postHtml && action) {
      applyComponentAction(action);
      clearQueuedActions();
    }
  }

  return (
    <ToggleGroup
      type="multi"
      style={{
        width: "100%",
        height: 26,
      }}
      value={selectedValues}
      options={[
        {
          value: "italic",
          label: <RiItalic size={16} />,
          tooltipContent: "Italic",
        },
        {
          value: "line-through",
          label: <RiStrikethrough size={16} />,
          tooltipContent: "Strikethrough",
        },
        {
          value: "underline",
          label: <RiUnderline size={16} />,
          tooltipContent: "Underline",
        },
      ]}
      onChange={(values) => {
        if (selectedValues.length > values.length) {
          const [deselectValue] = differenceBy(selectedValues, values);
          // NOTE (Chance 2023-12-05): Non-null assertion is safe here because
          // we know checked that `selectedValues.length > values.length`, which
          // means it's not empty.
          onChange(deselectValue!, false);
        } else if (values.length > selectedValues.length) {
          // NOTE (Chance 2023-12-05): Non-null assertion is safe here because
          // we know checked that `values.length > selectedValues.length`, which
          // means it's not empty.
          const [selectValue] = differenceBy(values, selectedValues);
          onChange(selectValue!, true);
        }
      }}
    />
  );
};

const TextTransformOptions: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const textTransform = useEditorSelector(selectTextTransform);

  return (
    <ToggleGroup
      type="single"
      style={{
        width: "100%",
        height: 26,
      }}
      value={textTransform || "none"}
      options={[
        {
          value: "none",
          label: <span>Ag</span>,
          tooltipContent: "Normal",
        },
        {
          value: "uppercase",
          label: <span>AG</span>,
          tooltipContent: "Uppercase",
        },
        {
          value: "lowercase",
          label: <span>ag</span>,
          tooltipContent: "Lowercase",
        },
      ]}
      onChange={(newValue) => {
        applyComponentAction({
          type: "setStyles",
          value: { textTransform: newValue },
        });
      }}
    />
  );
};

function useTextStyleHotkeys() {
  const store = useEditorStore();
  const dispatch = useEditorDispatch();
  const applyComponentAction = useApplyComponentAction();
  const { setTag, toggleBulletList, toggleNumberedList } =
    useRichTextComponent();
  const { isMenuOpen: isAIMenuOpen } = useAIStreaming();

  const handleToggleBoldText = React.useCallback(() => {
    const draftComponentType = selectDraftComponentType(store.getState());
    if (draftComponentType === "text") {
      const fontWeight = selectFontWeight(store.getState());
      applyComponentAction({
        type: "setStyles",
        value: {
          fontWeight: fontWeight === "700" ? null : "700",
        },
      });
    }
  }, [applyComponentAction, store]);

  const handleToggleItalicText = React.useCallback(() => {
    const draftComponentType = selectDraftComponentType(store.getState());
    const fontStyle = selectFontStyle(store.getState());

    if (draftComponentType === "text") {
      applyComponentAction({
        type: "setStyles",
        value: {
          fontStyle: fontStyle === "italic" ? null : "italic",
        },
      });
    }
  }, [applyComponentAction, store]);

  const handleToggleUnderlineText = React.useCallback(() => {
    const draftComponentType = selectDraftComponentType(store.getState());
    const textDecoration = selectTextDecoration(store.getState());

    if (draftComponentType === "text") {
      applyComponentAction({
        type: "setStyles",
        value: {
          textDecoration: textDecoration === "underline" ? null : "underline",
        },
      });
    }
  }, [applyComponentAction, store]);

  const handleToggleStrikethroughText = React.useCallback(() => {
    const draftComponentType = selectDraftComponentType(store.getState());
    const textDecoration = selectTextDecoration(store.getState());

    if (draftComponentType === "text") {
      applyComponentAction({
        type: "setStyles",
        value: {
          textDecoration: textDecoration ? null : "line-through",
        },
      });
    }
  }, [applyComponentAction, store]);

  const handleToggleLinkText = React.useCallback(() => {
    dispatch(setOpenPopoverId("tiptap-toolbar-link"));
  }, [dispatch]);

  const getFontSizeData = React.useCallback(() => {
    const fontSize = selectFontSize(store.getState());
    const { value, unit } = parseUnit(
      fontSize ?? 16,
      { value: "", unit: "" },
      "default",
      "px",
    );
    const newValue = typeof value === "string" ? Number.parseInt(value) : value;

    return {
      value: newValue,
      unit,
    };
  }, [store]);

  const adjustFontSize = React.useCallback(
    (type: "decrease" | "increase") => {
      const { value, unit } = getFontSizeData();

      let newFontSize;
      if (type === "decrease") {
        if (value === 1) {
          return;
        }
        newFontSize = Math.max(1, value - 1);
      } else {
        newFontSize = value + 1;
      }

      applyComponentAction({
        type: "setStyles",
        value: {
          fontSize: `${newFontSize}${unit}`,
        },
      });
    },
    [applyComponentAction, getFontSizeData],
  );

  const getLetterSpacingData = React.useCallback(() => {
    const letterSpacing = selectLetterSpacing(store.getState());
    const { value, unit } = parseUnit(
      letterSpacing ?? 0,
      { value: "", unit: "" },
      "default",
      "px",
    );
    const newValue = typeof value === "string" ? Number.parseInt(value) : value;

    return {
      value: newValue,
      unit,
    };
  }, [store]);

  const adjustLetterSpacing = React.useCallback(
    (type: "decrease" | "increase") => {
      const { value, unit } = getLetterSpacingData();

      let newLetterSpacing;
      if (type === "decrease") {
        if (value === 0) {
          return;
        } else if (value === 1) {
          newLetterSpacing = null;
        } else {
          newLetterSpacing = value - 1;
        }
      } else {
        newLetterSpacing = value + 1;
      }

      applyComponentAction({
        type: "setStyles",
        value: {
          letterSpacing:
            newLetterSpacing !== null
              ? `${newLetterSpacing}${unit}`
              : newLetterSpacing,
        },
      });
    },
    [applyComponentAction, getLetterSpacingData],
  );

  const getLineHeightData = React.useCallback(() => {
    const lineHeight = selectLineHeight(store.getState());
    const { value, unit } = parseUnit(
      lineHeight ?? 0,
      { value: "", unit: "" },
      "default",
      "px",
    );
    const newValue = typeof value === "string" ? Number.parseInt(value) : value;

    return {
      value: newValue,
      unit,
    };
  }, [store]);

  const adjustLineHeight = React.useCallback(
    (type: "decrease" | "increase") => {
      const { value, unit } = getLineHeightData();

      let newLineHeight;
      if (type === "decrease") {
        if (value === 0) {
          return;
        } else if (value === 1) {
          newLineHeight = null;
        } else {
          newLineHeight = value - 1;
        }
      } else {
        newLineHeight = value + 1;
      }

      applyComponentAction({
        type: "setStyles",
        value: {
          lineHeight:
            newLineHeight !== null ? `${newLineHeight}${unit}` : newLineHeight,
        },
      });
    },
    [applyComponentAction, getLineHeightData],
  );

  const adjustFontWeight = React.useCallback(
    (type: "decrease" | "increase") => {
      const fontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900, 950];
      const fontWeight = selectFontWeight(store.getState());
      const currentFontWeight = fontWeight ? Number(fontWeight) : 400;
      let currentIndex = fontWeights.indexOf(currentFontWeight);

      if (type === "decrease") {
        if (currentIndex > 0) {
          currentIndex -= 1;
        } else {
          return;
        }
      } else {
        if (currentIndex < fontWeights.length - 1) {
          currentIndex += 1;
        } else {
          return;
        }
      }

      const newFontWeight = fontWeights[currentIndex];

      applyComponentAction({
        type: "setStyles",
        value: {
          fontWeight: newFontWeight,
        },
      });
    },
    [applyComponentAction, store],
  );
  const enableNonDynamicTextEditing = useEnableNonDynamicTextEditing();

  useReploHotkeys({
    toggleBoldText: handleToggleBoldText,
    toggleH1Text: () => setTag("1"),
    toggleH2Text: () => setTag("2"),
    toggleH3Text: () => setTag("3"),
    toggleBulletList: () => toggleBulletList(),
    toggleNumberedList: () => toggleNumberedList(),
    toggleLinkText: handleToggleLinkText,
    toggleItalicText: handleToggleItalicText,
    toggleUnderlineText: handleToggleUnderlineText,
    toggleStrikethroughText: handleToggleStrikethroughText,
    decreaseFontSize: () => adjustFontSize("decrease"),
    increaseFontSize: () => adjustFontSize("increase"),
    decreaseLetterSpacing: () => adjustLetterSpacing("decrease"),
    ...(!isAIMenuOpen ? { editText: enableNonDynamicTextEditing } : {}),
    increaseLetterSpacing: () => adjustLetterSpacing("increase"),
    decreaseLineHeight: () => adjustLineHeight("decrease"),
    increaseLineHeight: () => adjustLineHeight("increase"),
    decreaseFontWeight: () => adjustFontWeight("decrease"),
    increaseFontWeight: () => adjustFontWeight("increase"),
  });
}

export default TextStyleModifier;

import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export const styleElements = {
  root: {
    defaultStyles: {
      display: "flex",
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    allowsLayoutModification: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    canContainChildren: true,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;

import * as React from "react";
import { Svg, type SvgProps } from "replo-utils/react/components";

export const SvgMin = (props: SvgProps) => (
  <Svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="#94A3B8"
      d="M1.86 5.91h.932l1.621 3.956h.06l1.62-3.957h.933V11h-.731V7.316h-.047l-1.502 3.676H4.14L2.638 7.314h-.047V11h-.73V5.91Zm7.05 0V11h-.768V5.91h.768Zm5.228 0V11h-.706l-2.588-3.734h-.047V11h-.768V5.91h.711l2.59 3.738h.047V5.909h.761Z"
    />
  </Svg>
);
export default SvgMin;

import Spinner from "@common/designSystem/Spinner";
import Tooltip from "@common/designSystem/Tooltip";
import { useStoreProductsFromDraftElement } from "@editor/hooks/useStoreProducts";
import { selectIsElementUpdateInProgressOrQueued } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import * as React from "react";

export const ElementUpdateLoadingIndicator = () => {
  const { isLoading: isFetchProductLoading } =
    useStoreProductsFromDraftElement();
  const elementUpdateInProgressOrQueued = useEditorSelector(
    selectIsElementUpdateInProgressOrQueued,
  );

  if (!elementUpdateInProgressOrQueued && !isFetchProductLoading) {
    return null;
  }

  return (
    <Tooltip
      triggerAsChild
      content={
        elementUpdateInProgressOrQueued
          ? "Saving your updates. Replo autosaves when you make changes."
          : "Loading product data from Shopify"
      }
    >
      <div tabIndex={-1}>
        <Spinner
          type="primary"
          size={24}
          className="text-blue-400"
          testId="pending-updates"
        />
      </div>
    </Tooltip>
  );
};

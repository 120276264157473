import type { RGBColor } from "react-color";
import tinycolor from "tinycolor2";

export const getHexColor = (value: string | RGBColor) =>
  tinycolor(value).toHexString();

export const getHex8Color = (value: string | RGBColor) =>
  tinycolor(value).toHex8String();

export const getRGBString = (value: string) => tinycolor(value).toRgbString();

export const getRGB = (value: string | null | undefined) =>
  tinycolor(value ?? undefined).toRgb();

export const getAutoCompletedColor = (color?: string | null) => {
  if (!color || color.length === 0) {
    return null;
  }

  let hexColor = color.replace(/#/g, "");
  if (hexColor.length < 3) {
    hexColor = hexColor.repeat(6 / hexColor.length);
  }

  return tinycolor(hexColor).isValid() ? getHexColor(hexColor) : null;
};

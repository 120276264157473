import type { RuntimeStyleProperties } from "../styleAttribute";
import { styleAttributeToDefaultStyle } from "../styleAttribute";

export type NormalizedFlexDirection = "row" | "column";
export type FlexDirection = RuntimeStyleProperties["flexDirection"];

/**
 * Given a flex direction, returns the direction normalized to "row" or "column".
 * "row-reverse" and "column-reverse" will be converted to their non-reverse equivalents.
 * This is useful since we want to use the normalized axis of flex containers to determine
 * which options to show for things like the align-self modifier.
 */
export const getNormalizedFlexDirection = (
  flexDirection: string | null | undefined,
): NormalizedFlexDirection => {
  switch (flexDirection) {
    case "row":
    case "row-reverse":
      return "row";
    case "column-reverse":
    case "column":
      return "column";
    default:
      return styleAttributeToDefaultStyle.flexDirection as NormalizedFlexDirection;
  }
};

import Button from "@common/designSystem/Button";
import {
  NEW_SHOPIFY_INTEGRATION_ID,
  ShopifyIntegrationSelectable,
} from "@editor/components/dashboard/projects/NewProjectFlow";
import { useSubscriptionTier } from "@editor/hooks/subscription";
import useGetProjectUtils from "@editor/hooks/useGetProjectUtils";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useGetUserWorkspaceDetailsQuery } from "@editor/reducers/api-reducer";
import { selectLoadableProject } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { mapShopifyIntegrationCapacityReasonToError } from "@editor/utils/shopifyIntegrationCapacity";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import classNames from "classnames";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { twMerge } from "tailwind-merge";

export const ConnectShopifyCallout: React.FC<{
  type: "assets" | "productPicker" | "publish" | "templateDefault";
  className?: string;
}> = ({ type, className }) => {
  const subscriptionTier = useSubscriptionTier();
  return (
    <div
      className={twMerge(
        classNames(
          "text-sm flex flex-col font-normal text-slate-500 gap-4 py-2",
          type === "publish" && "px-2",
        ),
        className,
      )}
    >
      <div className="flex flex-col gap-2">
        <h2 className="font-semibold text-default">
          {exhaustiveSwitch({ type })({
            publish: "Publish Page",
            productPicker: "Add Product Data",
            assets: "Upload Images",
            templateDefault: "Get Template Defaults",
          })}
        </h2>
        <p className="text-xs">
          {exhaustiveSwitch({ type })({
            // Note (Evan, 2024-04-09): We only tell people to upgrade their account when they are on the free plan -
            // there are other cases where an upgrade may be required (e.g. the published pages limit has been reached)
            // but the logic of figuring that out is a little annoying, so we just account for the most obvious case here.
            publish: `To publish this page, add Shopify as an integration${subscriptionTier === "free" ? " and upgrade your account" : ""}.`,
            productPicker:
              "Replo allows you to access Shopify data dynamically in the editor. You must integrate this project with Shopify.",
            assets: "To add images via upload, add Shopify as an integration.",
            templateDefault:
              "To get template defaults, add Shopify as an integration.",
          })}
        </p>
      </div>
      <img
        src="/replo-shopify-connector.svg"
        alt="Connect Replo To Shopify"
        className="block w-full"
      />
      <div className="flex flex-col gap-2">
        <ConnectShopifyCalloutAction type={type} />
        <p className="text-[10px] leading-relaxed">
          Don’t have a Shopify Store?{" "}
          <a
            href="https://airtable.com/appy2nf6tNKFEODB4/pagObddhF9f5Pln7P/form"
            className="underline"
          >
            Add yourself to the waitlist for non-Shopify deployment!
          </a>
        </p>
      </div>
    </div>
  );
};

export const ConnectShopifyCalloutAction: React.FC<{
  type:
    | "projectSettings"
    | "publish"
    | "productPicker"
    | "assets"
    | "templateDefault"
    | "billingModal";
  className?: string;
}> = ({ type, className }) => {
  const analytics = useLogAnalytics();
  const { setLatestProjectIdOnCookies } = useGetProjectUtils();
  const { projectId } = useParams();
  const { project } = useEditorSelector(selectLoadableProject);
  const workspaceId = project?.ownerWorkspace?.id;
  const { data } = useGetUserWorkspaceDetailsQuery();
  const workspaces = data?.workspaces;
  const selectedWorkspace = workspaces?.find(({ id }) => id === workspaceId);
  const [isLoading, setLoading] = React.useState(false);
  const { mutate: connectIntegration } =
    trpc.project.connectToIntegration.useMutation({
      onSuccess: () => {
        window.location.reload();
      },
    });

  const [selectedIntegrationId, setSelectedIntegrationId] = React.useState<
    string | null
  >(null);

  const shouldShowShopifyStoreSelector = type !== "billingModal";

  const cannotAddShopifyIntegrationReason =
    selectedIntegrationId === NEW_SHOPIFY_INTEGRATION_ID &&
    selectedWorkspace?.canAddShopifyIntegration?.hasCapacity === false
      ? selectedWorkspace?.canAddShopifyIntegration.reason
      : null;

  const navigate = useNavigate();

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setLoading(true);
    if (
      shouldShowShopifyStoreSelector &&
      project &&
      selectedIntegrationId &&
      selectedIntegrationId !== NEW_SHOPIFY_INTEGRATION_ID
    ) {
      try {
        connectIntegration({
          projectId: project.id,
          integrationId: selectedIntegrationId,
        });
      } catch {
        setLoading(false);
        return;
      }
    } else {
      if (workspaceId) {
        try {
          await trpcUtils.workspace.getCanAddShopifyIntegration.fetch(
            workspaceId,
          );
        } catch {
          setLoading(false);
          return;
        }
      }

      analytics("shopify.connect", {
        source: type,
      });

      if (projectId) {
        setLatestProjectIdOnCookies(projectId);
      }
      window.location.href = "https://replo.app/install/";
    }
  };

  return (
    <div>
      {shouldShowShopifyStoreSelector && (
        <ShopifyIntegrationSelectable
          className="mb-4"
          isDisabled={isLoading}
          workspaceId={workspaceId}
          selectedIntegrationId={selectedIntegrationId}
          handleChangeSelection={setSelectedIntegrationId}
        />
      )}
      <Button
        type="primary"
        size="base"
        isFullWidth
        className={className}
        onClick={(e) => {
          void handleClick(e);
        }}
        isDisabled={Boolean(cannotAddShopifyIntegrationReason)}
        isLoading={isLoading}
      >
        Add Shopify Integration
      </Button>
      {cannotAddShopifyIntegrationReason && workspaceId && (
        <div className="text-xs text-red-600 mt-3">
          {mapShopifyIntegrationCapacityReasonToError({
            reason: cannotAddShopifyIntegrationReason,
            redirectToBilling: () => {
              navigate(`/workspace/${workspaceId}/billing`);
            },
          })}
        </div>
      )}
    </div>
  );
};

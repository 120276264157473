import Button from "@common/designSystem/Button";
import useElementVersioning from "@editor/hooks/useElementVersioning";
import {
  restoreElementRevision,
  selectDraftElementId,
  selectIsPublishing,
  selectSelectedRevisionId,
  setEditorMode,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { trpc } from "@editor/utils/trpc";
import * as React from "react";

const VersioningPageButtons: React.FC = () => {
  const dispatch = useEditorDispatch();
  const isPublishable = useEditorSelector(
    (state) => state.core.pendingElementUpdates.length === 0,
  );
  const elementId = useEditorSelector(selectDraftElementId);
  const selectedRevisionId = useEditorSelector(selectSelectedRevisionId);
  const { isLoading: areElementRevisionsLoading } = useElementVersioning();
  const utils = trpc.useUtils();
  const {
    mutateAsync: revertElementVersion,
    isPending: isRestoreRevisionLoading,
  } = trpc.element.revertToRevision.useMutation({
    onSuccess: (data) => {
      void utils.element.findRevisions.invalidate({ elementId });
      dispatch(restoreElementRevision(data.element));
    },
  });

  const isPublishing = useEditorSelector(selectIsPublishing);

  return (
    <>
      <Button
        type="secondary"
        size="base"
        className="bg-slate-100 text-default"
        onClick={() => {
          dispatch(setEditorMode(EditorMode.edit));
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          if (elementId && selectedRevisionId) {
            void revertElementVersion({
              elementId,
              elementRevisionId: selectedRevisionId,
            });
          }
        }}
        isDisabled={
          !isPublishable ||
          isPublishing ||
          areElementRevisionsLoading ||
          isRestoreRevisionLoading ||
          !selectedRevisionId
        }
        type="primary"
        className="w-36 bg-blue-600"
        size="base"
        tooltipText="Restore Version"
        isLoading={isRestoreRevisionLoading}
      >
        Restore Version
      </Button>
    </>
  );
};

export default VersioningPageButtons;

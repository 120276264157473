import { headerHeight, leftBarWidth } from "@components/editor/constants";
import { getTargetFrameWindow } from "@editor/hooks/useTargetFrame";
import { selectIsPreviewMode } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import * as React from "react";
import type { Position } from "replo-runtime/shared/types";
import { useEffectEvent } from "replo-utils/react/use-effect-event";

import {
  selectCanvasDeltaXY,
  selectCanvases,
  selectCanvasInteractionMode,
  selectCanvasScale,
} from "./canvas-reducer";
import { useCanvasPortalPan } from "./useCanvasPortalPan";

export function useWheelHandler() {
  const canvases = useEditorSelector(selectCanvases);
  const canvasScale = useEditorSelector(selectCanvasScale);
  const { deltaX, deltaY } = useEditorSelector(selectCanvasDeltaXY);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const isContentEditing =
    useEditorSelector(selectCanvasInteractionMode) == "content-editing";
  const handleCanvasPortalPan = useCanvasPortalPan();

  const iframeCoordinateToCanvasCoordinate = React.useCallback(
    (iframePosition: Position): Position => {
      const x = canvasScale * iframePosition.x + deltaX + leftBarWidth;
      const y = canvasScale * iframePosition.y + deltaY + headerHeight;
      return { x, y };
    },
    [canvasScale, deltaX, deltaY],
  );

  // Note (Noah, 2022-01-11): Set handlers for wheel so that when we're content
  // editing (editing text inside the iframe) and the iframe's pointer-events is
  // auto, we still intercept events and scroll/zoom the canvas
  const getWheelHandler = useEffectEvent((targetFrame: HTMLIFrameElement) => {
    return (event: WheelEvent) => {
      const { x: clientX, y: clientY } = iframeCoordinateToCanvasCoordinate({
        x: event.clientX,
        y: event.clientY,
      });

      if (!isContentEditing) {
        handleCanvasPortalPan({
          clientX,
          clientY,
          deltaX: event.deltaX,
          deltaY: event.deltaY,
          metaKey: event.metaKey,
          ctrlKey: event.ctrlKey,
        });
        return;
      }

      event.preventDefault();
      event.stopImmediatePropagation();

      if (event.ctrlKey) {
        targetFrame.style.setProperty("pointer-events", "none");
      }

      handleCanvasPortalPan({
        clientX,
        clientY,
        deltaX: event.deltaX,
        deltaY: event.deltaY,
        metaKey: event.metaKey,
        ctrlKey: event.ctrlKey,
      });
      targetFrame.style.setProperty("pointer-events", "auto");
    };
  });

  React.useEffect(() => {
    if (
      // Note (Noah, 2022-01-13): Don't do anything in this listener if we're
      // not editing content inside the DOM, because we might be in preview
      // mode and if so we don't want the pan/zoom to happen
      isPreviewMode
    ) {
      return;
    }

    const cleanupFunctions: (() => void)[] = [];
    for (const canvas of Object.values(canvases)) {
      const canvasIFrame = canvas.targetFrame;
      const targetWindow = canvasIFrame
        ? getTargetFrameWindow(canvasIFrame)
        : null;
      if (!targetWindow || !canvasIFrame) {
        continue;
      }
      const handleWheel = getWheelHandler(canvasIFrame);
      targetWindow.addEventListener("wheel", handleWheel, {
        passive: false,
      });
      cleanupFunctions.push(() => {
        targetWindow.removeEventListener("wheel", handleWheel);
      });
    }
    return () => {
      for (const cleanup of cleanupFunctions) {
        cleanup();
      }
    };
  }, [canvases, isPreviewMode, getWheelHandler]);
}

import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _htmlContent: {
      name: "HTML Source Code",
      type: "htmlCodeEditor",
      defaultValue: null,
      description:
        "Specify the HTML source code to embed in this component. Important: be sure you trust the authors of this source code, since pasting untrusted code can affect your site's security.",
    },
  };
}

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: false,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
    ancestorDisallowList: [
      {
        ancestorTypes: ["button"],
        message: "Custom code component cannot be nested inside buttons.",
      },
    ],
  },
} satisfies ComponentConfig;

export default config;

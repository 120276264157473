import * as React from "react";

import useScript from "../../../shared/hooks/useScript";
import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import RenderComponentPlaceholder from "../RenderComponentPlaceholder";

const KnoCommerceWidget: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
}) => {
  const [isKnoRegistered, setKnoRegistered] = React.useState<boolean>(false);
  const surveyElementId = `kno-survey-${component.id}`;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const { storeUrl } = useRuntimeContext(ShopifyStoreContext);

  const knoCommerceApiKey = component.props._knoCommerceApiKey;
  const knoCommerceAccountId = component.props._knoCommerceAccountId;
  const surveyId = component.props._surveyId;

  // biome-ignore lint/correctness/useExhaustiveDependencies: storeUrl extra dep
  React.useEffect(() => {
    // NOTE (Matt 2023-07-27): KnoCommerce's widget relies on a single object exsting at `window.Kno`,
    // which inherently means that only 1 KnoCommerce widget can be loaded onto any given page,
    // otherwise they would override each other.
    if (
      globalWindow &&
      (!globalWindow.Kno || globalWindow.Kno.token) &&
      knoCommerceApiKey &&
      knoCommerceAccountId
    ) {
      globalWindow.Kno = {
        kno_id: knoCommerceApiKey,
        anonymous: true,
        survey: { selector: `#${surveyElementId}` },
      };
      // NOTE (Matt 2023-08-09): The Survey ID parameter is optional. If a user does not set the survey ID, KnoCommerce will default to their most recently published survey.
      if (surveyId) {
        globalWindow.Kno.survey.id = surveyId;
      }
      if (!isKnoRegistered) {
        setKnoRegistered(true);
      }
    }
  }, [
    globalWindow,
    surveyElementId,
    isKnoRegistered,
    knoCommerceApiKey,
    knoCommerceAccountId,
    storeUrl,
    surveyId,
  ]);

  // NOTE (Matt 2023-07-27): There will not be a race condition here because this script will not load until after `isKnoRegistered` is true,
  // which happens after our useEffect hook successfully runs.
  const src = knoCommerceAccountId
    ? `https://www.knocdn.com/v1/embed.js?id=${knoCommerceAccountId}`
    : null;
  useScript(src, { globalWindow });
  return (
    <div {...componentAttributes}>
      {knoCommerceApiKey && knoCommerceAccountId ? (
        <div>
          <div id={surveyElementId} />
        </div>
      ) : (
        isEditorApp && (
          <RenderComponentPlaceholder title="Once you set your KnoCommerce API Key and Account ID in the Config tab, your embedded KnoCommerce Widget will appear here." />
        )
      )}
    </div>
  );
};

export default KnoCommerceWidget;

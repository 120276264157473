import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const staySubscriptionWidget: ComponentTemplate = {
  id: "0c535bed-fb96-4e33-a67b-12b39c82ddbb",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Stay Ai Subscription Widget",
  leftBarCategory: { main: "productIntegrations", subCategory: "subscription" },
  preview: "integrations/stay-ai-subscription.svg",
  transforms: [],
  template: {
    id: "$uid",
    type: "staySubscriptionWidget",
    props: {},
  },
};

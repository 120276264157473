import classNames from "classnames";
import * as React from "react";

/**
 * Helper component that renders a button for choosing a layout.
 */
export const ElementEditorLayoutButton: React.FC<
  React.PropsWithChildren<{
    label: string;
    isActive: boolean;
    onClick: () => void;
    className?: string;
  }>
> = ({ label, isActive, onClick, children, className }) => {
  return (
    <>
      <button
        type="button"
        className={classNames(
          "flex aspect-[5/4] w-full cursor-pointer flex-col justify-between rounded border-2 bg-slate-50 p-0.5",
          isActive ? "border-blue-600" : "border-transparent",
          className,
        )}
        disabled={isActive}
        onClick={onClick}
      >
        {children}
      </button>
      <div className={classNames("mt-2 text-xs", isActive && "text-blue-600")}>
        {label}
      </div>
    </>
  );
};

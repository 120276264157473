import { updateAndSaveElement } from "@actions/core-actions";
import {
  Group,
  GroupHeader,
  GroupHeaderActionButton,
  GroupTitleContainer,
} from "@common/designSystem/Group";
import IconButton from "@common/designSystem/IconButton";
import Input from "@common/designSystem/Input";
import type { MenuItem } from "@common/designSystem/Menu";
import { Menu } from "@common/designSystem/Menu";
import Tooltip from "@common/designSystem/Tooltip";
import { TextTab } from "@common/TextTab";
import { elementTypeToEditorData } from "@components/editor/element";
import ResizablePane from "@components/ResizablePane";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useModal } from "@editor/hooks/useModal";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import {
  selectDraftElementType,
  selectElementById,
  selectElementMetadata,
  selectElementsMapping,
  selectIsShopifyIntegrationEnabled,
} from "@editor/reducers/core-reducer";
import type { EditorRootState } from "@editor/store";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import {
  getLastSelectedElementIdForTypeForStore,
  saveLastSelectedElementIdForTypeForStore,
} from "@editor/utils/localStorage";
import type { EditorRoute } from "@editor/utils/router";
import { routes } from "@editor/utils/router";
import { Spinner } from "@replo/design-system/components/spinner";
import classNames from "classnames";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import capitalize from "lodash-es/capitalize";
import groupBy from "lodash-es/groupBy";
import isEqual from "lodash-es/isEqual";
import * as React from "react";
import { BiSearch } from "react-icons/bi";
import { BsFillCircleFill, BsPlus } from "react-icons/bs";
import { RiHomeFill } from "react-icons/ri";
import {
  generatePath,
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { removeFolderNameFromElementName } from "replo-utils/element";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import type { ReploElement, ReploElementType } from "schemas/element";
import { twMerge } from "tailwind-merge";

type PaneInfo = Record<
  ReploElementType,
  {
    id: string;
    label: string;
    newTooltip: string;
    searchBoxPlaceholder: string;
  }
>;

export type ReploElementMetadata = Pick<
  ReploElement,
  | "id"
  | "name"
  | "type"
  | "publishedAt"
  | "isHomepage"
  | "isTurbo"
  | "createdAt"
  | "isPublished"
  | "storeId"
>;

export type GroupedElements = {
  categoryName: string | null;
  element: ReploElementMetadata;
};

type ElementListProps = {
  groupedElements: Record<string, GroupedElements[]>;
  hasItems: boolean;
  onClick: (contentItem: ReploElementMetadata) => void;
  currentElementType: ReploElementType;
};

const paneInfo: PaneInfo = {
  page: {
    id: "add-page",
    label: "Pages",
    newTooltip: "New Page",
    searchBoxPlaceholder: "Search Pages",
  },
  shopifyArticle: {
    id: "add-article",
    label: "Posts",
    newTooltip: "New Blog Post",
    searchBoxPlaceholder: "Search Posts",
  },
  shopifySection: {
    id: "add-section",
    label: "Sections",
    newTooltip: "New Shopify Section",
    searchBoxPlaceholder: "Search Sections",
  },
  shopifyProductTemplate: {
    id: "add-product-template",
    label: "Product Templates",
    newTooltip: "New Product Template",
    searchBoxPlaceholder: "Search Product Templates",
  },
};

export const reploElementDefaultCategoryName = "REPLO_WITHOUT_CATEGORY";

const ElementsPane: React.FC = () => {
  const reduxStore = useEditorStore();
  const [paneHeight, setPaneHeight] = React.useState(200);
  const projectId = useCurrentProjectId();
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const setDraftElement = useSetDraftElement();

  const navigate = useNavigate();
  const { search } = useLocation();

  const {
    items,
    currentElementType,
    setCurrentElementType,
    currentPaneInfo,
    groupedItemsByCategoryName,
  } = useCurrentElementType();

  const {
    filteredItems,
    searchTerm,
    setSearchTerm,
    groupedFilteredItemsByCategoryName,
  } = useSearchableItems(items);

  const resetDraftElement = () => {
    setDraftElement({
      componentId: null,
    });
  };

  const resetElement = (elementId: string) => {
    resetDraftElement();
    navigate(
      `${generatePath(routes.editor.element, {
        projectId,
        elementId,
      } as EditorRoute)}${search}`,
    );
  };

  const tabOptions = Object.entries(paneInfo);

  const emptyStateForCurrentElementType = exhaustiveSwitch({
    type: currentElementType,
  })({
    page: "Click the + icon to create a Shopify Page with Replo",
    shopifyArticle:
      "Click the + icon to create a Shopify Blog Article with Replo",
    shopifySection:
      "Click the + icon to create a Shopify OS2 section with Replo",
    shopifyProductTemplate:
      "Click the + icon to create a Product Template with Replo",
  });

  return (
    <div className="flex flex-col w-full">
      <div className="mx-2">
        <TextTab
          options={tabOptions.map(([key, value]) => ({
            value: key,
            label: value.label,
            isDisabled: !isShopifyIntegrationEnabled && key !== "page",
            tooltipContent:
              !isShopifyIntegrationEnabled && key !== "page"
                ? "Enabled with Shopify store integration"
                : undefined,
          }))}
          selectedValue={currentElementType}
          className="py-2"
          onChange={(value: ReploElementType) => {
            setCurrentElementType(value);
            if (projectId) {
              const lastSelectedElementIdForType =
                getLastSelectedElementIdForTypeForStore(projectId, value);
              const elementsMapping = selectElementsMapping(
                reduxStore.getState(),
              );
              if (
                lastSelectedElementIdForType &&
                elementsMapping &&
                lastSelectedElementIdForType in elementsMapping
              ) {
                // (2024-01-10 Vicky Note): Do not navigate to this element if element does not exist in redux state.
                resetElement(lastSelectedElementIdForType);
              }
            }
          }}
        />
      </div>
      <Group
        name="List"
        isCollapsible
        isDefaultOpen
        header={
          <GroupHeader
            className="mx-2"
            endEnhancer={
              <GroupHeaderActionButton
                aria-label={currentPaneInfo.newTooltip}
                onClick={() => navigate(`add?type=${currentElementType}`)}
              >
                <div data-testid={`${currentPaneInfo.id}-button`}>
                  <IconButton
                    icon={<BsPlus size={16} />}
                    tooltipText={currentPaneInfo.newTooltip}
                    type="tertiary"
                    // NOTE (Chance, 2023-05-02): This is purely decorative, as
                    // `GroupHeaderActionButton` will render the button element
                    isPhonyButton
                  />
                </div>
              </GroupHeaderActionButton>
            }
            collapseEndEnhancer={false}
          />
        }
      >
        <div className="mx-2">
          <Input
            autoComplete="off"
            placeholder={currentPaneInfo.searchBoxPlaceholder}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            onEnter={() => {
              // NOTE (Reinaldo, 2022-04-21): Select the first page on the list
              // when hitting enter if there's a page on the list and the search
              // input is not empty
              if (
                searchTerm.length > 0 &&
                filteredItems.length > 0 &&
                filteredItems[0]
              ) {
                resetElement(filteredItems[0].id);
              }
            }}
            endEnhancer={() => <BiSearch size={12} />}
          />
        </div>
        <ResizablePane
          isVertical
          size={paneHeight}
          onResize={setPaneHeight}
          minSize={200}
          maxSize={800}
          contentClassName="flex"
        >
          {filteredItems.length === 0 ? (
            <div
              className="mx-4 mt-4 flex h-fit w-full cursor-pointer flex-wrap text-left text-xs text-gray-400"
              onClick={() => {
                navigate(`add?type=${currentElementType}`);
              }}
            >
              <span>{emptyStateForCurrentElementType}</span>
            </div>
          ) : (
            <ElementList
              hasItems={items.length > 0}
              onClick={(element) => resetElement(element.id)}
              groupedElements={
                searchTerm.length > 0
                  ? groupedFilteredItemsByCategoryName
                  : groupedItemsByCategoryName
              }
              currentElementType={currentElementType}
            />
          )}
        </ResizablePane>
      </Group>
      <span className="mt-0.5 h-px w-full bg-slate-200" />
    </div>
  );
};

/* Note (Ovishek, 2022-04-20): Content or Content Item means different types of element
 * such as page, blog article or product template (in the future maybe).
 */
const ElementList: React.FC<React.PropsWithChildren<ElementListProps>> = ({
  groupedElements,
  hasItems,
  onClick,
  currentElementType,
}) => {
  const isDebugMode = useIsDebugMode();
  const { isLoading } = useCurrentProjectContext();
  const [activeElementId, setActiveElementId] = React.useState<string | null>(
    null,
  );

  const navigate = useNavigate();
  const {
    handleDeletion,
    handleDuplication,
    handleEditPage,
    handleMoveToGroup,
    handleSupportDuplication,
  } = usePageModificationHandlers();

  if (isLoading) {
    return (
      <div className="grid h-full w-full items-center justify-items-center">
        <Spinner width={2} size={20} />
      </div>
    );
  }

  if (!hasItems) {
    return null;
  }

  const elementsGroupsNames = (currentGroupName: string) => {
    return Object.keys(groupedElements)
      .filter(
        (name) =>
          ![currentGroupName, reploElementDefaultCategoryName].includes(name),
      )
      .sort();
  };

  const sortedGroupedElements = Object.entries(groupedElements).sort(
    ([titleOne], [titleTwo]) => {
      if (titleOne === reploElementDefaultCategoryName) {
        return 1;
      }
      if (titleTwo === reploElementDefaultCategoryName) {
        return -1;
      }
      return titleOne.localeCompare(titleTwo);
    },
  );

  return (
    <ul
      className="no-scrollbar mt-2 flex-1 overflow-scroll"
      data-testid="elements-list"
    >
      {sortedGroupedElements.map(([title, elements], index) => {
        if (title === reploElementDefaultCategoryName) {
          return elements.map(({ element }) => (
            <Menu
              key={element.id}
              items={getMenuItemsForElementItems({
                element,
                handleDeletion,
                handleDuplication,
                handleSupportDuplication,
                handleEditPage,
                handleMoveToGroup,
                elementsGroupsNames: elementsGroupsNames(
                  reploElementDefaultCategoryName,
                ),
                isDebugMode,
              })}
              menuType="context"
              onRequestOpen={() => setActiveElementId(element.id)}
              onRequestClose={() => setActiveElementId(null)}
              customWidth="auto"
              trigger={
                <li>
                  <ElementItem
                    element={element}
                    onClick={(element) => {
                      onClick(element);
                      saveLastSelectedElementIdForTypeForStore(
                        element.storeId,
                        element.type,
                        element.id,
                      );
                    }}
                    isFocused={activeElementId === element.id}
                  />
                </li>
              }
            />
          ));
        }

        const actionLabel = `New ${elementTypeToEditorData[currentElementType].singularDisplayName} in ${title}`;
        return (
          <Group
            name={`${title} (${elements.length})`}
            isDefaultOpen={true}
            isCollapsible
            key={`${title}-${index}`}
            contentClassName="py-0"
            header={
              <GroupHeader
                className="bg-white sticky top-0 z-10"
                collapseEndEnhancer={false}
                endEnhancer={
                  <GroupHeaderActionButton
                    aria-label={actionLabel}
                    onClick={() => {
                      const initialName = `${title.trim()}/`;
                      navigate(
                        `add?type=${currentElementType}&initialName=${initialName}`,
                      );
                    }}
                  >
                    <div data-testid={`${title}-${index}-add`} className="mr-2">
                      <IconButton
                        icon={<BsPlus size={16} />}
                        tooltipText={actionLabel}
                        type="tertiary"
                        // NOTE (Chance, 2023-11-08): This is purely decorative, as
                        // `GroupHeaderActionButton` will render the button element
                        isPhonyButton
                      />
                    </div>
                  </GroupHeaderActionButton>
                }
              >
                <GroupTitleContainer className="h-8 pl-2" />
              </GroupHeader>
            }
          >
            {elements.map(({ element }) => (
              <Menu
                key={element.id}
                items={getMenuItemsForElementItems({
                  element,
                  handleDeletion,
                  handleDuplication,
                  handleSupportDuplication,
                  handleEditPage,
                  handleMoveToGroup,
                  elementsGroupsNames: elementsGroupsNames(title),
                  isDebugMode,
                })}
                menuType="context"
                onRequestOpen={() => setActiveElementId(element.id)}
                onRequestClose={() => setActiveElementId(null)}
                customWidth="auto"
                trigger={
                  <ElementItem
                    element={element}
                    onClick={(element) => {
                      saveLastSelectedElementIdForTypeForStore(
                        element.storeId,
                        element.type,
                        element.id,
                      );
                      onClick(element);
                    }}
                    isFocused={activeElementId === element.id}
                  />
                }
              />
            ))}
          </Group>
        );
      })}
    </ul>
  );
};

const ElementItem: React.FC<{
  element: ReploElementMetadata;
  onClick(element: ReploElementMetadata): void;
  isFocused: boolean;
}> = ({ element, onClick, isFocused }) => {
  const draftElementType = useEditorSelector(selectDraftElementType);
  const { elementId } = useParams();
  const location = useLocation();
  const isCurrentElement = element.id === elementId;
  const ref = React.useRef<HTMLAnchorElement>(null);
  const difference = element.publishedAt
    ? formatDistanceToNowStrict(new Date(element.publishedAt))
    : null;
  const publishedTooltipLabel =
    element.isPublished && difference
      ? `Last Published ${difference} ago`
      : "Not Published";

  React.useEffect(() => {
    if (isCurrentElement) {
      ref.current?.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [isCurrentElement]);

  const { id, name, isHomepage, isTurbo, storeId } = element;

  if (!draftElementType) {
    return null;
  }

  if (!draftElementType) {
    return null;
  }

  const elementHasCategory = name.includes("/");
  const elementName = elementHasCategory
    ? removeFolderNameFromElementName(name)
    : name;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!e.metaKey && !e.ctrlKey) {
      e.preventDefault();
      onClick(element);
    }
  };

  return (
    <Link
      ref={ref}
      to={`${generatePath(routes.editor.element, {
        projectId: storeId,
        elementId: id,
      })}${location.search}`}
      className={twMerge(
        classNames(
          "group flex h-8 cursor-pointer items-center gap-1 pl-3 pr-4 text-xs tracking-tight text-black transition duration-300",
          {
            "bg-blue-200 font-semibold": isCurrentElement,
            "bg-blue-50": isFocused && !isCurrentElement,
            "pl-6": elementHasCategory,
            "hover:bg-blue-50": !isCurrentElement,
          },
        ),
      )}
      onClick={handleClick}
    >
      <span
        className={classNames("flex-1 truncate", {
          "text-black": !element.isPublished && !isCurrentElement,
          "text-blue-600": isCurrentElement,
        })}
      >
        {elementName}
      </span>
      {isHomepage && (
        <Tooltip content="This page is set as the Home Page" triggerAsChild>
          <div tabIndex={0}>
            <RiHomeFill size={16} className="text-slate-400" />
          </div>
        </Tooltip>
      )}
      {isTurbo && (
        <Tooltip content="This page is using Replo Turbo" triggerAsChild>
          <div
            className="w-[16px] h-[16px] bg-slate-400 rounded-full text-[10px] font-bold leading-none grid place-content-center text-white"
            tabIndex={0}
          >
            T
          </div>
        </Tooltip>
      )}
      {element.isPublished && (
        <Tooltip content={publishedTooltipLabel} triggerAsChild>
          {/* Note (Sebas, 2023-11-06): This margin is necessary to align the green dots
              with the content inside the buttons (plus icon) */}
          <div tabIndex={0} className="mr-0.5">
            <BsFillCircleFill size={8} className="text-green-400" />
          </div>
        </Tooltip>
      )}
    </Link>
  );
};

export function getMenuItemsForElementItems(params: {
  element: ReploElementMetadata;
  handleDeletion: (element: ReploElementMetadata) => void;
  handleDuplication: (element: ReploElementMetadata) => void;
  handleSupportDuplication: (element: ReploElementMetadata) => void;
  handleEditPage: (element: ReploElementMetadata) => void;
  handleMoveToGroup: (groupName: string, element: ReploElementMetadata) => void;
  elementsGroupsNames: string[];
  isDebugMode?: boolean;
}): MenuItem[] {
  const { element } = params;
  const menuItems: MenuItem[] = [
    {
      type: "leaf",
      id: "edit",
      title: `Edit ${
        elementTypeToEditorData[element.type].singularDisplayName
      } Settings`,
      onSelect: () => params.handleEditPage(element),
      isDisabled: false,
    },
    {
      type: "leaf",
      id: "rename",
      title: `Rename ${
        elementTypeToEditorData[element.type].singularDisplayName
      }`,
      onSelect: () => params.handleEditPage(element),
      isDisabled: false,
    },
    {
      type: "leaf",
      id: "duplicate",
      title: `Duplicate ${
        elementTypeToEditorData[element.type].singularDisplayName
      }`,
      onSelect: () => params.handleDuplication(element),
      isDisabled: false,
    },
    {
      type: "leaf",
      id: "delete",
      title: `Delete ${
        elementTypeToEditorData[element.type].singularDisplayName
      }`,
      onSelect: () => params.handleDeletion(element),
      isDisabled: false,
    },
  ];

  if (params.isDebugMode) {
    menuItems.push({
      type: "leaf",
      id: "duplicate.support",
      title: `Duplicate | Support Copy`,
      onSelect: () => params.handleSupportDuplication(element),
      isDisabled: false,
    });
  }

  if (params.elementsGroupsNames.length > 0) {
    menuItems.push({
      type: "nested",
      title: `Move ${
        elementTypeToEditorData[element.type].singularDisplayName
      } To Group`,
      items: params.elementsGroupsNames.map((name) => {
        return {
          type: "leaf",
          id: name.toLowerCase(),
          title: capitalize(name),
          onSelect: () => params.handleMoveToGroup(name, element),
          isDisabled: false,
        };
      }),
    });
  }
  return menuItems;
}

export const groupElements = (items: ReploElementMetadata[]) => {
  const groupedItems: GroupedElements[] = [];
  items.forEach((item) => {
    let categoryName = reploElementDefaultCategoryName;
    if (item.name.includes("/")) {
      categoryName = item.name.trim().split("/")[0]!.trim();
    }
    groupedItems.push({
      categoryName,
      element: item,
    });
  });

  const groupedElements = groupBy(groupedItems, "categoryName");

  return groupedElements;
};

export function useCurrentElementType() {
  const elementType = useEditorSelector(selectDraftElementType);
  const [currentElementType, setCurrentElementType] =
    useOverridableState<ReploElementType>(elementType);
  const items = useEditorSelector((state: EditorRootState) => {
    return selectElementMetadata(state, currentElementType);
  }, isEqual);

  const groupedItemsByCategoryName = groupElements(items);

  return {
    items,
    currentElementType,
    setCurrentElementType,
    currentPaneInfo: paneInfo[currentElementType],
    groupedItemsByCategoryName,
  };
}

function useSearchableItems(items: ReploElementMetadata[]) {
  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredItems = items.filter((item) => {
    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const groupedFilteredItemsByCategoryName = groupElements(filteredItems);
  return {
    filteredItems,
    searchTerm,
    setSearchTerm,
    groupedFilteredItemsByCategoryName,
  };
}

export function usePageModificationHandlers() {
  const store = useEditorStore();
  const modal = useModal();
  const dispatch = useEditorDispatch();
  const { items } = useCurrentElementType();
  const { filteredItems } = useSearchableItems(items);

  const handleDeletion = (metadata: ReploElementMetadata) => {
    const element = selectElementById(
      store.getState(),
      metadata.id,
    ) as ReploElement;
    modal.openModal({
      type: "deleteElementConfirmationModal",
      props: { type: "delete", element, elements: filteredItems },
    });
  };

  const handleDuplication = (metadata: ReploElementMetadata) => {
    const element = selectElementById(store.getState(), metadata.id);
    modal.openModal({
      type: "duplicateElementModal",
      props: {
        element,
      },
    });
  };

  const handleSupportDuplication = (metadata: ReploElementMetadata) => {
    const element = selectElementById(store.getState(), metadata.id);
    modal.openModal({
      type: "duplicateElementModal",
      props: {
        element,
        isSupportMode: true,
      },
    });
  };

  const handleEditPage = (metadata: ReploElementMetadata) => {
    const element = selectElementById(store.getState(), metadata.id);
    modal.openModal({
      type: "updateElementModal",
      props: {
        element,
      },
    });
  };

  const handleMoveToGroup = (
    groupName: string,
    metadata: ReploElementMetadata,
  ) => {
    const element = selectElementById(
      store.getState(),
      metadata.id,
    ) as ReploElement;
    const oldNameAfterSlash = removeFolderNameFromElementName(element.name);
    return dispatch(
      updateAndSaveElement(element.id, {
        ...element,
        name: `${groupName.trim()}/${oldNameAfterSlash}`,
      }),
    );
  };

  return {
    handleDeletion,
    handleDuplication,
    handleSupportDuplication,
    handleEditPage,
    handleMoveToGroup,
  };
}

export default ElementsPane;

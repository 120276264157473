import type { CredentialResponse } from "@react-oauth/google";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import * as React from "react";

const GoogleAuth = ({
  handleGoogleCredentials,
  width,
}: {
  handleGoogleCredentials: (
    credentialResponse: CredentialResponse,
  ) => Promise<void>;
  width?: number;
}) => {
  return (
    <span className="flex justify-center">
      <GoogleOAuthProvider clientId="127841552352-h0v9610ift21us4flnc4tdsi2nmtoi7h.apps.googleusercontent.com">
        <GoogleLogin
          type="standard"
          shape="rectangular"
          theme="outline"
          text="continue_with"
          size="large"
          width={width}
          logo_alignment="center"
          onSuccess={(credentialResponse: CredentialResponse) => {
            // NOTE (Matt 2024-02-12): GoogleLogin forces us to use a non-async function
            // for the success callback. the `handleGoogleCredentials` function should always
            // catch the error in the defined context. We've added a catch here purely so
            // the linter doesn't yell at us.
            handleGoogleCredentials(credentialResponse).catch((error) => {
              throw error;
            });
          }}
        />
      </GoogleOAuthProvider>
    </span>
  );
};

export default GoogleAuth;

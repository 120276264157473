import { formatQueryWithIds } from "@editor/hooks/useStoreProducts";
import { useGetShopifyProductsSummaryQuery } from "@editor/reducers/api-reducer";
import {
  selectDraftElementTemplateProducts,
  selectProjectId,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorProduct } from "@editor/reducers/template-reducer";
import { useEditorSelector } from "@editor/store";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { fakeProducts } from "replo-runtime/store/utils/fakeProducts";
import { isEmpty } from "replo-utils/lib/misc";

export const productSummaryRequestLimits = {
  infinite: 20,
  default: 100,
};

const useFirstNProductsSummary = (nProducts: number) => {
  const storeId = useEditorSelector(selectProjectId);
  const { data, isLoading } = useGetShopifyProductsSummaryQuery(
    storeId
      ? {
          storeId,
          pageSize: nProducts,
        }
      : skipToken,
  );
  const productsSummary = data ? data.products : undefined;
  return { productsSummary, isLoading };
};

export const useFirstNProductsSummaryOrPlaceholderProducts = (
  nProducts: number = productSummaryRequestLimits.default,
) => {
  const { productsSummary, isLoading } = useFirstNProductsSummary(nProducts);
  if (!isLoading && productsSummary && productsSummary.length === 0) {
    return {
      productsSummary: fakeProducts.map((fakeProduct) => ({
        id: Number(fakeProduct.id),
        title: fakeProduct.title,
        featuredImage: fakeProduct.featured_image,
        defaultVariantId: fakeProduct.variants[0]?.id ?? 0,
      })),
      isLoading,
      isPlaceholderProducts: true,
    };
  }
  return {
    productsSummary,
    isLoading,
    isPlaceholderProducts: false,
  };
};

export const useFetchSpecificProductsSummary = (
  ids: number[] | undefined,
  forceSkip: boolean = false,
) => {
  const storeId = useEditorSelector(selectProjectId);
  const formattedIds =
    ids && ids.length > 0 ? formatQueryWithIds(ids) : undefined;
  const { data, isLoading } = useGetShopifyProductsSummaryQuery(
    storeId && !forceSkip
      ? {
          storeId,
          pageSize: productSummaryRequestLimits.default,
          query: formattedIds,
        }
      : skipToken,
  );
  const productsSummary = data ? data.products : undefined;
  return { productsSummary, isLoading };
};

export const useTemplateEditorProductSummary = () => {
  const templateEditorProduct = useEditorSelector(selectTemplateEditorProduct);
  const { productsSummary: firstNProductsSummary } = useFirstNProductsSummary(
    productSummaryRequestLimits.default,
  );

  // Note (Evan, 2023-10-30): We check first in the fake products and the first n products, so that we only fire off
  // an additional request if we have to
  const placeholderProduct = fakeProducts.find(
    (fakeProduct) =>
      Number(fakeProduct.id) === Number(templateEditorProduct?.productId),
  );

  const cachedProduct =
    firstNProductsSummary &&
    firstNProductsSummary.find(
      (existingProduct) =>
        existingProduct.id === Number(templateEditorProduct?.productId),
    );

  const { productsSummary, isLoading } = useFetchSpecificProductsSummary(
    [templateEditorProduct ? Number(templateEditorProduct.productId) : 0],
    Boolean(!templateEditorProduct || placeholderProduct || cachedProduct),
  );

  if (!templateEditorProduct) {
    return {
      templateEditorProductSummary: null,
      isLoading: false,
    };
  }

  if (placeholderProduct) {
    return {
      templateEditorProductSummary: {
        title: placeholderProduct.title,
        id: Number(placeholderProduct.id),
        featuredImage: placeholderProduct.featured_image,
        defaultVariantId: placeholderProduct.variants[0]?.id ?? 0,
      },
      isLoading: false,
    };
  }

  if (cachedProduct) {
    return {
      templateEditorProductSummary: cachedProduct,
      isLoading: false,
    };
  }

  if (isLoading || !productsSummary) {
    return {
      templateEditorProductSummary: null,
      isLoading: true,
    };
  }

  if (!productsSummary[0]) {
    return {
      templateEditorProductSummary: null,
      isLoading: false,
    };
  }

  if (productsSummary[0].id !== Number(templateEditorProduct.productId)) {
    return {
      templateEditorProductSummary: null,
      isLoading: true,
    };
  }

  return {
    templateEditorProductSummary: productsSummary[0],
    isLoading: false,
  };
};

export const useAssignedProductsSummary = () => {
  const assignedProductIds = useEditorSelector(
    selectDraftElementTemplateProducts,
  );

  const { productsSummary, isLoading } = useFetchSpecificProductsSummary(
    assignedProductIds,
    isEmpty(assignedProductIds),
  );

  if (isEmpty(assignedProductIds) || isLoading || !productsSummary) {
    return { productsSummary: [], isLoading };
  }
  return {
    productsSummary,
    isLoading,
  };
};

import { ReploFlowsContext } from "@editor/components/flows/context/ReploFlowsContext";
import { useGetCurrentFlow } from "@editor/components/flows/hooks/useGetCurrentFlow";
import * as React from "react";
import type { FlowSlugs } from "schemas/flow";

const FlowOrchestrator: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [flowToDebug, setFlowToDebug] = React.useState<FlowSlugs | null>(null);
  const { currentFlow, currentInstance, nextStep } = useGetCurrentFlow();
  return (
    <ReploFlowsContext.Provider
      value={{
        currentFlow,
        currentInstance,
        nextStep,
        debug: {
          flowToDebug,
          setFlowToDebug,
        },
      }}
    >
      {children}
    </ReploFlowsContext.Provider>
  );
};

export default FlowOrchestrator;

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const text: ComponentTemplate = {
  id: "392a5f71-91c2-407a-b15d-a0adb152406c",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Basic/Text",
  leftBarCategory: { main: "basic", subCategory: "text" },
  preview: "basic/text.svg",

  transforms: [],
  template: {
    id: "29d75728-b9bb-4581-80e4-ff66a2f0557d",
    type: "text",
    props: {
      text: "<p>Add new text here</p>",
      style: {
        fontSize: "20px",
        lineHeight: "24px",
      },
    },
  },
};

import assign from "lodash-es/assign";
import get from "lodash-es/get";
import has from "lodash-es/has";
import isObject from "lodash-es/isObject";
import type { DynamicDataStore } from "replo-runtime/shared/runtime-context";
import type {
  ProductRefOrDynamic,
  ReploShopifyProduct,
  ReploShopifyVariant,
  ShopifySellingPlan,
  VariantWithState,
} from "replo-runtime/shared/types";
import { firstGroupMatchOfExpression } from "replo-runtime/shared/utils/regex";
import type { RenderChildren } from "replo-runtime/shared/utils/renderComponents";
import type { Variable } from "replo-runtime/shared/Variable";
import { getLiquidString } from "replo-runtime/store/utils/liquid";
import type { SelectedSellingPlanIdOrOneTimePurchase } from "replo-runtime/store/utils/product";
import type { ReploElementType } from "schemas/element";
import type { ConditionField } from "schemas/symbol";

import {
  getAlchemyGlobalPaintContext,
  type GlobalWindow,
} from "../shared/Window";
import { wrapStringWithLiquidChunks } from "./components/ReploLiquid/ReploLiquidChunk";

export type DropdownValue = {
  type: "dataTable";
  dataTableId: string;
  index: number;
  item: Record<string, any>; // data table row (column name -> value)
}; // When there are other types of dropdowns, add them here in an | type

export const TopLevelDynamicDataKeys = [
  "_product",
  "_variant",
  "_variants",
  "_sellingPlans",
  "_currentVariant",
  "_autoSelectVariant",
  "_defaultSelectedVariantId",
  "_optionsValues",
  "_selectedOptionValues",
  "_quantity",
  "_options",
  "_autoEnableHashmark",
  "_accessibilityChecked",
  "_accessibilityRole",
  "_accessibilityHidden",
  "_currentItem",
  "_currentTabItem",
  "_products",
  "_currentSelection",
  "secondsUntilEnd",
  "_currentOption",
  "_temporaryCartItems",
  "_currentTemporaryCartItem",
  "_temporaryCartTotalPrice",
  "_currentOptionValue",
  "minutesUntilEnd",
  "_temporaryCartTotalPriceIncludingDiscounts",
  "hoursUntilEnd",
  "daysUntilEnd",
  "_selectedSellingPlan",
  "_currentSellingPlan",
  "_swatches",
  "_templateProduct",
] as const;

export type TopLevelDynamicDataKey = (typeof TopLevelDynamicDataKeys)[number];
export const isTopLevelDynamicDataKey = (
  field: string,
): field is TopLevelDynamicDataKey => {
  return (TopLevelDynamicDataKeys as readonly string[]).includes(field);
};
/**
 * A context is a random blob of data passed down from components to their children.
 *
 * Components can use context to inter-connect and pass data (for example, Product component
 * passing options to an Option List component), to provide info about the current state (for
 * example, which modal is open, etc) to enable/disable certain actions and state triggers, and
 * more.
 *
 * Basically, this is equivalent to a React Context which is shared across the whole AlchemyElement
 * React tree.
 */
export interface Context {
  state: Partial<{
    productWrapperComponentId?: string;
    product: Partial<{
      product: ReploShopifyProduct | null;
      selectedOptionValues: Record<string, string | null> | null;
      selectedVariant?: ReploShopifyVariant;
      quantity: number;
      selectedSellingPlan: {
        id: number | null;
      };
      sellingPlans: ShopifySellingPlan[];
    }>;
    [propName: string]: any;
  }>;
  elementId: string;
  openModalComponent?: { id: string; repeatedIndex: string | null } | null;
  variants?: VariantWithState[];
  ancestorWithVariantsId?: string;
  selfOrParentHasVariants: boolean;
  /**
   * Variant triggers which are enabled for anything inside this component. For example,
   * inside a Product component we might want a variant trigger for a state when there
   * is no product variant selected. The values here are always true because it's easier
   * to merge together a record of true values rather than an array or a set, since components
   * further down in the tree may also merge in their own variantTriggers.
   */
  variantTriggers: Partial<Record<ConditionField, true>>;
  repeatedIndexPath: string;
  actionHooks: {
    componentIdToVariantSetters?: Record<
      string,
      {
        setActiveVariantId?(activeVariantId: string | number): void;
        getActiveVariantId(): string | number | null | undefined;
      }
    >;
    moveToNextCarouselItem?(): void;
    moveToPreviousCarouselItem?(): void;
    toggleCollapsible?(): void;
    setSelectedListItem?(): void;
    openModal?(componentId: string, repeatedIndex: string | null): void;
    setCurrentCollectionSelection?: (index: number) => void;
    setDropdownSelection?: (index: number) => void;
    increaseProductQuantity?: (quantity: number) => void;
    decreaseProductQuantity?: (quantity: number) => void;
    setProductQuantity?: (quantity: number) => void;
    setSelectedOptionValue?: (payload: {
      label: string;
      value: string;
    }) => void;
    updateCurrentProduct?: (payload: ProductRefOrDynamic) => void;
    setSelectedSellingPlan?: (
      payload: {
        sellingPlanId: SelectedSellingPlanIdOrOneTimePurchase;
      } | null,
    ) => void;
    scrollToSpecificCarouselItem?: (index: number) => void;
    goToItem?: (index: number) => void;
    goToNextItem?: () => void;
    goToPrevItem?: () => void;
    activateTabId?: (tabId: string) => void;
    setDropdownValue?: (componentId: string, value: any) => void;
    setActiveTabIndex?: (activeIndex: number) => void;
    scrollToNextCarouselItem?: () => void;
    scrollToPreviousCarouselItem?: () => void;
    decreaseVariantCountInTemporaryCart?: (
      product: ReploShopifyProduct,
    ) => void;
    removeVariantFromTemporaryCart?: (product: ReploShopifyProduct) => void;
    addVariantToTemporaryCart?: (product: ReploShopifyProduct) => void;
    setCurrentVariantId?: (variantId: number) => void;
    toggleDropdown?: () => void;
    scrollContainerRight?: (scrollAmount: number) => void;
    scrollContainerLeft?: (scrollAmount: number) => void;
    toggleFullScreen?: () => void;
    toggleMute?: () => void;
    togglePlay?: () => void;
    closeCurrentModal?: () => void;
  };
  dropdownValues: Record<string, DropdownValue>;
  tabs?: any;
  group?: {
    isDynamic: boolean;
    items: RenderChildren;
    isFirstItem: boolean;
    isLastItem: boolean;
    isActiveItem?: boolean;
    total: number;
    currentIndex: number;
  };
  attributes?: Partial<Record<TopLevelDynamicDataKey, any>>;
  attributeKeyToComponentId?: Partial<Record<TopLevelDynamicDataKey, string>>;
  elementType?: ReploElementType;
  isPublishing?: boolean;
  componentOverrides?: Record<string, any>;
  isInsideProductComponent?: boolean;
  isInsideTemplateProductComponent?: boolean;
  isInsideProductImageCarousel?: boolean;
  overrideProductLiquidOff?: boolean;
  componentReferencesTemplateProduct?: boolean;
  useSectionSettings?: boolean;
  store?: DynamicDataStore;
  klaviyoEmbedCode?: string;
  globalWindow: GlobalWindow | null;
}

export type ContextKey = keyof Context;

export const evaluateVariable = (v: Variable, context?: Context): unknown => {
  if (v == null) {
    return null;
  }
  if (typeof v === "string") {
    return compileHandlebars(v, context);
  }
  if (v.type === "constant") {
    return v.value;
  }

  return v;
};

export const evaluateVariableAsString = (
  v: Variable,
  context?: Context,
): string | undefined => {
  const evaluatedValue = evaluateVariable(v, context);
  if (typeof evaluatedValue === "string") {
    return evaluatedValue;
  }
  return undefined;
};

export const resolveDynamicDataString = (
  dynamicDataString: string,
  formatMatch: string | null,
  context?: Context,
) => {
  // NOTE (Gabe 2023-07-20): If we're publishing a product, we want to convert
  // the dynamicDataString to a liquid representation so Shopify is able to
  // inject the correct data while serving the page.
  if (
    context?.isPublishing &&
    ((context.isInsideProductComponent && !context.overrideProductLiquidOff) ||
      context?.useSectionSettings)
  ) {
    const liquidString = getLiquidString(dynamicDataString, context);
    if (liquidString) {
      return wrapStringWithLiquidChunks(liquidString);
    }
  }

  let contextKey = dynamicDataString.replace("{{", "").replace("}}", "");
  if (formatMatch) {
    contextKey = contextKey.replace(formatMatch, "");
  }
  let contextValue = get(context, contextKey, null);

  // Note (Evan, 2024-03-13): If we get null, it's possible that the contextKey has dots
  // in it. We can try to parse out the value via a greedy search.
  if (contextValue === null && context) {
    contextValue = resolveDynamicDataStringGreedy(context, contextKey);
  }
  return contextValue;
};

const replaceOneMatch = (target: string, context: Context): string | object => {
  let match;
  // biome-ignore lint/suspicious/noAssignInExpressions: allow expression set in parens
  if ((match = firstGroupMatchOfExpression(/({{.*?}})/g, target)) !== null) {
    let formatMatch;
    let formatTransform;

    // Try to parse out format specifiers of type |format to apply a transform
    // to the parsed value (poor man's liquid template system)
    if (
      // Note (Noah, 2023-11-24): Explainer for this regex - we want to match
      // the LAST occurrence of a "|" format specifier, something like
      // "{{my.dyna|mic.path|json}}". We want to match the last occurrence since
      // a segment of the dynamic path could have a pipe character in it. So:
      // 1. We start a capturing group
      // 2. We look for a pipe character
      // 3. After that pipe character, we look for an arbitrary number of characters
      //    OTHER than a pipe character, immediately followed by }} (end of the
      //    dynamic data value)
      // This results in "|json" being returned for the example above.
      // biome-ignore lint/suspicious/noAssignInExpressions: allow expression set in parens
      (formatMatch = firstGroupMatchOfExpression(/(\|[^|]*?)}}/g, match)) !==
      null
    ) {
      const formatKey = formatMatch.replace("|", "");
      const formatKeyToReplacement = {
        productDescription: (value: string) => {
          return value
            .replace(/(\r\n|\r|\n|\t)/g, "")
            .replace(new RegExp('"', "g"), '\\"');
        },
      };
      formatTransform = get(
        formatKeyToReplacement,
        formatKey as keyof typeof formatKeyToReplacement,
        null,
      );
    }

    let contextValue = resolveDynamicDataString(
      match,
      // Note (Noah, 2023-11-06, USE-540): We only want to parse out the "|
      // format" content if it corresponds to an actual transform. This ensures
      // that we don't accidentally render the wrong swatch name if the swatch
      // contains the "|" character, for example
      formatTransform ? formatMatch : null,
      context,
    ) as unknown as string;
    if (formatTransform) {
      contextValue = formatTransform(contextValue);
    }
    if (isObject(contextValue)) {
      return contextValue;
    }

    return target.replace(match, contextValue === null ? "" : contextValue);
  }
  return target;
};

const compileHandlebars = (
  target: string,
  context?: Context,
): string | object => {
  if (!context) {
    return "";
  }

  // TODO (Matt 2024-04-18): The only reason that we need to run this (jank)
  // code where we get the features object from window.alchemy is because of
  // how the current GoPuff integration works. Once we're able to create a
  // better integration for them (in the Integration Hub), then we can remove this.
  const windowStore = getAlchemyGlobalPaintContext()?.features;
  if (windowStore) {
    context.store = assign({}, context.store, windowStore);
  }

  try {
    // NOTE (Gabe 2023-11-09): If in the future we need to be able to replace
    // multiple matches we should update replaceOneMatch to be able to find
    // multiple matches instead of calling it repeatedly. This is because we use
    // this to map dynamic data to liquid and if we do that and then call
    // replaceOneMatch again on the returned string the liquid will get mapped
    // to nothing.
    return replaceOneMatch(target, context);
  } catch (error) {
    console.error(`Cannot compile Handlebars: ${target}, ${error}`);
    return "";
  }
};

/**
 * Performs a greedy search in order to attempt to resolve a key that may contain dots.
 * The approach is as follows:
 * - Start with an empty path
 * - Attempt to extend the path with the next segment, then the next 2 segments (treated as one key by joining them with a "."),
 *   then the next 3, etc. If any of these potential paths are present in the context, we add that to the path -
 *   this makes this a greedy algorithm.
 * - Continue until all segments are used, or we reach a point where we are unable to construct a path that is present in context
 *
 * This is not guaranteed to handle all cases, but it's probably good enough to handle dynamic data keys with "." present.
 */
const resolveDynamicDataStringGreedy = (context: Context, key: string) => {
  const segments = key.split(".");
  let path: string[] = [];
  let i = 0;
  while (i < segments.length) {
    let added = false;
    for (let j = i; j < segments.length; j++) {
      // Note (Evan, 2024-03-13): Constructs a prospective path from the current path, plus
      // the (j - i + 1) segments we could add (treated as one key by re-joining them with a ".")
      const prospectivePath = [...path, segments.slice(i, j + 1).join(".")];

      if (has(context, prospectivePath)) {
        path = prospectivePath;
        // Note (Evan, 2024-03-13): Skip to the remaining segments
        i = j + 1;
        added = true;
        break;
      }
    }

    // Note (Evan, 2024-03-13): If no combination of remaining segments was valid, just return null
    if (!added) {
      return null;
    }
  }
  return get(context, path, null);
};

import Button from "@components/common/designSystem/Button";
import {
  buildCreateExperimentArgs,
  StatusTag,
  useExperimentApi,
} from "@components/projectDashboard/experiments/common";
import IconButton from "@editor/components/common/designSystem/IconButton";
import { successToast } from "@editor/components/common/designSystem/Toast";
import Tooltip from "@editor/components/common/designSystem/Tooltip";
import { Loader } from "@editor/components/common/Loader";
import { BetaTag } from "@editor/components/projectDashboard/common/BetaTag";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import {
  useSubscriptionInfo,
  useSubscriptionTier,
} from "@editor/hooks/subscription";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { docs } from "@editor/utils/docs";
import { routes } from "@editor/utils/router";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import {
  format,
  formatDistanceStrict,
  formatDistanceToNowStrict,
} from "date-fns";
import * as React from "react";
import { BiCopy } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import type { Experiment } from "schemas/experiment";
import { getExperimentStatus } from "schemas/experiment";
import type { ReploProject } from "schemas/project";

export const ExperimentsTab = () => {
  const { project, isLoading: isLoadingProject } = useCurrentProjectContext();
  const projectId = project?.id;

  const logEvent = useLogAnalytics();
  const {
    list: { data, isFetching: isFetchingExperiments },
  } = useExperimentApi({ projectId });

  const { subscriptionInfo } = useSubscriptionInfo();
  React.useEffect(() => {
    logEvent("experiment.view.list", {
      billingPlanTier: subscriptionInfo.tier,
    });
  }, [logEvent, subscriptionInfo.tier]);

  if (isFetchingExperiments || !data || isLoadingProject || !project) {
    return <Loader />;
  }

  return <AllExperiments experiments={data.experiments} project={project} />;
};

const AllExperiments = ({
  project,
  experiments,
}: {
  project: ReploProject;
  experiments: Array<Experiment>;
}) => {
  const logEvent = useLogAnalytics();
  const navigate = useNavigate();
  const subscriptionTier = useSubscriptionTier();
  const {
    create: { mutateAsync: create, isPending: isCreating },
  } = useExperimentApi({ projectId: project.id });
  const handleCreateNewExperiment = async () => {
    const experimentData = buildCreateExperimentArgs(
      project.id,
      experiments,
      project.integrations?.shopify?.store.shopifyUrl,
    );
    await create(experimentData);
    logEvent("experiment.created", { billingPlanTier: subscriptionTier });
    successToast("Experiment Created", "");
    navigate(
      generatePath(routes.editor.experiments.edit, {
        projectId: project.id,
        experimentId: experimentData.id,
      }),
    );
  };
  const experimentBaseUrl = `https://${
    project.customDomain ?? `reploedge.com/${project.slug}`
  }`;

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center content-center text-sm">
        <div className="font-semibold flex gap-3 items-center">
          Experiments <BetaTag />
        </div>
        <div>
          <Button
            type="primary"
            size="base"
            onClick={() => void handleCreateNewExperiment()}
            isLoading={isCreating}
          >
            New Experiment
          </Button>
        </div>
      </div>
      <div>
        <table className="table-auto w-full text-sm">
          <thead>
            <tr className="border-b font-semibold text-left h-8">
              <th className="w-full max-w-[20%]">Name</th>
              <th className="w-full max-w-[20%]">Description</th>
              <th>Path</th>
              <th>Created</th>
              <th className="content-center">
                <Tooltip
                  content="Time the experiment has been active."
                  triggerAsChild
                  side="bottom"
                >
                  <div
                    className="flex items-center content-center"
                    tabIndex={0}
                  >
                    Duration
                    <span className="pl-2">
                      <BsInfoCircle className="text-slate-400" />
                    </span>
                  </div>
                </Tooltip>
              </th>
              <th className="whitespace-nowrap w-0">Status</th>
            </tr>
          </thead>
          <tbody>
            {experiments.map((experiment) => (
              <ExperimentRow
                experimentBaseUrl={experimentBaseUrl}
                key={experiment.id}
                experiment={experiment}
              />
            ))}
          </tbody>
        </table>
      </div>

      <div className="overflow-y-scroll">
        {experiments.length === 0 && (
          <div className="text-base my-4 p-1 text-slate-500">
            No experiments yet.
          </div>
        )}
        <ExperimentBetaInfo
          onCreate={() => void handleCreateNewExperiment()}
          isCreating={isCreating}
        />
      </div>
    </div>
  );
};

const ExperimentBetaInfo = ({
  onCreate,
  isCreating,
}: {
  onCreate: () => void;
  isCreating: boolean;
}) => {
  return (
    <div className="mt-4 p-5 rounded-md bg-slate-200 max-w-2xl mb-2">
      <div className="mb-4">
        <div className="my-2 text-2xl font-medium text-default">
          Welcome to Replo’s A/B Testing Beta!
        </div>
        <div className="max-w-2xl text-sm">
          <p className="mb-6">
            We’re rolling this out for our friends on the Growth and Custom
            plans in the next few weeks.
          </p>
          <p>
            Experiments direct traffic between your pages so you can determine
            which one is better. You can learn more about this feature in{" "}
            <a
              className="hover:underline text-blue-600"
              href={docs.abTesting.main}
            >
              Replo’s documentation
            </a>
            .
          </p>
        </div>
      </div>
      <div>
        <Button
          type="primary"
          size="base"
          icon={<span className="pl-3">→</span>}
          onClick={onCreate}
          isLoading={isCreating}
        >
          Create Experiment
        </Button>
      </div>
    </div>
  );
};

const ExperimentRow = ({
  experimentBaseUrl,
  experiment: {
    id,
    name,
    description,
    slug,
    createdAt,
    activatedAt,
    completedAt,
    archivedAt,
  },
}: {
  experimentBaseUrl: string;
  experiment: Experiment;
}) => {
  const { projectId } = useParams();
  const path = generatePath(routes.editor.experiments.detail, {
    projectId,
    experimentId: id,
  });
  const status = getExperimentStatus({
    createdAt,
    activatedAt,
    completedAt,
    archivedAt,
  });
  const createDateRelative = formatDistanceToNowStrict(createdAt, {
    addSuffix: true,
  });
  const createDateHuman = format(createdAt, "MMMM d, hh:mma");
  const duration = activatedAt
    ? formatDistanceStrict(activatedAt, completedAt || archivedAt || new Date())
    : "-";
  const experimentUrl = `${experimentBaseUrl}/${slug}`;
  return (
    <tr className="h-10">
      <td className="truncate text-blue-600 h-10 max-w-[1px]">
        <div className="mr-3 overflow-hidden truncate">
          <Link className="text-blue-600 font-medium" to={path}>
            {name}
          </Link>
        </div>
      </td>
      <td className="h-10 truncate max-w-[1px]">
        <div
          className={classNames("mr-3 overflow-hidden truncate", {
            "text-slate-400": description === "",
          })}
        >
          {description === "" ? "No description" : description}
        </div>
      </td>
      <td className="h-10 overflow-hidden max-w-[1px] min-w-[180px]">
        <div className="flex flex-row gap-x-2 items-center content-center justify-between pr-3">
          <span className="truncate">{`/${slug}`}</span>
          <IconButton
            tooltipText="Copy experiment URL"
            type="tertiary"
            className="h-7 w-7 grow-1 shrink-0"
            icon={<BiCopy size={16} />}
            onClick={() => {
              copy(experimentUrl);
              successToast("Experiment URL Copied", "");
            }}
          />
        </div>
      </td>
      <td className="h-10">
        <div className="text-slate-500 mr-1">
          <Tooltip content={createDateHuman} triggerAsChild>
            <span className="cursor-default" tabIndex={0}>
              {createDateRelative}
            </span>
          </Tooltip>
        </div>
      </td>
      <td className="h-10">
        <div className="text-slate-500 mr-1">{duration}</div>
      </td>
      <td className="h-10">
        <div className="mr-1">
          <StatusTag status={status} />
        </div>
      </td>
    </tr>
  );
};

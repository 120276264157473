import { resetProductData } from "@editor/reducers/commerce-reducer";
import {
  selectProjectId,
  setElementOnGetProject,
} from "@editor/reducers/core-reducer";
import { getFieldMapping } from "@editor/reducers/utils/core-reducer-utils";
import { useEditorDispatch, useEditorStore } from "@editor/store";
import {
  getLastSelectedElementIdForProject,
  saveCurrentElementId,
} from "@editor/utils/localStorage";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";
import { skipToken } from "@tanstack/react-query";
import mapValues from "lodash-es/mapValues";
import * as React from "react";
import { matchPath, useParams } from "react-router-dom";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import { noop } from "replo-utils/lib/misc";
import type { ReploElement } from "schemas/element";
import type { ReploProject } from "schemas/project";

type CurrentProjectContext = {
  project?: ReploProject;
  isLoading: boolean;
  // TODO (Fran 2024-08-29): improve this type
  error: any;
  refetch(): void;
};

const CurrentProjectContext = React.createContext<CurrentProjectContext>({
  project: undefined,
  isLoading: false,
  error: undefined,
  refetch: noop,
});

export const useCurrentProjectContext = () => {
  return React.useContext(CurrentProjectContext);
};

const CurrentProjectProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { projectId } = useParams();
  const { project, error, isLoading, refetch } = useGetProjectQuery(
    projectId ?? null,
  );

  return (
    <CurrentProjectContext.Provider
      value={{
        project,
        error,
        isLoading,
        refetch: () => void refetch(),
      }}
    >
      {children}
    </CurrentProjectContext.Provider>
  );
};

export default CurrentProjectProvider;

function useGetProjectQuery(projectId: string | null) {
  const {
    data: projectData,
    isSuccess,
    isLoading,
    error,
    refetch,
  } = trpc.project.get.useQuery(projectId ?? skipToken);

  const dispatch = useEditorDispatch();
  const store = useEditorStore();

  React.useEffect(() => {
    const existingProjectId = selectProjectId(store.getState());

    // NOTE (Fran 2024-08-23): Reinitialize the project and reset all product data whenever
    // the user switches to a different project.
    if (existingProjectId !== projectId) {
      dispatch(resetProductData());
    }
  }, [projectId, store, dispatch]);

  // biome-ignore lint/correctness/useExhaustiveDependencies(isLoading): We actually want this dep
  React.useEffect(() => {
    // NOTE (Fran 2024-08-23): Initialize the project only once when data is available.
    // Re-initialization occurs only if the project changes.
    if (projectData && isSuccess) {
      const elementMapping = getFieldMapping(projectData.elements ?? [], "id");
      const versionMapping = mapValues(
        elementMapping,
        (value) => value.version,
      );

      // try to match the path with the router element or product routes
      let pathMatch = null;
      pathMatch = matchPath(routes.editor.element, window.location.pathname);
      if (!pathMatch) {
        pathMatch = matchPath(routes.editor.product, window.location.pathname);
      }

      // Grab the element ID from the URL if one is specified,
      // otherwise try to get it from local storage
      const lastSavedElementId = getLastSelectedElementIdForProject(
        projectData.id,
      );

      let draftElementIdFromUrlOrLocalStorage =
        pathMatch?.params?.elementId || null;

      if (
        lastSavedElementId &&
        elementMapping[lastSavedElementId]?.storeId === projectData.id
      ) {
        draftElementIdFromUrlOrLocalStorage = lastSavedElementId;
      }

      // Set the element to the one from the URL or the localstorage or first one
      const draftElement =
        (getFromRecordOrNull(
          elementMapping,
          draftElementIdFromUrlOrLocalStorage,
        ) as ReploElement | null) ??
        ((projectData.elements?.[0] ?? null) as ReploElement | null);

      if (lastSavedElementId !== draftElement?.id && draftElement) {
        saveCurrentElementId(
          draftElement.id,
          elementMapping[draftElement.id]!.storeId,
        );
      }

      dispatch(
        setElementOnGetProject({
          project: projectData,
          draftElementId: draftElement?.id,
          elementMapping,
          versionMapping,
        }),
      );
    }
  }, [projectData, isSuccess, isLoading, dispatch]);

  React.useEffect(() => {
    if ((error as any)?.error?.status === 401) {
      window.location.href = "/auth/login";
    }
  }, [error]);

  return { project: projectData, refetch, isLoading, error };
}

import forEach from "lodash-es/forEach";
import { v4 as uuidv4 } from "uuid";

import type { Action } from "../types";

export function combineActionsIfIsNecessary(actions: Action[]) {
  const combinedActions: Action[] = [];

  forEach(actions, (action, index) => {
    const previousOriginalAction = actions[index - 1];
    const lastCombinedAction = combinedActions[combinedActions.length - 1];

    const isActionAddProductVariantToCart =
      action.type === "addProductVariantToCart";
    const isPreviousActionAddProductVariantToCart =
      previousOriginalAction?.type === "addProductVariantToCart";
    const isConsecutiveAddToCartAction =
      index > 0 &&
      isActionAddProductVariantToCart &&
      isPreviousActionAddProductVariantToCart;
    const isLastActionAlreadyCombined =
      lastCombinedAction?.type === "multipleProductVariantsAddToCart";

    // Note (Fran 2022-11-10): We need to look if the current action and the
    // previous action both are AddProductVariantToCart, if both have the same
    // action type we need to combine to avoid unnecessary fetches.
    if (isConsecutiveAddToCartAction && !isLastActionAlreadyCombined) {
      // Note (Fran 2022-11-10): If last action is not a combine action of
      // add to cart actions, we need to combine both, previous and current
      // action. That's why we get rid of the last one
      combinedActions.pop();
      combinedActions.push({
        id: uuidv4(),
        type: "multipleProductVariantsAddToCart",
        value: [previousOriginalAction.value, action.value],
      });
    } else if (isConsecutiveAddToCartAction) {
      lastCombinedAction?.value.push(action.value);
    } else {
      combinedActions.push(action);
    }
  });

  return combinedActions;
}

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const stampedProductReviewsWidget: ComponentTemplate = {
  id: "145e6418-edd1-42b3-81fb-f568b8f4edbc",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Stamped Product Reviews",
  leftBarCategory: { main: "productIntegrations", subCategory: "stamped" },
  preview: "integrations/stamped-product-reviews.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  tags: ["widget"],
  template: {
    id: "$uid",
    type: "stampedProductReviewsWidget",
    props: { style: { alignSelf: "stretch" } },
  },
};

import { z } from "zod";

// #region Flow input components schemas
const optionSchema = z.object({
  value: z.string(),
  label: z.string(),
  hasInput: z.boolean(),
  extraData: z.record(z.string(), z.unknown()).optional(),
});

const selectableButtonWithInputPropsSchema = z.object({
  type: z.literal("selectable-button"),
  label: z.string(),
  options: z.array(optionSchema),
});

const textInputPropsSchema = z.object({
  type: z.literal("input"),
  label: z.string(),
  placeholder: z.string(),
  inputType: z.literal("text"),
  isRequired: z.boolean(),
});

const stepResultPropertySchema = z.union([
  z.literal("buildingFor"),
  z.literal("isUserReferredOrInvited"),
]);

// #endregion

export const stepTypeSchema = z.enum([
  // #region Onboarding flow steps
  "onboarding.user.full-name",
  "onboarding.user.how-did-you-hear-about-us",
  "onboarding.user.who-are-you-building-pages-for",
  "onboarding.user.are-you-using-solo-or-team",
  "onboarding.user.what-do-you-want-to-do-in-replo",
  "onboarding.user.experience-level-with-nocode-builders",
  "onboarding.user.do-you-want-to-start-from-template",
  "onboarding.user.who-are-you-building-pages-for.revenue",
  "onboarding.user.which-best-describes-your-industry",
  // #endregion
  // #region Editor tour flow steps
  "tour.editor.generic",
  // #endregion
  // Add other flow steps here
]);

const flowSlugs = z.union([
  z.literal("onboarding"),
  z.literal("editor-tour"),
  // Add other flow slugs here
]);

const onboardingFlowConfigSchema = z.union([
  z.object({
    type: z.literal("onboarding.user.full-name"),
    props: z.array(textInputPropsSchema),
  }),
  z.object({
    type: z.literal("onboarding.user.how-did-you-hear-about-us"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.who-are-you-building-pages-for"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.are-you-using-solo-or-team"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.what-do-you-want-to-do-in-replo"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.experience-level-with-nocode-builders"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.do-you-want-to-start-from-template"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.who-are-you-building-pages-for.revenue"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.which-best-describes-your-industry"),
    props: selectableButtonWithInputPropsSchema,
  }),
]);
const editorTourConfigSchema = z.object({
  type: z.literal("tour.editor.generic"),
  props: z.object({
    content: z.object({
      type: z.literal("text"),
      text: z.string(),
    }),
    side: z.union([
      z.literal("left"),
      z.literal("right"),
      z.literal("top"),
      z.literal("bottom"),
      z.literal("bottom-left"),
    ]),
  }),
});

const flowStepConfigSchema = z.union([
  onboardingFlowConfigSchema,
  editorTourConfigSchema,
]);

const onboardingFlowStepDataSchema = z.union([
  z.object({
    type: z.literal("onboarding.user.full-name"),
    data: z.record(
      z.union([z.literal("firstName"), z.literal("lastName")]),
      z.string(),
    ),
  }),
  z.object({
    type: z.literal("onboarding.user.how-did-you-hear-about-us"),
    data: z.record(z.literal("heardFrom"), z.array(z.string())),
  }),
  z.object({
    type: z.literal("onboarding.user.who-are-you-building-pages-for"),
    data: z.record(z.literal("buildingFor"), z.string()),
  }),
  z.object({
    type: z.literal("onboarding.user.are-you-using-solo-or-team"),
    data: z.object({
      usingAs: z.string(),
      workspaceName: z.string(),
      projectId: z.string().optional(),
      createdWorkspaceId: z.string().optional(),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.what-do-you-want-to-do-in-replo"),
    data: z.record(z.literal("whatToDo"), z.array(z.string())),
  }),
  z.object({
    type: z.literal("onboarding.user.experience-level-with-nocode-builders"),
    data: z.record(z.literal("experienceLevel"), z.string()),
  }),
  z.object({
    type: z.literal("onboarding.user.do-you-want-to-start-from-template"),
    data: z.object({
      startFrom: z.string(),
      templateId: z.string().optional(),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.who-are-you-building-pages-for.revenue"),
    data: z.record(z.literal("yearlyRevenue"), z.string()),
  }),
  z.object({
    type: z.literal("onboarding.user.which-best-describes-your-industry"),
    data: z.record(z.literal("industry"), z.string()),
  }),
]);
const editorTourStepDataSchema = z.object({
  type: z.literal("tour.editor.generic"),
  data: z.object({}),
});

export const flowStepDataSchema = z.union([
  onboardingFlowStepDataSchema,
  editorTourStepDataSchema,
]);

const flowConditionSchema = z.union([
  z.object({
    type: z.literal("response"),
    step: stepTypeSchema,
    nextStep: z.string(),
    operator: z.literal("eq"),
    property: stepResultPropertySchema,
    value: z.union([z.string(), z.boolean(), z.null()]),
  }),
  z.object({
    type: z.literal("response"),
    step: stepTypeSchema,
    nextStep: z.string(),
    operator: z.literal("notIn"),
    property: stepResultPropertySchema,
    value: z.array(z.string()),
  }),
  z.object({
    type: z.literal("and"),
    step: stepTypeSchema,
    nextStep: z.string(),
    conditions: z.array(
      z.union([
        z.object({
          type: z.literal("response"),
          operator: z.literal("eq"),
          property: stepResultPropertySchema,
          value: z.union([z.string(), z.boolean(), z.null()]),
        }),
        z.object({
          type: z.literal("response"),
          operator: z.literal("notIn"),
          property: stepResultPropertySchema,
          value: z.array(z.string()),
        }),
      ]),
    ),
  }),
]);

export const flowNextStepSchema = z.union([
  z.string(),
  z.array(flowConditionSchema),
  z.null(),
]);

export const flowNextStepSubmitResponseSchema = z.union([
  z.string(),
  z.null(),
  z.undefined(),
]);

const flowStepSchema = z
  .object({
    id: z.string(),
    isSkippable: z.boolean(),
    nextStep: flowNextStepSchema,
  })
  .and(flowStepConfigSchema);

export const arrayOfFlowStepSchema = z.array(flowStepSchema);

export const flowEntityTypeSchema = z.union([
  z.literal("project"),
  z.literal("workspace"),
  z.literal("user"),
]);

const flowTriggerSchema = z.union([
  z.literal("automatic"),
  z.literal("manual"),
]);

const flowStatusSchema = z.union([z.literal("draft"), z.literal("live")]);

const flowSchema = z.object({
  slug: flowSlugs,
  name: z.string(),
  trigger: flowTriggerSchema,
  type: flowEntityTypeSchema,
  status: flowStatusSchema,
  steps: arrayOfFlowStepSchema,
  isRepeatable: z.boolean(),
});

const flowInstanceStepResultSchema = z.intersection(
  z.object({ completedAt: z.string(), skipped: z.boolean() }),
  flowStepDataSchema,
);

const flowStepResultsSchema = z.record(
  z.string(),
  flowInstanceStepResultSchema,
);

export const flowInstanceSchema = z.object({
  id: z.string(),
  flow: flowSchema,
  entityId: z.string(),
  entityType: z.string(),
  stepResults: flowStepResultsSchema,
  completedAt: z.coerce.date().nullable(),
  isDebug: z.boolean(),
});

// #region Flow Response Schemas
export const flowResponseSchema = z.object({
  slug: flowSlugs,
  name: z.string(),
  trigger: flowTriggerSchema,
  type: flowEntityTypeSchema,
  status: flowStatusSchema,
  description: z.string().nullable(),
  steps: arrayOfFlowStepSchema,
  isRepeatable: z.boolean(),
  updatedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
});

export type FlowResponse = {
  slug: "onboarding" | "editor-tour";
  name: string;
  trigger: "automatic" | "manual";
  type: "project" | "workspace" | "user";
  status: "draft" | "live";
  description: string | null;
  steps: FlowStep[];
  isRepeatable: boolean;
  updatedAt: Date;
  createdAt: Date;
};

export const flowInstanceResponseSchema = z.object({
  instance: flowInstanceSchema,
  nextStep: z.string().nullish(),
});

export type FlowInstance = {
  id: string;
  flow: Flow;
  entityId: string;
  entityType: string;
  stepResults: {
    [x: string]: {
      completedAt: string;
      skipped: boolean;
    } & (
      | (
          | {
              type: "onboarding.user.full-name";
              data: {
                [x: string]: string;
              };
            }
          | {
              type: "onboarding.user.how-did-you-hear-about-us";
              data: {
                [x: string]: string[];
              };
            }
          | {
              type: "onboarding.user.who-are-you-building-pages-for";
              data: {
                [x: string]: string;
              };
            }
          | {
              type: "onboarding.user.are-you-using-solo-or-team";
              data: {
                usingAs: string;
                workspaceName: string;
                projectId?: string;
                createdWorkspaceId?: string;
              };
            }
          | {
              type: "onboarding.user.what-do-you-want-to-do-in-replo";
              data: {
                [x: string]: string[];
              };
            }
          | {
              type: "onboarding.user.experience-level-with-nocode-builders";
              data: {
                [x: string]: string;
              };
            }
          | {
              type: "onboarding.user.do-you-want-to-start-from-template";
              data: {
                startFrom: string;
                templateId?: string;
              };
            }
          | {
              type: "onboarding.user.who-are-you-building-pages-for.revenue";
              data: {
                [x: string]: string;
              };
            }
          | {
              type: "onboarding.user.which-best-describes-your-industry";
              data: {
                [x: string]: string;
              };
            }
        )
      | {
          type: "tour.editor.generic";
          data: {};
        }
    );
  };
  completedAt: Date | null;
  isDebug: boolean;
};

export type FlowInstanceResponse = {
  instance: FlowInstance;
  nextStep?: string | null;
};
// #endregion

export type FlowStepType =
  | "onboarding.user.full-name"
  | "onboarding.user.how-did-you-hear-about-us"
  | "onboarding.user.who-are-you-building-pages-for"
  | "onboarding.user.are-you-using-solo-or-team"
  | "onboarding.user.what-do-you-want-to-do-in-replo"
  | "onboarding.user.experience-level-with-nocode-builders"
  | "onboarding.user.do-you-want-to-start-from-template"
  | "onboarding.user.who-are-you-building-pages-for.revenue"
  | "onboarding.user.which-best-describes-your-industry"
  | "tour.editor.generic";

type FlowStepResultProperty = "buildingFor" | "isUserReferredOrInvited";

// Define the union types for different conditions in the nextStep field
export type FlowStepCondition =
  | {
      type: "response";
      step: FlowStepType;
      nextStep: string;
      operator: "eq";
      property: FlowStepResultProperty;
      value: string | boolean | null;
    }
  | {
      type: "response";
      step: FlowStepType;
      nextStep: string;
      operator: "notIn";
      property: FlowStepResultProperty;
      value: string[];
    }
  | {
      type: "and";
      step: FlowStepType;
      nextStep: string;
      conditions: Array<
        | {
            type: "response";
            operator: "eq";
            property: FlowStepResultProperty;
            value: string | boolean | null;
          }
        | {
            type: "response";
            operator: "notIn";
            property: FlowStepResultProperty;
            value: string[];
          }
      >;
    };

// Define the type for the `nextStep` field
export type FlowNextStep = string | FlowStepCondition[] | null;

// Define the main `FlowStep` type
export type FlowStep = {
  id: string;
  isSkippable: boolean;
  nextStep: FlowNextStep;
} & (
  | {
      type: "onboarding.user.full-name";
      props: {
        type: "input";
        label: string;
        placeholder: string;
        inputType: "text";
        isRequired: boolean;
      }[];
    }
  | {
      type: FlowStepType;
      props: {
        type: "selectable-button";
        label: string;
        options: {
          value: string;
          label: string;
          hasInput: boolean;
          extraData?: Record<string, unknown>;
        }[];
      };
    }
  | {
      type: "tour.editor.generic";
      props: {
        content: {
          type: "text";
          text: string;
        };
        side: "left" | "right" | "top" | "bottom" | "bottom-left";
      };
    }
);

export type Flow = {
  slug: FlowSlugs;
  name: string;
  trigger: "automatic" | "manual";
  type: FlowEntity;
  status: "draft" | "live";
  steps: FlowStep[];
  isRepeatable: boolean;
};
export type FlowEntity = "project" | "workspace" | "user";
export type FlowStepData = z.infer<typeof flowStepDataSchema>;
export type FlowStepDataValueOf<TargetType extends FlowStepData["type"]> =
  Extract<FlowStepData, { type: TargetType }>["data"];
export type FlowStepConfig = z.infer<typeof flowStepConfigSchema>;
export type FlowStepConfigPropsValueOf<
  TargetType extends FlowStepConfig["type"],
> = Extract<FlowStepConfig, { type: TargetType }>["props"];
export type FlowInstanceStepResult = {
  completedAt: string;
  skipped: boolean;
} & FlowStepData;
export type FlowStepResultsDataType = z.infer<typeof flowStepResultsSchema>;
export type FlowStepOptionSchema = {
  value: string;
  label: string;
  hasInput: boolean;
  extraData?: Record<string, unknown>;
};
export type FlowSlugs = "onboarding" | "editor-tour";

import * as React from "react";
import type { MediaSize } from "replo-runtime/shared/utils/breakpoints";
import {
  mediaQueries,
  mediaSizes,
} from "replo-runtime/shared/utils/breakpoints";

import {
  GlobalWindowContext,
  useRuntimeContext,
} from "../../shared/runtime-context";

/**
 * This function returns the current real mediaSize and auto updates it when
 * resize happens.
 */
export function useCurrentMediaSize() {
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const [mediaSize, setMediaSize] = React.useState<MediaSize>();
  React.useEffect(() => {
    if (!globalWindow) {
      return;
    }
    const cleanupFunctions: Array<() => void> = [];
    let initialMatchFound = false;
    for (const size of mediaSizes) {
      const queryString = mediaQueries[size];
      const queryList = globalWindow.matchMedia(queryString);

      // Note (Evan, 2024-07-30): Apparently in some cases, matchMedia
      // can return null. If this happens, just skip this mediaSize, since
      // we can't add an event listener.
      if (!queryList) {
        continue;
      }

      const handleChange = (event: MediaQueryListEvent) => {
        if (event.matches) {
          setMediaSize(size);
        }
      };
      queryList.addEventListener("change", handleChange);
      cleanupFunctions.push(() =>
        queryList.removeEventListener("change", handleChange),
      );

      if (!initialMatchFound && queryList.matches) {
        setMediaSize(size);
        initialMatchFound = true;
      }
    }

    return () => {
      for (const cleanup of cleanupFunctions) {
        cleanup();
      }
    };
  }, [globalWindow]);
  return mediaSize;
}

import Button from "@common/designSystem/Button";
import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import {
  ErrorMessage as GlobalErrorMessage,
  ScreenData,
} from "@components/account/common";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import { storeToken } from "@editor/reducers/utils/store-token";
import { trpc } from "@editor/utils/trpc";
import * as React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";

type FormValues = {
  password: string;
  userId: string;
  token: string;
};

const ResetPassord: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { mutate: resetPasswordApi, isPending: isLoadingResetPass } =
    trpc.user.resetPassword.useMutation({
      onSuccess: ({ token }) => {
        storeToken(token);
        window.location.replace("/");
      },
      onError: () =>
        setResetPasswordErrorMessage(
          "An unknown error has occurred. Please reach out to support@replo.app for help.",
        ),
    });
  const [resetPasswordErrorMessage, setResetPasswordErrorMessage] =
    React.useState<string | null>("");
  const location = useLocation();

  const renderData = ScreenData[location.pathname ?? ""];
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: {
      password: "",
      /* For super-duper security, each link also contains the userId */
      userId: searchParams.get("userId") ?? "",
      token: searchParams.get("token") ?? "",
    },
    mode: "onBlur",
  });

  const onSubmit = async ({ userId, password, token }: FormValues) => {
    resetPasswordApi({
      userId,
      password,
      token,
    });
  };

  const watchAllFields = useWatch({ control });
  const passwordError = !isEmpty(watchAllFields.password)
    ? errors?.password?.message
    : undefined;

  return (
    <form
      onSubmit={(data) => {
        void handleSubmit(onSubmit)(data);
      }}
    >
      <GlobalErrorMessage errorMessage={resetPasswordErrorMessage} />
      <LabeledControl
        label="Password"
        className="text-default font-medium"
        size="sm"
        id="password"
      >
        <Input
          id="password"
          aria-invalid={passwordError ? "true" : undefined}
          aria-describedby={passwordError ? "error-password" : undefined}
          validityState={passwordError ? "invalid" : undefined}
          autoComplete="off"
          placeholder="••••••••••••"
          {...register("password", {
            required: "Please enter a valid Password.",
            minLength: {
              value: 8,
              message: "Password should be at least 8 characters long.",
            },
            // NOTE (Fran 2024-02-27): We should clear the errors when the user is typing
            onChange: () => clearErrors("password"),
          })}
          type="password"
          size="base"
        />
        <ErrorMessage id="error-password" error={passwordError} />
      </LabeledControl>
      <Button
        className="mt-10"
        type="primary"
        size="lg"
        htmlType="submit"
        isLoading={isLoadingResetPass}
        isFullWidth={true}
      >
        {renderData?.header}
      </Button>
    </form>
  );
};

export default ResetPassord;

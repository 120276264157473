import * as React from "react";
import type { FocusableElement } from "replo-utils/dom/dom-types";
import { useComposedRefs } from "replo-utils/react/use-composed-refs";

import { mergeProps } from "../../utils/props";
import { Tooltip } from "./tooltip";

/**
 * Higher-order component that adds a tooltip to a component with a
 * `tooltipText` prop. Should generally only be used within the design system
 * package.
 * @internal
 */
export function withTooltip<TriggerElement extends FocusableElement, Props>(
  Component: React.ForwardRefExoticComponent<
    Props & React.RefAttributes<TriggerElement>
  >,
  displayName?: string,
) {
  displayName ??= Component.displayName || Component.name;
  const TooltipTrigger = React.forwardRef<TriggerElement, any>(
    (
      { tooltipText, tooltipCustomContent, triggerRef, ...props },
      forwardedRef,
    ) => {
      const ref = useComposedRefs(triggerRef, forwardedRef);
      return <Component {...props} ref={ref} />;
    },
  );
  TooltipTrigger.displayName = `${displayName}TooltipTrigger`;

  const WithTooltip = React.forwardRef<
    TriggerElement,
    Props & { tooltipText?: string; tooltipCustomContent?: React.ReactNode }
  >(({ tooltipText, tooltipCustomContent, ...props }, ref) => {
    if (tooltipText || tooltipCustomContent) {
      return (
        <Tooltip
          content={tooltipCustomContent ?? tooltipText}
          trigger={({ triggerProps, triggerRef }) => {
            return (
              <TooltipTrigger
                {...(mergeProps(triggerProps, props) as any)}
                ref={ref}
                triggerRef={triggerRef}
              />
            );
          }}
        />
      );
    }
    return <Component {...(props as any)} ref={ref} />;
  });
  WithTooltip.displayName = `${displayName}WithTooltip`;
  return WithTooltip;
}

import classNames from "classnames";
import * as React from "react";
import { Resizable } from "react-resizable";
import { useControllableState } from "replo-utils/react/use-controllable-state";
import { twMerge } from "tailwind-merge";

type ResizablePaneProps = {
  minSize: number;
  maxSize: number;
  defaultSize?: number;
  size?: number;
  onResize?: (size: number) => void;
  className?: string;
  contentClassName?: string;
  handleClassName?: string;
  isVertical?: boolean;
  isDisabled?: boolean;
};

/**
 * Component in charge of rendering panes that can be resized.
 * It works in both directions.
 */
const ResizablePane: React.FC<React.PropsWithChildren<ResizablePaneProps>> = ({
  minSize,
  maxSize,
  defaultSize,
  size: controlledSize,
  onResize: onControlledResize,
  className,
  contentClassName,
  handleClassName,
  children,
  isVertical = false,
  isDisabled,
}) => {
  const [size, setSize] = useControllableState(
    controlledSize,
    defaultSize || minSize,
    onControlledResize,
  );

  return (
    <Resizable
      width={!isVertical ? size! : 0}
      height={isVertical ? size! : 0}
      minConstraints={[!isVertical ? minSize! : 0, isVertical ? minSize! : 0]}
      maxConstraints={[!isVertical ? maxSize! : 0, isVertical ? maxSize! : 0]}
      handle={
        !isDisabled ? (
          <div
            className={classNames(
              "absolute bottom-0 right-0",
              isVertical
                ? "left-0 w-full cursor-ns-resize"
                : "top-0 h-full cursor-ew-resize",
              twMerge(isVertical ? "-mb-1 py-1" : "-mr-1 px-1"),
            )}
          >
            <div className={classNames("w-full", "h-full", handleClassName)} />
          </div>
        ) : null
      }
      onResize={(_e, { size }) => {
        const newSize = isVertical ? size.height : size.width;
        setSize(newSize);
      }}
    >
      <div
        className={classNames("relative", className)}
        style={{
          width: !isVertical ? size : undefined,
          height: isVertical ? size : undefined,
        }}
      >
        <div
          className={classNames(
            "flex",
            isVertical ? "h-full flex-row" : "w-full flex-col",
            contentClassName,
          )}
        >
          {children}
        </div>
      </div>
    </Resizable>
  );
};

export default ResizablePane;

import { initializePosthogIfNeeded } from "@infra/analytics";
import posthog from "posthog-js";
import { isUnitTest } from "replo-runtime/shared/env";
import type { FeatureName } from "replo-utils/lib/featureFlags";
import { featureFlags } from "replo-utils/lib/featureFlags";
import { isNotNullish } from "replo-utils/lib/misc";

export function isFeatureEnabled(featureName: FeatureName) {
  initializePosthogIfNeeded();
  // Note (Sebas, 2023-04-05): In case we are in a unit test, we don't want to
  // use any feature flags, so we return false.
  if (isUnitTest()) {
    return false;
  }

  const featureFlagOverrides:
    | { [key in FeatureName]?: boolean }
    // @ts-ignore
    | undefined = window.E2E_FEATURE_FLAG_OVERRIDES;
  if (featureFlagOverrides) {
    const override = featureFlagOverrides[featureName];
    if (isNotNullish(override)) {
      return override;
    }
  }

  return Boolean(posthog?.isFeatureEnabled(featureName, { send_event: false }));
}

export function getPosthogExperimentPayloadFromPartialFeatureFlag(
  partialFeatureFlag: string,
) {
  if (isUnitTest()) {
    return null;
  }
  initializePosthogIfNeeded();

  const featureFlag = posthog?.featureFlags
    .getFlags()
    .filter((flag) => flag.includes(partialFeatureFlag))
    .find((flag) => posthog.isFeatureEnabled(flag));

  if (featureFlag) {
    return posthog?.getFeatureFlagPayload(featureFlag) as Record<
      string,
      string
    >;
  }

  return null;
}

export function enableFeatureFlag(featureName: FeatureName) {
  initializePosthogIfNeeded();
  return posthog.featureFlags.override({
    ...Object.fromEntries(featureFlags.map((f) => [f, isFeatureEnabled(f)])),
    [featureName]: true,
  });
}

export function disableFeatureFlag(featureName: FeatureName) {
  initializePosthogIfNeeded();
  return posthog.featureFlags.override({
    ...Object.fromEntries(featureFlags.map((f) => [f, isFeatureEnabled(f)])),
    [featureName]: false,
  });
}

export function clearFeatureFlagOverrides() {
  initializePosthogIfNeeded();
  return posthog.featureFlags.override(false);
}

export function getAnnouncementFeatureFlags() {
  initializePosthogIfNeeded();
  return (
    posthog?.featureFlags
      .getFlags()
      .filter(
        (flag) =>
          flag.startsWith("announcement_") && posthog.isFeatureEnabled(flag),
      ) ?? []
  );
}

// TODO (Fran 2024-09-17): Remove after the Library Project is done
export function isNewLeftBarUIEnable() {
  return isFeatureEnabled("left-panel-ui");
}

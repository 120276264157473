import * as React from "react";
import { Svg, type SvgProps } from "replo-utils/react/components";

export const SvgComponenticonModal = (props: SvgProps) => (
  <Svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <rect width={6} height={4} x={5} y={5} fill="currentColor" rx={1} />
    <rect
      width={11}
      height={11}
      x={2.5}
      y={2.5}
      stroke="currentColor"
      rx={0.5}
    />
  </Svg>
);
export default SvgComponenticonModal;

import * as React from "react";
import type { Flow, FlowInstance, FlowStep, FlowStepData } from "schemas/flow";

type ReploFlowsStepContextValue = {
  currentStep: FlowStep | null;
  submitStep(
    stepId: FlowStep["id"],
    stepType: FlowStep["type"],
    stepData: FlowStepData["data"],
    callback?: ({
      nextStep,
      instance,
    }: {
      nextStep?: string | null;
      instance: FlowInstance;
    }) => void,
  ): void;
  skipStep(
    stepId: FlowStep["id"],
    stepType: FlowStep["type"],
    callback?: ({
      nextStep,
      instance,
    }: {
      nextStep?: string | null;
      instance: FlowInstance;
    }) => void,
  ): void;
  goBack(callback?: (currentFlow: Flow, previousStepId: string) => void): void;
  isSubmitting: boolean;
};

export const ReploFlowsStepContext =
  React.createContext<ReploFlowsStepContextValue>({
    currentStep: null,
    submitStep: () => null,
    skipStep: () => null,
    goBack: () => null,
    isSubmitting: false,
  });

export function useReploFlowsStepContext() {
  return React.useContext(ReploFlowsStepContext);
}

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const reviewsIoProductRating: ComponentTemplate = {
  id: "3859d8d1-9443-4341-a95c-bd2ddd5daea8",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Reviews.io Product Rating",
  leftBarCategory: { main: "productIntegrations", subCategory: "reviewsIo" },
  preview: "integrations/reviewsio-product-rating.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  tags: ["reviews", "star"],
  template: {
    id: "c5f836c9-d8ad-495a-b530-859918bbe01e",
    type: "reviewsIoProductRating",
    props: {},
  },
};

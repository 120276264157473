import type { Nullish } from "replo-utils/lib/types";
import type { ConditionField, ReploVariant } from "schemas/symbol";
import { v4 as uuidv4 } from "uuid";

import type { PredefinedVariant, PredefinedVariantType } from "./types";

export function findDefault(variants: ReploVariant[]) {
  return variants.find(isDefaultVariant)!;
}

export function isDefaultVariant(
  variant: ReploVariant,
): variant is ReploVariant & { name: "default" } {
  return variant.name === "default";
}

export function findVariant(variants: ReploVariant[], id: string) {
  return variants.find((variant) => variant.id === id) ?? null;
}

export function findVariantByConditionField(
  variants: ReploVariant[],
  conditionField: string,
) {
  return variants.find((variant) =>
    variant.query?.statements.some(
      (statement) => statement.field === conditionField,
    ),
  );
}

export const isHoverVariant = (variant: ReploVariant) => {
  return variant.query?.statements.find(
    (item) => item.field === "interaction.hover",
  );
};

export const isHashmarkVariant = (variant: ReploVariant) => {
  return variant.query?.statements.find(
    (item) => item.field === "page.hashmark",
  );
};

/**
 * Augments variants with information about their state
 */
export function getVariantsWithState(
  variants: ReploVariant[],
  activeVariantId: string | Nullish,
) {
  const defaultVariantId = findDefault(variants)?.id;

  return variants.map((variant) => {
    return {
      ...variant,
      isDefault: variant.id === defaultVariantId,
      isActive: activeVariantId
        ? variant.id === activeVariantId
        : variant.id === defaultVariantId,
    };
  });
}

export function hasVariantWithConditionField(
  variants: ReploVariant[],
  conditionField: ConditionField,
) {
  return variants.some((variant) =>
    (variant.query?.statements ?? []).some(
      (statement) => statement.field === conditionField,
    ),
  );
}

const predefinedVariantTypeToData: Record<
  PredefinedVariantType,
  { name: string; field: ConditionField }
> = {
  hover: {
    name: "Hover",
    field: "interaction.hover",
  },
  loading: {
    name: "Loading",
    field: "state.action.loading",
  },
  outOfStock: {
    name: "Out of Stock",
    field: "state.product.selectedVariantUnavailable",
  },
  activeTab: {
    name: "Active Tab",
    field: "state.tabsV2Block.isCurrentTab",
  },
  selectedOption: {
    name: "Selected Option",
    field: "state.product.selectedOptionValues",
  },
  selectedVariant: {
    name: "Selected Variant",
    field: "state.product.selectedVariant",
  },
  selectedSellingPlan: {
    name: "Selected Selling Plan",
    field: "state.product.selectedSellingPlan",
  },
  collapsibleOpen: {
    name: "Collapsible Open",
    field: "state.collapsibleV2.isOpen",
  },
  beforeAfterDragging: {
    name: "Slider is Dragging",
    field: "state.beforeAfterSlider.isDragging",
  },
  tooltipOpen: {
    name: "Tooltip Open",
    field: "state.tooltip.isOpen",
  },
  selectedListItem: {
    name: "Selected List Item",
    field: "state.selectionList.isItemSelected",
  },
};

export const buildPredefinedVariant = (
  predefinedVariantType: PredefinedVariantType,
): PredefinedVariant => {
  const data = predefinedVariantTypeToData[predefinedVariantType];
  return {
    name: data.name,
    query: {
      type: "expression",
      operator: "or",
      statements: [
        {
          id: uuidv4(),
          field: data.field,
        },
      ],
    },
    predefinedVariantType,
  };
};

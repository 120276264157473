import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const horizontalRule: ComponentTemplate = {
  id: "horizontalRule",
  storeId: null,
  scope: "left-bar",
  type: "component",
  env: Env.PRODUCTION,
  name: "Basic/Horizontal Rule",
  leftBarCategory: { main: "basic", subCategory: "shapes" },
  preview: "basic/horizontal-rule.svg",
  tags: ["divider", "spacer"],
  template: {
    id: "b2f78196-ed14-429d-94bd-3841c2d1b4af",
    type: "spacer",
    props: {
      style: {
        width: "90%",
        height: "1px",
        marginTop: "20px",
        marginLeft: "5%",
        marginRight: "5%",
        paddingBottom: "20px",
        borderTopWidth: "2px",
        borderTopColor: "#CCCCCCFF",
        borderTopStyle: "solid",
      },
    },
    name: "Horizontal Rule",
  },
};

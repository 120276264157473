import type { SelectableOption } from "@editor/components/common/designSystem/Selectable";
import Selectable from "@editor/components/common/designSystem/Selectable";
import * as React from "react";
import { BsCalendar3 } from "react-icons/bs";

const TimeFrame: React.FC = () => {
  const timeFrameSelectableOptions: SelectableOption[] = [
    {
      value: "All time",
      label: "All time",
    },
    {
      value: "Last 24 hours",
      label: "Last 24 hours",
    },
    {
      value: "Last 7 days",
      label: "Last 7 days",
    },
    {
      value: "Last 30 days",
      label: "Last 30 days",
    },
    {
      value: "Last 60 days",
      label: "Last 60 days",
    },
    {
      value: "Last 90 days",
      label: "Last 90 days",
    },
    {
      value: "Custom range",
      label: "Custom range",
    },
  ];

  return (
    <Selectable
      options={timeFrameSelectableOptions}
      defaultValue={timeFrameSelectableOptions[0]?.label as string}
      onSelect={(value) => value}
      startEnhancer={() => (
        <div className="flex justify-center items-center">
          <BsCalendar3 className="h-4 w-4" />
        </div>
      )}
      labelClassName="mr-1"
      className="h-[32px] w-auto"
    />
  );
};

export default TimeFrame;

import Button from "@common/designSystem/Button";
import Modal from "@common/designSystem/Modal";
import Selectable from "@common/designSystem/Selectable";
import { successToast } from "@common/designSystem/Toast";
import { ModalLayout } from "@common/ModalLayout";
import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import PillTextArea from "@editor/components/common/PillTextArea";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import * as React from "react";
import type { WorkspaceRole } from "schemas/workspace";
import * as Yup from "yup";

import type { InviteTeamMembersModalProps } from "../AppModalTypes";

const RoleWithDescription: React.FC<{
  title: string;
  description: string;
}> = ({ title, description }) => {
  return (
    <div className="p-2 flex flex-col max-w-[200px] text-default hover:text-white">
      <p className="font-bold">{title}</p>
      <p>{description}</p>
    </div>
  );
};

const getRoleOptions = (isWorkspaceOwner: boolean) => [
  {
    simpleSelectedLabel: "Member",
    label: (
      <RoleWithDescription
        title="Member"
        description="Can create, edit, and share projects."
      />
    ),
    value: "member",
  },
  ...(isWorkspaceOwner
    ? [
        {
          simpleSelectedLabel: "Owner",
          label: (
            <RoleWithDescription
              title="Owner"
              description="Can fully configure and edit workspaces (billing, projects, permissions)."
            />
          ),
          value: "owner",
        },
      ]
    : []),
];

export const InviteTeamMembersModal: React.FC<InviteTeamMembersModalProps> = ({
  workspaceId,
  email,
}) => {
  const logEvent = useLogAnalytics();
  const [inviteAs, setInviteAs] = React.useState<WorkspaceRole>("member");
  const [emails, setEmails] = React.useState<string[]>(email ? [email] : []);
  const [inputError, setInputError] = React.useState<string | null>(null);
  const { closeModal } = useModal();

  const { data: users } =
    trpc.workspace.getWorkspaceAndProjectMembers.useQuery(workspaceId);

  const currentMembersList =
    users
      ?.filter((user) => user.workspaceMemberships.length > 0)
      .map((user) => user.email) ?? [];

  const {
    mutate: inviteWorkspaceMembers,
    isPending: isWorkspaceMembershipsLoading,
  } = trpc.workspace.inviteMembers.useMutation({
    onSuccess: () => {
      void trpcUtils.workspace.getWorkspaceAndProjectMembers.invalidate(
        workspaceId,
      );
      successToast("Invites sent", "The invites were sent successfully.");
      logEvent("workspace.members.add", {
        numberOfInvites: emails.length,
        emails,
        permissionLevel: inviteAs,
        workspaceId,
      });
      closeModal({ type: "inviteTeamMembersModal" });
    },
  });

  const isWorkspaceOwner = useIsWorkspaceOwner(workspaceId);

  const roleOptions = getRoleOptions(isWorkspaceOwner);

  const handleSendInvites = () => {
    setInputError(null);
    const invalidEmails = emails.filter((email) =>
      currentMembersList.includes(email),
    );
    if (invalidEmails.length > 0) {
      setInputError(
        `The following email${invalidEmails.length === 1 ? " is" : "s are"} already ${invalidEmails.length === 1 ? "a member" : "members"} of this workspace: ${invalidEmails.join(
          ", ",
        )}`,
      );
      return;
    }

    if (emails.length === 0) {
      setInputError("Please enter at least one email address");
      return;
    }
    void inviteWorkspaceMembers({
      workspaceId: workspaceId ?? "",
      usersToGrantAccess: emails.map((email) => ({
        email,
        role: inviteAs,
      })),
    });
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        closeModal({ type: "inviteTeamMembersModal" });
      }}
      className="w-auto"
      testId="announcements-modal"
    >
      <ModalLayout
        height="auto"
        width="25vw"
        minWidth={400}
        layoutClassnames="p-0 gap-2 ml-2"
        mainContent={() => (
          <div className="flex flex-col gap-5 w-full">
            <div className="font-semibold text-default">
              Invite Team Members
            </div>
            <div className="flex justify-between items-center">
              <div className="text-sm text-default">Invite members as</div>
              <Selectable
                options={roleOptions}
                value={inviteAs}
                className="w-32 h-9"
                isDisabled={roleOptions.length === 1}
                dropdownAlign="end"
                disableDropdownFixedWidth={true}
                onSelect={(value) =>
                  setInviteAs(
                    (value ?? "member") as "owner" | "viewer" | "member",
                  )
                }
              />
            </div>
            <PillTextArea
              pills={emails}
              setPills={setEmails}
              inputValidator={Yup.string()
                .email("Invalid email address")
                .required("Email is required")}
            />
            <ErrorMessage error={inputError} className="mb-0" />
            <Button
              type="primary"
              size="base"
              className="self-end"
              textClassNames="text-sm font-normal"
              onClick={handleSendInvites}
              isLoading={isWorkspaceMembershipsLoading}
              isDisabled={isWorkspaceMembershipsLoading}
            >
              Send Invites
            </Button>
          </div>
        )}
        mainContentClassnames="mb-0 max-h-[82vh]"
      />
    </Modal>
  );
};

import { elementTypeToEditorData } from "@components/editor/element";
import { useElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import type {
  PageLayoutType,
  ReploEditableElement,
} from "@editor/utils/element";
import classNames from "classnames";
import * as React from "react";
import { twMerge } from "tailwind-merge";

import { ElementEditorHeading } from "./ElementEditorHeading";
import { ElementEditorLayoutButton } from "./ElementEditorLayoutButton";
import { ElementSettingsSwitch } from "./ElementSettingsSwitch";

/**
 * Helper function to get page current layout.
 */
export default function getCurrentLayout({
  hideDefaultHeader,
  hideDefaultFooter,
}: ReploEditableElement): PageLayoutType {
  if (hideDefaultHeader && hideDefaultFooter) {
    return "no-header-footer";
  }
  if (hideDefaultFooter && !hideDefaultHeader) {
    return "no-footer";
  }
  if (!hideDefaultFooter && hideDefaultHeader) {
    return "no-header";
  }
  return "default";
}

export const ShopifyThemeSettings: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { element, onChangeElement } = useElementEditorDataContext();
  const layout = getCurrentLayout(element);

  return (
    <div
      className={classNames(twMerge("mt-4 flex flex-col gap-y-2", className))}
    >
      <ElementEditorHeading className="text-base">
        Shopify Theme Settings
      </ElementEditorHeading>
      <ElementSettingsSwitch
        title="Hide Announcement Bar"
        description={`If set, your Shopify theme's announcement bar will be hidden on this ${elementTypeToEditorData[element.type].singularDisplayName.toLowerCase()}.`}
        isOn={element.hideShopifyAnnouncementBar}
        onChange={(hideShopifyAnnouncementBar) =>
          onChangeElement({ ...element, hideShopifyAnnouncementBar })
        }
      />
      {element.type === "shopifyProductTemplate" && (
        <ElementSettingsSwitch
          title="Include Product Schema"
          description="If set, structured product data will be added to this template to enhance search results."
          isOn={element.includeProductSchema}
          onChange={(includeProductSchema) =>
            onChangeElement({ ...element, includeProductSchema })
          }
        />
      )}
      <ul className="mt-2 grid grid-cols-4 gap-6 text-slate-400">
        <li>
          <ElementEditorLayoutButton
            label="Hide Header/Footer"
            isActive={layout === "no-header-footer"}
            onClick={() =>
              onChangeElement({
                ...element,
                hideDefaultHeader: true,
                hideDefaultFooter: true,
              })
            }
          />
        </li>
        <li>
          <ElementEditorLayoutButton
            label="Show Only Header"
            isActive={layout === "no-footer"}
            onClick={() =>
              onChangeElement({
                ...element,
                hideDefaultHeader: false,
                hideDefaultFooter: true,
              })
            }
          >
            <span className="h-[15%] w-full rounded bg-slate-200" />
          </ElementEditorLayoutButton>
        </li>
        <li>
          <ElementEditorLayoutButton
            label="Show Only Footer"
            isActive={layout === "no-header"}
            onClick={() =>
              onChangeElement({
                ...element,
                hideDefaultHeader: true,
                hideDefaultFooter: false,
              })
            }
            className="flex-col-reverse"
          >
            <span className="h-[15%] w-full rounded bg-slate-200" />
          </ElementEditorLayoutButton>
        </li>
        <li>
          <ElementEditorLayoutButton
            label="With Header/Footer"
            isActive={layout === "default"}
            onClick={() =>
              onChangeElement({
                ...element,
                hideDefaultHeader: false,
                hideDefaultFooter: false,
              })
            }
          >
            <span className="h-[15%] w-full rounded bg-slate-200" />
            <span className="h-[15%] w-full rounded bg-slate-200" />
          </ElementEditorLayoutButton>
        </li>
      </ul>
    </div>
  );
};

import Tooltip from "@common/designSystem/Tooltip";
import classNames from "classnames";
import * as React from "react";
import { twMerge } from "tailwind-merge";

type ButtonProps = {
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
  id?: string;
  className?: string;
  isDisabled?: boolean;
  tooltipContent?: React.ReactNode;
  icon?: React.ReactNode;
  children: React.ReactNode;
};

const LabeledIconButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function LabeledIconButton(
    {
      icon,
      onClick,
      className,
      isDisabled,
      tooltipContent,
      children,
      id,
      ...props
    },
    ref,
  ): JSX.Element {
    const defaultClassNames = twMerge(
      classNames(
        "w-12 flex flex-col items-center text-default justify-between capitalize disabled:pointer-events-none disabled:text-slate-400",
        { "cursor-pointer": !isDisabled },
        className,
      ),
    );

    if (tooltipContent) {
      return (
        <Tooltip content={tooltipContent} triggerAsChild>
          <button
            id={id}
            ref={ref}
            type="button"
            className={defaultClassNames}
            onClick={onClick}
            disabled={isDisabled}
            {...props}
          >
            {icon && (
              <span className="flex max-h-4 flex-1 items-center text-base">
                {icon}
              </span>
            )}
            <span className="pt-2 text-xs">{children}</span>
          </button>
        </Tooltip>
      );
    }

    return (
      <button
        id={id}
        ref={ref}
        type="button"
        className={defaultClassNames}
        onClick={onClick}
        disabled={isDisabled}
        {...props}
      >
        {icon && (
          <span className="flex max-h-4 flex-1 items-center text-base">
            {icon}
          </span>
        )}
        <span className="pt-2 text-xs">{children}</span>
      </button>
    );
  },
);

export default LabeledIconButton;

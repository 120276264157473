import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { prependProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedLiquidReviewIntegrations } from "../SharedLiquidReviewIntegrations";

function StampedProductReviewsWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const product = useProductFromProps(component.props, context);
  const liquidSource = `<div
      id="stamped-main-widget"
      data-product-id="{{ product.id }}"
      data-name="{{ product.title | escape }}"
      data-url="{{ shop.url }}{{ product.url }}"
      data-image-url="{{ product.featured_image | product_img_url: "large" |replace: '?', '%3F' | replace: '&','%26'}}"
      data-description="{{ product.description | escape }}"
      data-product-sku="{{product.handle}}"
      data-product-title="{{product.title}}"
      data-product-type="{{product.type}}">
      {{product.metafields.stamped.reviews}}
      </div>`;
  const liquid = prependProductAssignmentToLiquidSource({
    product,
    liquidSource,
    context: props.context,
  });

  return (
    <SharedLiquidReviewIntegrations
      attributes={componentAttributes}
      component={component}
      liquidSource={liquid}
      placeholder="Stamped Product Reviews Widget will appear here"
      shouldHavePlaceHolder={isEditorApp || !product}
      appName="Stamped"
      context={context}
    />
  );
}

export default StampedProductReviewsWidget;

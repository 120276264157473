import Selectable from "@editor/components/common/designSystem/Selectable";
import ToggleGroup from "@editor/components/common/designSystem/ToggleGroup";
import startCase from "lodash-es/startCase";
import * as React from "react";
import type {
  AlchemyAnimationTriggerType,
  AlchemyAnimationType,
} from "replo-runtime/shared/enums";
import type { Animation, AnimationField } from "replo-runtime/shared/types";

export const getAnimationName = (
  trigger: AlchemyAnimationTriggerType,
  type: AlchemyAnimationType,
) => {
  return `${startCase(trigger)} → ${startCase(type)}`;
};

type AnimationFieldProps = {
  animation: Partial<Animation>;
  field: AnimationField;
  onChange: (value: any) => void;
};

const animationFieldMap: { [key: string]: any } = {
  select: ({ animation, field, onChange }: AnimationFieldProps) => {
    return (
      <Selectable
        key={field.name}
        value={animation.value?.[field.id]}
        options={field.options}
        placeholder={field.placeholder}
        onSelect={onChange}
      />
    );
  },
  toggle: ({ animation, field, onChange }: AnimationFieldProps) => {
    return (
      <ToggleGroup
        allowsDeselect={false}
        key={field.name}
        type="single"
        value={animation.value?.[field.id]}
        options={field.options}
        onChange={onChange}
        style={{ width: "100%" }}
      />
    );
  },
};

export const getAnimationField = (props: AnimationFieldProps) => {
  return animationFieldMap[props.field.type](props);
};

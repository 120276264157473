import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const StaySubscriptionWidget: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
}) => {
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const liquid = `
    {% render 'rtx-pdp', product: product %}
  `;

  return (
    <div {...componentAttributes}>
      <SharedShopifyLiquid
        forceEditorPlaceholder={isEditorApp}
        liquidSource={liquid}
        componentId={component.id}
        componentAttributes={componentAttributes}
        placeholder="The Stay Ai subscription widget will appear here after publishing. Please ensure you have Stay Ai installed."
        repeatedIndexPath={context.repeatedIndexPath}
        isLiquidSupported
      />
    </div>
  );
};

export default StaySubscriptionWidget;

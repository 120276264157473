import type {
  ObjectRepeatType,
  ObjectSizeType,
} from "@editor/components/common/designSystem/BackgroundAssetPicker";
import BackgroundAssetPicker from "@editor/components/common/designSystem/BackgroundAssetPicker";
import InlineAssetSelector from "@editor/components/common/designSystem/InlineAssetSelector";
import Popover from "@editor/components/common/designSystem/Popover";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useModal } from "@editor/hooks/useModal";
import {
  selectBackgroundColor,
  selectBackgroundGradientStops,
  selectBackgroundGradientTilt,
  selectBackgroundImage,
  selectBackgroundPositionX,
  selectBackgroundPositionY,
  selectBackgroundRepeat,
  selectBackgroundSize,
  selectDraftComponentContext,
  selectDraftComponentId,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import ModifiersInfo from "@editorComponents/ModifiersInfo";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";
import ModifierGroup from "@editorExtras/ModifierGroup";
import DynamicColorModifier from "@editorModifiers/DynamicColorModifier";
import { hasDynamicData } from "@editorModifiers/utils";
import * as React from "react";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import type { GradientStop, SolidOrGradient } from "replo-runtime/shared/types";
import { evaluateVariableAsString } from "replo-runtime/store/AlchemyVariable";

export const BackgroundModifier: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { src } = useBackgroundImage();
  const backgroundColor = useEditorSelector(selectBackgroundColor);

  return (
    <ModifierGroup
      title="Background"
      titleEnhancer={<ModifiersInfo documentationType="background" />}
      isDefaultOpen={Boolean(src) || Boolean(backgroundColor)}
    >
      <BackgroundImageControl />
      <BackgroundColorControl />
    </ModifierGroup>
  );
};

const BackgroundImageControl: React.FC = () => {
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const backgroundSize = useEditorSelector(
    selectBackgroundSize,
  ) as ObjectSizeType;
  const backgroundRepeat = useEditorSelector(
    selectBackgroundRepeat,
  ) as ObjectRepeatType;
  const backgroundPositionX = useEditorSelector(selectBackgroundPositionX);
  const backgroundPositionY = useEditorSelector(selectBackgroundPositionY);
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const applyComponentAction = useApplyComponentAction();
  const modal = useModal();
  const { src, assetSrc, removeImageSrc } = useBackgroundImage();

  const [isOpen, setIsOpen] = React.useState(false);

  const onClickDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.URL,
        referrerData: {
          type: "style",
          styleAttribute: "backgroundImage",
        },
        initialPath: getPathFromVariable(src!),
      },
    });
  };

  const openModal = () => {
    modal.openModal({
      type: "assetLibraryModal",
      props: {
        referrer: "modifier/background",
        value: assetSrc!,
        assetContentType: "image",
      },
    });
  };

  const openPopover = () => {
    setIsOpen(true);
  };

  return (
    <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
      {typeof src === "string" && src?.includes("{{") ? (
        <DynamicDataValueIndicator
          type="color"
          templateValue={src}
          onClick={openPopover}
          onRemove={removeImageSrc}
          componentId={draftComponentId ?? undefined}
        />
      ) : (
        <InlineAssetSelector
          swatchTooltip="Background Image"
          emptyTitle="Choose Image"
          onClickSelectAsset={openPopover}
          onRemoveAsset={removeImageSrc}
          allowRemoveAsset
          allowsDynamicData={hasDynamicData(draftComponentId)}
          onClickDynamicData={onClickDynamicData}
          asset={{
            type: "image",
            src: assetSrc!,
          }}
          onInputChange={(url) =>
            applyComponentAction({
              type: "setStyles",
              value: {
                // Note (Sebas, 2022-10-12): We need to use double quotes to prevent
                // data:image sources which may contain simple quotes from not working.
                backgroundImage: `url("${url}")`,
                backgroundSize: "cover",
              },
            })
          }
        />
      )}
      <Popover.Anchor className="relative top-0 left-0" />
      {isOpen && (
        <Popover.Content
          title="Background Image"
          shouldPreventDefaultOnInteractOutside={areModalsOpen}
        >
          <BackgroundAssetPicker
            url={assetSrc ?? undefined}
            emptyTitle="No Background Selected"
            selectAssetTitle="Select Background"
            changeAssetTitle="Change Background"
            onClickSelectAsset={openModal}
            onClickDynamicDataForUrl={onClickDynamicData}
            backgroundSizeValue={backgroundSize}
            onChangeBackgroundSize={(value: any) => {
              applyComponentAction({
                type: "setStyles",
                value: { backgroundSize: value },
              });
            }}
            backgroundPositionValue={{
              x: backgroundPositionX ? String(backgroundPositionX) : undefined,
              y: backgroundPositionY ? String(backgroundPositionY) : undefined,
            }}
            onChangeBackgroundPositionX={(value: any) => {
              applyComponentAction({
                type: "setStyles",
                value: { backgroundPositionX: value },
              });
            }}
            onChangeBackgroundPositionY={(value: any) => {
              applyComponentAction({
                type: "setStyles",
                value: { backgroundPositionY: value },
              });
            }}
            backgroundRepeatValue={backgroundRepeat ?? "repeat"}
            onChangeBackgroundRepeat={(value: any) => {
              applyComponentAction({
                type: "setStyles",
                value: { backgroundRepeat: value },
              });
            }}
            allowsSettingDynamicData
          />
        </Popover.Content>
      )}
    </Popover>
  );
};

const BackgroundColorControl: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const backgroundColor = useEditorSelector(selectBackgroundColor);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const backgroundGradientTilt = useEditorSelector(
    selectBackgroundGradientTilt,
  );
  const backgroundGradientStops = useEditorSelector(
    selectBackgroundGradientStops,
  ) as GradientStop[];

  return (
    <div className="mt-2">
      <DynamicColorModifier
        previewProperty="backgroundColor"
        gradientSelectionType="backgroundColor"
        gradientData={{
          tilt: String(backgroundGradientTilt ?? "90deg"),
          stops: backgroundGradientStops || [
            { color: "#df9393", location: "0%" },
          ],
        }}
        field="style.backgroundColor"
        value={backgroundColor ?? undefined}
        popoverTitle="Background Color"
        onChange={(value: SolidOrGradient) => {
          const solidOrGradient = value;
          if (solidOrGradient.type === "solid") {
            applyComponentAction({
              componentId: draftComponentId,
              type: "setStyles",
              value: { backgroundColor: solidOrGradient.color },
            });
          } else {
            const gradient = solidOrGradient.gradient;
            applyComponentAction({
              componentId: draftComponentId,
              type: "setStyles",
              value: {
                backgroundColor: "alchemy:gradient",
                __alchemyGradient__backgroundColor__tilt: gradient.tilt,
                __alchemyGradient__backgroundColor__stops: gradient.stops,
              },
            });
          }
        }}
      />
    </div>
  );
};

function useBackgroundImage() {
  const applyComponentAction = useApplyComponentAction();
  const draftComponentContext = useEditorSelector(selectDraftComponentContext);
  const rawBackgroundImage = useEditorSelector(selectBackgroundImage);
  const backgroundImage =
    rawBackgroundImage &&
    rawBackgroundImage?.length > 0 &&
    !rawBackgroundImage.includes("{{")
      ? rawBackgroundImage.slice(5, -2)
      : rawBackgroundImage;

  const removeImageSrc = React.useCallback(() => {
    applyComponentAction({
      type: "setStyles",
      value: { backgroundImage: "" },
    });
  }, [applyComponentAction]);

  return {
    src: backgroundImage,
    assetSrc:
      backgroundImage?.includes("{{") && draftComponentContext
        ? evaluateVariableAsString(backgroundImage, draftComponentContext)
        : backgroundImage,
    removeImageSrc,
  };
}

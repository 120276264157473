import { Menu, MenuTrigger } from "@common/designSystem/Menu";
import { headerHeight, leftBarWidth } from "@components/editor/constants";
import useAutoRefetchCanvasDocument from "@editor/hooks/useAutoRefetchCanvasDocument";
import useContextMenuItems from "@editor/hooks/useContextMenuItems";
import useCurrentDragType from "@editor/hooks/useCurrentDragType";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useRightBarVisibility from "@editor/hooks/useRightBarVisibility";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import {
  getTargetFrameWindow,
  useTargetFrameDocument,
} from "@editor/hooks/useTargetFrame";
import { trackError } from "@editor/infra/analytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectDraftComponentId,
  selectDraftElementHideDefaultFooter,
  selectDraftElementHideDefaultHeader,
  selectDraftElementHideShopifyAnnouncementBar,
  selectDraftElementId,
  selectEditorMode,
  selectIsPreviewMode,
  selectPendingElementUpdatesSize,
  selectStoreShopifyUrl,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { isDevelopment } from "@editor/utils/env";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import mapValues from "lodash-es/mapValues";
import pick from "lodash-es/pick";
import * as React from "react";
import { ErrorBoundary } from "replo-runtime/shared/ErrorBoundary";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import { useRequiredContext } from "replo-utils/react/context";

import { BoundingBoxes } from "./BoundingBoxes";
import { CANVAS_FRAME_GAP } from "./canvas-constants";
import { CanvasAreaContext, CanvasContext } from "./canvas-context";
import {
  resetFrameXPosition,
  selectActiveCanvas,
  selectActiveCanvasFrame,
  selectActiveCanvasWidth,
  selectCanvases,
  selectCanvasesKeys,
  selectCanvasHtml,
  selectCanvasInteractionMode,
  selectCanvasIsLoading,
  selectCanvasLoadingType,
  selectCanvasWillChangeStatus,
  selectLargestVisibleCanvasHeight,
  selectPaintVersion,
  selectPreviewWidth,
  selectPrimaryCanvasHeight,
  selectVisibleCanvases,
  selectVisibleCanvasesTotalWidth,
  setCanvasArea,
  setCanvasHeight,
  setCanvasInteractionMode,
  setStateFromLocalStorage,
} from "./canvas-reducer";
import type { CanvasFrameRefs, CanvasState } from "./canvas-types";
import {
  getCanvasLocalStorageState,
  setCanvasLocalStorageState,
} from "./canvas-utils";
import { CanvasHeaderBar } from "./CanvasHeaderBar";
import { CanvasResizer } from "./CanvasResizer";
import { EditorLoadingScreen } from "./EditorLoadingScreen";
import { FileDropZone } from "./FileDropZone";
import { setupJss } from "./jss";
import { useSyncRuntimeStoreWithEditorState } from "./stores/runtime";
import { useCanvasEnterLeaveMouseHandlers } from "./useCanvasEnterLeaveMouseHandlers";
import { useDragToPanCanvasMouseHandlers } from "./useDragToPanCanvasMouseHandlers";
import { useFrameOnLoad } from "./useFrameOnLoad";
import { usePaintTargetFrames } from "./usePaintTargetFrames";
import useSetActiveCanvas from "./useSetActiveCanvas";
import { useUpdateCanvasHeight } from "./useUpdateCanvasHeight";
import { useUpdateFramePositionStyles } from "./useUpdateFramePositionStyles";
import { useWheelHandler } from "./useWheelHandler";

setupJss();

export function CanvasArea() {
  const dispatch = useEditorDispatch();
  const setDraftElement = useSetDraftElement();

  const canvasAreaRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      dispatch(setCanvasArea(node));
    },
    [dispatch],
  );

  const frameRefs = React.useRef<CanvasFrameRefs>(
    new Map([
      ["mobile", { element: null, isLoaded: false }],
      ["tablet", { element: null, isLoaded: false }],
      ["desktop", { element: null, isLoaded: false }],
    ]),
  );

  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const canvasIsLoading = useEditorSelector(selectCanvasIsLoading);
  const interactionMode = useEditorSelector(selectCanvasInteractionMode);
  const editorMode = useEditorSelector(selectEditorMode);
  const canvasHtml = useEditorSelector(selectCanvasHtml);
  const activeCanvasFrame = useEditorSelector(selectActiveCanvasFrame);

  const isContentEditing = interactionMode === "content-editing";
  const { currentDragType } = useCurrentDragType();

  usePersistCanvasSettings();
  useCanvasEnterLeaveMouseHandlers();
  useWheelHandler();

  const isMultipleCanvasesEnabled = isFeatureEnabled("multiple-canvases");

  let cursor: string;
  if (interactionMode === "readyToGrab") {
    cursor = "grab";
  } else if (interactionMode === "grabbing") {
    cursor = "grabbing";
  } else {
    cursor = currentDragType ? "grabbing" : "default";
  }

  const canvasEditorStyles: React.CSSProperties = {
    left: `${leftBarWidth}px`,
    top: `${headerHeight}px`,
    cursor,
  };
  const canvasPreviewStyles: React.CSSProperties = {
    top: `${headerHeight}px`,
    height: `calc(100% - ${headerHeight}px)`,
    cursor: currentDragType ? "grabbing" : "default",
    overflow: "hidden",
  };

  return (
    <div
      ref={canvasAreaRef}
      className="canvas unselectable fixed inset-0 w-full h-full"
      data-testid="canvas-background"
      style={isPreviewMode ? canvasPreviewStyles : canvasEditorStyles}
      onMouseDown={() => {
        if (interactionMode === "readyToGrab") {
          dispatch(setCanvasInteractionMode("grabbing"));
        }
      }}
      onClick={() => {
        if (isContentEditing) {
          dispatch(setCanvasInteractionMode("edit"));
          // Note (Ovishek, 2022-06-08): blur() is used so that hotkeys work
          activeCanvasFrame?.blur?.();
        } else if (editorMode === EditorMode.edit) {
          // Reset draft component when the canvas is clicked outside of
          // the rendered components.
          setDraftElement({ componentId: null });
        }
      }}
    >
      {isMultipleCanvasesEnabled && !canvasIsLoading && !isPreviewMode && (
        <CanvasHeaderBar />
      )}
      <CanvasContextMenu>
        <CanvasAreaContext.Provider
          value={{
            canvasHtml,
            currentDragType,
            frameRefs,
          }}
        >
          {!isMultipleCanvasesEnabled && <CanvasResizer />}
          <CanvasAreaImpl />
        </CanvasAreaContext.Provider>
      </CanvasContextMenu>
    </div>
  );
}

const CanvasContextMenu: React.FC<React.PropsWithChildren> = ({ children }) => {
  const contextMenuItems = useContextMenuItems("canvasRightClickMenu");

  return (
    <Menu
      disableOutsideInteractionsWhileOpen={false}
      menuType="context"
      items={contextMenuItems}
      customWidth={280}
      trigger={
        <MenuTrigger asChild>
          <div
            // Note (Noah, 2022-03-34): Cursor inherit here, since by default
            // the cursor is pointer for Menus and we don't want that to
            // interfere with other things that set the cursor styles in Canvas
            className="h-full cursor-[inherit] rounded-none"
          >
            {children}
          </div>
        </MenuTrigger>
      }
    />
  );
};

function CanvasAreaImpl() {
  const { canvasHtml } = useRequiredContext(CanvasAreaContext);
  const canvases = useEditorSelector(selectCanvases);
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const previewWidth = useEditorSelector(selectPreviewWidth);
  const canvasLoadingType = useEditorSelector(selectCanvasLoadingType);
  const canvasIsLoading = useEditorSelector(selectCanvasIsLoading);
  const isMultipleCanvasesEnabled = isFeatureEnabled("multiple-canvases");

  const frameRef = React.useRef<HTMLDivElement>(null);

  useWindowResize();
  useResetDraftComponentIfNeeded();
  useAutoRefetchCanvasDocument();
  useExitPromptIfPendingUpdates();
  useSanitizedTargetFrameDocument();
  useResetCanvasOnElementChange();
  useDragToPanCanvasMouseHandlers();
  useUpdateCanvasesHeights();
  usePaintTargetFrames();
  useScrollbarStyle();
  useSyncRuntimeStoreWithEditorState();

  return (
    <CanvasWrapperFrame canvases={canvases} frameRef={frameRef}>
      {canvasIsLoading && <EditorLoadingScreen type={canvasLoadingType} />}

      <div id="alchemyTargetFrameRoot" className="absolute h-full w-full" />
      {canvasHtml && canvasLoadingType !== "fetchingContent" && (
        <div
          className={classNames("absolute left-0 bottom-0 w-full h-full", {
            hidden: canvasIsLoading,
          })}
        >
          {isMultipleCanvasesEnabled ? (
            <ErrorBoundary onError={handleCanvasRenderError}>
              <div
                className="flex h-full"
                style={{ gap: isPreviewMode ? 0 : CANVAS_FRAME_GAP }}
              >
                {Object.entries(canvases).map(([key, canvas]) => {
                  const {
                    canvasHeight,
                    canvasWidth,
                    isVisible: _isVisible,
                    targetFrame,
                  } = canvas;
                  const isVisible = isPreviewMode
                    ? key === "desktop"
                    : _isVisible;

                  return (
                    <CanvasContext.Provider
                      key={key}
                      value={{
                        canvas: key as EditorCanvas,
                        canvasWidth: isPreviewMode ? previewWidth : canvasWidth,
                        canvasHeight,
                        isVisible,
                        targetFrame,
                      }}
                    >
                      {isVisible && (
                        <CanvasOverlayWrapper>
                          <CanvasOverlay />
                          <CanvasResizer />
                        </CanvasOverlayWrapper>
                      )}
                      {/* Note (Noah, 2024-07-15): We still want to keep
                      rendering the actual canvas iframe even if it's not
                      visible because if we unmounted it we'd lose all pre-existing
                      style tags which have been inserted into the <head> by Repainter */}
                      <Canvas />
                    </CanvasContext.Provider>
                  );
                })}
              </div>
            </ErrorBoundary>
          ) : (
            <ErrorBoundary onError={handleCanvasRenderError}>
              <CanvasContext.Provider
                value={{
                  canvas: "desktop",
                  isVisible: true,
                  targetFrame: canvases.desktop.targetFrame,
                  // NOTE (Chance 2024-06-25): This may be a little confusing
                  // but the canvas height and width are synced with the active
                  // canvas in single-canvas mode. This is because the reducer
                  // enforces constraints on the max/min sizes for each
                  // breakpoint, and we want the width of the canvas to update
                  // the active canvas when it is changing.
                  canvasHeight: canvases[activeCanvas].canvasHeight,
                  canvasWidth: canvases[activeCanvas].canvasWidth,
                }}
              >
                <CanvasOverlay />
                <Canvas />
              </CanvasContext.Provider>
            </ErrorBoundary>
          )}
        </div>
      )}
    </CanvasWrapperFrame>
  );
}

const CanvasWrapperFrame: React.FC<
  React.PropsWithChildren<{
    frameRef: React.RefObject<HTMLDivElement>;
    canvases: CanvasState["canvases"];
  }>
> = ({ frameRef, canvases, children }) => {
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const canvasWillChangeStatus = useEditorSelector(
    selectCanvasWillChangeStatus,
  );
  // TODO (Chance 2024-06-20): These components pass through children. They are
  // only components because we want to split up the hook logic based on the
  // multi-canvas flag. When single-canvas mode is gone, add the height/width
  // logic from `UpdateFramePositionStyles` into `useUpdateFramePositionStyles`
  // and call it here.
  const Wrapper = isFeatureEnabled("multiple-canvases")
    ? UpdateFramePositionStyles
    : UpdateFramePositionStylesLegacy;
  return (
    <div
      ref={frameRef}
      className={classNames("origin-top-left", {
        "h-full": isPreviewMode,
      })}
      onClick={(e) => e.stopPropagation()}
      style={{ willChange: isPreviewMode ? "auto" : canvasWillChangeStatus }}
    >
      <Wrapper frameRef={frameRef} canvases={canvases}>
        {children}
      </Wrapper>
    </div>
  );
};

const CanvasOverlayWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { canvas, canvasWidth, canvasHeight } =
    useRequiredContext(CanvasContext);
  const xOffset = useCanvasXOffset(canvas);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);

  // NOTE (Martin, 2024-07-29): We don't need a wrapper div on preview mode
  // since overlay is gone and we will only have one set of resizers that can
  // accomodate themselves to the parent container.
  if (isPreviewMode) {
    return children;
  }

  return (
    <div
      className="absolute"
      style={{ width: canvasWidth, height: canvasHeight, left: xOffset }}
    >
      {children}
    </div>
  );
};

function CanvasOverlay() {
  const interactionMode = useEditorSelector(selectCanvasInteractionMode);
  const shouldShowCanvasOverlayForInteractionMode = exhaustiveSwitch({
    type: interactionMode,
  })({
    "dragging-components": true,
    edit: true,
    grabbing: false,
    readyToGrab: false,
    resizing: false,
    "content-editing": false,
    locked: true,
  });

  const editorMode = useEditorSelector(selectEditorMode);
  const shouldShowCanvasOverlayForEditorMode = exhaustiveSwitch({
    type: editorMode,
  })({
    [EditorMode.edit]: true,
    [EditorMode.aiGeneration]: true,
    [EditorMode.versioning]: false,
    // Note (Noah, 2024-07-14, REPL-12588): We want to keep the overlay if we're in
    // preview mode but we're resizing the canvas frame, because if we don't, the mouse
    // will go outside of the ReactResizable which means onResize handlers won't be called
    [EditorMode.preview]: interactionMode === "resizing",
  });

  if (
    !shouldShowCanvasOverlayForEditorMode ||
    !shouldShowCanvasOverlayForInteractionMode
  ) {
    return null;
  }

  return (
    <PotentiallyInteractiveDropZone>
      <BoundingBoxes />
    </PotentiallyInteractiveDropZone>
  );
}

/**
 * Renders either a dropzone where users can upload images, OR just a normal div,
 * depending on the edit mode and interaction state of the canvas which it's rendered in.
 */
const PotentiallyInteractiveDropZone: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const interactionMode = useEditorSelector(selectCanvasInteractionMode);

  // Note (Noah, 2024-07-13): Important to keep this both for edit and dragging-components
  // because otherwise React will change the DOM structure when you begin to drag a
  // component, which will mess up the events from react-draggable and make drag and drop
  // not work
  if (interactionMode === "edit" || interactionMode === "dragging-components") {
    return (
      <FileDropZone
        // NOTE (Chance 2024-06-10): File upload area is role `button` by
        // default, but in the canvas it doesn't work like a button so we can
        // just use `presentation` role to reset it like a normal div.
        role="presentation"
        className="absolute inset-0"
      >
        {children}
      </FileDropZone>
    );
  }
  return <div className="absolute inset-0">{children}</div>;
};

function Canvas() {
  const { canvasHtml, frameRefs } = useRequiredContext(CanvasAreaContext);
  const { canvas, canvasWidth, canvasHeight, isVisible } =
    useRequiredContext(CanvasContext);

  const setFrameRef = React.useCallback(
    (canvas: EditorCanvas, iframeElement: HTMLIFrameElement | null) => {
      frameRefs.current.set(canvas, {
        element: iframeElement,
        // NOTE (Chance 2024-06-24): `isLoaded` is set to false when the ref
        // mounts. If the ref handler is called again the canvas is either
        // unmounting or a new canvas is being set. We reset the loading state
        // in either case.
        isLoaded: false,
      });
    },
    [frameRefs],
  );

  const handleFrameLoad = useFrameOnLoad(frameRefs);

  if (!canvasHtml) {
    return null;
  }
  return (
    <CanvasIFrame
      canvas={canvas}
      width={canvasWidth}
      height={canvasHeight}
      srcDoc={canvasHtml}
      setRef={setFrameRef}
      onLoad={(event) => {
        handleFrameLoad(canvas, event.currentTarget);
      }}
      isHidden={!isVisible}
      data-testid={canvas === "desktop" ? "canvas-iframe" : undefined}
      name={canvas === "desktop" ? "canvas-frame" : undefined}
    />
  );
}

interface CanvasIFrameProps {
  width?: string | number;
  height?: string | number;
  setRef: (canvas: EditorCanvas, node: HTMLIFrameElement | null) => void;
  srcDoc: string;
  onLoad: (event: React.SyntheticEvent<HTMLIFrameElement, Event>) => void;
  isHidden?: boolean;
  "data-testid"?: string;
  name?: string;
  canvas: EditorCanvas;
}

function CanvasIFrame({
  width = "100%",
  height = "100%",
  setRef,
  onLoad,
  srcDoc,
  isHidden = false,
  "data-testid": testId,
  name,
  canvas,
}: CanvasIFrameProps) {
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const canvasInteractionMode = useEditorSelector(selectCanvasInteractionMode);
  const ref = React.useCallback(
    (node: HTMLIFrameElement | null) => {
      setRef(canvas, node);
    },
    [setRef, canvas],
  );

  const enableInteractions =
    canvasInteractionMode === "content-editing" ||
    (isPreviewMode && canvasInteractionMode !== "resizing");

  return (
    <iframe
      ref={ref}
      className={classNames("bg-white border-0 h-full", isHidden && "hidden")}
      style={{
        width,
        height:
          !isFeatureEnabled("multiple-canvases") || isPreviewMode
            ? "100%"
            : height,
        pointerEvents: enableInteractions ? "auto" : "none",
      }}
      sandbox="allow-same-origin allow-scripts allow-popups allow-top-navigation"
      data-canvas-id={canvas}
      data-testid={testId}
      name={name}
      onLoad={onLoad}
      srcDoc={srcDoc}
    />
  );
}

interface UpdateFramePositionStylesProps {
  frameRef: React.RefObject<HTMLDivElement>;
  canvases: CanvasState["canvases"];
}

const UpdateFramePositionStyles: React.FC<
  React.PropsWithChildren<UpdateFramePositionStylesProps>
> = ({ frameRef, canvases, children }) => {
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const previewWidth = useEditorSelector(selectPreviewWidth);
  const canvasesTotalWidth = useEditorSelector(selectVisibleCanvasesTotalWidth);
  const largestCanvasHeight = useEditorSelector(
    selectLargestVisibleCanvasHeight,
  );

  useUpdateFramePositionStyles(frameRef, {
    width: isPreviewMode ? previewWidth : canvasesTotalWidth,
    height: isPreviewMode ? canvases.desktop.canvasHeight : largestCanvasHeight,
  });

  return children;
};

const UpdateFramePositionStylesLegacy: React.FC<
  React.PropsWithChildren<UpdateFramePositionStylesProps>
> = ({ frameRef, children }) => {
  const activeCanvasWidth = useEditorSelector(selectActiveCanvasWidth);
  const primaryCanvasHeight = useEditorSelector(selectPrimaryCanvasHeight);
  useUpdateFramePositionStyles(frameRef, {
    width: activeCanvasWidth,
    height: primaryCanvasHeight,
  });
  return children;
};

// #region Hooks
function useWindowResize() {
  const isRightBarVisible = useRightBarVisibility();
  const dispatch = useEditorDispatch();

  React.useEffect(() => {
    const debouncedResetFrameXPosition = debounce(() => {
      dispatch(resetFrameXPosition({ isRightBarVisible }));
    }, 100);

    window.addEventListener("resize", debouncedResetFrameXPosition);
    return () => {
      window.removeEventListener("resize", debouncedResetFrameXPosition);
    };
  }, [isRightBarVisible, dispatch]);
}

function useResetDraftComponentIfNeeded() {
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const setDraftElement = useSetDraftElement();

  React.useEffect(() => {
    if (isPreviewMode && draftComponentId) {
      setDraftElement({ componentId: null });
    }
  }, [isPreviewMode, draftComponentId, setDraftElement]);
}

function useExitPromptIfPendingUpdates() {
  const pendingUpdatesSize = useEditorSelector(selectPendingElementUpdatesSize);
  const hasPendingUpdates = pendingUpdatesSize > 0;
  React.useEffect(() => {
    if (!hasPendingUpdates) {
      return;
    }

    function handleBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
      event.returnValue =
        "There are unsaved changes, are you sure you want to exit?";
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasPendingUpdates]);
}

// TODO (Martin, 2024-08-12): Get rid of forbidden elements and the use of
// useSanitizedTargetFrameDocument once we have shipped no mirror in the editor.
const FORBIDDEN_STORE_SELECTORS_FOR_ALL_STORES = [
  "#kameleoonLoadingStyleSheet",
];
const FORBIDDEN_STORE_SELECTORS_BY_STORE_URL: Record<string, string[]> = {
  "offfield.myshopify.com": [".check-age"],
  "loop-united-states.myshopify.com": [
    "#gdpr-blocking-page-overlay",
    "#pandectes-banner",
  ],
};

function useSanitizedTargetFrameDocument() {
  const storeShopifyUrl = useEditorSelector(selectStoreShopifyUrl);
  const canvases = useEditorSelector(selectCanvases);

  const removeElementsFromCanvases = React.useCallback(
    (selectors: string[]) => {
      for (const canvas of Object.values(canvases)) {
        const targetDocument = canvas.targetFrame?.contentDocument;
        if (!targetDocument) {
          continue;
        }

        for (const selector of selectors) {
          const forbiddenElement = targetDocument.querySelector(selector);
          forbiddenElement?.remove();
        }
      }
    },
    [canvases],
  );

  React.useEffect(() => {
    removeElementsFromCanvases(FORBIDDEN_STORE_SELECTORS_FOR_ALL_STORES);
    if (storeShopifyUrl) {
      const selectors = FORBIDDEN_STORE_SELECTORS_BY_STORE_URL[storeShopifyUrl];
      if (selectors) {
        removeElementsFromCanvases(selectors);
      }
    }
  }, [removeElementsFromCanvases, storeShopifyUrl]);
}

function useResetCanvasOnElementChange() {
  const draftElementId = useEditorSelector(selectDraftElementId);
  const setActiveCanvas = useSetActiveCanvas();

  const draftElementIdRef = React.useRef<string | null>(draftElementId);
  React.useEffect(() => {
    if (draftElementIdRef.current !== draftElementId) {
      draftElementIdRef.current = draftElementId;
      setActiveCanvas({ canvas: "desktop", source: "reset" });
    }
  }, [draftElementId, setActiveCanvas]);
}

function useUpdateCanvasesHeights() {
  const updateCanvasHeight = useUpdateCanvasHeight();
  const dispatch = useEditorDispatch();
  const draftElementId = useEditorSelector(selectDraftElementId);
  const canvasIsLoading = useEditorSelector(selectCanvasIsLoading);
  const canvasesKeys = useEditorSelector(selectCanvasesKeys);
  const visibleCanvases = useEditorSelector(selectVisibleCanvases);
  const paintVersion = useEditorSelector(selectPaintVersion);
  const isMultipleCanvasesEnabled = isFeatureEnabled("multiple-canvases");

  const visibleCanvasesLength = Object.keys(visibleCanvases).length;

  const updateCanvasesHeight = React.useCallback(() => {
    const canvasesKeysToUse = isMultipleCanvasesEnabled
      ? canvasesKeys
      : ["desktop"];

    for (const key of canvasesKeysToUse) {
      updateCanvasHeight(key as EditorCanvas, {
        forceResetHeight: true,
      });
    }
  }, [isMultipleCanvasesEnabled, canvasesKeys, updateCanvasHeight]);

  // Update if paintVersion changes
  const paintVersionRef = React.useRef(paintVersion);
  const visibleCanvasesLengthRef = React.useRef(visibleCanvasesLength);

  // biome-ignore lint/correctness/useExhaustiveDependencies(isMultipleCanvasesEnabled): We actually want this
  React.useEffect(() => {
    if (paintVersionRef.current !== paintVersion) {
      paintVersionRef.current = paintVersion;
      updateCanvasesHeight();
      // TODO (Martin, 2024-08-09): currently having an issue where sometimes
      // height is not calculated correctly after painting, so the update is
      // not correct. We need to debug the updateCanvasesHeight further but
      // in the meantime we can update it again after a delay to fix the issue.
      setTimeout(updateCanvasesHeight, 1000);
    }
    if (visibleCanvasesLengthRef.current !== visibleCanvasesLength) {
      visibleCanvasesLengthRef.current = visibleCanvasesLength;
      updateCanvasesHeight();
    }
  }, [
    paintVersion,
    visibleCanvasesLength,
    updateCanvasesHeight,
    isMultipleCanvasesEnabled,
  ]);

  // #region
  // Update if anything about hiding Shopify theme parts changes
  const draftElementHideDefaultHeader = useEditorSelector(
    selectDraftElementHideDefaultHeader,
  );
  const draftElementHideDefaultFooter = useEditorSelector(
    selectDraftElementHideDefaultFooter,
  );
  const draftElementHideShopifyAnnouncementBar = Boolean(
    useEditorSelector(selectDraftElementHideShopifyAnnouncementBar),
  );
  const hideDefaultHeaderRef = React.useRef(draftElementHideDefaultHeader);
  const hideDefaultFooterRef = React.useRef(draftElementHideDefaultFooter);
  const hideShopifyAnnouncementBarRef = React.useRef(
    draftElementHideShopifyAnnouncementBar,
  );

  React.useEffect(() => {
    if (!draftElementId) {
      return;
    }

    if (
      hideDefaultHeaderRef.current !== draftElementHideDefaultHeader ||
      hideDefaultFooterRef.current !== draftElementHideDefaultFooter ||
      hideShopifyAnnouncementBarRef.current !==
        draftElementHideShopifyAnnouncementBar
    ) {
      // We might need some extra time because Shopfiy theme parts
      // might take some time loading.
      setTimeout(updateCanvasesHeight, 100);
    }
    hideDefaultHeaderRef.current = draftElementHideDefaultHeader;
    hideDefaultFooterRef.current = draftElementHideDefaultFooter;
    hideShopifyAnnouncementBarRef.current =
      draftElementHideShopifyAnnouncementBar;
  }, [
    updateCanvasesHeight,
    draftElementId,
    draftElementHideDefaultHeader,
    draftElementHideDefaultFooter,
    draftElementHideShopifyAnnouncementBar,
  ]);
  // #endregion

  // Reset canvas height when loading to properly show loading state
  React.useEffect(() => {
    if (canvasIsLoading) {
      // NOTE (Martin, 2024-08-07): for the loading state, we only show the
      // primary canvas so there's no need to updates heights on anything else.
      dispatch(setCanvasHeight({ canvas: "desktop", height: 1000 }));
    }
  }, [dispatch, canvasIsLoading]);
}

/**
 * Persist the canvas settings to local storage. The reason for this
 * implementation vs. useLocalStorageState is that we want the actual state to
 * be managed by the canvas reducer. We only need to use local storage to
 * persist the state across sessions, so its updates do not need to be stateful
 * on their own. This reduces duplication and unnecessary updates when syncing.
 */
function usePersistCanvasSettings() {
  const dispatch = useEditorDispatch();
  const canvases = useEditorSelector(selectCanvases);
  const activeCanvas = useEditorSelector(selectActiveCanvas);

  const projectId = useCurrentProjectId();

  const isMultipleCanvasesEnabled = isFeatureEnabled("multiple-canvases");

  // NOTE (Chance 2024-06-19): There are two effects here we want to coordinate.
  // We need to "initialize" the canvas settings from local storage based on the
  // current project ID. This is not initially set in the reducer because the
  // project ID may not be set depending on which route the user initially lands
  // on. Only after we have initialized the state do we want to sync later
  // updates back to local storage.
  const initializedProjectId = React.useRef<string | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies(activeCanvas): We actually want this
  React.useEffect(() => {
    if (!projectId || !isMultipleCanvasesEnabled) {
      return;
    }
    if (initializedProjectId.current !== projectId) {
      // state has not been initially set by the local storage value for this
      // project, so we should not sync its value. Otherwise the localstorage
      // will be updated w/ the current state and screw up persistence.
      return;
    }

    setCanvasLocalStorageState(
      projectId,
      mapValues(canvases, (canvas) => {
        return pick(canvas, "isVisible", "canvasWidth");
      }),
    );
  }, [projectId, isMultipleCanvasesEnabled, canvases, activeCanvas]);

  React.useEffect(() => {
    if (!projectId || !isMultipleCanvasesEnabled) {
      return;
    }

    if (initializedProjectId.current === projectId) {
      // state has already been initialized from local storage value
      return;
    }

    initializedProjectId.current = projectId;
    const initialStateFromLocalStorage = getCanvasLocalStorageState(projectId);
    if (initialStateFromLocalStorage) {
      dispatch(setStateFromLocalStorage(initialStateFromLocalStorage));
    }
  }, [projectId, isMultipleCanvasesEnabled, dispatch]);
}

const NO_SCROLLBAR_CLASSNAME = "replo-editor-no-scrollbar";
const NO_SCROLLBAR_CSS_CONTENT = `
  .${NO_SCROLLBAR_CLASSNAME}::-webkit-scrollbar {
    display: none !important;
  }
  .${NO_SCROLLBAR_CLASSNAME} {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }
`;

// TODO (Noah, 2023-08-31, USE-401, USE-322, REPL-8351): Apparently in certain cases,
// possibly due to race conditions in browser painting? our iframe height logic gets
// messed up and the iframe ends up being slightly too short for the content of the page,
// which gives it a scrollbar. This scrollbar can push over content of the page which
// is supposed to wrap, resulting in everything shifting over and causing a flicker.
// To get around this for now, we add a style element to the iframe which makes extremely
// sure that the body is set to not show the scrollbar in editor mode. This is kind of
// hacky, we should figure out the actual solution.
function useScrollbarStyle() {
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const canvases = useEditorSelector(selectCanvases);
  const primaryCanvasDocument = useTargetFrameDocument("desktop");

  React.useEffect(() => {
    // NOTE (Chance 2024-06-12): Use a unique style id to avoid adding the same
    // style multiple times, as the effect will run when each canvas iframe is
    // loaded.
    const styleId = "replo-editor-no-scrollbar-style";
    for (const canvas of Object.values(canvases)) {
      const canvasIFrame = canvas.targetFrame;
      if (!canvasIFrame) {
        continue;
      }

      const targetFrameDocument = getTargetFrameWindow(canvasIFrame)?.document;
      if (targetFrameDocument) {
        if (targetFrameDocument.getElementById(styleId)) {
          continue;
        }
        // TODO (Martin, 2024-08-07): move this to editorStyles. REPL-13086
        const styleElement = targetFrameDocument.createElement("style");
        styleElement.id = styleId;
        styleElement.textContent = NO_SCROLLBAR_CSS_CONTENT;
        targetFrameDocument.head.append(styleElement);
        targetFrameDocument.documentElement.classList.add(
          NO_SCROLLBAR_CLASSNAME,
        );
        targetFrameDocument.body.classList.add(NO_SCROLLBAR_CLASSNAME);
      }
    }
  }, [canvases]);

  // Note (Noah, 2023-08-31, USE-401, USE-322, REPL-8351): Make sure to reset
  // our scrollbar class in preview mode, otherwise users won't be able to see
  // the scrollbar in preview.
  // NOTE (Martin, 2024-08-07): we only need to check for primaryCanvas on this
  // effect, as it's the only canvas that is used on preview mode.
  React.useEffect(() => {
    if (!primaryCanvasDocument) {
      return;
    }

    if (isPreviewMode) {
      primaryCanvasDocument.documentElement.classList.remove(
        NO_SCROLLBAR_CLASSNAME,
      );
    } else {
      primaryCanvasDocument.documentElement.classList.add(
        NO_SCROLLBAR_CLASSNAME,
      );
    }
  }, [isPreviewMode, primaryCanvasDocument]);
}

function useCanvasXOffset(canvas: EditorCanvas) {
  const visibleCanvases = useEditorSelector(selectVisibleCanvases);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  if (!isFeatureEnabled("multiple-canvases") || isPreviewMode) {
    return 0;
  }

  let xOffset = 0;
  for (const [key, visibleCanvas] of Object.entries(visibleCanvases)) {
    if (key === canvas) {
      break;
    }
    xOffset += visibleCanvas.canvasWidth + CANVAS_FRAME_GAP;
  }
  return xOffset;
}
// #endregion

function handleCanvasRenderError(error: unknown, info: React.ErrorInfo) {
  if (isDevelopment) {
    console.error("[REPLO] Component rendering error in Canvas", {
      error,
      reactErrorInfo: info,
    });
  }
  trackError(error);
}

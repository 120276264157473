import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const reviewsIoReviews: ComponentTemplate = {
  id: "308c1a95-e8ff-4c39-81d3-6519de31b8bd",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Reviews.io Widget",
  leftBarCategory: { main: "productIntegrations", subCategory: "reviewsIo" },
  preview: "integrations/reviewsio-product-reviews.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  template: {
    id: "fd1f17d4-2f26-4337-a1d2-551537a10953",
    type: "reviewsIoReviews",
    props: {},
  },
};

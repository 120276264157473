import * as RadixAvatar from "@radix-ui/react-avatar";
import classNames from "classnames";
import * as React from "react";
import { twMerge } from "tailwind-merge";

export type AvatarSize = "xs" | "sm" | "base" | "lg" | "xl";

type AvatarProps = {
  name?: string | null;
  imageSrc?: string;
  className?: string;
  size?: AvatarSize;
  imageClassName?: string;
};

const Avatar = ({
  imageSrc,
  name,
  className,
  size = "base",
  imageClassName = "",
}: AvatarProps) => {
  const containerClassName = twMerge(
    classNames(
      `inline-flex items-center justify-items-center align-middle rounded-full flex-shrink-0`,
      {
        "h-5 w-5": size === "xs",
        "h-6 w-6": size === "sm",
        "h-9 w-9": size === "base",
        "h-12 w-12": size === "lg",
        "h-24 w-24": size === "xl",
      },
      className,
    ),
  );

  const fallbackClassName = classNames({
    "text-xs": size === "xs",
    "text-sm": size === "sm",
    "text-lg": size === "base" || size === "lg",
    "text-3xl": size === "xl",
  });

  const firstLetter = name?.charAt(0) || "?";
  const delayMs = Boolean(imageSrc) ? 600 : 0;
  return (
    <RadixAvatar.Root className={containerClassName}>
      {imageSrc && name && (
        <RadixAvatar.Image
          style={{ borderRadius: "inherit" }}
          className={twMerge("h-full w-full object-cover", imageClassName)}
          src={imageSrc}
          alt={name}
        />
      )}
      {name ? (
        <RadixAvatar.AvatarFallback
          delayMs={delayMs}
          style={{ borderRadius: "inherit" }}
          className={twMerge(
            "flex h-full w-full items-center justify-center bg-blue-600 text-slate-50",
            fallbackClassName,
            imageClassName,
          )}
        >
          {firstLetter?.toUpperCase()}
        </RadixAvatar.AvatarFallback>
      ) : (
        <RadixAvatar.AvatarFallback
          delayMs={delayMs}
          style={{ borderRadius: "inherit" }}
          className={twMerge(
            "h-full w-full border-2 border-dotted border-slate-400",
            imageClassName,
          )}
        ></RadixAvatar.AvatarFallback>
      )}
    </RadixAvatar.Root>
  );
};

export default Avatar;

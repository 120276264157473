import { activeTabVariantActionTransform } from "@editor/components/editor/templates/transforms";
import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const switchTemplate: ComponentTemplate = {
  id: "d38a50d4-278f-4068-b4f2-2dd3c8110e46",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Component/Switch",
  leftBarCategory: { main: "interactive", subCategory: "toggles" },
  preview: "component/switch.svg",
  transforms: [activeTabVariantActionTransform],
  tags: ["toggle", "switch"],
  template: {
    id: "5e7460c5-a855-4017-a7ac-7f2caa51bf14",
    name: "Switch",
    type: "toggleContainer",
    props: {
      style: {
        width: "48px",
        height: "24px",
        display: "flex",
        alignItems: "center",
        borderTopColor: "#000000FF",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        justifyContent: "center",
        borderLeftColor: "#000000FF",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderRightColor: "#000000FF",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderBottomColor: "#000000FF",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderTopLeftRadius: "24px",
        borderTopRightRadius: "24px",
        borderBottomLeftRadius: "24px",
        borderBottomRightRadius: "24px",
        __animateVariantTransitions: true,
      },
      _accessibilityRole: "switch",
      _accessibilityHidden: true,
      _accessibilityChecked: false,
    },
    children: [
      {
        id: "ffc8877d-8d7e-48b4-93f3-0f4e2c5d4226",
        name: "Switch Indicator",
        type: "toggleIndicator",
        props: {
          style: {
            top: "50%",
            left: "2px",
            width: "18px",
            height: "18px",
            display: "flex",
            maxWidth: "100%",
            position: "absolute",
            alignItems: "flex-start",
            __transform: {
              translateX: "0px",
              translateY: "-50%",
              translateZ: "0px",
            },
            justifyContent: "flex-start",
            backgroundColor: "#000000FF",
            borderTopLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomLeftRadius: "50%",
            borderBottomRightRadius: "50%",
            __animateVariantTransitions: true,
          },
        },
        children: [],
      },
    ],
    variants: [
      {
        id: "802ceb58-0620-411b-801c-f5f4b35ca21e",
        name: "default",
        query: null,
        componentOverrides: null,
      },
      {
        id: "3568b06c-e0dc-41fc-9732-1249228c6f16",
        name: "Active",
        query: null,
        componentOverrides: null,
      },
    ],
    variantOverrides: {
      "3568b06c-e0dc-41fc-9732-1249228c6f16": {
        componentOverrides: {
          "ffc8877d-8d7e-48b4-93f3-0f4e2c5d4226": {
            props: {
              style: {
                left: "26px",
              },
              _accessibilityChecked: true,
            },
          },
          "5e7460c5-a855-4017-a7ac-7f2caa51bf14": {
            props: {
              _accessibilityChecked: true,
              style: {
                backgroundColor: "#e2e8f0",
              },
            },
          },
        },
      },
    },
  },
};

import * as coreActions from "@actions/core-actions";
import { useEditorDispatch } from "@editor/store";
import * as React from "react";

export default function useSetDraftElement() {
  const dispatch = useEditorDispatch();
  const setDraftElement = React.useCallback(
    (payload: coreActions.SetDraftElementPayload) =>
      dispatch(coreActions.setDraftElement(payload)),
    [dispatch],
  );

  return setDraftElement;
}

import AuthLayout from "@components/account/AuthLayout";
import { useNextUrlWithPendingAppInstallationId } from "@components/account/common";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";

const AuthPage = () => {
  const { isAuthenticated, isLoading } = useCurrentUser();
  const { nextUrl, nextSearchParams } =
    useNextUrlWithPendingAppInstallationId();

  if (isAuthenticated && !isLoading) {
    return (
      <Navigate
        to={{
          pathname: nextUrl ?? "/home",
          search: nextSearchParams.toString(),
        }}
        replace
      />
    );
  }

  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
};

export default AuthPage;

import { useEditorDispatch, useEditorSelector } from "@editor/store";
import * as React from "react";

import {
  selectActiveCanvasWidth,
  setDeltaXY as setDeltaXYAction,
} from "./canvas-reducer";
import type { SetDeltaXYPayloadWithoutFrameWidth } from "./canvas-types";

export function useSetDeltaXY() {
  const dispatch = useEditorDispatch();
  const activeCanvasWidth = useEditorSelector(selectActiveCanvasWidth);
  const setDeltaXY = React.useCallback(
    (payload: SetDeltaXYPayloadWithoutFrameWidth) => {
      if (typeof payload === "function") {
        dispatch(
          setDeltaXYAction((deltaXY) => ({
            ...payload(deltaXY),
            frameWidth: activeCanvasWidth,
          })),
        );
      } else {
        dispatch(
          setDeltaXYAction({ ...payload, frameWidth: activeCanvasWidth }),
        );
      }
    },
    [dispatch, activeCanvasWidth],
  );
  return setDeltaXY;
}

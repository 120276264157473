import { slugSchema } from "schemas/utils";
import type { Variation } from "schemas/variation";
import { variationSchema } from "schemas/variation";
import { z } from "zod";

const experimentStatuses = ["draft", "archived", "complete", "active"] as const;

export type ExperimentStatus = (typeof experimentStatuses)[number];

export const ExperimentStatuses: Record<
  Uppercase<ExperimentStatus>,
  ExperimentStatus
> = {
  DRAFT: "draft",
  ARCHIVED: "archived",
  COMPLETE: "complete",
  ACTIVE: "active",
};

export const experimentStatusSchema = z.enum(experimentStatuses);

export const experimentVariationsArraySchema = z
  .array(variationSchema)
  .refine(
    (variations) =>
      variations.reduce((sum, v) => v.allocationPercent + sum, 0) === 100,
    {
      message: "sum of variation percentages must add up to 100",
    },
  )
  .refine(
    (variations) =>
      new Set(variations.map((v) => v.slug)).size === variations.length,
    {
      message: "variations must have unique key",
    },
  );

export const experimentSchema = z.object({
  id: z.string().uuid(),
  projectId: z.string(),
  slug: slugSchema,
  name: z.string(),
  description: z.string().nullable(),
  createdAt: z.coerce.date(),
  activatedAt: z.coerce.date().nullable(),
  archivedAt: z.coerce.date().nullable(),
  completedAt: z.coerce.date().nullable(),
  variations: experimentVariationsArraySchema,
});

export type Experiment = z.infer<typeof experimentSchema> & {
  variations: Array<Variation>;
};

export const experimentDetailSchema = z.object({
  id: z.string().uuid(),
  projectId: z.string(),
  projectSlug: slugSchema,
  projectCustomDomain: z.string().nullable(),
  slug: slugSchema,
  name: z.string(),
  description: z.string().nullish(),
  createdAt: z.coerce.date(),
  activatedAt: z.coerce.date().nullable(),
  archivedAt: z.coerce.date().nullable(),
  completedAt: z.coerce.date().nullable(),
  variations: experimentVariationsArraySchema,
});

export type ExperimentDetail = z.infer<typeof experimentDetailSchema> & {
  variations: Array<Variation>;
};

export const experimentListSchema = z.object({
  experiments: z.array(experimentSchema),
});

/**
 * Determine an experiment's status by using the most recently set timestamp
 * on the experiment. The updating process for experiments results in timestamps
 * that are only increasing.
 *
 * @author Ben 2023-10-04
 */
export function getExperimentStatus(dates: {
  createdAt: Date;
  activatedAt: Date | null;
  completedAt: Date | null;
  archivedAt: Date | null;
}): ExperimentStatus {
  type Input = typeof dates;
  const keyedStatuses: { [index in keyof Input]: ExperimentStatus } = {
    createdAt: ExperimentStatuses.DRAFT,
    activatedAt: ExperimentStatuses.ACTIVE,
    completedAt: ExperimentStatuses.COMPLETE,
    archivedAt: ExperimentStatuses.ARCHIVED,
  };
  const [key] = Object.entries(dates)
    .filter(([key]) => key in keyedStatuses)
    .filter(([, value]) => Boolean(value))
    .map(
      ([key, value]) =>
        [key, Number(new Date(value!))] as [keyof Input, number],
    )
    .sort(([, a], [, b]) => b - a)
    .find(Boolean)!;
  return keyedStatuses[key];
}

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const h2: ComponentTemplate = {
  id: "cbb42361-c053-467b-87f5-82d54be78a19",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Basic/h2",
  leftBarCategory: { main: "basic", subCategory: "text" },
  preview: "basic/h2.svg",
  tags: ["heading", "text", "subtitle"],
  transforms: [],
  template: {
    id: "$uid",
    type: "text",
    props: {
      text: "<h2>Add new heading here</h2>",
      style: {
        fontSize: "32px",
        lineHeight: "36px",
      },
    },
  },
};

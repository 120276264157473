import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { prependProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedLiquidReviewIntegrations } from "../SharedLiquidReviewIntegrations";

function JudgeProductRatingWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const product = useProductFromProps(component.props, context);
  const liquidSource = `<!-- Start of Judge.me code -->
  <div style='{{ jm_style }}' class='jdgm-widget jdgm-preview-badge'  data-id='{{ product.id }}'>
    {{ product.metafields.judgeme.badge }}
  </div>
<!-- End of Judge.me code -->`;
  const liquid = prependProductAssignmentToLiquidSource({
    product,
    liquidSource,
    context: props.context,
  });
  return (
    <SharedLiquidReviewIntegrations
      attributes={componentAttributes}
      component={component}
      liquidSource={liquid}
      isProductRating
      placeholder="Judge Product Rating Widget will appear here"
      shouldHavePlaceHolder={isEditorApp || !product}
      appName="Judge.me"
      context={context}
    />
  );
}

export default JudgeProductRatingWidget;

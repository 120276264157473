import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const youtubeEmbed: ComponentTemplate = {
  id: "youtubeEmbed",
  storeId: null,
  scope: "left-bar",
  type: "component",
  env: Env.PRODUCTION,
  name: "Video/Youtube",
  leftBarCategory: { main: "media", subCategory: "video" },
  tags: ["embed", "video"],
  preview: "video/youtube-embed.svg",
  template: {
    id: "90c90d52-3d1d-448e-ad1f-222fc7c613ad",
    type: "youtubeEmbedV2",
    props: {},
  },
};

import * as React from "react";
import { Svg, type SvgProps } from "replo-utils/react/components";

export const SvgEmpyProjects = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={513}
    height={328}
    fill="none"
    {...props}
  >
    <rect width={296} height={296} x={112} fill="#EFF6FF" rx={24} />
    <g filter="url(#a)">
      <rect
        width={285}
        height={135}
        x={3}
        y={22}
        fill="#fff"
        rx={8}
        shapeRendering="crispEdges"
      />
      <rect width={152} height={7} x={23} y={42} fill="#E5E7EB" rx={3.5} />
      <rect width={245} height={32} x={23} y={57} fill="#F1F5F9" rx={4} />
      <rect width={86} height={32} x={182} y={105} fill="#2563EB" rx={4} />
    </g>
    <g filter="url(#b)">
      <rect
        width={285}
        height={135}
        x={225}
        y={189}
        fill="#fff"
        rx={8}
        shapeRendering="crispEdges"
      />
      <rect width={152} height={7} x={245} y={209} fill="#E5E7EB" rx={3.5} />
      <rect width={245} height={32} x={245} y={224} fill="#F1F5F9" rx={4} />
      <rect width={86} height={32} x={404} y={272} fill="#2563EB" rx={4} />
    </g>
    <defs>
      <filter
        id="a"
        width={291}
        height={141}
        x={0}
        y={20}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          radius={1}
          result="effect1_dropShadow_517_3239"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_517_3239"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          in2="effect1_dropShadow_517_3239"
          result="effect2_dropShadow_517_3239"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_517_3239"
          result="shape"
        />
      </filter>
      <filter
        id="b"
        width={291}
        height={141}
        x={222}
        y={187}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          radius={1}
          result="effect1_dropShadow_517_3239"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_517_3239"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          in2="effect1_dropShadow_517_3239"
          result="effect2_dropShadow_517_3239"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_517_3239"
          result="shape"
        />
      </filter>
    </defs>
  </Svg>
);
export default SvgEmpyProjects;

import Button from "@common/designSystem/Button";
import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import { useRouterFlowCallbacks } from "@components/flows/hooks/useRouterFlowCallbacks";
import * as React from "react";

type FlowActionButtonsProps = {
  shouldDisableNextButton: boolean;
  hideBackButton?: boolean;
  customLabels?: {
    nextButton?: string;
    backButton?: string;
  };
};

const FlowActionButtons: React.FC<FlowActionButtonsProps> = ({
  shouldDisableNextButton,
  hideBackButton = false,
  customLabels,
}) => {
  const { goBack, isSubmitting } = useReploFlowsStepContext();
  const { goBackCallback } = useRouterFlowCallbacks();

  const onGoBack = React.useCallback(() => {
    goBack((currentFlow, previousStepId) =>
      goBackCallback(currentFlow.slug, previousStepId),
    );
  }, [goBack, goBackCallback]);

  return (
    <div className="flex gap-2 self-end">
      {!hideBackButton ? (
        <Button
          className="w-full lg:w-fit bg-slate-100 text-slate-600"
          textClassNames="font-normal"
          type="secondary"
          size="lg"
          isDisabled={isSubmitting}
          onClick={onGoBack}
        >
          {customLabels?.backButton ?? "Back"}
        </Button>
      ) : null}
      <Button
        className="w-full lg:w-fit"
        textClassNames="font-normal"
        type="primary"
        size="lg"
        htmlType="submit"
        isLoading={isSubmitting}
        isDisabled={shouldDisableNextButton}
      >
        {customLabels?.nextButton ?? "Next"}
      </Button>
    </div>
  );
};

export default FlowActionButtons;

import Switch from "@editor/components/common/designSystem/Switch";
import * as React from "react";

import { ElementEditorDescription } from "./ElementEditorDescription";
import { ElementEditorHeading } from "./ElementEditorHeading";

export const ElementSettingsSwitch: React.FC<{
  title: string;
  description: string;
  isOn?: boolean;
  onChange(value: boolean): void;
}> = ({ title, description, isOn, onChange }) => {
  return (
    <div className="flex flex-row items-center justify-between text-slate-400">
      <div>
        <ElementEditorHeading>{title}</ElementEditorHeading>
        <ElementEditorDescription>{description}</ElementEditorDescription>
      </div>
      <Switch isOn={isOn} onChange={onChange} backgroundOnColor="bg-blue-600" />
    </div>
  );
};

import { convertDaysToMs } from "replo-utils/lib/datetime";

export type DefaultTimeframeOption =
  | "last-24-hours"
  | "last-7-days"
  | "last-14-days"
  | "last-30-days"
  | "last-90-days"
  | "last-180-days"
  | "last-365-days"
  | "this-week"
  | "last-month";

export const MS_IN_SELECTED_RANGE: Record<DefaultTimeframeOption, number> = {
  "last-24-hours": convertDaysToMs(1),
  "last-7-days": convertDaysToMs(7),
  "last-14-days": convertDaysToMs(14),
  "last-30-days": convertDaysToMs(30),
  "last-90-days": convertDaysToMs(90),
  "last-180-days": convertDaysToMs(180),
  "last-365-days": convertDaysToMs(365),
  "this-week": convertDaysToMs(7),
  "last-month": convertDaysToMs(30),
};

export const TIMEFRAME_COMBOBOX_OPTIONS: Array<{
  value: DefaultTimeframeOption;
  label: string;
}> = [
  { value: "last-24-hours", label: "Last 24 hours" },
  { value: "last-7-days", label: "Last 7 days" },
  { value: "last-14-days", label: "Last 14 days" },
  { value: "last-30-days", label: "Last 30 days" },
  { value: "last-90-days", label: "Last 90 days" },
  { value: "last-180-days", label: "Last 180 days" },
  { value: "last-365-days", label: "Last 365 days" },
  { value: "this-week", label: "This week" },
  { value: "last-month", label: "Last month" },
];

export const TEN_MINUTES_IN_MS = 600_000;

export const LAST_7_DAYS_COMBOBOX_OPTION_VALUE: DefaultTimeframeOption =
  "last-7-days";
export const DEFAULT_CUSTOM_DATE_RANGE_COMBOBOX_OPTION_VALUE =
  "custom-date-range";

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const carousel: ComponentTemplate = {
  id: "b00dd763-347e-41b0-a8b7-a7c6b8d92faf",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Carousel/Single-Slide Carousel",
  leftBarCategory: { main: "interactive", subCategory: "carousel" },
  preview: "carousel/base.png",
  template: {
    id: "99aba73c-8284-4357-8cf6-5aab8afa5e2b",
    name: "single-slide carousel",
    type: "carouselV3",
    props: {
      style: {
        display: "flex",
        flexGrow: "1",
        maxWidth: "100%",
        __flexGap: "24px",
        alignSelf: "stretch",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
      _autoWidth: false,
      _itemsPerView: 1,
      _slideClickTransition: true,
    },
    children: [
      {
        id: "d8890b90-0be8-4e0b-8d6e-693332b6002c",
        name: "Previous Button",
        type: "carouselV3Control",
        props: {
          style: {
            color: "#000000FF",
            cursor: "pointer",
            display: "flex",
            flexWrap: "wrap",
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center",
          },
          "style@sm": {
            top: "50%",
            left: "0px",
            right: "auto",
            bottom: "auto",
            zIndex: 1,
            flexGrow: "unset",
            position: "absolute",
            alignSelf: "auto",
            __transform: {
              skewX: "0deg",
              skewY: "0deg",
              scaleX: "100%",
              scaleY: "100%",
              scaleZ: "100%",
              rotateX: "0deg",
              rotateY: "0deg",
              rotateZ: "0deg",
              translateX: "0px",
              translateY: "-50%",
              translateZ: "0px",
            },
          },
          direction: "previous",
        },
        children: [
          {
            id: "fe812822-1c3f-4ec9-92e5-72a6a47417da",
            type: "icon",
            props: {
              style: {
                width: "40px",
                height: "40px",
                __width: "40px",
                __height: "40px",
              },
              iconName: "chevronLeft",
            },
            variantOverrides: {},
          },
        ],
        variants: [
          {
            id: "71621b12-b2dd-44ef-8aac-38196209bb07",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "05ea09fb-0644-4388-8c0f-0f468e82f5c9",
            name: "First Item",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "bf8b0b4c-79fd-4043-a18b-9840a038a6e3",
                  field: "state.group.isFirstItemActive",
                  value: null,
                  operator: null,
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "05ea09fb-0644-4388-8c0f-0f468e82f5c9": {
            componentOverrides: {
              "d8890b90-0be8-4e0b-8d6e-693332b6002c": {
                props: {
                  style: {
                    cursor: "default",
                  },
                },
              },
              "fe812822-1c3f-4ec9-92e5-72a6a47417da": {
                props: {
                  style: {
                    opacity: "20%",
                  },
                },
              },
            },
          },
        },
      },
      {
        id: "7c17b218-6e4b-42bd-be21-d22f770a0097",
        name: "Container",
        type: "container",
        props: {
          style: {
            display: "flex",
            flexGrow: 1,
            maxWidth: "100%",
            alignSelf: "stretch",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "flex-start",
          },
          "style@md": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          },
          "style@sm": {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "flex-start",
          },
        },
        children: [
          {
            id: "39d21018-4e45-4bc1-aa45-04b557765e21",
            name: "Slides",
            type: "carouselV3Slides",
            props: {
              style: {
                __width: "600px",
                display: "flex",
                flexGrow: 1,
                overflow: "hidden",
                alignSelf: "stretch",
                alignItems: "center",
                justifyContent: "flex-start",
              },
              "style@sm": {
                width: "auto",
                height: "auto",
                __width: "400px",
                flexGrow: 1,
                alignSelf: "stretch",
                flexBasis: "auto",
              },
            },
            children: [
              {
                id: "c7745530-5f51-42f1-8250-7020cad2f06e",
                name: "Slide 1",
                type: "container",
                props: {
                  style: {
                    __width: "auto",
                    display: "flex",
                    flexGrow: 1,
                    __flexGap: "24px",
                    alignSelf: "stretch",
                    alignItems: "center",
                    paddingTop: "24px",
                    paddingBottom: "24px",
                    justifyContent: "center",
                  },
                  "style@sm": {
                    width: "auto",
                    height: "auto",
                    display: "flex",
                    flexGrow: 1,
                    alignSelf: "auto",
                    flexDirection: "column",
                  },
                },
                children: [
                  {
                    id: "fc59d62a-b43a-4461-b135-76bd63f97586",
                    name: "Image",
                    type: "image",
                    props: {
                      style: {
                        __width: "500px",
                        __height: "500px",
                        flexGrow: 1,
                        maxWidth: "800px",
                        objectFit: "cover",
                        __imageSource:
                          "https://images.unsplash.com/photo-1504198266287-1659872e6590?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTd8fHxlbnwwfHx8fHw%3D",
                        __imageAltText:
                          "A single green leaf against a white background.",
                      },
                      "style@md": {
                        width: "auto",
                        flexGrow: 1,
                      },
                      "style@sm": {
                        height: "auto",
                        flexGrow: 1,
                      },
                    },
                    markers: {
                      _aiGeneratedAltText: true,
                    },
                  },
                  {
                    id: "4b78457b-4d67-4b00-8b78-23089851d2d0",
                    name: "Content",
                    type: "container",
                    props: {
                      style: {
                        display: "flex",
                        maxWidth: "100%",
                        __flexGap: "12px",
                        alignSelf: "stretch",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      },
                      "style@sm": {
                        width: "auto",
                        marginLeft: "16px",
                        marginRight: "16px",
                      },
                    },
                    children: [
                      {
                        id: "fa68f326-cc08-4dba-bc74-25ebde16312e",
                        type: "text",
                        props: {
                          text: "<p>Slide Title</p>",
                          style: {
                            fontSize: "24px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "700",
                            lineHeight: "28px",
                          },
                        },
                      },
                      {
                        id: "edae1aff-0d0f-4f89-9f84-bc8341af7047",
                        type: "text",
                        props: {
                          text: "<p>This is slide 1 of 4</p>",
                          style: {
                            fontSize: "20px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "400",
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                id: "7594d56f-abd5-4d47-b7a1-263aaa9c9a25",
                name: "Slide 2",
                type: "container",
                props: {
                  style: {
                    __width: "auto",
                    display: "flex",
                    flexGrow: 1,
                    __flexGap: "24px",
                    alignSelf: "stretch",
                    alignItems: "center",
                    paddingTop: "24px",
                    paddingBottom: "24px",
                    justifyContent: "center",
                  },
                  "style@sm": {
                    width: "auto",
                    height: "auto",
                    display: "flex",
                    flexGrow: 1,
                    alignSelf: "auto",
                    flexDirection: "column",
                  },
                },
                children: [
                  {
                    id: "4e6afc47-3425-44eb-96fe-31d83837f0ac",
                    name: "Image",
                    type: "image",
                    props: {
                      style: {
                        __width: "500px",
                        __height: "400px",
                        flexGrow: 1,
                        maxWidth: "600px",
                        objectFit: "cover",
                        __imageSource:
                          "https://images.unsplash.com/photo-1504204267155-aaad8e81290d?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                        __imageAltText:
                          "A single green palm leaf in a clear glass of water on a white background.",
                      },
                      "style@md": {
                        width: "auto",
                        flexGrow: 1,
                      },
                      "style@sm": {
                        height: "auto",
                        flexGrow: 1,
                      },
                    },
                    markers: {
                      _aiGeneratedAltText: true,
                    },
                  },
                  {
                    id: "1ca25449-9e3b-4571-83c4-4c41a7a77391",
                    name: "Content",
                    type: "container",
                    props: {
                      style: {
                        display: "flex",
                        maxWidth: "100%",
                        __flexGap: "12px",
                        alignSelf: "stretch",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      },
                      "style@sm": {
                        width: "auto",
                        marginLeft: "16px",
                        marginRight: "16px",
                      },
                    },
                    children: [
                      {
                        id: "00100988-0b32-4a7c-a545-4f0e8890eeeb",
                        type: "text",
                        props: {
                          text: "<p>Slide Title</p>",
                          style: {
                            fontSize: "24px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "700",
                            lineHeight: "28px",
                          },
                        },
                      },
                      {
                        id: "e33c9d87-1ece-4ed1-b630-5b644b9fc467",
                        type: "text",
                        props: {
                          text: "<p>This is slide 2 of 4</p>",
                          style: {
                            fontSize: "20px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "400",
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                id: "112423e6-cb52-4f42-a6e0-0b7e7c69d310",
                name: "Slide 3",
                type: "container",
                props: {
                  style: {
                    __width: "auto",
                    display: "flex",
                    flexGrow: 1,
                    __flexGap: "24px",
                    alignSelf: "stretch",
                    alignItems: "center",
                    paddingTop: "24px",
                    paddingBottom: "24px",
                    justifyContent: "center",
                  },
                  "style@sm": {
                    width: "auto",
                    height: "auto",
                    display: "flex",
                    flexGrow: 1,
                    alignSelf: "auto",
                    flexDirection: "column",
                  },
                },
                children: [
                  {
                    id: "f27b1035-d1b5-485f-9c7d-a44dc0558088",
                    name: "Image",
                    type: "image",
                    props: {
                      style: {
                        __width: "500px",
                        __height: "400px",
                        flexGrow: 1,
                        maxWidth: "600px",
                        objectFit: "cover",
                        __imageSource:
                          "https://images.unsplash.com/photo-1471086569966-db3eebc25a59?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                        __imageAltText:
                          "Single monstera leaf in a clear glass vase against a white background.",
                      },
                      "style@md": {
                        width: "auto",
                        flexGrow: 1,
                      },
                      "style@sm": {
                        height: "auto",
                        flexGrow: 1,
                      },
                    },
                    markers: {
                      _aiGeneratedAltText: true,
                    },
                  },
                  {
                    id: "4e8a9bcd-00d1-4585-b1f8-79d46b625224",
                    name: "Content",
                    type: "container",
                    props: {
                      style: {
                        display: "flex",
                        maxWidth: "100%",
                        __flexGap: "12px",
                        alignSelf: "stretch",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      },
                      "style@sm": {
                        width: "auto",
                        marginLeft: "16px",
                        marginRight: "16px",
                      },
                    },
                    children: [
                      {
                        id: "f4d1dc53-8507-48b4-bcf2-d25189674bb0",
                        type: "text",
                        props: {
                          text: "<p>Slide Title</p>",
                          style: {
                            fontSize: "24px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "700",
                            lineHeight: "28px",
                          },
                        },
                      },
                      {
                        id: "5e95d30d-79da-4436-8c77-e1c5d918d6ff",
                        type: "text",
                        props: {
                          text: "<p>This is slide 3 of 4</p>",
                          style: {
                            fontSize: "20px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "400",
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                id: "b1398e44-318e-4035-ae30-7283aefae7e6",
                name: "Slide 4",
                type: "container",
                props: {
                  style: {
                    __width: "auto",
                    display: "flex",
                    flexGrow: 1,
                    __flexGap: "24px",
                    alignSelf: "stretch",
                    alignItems: "center",
                    paddingTop: "24px",
                    paddingBottom: "24px",
                    justifyContent: "center",
                  },
                  "style@sm": {
                    width: "auto",
                    height: "auto",
                    display: "flex",
                    flexGrow: 1,
                    alignSelf: "auto",
                    flexDirection: "column",
                  },
                },
                children: [
                  {
                    id: "febd9b1d-9216-4a98-9c2c-f7a9459d1f3b",
                    name: "Image",
                    type: "image",
                    props: {
                      style: {
                        __width: "500px",
                        __height: "400px",
                        flexGrow: 1,
                        maxWidth: "600px",
                        objectFit: "cover",
                        __imageSource:
                          "https://images.unsplash.com/photo-1655658233701-a0bada796e94?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                        __imageAltText:
                          "A green palm leaf beside two blank white cards on a white background.",
                      },
                      "style@md": {
                        width: "auto",
                        flexGrow: 1,
                      },
                      "style@sm": {
                        height: "auto",
                        flexGrow: 1,
                      },
                    },
                    markers: {
                      _aiGeneratedAltText: true,
                    },
                  },
                  {
                    id: "72beb111-d7bc-420e-a338-40dcbd95692b",
                    name: "Content",
                    type: "container",
                    props: {
                      style: {
                        display: "flex",
                        maxWidth: "100%",
                        __flexGap: "12px",
                        alignSelf: "stretch",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      },
                      "style@sm": {
                        width: "auto",
                        marginLeft: "16px",
                        marginRight: "16px",
                      },
                    },
                    children: [
                      {
                        id: "d9d32808-e058-4b8a-aa1e-535f865a30ed",
                        type: "text",
                        props: {
                          text: "<p>Slide Title</p>",
                          style: {
                            fontSize: "24px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "700",
                            lineHeight: "28px",
                          },
                        },
                      },
                      {
                        id: "63f39f98-9bc4-48ab-ad64-108ea9045228",
                        type: "text",
                        props: {
                          text: "<p>This is slide 4 of 4</p>",
                          style: {
                            fontSize: "20px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "400",
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: "b4bbedcb-98f3-4d2f-b2ca-db82f1ea10ab",
            name: "Indicators",
            type: "carouselV3Indicators",
            props: {
              style: {
                zIndex: 2,
                display: "flex",
                position: "relative",
                alignItems: "center",
                paddingTop: "16px",
                paddingBottom: "16px",
                justifyContent: "center",
              },
              "style@sm": {
                display: "none",
                __display: "inline",
              },
            },
            children: [
              {
                id: "2e35f357-82c1-49a6-b2de-34fe1f6e10c2",
                name: "Indicator",
                type: "container",
                props: {
                  style: {
                    width: "10px",
                    cursor: "pointer",
                    height: "10px",
                    __width: "10px",
                    display: "flex",
                    __height: "10px",
                    flexGrow: "unset",
                    maxWidth: "100%",
                    alignSelf: "auto",
                    alignItems: "flex-start",
                    marginLeft: "10px",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    backgroundColor: "#0000004D",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  },
                },
                children: [],
                variants: [
                  {
                    id: "7da51b64-850a-4991-87d7-40dc9b51b34f",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "c6ad7920-936d-48fc-9fe0-7f288e475732",
                    name: "Current item is active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "a5eefa18-3bc3-4890-a191-335f2c071f83",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "c6ad7920-936d-48fc-9fe0-7f288e475732": {
                    componentOverrides: {
                      "2e35f357-82c1-49a6-b2de-34fe1f6e10c2": {
                        props: {
                          style: {
                            backgroundColor: "#000000FF",
                          },
                        },
                      },
                    },
                  },
                },
              },
            ],
          },
        ],
      },
      {
        id: "028ff8d0-79db-49ce-af5e-194307c1b849",
        name: "Next Button",
        type: "carouselV3Control",
        props: {
          style: {
            color: "#000000FF",
            cursor: "pointer",
            display: "flex",
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center",
          },
          "style@sm": {
            top: "50%",
            left: "auto",
            right: "0px",
            bottom: "auto",
            zIndex: 1,
            flexGrow: "unset",
            position: "absolute",
            alignSelf: "auto",
            __transform: {
              skewX: "0deg",
              skewY: "0deg",
              scaleX: "100%",
              scaleY: "100%",
              scaleZ: "100%",
              rotateX: "0deg",
              rotateY: "0deg",
              rotateZ: "0deg",
              translateX: "0px",
              translateY: "-50%",
              translateZ: "0px",
            },
          },
          direction: "next",
        },
        children: [
          {
            id: "c5fcb105-38e0-4c65-a247-f07f5311cf1d",
            type: "icon",
            props: {
              style: {
                width: "40px",
                height: "40px",
                __width: "40px",
                __height: "40px",
              },
              iconName: "chevronRight",
            },
            variantOverrides: {},
          },
        ],
        variants: [
          {
            id: "f5ae43a0-7c6c-4eb8-909a-95303945865a",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "cb2e8961-2fd6-4ff0-a957-324c2ff3f64a",
            name: "Last Item",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "fa7db26c-7755-450b-b10f-b5f0f5c6a624",
                  field: "state.group.isLastItemActive",
                  value: null,
                  operator: null,
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "cb2e8961-2fd6-4ff0-a957-324c2ff3f64a": {
            componentOverrides: {
              "028ff8d0-79db-49ce-af5e-194307c1b849": {
                props: {
                  style: {
                    cursor: "default",
                  },
                },
              },
              "c5fcb105-38e0-4c65-a247-f07f5311cf1d": {
                props: {
                  style: {
                    opacity: "20%",
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};

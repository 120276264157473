import type { MenuItem } from "@common/designSystem/Menu";
import { Menu } from "@common/designSystem/Menu";
import * as React from "react";
import { BsCaretDownFill } from "react-icons/bs";

type ChevronMenuIndicatorProps = {
  items: MenuItem[];
  menuWidth?: number;
  align?: "end" | "center" | "start";
  isDisabled?: boolean;
};

const ChevronMenuIndicator = ({
  items,
  menuWidth,
  align,
  isDisabled = false,
}: ChevronMenuIndicatorProps) => {
  return (
    <Menu
      items={items}
      menuType="normal"
      customWidth={menuWidth}
      align={align}
      isDisabled={isDisabled}
      trigger={
        <div className="flex h-4 w-4 items-center justify-center rounded">
          <BsCaretDownFill className="text-subtle" size={8} />
        </div>
      }
    />
  );
};

export default ChevronMenuIndicator;

import {
  selectComponentDataMapping,
  selectComponentMapping,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
} from "@editor/reducers/core-reducer";
import { selectExplicitExpandedNodeIds } from "@editor/reducers/tree-reducer";
import { useEditorStore } from "@editor/store";
import {
  canMoveComponentToParent,
  getParentComponentFromMapping,
} from "@editor/utils/component";
import type { AlchemyClipboard } from "@editor/utils/copyPaste";
import { useGetAttributeRef } from "@hooks/useGetAttribute";
import useSelection from "@hooks/useSelection";
import * as React from "react";
import { getChildren } from "replo-runtime/shared/utils/component";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";

const contextMenuSource = "contextMenu";

export const useFindParentForPaste = () => {
  const store = useEditorStore();
  const { selectedIds } = useSelection();
  const getAttributeRef = useGetAttributeRef();
  const isMultiSelect = selectedIds.length > 1;

  const findParentForPaste = React.useCallback(
    (alchemyClipboard: AlchemyClipboard, componentId: string) => {
      const draftElement =
        selectDraftElement_warningThisWillRerenderOnEveryUpdate(
          store.getState(),
        );
      const componentMapping = selectComponentMapping(store.getState());
      const explicitExpandedNodeIds = selectExplicitExpandedNodeIds(
        store.getState(),
      );
      const componentSelectedForPaste = getFromRecordOrNull(
        componentMapping,
        componentId,
      )?.component;
      let newParent = componentSelectedForPaste!;
      let positionWithinSiblings = 0;

      const component = (() => {
        if (alchemyClipboard.type === "figmaPluginExportv2") {
          return alchemyClipboard.reploComponent;
        }

        // TODO (Evan, 2024-07-25): Clean this up more, why
        // is this so heinous
        return (
          alchemyClipboard.type !== "copyPasteStyles" &&
          alchemyClipboard.type !== "pasteCSSStyles" &&
          (alchemyClipboard.type === "singleComponent" ||
          alchemyClipboard.type === "text" ||
          alchemyClipboard.type === "image"
            ? alchemyClipboard.component
            : alchemyClipboard.components[0]!)
        );
      })();

      if (!component) {
        return null;
      }

      const parentOfSelectedComponent =
        componentSelectedForPaste &&
        getParentComponentFromMapping(
          componentMapping,
          componentSelectedForPaste.id,
        );

      const newSingleComponentId =
        alchemyClipboard.type === "singleComponent" &&
        alchemyClipboard.component?.id;

      if (parentOfSelectedComponent) {
        const canMoveInside = canMoveComponentToParent(
          draftElement,
          component,
          componentSelectedForPaste,
          getAttributeRef.current,
          contextMenuSource,
          selectComponentDataMapping(store.getState()),
        ).canMove;
        const isEmptyContainer =
          componentSelectedForPaste.type === "container" &&
          getChildren(componentSelectedForPaste).length === 0;

        // Note (Noah, 2022-02-07): If we can't move component as a child or pasting in the same component, paste
        // as a sibling instead
        if (
          componentSelectedForPaste &&
          (!canMoveInside ||
            newSingleComponentId === componentId ||
            isMultiSelect ||
            // NOTE (Fran 2024-04-16): If the parent is a container and it's empty, we should paste the component inside it.
            (!isEmptyContainer &&
              !explicitExpandedNodeIds.includes(componentId)))
        ) {
          newParent = parentOfSelectedComponent!;
          positionWithinSiblings =
            getChildren(newParent!).indexOf(componentSelectedForPaste) + 1;
        }
      }

      return {
        newParent,
        positionWithinSiblings,
      };
    },
    [store, getAttributeRef, isMultiSelect],
  );

  return findParentForPaste;
};

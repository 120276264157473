import Button from "@common/designSystem/Button";
import Input from "@common/designSystem/Input";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import { toast } from "@editor/components/common/designSystem/Toast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { storeToken } from "@editor/reducers/utils/store-token";
import { trpc } from "@editor/utils/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useForm } from "react-hook-form";
import { passwordSchema } from "schemas/auth";
import { z } from "zod";

const validationSchema = z.object({
  password: z.string().min(1, "Please enter a valid password"),
  newPassword: passwordSchema,
});

export default function SecurityBoard() {
  const logEvent = useLogAnalytics();
  const { mutate: updateAccountPassword, isPending: isLoading } =
    trpc.user.updateAccountPassword.useMutation({
      onSuccess: ({ token }) => {
        storeToken(token);
        toast({
          header: "Password Updated",
          message: "Your password has successfully been updated.",
        });
        logEvent("user.setting.updated", {
          infoUpdated: "password",
        });
        reset();
      },
    });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      newPassword: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const hasErrors = Object.keys(errors).length > 0;

  async function onSubmit({
    password,
    newPassword,
  }: {
    password: string;
    newPassword: string;
  }) {
    updateAccountPassword({
      password: password,
      newPassword: newPassword,
    });
  }

  return (
    <div className="flex w-64 flex-col">
      <h3 className="mb-2 text-sm font-medium text-default">Password</h3>
      <form
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
      >
        <LabeledControl label="Current Password">
          <div className={errors?.password?.message ? "mb-1.5" : "mb-3"}>
            <Input
              // TODO (Chance 2023-11-10): This field should be marked as
              // required in its markup.
              aria-invalid={errors?.password?.message ? "true" : undefined}
              aria-describedby={
                errors?.password?.message ? "error-password" : undefined
              }
              autoComplete="current-password"
              placeholder=""
              type="password"
              size="base"
              {...register("password")}
            />
          </div>
          <ErrorMessage error={errors?.password?.message} id="error-password" />
        </LabeledControl>
        <LabeledControl label="New Password">
          <div className={errors?.newPassword?.message ? "mb-1.5" : "mb-3"}>
            <Input
              // TODO (Chance 2023-11-10): This field should be marked as
              // required in its markup.
              aria-invalid={errors?.newPassword?.message ? "true" : undefined}
              aria-describedby={
                errors?.newPassword?.message
                  ? "error-password-confirm"
                  : undefined
              }
              key="newPassword"
              autoComplete="off"
              placeholder=""
              type="password"
              size="base"
              {...register("newPassword")}
            />
          </div>
          <ErrorMessage
            error={errors?.newPassword?.message}
            id="error-password-confirm"
          />
        </LabeledControl>
        <Button
          type="primary"
          htmlType="submit"
          size="base"
          className="px-3"
          isDisabled={isLoading || hasErrors}
          isLoading={isLoading}
        >
          Update Password
        </Button>
      </form>
    </div>
  );
}

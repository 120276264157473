import * as coreActions from "@actions/core-actions";
import { getTokenFromStorage } from "@editor/infra/auth";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { openBillingModalIfNeeded } from "@editor/reducers/modals-reducer";
import type { EditorRootState } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import type { TrpcQueryReturn, TrpcRouterArgs } from "@editor/utils/trpc";
import { trpcClient } from "@editor/utils/trpc";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import {
  handleStreamingAction,
  markStreamingUpdateFinished,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftElementFromCoreState,
  setEditorMode,
  setStreamingUpdate,
} from "@reducers/core-reducer";
import type { BaseQueryApi } from "@reduxjs/toolkit/dist/query/react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import cloneDeep from "lodash-es/cloneDeep";
import { batch } from "react-redux";
import { forEachComponentAndDescendants } from "replo-runtime";
import type { Component } from "replo-runtime/shared/Component";
import { getPublisherUrl } from "replo-runtime/shared/config";
import type { RuntimeStyleProperties } from "replo-runtime/shared/styleAttribute";
import type { ReploShopifyProduct } from "replo-runtime/shared/types";
import type { MobileResponsiveBody, TextV2Body } from "schemas/ai";
import { aiComponentActionSchema } from "schemas/ai";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";

import {
  scrollToComponentId,
  setCanvasInteractionMode,
} from "@/features/canvas/canvas-reducer";

export const goals = [
  "maximizeConversion",
  "maximizeCartSize",
  "maximizeCartValue",
  "educateUser",
  "tellBrandStory",
  "lowerBounceRate",
] as const;
export const goalsSchema = z.enum(goals);
export type Goal = z.infer<typeof goalsSchema>;

export const tones = [
  "professional",
  "casual",
  "sassy",
  "confident",
  "joyful",
  "friendly",
  "direct",
  "other",
] as const;
export const tonesSchema = z.enum(tones);
export type Tone = z.infer<typeof tonesSchema>;

export const topics = [
  "title",
  "heroCopy",
  "callToAction",
  "productDescription",
  "benefits",
  "usageInstructions",
  "faq",
] as const;
export const topicsSchema = z.enum(topics);
export type Topic = z.infer<typeof topicsSchema>;

export const pageTopics = [
  "advertorial",
  "listicle",
  "longFormProductOffer",
  "videoSalesLetter",
  "productDropPage",
  "faq",
] as const;
export const pageTopicsSchema = z.enum(pageTopics);
export type PageTopic = z.infer<typeof pageTopicsSchema>;

type AskAiTextPayload = {
  meta: {
    model: "gpt-4" | "gpt-3.5-turbo" | "gpt-4o-mini";
  };
  operation: "generate";
  type: "text";
  options: {
    component: Component;
    context: {
      product?: ReploShopifyProduct;
      additionalContext?: string;
      tone?: string;
      topic?: Topic;
      goal?: Goal;
    };
  };
};

type AskAiPagePayload = {
  meta: {
    model: "gpt-4" | "gpt-3.5-turbo" | "gpt-4o-mini";
  };
  operation: "generate";
  type: "page";
  options: {
    context: {
      product?: ReploShopifyProduct;
      additionalContext?: string;
      tone?: string;
      topic?: PageTopic;
      goal?: Goal;
    };
  };
};

// Note (Evan, 2024-07-09): Essentially a no-op, just copies the component over
const defaultTransformDraftElementComponentStyles = (
  draftElementComponent: Readonly<Component>,
) => ({
  transformedComponent: cloneDeep(draftElementComponent),
  originalStyles: {},
});

type AIStreamingBody = MobileResponsiveBody | TextV2Body;

type KeysPopulatedInQueryFn =
  | "streamingUpdateId"
  | "screenshotDependencies"
  | "model"
  | "elementId";

// Note (Evan, 2024-07-09): This function streams actions from a given url, with a given
// JSON body. We also pass the componentId (to ensure all actions have componentId defined),
// and an optional function that can temporarily transform the draft element component while streaming
// (currently used for fixing the width/height of text components when streaming text). This isn't
// ~technically~ a BaseQueryFn, but it's pretty close - the only differences are that we take
// simpler args (i.e., we put the query args into the body before passing), and we don't return anything.
const aiStreamingQuery = async (
  args: {
    url: string;
    body: Omit<AIStreamingBody, KeysPopulatedInQueryFn>;
    componentId: string;
    // Note (Evan, 2024-07-09): Optional function that modifies styles for the draft element component and its children
    // and returns the modified component and the original styles (as a mapping from component id -> component styles)
    transformDraftElementComponentStyles?: (
      draftElementComponent: Readonly<Component>,
    ) => {
      transformedComponent: Component;
      originalStyles: Record<string, any>;
    };
  },
  { signal, dispatch, getState }: BaseQueryApi,
) => {
  const {
    url,
    body: _body,
    transformDraftElementComponentStyles = defaultTransformDraftElementComponentStyles,
  } = args;

  // Note (Evan, 2024-08-21): This type assertion is explicitly recommended
  // by the RTK docs, https://redux-toolkit.js.org/rtk-query/usage-with-typescript#typing-dispatch-and-getstate
  const {
    dependencies,
    products,
    productMetafieldValues,
    variantMetafieldValues,
  } = coreActions.getDependencyData(getState() as EditorRootState);

  const draftElement = selectDraftElement_warningThisWillRerenderOnEveryUpdate(
    getState() as EditorRootState,
  );

  const draftElementComponent = draftElement.component;

  const streamingUpdateId = uuidv4();
  const screenshotDependencies = {
    dependencies,
    products,
    productMetafieldValues,
    variantMetafieldValues,
    fullComponent: draftElementComponent,
  };

  const body: AIStreamingBody = {
    ..._body,
    elementId: draftElement.id,
    streamingUpdateId,
    screenshotDependencies,
    model: isFeatureEnabled("ai-claude") ? "claude-3.5-sonnet" : undefined,
  };

  const token = getTokenFromStorage();

  // Note (Evan, 2024-06-04): Return to normal editing when aborted
  const abortHandler = () => {
    dispatch(setStreamingUpdate(null));
    dispatch(setEditorMode(EditorMode.edit));
    dispatch(setCanvasInteractionMode("edit"));
  };

  signal.addEventListener("abort", abortHandler);

  await fetchEventSource(url, {
    method: "POST",
    headers: {
      authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    signal,
    onopen: async () => {
      const { transformedComponent, originalStyles } =
        transformDraftElementComponentStyles(draftElementComponent);

      dispatch(
        setStreamingUpdate({
          id: streamingUpdateId,
          draftElementComponent: transformedComponent,
          isStreaming: true,
          actions: [],
          originalStyles,
          textMap: {},
          repaintKey: 0,
        }),
      );
    },

    onmessage: (event) => {
      // Note (Evan, 2024-06-11): If an error event comes in, throw an error
      // so the onerror handler kicks in
      if (event.event === "error") {
        throw JSON.parse(event.data);
      }

      if (event.data === "") {
        return;
      }

      // Note (Evan, 2024-06-04): This type assertion is explicitly recommended
      // by the RTK docs, https://redux-toolkit.js.org/rtk-query/usage-with-typescript#typing-dispatch-and-getstate
      const state = getState() as EditorRootState;

      const { data: action } = z
        .object({ data: aiComponentActionSchema })
        .parse(JSON.parse(event.data));

      batch(() => {
        dispatch(
          handleStreamingAction({
            ...action,
            activeCanvas: state.canvas.activeCanvas,
          }),
        );
        dispatch(scrollToComponentId(action.componentId));
      });
    },
    onerror: (error) => {
      // Note (Evan, 2024-06-10): Return to normal editing
      dispatch(setStreamingUpdate(null));
      dispatch(setEditorMode(EditorMode.edit));
      dispatch(setCanvasInteractionMode("edit"));

      // Note (Evan, 2024-06-10): We re-throw this so the error toast shows
      throw error;
    },
    onclose: () => {
      dispatch(markStreamingUpdateFinished());
      dispatch(setCanvasInteractionMode("edit"));

      signal.removeEventListener("abort", abortHandler);
    },
  });
};

type AskAiPayload = AskAiTextPayload | AskAiPagePayload;

export const aiApi = createApi({
  reducerPath: "aiApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${getPublisherUrl()}/api/v1/ai`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = getTokenFromStorage();

      if (token) {
        headers.set("Authorization", `Token ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    askAi: builder.mutation<
      { component: Component },
      { payload: AskAiPayload; storeId: string }
    >({
      query: ({ payload, storeId }) => ({
        url: "/",
        method: "POST",
        body: { payload, storeId },
        params: { storeId },
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.catch((error: any) => {
          dispatch(
            openBillingModalIfNeeded({
              key: error.error?.data?.key,
              source: "ai.credits",
            }),
          );
        });
      },
    }),
    aiAltText: builder.mutation<
      TrpcQueryReturn<"ai", "createAltText">,
      // Note (Evan, 2024-05-08): Take the componentId as a param, even though it's not
      // passed to the server, so that we can use it to assign the result
      // to the correct component.
      // Note (Evan, 2024-08-16): Pass the more generally-named 'triggeredManually' instead
      // of 'throwOnError' - we throw on error iff the mutation is triggered manually, we
      // use the more general name since this also determines whether the action dispatched
      // should reflect in the undo/redo history. (When it is automatic, it should not be
      // undo-able)
      Omit<TrpcRouterArgs<"ai", "createAltText">, "throwOnError"> & {
        componentId: string;
        triggeredManually: boolean;
      }
    >({
      queryFn: async ({ imageUrl, triggeredManually }) => {
        const data = await trpcClient.ai.createAltText.mutate({
          imageUrl,
          throwOnError: triggeredManually,
        });
        return { data };
      },
      onQueryStarted: async (
        { componentId, triggeredManually },
        { queryFulfilled, dispatch },
      ) => {
        const { data } = await queryFulfilled;

        if (data.result) {
          dispatch(
            coreActions.applyComponentAction({
              type: "applyCompositeAction",
              activeCanvas: "desktop" as const,
              value: [
                {
                  type: "setStyles",
                  activeCanvas: "desktop" as const,
                  value: { __imageAltText: data.result },
                  componentId,
                },
                {
                  type: "setMarker",
                  value: {
                    _aiGeneratedAltText: true,
                  },
                  componentId,
                  activeCanvas: "desktop" as const,
                },
              ],
              shouldBypassHistory: !triggeredManually,
            }),
          );
        }
      },
    }),

    bulkAiAltText: builder.mutation<
      TrpcQueryReturn<"ai", "createBulkAltText">,
      TrpcRouterArgs<"ai", "createBulkAltText">
    >({
      queryFn: async (imageComponentIdToUrl) => {
        const data = await trpcClient.ai.createBulkAltText.mutate(
          imageComponentIdToUrl,
        );

        return { data };
      },
      onQueryStarted: async (_args, { queryFulfilled, dispatch }) => {
        const { data } = await queryFulfilled;

        const actions = Object.entries(data).flatMap(
          ([componentId, altTextValue]) => [
            {
              type: "setStyles" as const,
              activeCanvas: "desktop" as const,
              value: { __imageAltText: altTextValue },
              componentId,
            },
            {
              type: "setMarker" as const,
              value: {
                _aiGeneratedAltText: true,
              },
              componentId,
              activeCanvas: "desktop" as const,
            },
          ],
        );

        if (actions.length === 0) {
          return;
        }

        dispatch(
          coreActions.applyComponentAction({
            type: "applyCompositeAction",
            activeCanvas: "desktop" as const,
            value: actions,
            shouldBypassHistory: true,
          }),
        );
      },
    }),

    aiTextV2: builder.mutation<
      {},
      Omit<TextV2Body, KeysPopulatedInQueryFn> & {
        textComponentDimensions: Record<
          string,
          { height: number; width: number }
        >;
      }
    >({
      queryFn: async (
        {
          component,
          textComponentDimensions,
          projectId,
          product,
          projectContext,
          userPrompt,
        },
        api,
      ) => {
        const body: Omit<TextV2Body, KeysPopulatedInQueryFn> = {
          component,
          projectId,
          projectContext,
          userPrompt,
          product,
        };

        try {
          // Note (Evan, 2024-07-18): IMPORTANT: this endpoint is currently rate-limited to a maximum of
          // 1 action per 10ms, on the backend (routes/ai.ts) - this is in order to allow the editor sufficient time
          // to process each action. Though currently this approach doesn't seem to add net time to text generation,
          // that could become the case in the future, particularly if the behavior of our LLM provider (currently OpenAI)
          // changes. So if things become slow in the future, that is the place to start.
          await aiStreamingQuery(
            {
              url: `${getPublisherUrl()}/api/v1/ai/text-v2`,
              body,
              componentId: component.id,
              transformDraftElementComponentStyles: (draftElementComponent) => {
                // Note (Evan, 2024-06-07): Set text components' current width/height as minimum so things
                // don't jerk around while streaming. Save any existing minHeight/minWidth so we can restore them when streaming is done.
                const originalStyles: Record<string, RuntimeStyleProperties> =
                  {};

                const draftElementComponentCopy = cloneDeep(
                  draftElementComponent,
                );

                forEachComponentAndDescendants(
                  draftElementComponentCopy,
                  (component) => {
                    const dimensions = textComponentDimensions[component.id];

                    if (dimensions) {
                      originalStyles[component.id] = {
                        minWidth: component.props.style?.minWidth,
                        minHeight: component.props.style?.minHeight,
                      };

                      component.props.style = {
                        ...component.props.style,
                        minHeight: `${dimensions.height}px`,
                        minWidth: `${dimensions.width}px`,
                      };
                    }
                  },
                );

                return {
                  transformedComponent: draftElementComponentCopy,
                  originalStyles,
                };
              },
            },
            api,
          );

          return { data: {} };
        } catch (error) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: String(error),
              data: error,
            },
          };
        }
      },
    }),
    aiMobileResponsive: builder.mutation<
      {},
      Omit<MobileResponsiveBody, KeysPopulatedInQueryFn>
    >({
      queryFn: async ({ component, projectId }, api) => {
        try {
          await aiStreamingQuery(
            {
              url: `${getPublisherUrl()}/api/v1/ai/mobile-responsive`,
              body: {
                component,
                projectId,
              },
              componentId: component.id,
            },
            api,
          );

          return { data: {} };
        } catch (error) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: String(error),
              data: error,
            },
          };
        }
      },
    }),
  }),
});

export const {
  useAskAiMutation,
  useAiAltTextMutation,
  useBulkAiAltTextMutation,
  useAiTextV2Mutation,
  useAiMobileResponsiveMutation,
} = aiApi;

import { ToastManager } from "@common/designSystem/Toast";
import {
  clearTemplateLibraryRedirection,
  getLibraryTemplateData,
} from "@common/utils";
import DashboardSidebar from "@editor/components/dashboard/sidebar/DashboardSidebar";
import { DebugModal } from "@editor/components/editor/debug/DebugPanel";
import Flows from "@editor/components/flows/Flows";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { useLocalStorage } from "@editor/hooks/useLocalStorage";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { routes } from "@editor/utils/router";
import {
  selectInternalDebugModeOn as selectDevDebugPanelVisibility,
  setDebugPanelVisibility,
} from "@reducers/core-reducer";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useEffectEvent } from "replo-utils/react/use-effect-event";

export default function DashboardLayout({ children }: React.PropsWithChildren) {
  const dispatch = useEditorDispatch();
  const isDebugPanelVisible = useEditorSelector(selectDevDebugPanelVisibility);
  const toggleDebugMode = React.useCallback(() => {
    dispatch(setDebugPanelVisibility(!isDebugPanelVisible));
  }, [dispatch, isDebugPanelVisible]);
  useReploHotkeys({ debug: toggleDebugMode });

  useRedirectToTemplateDetailsIfNeeded();
  const { user } = useCurrentUser();
  const params = new URLSearchParams({ utm_source: "dashboard" });

  if (user) {
    params.append("email", user.email);
    if (user.firstName) {
      params.append("name", user.firstName);
    }
  }

  return (
    <div className="flex flex-col h-screen">
      <ToastManager />
      {user && <Flows entityType="user" />}
      {isDebugPanelVisible && <DebugModal />}
      <div
        className="grid grow w-screen gap-x-4 overflow-hidden bg-white"
        style={{
          gridTemplateColumns: "250px 1fr",
        }}
      >
        <DashboardSidebar />
        <div
          className="no-scrollbar overflow-scroll"
          // NOTE (Fran 2023-10-03): we need to calculate the exact height of the content without
          // the header to avoid have the wrong size.
          style={{ height: "calc(100vh - 35px)" }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

function useRedirectToTemplateDetailsIfNeeded() {
  const localStorage = useLocalStorage();
  const navigate = useNavigate();

  const onBeforeUnload = useEffectEvent(() => {
    clearTemplateLibraryRedirection(localStorage);
  });

  React.useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [onBeforeUnload]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    const libraryRedirectionData = getLibraryTemplateData(localStorage);
    if (libraryRedirectionData) {
      navigate(
        generatePath(`/home/${routes.templateSharedDetails}`, {
          templateId: libraryRedirectionData.templateId,
        }),
        { state: { marketplaceModalRequestType: "share" } },
      );

      clearTemplateLibraryRedirection(localStorage);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

import * as React from "react";

// NOTE (Matt 2024-06-07): This component is used to wrap liquid code in a way that
// will make it easier to identify and manipulate chunks of liquid code
// during the chunkification process. Generally speaking, you should wrap
// the highest root node of any liquid code you're working on in these
// tags. If you are writing JSX, you can just use the ReploLiquidChunk component.
// If you are working with dynamic data or any other kind of plaintext liquid as
// a string, you can use these consts.

export const LIQUID_CHUNK_BEGIN =
  "{%- comment -%}replo-liquid-chunk-begin{%- endcomment -%}";
export const LIQUID_CHUNK_END =
  "{%- comment -%}replo-liquid-chunk-end{%- endcomment -%}";

export const wrapStringWithLiquidChunks = (str: string) =>
  `${LIQUID_CHUNK_BEGIN}${str}${LIQUID_CHUNK_END}`;

const ReploLiquidChunk = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      {LIQUID_CHUNK_BEGIN}
      {children}
      {LIQUID_CHUNK_END}
    </>
  );
};

export default ReploLiquidChunk;

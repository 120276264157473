import { selectFontFamily } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import ModifierGroup from "@editorExtras/ModifierGroup";
import { FontFamilySelectable } from "@editorModifiers/TextStyleModifier";
import * as React from "react";
import { isNotNullish } from "replo-utils/lib/misc";

const FontModifier: React.FC<React.PropsWithChildren<unknown>> = () => {
  const fontFamily = useEditorSelector(selectFontFamily);

  return (
    <ModifierGroup title="Font" isDefaultOpen={isNotNullish(fontFamily)}>
      <FontFamilySelectable />
    </ModifierGroup>
  );
};

export default FontModifier;

import Button from "@common/designSystem/Button";
import Input from "@common/designSystem/Input";
import Modal from "@common/designSystem/Modal";
import toast from "@common/designSystem/Toast";
import { ModalLayout } from "@common/ModalLayout";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import { useWorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import { skipToken } from "@tanstack/react-query";
import * as React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";

import type { DeleteWorkspaceModalProps } from "../AppModalTypes";

type FormValues = {
  workspaceName: string;
};

export const DeleteWorkspaceModal: React.FC<DeleteWorkspaceModalProps> = ({
  workspaceId,
}) => {
  const logEvent = useLogAnalytics();
  const { closeModal } = useModal();
  const navigate = useNavigate();
  const { setWorkspaceId } = useWorkspaceDashboardContext();
  const { data: userWorkspacesData } =
    trpc.workspace.getUserWorkspacesList.useQuery();
  const { data } = trpc.workspace.getById.useQuery(
    workspaceId ? { id: workspaceId } : skipToken,
  );
  const { mutate: deleteWorkspace, isPending: isLoading } =
    trpc.workspace.delete.useMutation({
      onSuccess: async () => {
        await trpcUtils.user.get.invalidate();
        toast({
          header: "Workspace deleted",
        });
        const nonDeletedWorkspaces = userWorkspacesData?.workspaces.filter(
          (workspace) => workspace.id !== data?.workspace.id,
        );
        setWorkspaceId(nonDeletedWorkspaces?.[0]?.id ?? null);
        if (data?.workspace) {
          logEvent("workspace.delete", {
            workspaceId: data?.workspace.id,
            workspaceName: data?.workspace.name,
          });
        }
        navigate(`/home`);
        closeModal({ type: "deleteWorkspaceModal" });
      },
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: {
      workspaceName: "",
    },
  });
  const workspaceNameValue = useWatch({ control, name: "workspaceName" });
  const hasWorkspaceNameErrors = !isEmpty(errors.workspaceName);

  const onSubmit = ({ workspaceName }: FormValues) => {
    if (data?.workspace.name === workspaceName) {
      void deleteWorkspace(data.workspace.id);
    }
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        closeModal({ type: "deleteWorkspaceModal" });
      }}
      className="w-auto"
    >
      <ModalLayout
        height="auto"
        width="25vw"
        minWidth={400}
        mainContent={() => (
          <div className="flex flex-col gap-6 p-2 w-full">
            <h2 className="text-xl font-medium">Delete Workspace</h2>
            <p className="text-sm text-default">
              When you delete a workspace, all content such as referral codes
              and workspace members will also be deleted.
            </p>
            <form
              className="flex flex-col gap-6"
              onSubmit={(data) => {
                void handleSubmit(onSubmit)(data);
              }}
            >
              <div className="flex flex-col gap-2">
                <div className="flex flex-col text-sm text-slate-400">
                  <span>
                    Type the name of the workspace to confirm deletion.
                  </span>
                  <span>Workspace Name: {data?.workspace.name}</span>
                </div>
                <label htmlFor="workspaceName" className="sr-only">
                  Workspace name
                </label>
                <Input
                  id="workspaceName"
                  aria-invalid={hasWorkspaceNameErrors ? "true" : undefined}
                  aria-describedby={
                    hasWorkspaceNameErrors ? "error-workspace-name" : undefined
                  }
                  autoComplete="off"
                  placeholder={data?.workspace.name}
                  {...register("workspaceName", {
                    required: "Please enter the workspace name.",
                    validate: (value) =>
                      value === data?.workspace.name ? true : false,
                  })}
                  type="text"
                  size="base"
                  validityState={hasWorkspaceNameErrors ? "invalid" : "valid"}
                  autoFocus
                />
                <ErrorMessage
                  id="error-workspace-name"
                  error={errors.workspaceName?.message}
                />
              </div>
              <div className="flex gap-2 self-end">
                <Button
                  type="secondary"
                  size="base"
                  textClassNames="text-xs"
                  onClick={() => closeModal({ type: "deleteWorkspaceModal" })}
                >
                  Cancel
                </Button>
                <Button
                  type="danger"
                  size="base"
                  textClassNames="text-xs"
                  htmlType="submit"
                  isDisabled={hasWorkspaceNameErrors || !workspaceNameValue}
                  isLoading={isLoading}
                >
                  Delete
                </Button>
              </div>
            </form>
          </div>
        )}
      />
    </Modal>
  );
};

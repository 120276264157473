import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const infiniteOptionsWidget: ComponentTemplate = {
  id: "14ef087c-f182-4838-9c3b-0b2c70bc33eb",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Infinite Options Widget",
  leftBarCategory: {
    main: "productIntegrations",
    subCategory: "infiniteOptions",
  },
  preview: "product/infinite-options.svg",
  transforms: [],
  template: {
    id: "$uid",
    type: "infiniteOptionsWidget",
    props: {},
  },
};

import * as React from "react";
import YouTubePlayer from "react-player/youtube";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import RenderComponentPlaceholder from "../RenderComponentPlaceholder";
import { customProperties } from "./config";

const YoutubeEmbedV2: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
}) => {
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const url = component.props.url;
  const hideControls = Boolean(component.props.hideControls);
  const autoPlay = Boolean(component.props._autoplay);
  const loop = Boolean(component.props._loop);
  const defaultMuted = Boolean(component.props._defaultMuted);
  return (
    <div {...componentAttributes}>
      {url ? (
        <YouTubePlayer
          // Note (Reinaldo, 2022-04-20): For some reason, YouTubePlayer don't rerender if controls prop change
          // so we are getting around this issue by using it as a key to force a rerender
          key={`${hideControls}-${autoPlay}-${defaultMuted}`}
          controls={!hideControls}
          loop={loop}
          muted={defaultMuted}
          playing={autoPlay}
          url={url}
          width="100%"
          height={`var(${customProperties.height})`}
          style={{ aspectRatio: `var(${customProperties.aspectRatio}, auto)` }}
        />
      ) : (
        isEditorApp && (
          <RenderComponentPlaceholder title="Once you set the proper url, the YouTube video will appear here." />
        )
      )}
    </div>
  );
};

export default YoutubeEmbedV2;

import Tooltip from "@common/designSystem/Tooltip";
import * as DropdownContextMenu from "@radix-ui/react-context-menu";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import classNames from "classnames";
import * as React from "react";
import { FaChevronRight } from "react-icons/fa";
import { animated, config, useSpring } from "react-spring";
import { useControllableState } from "replo-utils/react/use-controllable-state";
import { twMerge } from "tailwind-merge";

type MenuItemLeaf = {
  type: "leaf";
  id: string;
  title: React.ReactNode;
  onSelect?(): void;
  isDisabled?: boolean;
  endEnhancer?(): React.ReactNode;
};

type MenuItemSection = { type: "section"; items: MenuItem[] };

type MenuItemNested = {
  type: "nested";
  id?: string;
  title: React.ReactNode;
  items: MenuItem[];
};

export type MenuItem = MenuItemSection | MenuItemLeaf | MenuItemNested;

type MenuType = "normal" | "context";

type MenuProps = {
  items: MenuItem[];
  isOpen?: boolean;
  onRequestOpen?(): void;
  onRequestClose?(): void;
  trigger: Exclude<React.ReactNode, undefined | null | boolean>;
  menuType?: MenuType;
  customWidth?: number | "auto";
  disableOutsideInteractionsWhileOpen?: boolean;
  align?: "end" | "center" | "start" | undefined;
  isDisabled?: boolean;
  contentClassNames?: string;
  size?: "xs" | "sm";
};

type MenuItemProps = {
  item: MenuItem;
  index?: number;
  lastIndex: number;
  menuType: "normal" | "context";
  customWidth?: number | "auto";
  size?: "xs" | "sm";
};

const MenuContext = React.createContext<{
  menuType: MenuType;
  isDisabled: boolean;
  isOpen: boolean;
} | null>(null);
MenuContext.displayName = "MenuContext";
function useMenuContext() {
  const context = React.useContext(MenuContext);
  if (!context) {
    throw new Error(
      "Menu compound components cannot be rendered outside the Menu component",
    );
  }
  return context;
}

const MenuItem = ({
  item,
  index,
  lastIndex,
  menuType,
  size,
}: MenuItemProps) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);

  const MenuComponent =
    menuType === "normal" ? DropdownMenu : DropdownContextMenu;

  const transitions = useSpring({
    opacity: openMenu ? 1 : 0,
    transform: openMenu ? `scale(${1})` : `scale(${0.9})`,
    config: config.stiff,
  });

  const changeFocus = () => setIsFocused(!isFocused);

  switch (item?.type) {
    case "leaf": {
      const {
        id,
        title,
        isDisabled = false,
        onSelect,
        endEnhancer = null,
      } = item;

      return (
        <MenuComponent.Item
          key={id.toString()}
          disabled={isDisabled}
          onClick={(e) => e.stopPropagation()}
          onSelect={onSelect}
          asChild
        >
          <div
            onFocus={changeFocus}
            onBlur={changeFocus}
            className={classNames(
              "flex h-7 select-none items-center justify-between text-default hover:bg-hover focus:outline-none rounded",
              {
                "cursor-pointer": !isDisabled,
                "cursor-not-allowed": isDisabled,
                "px-4 py-2": typeof title === "string",
              },
            )}
            data-testid={item.id}
          >
            <span
              className={classNames("grow", {
                "text-disabled": isDisabled,
                "text-xs": size === "xs",
                "text-sm": size === "sm",
              })}
            >
              {title}
            </span>
            {endEnhancer && (
              <span
                className={classNames("flex items-center", {
                  "text-default": !isDisabled,
                  "text-danger": isFocused,
                  "text-disabled": isDisabled,
                })}
              >
                {endEnhancer()}
              </span>
            )}
          </div>
        </MenuComponent.Item>
      );
    }
    case "section": {
      const { items } = item;
      const indexForNonZero = index === lastIndex ? -3 : -1;
      return (
        <>
          {index !== 0 && (
            <MenuComponent.Separator asChild>
              <hr className="my-2 border-t border-slate-200" />
            </MenuComponent.Separator>
          )}
          {items.map((sectionItem, i) => {
            return (
              <div key={i.toString()}>
                <MenuItem
                  item={sectionItem}
                  index={index === 0 ? i : indexForNonZero}
                  lastIndex={
                    index === lastIndex && i === items.length - 1 ? -3 : -2
                  }
                  menuType={menuType}
                  size={size}
                />
              </div>
            );
          })}
        </>
      );
    }
    case "nested": {
      const { items, title } = item;
      const menuDontHaveItems = items.length === 0;
      return (
        <MenuComponent.Sub open={openMenu} onOpenChange={setOpenMenu}>
          <MenuComponent.SubTrigger asChild disabled={menuDontHaveItems}>
            <div
              onFocus={changeFocus}
              onBlur={changeFocus}
              className={classNames(
                "flex h-7 select-none items-center justify-between hover:bg-hover focus:outline-none",
                {
                  "px-4 py-2": typeof title === "string",
                  "text-default": !menuDontHaveItems,
                  "text-disabled cursor-not-allowed": menuDontHaveItems,
                },
              )}
            >
              <span
                className={classNames({
                  "text-xs": size === "xs",
                  "text-sm": size === "sm",
                })}
              >
                {title}
              </span>
              <FaChevronRight className="text-subtle" size={8} />
            </div>
          </MenuComponent.SubTrigger>

          <MenuComponent.Portal>
            <MenuComponent.SubContent asChild sideOffset={4}>
              <animated.div
                style={transitions}
                className="rounded bg-white shadow p-1"
              >
                {items.map((nestedItem, i) => {
                  return (
                    <div key={i.toString()}>
                      <MenuItem
                        item={nestedItem}
                        index={i}
                        lastIndex={items.length - 1}
                        menuType={menuType}
                        size={size}
                      />
                    </div>
                  );
                })}
              </animated.div>
            </MenuComponent.SubContent>
          </MenuComponent.Portal>
        </MenuComponent.Sub>
      );
    }
    default:
      return null;
  }
};

export function Menu({
  items,
  isOpen: isOpenProp,
  onRequestClose,
  onRequestOpen,
  menuType = "normal",
  customWidth,
  disableOutsideInteractionsWhileOpen,
  align,
  isDisabled = false,
  contentClassNames,
  trigger,
  size = "xs",
}: MenuProps) {
  const [isOpen, setIsOpen] = useControllableState(
    isOpenProp,
    false,
    onOpenChange,
  );
  const transitions = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? `scale(${1})` : `scale(${0.9})`,
    config: config.stiff,
  });

  const MenuComponent =
    menuType === "normal" ? DropdownMenu : DropdownContextMenu;

  function onInteractOutside() {
    if (menuType === "context") {
      onRequestClose?.();
      return;
    }
    if (isOpenProp === undefined) {
      setIsOpen(!isOpen);
    }
    onRequestClose?.();
  }

  function onOpenChange(open: boolean) {
    if (open) {
      onRequestOpen?.();
    } else if (!open) {
      onRequestClose?.();
    }
  }

  const menuAlign = align || (customWidth ? "end" : "center");

  if (!items || items.length === 0) {
    return trigger;
  }

  return (
    <MenuComponent.Root
      open={isDisabled ? false : isOpen}
      onOpenChange={setIsOpen}
      modal={disableOutsideInteractionsWhileOpen}
    >
      <MenuContext.Provider value={{ menuType, isDisabled, isOpen }}>
        {!React.isValidElement(trigger) || trigger.type !== MenuTrigger ? (
          <MenuTrigger>{trigger}</MenuTrigger>
        ) : (
          trigger
        )}
        <MenuComponent.Portal>
          <MenuComponent.Content
            asChild
            onInteractOutside={onInteractOutside}
            className={classNames([
              {
                "w-[12.5rem]": menuType === "context" && !customWidth,
              },
              contentClassNames,
            ])}
            style={{ width: customWidth }}
            align={menuAlign}
            alignOffset={customWidth ? -8 : 0}
            sideOffset={customWidth ? 8 : 0}
          >
            <animated.div
              style={transitions}
              className="rounded bg-white shadow p-1"
            >
              {items.map((item, index) => {
                return (
                  <div key={index.toString()}>
                    <MenuItem
                      item={item}
                      index={index}
                      lastIndex={items.length - 1}
                      menuType={menuType}
                      customWidth={customWidth}
                      size={size}
                    />
                  </div>
                );
              })}
            </animated.div>
          </MenuComponent.Content>
        </MenuComponent.Portal>
      </MenuContext.Provider>
    </MenuComponent.Root>
  );
}

export const MenuTrigger = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<{
    className?: string;
    style?: React.CSSProperties;
    asChild?: boolean;
    tooltipText?: string;
  }>
>(function MenuTrigger(
  { className, style, asChild, children, tooltipText, ...props },
  ref,
) {
  const { menuType, isDisabled } = useMenuContext();
  const MenuComponent =
    menuType === "normal" ? DropdownMenu : DropdownContextMenu;
  const trigger = (
    <MenuComponent.Trigger
      ref={ref}
      className={twMerge(
        classNames("rounded outline-1 outline-blue-600", {
          "cursor-rounded": menuType !== "context" && !isDisabled,
          "cursor-default": menuType !== "context" && isDisabled,
        }),
        className,
      )}
      style={style}
      asChild={asChild}
      {...props}
    >
      {children}
    </MenuComponent.Trigger>
  );
  if (tooltipText) {
    return (
      <Tooltip content={tooltipText} triggerAsChild>
        {trigger}
      </Tooltip>
    );
  }
  return trigger;
});

import { Button } from "@replo/design-system/components/button";
import * as React from "react";
import { IoDocumentText } from "react-icons/io5";

type NoDataProps = {
  resetAll: () => void;
};

export const NoData: React.FC<NoDataProps> = ({ resetAll }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <IoDocumentText className="text-gray-200 text-4xl" />
      <div className="flex flex-col items-center justify-center gap-1">
        <div className="text-default text-base font-medium">
          No matching pages
        </div>
        <div className="text-slate-500 text-sm font-medium">
          Try adjusting your filters or search term
        </div>
        <Button
          variant="no-style"
          className="text-blue-600 text-sm font-semibold"
          onClick={resetAll}
        >
          Reset all
        </Button>
      </div>
    </div>
  );
};

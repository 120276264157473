import { trpc } from "@editor/utils/trpc";
import { skipToken } from "@tanstack/react-query";

const useWorkspaceUrlHosts = (
  workspaceId: string | null,
): {
  data: {
    value: string;
    label: string;
  }[];
  isLoading: boolean;
} => {
  const { data, isLoading } = trpc.analytics.getUrlHosts.useQuery(
    workspaceId ? { workspaceId } : skipToken,
  );

  // NOTE (Kurt, 2024-09-10): As of this writing, most of our customers have a primary domain configured,
  // which is different from the default `myshopify.com` URL. For these customers, we know that the
  // `myshopify.com` domain will never show any metric data since we will always associate with the primary
  // domain. At this point, we filter out the `myshopify.com` domain from the list of URL hosts.
  const filteredNoMyShopifyUrlHosts = data?.filter(
    (host) => !host.includes("myshopify.com"),
  );

  const transformedData = filteredNoMyShopifyUrlHosts?.map((host) => ({
    value: host,
    label: host,
  }));

  return { data: transformedData ?? [], isLoading };
};

export default useWorkspaceUrlHosts;

import * as React from "react";

import { usePreloadImagesFromDataTable } from "../../../shared/hooks/usePreloadImagesFromDataTable";
import {
  DataTablesContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { mergeContext } from "../../../shared/utils/context";
import { getRowObjectsFromDataTable } from "../../AlchemyDataTable";
import { ReploComponent } from "../ReploComponent";

const CollectionSelect = (props: RenderComponentProps) => {
  const itemsConfig = props.component.props.items;
  const { mapping: dataTableMapping } = useRuntimeContext(DataTablesContext);
  const {
    products,
    activeCurrency: currencyCode,
    moneyFormat,
    activeLanguage: language,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  let items =
    getRowObjectsFromDataTable(itemsConfig?.id, dataTableMapping, {
      products,
      currencyCode,
      moneyFormat,
      language,
      templateProduct,
    }) || [];
  usePreloadImagesFromDataTable(itemsConfig?.id);

  if (props.component.props.filter) {
    for (const statement of props.component.props.filter.statements) {
      items = items.filter((item: Record<string, any>) => {
        let getValue: () => any = () => null;
        if (statement.type === "dropdown") {
          getValue = () =>
            props.context.dropdownValues[statement.value.dropdownId]?.item?.[
              statement.value.dataTableColumn
            ];
        }
        return item[statement.columnName] === getValue();
      });
    }
  }

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const currentItem = items && items.length > 0 ? items[currentIndex] : null;

  const newContext = mergeContext(props.context, {
    state: {
      collectionSelect: {
        currentIndex: currentIndex,
        currentSelection: currentItem,
      },
    },
    attributes: {
      _currentSelection: currentItem,
    },
    variantTriggers: {
      "collectionSelect.item": true,
    },
    actionHooks: {
      setCurrentCollectionSelection: (index: number) => {
        setCurrentIndex(index);
      },
    },
  });

  return (
    <div {...props.componentAttributes}>
      {props.component.children?.map((child) => {
        return (
          <ReploComponent
            {...props}
            key={child.id}
            component={child}
            context={newContext}
            repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
          />
        );
      })}
    </div>
  );
};

export default CollectionSelect;

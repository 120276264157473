import type { Component } from "replo-runtime/shared/Component";
import { v4 as uuidv4 } from "uuid";

export const getNewTabPanel = (id: string) => {
  const template: Component = {
    id,
    type: "tabs__onePanelContent",
    props: {
      style: {
        display: "flex",
        flexDirection: "column",
      },
    },
    children: [
      {
        id: uuidv4(),
        type: "container",
        props: { style: { display: "flex", flexDirection: "column" } },
        children: [],
      },
    ],
  };
  return template;
};

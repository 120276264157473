import { useWorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import useCurrentUser from "@hooks/useCurrentUser";
import useUserProjects from "@hooks/useUserProjects";
import { useNavigate, useSearchParams } from "react-router-dom";
import type { Flow } from "schemas/flow";

export const useRouterFlowCallbacks = () => {
  const { data } = useUserProjects();
  const projects = data?.allProjects ?? [];
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setWorkspaceId } = useWorkspaceDashboardContext();
  // NOTE (Fran 2024-02-21): Used to get the projectId owned by this user created in step four
  // (are you using solo or team) of the onboarding flow.
  const { user } = useCurrentUser();

  const handleResponseNavigation = ({
    nextStep,
    flowSlug,
  }: {
    nextStep: string | null;
    flowSlug: Flow["slug"];
  }) => {
    if (nextStep) {
      navigate({
        pathname: `/flows/${flowSlug}/${nextStep}`,
        search: searchParams.toString(),
      });
    } else {
      if (searchParams.get("pendingAppInstallationId")) {
        navigate({
          pathname: "/project/new",
          search: searchParams.toString(),
        });
      } else {
        // NOTE (Sebas, 2024-03-04): We need to set the workspace id when the user finishes the
        // onboarding flow to prevent the sidebar from showing empty data.
        if (user?.workspace?.id) {
          setWorkspaceId(user.workspace.id);
        }

        const firstProjectIdFromLocalStorage =
          localStorage.getItem("firstProjectId");
        if (firstProjectIdFromLocalStorage) {
          localStorage.removeItem("firstProjectId");
          navigate(`/editor/${firstProjectIdFromLocalStorage}`);
        } else {
          // NOTE (Fran 2024-02-22): If the user abandons the flow and returns later,
          // the browser's local storage is likely cleaned up. In that case, we should find the
          // first project created owned by the user and redirect to it.
          const firstProjectId =
            projects.find(
              (project) =>
                project.name?.includes("First Project") &&
                project.ownerId === user?.id,
            )?.id ?? projects[0]?.id;

          if (firstProjectId) {
            navigate(`/editor/${firstProjectId}`);
          } else {
            navigate("/home");
          }
        }
      }
    }
  };

  const submitOrSkipStepCallback = ({
    nextStep,
    flowSlug,
  }: {
    nextStep: string | null;
    flowSlug: Flow["slug"];
  }) => {
    handleResponseNavigation({ nextStep, flowSlug });
  };

  const goBackCallback = (
    currentFlowSlug: string | null,
    previousStepId: string,
  ) => {
    navigate({
      pathname: `/flows/${currentFlowSlug}/${previousStepId}`,
      search: searchParams.toString(),
    });
  };

  return { submitOrSkipStepCallback, goBackCallback };
};

import type { Component } from "replo-runtime/shared/Component";
import { mediaSizeStyles } from "replo-runtime/shared/utils/breakpoints";

/**
 * Determines if the given component is completely hidden in all media sizes.
 *
 * @param component The component to check for hidden state.
 * @returns True if the component is hidden in all media sizes, false otherwise.
 */
export const isCompletelyHiddenComponent = (component: Component): boolean => {
  const isHiddenInMediaSize = (mediaSize: string) => {
    const styles = component?.props?.[mediaSize] as Record<string, any>;
    return styles?.display === "none";
  };
  return mediaSizeStyles.every(isHiddenInMediaSize);
};

import type { ButtonSharedProps } from "@common/designSystem/Button";
import Button from "@common/designSystem/Button";
import { SimpleSkeletonLoader } from "@editor/components/common/designSystem/SkeletonLoader";
import ReploAreaChart from "@replo/design-system/components/shadcn/AreaChart";
import ReploPieChart from "@replo/design-system/components/shadcn/PieChart";
import * as React from "react";
import { BsGraphUp } from "react-icons/bs";

import PagesOverview from "@/features/analytics/PagesOverview";

type SetupPageProps = {
  onEnable: () => void;
  isEnabling: boolean;
};

export const SetupPage: React.FC<SetupPageProps> = ({
  onEnable,
  isEnabling,
}) => {
  const commonProps: ButtonSharedProps = {
    size: "base",
    type: "primary",
    textClassNames: "font-normal",
    className: "bg-[#2563EB] px-14",
    isDisabled: isEnabling,
  };

  return (
    <div className="max-h-[80vh]">
      <div className="absolute inset-0 z-10 w-full bg-white bg-opacity-[0.90]">
        <div className="flex h-[70%] w-full justify-center items-center">
          <div className="flex flex-col space-y-6 items-center">
            <BsGraphUp className="h-10 w-10 text-black" />
            <div className="text-base font-semibold max-w-[400px] text-center">
              Setup Analytics to get insights on your top converting pages.{" "}
              <span className="text-blue-600">Learn more</span>
            </div>
            <Button {...commonProps} onClick={onEnable}>
              Set up Analytics
            </Button>
          </div>
        </div>
      </div>
      <div className="relative max-w-xl xl:max-w-7xl pr-5">
        <div className="flex w-full flex-col">
          <div className="flex flex-row space-x-4">
            <SimpleSkeletonLoader height="14" width="160" />
            <SimpleSkeletonLoader height="14" width="160" />
            <SimpleSkeletonLoader height="14" width="160" />
          </div>
          <div className="text-2xl font-semibold my-8">
            <SimpleSkeletonLoader height="14" width="141" />
          </div>
          <div className="flex gap-5 flex-col min-[950px]:flex-row">
            <ReploAreaChart
              title={<SimpleSkeletonLoader width="141" height="10" />}
            />
            <ReploPieChart
              skeleton={<SimpleSkeletonLoader width="141" height="10" />}
            />
          </div>
          <PagesOverview skeleton={true} />
        </div>
      </div>
    </div>
  );
};

import { convertDaysToMs } from "replo-utils/lib/datetime";
import type {
  AnalyticsReadQuery,
  FilterCondition,
  MetricName,
} from "schemas/analyticsRead";
import { ConditionOperatorEnum } from "schemas/analyticsRead";

export const RESULTS_PER_PAGE = 25;

export const DEFAULT_SORT_METRIC: MetricName = "unique_sessions";
export const DEFAULT_QUERY_RESULT_LIMIT = RESULTS_PER_PAGE;
export const DEFAULT_QUERY_RESULT_OFFSET = 0;
export const DEFAULT_ORDER: AnalyticsReadQuery["order"] = "DESC";

export const DEFAULT_URL_HOST = "ALL_URLS";

const DEFAULT_METRICS: AnalyticsReadQuery["metrics"] = [
  "conversions",
  "views",
  "conversion_rates",
  "unique_sessions",
  "revenue",
  "revenue_per_session",
  "average_order_value",
];

export const DEFAULT_FILTERS = {
  urlPath: [
    {
      operator: ConditionOperatorEnum.DOES_NOT_CONTAIN,
      value: "/checkouts/",
    },
    {
      operator: ConditionOperatorEnum.DOES_NOT_CONTAIN,
      value: "/orders/",
    },
    {
      operator: ConditionOperatorEnum.DOES_NOT_CONTAIN,
      value: "/tools/recurring/portal/",
    },
    {
      operator: ConditionOperatorEnum.DOES_NOT_CONTAIN,
      value: "/account/",
    },
  ] as FilterCondition[],
  device: [],
  utm_name: [],
};

/**
 * TODO (Max, 2024-09-12): The currentDatetime won't get updated if the query's range
 * doesn't change. We need to make sure now() updates on every API call.
 * https://linear.app/replo/issue/REPL-13573
 */
const currentDatetime = Date.now();

const rangeLastSevenDays = {
  id: crypto.randomUUID(),
  startDatetime: currentDatetime - convertDaysToMs(7),
  endDatetime: currentDatetime,
  interval: convertDaysToMs(7),
};

const rangeLastSevenDaysCompareAt = {
  id: crypto.randomUUID(),
  startDatetime: currentDatetime - convertDaysToMs(14),
  endDatetime: currentDatetime - convertDaysToMs(7),
  interval: convertDaysToMs(7),
};

export const DEFAULT_QUERY: Omit<AnalyticsReadQuery, "urlHosts"> = {
  ranges: {
    mainRange: rangeLastSevenDays,
    compareAtRanges: [rangeLastSevenDaysCompareAt],
  },
  order: DEFAULT_ORDER,
  sortMetric: DEFAULT_SORT_METRIC,
  metrics: DEFAULT_METRICS,
  offset: DEFAULT_QUERY_RESULT_OFFSET,
  limit: DEFAULT_QUERY_RESULT_LIMIT,
  filters: DEFAULT_FILTERS,
};

export const METRICS_REQUIRING_PERCENTAGE = ["conversion_rates"];
export const METRICS_REQUIRING_CURRENCY = [
  "revenue",
  "revenue_per_session",
  "average_order_value",
];

export const DEFAULT_CURRENCY = "$";

export const PAGE_NAME_KEY = "page_name";

export const TABLE_METRICS: {
  label: string;
  key: MetricName;
}[] = [
  { label: "Sessions", key: "unique_sessions" },
  { label: "Conversions", key: "conversions" },
  { label: "Revenue", key: "revenue" },
  { label: "Conversion Rate", key: "conversion_rates" },
  { label: "AOV", key: "average_order_value" },
  { label: "Revenue per Session", key: "revenue_per_session" },
];

export const METRIC_SORT_TOOLTIP_TEXT: Record<
  AnalyticsReadQuery["sortMetric"],
  string
> = {
  conversion_rates: "Sort table by Conversion Rate",
  average_order_value: "Sort table by AOV",
  revenue_per_session: "Sort table by Revenue/Session",
  unique_sessions: "Sort table by Unique Sessions",
  conversions: "Sort table by Conversions",
  revenue: "Sort table by Revenue",
  views: "Sort table by Views",
};

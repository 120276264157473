import { useRichTextComponent } from "@editor/components/RichTextComponentContext";
import {
  useApplyComponentAction,
  type UseApplyComponentActionType,
} from "@editor/hooks/useApplyComponentAction";
import {
  selectAncestorTextColor,
  selectColor,
  selectColorGradientStops,
  selectColorGradientTilt,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { isAllTextColored } from "@editor/utils/rte";
import ModifierGroup from "@editorExtras/ModifierGroup";
import DynamicColorModifier from "@editorModifiers/DynamicColorModifier";
import * as React from "react";
import type { GradientStop, SolidOrGradient } from "replo-runtime/shared/types";
import { coerceNumberToString, isNotNullish } from "replo-utils/lib/misc";

export const ForegroundColorModifier: React.FC<
  React.PropsWithChildren<{
    allowsGradientSelection: boolean;
  }>
> = ({ allowsGradientSelection }) => {
  // NOTE (Fran 2024-04-15):  Given the text color inheritance, we need to show if the component
  // has a color or if any ancestor text color will apply.
  const ancestorTextColor = useEditorSelector(selectAncestorTextColor);
  const color = useEditorSelector(selectColor);
  const colorGradientTilt = useEditorSelector(selectColorGradientTilt);
  const colorGradientStops = useEditorSelector(
    selectColorGradientStops,
  ) as GradientStop[];

  const applyComponentAction = useApplyComponentAction();

  // if there is a instance of the tipTapEditor being used by the rich text
  // control we must use the same one and queue our changes
  const { tipTapEditor, queueAction } = useRichTextComponent();

  const changeColor = (newValue: SolidOrGradient) => {
    let gradientOrSolid = null;
    if (allowsGradientSelection) {
      if (newValue.type === "solid") {
        gradientOrSolid = newValue.color;
      } else {
        gradientOrSolid = newValue.gradient;
      }
    } else if (typeof newValue === "string") {
      gradientOrSolid = newValue;
    }
    const newStyleValue =
      gradientOrSolid && typeof gradientOrSolid === "object"
        ? {
            color: "alchemy:gradient",
            __alchemyGradient__color__tilt: gradientOrSolid?.tilt,
            __alchemyGradient__color__stops: gradientOrSolid?.stops,
          }
        : { color: gradientOrSolid };
    const action: UseApplyComponentActionType = {
      type: "setStyles",
      value: newStyleValue,
    };
    // If all text in the editor already has a color then we've got to unset it
    // in order for the changes to have an effect.
    if (tipTapEditor && isAllTextColored(tipTapEditor)) {
      queueAction(action);
      tipTapEditor?.chain().selectAll().unsetColor().run();
    } else {
      applyComponentAction(action);
    }
  };

  const modifierValue = color ?? ancestorTextColor;

  return (
    <ModifierGroup
      title="Text Color"
      isDefaultOpen={isNotNullish(modifierValue)}
    >
      <DynamicColorModifier
        previewProperty="color"
        popoverTitle="Text Color"
        gradientSelectionType={allowsGradientSelection ? "color" : null}
        gradientData={{
          tilt: coerceNumberToString(colorGradientTilt) ?? "90deg",
          stops: colorGradientStops ?? [
            {
              id: "c7795a8c-4e13-4011-889b-64adb0e11e41",
              color: "#df9393",
              location: "0%",
            },
          ],
        }}
        field="style.color"
        value={modifierValue ?? undefined}
        onChange={changeColor}
        onRemove={() => {
          // NOTE (Fran 2024-05-09): If the color is not set we will show the inherited color from any
          // ancestor, so in this case, we should set the color to transparent. If the color is set, we
          // should remove it and show the inherited color.
          const onRemoveNewColor = color ? null : "#00000000";
          changeColor({
            type: "solid",
            color: onRemoveNewColor,
          });
        }}
      />
    </ModifierGroup>
  );
};

import type { ComponentTemplate } from "@editor/types/component-template";
import { getCanBeAddedFunction } from "@editor/utils/template";
import { Env } from "replo-runtime/shared/enums";

const description: ComponentTemplate = {
  id: "02610fce-010c-4550-be0f-9bb064d96d21",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Product Description",
  leftBarCategory: { main: "product", subCategory: "productData" },
  preview: "product/description.svg",
  template: {
    id: "6cd87cb4-c69b-4700-b4a6-53b39a686b6c",
    name: "Description",
    type: "text",
    props: {
      text: "{{attributes._product.description}}",
      style: { fontSize: "20px", lineHeight: "24px" },
    },
  },
  canBeAddedAsChild: getCanBeAddedFunction(
    "product",
    "Product Descriptions can only be added inside Product boxes.",
  ),
};

export default description;

import ColorSwatch from "@common/designSystem/ColorSwatch";
import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import { Input, useOverridableInput } from "@common/designSystem/Input";
import Tooltip from "@common/designSystem/Tooltip";
import classNames from "classnames";
import * as React from "react";
import { BsX } from "react-icons/bs";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { twMerge } from "tailwind-merge";

type InlineAssetSelectorProps = {
  asset?: { type: "video"; src: string } | { type: "image"; src: string };
  emptyTitle?: string;
  onClickSelectAsset?(): void;
  onRemoveAsset?(): void;
  allowRemoveAsset?: boolean;
  allowsDynamicData?: boolean;
  onClickDynamicData?(): void;
  swatchTooltip?: string;
  alwaysShowRemoveButton?: boolean;
  onInputChange?(url: string): void;
  isDisabled?: boolean;
  swatchId?: string;
  inputName?: string;
  autocomplete?: "on" | "off";
  wrapperClassnames?: string;
  inputPlaceholder?: string;
};

const InlineAssetSelector = ({
  asset,
  emptyTitle,
  onClickSelectAsset,
  onRemoveAsset,
  allowsDynamicData = false,
  allowRemoveAsset = true,
  onClickDynamicData,
  swatchTooltip,
  alwaysShowRemoveButton,
  onInputChange,
  isDisabled,
  swatchId,
  inputName,
  autocomplete = "off",
  wrapperClassnames,
  inputPlaceholder,
}: InlineAssetSelectorProps) => {
  function _getTitle() {
    return asset?.src ?? emptyTitle;
  }

  const valueIsDynamic = isDynamicDataValue(asset?.src);
  const showInputField = !valueIsDynamic && Boolean(onInputChange);
  const srcInputProps = useOverridableInput({
    value: asset?.src ?? "",
    onValueChange: onInputChange,
  });
  const showRemoveButton =
    onRemoveAsset &&
    ((asset?.src && allowRemoveAsset) || alwaysShowRemoveButton);

  return (
    <div className={twMerge("flex", wrapperClassnames)}>
      <Tooltip content={isDisabled ? swatchTooltip : null} triggerAsChild>
        {
          // Note (Mariano, 2022-08-31): We need to add this button with the type in it because we are using
          // this AssetSelector in some components that have a form with a onSubmit event, and by default
          // every default button will trigger that event: https://stackoverflow.com/questions/2825856/html-button-to-not-submit-form
        }
        <button
          type="button"
          data-testid="select-asset"
          disabled={isDisabled}
          className={classNames({ "cursor-default": !onClickSelectAsset })}
          onClick={isDisabled ? undefined : onClickSelectAsset}
        >
          <ColorSwatch
            id={swatchId}
            value={asset}
            onOptionClick={isDisabled ? undefined : onRemoveAsset}
          />
        </button>
      </Tooltip>
      <div className="flex w-full flex-row">
        <div
          className={classNames(
            "flex w-full items-center justify-between rounded bg-subtle",
            allowsDynamicData ? "mx-2" : "ml-2",
          )}
        >
          {showInputField && (
            <Input
              {...srcInputProps}
              shouldSelectTextOnFocus
              placeholder={inputPlaceholder ?? "Source"}
              onOptionClick={onRemoveAsset}
              isDisabled={isDisabled}
              name={inputName}
              autoComplete={autocomplete}
              endEnhancer={() =>
                showRemoveButton && (
                  <RemoveAssetButton onRemoveAsset={onRemoveAsset} />
                )
              }
            />
          )}
          {!showInputField && (
            <span
              className={classNames("cursor-pointer truncate text-xs", {
                "text-default": asset,
                "text-slate-400": !asset,
              })}
              onClick={() => onClickSelectAsset?.()}
            >
              {_getTitle()}
            </span>
          )}
          {!showInputField && showRemoveButton && (
            <RemoveAssetButton onRemoveAsset={onRemoveAsset} />
          )}
        </div>
        {allowsDynamicData && (
          <div className="flex">
            <DynamicDataButton onClick={() => onClickDynamicData?.()} />
          </div>
        )}
      </div>
    </div>
  );
};

const RemoveAssetButton: React.FC<{ onRemoveAsset(): void }> = ({
  onRemoveAsset,
}) => {
  return (
    <div
      className="h-4 w-4 cursor-pointer text-subtle flex items-center justify-center"
      onClick={() => onRemoveAsset()}
    >
      <BsX size={12} />
    </div>
  );
};

export default InlineAssetSelector;

import ReploLogoBadge from "@svg/logo-badge";
import * as React from "react";

interface LogoProps {
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  "aria-hidden"?: boolean;
}

const LogoBadge: React.FC<LogoProps> = (props) => {
  return <ReploLogoBadge {...props} />;
};

export default LogoBadge;

import type { SelectableOption } from "@editor/components/common/designSystem/Selectable";
import Selectable from "@editor/components/common/designSystem/Selectable";
import * as React from "react";
import { BsGlobe2 } from "react-icons/bs";

const ShopifyURL: React.FC = () => {
  const fakeShopifyUrls = [
    "https://www.intro-kurt.myshopify.com",
    "https://www.intro-kurt-1.myshopify.com/",
    "https://www.intro-kurt-2.myshopify.com/",
  ];

  const fakeShopifyUrlsOptions: SelectableOption[] = fakeShopifyUrls.map(
    (url) => ({
      value: url,
      label: url,
    }),
  );

  return (
    <Selectable
      options={fakeShopifyUrlsOptions}
      defaultValue={fakeShopifyUrls[0]}
      onSelect={(value) => value}
      startEnhancer={() => <BsGlobe2 className="h-4 w-4" />}
      labelClassName="mr-1"
      className="h-[32px] w-auto"
    />
  );
};

export default ShopifyURL;

import { selectProjectId } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";
import { skipToken } from "@tanstack/react-query";
import type { BillingTier } from "schemas/billing";
import { billingPlansByTier, BillingTiers } from "schemas/billing";

/**
 * get the current projects active subscription details (name, paymentProcessor,
 * startDate, monthlyAmount id, etc.)
 */
export function useSubscriptionDetails(workspaceId?: string) {
  const projectId = useEditorSelector(selectProjectId);
  const workspaceActiveSubscription =
    trpc.subscriptions.getActiveSubscriptionByWorkspace.useQuery(
      workspaceId ?? skipToken,
    );
  const projectActiveSubscription =
    trpc.subscriptions.getActiveSubscriptionByProject.useQuery(
      projectId ?? skipToken,
    );
  if (workspaceId) {
    return workspaceActiveSubscription;
  }
  return projectActiveSubscription;
}

/**
 * gets the current projects active subscription info (CTA, description,
 * features, plan limits etc.)
 */
export function useSubscriptionInfo(workspaceId?: string) {
  const { data: subscriptionDetails, ...rest } =
    useSubscriptionDetails(workspaceId);
  const activeSubscriptionTier: BillingTier =
    subscriptionDetails?.name ?? BillingTiers.FREE;
  return {
    ...rest,
    subscriptionInfo: billingPlansByTier[activeSubscriptionTier],
  };
}

export function useSubscriptionTier(): BillingTier {
  const { subscriptionInfo } = useSubscriptionInfo();
  return subscriptionInfo.tier ?? "free";
}

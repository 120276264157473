import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { H } from "highlight.run";
import { getConfig } from "replo-runtime/shared/config";
import type { User } from "replo-runtime/shared/types";

export const initializeSessionTracking = () => {
  if (
    !getConfig("shouldLogAnalytics", false) ||
    !isFeatureEnabled("use-highlight")
  ) {
    return;
  }

  H.init("ve6jnwgp", {
    tracingOrigins: true,
    // Note (Noah, 2023-06-22): Disable console recording for debug, since when
    // we turn the redux logging to console debug flag on, it makes the app crash lol
    consoleMethodsToRecord: ["warn", "error"],
  });
};

export const identifySessionTrackingUser = (user: User) => {
  if (
    !getConfig("shouldLogAnalytics", false) ||
    !isFeatureEnabled("use-highlight")
  ) {
    return;
  }

  H.identify(user.email, {
    displayName: user.name ?? "Anonymous",
    email: user.email,
  });
};

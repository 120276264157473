import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import {
  selectDraftComponentContext,
  selectDraftComponentId,
  selectImageSource,
  selectImageSourceComponentProps,
  selectObjectFit,
  selectObjectPosition,
  selectPropLoading,
  selectPropSrc,
} from "@editor/reducers/core-reducer";
import { selectEditorMediaUploadComponentIds } from "@editor/reducers/editor-media-upload-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import type { ObjectFitType } from "@editor/types/object-fit";
import { getImageSourceComponentActions } from "@editor/utils/getImageSourceComponentActions";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import ImageSourceSelector from "@editorComponents/ImageSourceSelector";
import ModifierGroup from "@editorExtras/ModifierGroup";
import * as React from "react";
import type { AssetLoadingType } from "replo-runtime/shared/asset-loading";
import { coerceNumberToString } from "replo-utils/lib/misc";
import { isValidHttpUrl } from "replo-utils/lib/url";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";

const ImageSourceModifier = () => {
  const store = useEditorStore();
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const draftComponentContext = useEditorSelector(selectDraftComponentContext);
  const objectFit = useEditorSelector(selectObjectFit);
  const imageSource = useEditorSelector(selectImageSource);
  const rawObjectPosition = useEditorSelector(selectObjectPosition);
  const propSrc = useEditorSelector(selectPropSrc);
  const propLoading = useEditorSelector(selectPropLoading);
  const editorMediaUploadComponentIds = useEditorSelector(
    selectEditorMediaUploadComponentIds,
  );
  const applyComponentAction = useApplyComponentAction();

  if (!draftComponentId) {
    return null;
  }

  const shouldOpenModifierWhenEmpty =
    !editorMediaUploadComponentIds.includes(draftComponentId);

  const src = imageSource || propSrc;
  const isValidSrc = isImageSourceValid(src);

  const loading = propLoading ?? "eager";
  const objectPosition = coerceNumberToString(rawObjectPosition)?.split(" ");

  const onChangeImageSource = (value: string) => {
    const { imageComponentId, imageSourceProp, imageUrl } =
      selectImageSourceComponentProps(store.getState(), value);
    const actions = getImageSourceComponentActions({
      canvas: activeCanvas,
      draftComponentId,
      imageComponentId,
      imageSourceProp,
      imageUrl,
    });

    applyComponentAction({
      type: "applyCompositeAction",
      value: actions,
    });
  };

  return (
    <div data-testid="image-source-modifier" className="flex flex-col">
      <ModifierGroup title="Image Source">
        <ImageSourceSelector
          src={src}
          objectFitValue={
            // Note (Noah, 2024-03-06): Cast is fine here since we never allow selecting any
            // object fit value other than those in ObjectFitType
            (objectFit ??
              styleAttributeToEditorData.objectFit
                .defaultValue) as ObjectFitType
          }
          assetLoadingValue={loading}
          objectPositionValues={{
            x: objectPosition?.[0],
            y: objectPosition?.[1],
          }}
          componentContext={draftComponentContext ?? undefined}
          onChangeImageSource={onChangeImageSource}
          onChangeObjectFit={(value: string) => {
            applyComponentAction({
              type: "setStyles",
              value: {
                objectFit: value,
              },
            });
          }}
          onChangeAssetLoading={(value: AssetLoadingType) => {
            applyComponentAction({
              type: "setProps",
              value: {
                loading: value,
              },
            });
          }}
          onChangeObjectPositionX={(value: string) => {
            applyComponentAction({
              type: "setStyles",
              value: {
                objectPosition: `${value} ${objectPosition?.[1] ?? "center"}`,
              },
            });
          }}
          onChangeObjectPositionY={(value: string) => {
            applyComponentAction({
              type: "setStyles",
              value: {
                objectPosition: `${objectPosition?.[0] ?? "center"} ${value}`,
              },
            });
          }}
          onRemove={() => {
            applyComponentAction({
              type: "applyCompositeAction",
              value: [
                {
                  type: "setStyles",
                  value: { __imageSource: null },
                },
                {
                  type: "deleteProps",
                  propName: "src",
                  analyticsExtras: {
                    actionType: "edit",
                    createdBy: "replo",
                  },
                },
              ],
            });
          }}
          componentId={draftComponentId ?? undefined}
          openPopoverWhenEmpty={shouldOpenModifierWhenEmpty}
        />
        {!isValidSrc ? (
          <ErrorMessage
            error="Please enter a valid image source"
            className="mt-1"
          />
        ) : null}
      </ModifierGroup>
    </div>
  );
};

const isImageSourceValid = (src: string | null) => {
  // NOTE (Sebas, 2024-04-30): If the value is null, we should consider it as a valid src
  // to avoid showing the error message.
  if (!src || src.startsWith("data:image") || src.startsWith("{{attributes")) {
    return true;
  }
  return isValidHttpUrl(src);
};

export default ImageSourceModifier;

import type {
  Component,
  ProductMetafieldMapping,
  VariantMetafieldMapping,
} from "replo-runtime/shared/types";
import {
  reploComponentSchema,
  reploComponentSchemaBase,
} from "schemas/component";
import { nullishToOptional } from "schemas/utils";
import { z } from "zod";

const reploElementVersionKindSchema = z.enum([
  "revert",
  "publish",
  "automatic",
  "automaticElementOutdated",
]);

export type ReploElementVersionKind =
  | "revert"
  | "publish"
  | "automatic"
  | "automaticElementOutdated";

export const ReploElementVersionKinds = {
  revert: "revert",
  publish: "publish",
  automatic: "automatic",
  automaticElementOutdated: "automaticElementOutdated",
} satisfies Record<ReploElementVersionKind, ReploElementVersionKind>;

export const metafieldTypes = [
  "boolean",
  "collection_reference",
  "color",
  "date",
  "date_time",
  "dimension",
  "file_reference",
  "json",
  "money",
  "multi_line_text_field",
  "number_decimal",
  "number_integer",
  "page_reference",
  "product_reference",
  "rating",
  "single_line_text_field",
  "url",
  "variant_reference",
  "volume",
  "weight",
  "rich_text_field",
] as const;

const reploMetafieldCommonSchema = z.object({
  key: z.string(),
  namespace: z.string(),
  shopifyId: z.string().optional(),
});

const reploIntegerMetafieldSchema = reploMetafieldCommonSchema.extend({
  type: z.literal("number_integer"),
  value: z.number(),
});

const reploStringMetafieldSchema = reploMetafieldCommonSchema.extend({
  type: z.literal("string"),
  value: z.string(),
});

const reploBooleanMetafieldSchema = reploMetafieldCommonSchema.extend({
  type: z.literal("boolean"),
  value: z.boolean(),
});

export const reploMetafieldSchema = z.union([
  reploIntegerMetafieldSchema,
  reploStringMetafieldSchema,
  reploBooleanMetafieldSchema,
]);

// TODO (Martin, 2023-08-18): Get rid of this one in favor of
// reploMetafieldSchema once we are sure that it covers all the
// possible types, see more in USE-352
// Follow-up: REPL-8283
export const reploGenericMetafieldSchema = z.object({
  shopifyId: z.string().optional(),
  type: z.string(),
  namespace: z.string(),
  value: z.union([z.string(), z.number(), z.boolean()]),
  key: z.string(),
});

export type ReploGenericMetafield = {
  shopifyId?: string;
  type: string;
  namespace: string;
  value: string | number | boolean;
  key: string;
};

export const reploGenericExistingMetafieldSchema =
  reploGenericMetafieldSchema.required({ shopifyId: true });

export type ReploExistingGenericMetafield = {
  shopifyId: string;
  type: string;
  namespace: string;
  value: string | number | boolean;
  key: string;
};

export const dataTableSchema = z.object({
  id: z.string(),
  name: z.string(),
  singularItemName: z.string().nullable(),
  pluralItemName: z.string().nullable(),
  data: z.object({
    schema: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        type: z.enum([
          "text",
          "color",
          "image",
          "imageArray",
          "product",
          "plainText",
        ]),
      }),
    ),
    rows: z.array(z.record(z.unknown())),
  }),
});

export type DataTable = {
  id: string;
  name: string;
  singularItemName: string | null;
  pluralItemName: string | null;
  data: {
    schema: {
      id: string;
      name: string;
      type: "text" | "color" | "image" | "imageArray" | "product" | "plainText";
    }[];
    rows: {
      [x: string]: unknown;
    }[];
  };
};

export const reploElementTypeSchema = z.enum([
  "page",
  "shopifyProductTemplate",
  "shopifyArticle",
  "shopifySection",
]);

export type ReploElementType =
  | "page"
  | "shopifyProductTemplate"
  | "shopifyArticle"
  | "shopifySection";

/**
 * NOTE (Gabe 2023-08-10): We accept null values because that's what Django
 * passes, but we convert them to undefined to be consistent with our
 * Replo Element type.
 */

export const reploElementSchema = z.object({
  id: z.string(),
  version: z.number(),
  storeId: z.string().uuid(),
  name: z.string(),
  type: reploElementTypeSchema,
  shopifyPageId: nullishToOptional(z.string()),
  hideDefaultHeader: z.boolean(),
  hideDefaultFooter: z.boolean(),
  hideShopifyAnnouncementBar: z
    .boolean()
    // TODO (Gabe 2023-10-09): For some reason the POST endpoint is being hit
    // with this value undefined. For now we'll allow this and default to false.
    .optional()
    .transform((val) => val ?? false),
  customHeadContent: nullishToOptional(z.string()),
  isHomepage: z.boolean(),
  shopifyPagePath: z.string(),
  shopifyTemplateSuffix: nullishToOptional(z.string()),
  shopifyBlogId: nullishToOptional(z.string()),
  publishedAt: nullishToOptional(z.string()),
  templateShopifyProductIds: nullishToOptional(z.array(z.string())),
  productTemplateSlug: nullishToOptional(z.string()),
  component: reploComponentSchema,
  createdAt: z.string(),
  updatedAt: z.string(),
  isPublished: nullishToOptional(z.boolean()),
  lastPublishedComponent: nullishToOptional(reploComponentSchemaBase.partial()),
  htmlString: nullishToOptional(z.string()),
  // TODO (Gabe 2023-09-18): For some reason, using nullishToUndefined on the
  // object loses the type information from the nullishToUndefined properties
  // within, so we use the same pattern but not the helper.
  mainThemeLastPublishedContent: z
    .object({
      outputHtml: z.string(),
      chunks: z.array(z.string()),
      runtimeVersion: nullishToOptional(z.string()),
      styles: nullishToOptional(z.string()),
    })
    .nullish()
    .transform((val) => val ?? undefined),
  shopifyMetafields: z.array(reploGenericMetafieldSchema),
  shopifyArticleImage: z.object({
    src: nullishToOptional(z.string()),
    alt: nullishToOptional(z.string()),
  }),
  runtimeVersion: nullishToOptional(z.string()),
  isTurbo: nullishToOptional(z.boolean()),
  useSectionSettings: nullishToOptional(z.boolean()),
  slug: nullishToOptional(z.string()),
  includeProductSchema: nullishToOptional(z.boolean()),
});

export type ReploElement = {
  id: string;
  version: number;
  storeId: string;
  name: string;
  type: "page" | "shopifyProductTemplate" | "shopifyArticle" | "shopifySection";
  shopifyPageId?: string;
  hideDefaultHeader: boolean;
  hideDefaultFooter: boolean;
  hideShopifyAnnouncementBar: boolean;
  customHeadContent?: string;
  isHomepage: boolean;
  shopifyPagePath: string;
  shopifyTemplateSuffix?: string;
  shopifyBlogId?: string;
  publishedAt?: string;
  templateShopifyProductIds?: string[];
  productTemplateSlug?: string;
  component: Component;
  createdAt: string;
  updatedAt: string;
  isPublished?: boolean;
  lastPublishedComponent?: Partial<Component>;
  htmlString?: string;
  mainThemeLastPublishedContent?: {
    outputHtml: string;
    chunks: string[];
    runtimeVersion?: string;
    styles?: string;
  };
  shopifyMetafields: {
    shopifyId?: string;
    type: string;
    namespace: string;
    value: string | number | boolean;
    key: string;
  }[];
  shopifyArticleImage: {
    src?: string;
    alt?: string;
  };
  runtimeVersion?: string;
  isTurbo?: boolean;
  useSectionSettings?: boolean;
  slug?: string;
  includeProductSchema?: boolean;
};

export const reploElementVersionRevision = z.object({
  id: z.string(),
  elementId: z.string().optional(),
  kind: reploElementVersionKindSchema,
  title: z.string(),
  createdAt: z.string(),
  revertedTo: z.string().optional(),
  createdBy: z
    .object({
      id: z.string(),
      email: z.string(),
      firstName: z.string().nullish(),
      lastName: z.string().nullish(),
      name: z.string().nullish(),
    })
    .optional(),
  // Note (Noah, 2024-05-09): Old revisions might have components which are technically invalid,
  // since we might have updated the schema and the component might not match the new schema. Not
  // sure this is always the case, but we should look into this
  component: z.unknown().optional(),
});

export type ReploElementVersionRevision = {
  id: string;
  elementId?: string;
  kind: "revert" | "publish" | "automatic" | "automaticElementOutdated";
  title: string;
  createdAt: string;
  revertedTo?: string;
  createdBy?: {
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
  };
  component?: Component;
};

export const reploSimpleElementSchema = reploElementSchema.pick({
  id: true,
  version: true,
  name: true,
  type: true,
  shopifyPageId: true,
  storeId: true,
  hideDefaultHeader: true,
  hideDefaultFooter: true,
  isHomepage: true,
  publishedAt: true,
  shopifyPagePath: true,
  shopifyTemplateSuffix: true,
  shopifyBlogId: true,
  templateShopifyProductIds: true,
  productTemplateSlug: true,
  isPublished: true,
  createdAt: true,
  shopifyArticleImage: true,
  shopifyMetafields: true,
});

export type ReploSimpleElement = {
  id: string;
  version: number;
  name: string;
  type: "page" | "shopifyProductTemplate" | "shopifyArticle" | "shopifySection";
  shopifyPageId?: string;
  storeId: string;
  hideDefaultHeader: boolean;
  hideDefaultFooter: boolean;
  isHomepage: boolean;
  publishedAt?: string;
  shopifyPagePath: string;
  shopifyTemplateSuffix?: string;
  shopifyBlogId?: string;
  templateShopifyProductIds?: string[];
  productTemplateSlug?: string;
  isPublished?: boolean;
  createdAt: string;
  shopifyArticleImage: {
    src?: string;
    alt?: string;
  };
  shopifyMetafields: {
    shopifyId?: string;
    type: string;
    namespace: string;
    value: string | number | boolean;
    key: string;
  }[];
};

export const reploDraftElementSchema = reploElementSchema.omit({
  htmlString: true,
  lastPublishedComponent: true,
  mainThemeLastPublishedContent: true,
});

export type ReploDraftElement = Omit<
  ReploElement,
  "htmlString" | "lastPublishedComponent" | "mainThemeLastPublishedContent"
>;

export const reploPartialElementSchema = reploElementSchema
  .pick({
    storeId: true,
    name: true,
    type: true,
    shopifyPageId: true,
    shopifyTemplateSuffix: true,
    shopifyBlogId: true,
    publishedAt: true,
    templateShopifyProductIds: true,
    isPublished: true,
  })
  .merge(
    reploElementSchema
      .pick({
        hideDefaultHeader: true,
        hideDefaultFooter: true,
        hideShopifyAnnouncementBar: true,
        isHomepage: true,
        shopifyPagePath: true,
        component: true,
        shopifyMetafields: true,
        shopifyArticleImage: true,
        isTurbo: true,
        useSectionSettings: true,
        slug: true,
        includeProductSchema: true,
      })
      .partial(),
  );

export type ReploPartialElement = {
  storeId: string;
  name: string;
  type: "page" | "shopifyProductTemplate" | "shopifyArticle" | "shopifySection";
  shopifyPageId?: string;
  shopifyTemplateSuffix?: string;
  shopifyBlogId?: string;
  publishedAt?: string;
  templateShopifyProductIds?: string[];
  isPublished?: boolean;
  hideDefaultHeader?: boolean;
  hideDefaultFooter?: boolean;
  hideShopifyAnnouncementBar?: boolean;
  isHomepage?: boolean;
  shopifyPagePath?: string;
  component?: Component;
  shopifyMetafields?: {
    shopifyId?: string;
    type: string;
    namespace: string;
    value: string | number | boolean;
    key: string;
  }[];
  shopifyArticleImage?: {
    src?: string;
    alt?: string;
  };
  isTurbo?: boolean;
  useSectionSettings?: boolean;
  slug?: string;
  includeProductSchema?: boolean;
};

const productIdSchema = z.union([z.string(), z.number()]).nullable();

export const metafieldsDependencySchema = z.object({
  type: z.literal("metafields"),
  entityType: z.enum(["product", "collection", "variant"]),
  productId: productIdSchema.optional(),
  variantId: z.string().optional(),
  productHandle: z.string(),
  metafieldKeys: z.array(
    reploGenericMetafieldSchema.pick({
      key: true,
      namespace: true,
      type: true,
    }),
  ),
});
export type MetafieldsDependency = {
  type: "metafields";
  entityType: "product" | "collection" | "variant";
  productId?: string | number | null;
  variantId?: string;
  productHandle: string;
  metafieldKeys: {
    key: string;
    namespace: string;
    type: string;
  }[];
};

export const productsDependencySchema = z.object({
  type: z.literal("products"),
  productIds: z.array(productIdSchema),
});
export type ProductsDependency = {
  type: "products";
  productIds: (string | number | null)[];
};

export const dataTablesDependencySchema = z.object({
  type: z.literal("dataTable"),
  dataTableId: z.string(),
});
export type DataTablesDependency = {
  type: "dataTable";
  dataTableId: string;
};

export const dependencySchema = z.discriminatedUnion("type", [
  metafieldsDependencySchema,
  productsDependencySchema,
  dataTablesDependencySchema,
]);
export type Dependency =
  | {
      type: "metafields";
      entityType: "product" | "collection" | "variant";
      productId?: string | number | null;
      variantId?: string;
      productHandle: string;
      metafieldKeys: {
        key: string;
        namespace: string;
        type: string;
      }[];
    }
  | {
      type: "products";
      productIds: (string | number | null)[];
    }
  | {
      type: "dataTable";
      dataTableId: string;
    };

const storeVariantSchema = z.object({
  id: z.number(),
  sku: z.string().nullable(),
  title: z.string(),
  option1: z.string(),
  option2: z.string().nullish(),
  option3: z.string().nullish(),
  available: z.boolean(),
  name: z.string(),
  price: z.string(),
  compare_at_price: z.string().nullable(),
  featured_image: z.object({ src: z.string() }).nullish(),
});

const storeSellingPlanOptionDefinitionSchema = z.object({
  name: z.string(),
  position: z.number(),
  /**
   * NOTE Ben 2024-02-19: (For USE-747) As far as I can tell from
   * https://shopify.dev/docs/api/admin-graphql/2024-01/objects/SellingPlanGroup, the options field
   * in graphql is supposed to be a list of non-null strings, but it clearly is not in some cases.
   * We're allowing null here because we don't assume it's a string in the runtime, and this is
   * the least invasive change at the moment.
   */
  values: z.array(z.string().nullable()),
});

const storeSellingPlanOptionSchema = z.object({
  name: z.string().optional(),
  position: z.number(),
  value: z.string(),
});

const storeSellingPlanPriceAdjustmentSchema = z.object({
  value: z.number(),
  value_type: z.enum(["percentage", "fixed_amount", "price"]),
});

const storeSellingPlanSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  options: z.array(storeSellingPlanOptionSchema),
  price_adjustments: z.array(storeSellingPlanPriceAdjustmentSchema),
});

const storeSellingPlanGroupSchema = z.object({
  id: z.string(),
  app_id: z.string().nullable(),
  options: z.array(storeSellingPlanOptionDefinitionSchema),
  selling_plans: z.array(storeSellingPlanSchema),
});

const metafieldTypeSchema = z.enum(metafieldTypes);
export const metafieldValuesMappingSchema = z.record(
  z.record(
    z.object({
      type: metafieldTypeSchema,
      value: z.union([z.string(), z.number()]),
    }),
  ),
);

export const productStatus = ["ACTIVE", "DRAFT", "ARCHIVED"] as const;

export const storeProductSchema = z.object({
  title: z.string(),
  images: z.array(z.string()),
  id: z.union([z.string(), z.number()]),
  type: z.string(),
  variants: z.array(storeVariantSchema),
  options: z.array(z.string()),
  featured_image: z.string().nullable(),
  description: z.string(),
  handle: z.string(),
  status: z.enum(productStatus),
  // Note (Noah, 2022-08-19, REPL-3674): Apparently, selling plan groups can be undefined
  // in liquid objects
  selling_plan_groups: z.array(storeSellingPlanGroupSchema).optional(),
});

export const reploElementProductSchema = z.unknown();
export type ReploElementProduct = unknown;

export const reploProductMetafieldValue = z.unknown();
export type ReploProductMetafieldValue = unknown;

// TODO (Evan, 2024-01-23): remove this once publishing is refactored (REPL-8639)
export const publishElementPropsSchema = z.object({
  shop: z.string(),
  token: z.string(),
  themeId: z.number(),
  runtimeVersion: z.string(),
  element: reploElementSchema,
  dataTables: z.array(dataTableSchema),
  swatches: z.array(z.unknown()),
  symbols: z.array(z.unknown()),
  dependencies: z.record(z.array(dependencySchema)),
  elementProducts: z.array(reploElementProductSchema),
  productMetafieldValues: z.record(reploProductMetafieldValue),
  variantMetafieldValues: z.record(z.unknown()),
  currencyCode: z.string(),
  carouselV4: z.boolean().default(false),
  okendoNamespace: z.enum(["okendoReviews", "okeWidgetApi"]).nullish(),
  useShopifyDebugTools: z.boolean().default(false).nullish(),
});

export const previewElementPropsSchema = z.object({
  storeId: z.string().uuid(),
  elementId: z.string().uuid(),
  previewElementVersion: z.number(),
  dependencies: z.record(z.array(dependencySchema)),
  elementProducts: z.array(z.unknown()),
  variantMetafieldValues: z.record(z.unknown()),
  productMetafieldValues: z.record(z.unknown()),
  okendoNamespace: z.enum(["okendoReviews", "okeWidgetApi"]).nullish(),
  shop: z.string(),
  dataTables: z.array(dataTableSchema),
  currencyCode: z.string(),
  activeLanguage: z.string(),
  activeShopifyUrlRoot: z.string(),
  moneyFormat: z.string().nullable(),
  carouselV4: z.boolean().default(false),
});

export type PreviewElementProps = {
  storeId: string;
  elementId: string;
  previewElementVersion: number;
  dependencies: {
    [x: string]: Dependency[];
  };
  elementProducts: unknown[];
  variantMetafieldValues: {
    [x: string]: unknown;
  };
  productMetafieldValues: {
    [x: string]: unknown;
  };
  okendoNamespace?: "okendoReviews" | "okeWidgetApi" | null;
  shop: string;
  dataTables: DataTable[];
  currencyCode: string;
  activeLanguage: string;
  activeShopifyUrlRoot: string;
  moneyFormat: string | null;
  carouselV4?: boolean;
};

export const publishElementBodySchema = z.intersection(
  publishElementPropsSchema.pick({
    carouselV4: true,
    dependencies: true,
    okendoNamespace: true,
    useShopifyDebugTools: true,
  }),
  z.object({
    elementId: z.string().uuid(),
    theme_id: z.string().nullable(),
    products: z.array(storeProductSchema),
    productMetafieldValues: z.record(metafieldValuesMappingSchema),
    variantMetafieldValues: z.record(metafieldValuesMappingSchema),
  }),
);

export type PublishElementBody = {
  carouselV4: boolean;
  dependencies: {
    [x: string]: Dependency[];
  };
  okendoNamespace?: "okendoReviews" | "okeWidgetApi" | null;
  useShopifyDebugTools?: boolean | null;
} & {
  elementId: string;
  theme_id: string | null;
  products: {
    title: string;
    images: string[];
    id: string | number;
    type: string;
    variants: {
      id: number;
      sku: string | null;
      title: string;
      option1: string;
      option2?: string | null;
      option3?: string | null;
      available: boolean;
      name: string;
      price: string;
      compare_at_price: string | null;
      featured_image?: {
        src: string;
      } | null;
    }[];
    options: string[];
    featured_image: string | null;
    description: string;
    handle: string;
    status: "ACTIVE" | "DRAFT" | "ARCHIVED";
    selling_plan_groups?: {
      id: string;
      app_id: string | null;
      options: {
        name: string;
        position: number;
        values: (string | null)[];
      }[];
      selling_plans: {
        id: number;
        name: string;
        description: string | null;
        options: {
          name?: string;
          position: number;
          value: string;
        }[];
        price_adjustments: {
          value: number;
          value_type: "percentage" | "fixed_amount" | "price";
        }[];
      }[];
    }[];
  }[];
  productMetafieldValues: ProductMetafieldMapping;
  variantMetafieldValues: {
    [x: string]: {
      [x: string]: {
        [x: string]: VariantMetafieldMapping;
      };
    };
  };
};

export const publishElementReturnSchema = reploElementSchema.omit({
  customHeadContent: true,
  lastPublishedComponent: true,
  mainThemeLastPublishedContent: true,
  runtimeVersion: true,
  isTurbo: true,
  slug: true,
});

export const componentTemplatePreviewPropsSchema = z.object({
  storeId: z.string().uuid(),
  dependencies: z.record(z.array(dependencySchema)),
  elementProducts: z.array(z.unknown()),
  variantMetafieldValues: z.record(z.unknown()),
  productMetafieldValues: z.record(z.unknown()),
  shop: z.string(),
  currencyCode: z.string(),
  activeLanguage: z.string(),
  activeShopifyUrlRoot: z.string(),
  moneyFormat: z.string().nullable(),
});

export type ShopifySectionSchema = {
  blocks?: {
    type: "@app";
  }[];
  settings: (
    | {
        id: string;
        type: "richtext" | "image_picker" | "url";
        label: string;
        value?: string;
        default?: string;
        info?: string;
      }
    | {
        type: "header";
        content?: string;
        info?: string;
      }
  )[];
};

export type ComponentTemplatePreviewProps = {
  storeId: string;
  dependencies: {
    [x: string]: Dependency[];
  };
  elementProducts: unknown[];
  variantMetafieldValues: {
    [x: string]: VariantMetafieldMapping;
  };
  productMetafieldValues: {
    [x: string]: ProductMetafieldMapping;
  };
  shop: string;
  currencyCode: string;
  activeLanguage: string;
  activeShopifyUrlRoot: string;
  moneyFormat: string | null;
};

export function isReploElementType(value: unknown): value is ReploElementType {
  return reploElementTypeSchema.options.includes(value as ReploElementType);
}

export function isPageOrShopifyArticleType(
  value: unknown,
): value is "page" | "shopifyArticle" {
  const pageOrBlogArticleType = reploElementTypeSchema.extract([
    "page",
    "shopifyArticle",
  ]);
  return pageOrBlogArticleType.options.includes(
    value as "page" | "shopifyArticle",
  );
}

export const updateElementSource = z.enum(["pageSettings", "component"]);
export type UpdateElementSource = "pageSettings" | "component";

import { z } from "zod";

export const MAX_FILE_UPLOAD_COUNT = 25 as const;

const sectionKeyRegex = /^sections\/.+\.(?:liquid|json)$/;

const sectionAssetSchema = z.object({
  key: z.string().regex(sectionKeyRegex),
  public_url: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  content_type: z.string(),
  size: z.number(),
  checksum: z.string(),
  theme_id: z.number(),
});

export type SectionAsset = z.infer<typeof sectionAssetSchema>;

export const sectionSchema = z.object({
  key: z.string().regex(sectionKeyRegex),
  themeId: z.string(),
  publicUrl: z.string().nullable(),
  size: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

const assetSchema = z.object({
  id: z.string(),
  publicUrl: z.string(),
});

const pageInfoSchema = z
  .object({
    hasNextPage: z.boolean(),
    hasPreviousPage: z.boolean(),
    prevPage: z.string().nullish(),
    nextPage: z.string().nullish(),
  })
  .nullable();

export const libraryAssetsSchema = z.object({
  assets: z.array(assetSchema),
  pageInfo: pageInfoSchema,
});

import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import * as React from "react";

export const RadioGroup = RadioGroupPrimitive.Root;

type RadioGroupItemProps = {
  value: string;
  isSelected: boolean;
};

export const RadioGroupItem = ({
  value,
  isSelected,
  ...props
}: RadioGroupItemProps) => {
  return (
    <RadioGroupPrimitive.Item
      value={value}
      style={{
        all: "unset",
        width: "20px",
        height: "20px",
        cursor: "pointer",
      }}
      className="rounded-full"
      {...props}
    >
      <RadioGroupPrimitive.Indicator
        forceMount
        asChild
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <div
          style={{ transition: "all 300ms ease-in-out" }}
          className={`block h-2 w-2 rounded-full ${
            isSelected ? "bg-blue-600" : "bg-gray-200"
          }`}
        >
          <div className="block  h-2 w-2 rounded-full bg-white" />
        </div>
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
};

import { mapAndConvertComponentStylePropsToStyle } from "../../../shared/utils/breakpoints";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export const customProperties = {
  svgWidth: "--rsw",
};

export const styleElements = {
  root: {
    defaultStyles: {
      lineHeight: "100%",
    },
    overrideStyles({ component, styleProps }) {
      const size = component.props.size;
      return mapAndConvertComponentStylePropsToStyle(styleProps, (styles) => {
        const onlyHeightAvailable = styles.__hasHeight && !styles.__hasWidth;
        const svgWidth = onlyHeightAvailable ? "auto" : "100%";

        return {
          width: styles.width ?? size,
          height: styles.height ?? size,
          [customProperties.svgWidth]: svgWidth,
        };
      });
    },
  },
  icon: {
    defaultStyles: {
      width: `var(${customProperties.svgWidth}, 100%)`,
      height: "100%",
    },
  },
} satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: ["width", "height"],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;

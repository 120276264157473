import * as RadioGroup from "@radix-ui/react-radio-group";
import * as React from "react";
import type { Component } from "replo-runtime/shared/Component";
import type { RenderComponentProps } from "replo-runtime/shared/types";
import { mergeContext } from "replo-runtime/shared/utils/context";
import { useRenderChildren } from "replo-runtime/shared/utils/renderComponents";
import type { Context } from "replo-runtime/store/AlchemyVariable";
import {
  isItemsDynamic,
  type ItemsConfig,
} from "replo-runtime/store/utils/items";

import { ReploComponent } from "../ReploComponent";

type SelectionListContextValue = {
  selectedItem: string | null;
  setSelectedItem?: (selectedItem: string) => void;
};

const SelectionListContext = React.createContext<SelectionListContextValue>({
  selectedItem: null,
  setSelectedItem: undefined,
});

const useSelectionListContext = () => {
  return React.useContext(SelectionListContext);
};

const SelectionList: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
  context,
}) => {
  const customProps = getCustomProps(component);
  const listItems = useRenderChildren(component, {
    itemsConfig: customProps.items,
    context,
  });
  const areItemsDynamic = isItemsDynamic(customProps.items);
  const [selectedItem, setSelectedItem] = React.useState<string>(
    listItems[0]?.component.id ? `${listItems[0].component.id}.0` : "",
  );

  const isAriaSelectionEnable =
    customProps.accessibilitySelection === undefined
      ? true
      : customProps.accessibilitySelection;

  return (
    <SelectionListContext.Provider value={{ selectedItem, setSelectedItem }}>
      <SelectionListWrapper isAriaSelectionEnable={isAriaSelectionEnable}>
        <div {...componentAttributes}>
          {listItems.map((item, index) => {
            const template =
              component.children?.[index] ?? component.children?.[0];

            if (!template) {
              return null;
            }

            return (
              <SelectionListItem
                isAriaSelectionEnable={isAriaSelectionEnable}
                index={areItemsDynamic ? index : 0}
                key={item.component.id}
                item={item}
                parentContext={context}
                template={template}
              />
            );
          })}
        </div>
      </SelectionListWrapper>
    </SelectionListContext.Provider>
  );
};

const SelectionListWrapper: React.FC<
  React.PropsWithChildren<{
    isAriaSelectionEnable: boolean;
  }>
> = ({ isAriaSelectionEnable, children }) => {
  const selectionListContext = useSelectionListContext();
  if (isAriaSelectionEnable) {
    return (
      <RadioGroup.Root value={selectionListContext.selectedItem ?? ""} asChild>
        {children}
      </RadioGroup.Root>
    );
  }
  return children;
};

const SelectionListItem: React.FC<{
  isAriaSelectionEnable: boolean;
  item: { component: Component; context: Context; item: any };
  index: number;
  parentContext: Context;
  template: Component;
}> = ({ isAriaSelectionEnable, parentContext, item, template, index }) => {
  const selectionListContext = useSelectionListContext();
  const repeatedIndexPath = `${parentContext.repeatedIndexPath}.${index}`;

  if (!isAriaSelectionEnable) {
    return (
      <ReploComponent
        component={template}
        context={mergeContext(item.context, parentContext)}
        repeatedIndexPath={repeatedIndexPath}
      />
    );
  }

  const itemValue = `${item.component.id}.${index}`;
  const mergedContext = mergeContext(item.context, {
    ...parentContext,
    attributes: {
      ...parentContext.attributes,
      ...item.context.attributes,
    },
    state: {
      ...parentContext.state,
      selectionList: {
        isItemSelected: selectionListContext.selectedItem === itemValue,
        selectedListItem: item.component.id,
      },
    },
    variantTriggers: {
      ...parentContext.variantTriggers,
      "state.selectionList.isItemSelected": true,
    },
    actionHooks: {
      ...parentContext.actionHooks,
      setSelectedListItem: () => {
        selectionListContext.setSelectedItem?.(itemValue);
      },
    },
  });

  return (
    <RadioGroup.Item value={itemValue} asChild>
      <ReploComponent
        component={template}
        context={mergedContext}
        repeatedIndexPath={repeatedIndexPath}
        defaultActions={{
          actions: {
            onClick: [
              {
                id: "alchemy:setSelectedListItem",
                type: "setSelectedListItem",
                value: null,
              },
            ],
          },
        }}
      />
    </RadioGroup.Item>
  );
};

function getCustomProps(component: Component) {
  return Object.fromEntries(
    Object.entries(component.props).map(([key, value]) => [
      key.startsWith("_") ? key.slice(1) : key,
      value,
    ]),
  ) as {
    items: ItemsConfig;
    accessibilitySelection: boolean;
  };
}

export default SelectionList;

import * as React from "react";

import type { RenderComponentProps } from "../../../shared/types";
import { mergeContext } from "../../../shared/utils/context";
import Container from "../Container";

const Collapsible = (props: RenderComponentProps) => {
  return (
    <Container
      {...props}
      context={mergeContext(props.context, {
        actionHooks: {
          toggleCollapsible: () => {
            const setters =
              props.context.actionHooks.componentIdToVariantSetters?.[
                props.component.id
              ];
            setters?.setActiveVariantId?.(
              setters.getActiveVariantId() === "alchemy:collapsibleOpen"
                ? "alchemy:collapsibleClosed"
                : "alchemy:collapsibleOpen",
            );
          },
        },
      })}
    />
  );
};

export default Collapsible;

import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _shopifySectionName: {
      name: "Section",
      type: "shopifyThemeSections",
      description:
        "Specify the Section from your Shopify Theme that you'd like to embed here. This section can be customized in the Shopify Theme Editor.",
      defaultValue: null,
    },
  };
}

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    customProps: convertToLegacyProps(getConfigurableProps()),
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
    shouldBeRootChild: () => true,
    ancestorDisallowList: [
      {
        elementTypes: ["shopifyArticle"],
        message: "Shopify Liquid can't be used in Shopify Articles",
      },
      {
        ancestorTypes: ["button"],
        message: "Shopify Liquid cannot be nested inside buttons.",
      },
    ],
  },
} satisfies ComponentConfig;

export default config;

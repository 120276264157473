import { leftBarWidth as defaultLeftBarWidth } from "@components/editor/constants";
import type { EditorRootState } from "@editor/store";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export type LeftBarTab = "tree" | "components";
export type RightBarTab =
  | "design"
  | "custom"
  | "interactions"
  | "accessibility";

type SupportedPopoverId =
  | "modifier-icon-selector"
  | "config-product-selector"
  | "tiptap-toolbar-link";

export type UIState = {
  isCreateComponentFormVisible: boolean;
  leftBarWidth: number;
  leftBarActiveTab: LeftBarTab;
  leftBarNavigationOpenedMenu: string;
  rightBarActiveTab: RightBarTab;
  isRichTextEditorFocused: boolean;
  isRenamingTreeNode: boolean;
  themeId: string | null;
  isShopifyStoreClosed: boolean;
  openPopoverId: SupportedPopoverId | null;
  hasSetupAnalytics: boolean;
};

const initialState: UIState = {
  isCreateComponentFormVisible: false,
  leftBarWidth: defaultLeftBarWidth,
  leftBarActiveTab: "tree",
  rightBarActiveTab: "design",
  isRichTextEditorFocused: false,
  isRenamingTreeNode: false,
  themeId: null,
  isShopifyStoreClosed: false,
  openPopoverId: null,
  leftBarNavigationOpenedMenu: "",
  hasSetupAnalytics: false,
};

/**
 * A slice for any UI-only related state, like modals, popovers, tooltips, etc.
 */

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setHasSetupAnalytics: (state, action: PayloadAction<boolean>) => {
      state.hasSetupAnalytics = action.payload;
    },
    setIsCreateComponentFormVisible: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isCreateComponentFormVisible = action.payload;
    },
    setLeftBarWidth: (state, action: PayloadAction<number>) => {
      state.leftBarWidth = action.payload;
    },
    setLeftBarActiveTab: (state, action: PayloadAction<LeftBarTab>) => {
      state.leftBarActiveTab = action.payload;
    },
    setRightBarActiveTab: (state, action: PayloadAction<RightBarTab>) => {
      state.rightBarActiveTab = action.payload;
    },
    setIsRichTextEditorFocused: (state, action: PayloadAction<boolean>) => {
      state.isRichTextEditorFocused = action.payload;
    },
    setIsRenamingTreeNode: (state, action: PayloadAction<boolean>) => {
      state.isRenamingTreeNode = action.payload;
    },
    setThemeId: (state, action: PayloadAction<string>) => {
      state.themeId = action.payload;
    },
    setShopifyStoreIsClosed(state, action: PayloadAction<boolean>) {
      state.isShopifyStoreClosed = action.payload;
    },
    setOpenPopoverId: (
      state,
      action: PayloadAction<SupportedPopoverId | null>,
    ) => {
      state.openPopoverId = action.payload;
    },
    setLeftBarNavigationOpenedMenu: (state, action: PayloadAction<string>) => {
      state.leftBarNavigationOpenedMenu = action.payload;
    },
  },
});

const { actions, reducer } = uiSlice;

export const {
  setIsCreateComponentFormVisible,
  setLeftBarWidth,
  setLeftBarActiveTab,
  setRightBarActiveTab,
  setIsRichTextEditorFocused,
  setIsRenamingTreeNode,
  setThemeId,
  setShopifyStoreIsClosed,
  setOpenPopoverId,
  setLeftBarNavigationOpenedMenu,
  setHasSetupAnalytics,
} = actions;
export default reducer;

export const selectHasSetupAnalytics = (state: EditorRootState) => {
  return state.ui.hasSetupAnalytics;
};

export const selectLeftBarActiveTab = (state: EditorRootState) => {
  return state.ui.leftBarActiveTab;
};

export const selectLeftBarWidth = (state: EditorRootState) => {
  return state.ui.leftBarWidth;
};

export const selectIsRenamingTreeNode = (state: EditorRootState) => {
  return state.ui.isRenamingTreeNode;
};

export const selectAllowHorizontalScroll = (state: EditorRootState) => {
  return state.ui.leftBarWidth === 500;
};

export const selectRightBarActiveTab = (state: EditorRootState) => {
  return state.ui.rightBarActiveTab;
};

export const selectThemeId = (state: EditorRootState) => {
  return state.ui.themeId;
};

export const selectIsShopifyStoreClosed = (state: EditorRootState) => {
  return state.ui.isShopifyStoreClosed;
};

export const selectOpenPopoverId = (state: EditorRootState) => {
  return state.ui.openPopoverId;
};

export const selectLeftBarNavigationOpenedMenu = (state: EditorRootState) => {
  return state.ui.leftBarNavigationOpenedMenu;
};

import * as React from "react";

import {
  FeatureFlagsContext,
  ReploElementContext,
  RuntimeHooksContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { styleElements } from "../Spinner/config";

const Spinner: React.FC<RenderComponentProps> = ({
  componentAttributes: attributes,
  component,
}) => {
  const foregroundColor = String(component.props.foregroundColor);
  const secondaryColor = String(component.props.secondaryColor);
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const { featureFlags } = useRuntimeContext(FeatureFlagsContext);
  const { useSectionSettings } = useRuntimeContext(ReploElementContext);

  return (
    <div {...attributes}>
      <svg
        className={!isEditorCanvas ? "replo-animate-spinner" : undefined}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        style={
          // Note (Martin, 2023-06-09): Typically we would set styles via a
          // className but it seems to not be applying correctly to this svg
          // so we default to adding those via inline styles.
          styleElements.icon.overrideStyles({
            component,
            context: {
              featureFlags,
              useSectionSettings,
            },
            styleProps: {},
          })
        }
      >
        <circle
          cx="12"
          cy="12"
          r="10"
          stroke={foregroundColor}
          strokeWidth="4"
        />
        <path
          fill={secondaryColor}
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>
  );
};

export default Spinner;

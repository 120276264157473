import type { CodeEditorLanguage } from "@editor/components/modals/CodeEditorModal";
import {
  selectDraftComponentType,
  selectPropCSSContent,
  selectPropHTMLContent,
  selectPropLiquidContent,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import useApplyComponentAction from "@hooks/useApplyComponentAction";
import { useModal } from "@hooks/useModal";
import { getRenderData } from "replo-runtime/store/components";

const useOpenCodeEditor = () => {
  const applyComponentAction = useApplyComponentAction();
  const modal = useModal();
  const draftComponentType = useEditorSelector(selectDraftComponentType);
  const htmlContent = useEditorSelector(selectPropHTMLContent);
  const cssContent = useEditorSelector(selectPropCSSContent);
  const liquidContent = useEditorSelector(selectPropLiquidContent);

  const supportedShortcutTypes = new Set([
    "htmlCodeEditor",
    "cssCodeEditor",
    "liquidCodeEditor",
  ]);

  if (!draftComponentType) {
    return;
  }

  const renderData = getRenderData(draftComponentType);
  const currentTypeRenderData = renderData?.customProps?.find((customProp) =>
    supportedShortcutTypes.has(customProp.type),
  );

  if (!currentTypeRenderData) {
    return;
  }

  const { type } = currentTypeRenderData;

  const editorConfig = {
    htmlCodeEditor: {
      value: htmlContent,
      language: "html",
      propName: "_htmlContent",
    },
    cssCodeEditor: {
      value: cssContent,
      language: "css",
      propName: "_css",
    },
    liquidCodeEditor: {
      value: liquidContent,
      language: "liquid",
      propName: "_liquidContent",
    },
  }[type as "htmlCodeEditor" | "cssCodeEditor" | "liquidCodeEditor"];

  return () =>
    modal.openModal({
      type: "codeEditorModal",
      props: {
        value: editorConfig.value,
        language: editorConfig.language as CodeEditorLanguage,
        onChange: (value: string) =>
          applyComponentAction({
            type: "setProps",
            value: { [editorConfig.propName]: value },
          }),
      },
    });
};

export default useOpenCodeEditor;

import Banner from "@common/designSystem/Banner";
import type { MenuItem } from "@common/designSystem/Menu";
import Tooltip from "@common/designSystem/Tooltip";
import LogoBadge from "@common/LogoBadge";
import { elementTypeToEditorData } from "@components/editor/element";
import ActionButtons from "@components/header/ActionButtons";
import { ElementUpdateLoadingIndicator } from "@components/header/ElementUpdateLoadingIndicator";
import PublishedStatus from "@components/header/PublishedStatus";
import VersioningPageButtons from "@components/header/VersioningButtons";
import { Menu, MenuTrigger } from "@editor/components/common/designSystem/Menu";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import type { GroupedElements } from "@editor/components/ElementsPane";
import {
  getMenuItemsForElementItems,
  groupElements,
  reploElementDefaultCategoryName,
  useCurrentElementType,
  usePageModificationHandlers,
} from "@editor/components/ElementsPane";
import PageControlButtons from "@editor/components/header/PageControlButtons";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import useGetHeaderLogoRedirection from "@editor/hooks/useGetHeaderLogoRedirection";
import useGetStoreNameAndUrl from "@editor/hooks/useGetStoreNameAndUrl";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import usePublishedInfo from "@editor/hooks/usePublishedInfo";
import { useUserHasWorkspaceMembership } from "@editor/hooks/useUserHasWorkspaceMembership";
import {
  selectDoesExistDraftElement,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftElementName,
  selectDraftElementType,
  selectEditorMode,
  selectLoadableProject,
  setEditorMode,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { useLogAnalytics } from "@hooks/useLogAnalytics";
import { Button } from "@replo/design-system/components/button";
import classNames from "classnames";
import * as React from "react";
import { BsArrowUpRight, BsChevronDown } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import type { ReploElement } from "schemas/element";
import { z } from "zod";

const Header: React.FC = React.memo(function Header() {
  const editorMode = useEditorSelector(selectEditorMode);
  const draftElementType = useEditorSelector(selectDraftElementType);
  const draftElementName = useEditorSelector(selectDraftElementName);
  const draftElementExists = useEditorSelector(selectDoesExistDraftElement);
  const { storeName } = useGetStoreNameAndUrl();
  const isEditMode = useEditorSelector(selectEditorMode) === EditorMode.edit;
  const redirectTo = useGetHeaderLogoRedirection();
  const {
    subscriptionInfo,
    isLoading: isSubscriptionDetailsLoading,
    isFetching: isSubscriptionFetching,
  } = useSubscriptionInfo();
  const logEvent = useLogAnalytics();
  const { url, urlIsDisabled, tooltipLabel } = usePublishedInfo();
  const { isLoading: isProjectLoading, project } = useEditorSelector(
    selectLoadableProject,
  );
  const userIsWorkspaceMember = useUserHasWorkspaceMembership(project?.id);
  const isLoading =
    isProjectLoading || isSubscriptionDetailsLoading || isSubscriptionFetching;

  const navigate = useNavigate();

  const managePlan = () => {
    navigate(`/workspace/${project?.ownerWorkspaceId}/billing`);
  };
  return (
    <header className="relative flex flex-col">
      <div className="flex h-[60px] w-full justify-between overflow-hidden bg-white px-2">
        <div className="ml-2 flex items-center gap-2">
          <div className="flex w-64">
            <div className="mr-2 flex cursor-pointer items-center text-slate-200 transition-all hover:text-slate-400">
              <Tooltip
                triggerAsChild
                key="logo"
                content="Back to Replo Dashboard"
              >
                <Link to={redirectTo} className="flex flex-row items-center">
                  <span className="sr-only">Back to Replo Dashboard</span>
                  <LogoBadge
                    aria-hidden
                    className="mr-1 h-9 w-9 cursor-pointer text-black"
                  />
                </Link>
              </Tooltip>
            </div>
            <div className="flex flex-col gap-1 text-xs min-w-0">
              <div className="text-sm font-semibold text-default">
                <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                  {storeName}
                </div>
              </div>
              <div
                className={classNames(
                  "flex items-center gap-2",
                  isLoading && "invisible",
                )}
              >
                <div className="rounded bg-blue-200 px-2 py-0.5 text-xs text-blue-600">
                  {subscriptionInfo.displayName} Plan
                </div>
                {userIsWorkspaceMember ? (
                  <button
                    className="cursor-pointer text-blue-600"
                    type="button"
                    onClick={managePlan}
                  >
                    {subscriptionInfo?.tier === "free"
                      ? "Upgrade Now"
                      : "Manage Plan"}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          {isEditMode && <ActionButtons />}
        </div>
        {draftElementExists && (
          <TitleAndCenterMenu
            pageTitle={
              draftElementType &&
              `${elementTypeToEditorData[draftElementType].singularDisplayName} / ${draftElementName}`
            }
          />
        )}
        <div className="flex items-center space-x-2">
          <ElementUpdateLoadingIndicator />
          {editorMode === EditorMode.versioning ? (
            <VersioningPageButtons />
          ) : (
            <PageControlButtons />
          )}
          <Button
            variant="tertiary"
            data-testid="live-page-button"
            isDisabled={urlIsDisabled}
            icon={
              <BsArrowUpRight
                size={18}
                className={classNames(!urlIsDisabled && "text-blue-600")}
              />
            }
            tooltipText={tooltipLabel}
            aria-label={tooltipLabel}
            onClick={() => {
              logEvent("header.viewPage", {});
              if (url) {
                window.open(url, "_blank");
              }
            }}
          />
        </div>
      </div>
      <DebugModeBanner />
    </header>
  );
});

const DebugModeBanner: React.FC = () => {
  const [usePublisherStagingUrl] = useLocalStorageState(
    "replo.debug.usePublisherStagingUrl",
    false,
    { schema: z.boolean() },
  );
  return usePublisherStagingUrl ? (
    <Banner
      backgroundColor="bg-yellow-200"
      className="color-white p-1 text-xs"
      isDismissable={false}
    >
      [Replo debug] Using publisher staging URL!
    </Banner>
  ) : null;
};

const PageLink = () => {
  const { path, url, urlIsDisabled } = usePublishedInfo();

  return path ? (
    <div
      className={classNames(
        "mt-1 max-w-[280px] overflow-hidden text-ellipsis whitespace-nowrap text-center font-mono text-xs text-slate-400",
        {
          "hover:text-blue-600": !urlIsDisabled,
        },
      )}
    >
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className={classNames({
          "pointer-events-none cursor-default": urlIsDisabled,
        })}
      >
        {path}
      </a>
    </div>
  ) : null;
};

const getCurrentGroupName = (
  draftElement: ReploElement,
  groupedElements: Record<string, GroupedElements[]>,
) => {
  const flattenedElements = Object.values(groupedElements).flatMap(
    (arr) => arr,
  );
  const currentGroup = flattenedElements.find(
    (el) => el.element.id === draftElement.id,
  );

  return currentGroup?.categoryName;
};

const TitleAndCenterMenu: React.FC<{ pageTitle: string }> = ({ pageTitle }) => {
  const dispatch = useEditorDispatch();
  const [isOpen, setOpen] = React.useState(false);
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const { items } = useCurrentElementType();
  const groupedElements = groupElements(items);
  const currentGroupName = draftElement
    ? getCurrentGroupName(draftElement, groupedElements)
    : undefined;
  const elementsGroupsNames = Object.keys(groupedElements)
    .filter(
      (group) =>
        ![currentGroupName, reploElementDefaultCategoryName].includes(group),
    )
    .sort();
  const {
    handleDeletion,
    handleDuplication,
    handleEditPage,
    handleMoveToGroup,
    handleSupportDuplication,
  } = usePageModificationHandlers();
  const isDebugMode = useIsDebugMode();
  const menuItems: MenuItem[] = [
    ...(draftElement
      ? getMenuItemsForElementItems({
          element: draftElement,
          handleDeletion,
          handleDuplication,
          handleSupportDuplication,
          handleEditPage,
          handleMoveToGroup,
          elementsGroupsNames: elementsGroupsNames,
          isDebugMode,
        })
      : []),
    {
      type: "leaf",
      id: "history",
      title: "View Version History",
      onSelect: () => {
        dispatch(setEditorMode(EditorMode.versioning));
      },
    },
  ];

  return (
    <div
      className="absolute flex h-[60px] flex-col items-center justify-center"
      style={{ left: "50%", transform: "translateX(-50%)" }}
    >
      <div className="relative flex flex-row">
        <PublishedStatus />
        <h2 className="max-w-[280px] overflow-hidden text-ellipsis whitespace-nowrap text-center text-sm font-medium capitalize text-default">
          {pageTitle}
        </h2>
        <Menu
          items={menuItems}
          isOpen={isOpen}
          onRequestClose={() => setOpen(false)}
          contentClassNames="mt-10"
          trigger={
            <MenuTrigger asChild>
              <button
                type="button"
                className="group pt-0.5"
                aria-label="Page Settings Button"
                onClick={() => setOpen(true)}
                style={{ backgroundColor: "unset" }}
              >
                <BsChevronDown
                  className={classNames(
                    "ml-1 h-4 w-6 scale-75 transform transition duration-300 ease-in-out",
                    {
                      "group-hover:translate-y-1.5": !isOpen,
                      "translate-y-1.5": isOpen,
                    },
                  )}
                />
              </button>
            </MenuTrigger>
          }
        />
      </div>
      <PageLink />
    </div>
  );
};

export default Header;

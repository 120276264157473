import { accordionBlock } from "@components/editor/templates/accordionBlock";
import { button } from "@components/editor/templates/button";
import { buyWithPrimeButton } from "@components/editor/templates/buyWithPrimeButton";
import circle from "@components/editor/templates/circle";
import { collapsibleV2 } from "@components/editor/templates/collapsibleV2";
import { countdownTimer } from "@components/editor/templates/countdownTimer";
import { marquee } from "@components/editor/templates/dynamic/marquee";
import { feraMediaGalleryWidget } from "@components/editor/templates/feraMediaGalleryWidget";
import { feraProductRatingWidget } from "@components/editor/templates/feraProductRatingWidget";
import { feraProductReviewsWidget } from "@components/editor/templates/feraProductReviewsWidget";
import { feraStoreReviewsWidget } from "@components/editor/templates/feraStoreReviewsWidget";
import { h1 } from "@components/editor/templates/h1";
import { h2 } from "@components/editor/templates/h2";
import { h3 } from "@components/editor/templates/h3";
import { horizontalRule } from "@components/editor/templates/horizontalRule";
import { customHtml } from "@components/editor/templates/htmlContent";
import { icon } from "@components/editor/templates/icon";
import { image } from "@components/editor/templates/image";
import { infiniteOptionsWidget } from "@components/editor/templates/infiniteOptionsWidget";
import { googleMapsEmbed } from "@components/editor/templates/integrations/googleMapsEmbed";
import { klaviyoEmbed } from "@components/editor/templates/integrations/klaviyoEmbed";
import { tikTokEmbed } from "@components/editor/templates/integrations/tikTokEmbed";
import { vimeoEmbed } from "@components/editor/templates/integrations/vimeoEmbed";
import { youtubeEmbed } from "@components/editor/templates/integrations/youtubeEmbed";
import { judgeProductRatingWidget } from "@components/editor/templates/judgeProductRatingWidget";
import { judgeProductReviewsWidget } from "@components/editor/templates/judgeProductReviewsWidget";
import { junipProductRating } from "@components/editor/templates/junipProductRating";
import { junipReviews } from "@components/editor/templates/junipReviews";
import { looxProductRating } from "@components/editor/templates/looxProductRating";
import { looxReviews } from "@components/editor/templates/looxReviews";
import { modal } from "@components/editor/templates/modal";
import { okendoProductRatingSummary } from "@components/editor/templates/okendoProductRatingSummary";
import { postscriptSignupForm } from "@components/editor/templates/postscriptSignupForm";
import { addToCartButton } from "@components/editor/templates/product/addToCartButton";
import buyNow from "@components/editor/templates/product/buyNow";
import compareAtPrice from "@components/editor/templates/product/compareAtPrice";
import description from "@components/editor/templates/product/description";
import price from "@components/editor/templates/product/price";
import product from "@components/editor/templates/product/product";
import {
  optionSelect,
  optionSelectDropdown,
  optionSelectWithRadio,
} from "@components/editor/templates/product/productOptionSelect";
import quantity from "@components/editor/templates/product/quantity";
import { quantitySelector } from "@components/editor/templates/product/quantitySelector";
import {
  sellingPlanDropdown,
  sellingPlanList,
  sellingPlanListWithRadio,
} from "@components/editor/templates/product/sellingPlanList";
import { subscribeAndSave } from "@components/editor/templates/product/subscribeAndSave";
import variantImage from "@components/editor/templates/product/variantImage";
import {
  variantList,
  variantListWithRadio,
  variantSelectDropdown,
} from "@components/editor/templates/product/variantList";
import { radioButton } from "@components/editor/templates/radioButton";
import { reviewsIoProductRating } from "@components/editor/templates/reviewsIoProductRating";
import { reviewsIoReviews } from "@components/editor/templates/reviewsIoReviews";
import { appBlocks } from "@components/editor/templates/shopify/appBlocks";
import {
  rawLiquid,
  shopifySection,
} from "@components/editor/templates/shopify/section";
import { shopifyProductRatingWidget } from "@components/editor/templates/shopifyProductRatingWidget";
import { shopifyProductReviewsWidget } from "@components/editor/templates/shopifyProductReviewsWidget";
import { spinner } from "@components/editor/templates/spinner";
import {
  container,
  fourColumns,
  horizontalStack,
  oneByTwoColumns,
  threeColumns,
  twoByOneColumns,
  twoColumns,
} from "@components/editor/templates/stacks";
import { stampedProductRatingWidget } from "@components/editor/templates/stampedProductRatingWidget";
import { stampedProductReviewsWidget } from "@components/editor/templates/stampedProductReviewsWidget";
import { starRating } from "@components/editor/templates/starRating";
import { starRatingAndText } from "@components/editor/templates/starRatingAndText";
import { switchTemplate } from "@components/editor/templates/switch";
import { tabsV2Left, tabsV2Top } from "@components/editor/templates/tabsV2";
import { text } from "@components/editor/templates/text";
import { videoPlayer } from "@components/editor/templates/videoPlayer";
import { yotpoProductRating } from "@components/editor/templates/yotpoProductRating";
import { yotpoReviews } from "@components/editor/templates/yotpoReviews";
import { beforeAfterSlider } from "@editor/components/editor/templates/beforeAfterSlider";
import bulletedList from "@editor/components/editor/templates/bulletedList";
import { bannerCarousel } from "@editor/components/editor/templates/carousel/banner";
import { carousel } from "@editor/components/editor/templates/carousel/base";
import { centeredCarousel } from "@editor/components/editor/templates/carousel/centered";
import { carouselIndicators } from "@editor/components/editor/templates/carousel/indicators";
import { carouselNextControl } from "@editor/components/editor/templates/carousel/next";
import { carouselPanels } from "@editor/components/editor/templates/carousel/panels";
import { carouselPreviousControl } from "@editor/components/editor/templates/carousel/previous";
import { carouselSlides } from "@editor/components/editor/templates/carousel/slides";
import { slideshowCarousel } from "@editor/components/editor/templates/carousel/slideshow";
import { carouselWithImageIndicators } from "@editor/components/editor/templates/carousel/withImageIndicators";
import { carouselWithMultipleItems } from "@editor/components/editor/templates/carousel/withMultipleItems";
import { carouselWithProductImages } from "@editor/components/editor/templates/carousel/withProductImages";
import { dynamicBundle } from "@editor/components/editor/templates/integrations/rebuy/dynamicBundle";
import { homePageCrossSell } from "@editor/components/editor/templates/integrations/rebuy/homePageCrossSell";
import { productAddOns } from "@editor/components/editor/templates/integrations/rebuy/productAddOns";
import { productPageCrossSell } from "@editor/components/editor/templates/integrations/rebuy/productPageCrossSell";
import { productPageUpsell } from "@editor/components/editor/templates/integrations/rebuy/productPageUpsell";
import { knoCommerceWidget } from "@editor/components/editor/templates/knoCommerceWidget";
import { okendoReviewsWidget } from "@editor/components/editor/templates/okendoReviewsWidget";
import { dynamicCheckoutButtons } from "@editor/components/editor/templates/product/dynamicCheckoutButtons";
import { productCollection } from "@editor/components/editor/templates/product/productCollection";
import productWithCarousel from "@editor/components/editor/templates/product/productWithCarousel";
import productWithSwatch from "@editor/components/editor/templates/product/productWithSwatch";
import productWithVariantAndOptionList from "@editor/components/editor/templates/product/productWithVariantAndOptionList";
import { swatchOptionListCircular } from "@editor/components/editor/templates/product/swatchOptionCircular";
import title from "@editor/components/editor/templates/product/title";
import { rechargeSubscriptionWidget } from "@editor/components/editor/templates/rechargeSubscriptionWidget";
import { imageGrid } from "@editor/components/editor/templates/selection/imageGrid";
import { productImageGrid } from "@editor/components/editor/templates/selection/productImageGrid";
import { productQuantitySelection } from "@editor/components/editor/templates/selection/productQuantity";
import { productSelection } from "@editor/components/editor/templates/selection/productSelection";
import { staySubscriptionWidget } from "@editor/components/editor/templates/staySubscriptionWidget";
import { tooltip } from "@editor/components/editor/templates/tooltip";
import type { ComponentTemplate } from "@editor/types/component-template";

const getHardcodedComponentTemplates = (): ComponentTemplate[] => {
  const hardcodedTemplates = [
    text,
    image,
    horizontalStack,
    container,
    twoColumns,
    oneByTwoColumns,
    twoByOneColumns,
    threeColumns,
    fourColumns,
    horizontalRule,
    beforeAfterSlider,
    product,
    productCollection,
    productWithCarousel,
    productWithSwatch,
    productWithVariantAndOptionList,
    description,
    title,
    quantity,
    variantImage,
    price,
    compareAtPrice,
    buyNow,
    addToCartButton,
    variantList,
    quantitySelector,
    variantSelectDropdown,
    variantListWithRadio,
    optionSelect,
    sellingPlanList,
    sellingPlanDropdown,
    sellingPlanListWithRadio,
    optionSelectDropdown,
    optionSelectWithRadio,
    swatchOptionListCircular,
    googleMapsEmbed,
    klaviyoEmbed,
    videoPlayer,
    vimeoEmbed,
    youtubeEmbed,
    tikTokEmbed,
    button,
    switchTemplate,
    radioButton,
    shopifySection,
    collapsibleV2,
    tabsV2Top,
    tabsV2Left,
    starRating,
    rawLiquid,
    appBlocks,
    marquee,
    customHtml,
    carousel,
    carouselWithMultipleItems,
    centeredCarousel,
    bannerCarousel,
    carouselWithImageIndicators,
    slideshowCarousel,
    carouselWithProductImages,
    carouselSlides,
    carouselNextControl,
    carouselPreviousControl,
    carouselIndicators,
    carouselPanels,
    rechargeSubscriptionWidget,
    staySubscriptionWidget,
    knoCommerceWidget,
    okendoReviewsWidget,
    okendoProductRatingSummary,
    h1,
    h2,
    h3,
    bulletedList,
    countdownTimer,
    icon,
    starRatingAndText,
    spinner,
    circle,
    junipReviews,
    junipProductRating,
    yotpoReviews,
    yotpoProductRating,
    looxReviews,
    looxProductRating,
    reviewsIoReviews,
    reviewsIoProductRating,
    dynamicCheckoutButtons,
    accordionBlock,
    buyWithPrimeButton,
    dynamicBundle,
    homePageCrossSell,
    productAddOns,
    productPageCrossSell,
    productPageUpsell,
    modal,
    stampedProductReviewsWidget,
    stampedProductRatingWidget,
    feraProductRatingWidget,
    feraProductReviewsWidget,
    feraStoreReviewsWidget,
    feraMediaGalleryWidget,
    shopifyProductReviewsWidget,
    shopifyProductRatingWidget,
    judgeProductRatingWidget,
    judgeProductReviewsWidget,
    infiniteOptionsWidget,
    postscriptSignupForm,
    subscribeAndSave,
    tooltip,
    productQuantitySelection,
    productSelection,
    imageGrid,
    productImageGrid,
  ];

  return hardcodedTemplates;
};

export const getTemplateName = (template: ComponentTemplate) => {
  const [categoryOrName, ...rest] = template.name.split("/");
  return template.name.includes("/") ? rest.join("/") : categoryOrName;
};

export const hardcodedComponentTemplates: ComponentTemplate[] =
  getHardcodedComponentTemplates();

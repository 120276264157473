import * as React from "react";

import type { RenderComponentProps } from "../../../shared/types";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { prependProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedLiquidReviewIntegrations } from "../SharedLiquidReviewIntegrations";

function FeraProductRatingWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const product = useProductFromProps(component.props, context);
  const liquidSource =
    '<div data-fera-container="product_reviews_summary" data-product-id="{{ product.id }}"></div>';
  const liquid = prependProductAssignmentToLiquidSource({
    product,
    liquidSource,
    context,
  });

  return (
    <SharedLiquidReviewIntegrations
      attributes={componentAttributes}
      isProductRating
      component={component}
      liquidSource={liquid}
      placeholder="Fera Product Rating Widget will appear here"
      shouldHavePlaceHolder={!product}
      appName="Fera.ai"
      context={context}
    />
  );
}

export default FeraProductRatingWidget;

import {
  getTemplateName,
  hardcodedComponentTemplates,
} from "@editor/components/editor/componentTemplates";
import { groupComponentTemplatesByLeftBarCategory } from "@editor/utils/component-templates";
import type {
  ComponentTemplateLeftBarSubCategory,
  ComponentTemplateScope,
} from "schemas/componentTemplates";

const mapSubCategoryToOrder: Record<
  ComponentTemplateLeftBarSubCategory,
  number
> = {
  text: 1,
  core: 2,
  shapes: 3,
  columns: 1,
  productLayouts: 1,
  productData: 3,
  buttons: 2,
  variants: 4,
  options: 5,
  sellingPlans: 6,
  subscription: 0,
  okendo: 0,
  junip: 0,
  yotpo: 0,
  loox: 0,
  reviewsIo: 0,
  stamped: 0,
  fera: 0,
  shopify: 0,
  judgeMe: 0,
  infiniteOptions: 0,
  image: 0,
  video: 0,
  toggles: 0,
  animations: 0,
  countdown: 0,
  popup: 0,
  carousel: 0,
  form: 0,
  map: 0,
  rebuy: 0,
  beforeAfterSlider: 0,
  other: 0,
};

export default function useSearchableTemplateLeftBarCategories(
  scope: ComponentTemplateScope,
  searchTerm: string,
) {
  const categories = groupComponentTemplatesByLeftBarCategory(
    hardcodedComponentTemplates,
    scope,
  );
  return {
    categories: Object.entries(categories).map(
      ([mainCategory, subCategories]) => {
        return {
          mainCategory,
          subCategories: Object.entries(subCategories)
            .map(([subCategory, componentTemplates]) => ({
              subCategory,
              componentTemplates: componentTemplates.filter(
                (componentTemplate) => {
                  const lowerSearchTerm = searchTerm.toLowerCase();
                  const templateName = getTemplateName(componentTemplate);
                  return (
                    templateName?.toLowerCase().includes(lowerSearchTerm) ||
                    componentTemplate.tags?.some((tag) =>
                      tag.toLowerCase().includes(lowerSearchTerm),
                    )
                  );
                },
              ),
            }))
            .sort((a, b) => {
              return (
                mapSubCategoryToOrder[
                  a.subCategory as ComponentTemplateLeftBarSubCategory
                ] -
                mapSubCategoryToOrder[
                  b.subCategory as ComponentTemplateLeftBarSubCategory
                ]
              );
            }),
        };
      },
    ),
  };
}

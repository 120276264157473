import Button from "@common/designSystem/Button";
import Input from "@common/designSystem/Input";
import { ErrorMessage as GlobalErrorMessage } from "@components/account/common";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import GoogleAuth from "@editor/components/account/GoogleAuth";
import Avatar from "@editor/components/common/designSystem/Avatar";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import toast, {
  errorToast,
} from "@editor/components/common/designSystem/Toast";
import Tooltip from "@editor/components/common/designSystem/Tooltip";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { getErrorMessage } from "@editor/utils/rtk-query-error-helpers";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import type { CredentialResponse } from "@react-oauth/google";
import * as React from "react";
import { useForm } from "react-hook-form";
import { BsCheckCircleFill } from "react-icons/bs";
import { z } from "zod";

const validationSchema = z.object({
  firstName: z.string().min(1, "You must enter a first name"),
  lastName: z.string(),
});

export type UserName = {
  firstName: string;
  lastName: string;
};

export default function ProfileBoard() {
  const [googleErrorMessage, setGoogleErrorMessage] = React.useState<
    string | null
  >(null);
  const { user, isLoading: isUserLoading } = useCurrentUser();

  const {
    mutate: updateProfileName,
    isPending: isLoading,
    error,
  } = trpc.user.updateProfileName.useMutation({
    onSuccess: (user) => {
      toast({
        header: "Profile Updated",
        message: "Your profile has been successfully updated",
      });
      logEvent("user.setting.updated", {
        infoUpdated: "name",
      });
      trpcUtils.user.get.setData({}, (oldData) => {
        if (!oldData) {
          return oldData;
        }

        return {
          ...oldData,
          firstName: user.firstName,
          lastName: user.lastName,
          name: `${user.firstName} ${user.lastName}`,
        };
      });
    },
    onError: () => {
      errorToast(
        "Failed Updating Profile",
        "Please try again or reach out to support@replo.app for help.",
      );
    },
  });

  const { mutate: connectGoogleAccount } =
    trpc.user.connectGoogleAccount.useMutation({
      onSuccess: ({ verifiedAt }) => {
        trpcUtils.user.get.setData({}, (oldData) => {
          if (!oldData) {
            return oldData;
          }

          return {
            ...oldData,
            verifiedAt,
          };
        });

        toast({
          header: "Google Account Connected",
          message: "Your Google account has been successfully connected",
        });
      },
      onError: (error) => setGoogleErrorMessage(error.message),
    });

  const onGoogleSubmit = async (credentialResponse: CredentialResponse) => {
    const { clientId, credential } = credentialResponse;
    if (clientId && credential) {
      connectGoogleAccount({ clientId, credential });
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
    },
    resolver: zodResolver(validationSchema),
  });

  const logEvent = useLogAnalytics();

  const onSubmit = async ({ firstName, lastName }: UserName) => {
    updateProfileName({ firstName, lastName });
  };

  const defaultErrorMessage =
    errors?.firstName?.message ?? getErrorMessage(error)?.message;

  return user && !isUserLoading ? (
    <div className="flex">
      <Avatar name={user.name} size="xl" className="mr-8" />
      <div className="flex flex-1 flex-col">
        <div className="flex w-64 flex-col">
          <h3 className="mb-2 text-sm font-medium text-default">
            Your Profile
          </h3>
          <form
            onSubmit={(data) => {
              void handleSubmit(onSubmit)(data);
            }}
          >
            <div className="grid grid-cols-2 gap-2">
              <LabeledControl label="First Name">
                <div className={defaultErrorMessage ? "mb-1.5" : "mb-3"}>
                  <Input
                    aria-invalid={defaultErrorMessage ? "true" : undefined}
                    aria-describedby={
                      defaultErrorMessage ? "error-first-name" : undefined
                    }
                    autoComplete="off"
                    placeholder="First Name"
                    {...register("firstName")}
                    type="text"
                    size="base"
                  />
                </div>
                <ErrorMessage
                  id="error-first-name"
                  error={defaultErrorMessage}
                />
              </LabeledControl>
              <LabeledControl label="Last Name">
                <div className={errors?.lastName?.message ? "mb-1.5" : "mb-3"}>
                  <Input
                    // TODO (Chance 2023-11-10): This field should be marked as
                    // required. I assume it's not for stylistic purposes but we
                    // can do this properly while still controlling the style of
                    // the displayed errors.
                    aria-invalid={
                      errors?.lastName?.message ? "true" : undefined
                    }
                    aria-describedby={
                      errors?.lastName?.message ? "error-last-name" : undefined
                    }
                    autoComplete="off"
                    placeholder="Last Name"
                    {...register("lastName")}
                    type="text"
                    size="base"
                  />
                </div>
                <ErrorMessage error={errors?.lastName?.message} />
              </LabeledControl>
            </div>
            <LabeledControl label="Email">
              <div className="mb-3 text-sm text-default flex items-center">
                {user.email}
                {user.verifiedAt && (
                  <Tooltip content="Email account is verified" triggerAsChild>
                    <div tabIndex={0}>
                      <BsCheckCircleFill className="text-green-400 ml-1" />
                    </div>
                  </Tooltip>
                )}
              </div>
            </LabeledControl>
            {user.referralCode?.code && (
              <LabeledControl label="Referrer Code">
                <div className="mb-3 text-sm text-default">
                  {user.referralCode.code}
                </div>
              </LabeledControl>
            )}
            {!user.verifiedAt && (
              <div className="mb-4 text-xs text-slate-400">
                Connect your Google account
                <GlobalErrorMessage errorMessage={googleErrorMessage} />
                <GoogleAuth
                  width={256}
                  handleGoogleCredentials={onGoogleSubmit}
                />
              </div>
            )}
            <div className="mb-4 text-xs text-slate-400">
              To change your email address, please reach out to
              <a href="mailto:support@replo.app"> support@replo.app</a>
            </div>
            <Button
              type="primary"
              size="base"
              htmlType="submit"
              className="px-3"
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Update Profile
            </Button>
          </form>
        </div>
      </div>
    </div>
  ) : null;
}

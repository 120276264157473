type TailwindClassnameTypeProps = "bg" | "text";

export function isTailwindClassName(
  className: string,
  type: TailwindClassnameTypeProps,
) {
  const typeToRegex: Record<TailwindClassnameTypeProps, RegExp> = {
    bg: /^bg-\S+/g,
    text: /^text-\S+/g,
  };
  return typeToRegex[type].test(className) ? true : false;
}

import * as React from "react";
import type {
  ElementGenericMetafield,
  ElementMetafieldType,
} from "replo-runtime/shared/types";
import type { ReploPartialElement } from "schemas/element";

const useOnChangeMetafields = (
  element: ReploPartialElement,
  setElement: (element: React.SetStateAction<ReploPartialElement>) => void,
) => {
  const metafields = React.useMemo(() => {
    return element.shopifyMetafields ?? [];
  }, [element.shopifyMetafields]);

  const setMetafields = React.useCallback(
    (shopifyMetafields: ElementGenericMetafield[]) => {
      setElement((value) => ({
        ...value,
        shopifyMetafields,
      }));
    },
    [setElement],
  );

  const onMetafieldChange = React.useCallback(
    (
      key: string,
      value: string | number,
      namespace: string,
      type: ElementMetafieldType,
    ) => {
      const metafieldToChange = { value, type, key, namespace };
      const shopifyMetafields = (metafields ?? []).map((metafield) => {
        if (metafield.key === metafieldToChange.key) {
          return {
            ...metafield,
            value: metafieldToChange.value,
          };
        }
        return metafield;
      });

      const metafieldIndex = metafields?.findIndex(
        (field) => field.key === metafieldToChange.key,
      );

      if (metafieldIndex === -1) {
        shopifyMetafields.push(metafieldToChange);
      }

      setMetafields(shopifyMetafields);
    },
    [metafields, setMetafields],
  );

  return { metafields, onMetafieldChange };
};

export default useOnChangeMetafields;

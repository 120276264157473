import * as React from "react";

import type { RenderComponentProps } from "../../../shared/types";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { prependProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const RebuyWidget: React.FC<RenderComponentProps> = ({
  componentAttributes: attributes,
  component,
  context,
}) => {
  const rebuyWidgetEmbed = component.props._rebuyWidgetEmbed;
  const product = useProductFromProps(component.props, context);

  const rebuyEmbedIncludesProductId = rebuyWidgetEmbed?.includes(
    "data-rebuy-shopify-product-ids",
  );

  const rebuyWidgetIdRegex = rebuyWidgetEmbed?.match(
    /data-rebuy-id="(\d+)"/,
  )?.[1];

  let liquidSource = `<div data-rebuy-id="${rebuyWidgetIdRegex}"${
    rebuyEmbedIncludesProductId
      ? ' data-rebuy-shopify-product-ids="{{ product.id }}"'
      : ""
  }></div>`;

  if (rebuyEmbedIncludesProductId) {
    liquidSource = prependProductAssignmentToLiquidSource({
      product,
      liquidSource,
      context: context,
    });
  }
  const isRegexDefined = rebuyWidgetIdRegex != null;
  const placeholder = isRegexDefined
    ? "Rebuy Widget will appear here"
    : "Once you set your Rebuy embed code in the Config tab, your embedded Rebuy Widget will appear here.";
  return (
    <SharedShopifyLiquid
      forceEditorPlaceholder={!isRegexDefined}
      liquidSource={liquidSource}
      componentId={component.id}
      componentAttributes={attributes}
      placeholder={placeholder}
      repeatedIndexPath={context.repeatedIndexPath}
      isLiquidSupported
    />
  );
};

export default RebuyWidget;

import Button from "@common/designSystem/Button";
import ComponentPreview from "@components/ComponentPreview";
import toast from "@editor/components/common/designSystem/Toast";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import useOnSubmitTemplateAction from "@editor/hooks/useOnSubmitTemplateAction";
import { selectDraftElementId } from "@editor/reducers/core-reducer";
import { setLastMarketplacePath } from "@editor/reducers/marketplace-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import type { ComponentTemplate } from "@editor/types/component-template";
import { generateEditorPathname, routes } from "@editor/utils/router";
import { getIsTemplateNewlyAdded } from "@editor/utils/template";
import SvgMarketplaceTemplateBadge from "@svg/marketplace-template-badge";
import classNames from "classnames";
import * as React from "react";
import { BsCheckLg, BsLink45Deg } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  type CategorySlug,
  getCategorySlugFromElementType,
  getComponentTemplateBadgeColor,
  getComponentTemplateBadgeName,
} from "replo-runtime/shared/componentTemplatesCategories";
import { getConfig } from "replo-runtime/shared/config";
import type { ComponentTemplateCollection } from "schemas/componentTemplates";
import type { ReploElementType } from "schemas/element";
import { twMerge } from "tailwind-merge";

type TemplateCardProps =
  | {
      type: "collection";
      collection: ComponentTemplateCollection;
      componentTemplate?: never;
      elementType: ReploElementType;
    }
  | {
      type: "template";
      collection?: never;
      componentTemplate: ComponentTemplate;
      elementType: ReploElementType;
    };

export const TemplateCard: React.FC<TemplateCardProps> = ({
  type,
  collection,
  componentTemplate,
  elementType,
}) => {
  const modal = useModal();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const params = useParams();
  const projectId = useCurrentProjectId();
  const { onSubmit } = useOnSubmitTemplateAction();
  const draftElementId = useEditorSelector(selectDraftElementId);
  const analytics = useLogAnalytics();
  const categorySlug = params.categorySlug as CategorySlug;
  const dispatch = useEditorDispatch();

  const onTemplateClick = () => {
    if (type === "template" && componentTemplate) {
      void onSubmit(componentTemplate, elementType);
      return navigate(
        generateEditorPathname("", {
          projectId: projectId ?? "",
          draftElementId: draftElementId,
        }),
      );
    }
  };

  const onDetailsClick = async () => {
    if (type === "template") {
      if (!componentTemplate || !projectId) {
        return;
      }
      modal.closeModal({});
      analytics("editor.marketplace.details", {
        type: "componentTemplate",
        componentTemplateId: componentTemplate.id,
        componentTemplateName: componentTemplate.name,
      });
      const newState = {
        ...state,
        elementType,
        fromPath: pathname,
        categorySlug:
          categorySlug ?? getCategorySlugFromElementType(elementType),
      };
      dispatch(setLastMarketplacePath(pathname));
      navigate(
        generateEditorPathname(routes.marketplaceModalDetails, {
          projectId: projectId ?? "",
          elementId: draftElementId,
          templateId: componentTemplate.id,
        }),
        {
          state: newState,
        },
      );
    }
    if (type === "collection") {
      modal.closeModal({});
      analytics("editor.marketplace.details", {
        type: "collection",
        collectionId: collection.id,
        collectionName: collection.name,
      });
      const newState = {
        ...state,
        elementType,
        fromPath: pathname,
        categorySlug:
          categorySlug ?? getCategorySlugFromElementType(elementType),
      };
      dispatch(setLastMarketplacePath(pathname));
      navigate(
        generateEditorPathname(routes.marketplaceModalCollection, {
          projectId: projectId ?? "",
          elementId: draftElementId,
          collectionId: collection.id,
        }),
        {
          state: newState,
        },
      );
    }
  };

  const onOrganizationNameClick = () => {
    modal.closeModal({});
    const collectionId =
      type === "template" ? componentTemplate.collection?.id : collection.id;
    const collectionName =
      type === "template"
        ? componentTemplate.collection?.name
        : collection.name;

    analytics("editor.marketplace.details", {
      type: "collection",
      collectionId,
      collectionName,
    });
    const newState = {
      ...state,
      elementType: elementType,
      categorySlug,
    };
    dispatch(setLastMarketplacePath(pathname));

    navigate(
      generateEditorPathname(routes.marketplaceModalCollection, {
        projectId: projectId ?? "",
        elementId: draftElementId,
        collectionId,
      }),
      {
        state: newState,
      },
    );
  };

  const organizationName =
    type === "template"
      ? componentTemplate.collection?.organizationName
      : collection.organizationName;
  const profileImageUrl =
    type === "template"
      ? componentTemplate.collection?.profileImageUrl
      : collection.profileImageUrl;
  const name = type === "template" ? componentTemplate.name : collection.name;
  const testId = type === "template" ? "template-card" : "collection-card";
  const wrapperOnClick =
    type === "collection" ? onOrganizationNameClick : onTemplateClick;

  return (
    <div>
      {type === "template" ? (
        <ComponentTemplateItem
          item={componentTemplate}
          onSelect={() => {
            void onDetailsClick();
          }}
        />
      ) : (
        <CollectionItem
          imgUrl={collection.coverImageUrl}
          onDetailsClick={onDetailsClick}
        />
      )}
      <div
        className="flex cursor-pointer items-center pt-3"
        data-testid={testId}
        onClick={wrapperOnClick}
      >
        {profileImageUrl && (
          <img
            className="h-9 w-9 rounded-full object-cover"
            src={profileImageUrl}
            alt={name}
          />
        )}
        <div className="flex flex-1 items-center justify-between pl-2">
          <div className="flex flex-col gap-1">
            <p className="cursor-pointer text-xs font-bold">{name}</p>
            <p
              className="cursor-pointer text-xs text-blue-600 hover:text-blue-800 "
              onClick={(e) => {
                // Note (Noah, 2023-05-12): Stop propagation so that the onClick
                // handler above which triggers the default behavior to add the
                // template to the current page doesn't trigger, since when this card
                // is a template, we want to open the collection when they click on
                // the org name
                e.stopPropagation();
                onOrganizationNameClick();
              }}
            >
              {organizationName}
            </p>
          </div>
          {type === "template" && (
            <div className="flex gap-1">
              <CopyComponentTemplateUrlButton
                id={componentTemplate.id}
                name={componentTemplate.name}
              />
              <Button
                type="secondary"
                size="base"
                className="w-fit bg-slate-200 bg-opacity-50 text-blue-600"
                textClassNames="font-normal text-xs"
                isRounded
                onClick={(e) => {
                  e.stopPropagation();
                  void onTemplateClick();
                }}
                hasMinDimensions={false}
              >
                ADD
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ComponentTemplateItem: React.FC<{
  item: ComponentTemplate;
  onSelect: () => void;
}> = ({ item, onSelect }) => {
  const isNewlyAdded = getIsTemplateNewlyAdded(item.createdAt);
  const badgeColors = isNewlyAdded
    ? { primaryColor: "#6CB594", secondaryColor: "#6CB594" }
    : item?.badgeId && getComponentTemplateBadgeColor(item?.badgeId);
  const badgeName = isNewlyAdded
    ? "Just Added"
    : item?.badgeId && getComponentTemplateBadgeName(item?.badgeId);
  // NOTE (Sebas, 2024-01-05): This is a calculated width based on the
  // badge name length. This works with the current badges we have, but
  // if we add longer badges, we should verify that this still works.
  const calculatedWidth = badgeName ? badgeName?.length * 10 : 139;
  return (
    <div
      className={twMerge(
        classNames("group relative w-full cursor-pointer h-[200px] m-4", {
          "h-[450px]": item.type === "page",
        }),
      )}
      onClick={onSelect}
    >
      {badgeName && (
        <div className="absolute -top-4 -left-3.5 z-10">
          <div className="relative">
            <SvgMarketplaceTemplateBadge
              width={calculatedWidth}
              primaryColor={badgeColors?.primaryColor}
              secondaryColor={badgeColors?.secondaryColor}
            />
            <p className="absolute top-1.5 left-2 text-white text-sm font-bold">
              {badgeName}
            </p>
          </div>
        </div>
      )}

      <ComponentPreview type="snapshot" componentTemplate={item} />
    </div>
  );
};

const CollectionItem: React.FC<{
  imgUrl: string;
  onDetailsClick(): Promise<void>;
}> = ({ imgUrl, onDetailsClick }) => {
  return (
    <div
      className="aspect-video cursor-pointer"
      onClick={() => {
        void onDetailsClick();
      }}
    >
      <ComponentPreview type="img" src={imgUrl} />
    </div>
  );
};

const CopyComponentTemplateUrlButton: React.FC<{
  id: ComponentTemplate["id"];
  name: ComponentTemplate["name"];
}> = ({ id, name }) => {
  const [isCopied, setIsCopied] = React.useState(false);

  const analytics = useLogAnalytics();

  const onTemplateCopy = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setIsCopied(true);
    const url = getConfig("dashboardUrl") ?? "https://dashboard.replo.app/";
    const baseUrl = `${url}/?templateId=${id}`;
    void navigator.clipboard.writeText(baseUrl);

    analytics("editor.componentTemplate.share", {
      shareUrl: baseUrl,
      componentTemplateId: id,
      componentTemplateName: name,
      from: "componentTemplateCard",
    });

    toast({
      header: "Template Link Copied",
      message: "The template link has been copied to your clipboard.",
    });
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <Button
      type="secondary"
      size="base"
      className={classNames({
        "bg-opacity-50 bg-slate-200": !isCopied,
        "bg-blue-600 hover:bg-blue-600": isCopied,
      })}
      textClassNames="font-normal text-xs"
      isRounded
      hasMinDimensions={false}
      onClick={onTemplateCopy}
      aria-label="Copy URL"
      tooltipText="Copy URL"
    >
      {isCopied ? (
        <BsCheckLg size={16} className="text-white" />
      ) : (
        <BsLink45Deg size={16} className="text-blue-600" />
      )}
    </Button>
  );
};

export default TemplateCard;

import ChevronMenuIndicator from "@common/designSystem/ChevronMenuIndicator";
import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import type { MenuItem } from "@common/designSystem/Menu";
import { MAX_STYLE_PROPERTY_VALUE_LENGTH } from "@editor/components/editor/constants";
import { getHotKey } from "@editor/utils/getHotKey";
import classNames from "classnames";
import * as React from "react";
import useMeasure from "react-use-measure";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { useComposedEventHandlers } from "replo-utils/react/use-composed-event-handlers";
import { useDebouncedCallback } from "replo-utils/react/use-debounced-callback";
import { twMerge } from "tailwind-merge";

import { errorToast } from "./Toast";

export type InputSize = "sm" | "base" | "xl";

type InputOwnProps = {
  value?: string | number;
  defaultValue?: string | number;
  size?: InputSize;
  type?: string;
  placeholder?: string | null;
  autoFocus?: boolean;
  autoComplete?: string;
  startEnhancer?(): React.ReactNode;
  endEnhancer?(): React.ReactNode;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  // NOTE (Chance 2023-11-13): Similar to `isPhonyButton` in `Button`, this prop
  // renders a container `div` that is styled like an input but does not accept
  // user input or hold a value.
  isPhonyInput?: boolean;
  validityState?: "valid" | "invalid";
  name?: string;
  id?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
  onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
  onEnter?(): void;
  onEscape?(): void;
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onKeyUp?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onKeyPress?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onMouseDown?(e: React.MouseEvent<HTMLInputElement>): void;
  onMouseUp?(e: React.MouseEvent<HTMLInputElement>): void;
  onMouseMove?(e: React.MouseEvent<HTMLInputElement>): void;
  onClick?(e: React.MouseEvent<HTMLInputElement>): void;
  onPaste?(e: React.ClipboardEvent<HTMLInputElement>): void;
  /**
   * **IMPORTANT** (Chance 2023-11-10): Adding classnames to design system
   * components should be considered an escape hatch and avoided if possible.
   * Things like spacing and layout can generally be handled with wrapper layout
   * components. Design variations should be considered in the design system.
   */
  unsafe_className?: string;
  /**
   * **IMPORTANT** (Chance 2023-11-10): Adding classnames to design system
   * components should be considered an escape hatch and avoided if possible.
   * Things like spacing and layout can generally be handled with wrapper layout
   * components. Design variations should be considered in the design system.
   */
  unsafe_inputClassName?: string;
  /**
   * **IMPORTANT** (Chance 2023-11-10): Adding classnames to design system
   * components should be considered an escape hatch and avoided if possible.
   * Things like spacing and layout can generally be handled with wrapper layout
   * components. Design variations should be considered in the design system.
   */
  unsafe_style?: React.CSSProperties;
  menuItems?: MenuItem[];
  allowsDragOnStartEnhancer?: boolean;
  isFocused?: boolean;
  isRequired?: boolean;
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  allowsDynamicData?: boolean;
  onClickDynamicData?(): void;
  shouldSelectTextOnFocus?: boolean;
  onOptionClick?(): void;
};

export type InputProps = InputOwnProps &
  Omit<
    React.ComponentPropsWithRef<"input">,
    keyof InputOwnProps | "className" | "style"
  >;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  function Input(
    {
      placeholder,
      size = "sm",
      type = "text",
      autoFocus = false,
      autoComplete = "on",
      startEnhancer,
      endEnhancer,
      isDisabled = false,
      validityState,
      isReadOnly,
      isPhonyInput,
      id,
      onBlur,
      onFocus,
      onEnter,
      onEscape,
      onKeyPress,
      onMouseDown,
      onMouseUp,
      onMouseMove,
      unsafe_className: className,
      unsafe_inputClassName: inputClassName,
      menuItems,
      allowsDragOnStartEnhancer = false,
      unsafe_style: style,
      // TODO (Noah, Chance, REPL-9830): We need to fix the controlledFocus
      // of these inputs, currently it's broken
      isFocused: _controlledFocus = false,
      isRequired = false,
      allowsDynamicData = false,
      onClickDynamicData,
      shouldSelectTextOnFocus = false,
      onOptionClick,
      ...props
    }: InputOwnProps,
    forwardedRef,
  ) {
    const [ref, { width }] = useMeasure();

    const inputClassNames = twMerge(
      classNames(
        "bg-inherit text-default disabled:text-disabled border-0 rounded w-full focus:outline-none",
        {
          "text-xs h-6": size === "sm",
          "text-sm h-7": size === "base",
          "text-base h-10 px-3": size === "xl",
        },
        {
          "placeholder:text-subtle": !isDisabled,
          "placeholder:text-disabled cursor-not-allowed": isDisabled,
          "text-danger": validityState === "invalid",
        },
      ),
      inputClassName,
    );

    const enhancerClassNames = "flex items-center text-subtle";

    function onKeyPressInput(e: React.KeyboardEvent<HTMLInputElement>) {
      if (e.key === "Enter" && onEnter) {
        onEnter?.();
      }
      if (e.key === "Escape" && onEscape) {
        onEscape?.();
      }
      return onKeyPress?.(e);
    }

    const shouldAddMouseHandlers =
      !Boolean(startEnhancer) || !allowsDragOnStartEnhancer;

    const onInputMouseUp = (
      e: React.MouseEvent<HTMLInputElement, globalThis.MouseEvent>,
    ) => {
      onMouseUp?.(e);
      if (onOptionClick) {
        const hotkey = getHotKey(e);
        if (hotkey === "altKey") {
          onOptionClick();
        }
      }
    };
    const endEnhancerContent = endEnhancer?.();

    return (
      <div className="flex w-full gap-2">
        <div
          className={twMerge(
            classNames(
              "flex justify-content-around p-2 gap-2 w-full items-center text-default rounded transition-all duration-75 relative focus-within:outline focus-within:outline-blue-600 focus-within:outline-1",
              {
                "h-6": size === "sm",
                "h-8": size === "base",
                "bg-disabled cursor-not-allowed": isDisabled,
                "bg-subtle": !isDisabled,
                "bg-danger-emphasis border border-red-600":
                  validityState === "invalid",
              },
            ),
            className,
          )}
          ref={ref}
          // NOTE (Sebas, 2024-06-27): We need to stop propagation of the keydown event
          // to prevent the hotkeys from being triggered.
          onKeyDown={(e) => e.stopPropagation()}
        >
          {startEnhancer && (
            <div
              className={enhancerClassNames}
              style={{
                cursor: allowsDragOnStartEnhancer ? "ns-resize" : style?.cursor,
              }}
              onBlur={onBlur}
              onFocus={onFocus}
            >
              {startEnhancer()}
            </div>
          )}
          {isPhonyInput ? (
            <div className={inputClassNames} style={style} id={id}></div>
          ) : (
            <input
              {...props}
              className={inputClassNames}
              style={style}
              type={type}
              placeholder={placeholder ?? undefined}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              disabled={isDisabled}
              readOnly={isReadOnly}
              onBlur={onBlur}
              onFocus={(event) => {
                onFocus?.(event);
                if (!event.defaultPrevented && shouldSelectTextOnFocus) {
                  event.target.select();
                }
              }}
              onKeyPress={onKeyPressInput}
              onMouseDown={shouldAddMouseHandlers ? onMouseDown : undefined}
              onMouseUp={shouldAddMouseHandlers ? onInputMouseUp : undefined}
              onMouseMove={shouldAddMouseHandlers ? onMouseMove : undefined}
              ref={forwardedRef}
              id={id}
              required={isRequired}
            />
          )}
          {endEnhancerContent && !menuItems && (
            <span className={enhancerClassNames}>{endEnhancerContent}</span>
          )}
          {!endEnhancer && menuItems && (
            <ChevronMenuIndicator
              items={menuItems}
              menuWidth={width}
              isDisabled={isDisabled}
            />
          )}
        </div>
        {allowsDynamicData && (
          <DynamicDataButton
            onClick={(e) => {
              e.stopPropagation();
              onClickDynamicData?.();
            }}
          />
        )}
      </div>
    );
  },
);

export default Input;

export type DebouncedInputProps = {
  /**
   * NOTE (Chance 2023-12-08): The change handler that is fired immediately when
   * the input changes. This function is not debounced, but it can be used to
   * handle things on the event itself before the debounced `onValueChange`
   * handler is triggered.
   */
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  /**
   * NOTE (Chance 2023-12-08): This is the debounced function that fires in
   * response to the value changing at a given interval.
   */
  onValueChange: ((value: string) => void) | undefined;
  /**
   * The debounce timeout for `onValueChange`
   * @default 300
   */
  timeout?: number;
  value: string;
};

/**
 * A utility hook for getting handling inputs where there is an internal value,
 * but the provided value is controlled by a parent component and may be updated
 * by a change handler that is debounced given a timeout value. When the
 * provided value changes it will override the internal value.
 */
export function useOverridableInput({
  onChange,
  onValueChange,
  timeout = 300,
  value,
  ...props
}: DebouncedInputProps) {
  const [inputValue, setInputValue] = useOverridableState(
    value,
    useDebouncedCallback((value: string) => onValueChange?.(value), timeout),
  );
  const handleChange = useComposedEventHandlers(
    onChange,
    React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value != null && value.length > MAX_STYLE_PROPERTY_VALUE_LENGTH) {
          // NOTE (Fran 2024-04-22): Add this check to avoid the editor freeze because of a huge string.
          // Example: a huge svg source.
          errorToast(
            "Invalid Input Value",
            `Please input a string with less than ${MAX_STYLE_PROPERTY_VALUE_LENGTH} characters.`,
          );
        } else {
          setInputValue(event.target.value);
        }
      },
      [setInputValue],
    ),
  );

  return {
    value: inputValue,
    onChange: handleChange,
    ...props,
  };
}

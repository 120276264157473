import type { EditorRootState } from "@editor/store";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export type MarketplaceState = {
  lastPath: string | null;
};

const initialState: MarketplaceState = {
  lastPath: null,
};

const marketplaceReducer = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    /**
     * Sets the last marketplace path.
     * This allows us to redirect back to the marketplace after the user closes it and navigates to
     * other parts of the app.
     */
    setLastMarketplacePath: (
      state,
      action: PayloadAction<MarketplaceState["lastPath"]>,
    ) => {
      state.lastPath = action.payload;
    },
  },
});

/**
 * Used to get the last marketplace path that was visited.
 * This allows us to redirect back to the marketplace after the user closes it and navigates to
 * other parts of the app.
 */
export const selectLastMarketplacePath = (state: EditorRootState) => {
  return state.marketplace.lastPath;
};

const { reducer, actions } = marketplaceReducer;
export const { setLastMarketplacePath } = actions;
export default reducer;

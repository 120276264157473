import type { CustomPropsRecord } from "../../../shared/Component";
import { mapAndConvertComponentStylePropsToStyle } from "../../../shared/utils/breakpoints";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    url: {
      type: "string",
      name: "YouTube URL",
      description:
        "The url of the YouTube video to display. For example: https://www.youtube.com/watch?v=dQw4w9WgXcQ",
      defaultValue: "",
    },
    hideControls: {
      type: "boolean",
      name: "Hide Controls",
      defaultValue: false,
      description:
        "If enabled, no play/pause or fullscreen controls will be shown while the video is playing.",
    },
    _autoplay: {
      name: "Autoplay",
      type: "boolean",
      description: `If enabled, the video will autoplay on mute when loaded. "Default Muted" must be enabled for autoplay to work.`,
      defaultValue: false,
      isEnabled: (config) => Boolean(config.component.props._defaultMuted),
    },
    _loop: {
      name: "Autoloop",
      type: "boolean",
      defaultValue: false,
      description: "If enabled, the video will loop after it ends",
    },
    _defaultMuted: {
      name: "Default Muted",
      type: "boolean",
      defaultValue: true,
      description: "If enabled, the video will play on mute by default",
    },
  };
}

export const customProperties = {
  height: "--rhe",
  aspectRatio: "--rar",
};

export const styleElements = {
  root: {
    overrideStyles({ styleProps }) {
      return mapAndConvertComponentStylePropsToStyle(styleProps, (styles) => {
        return {
          [customProperties.height]: styles.__hasHeight ? "100%" : "auto",
          [customProperties.aspectRatio]: styles.__hasHeight ? "auto" : "16/9",
        };
      });
    },
  },
} satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;

import classNames from "classnames";
import * as React from "react";
import { CgSpinner } from "react-icons/cg";
import { twMerge } from "tailwind-merge";

export type SpinnerProps = {
  type: "primary" | "secondary" | "danger";
  size?: number;
  className?: string;
  testId?: string;
  "aria-hidden"?: boolean;
  "aria-label"?: string;
  "aria-labelledby"?: string;
  role?: string;
};

const Spinner = ({
  type,
  className,
  size = 16,
  "aria-hidden": ariaHidden,
  "aria-label": ariaLabel,
  "aria-labelledby": ariaLabelledBy,
  role,
  testId,
}: SpinnerProps) => {
  const cn = twMerge(
    classNames(
      "animate-spin",
      {
        "text-blue-100": type === "primary",
        "text-slate-100": type === "secondary",
        "text-red-100": type === "danger",
      },
      className,
    ),
  );
  return (
    <CgSpinner
      size={size}
      className={cn}
      data-testid={testId}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      role={role}
    />
  );
};

export default Spinner;

import * as React from "react";

import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../shared/runtime-context";
import type { SharedLiquidReviewsProps } from "../../shared/types";
import { useProductFromProps } from "../hooks/useProductFromProps";
import { prependProductAssignmentToLiquidSource } from "../utils/reviews";
import { SharedLiquidReviewIntegrations } from "./SharedLiquidReviewIntegrations";

export const SharedLooxReviews = ({
  liquidSource,
  component,
  context,
  attributes,
  reviewsComponentType,
}: SharedLiquidReviewsProps) => {
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const globalWindow = useRuntimeContext(GlobalWindowContext);

  const product = useProductFromProps(component.props, context);

  const shouldHavePlaceHolder =
    isEditorApp &&
    // @ts-expect-error
    !globalWindow?.LOOX;

  const liquid = prependProductAssignmentToLiquidSource({
    product,
    liquidSource,
    context,
  });

  // Note (Fran 2022-11-03): The reviews widget would not work on editor because
  // loox have a whitelist urls for external sites. In shopify this will work.
  // Noah reach out to Loox support to avoid this placeholder, this should removed
  // when we integrated with they.
  // Thread https://replohq.slack.com/archives/C03AACVP08Y/p1667487129100229
  // Docs https://help.loox.io/article/416-how-do-i-white-list-an-external-domain-to-display-the-loox-iframe

  const forceEditorPlaceholder = reviewsComponentType === "Reviews";
  const placeholder = forceEditorPlaceholder
    ? "The Loox Reviews Widget will appear here when you publish. Something not right? Let us know at support@replo.app"
    : `Loox ${reviewsComponentType} Widget will appear here`;
  return (
    <SharedLiquidReviewIntegrations
      attributes={attributes}
      component={component}
      liquidSource={liquid}
      placeholder={placeholder}
      shouldHavePlaceHolder={shouldHavePlaceHolder || !product}
      appName="Loox"
      context={context}
    />
  );
};

import {
  Group,
  GroupHeader,
  GroupHeaderActionButton,
  GroupTitle,
  GroupTitleContainer,
} from "@editor/components/common/designSystem/Group";
import IconButton from "@editor/components/common/designSystem/IconButton";
import * as React from "react";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";

type CollapsibleGroupProps = {
  isCollapsible?: true;
  isDefaultOpen?: boolean;
  isOpen?: boolean;
  onOpenChange?: (value: boolean) => void;
  collapseEndEnhancer?: boolean;
};

type NotCollapsibleGroupProps = {
  isCollapsible: false;
  isDefaultOpen?: never;
  isOpen?: never;
  onOpenChange?: never;
  collapseEndEnhancer?: never;
};

type ContextProps = {
  name: string;
  isCollapsed: boolean;
};

const ModifierGroupContext = React.createContext<ContextProps | null>(null);
ModifierGroupContext.displayName = "ModifierGroupContext";

// NOTE (Chance 2023-11-10): This hook is for external components to read to
// know whether or not they are rendered inside of a modifier group
export function useModifierGroupContext() {
  return React.useContext(ModifierGroupContext);
}

type IconProps =
  | {
      icon: React.ComponentType | null | undefined;
      iconTooltip: string | null | undefined;
      iconAriaLabel?: string | null;
      iconShouldOpenModifierGroup?: boolean;
    }
  | {
      icon: React.ComponentType | null | undefined;
      iconTooltip?: never;
      iconAriaLabel: string | null | undefined;
      iconShouldOpenModifierGroup?: boolean;
    }
  | {
      icon?: never;
      iconTooltip?: never;
      iconAriaLabel?: never;
      iconShouldOpenModifierGroup?: never;
    };

type ModifierGroupProps = (CollapsibleGroupProps | NotCollapsibleGroupProps) &
  IconProps & {
    title: string;
    onClick?(isActive: boolean): void;
    isDisabled?: boolean;
    endEnhancer?: React.ReactNode;
    titleEnhancer?: React.ReactNode;
    titleClassName?: string;
    onClose?(): void;
  };

const ModifierGroup: React.FC<React.PropsWithChildren<ModifierGroupProps>> = ({
  title,
  onClick,
  icon,
  iconTooltip,
  isDisabled = false,
  endEnhancer,
  isCollapsible = true,
  isDefaultOpen = true,
  children,
  collapseEndEnhancer = true,
  titleEnhancer,
  titleClassName,
  iconShouldOpenModifierGroup = false,
}) => {
  const [isOpen, setIsOpen] = useOverridableState(
    isDefaultOpen,
    undefined,
    undefined,
  );

  const endEnhancers = (() => {
    const IconComponent = icon!;
    return (
      <>
        {icon ? (
          <GroupHeaderActionButton
            aria-label={iconTooltip!}
            onClick={() => {
              onClick?.(true);
              if (iconShouldOpenModifierGroup) {
                setIsOpen(true);
              }
            }}
          >
            <div className="flex flex-row items-center justify-end">
              <IconButton
                className="px-0"
                icon={<IconComponent />}
                tooltipText={iconTooltip}
                type="tertiary"
                isDisabled={isDisabled}
                // NOTE (Fran 2023-10-04): With need this to avoid nesting buttons.
                isPhonyButton
              />
            </div>
          </GroupHeaderActionButton>
        ) : null}
        {endEnhancer}
      </>
    );
  })();

  return (
    <ModifierGroupContext.Provider
      value={{ name: title, isCollapsed: isCollapsible && !isOpen }}
    >
      <Group
        name={title ?? ""}
        // NOTE (Chance 2023-11-08): Casting because I want to preserve the extra
        // type safety provided to consumer components by explicitly
        // allowing/disallowing certain props based on the value of
        // `isCollapsible` to prevent possible footguns.
        isCollapsible={isCollapsible as true}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        className="relative w-full"
        header={
          <GroupHeader
            endEnhancer={endEnhancers}
            titleEnhancer={titleEnhancer}
            collapseEndEnhancer={collapseEndEnhancer}
          >
            <GroupTitleContainer>
              <GroupTitle className={titleClassName} />
            </GroupTitleContainer>
          </GroupHeader>
        }
      >
        {children}
      </Group>
    </ModifierGroupContext.Provider>
  );
};

export default ModifierGroup;

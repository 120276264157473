import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const yotpoProductRating: ComponentTemplate = {
  id: "e1c35c8a-062c-424f-88dd-7ef623d55909",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Yotpo Product Rating",
  leftBarCategory: { main: "productIntegrations", subCategory: "yotpo" },
  preview: "integrations/yotpo-product-rating.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  tags: ["reviews", "star"],
  template: {
    id: "$uid",
    type: "yotpoProductRating",
    props: {},
  },
};

import type * as React from "react";
import { config, useSpring } from "react-spring";

/**
 * Hook that returns a standard spring animation setup.
 *
 * Pass in the properties you want to animate, and it will return the styles to pass
 * to animated.div, etc. E.g:
 *
 * ```
 * const styles = useStandardSpring({ opacity: isOpen ? 1 : 0 });
 * return <animated.dev style={styles}>Hello</animated.div>
 * ```
 *
 * We should generally prefer using this over the { to, from } spring setup, since
 * usually we just have purely reactive animations.
 */
export default function useStandardSpring(properties: React.CSSProperties) {
  return useSpring({
    config: config.stiff,
    ...properties,
  });
}

import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useGetStoreNameAndUrl from "@editor/hooks/useGetStoreNameAndUrl";
import { useOpenModal } from "@editor/hooks/useModal";
import usePaintSharedState, {
  useEditorReadableState,
} from "@editor/hooks/usePaintSharedState";
import { useStoreProductsFromDraftElement } from "@editor/hooks/useStoreProducts";
import { trackError } from "@editor/infra/analytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectLocaleData,
  selectOkendoWidgetNamespace,
  selectProductMetafieldValues,
  selectShopifyUrlRoot,
  selectVariantMetafieldValues,
} from "@editor/reducers/commerce-reducer";
import { selectElementIsLoading } from "@editor/reducers/core-reducer";
import { liquidRendererSlice } from "@editor/reducers/liquid-renderer-reducer";
import { getElementWithRevisionState } from "@editor/reducers/utils/core-reducer-utils";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { isDevelopment } from "@editor/utils/env";
import { trpc } from "@editor/utils/trpc";
import { skipToken } from "@tanstack/react-query";
import * as React from "react";
import { shallowEqual } from "react-redux";
import { RENDER_ENV_EDITOR } from "replo-runtime/shared/render-environment";
import type {
  CanvasElementsLoadingStateContextValue,
  ComponentErrorContextValue,
  ComponentInventoryContextValue,
  ComponentUpdateContextValue,
  CustomFontsContextValue,
  DataTablesContextValue,
  DraftElementContextValue,
  DynamicDataStore,
  DynamicDataStoreContextValue,
  EditorCanvasContextValue,
  EditorMediaUploadContextValue,
  EditorSelectionContextValue,
  ExtraContextValue,
  FeatureFlagsContextValue,
  RenderedLiquidContextValue,
  RenderEnvironmentContextEditor,
  RenderEnvironmentContextValue,
  ReploEditorActiveCanvasContextValue,
  ReploEditorCanvasContextValue,
  ReploElementContextValue,
  ReploSymbolsContextValue,
  RuntimeContextValue,
  RuntimeHooksContextValue,
  SelectedRevisionContextValue,
  SharedStateContextValue,
  ShopifyStoreContextValue,
  SwatchesContextValue,
  TemplateEditorProductContextValue,
} from "replo-runtime/shared/runtime-context";
import {
  CanvasElementsLoadingStateContext,
  ComponentErrorContext,
  ComponentInventoryContext,
  ComponentUpdateContext,
  CustomFontsContext,
  DataTablesContext,
  DraftElementContext,
  DynamicDataStoreContext,
  EditorCanvasContext,
  EditorMediaUploadContext,
  EditorSelectionContext,
  ExtraContext,
  FeatureFlagsContext,
  RenderedLiquidContext,
  RenderEnvironmentContext,
  ReploEditorActiveCanvasContext,
  ReploEditorCanvasContext,
  ReploElementContext,
  ReploSymbolsContext,
  RuntimeContextProvider,
  RuntimeHooksContext,
  SelectedRevisionContext,
  SharedStateContext,
  ShopifyStoreContext,
  SwatchesContext,
  TemplateEditorProductContext,
} from "replo-runtime/shared/runtime-context";
import { componentInventory } from "replo-runtime/store/componentInventory";
import { fakeProducts } from "replo-runtime/store/utils/fakeProducts";
import { canUseDOM } from "replo-utils/dom/misc";

import {
  selectActiveCanvas,
  selectCanvasInteractionMode,
  setCanvasInteractionMode,
} from "@/features/canvas/canvas-reducer";
import {
  useEditorOverrideText,
  useIsLabelledByOtherComponent,
  useIsPreviewMode,
  useRuntimeOverrideVariantId,
} from "@/features/canvas/stores/runtime";

// NOTE (Chance 2024-03-13): The following hooks are used to construct the
// context values that will be passed into all of the providers needed for
// runtime components in the editor. Each environment will have its own set of
// functions to serve a similar function, since the data sources for each
// environment are different.
//
// Also note that these all liberally use `useMemo` to return stable objects. We
// should be able to remove and simplify some of these, but I opted to keep them
// consistent for now since the code is very much in-flux and returned objects
// may change throughout the larger refactor process.

interface ProviderProps {
  children: React.ReactNode;
}

type ProviderPropsWithOptionalValue<T extends RuntimeContextValue> =
  ProviderProps & {
    ctx?: T | null;
  };

// #region CanvasElementsLoadingStateContext

export function useInitCanvasElementsLoadingStateContext() {
  const isLoading = useEditorSelector(selectElementIsLoading);
  const context = React.useMemo<CanvasElementsLoadingStateContextValue>(() => {
    return {
      // NOTE (Chance 2024-03-14): We don't currently deal with the "error"
      // state but we probably should.
      state: isLoading ? "loading" : "loaded",
    };
  }, [isLoading]);
  return context;
}

export function CanvasElementsLoadingStateProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<CanvasElementsLoadingStateContextValue>) {
  return (
    <RuntimeContextProvider
      context={CanvasElementsLoadingStateContext}
      value={ctx}
    >
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region ComponentErrorContext

export function useInitComponentErrorContext() {
  const openModal = useOpenModal();
  return React.useMemo<ComponentErrorContextValue>(() => {
    return {
      onComponentError: (componentId, error, reactErrorInfo) => {
        if (isDevelopment) {
          console.error("[REPLO] Component rendering error.", {
            componentId,
            error,
            reactErrorInfo,
          });
        }
        trackError(error);
        openModal({
          type: "fullPageErrorModal",
          props: {
            details: {
              header: "Unable to render content",
              subheader: "Something went wrong displaying this content.",
              message:
                "This usually indicates an internal error. If this error persists, please contact support@replo.app.",
            },
          },
        });
      },
    };
  }, [openModal]);
}

export function ComponentErrorProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<ComponentErrorContextValue>) {
  return (
    <RuntimeContextProvider context={ComponentErrorContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region ComponentInventoryContext

export function useInitComponentInventoryContext() {
  return React.useMemo<ComponentInventoryContextValue>(() => {
    return { componentInventory };
  }, []);
}

export function ComponentInventoryProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<ComponentInventoryContextValue>) {
  return (
    <RuntimeContextProvider context={ComponentInventoryContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region ComponentUpdateContext

export function useInitComponentUpdateContext() {
  const isContentEditing =
    useEditorSelector(selectCanvasInteractionMode) === "content-editing";
  const applyComponentAction = useApplyComponentAction();
  const dispatch = useEditorDispatch();

  type OnSubmit =
    ComponentUpdateContextValue["onSubmitContentEditableTextUpdate"];

  const setContentEditing = React.useCallback(
    (contentEditing: boolean) => {
      dispatch(
        setCanvasInteractionMode(contentEditing ? "content-editing" : "edit"),
      );
    },
    [dispatch],
  );

  const onSubmitContentEditableTextUpdate = React.useCallback<OnSubmit>(
    (componentId, htmlContent) => {
      setContentEditing(false);
      applyComponentAction({
        type: "setProps",
        componentId,
        value: {
          text: htmlContent,
        },
      });
    },
    [applyComponentAction, setContentEditing],
  );

  return React.useMemo<ComponentUpdateContextValue>(() => {
    return {
      isContentEditing,
      onSubmitContentEditableTextUpdate,
    };
  }, [onSubmitContentEditableTextUpdate, isContentEditing]);
}

export function ComponentUpdateProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<ComponentUpdateContextValue>) {
  return (
    <RuntimeContextProvider context={ComponentUpdateContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region CustomFontsContext

export function useInitCustomFontsContext() {
  const { storeId } = useStoreData();
  const { data: uploadedFonts } = trpc.store.getFonts.useQuery(
    storeId ? { storeId } : skipToken,
  );
  return React.useMemo<CustomFontsContextValue>(() => {
    return { uploadedFonts: uploadedFonts ?? [] };
  }, [uploadedFonts]);
}

export function CustomFontsProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<CustomFontsContextValue>) {
  return (
    <RuntimeContextProvider context={CustomFontsContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region DataTablesContext

export function useInitDataTablesContext() {
  const ctx: DataTablesContextValue = useEditorSelector((state) => {
    return {
      draftDataTable: state.core.dataTables.draft,
      mapping: state.core.dataTables.mapping,
    };
  }, shallowEqual);
  return ctx;
}

export function DataTablesProvider({
  ctx,
  children,
}: ProviderPropsWithOptionalValue<DataTablesContextValue>) {
  return (
    <RuntimeContextProvider context={DataTablesContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region DraftElementContext

export function useInitDraftElementContext() {
  const ctx: DraftElementContextValue = useEditorSelector((state) => {
    const elements = getElementWithRevisionState(state.core.elements);
    const draftElementId = elements.draftElementId ?? null;
    const draftElementComponentId = elements.draftComponentId ?? null;
    const { draftRepeatedIndex, draftSymbolInstanceId, draftSymbolId } =
      elements;
    return {
      draftElementId,
      draftRepeatedIndex,
      draftSymbolInstanceId,
      draftSymbolId,
      draftElementComponentId,
    };
  }, shallowEqual);
  return ctx;
}

export function DraftElementProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<DraftElementContextValue>) {
  return (
    <RuntimeContextProvider context={DraftElementContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region DynamicDataStoreContext

export function useInitDynamicDataStoreContext() {
  const [store, setStore] = React.useState<DynamicDataStore>({});
  return React.useMemo<DynamicDataStoreContextValue>(() => {
    return { store, setStore };
  }, [store]);
}

export function DynamicDataStoreProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<DynamicDataStoreContextValue>) {
  return (
    <RuntimeContextProvider context={DynamicDataStoreContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region ShopifyStoreContext

export function useInitShopifyStoreContext() {
  const {
    storeId,
    productMetafieldValues,
    variantMetafieldValues,
    activeCurrency,
    activeLanguage,
    activeShopifyUrlRoot,
    moneyFormat,
  } = useEditorSelector((state) => {
    const { activeCurrency, activeLanguage, moneyFormat } =
      selectLocaleData(state);
    const activeShopifyUrlRoot = selectShopifyUrlRoot(state);
    return {
      storeId: state.core.project?.id,
      productMetafieldValues: selectProductMetafieldValues(state),
      variantMetafieldValues: selectVariantMetafieldValues(state),
      activeCurrency,
      activeLanguage,
      activeShopifyUrlRoot,
      moneyFormat,
    };
  }, shallowEqual);
  const { products } = useStoreProductsFromDraftElement();
  const { storeUrl } = useGetStoreNameAndUrl();

  return React.useMemo<ShopifyStoreContextValue>(() => {
    return {
      storeId,
      storeUrl,
      templateProduct: null,
      products,
      productMetafieldValues,
      variantMetafieldValues,
      fakeProducts,
      activeCurrency,
      activeLanguage,
      moneyFormat,
      activeShopifyUrlRoot,
    };
  }, [
    storeId,
    storeUrl,
    products,
    productMetafieldValues,
    variantMetafieldValues,
    activeCurrency,
    activeLanguage,
    moneyFormat,
    activeShopifyUrlRoot,
  ]);
}

export function ShopifyStoreProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<ShopifyStoreContextValue>) {
  return (
    <RuntimeContextProvider context={ShopifyStoreContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region EditorSelectionContext

export function useInitEditorSelectionContext() {
  const ctx: EditorSelectionContextValue = useEditorSelector((state) => {
    return {
      selectedIds: state.selection.selectedIds,
      lastSelectedId: state.selection.lastSelectedId,
    };
  }, shallowEqual);
  return ctx;
}

export function EditorSelectionProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<EditorSelectionContextValue>) {
  return (
    <RuntimeContextProvider context={EditorSelectionContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region ExtraContext

export function useInitExtraContext() {
  const okendoNamespace = useEditorSelector(selectOkendoWidgetNamespace);
  const ctx: ExtraContextValue = React.useMemo<ExtraContextValue>(() => {
    return {
      overrideProductLiquidOff: false,
      runtimeVersion: null,
      okendoNamespace,
    };
  }, [okendoNamespace]);
  return ctx;
}

export function ExtraContextProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<ExtraContextValue>) {
  return (
    <RuntimeContextProvider context={ExtraContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region FeatureFlagsContext

export function useInitFeatureFlagsContext() {
  const carouselV4 = isFeatureEnabled("carousel-v4");
  const carouselDebug = isFeatureEnabled("carousel-debug");
  return React.useMemo<FeatureFlagsContextValue>(() => {
    return {
      featureFlags: {
        carouselV4,
        carouselDebug,
      },
    };
  }, [carouselV4, carouselDebug]);
}

export function FeatureFlagsProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<FeatureFlagsContextValue>) {
  return (
    <RuntimeContextProvider context={FeatureFlagsContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region RenderedLiquidContext

export function useInitRenderedLiquidContext() {
  const dispatch = useEditorDispatch();
  const { storeId, storeUrl } = useStoreData();
  const { mutate: renderLiquidPublisher } =
    trpc.mirror.renderLiquid.useMutation({
      onMutate(variables) {
        const { liquidSource } = variables;
        dispatch(
          liquidRendererSlice.actions.setRequestInProgress({
            liquidSource,
            inProgress: true,
          }),
        );
      },
      onSuccess: ({ html }, { liquidSource }) => {
        dispatch(
          liquidRendererSlice.actions.setLiquidRendererCache({
            html,
            liquidSource,
          }),
        );
      },
      onError: () => {
        dispatch(liquidRendererSlice.actions.setLiquidRendererCache());
      },
    });
  const { cache, requestsInProgress } = useEditorSelector(
    (state) => ({
      cache: state.liquidRenderer.renderedLiquidCache,
      requestsInProgress: state.liquidRenderer.requestsInProgress,
    }),
    shallowEqual,
  );

  const cacheRef = React.useRef(cache);
  const requestsInProgressRef = React.useRef(requestsInProgress);

  React.useEffect(() => {
    cacheRef.current = cache;
    requestsInProgressRef.current = requestsInProgress;
  }, [cache, requestsInProgress]);

  const requestRenderLiquid = React.useCallback(
    (liquidSource: string) => {
      if (
        storeId &&
        storeUrl &&
        !cacheRef.current[liquidSource] &&
        !requestsInProgressRef.current[liquidSource]
      ) {
        return void renderLiquidPublisher({
          liquidSource,
          storeId,
        });
      }
    },
    [storeId, storeUrl, renderLiquidPublisher],
  );

  return React.useMemo<RenderedLiquidContextValue>(() => {
    return {
      cache,
      requestRenderLiquid,
      requestsInProgress,
    };
  }, [cache, requestsInProgress, requestRenderLiquid]);
}

export function RenderedLiquidProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<RenderedLiquidContextValue>) {
  return (
    <RuntimeContextProvider context={RenderedLiquidContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region RenderEnvironmentContext

export type EditorRenderEnvironmentContextValue =
  RenderEnvironmentContextValue & {
    isEditorApp: true;
  };

export function useInitRenderEnvironmentContext(): RenderEnvironmentContextEditor {
  return React.useMemo<EditorRenderEnvironmentContextValue>(() => {
    return {
      isEditorApp: true,
      isElementPreview: false,
      isPublishedPage: false,
      isPublishing: false,
      previewEnvironment: "editor",
      renderEnvironment: RENDER_ENV_EDITOR,
    } satisfies EditorRenderEnvironmentContextValue;
  }, []);
}

export function RenderEnvironmentProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<EditorRenderEnvironmentContextValue>) {
  return (
    <RuntimeContextProvider context={RenderEnvironmentContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region ReploEditorActiveCanvasContext

export function useInitReploEditorActiveCanvasContext() {
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const ctx: ReploEditorActiveCanvasContextValue = React.useMemo(() => {
    return { activeCanvas };
  }, [activeCanvas]);
  return ctx;
}

export function ReploEditorActiveCanvasProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<ReploEditorActiveCanvasContextValue>) {
  return (
    <RuntimeContextProvider
      context={ReploEditorActiveCanvasContext}
      value={ctx}
    >
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region ReploEditorCanvasContext

export function useInitReploEditorCanvasContext() {
  // TODO (Chance 2024-03-14): Stop doing this and get values from the store.
  // Keeping for back-compat right now. This may cause problems when we remove
  // Repainter since this is not stateful.
  const editorCanvasScale = canUseDOM
    ? window.alchemyEditor?.canvasScale ?? 0
    : 0;
  const editorCanvasYOffset = canUseDOM
    ? window.alchemyEditor?.canvasYOffset ?? 0
    : 0;

  return React.useMemo<ReploEditorCanvasContextValue>(() => {
    return {
      editorCanvasYOffset,
      editorCanvasScale,
    };
  }, [editorCanvasYOffset, editorCanvasScale]);
}

export function ReploEditorProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<ReploEditorCanvasContextValue>) {
  return (
    <RuntimeContextProvider context={ReploEditorCanvasContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region ReploElementContext

export function useInitReploElementContext() {
  const ctx: ReploElementContextValue = useEditorSelector((state) => {
    const { mapping, draftElementId } = getElementWithRevisionState(
      state.core.elements,
    );
    const draftElement = draftElementId ? mapping[draftElementId] : null;

    return {
      elementType: draftElement?.type ?? "page",
      useSectionSettings: undefined,
    };
  }, shallowEqual);
  return ctx;
}

export function ReploElementProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<ReploElementContextValue>) {
  return (
    <RuntimeContextProvider context={ReploElementContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region ReploSymbolsContext

export function useInitReploSymbolsContext() {
  const symbols = useEditorSelector(
    (state) => Object.values(state.core.symbols.mapping),
    shallowEqual,
  );
  return React.useMemo<ReploSymbolsContextValue>(() => {
    return { symbols };
  }, [symbols]);
}

export function ReploSymbolsProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<ReploSymbolsContextValue>) {
  return (
    <RuntimeContextProvider context={ReploSymbolsContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region SelectedRevisionContext

export function useInitSelectedRevisionContext() {
  const ctx: SelectedRevisionContextValue = useEditorSelector((state) => {
    return {
      selectedRevisionId: state.core.elements.selectedRevisionId,
    };
  }, shallowEqual);
  return ctx;
}

export function SelectedRevisionProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<SelectedRevisionContextValue>) {
  return (
    <RuntimeContextProvider context={SelectedRevisionContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region SharedStateContext

export function useInitSharedStateContext() {
  const { sharedState, setSharedState } = usePaintSharedState();
  const { setEditorReadableState } = useEditorReadableState();
  const ctx = React.useMemo<SharedStateContextValue>(() => {
    return {
      sharedState,
      setSharedState,
      setEditorReadableState,
    };
  }, [sharedState, setSharedState, setEditorReadableState]);
  return ctx;
}

export function SharedStateProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<SharedStateContextValue>) {
  return (
    <RuntimeContextProvider context={SharedStateContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region SwatchesContext

export function useInitSwatchesContext() {
  const projectId = useCurrentProjectId();
  const { data: swatches } = trpc.swatch.list.useQuery(
    projectId ? { projectId } : skipToken,
  );
  return React.useMemo(() => {
    return { swatches: swatches ?? [] };
  }, [swatches]);
}

export function SwatchesProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<SwatchesContextValue>) {
  return (
    <RuntimeContextProvider context={SwatchesContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region TemplateEditorProductContext

export function useInitTemplateEditorProductContext() {
  const ctx: TemplateEditorProductContextValue = useEditorSelector((state) => {
    return {
      templateEditorProduct: state.template.templateEditorProduct,
    };
  }, shallowEqual);
  return ctx;
}

export function TemplateEditorProductProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<TemplateEditorProductContextValue>) {
  return (
    <RuntimeContextProvider context={TemplateEditorProductContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

// #region EditorMediaUploadContext
export function useInitEditorMediaUploadContext() {
  const editorMediaUploadingComponentIds = useEditorSelector(
    (state) => state.editorMediaUpload.componentIds,
  );
  return React.useMemo<EditorMediaUploadContextValue>(() => {
    return { editorMediaUploadingComponentIds };
  }, [editorMediaUploadingComponentIds]);
}

export function EditorMediaUploadProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<EditorMediaUploadContextValue>) {
  return (
    <RuntimeContextProvider context={EditorMediaUploadContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}
// #endregion

// #region EditorCanvasContext
export function EditorCanvasProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<EditorCanvasContextValue>) {
  return (
    <RuntimeContextProvider context={EditorCanvasContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}
// #endregion

// #region RuntimeHooksContext

export function useInitRuntimeHooksContext() {
  return React.useMemo<RuntimeHooksContextValue>(() => {
    return {
      useEditorOverrideActiveVariantId: (componentId: string) => {
        // biome-ignore lint/correctness/useHookAtTopLevel: Biome is wrong about this hook, this is actually top-level
        return useRuntimeOverrideVariantId(componentId) ?? null;
      },
      useEditorOverrideTextValue(componentId: string) {
        return useEditorOverrideText(componentId) ?? null;
      },
      useIsEditorEditModeRenderEnvironment: () => {
        // biome-ignore lint/correctness/useHookAtTopLevel: Biome is wrong about this hook, this is actually top-level
        const isPreviewMode = useIsPreviewMode();
        return !isPreviewMode;
      },
      useIsLabelledByOtherComponent(componentId: string) {
        return useIsLabelledByOtherComponent(componentId);
      },
    };
  }, []);
}

export function RuntimeHooksContextProvider({
  children,
  ctx,
}: ProviderPropsWithOptionalValue<RuntimeHooksContextValue>) {
  return (
    <RuntimeContextProvider context={RuntimeHooksContext} value={ctx}>
      {children}
    </RuntimeContextProvider>
  );
}

// #endregion

function useStoreData() {
  const storeId = useEditorSelector((state) => state.core.project?.id);
  const { storeUrl, storeName } = useGetStoreNameAndUrl();
  return React.useMemo(() => {
    return {
      storeId,
      storeUrl,
      storeName,
    };
  }, [storeId, storeUrl, storeName]);
}

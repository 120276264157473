import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const klaviyoEmbed: ComponentTemplate = {
  id: "klaviyoEmbed",
  storeId: null,
  scope: "left-bar",
  type: "component",
  env: Env.PRODUCTION,
  name: "Form/Klaviyo Form Embed",
  leftBarCategory: { main: "pageIntegrations", subCategory: "form" },
  preview: "integrations/klaviyo-form-embed.svg",
  template: {
    id: "7db0c790-d096-4124-9935-198ae32d822f",
    type: "klaviyoEmbed",
    props: {},
  },
};

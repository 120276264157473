import { trpc } from "@editor/utils/trpc";
import { skipToken } from "@tanstack/react-query";
import * as React from "react";
import type { AnalyticsReadQuery } from "schemas/analyticsRead";

const useAnalyticsRead = (
  query: AnalyticsReadQuery,
  workspaceId: string | null,
) => {
  const {
    data,
    isLoading,
    refetch: refetchRangeResults,
  } = trpc.analytics.find.useQuery(
    workspaceId ? { query, workspaceId } : skipToken,
  );

  const [rangeResults, setRangeResults] = React.useState(data?.rangeResults);
  const [totalRowsCount, setTotalRowsCount] = React.useState(
    data?.totalRowsCount,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: We want this for now
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await refetchRangeResults();
      setRangeResults(response.data?.rangeResults);
      setTotalRowsCount(response.data?.totalRowsCount);
    };

    void fetchData();
  }, [query, workspaceId, refetchRangeResults]);
  return {
    rangeResults: rangeResults ?? null,
    totalRowsCount: totalRowsCount ?? null,
    isLoading,
  };
};

export default useAnalyticsRead;

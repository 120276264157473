import { errorToast } from "@editor/components/common/designSystem/Toast";
import { FullScreenLoader } from "@editor/components/common/FullScreenLoader";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { initWorkspaceBasedAnalytics } from "@editor/infra/analytics";
import { trpc } from "@editor/utils/trpc";
import * as React from "react";
import { Navigate } from "react-router-dom";

/**
 * Provides data, access, and analytics for the current workspace.
 *
 * NOTE (Fran 2024-02-23): If the user is not member of the requested workspace, we redirect to the
 * first workspace the user is member of. We will default to home if the user is not part of any
 * workspace.
 *
 * NOTE Ben 2024-08-16: We initialize workspace analytics here because all workspace-based
 * components (tabs for analytics + settings, etc) are used as interior components.
 */
export const WorkspaceProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const workspaceId = useCurrentWorkspaceId();
  const { data, isSuccess, isLoading, isFetching } =
    trpc.workspace.getUserWorkspacesList.useQuery();
  const { user } = useCurrentUser();
  const workspaces = data?.workspaces ?? [];
  const workspace = workspaces.find(({ id }) => id === workspaceId);
  const userIsMemberOfRequestedWorkspace = Boolean(workspace);
  const workspaceToRedirect = workspaces[0];

  React.useEffect(() => {
    if (!isFetching && !userIsMemberOfRequestedWorkspace && isSuccess) {
      errorToast(
        "User cannot access Workspace",
        "Please try again or reach out to support@replo.app for help.",
      );
    }
  }, [userIsMemberOfRequestedWorkspace, isSuccess, isFetching]);

  React.useEffect(() => {
    if (workspace && isSuccess) {
      initWorkspaceBasedAnalytics(workspace);
    }
  }, [workspace, isSuccess]);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  if (userIsMemberOfRequestedWorkspace || user?.isSuperuser) {
    return <>{children}</>;
  }

  if (!userIsMemberOfRequestedWorkspace && isSuccess && workspaceToRedirect) {
    return (
      <Navigate to={`/workspace/${workspaceToRedirect.id}/projects`} replace />
    );
  }

  return <Navigate to="/home" replace />;
};

// This file is generated; do not edit directly

/* eslint-disable simple-import-sort/imports, @typescript-eslint/consistent-type-imports, unicorn/import-style, import/newline-after-import */
import * as React from "react";
import { SVGProps, Ref, forwardRef, memo } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={0}
    height={0}
    ref={ref}
    {...props}
  >
    <defs>
      <symbol viewBox="0 0 16 16" id="size-max">
        <path d="M.636 5.91h.933l1.62 3.956h.06l1.62-3.957h.933V11h-.73V7.316h-.048l-1.502 3.677h-.606l-1.501-3.68h-.048V11h-.73zM7.35 11h-.816l1.832-5.09h.888L11.085 11h-.816L8.83 6.834h-.04zm.136-1.994h2.645v.647H7.485zm4.87-3.097L13.572 7.9h.04l1.218-1.99h.89l-1.586 2.545L15.73 11h-.895l-1.223-1.964h-.04L12.35 11h-.895l1.623-2.545-1.613-2.546z" />
      </symbol>
      <symbol viewBox="0 0 16 16" id="size-min">
        <path d="M1.86 5.91h.932l1.621 3.956h.06l1.62-3.957h.933V11h-.731V7.316h-.047l-1.502 3.677H4.14l-1.502-3.68h-.047V11h-.73zm7.05 0V11h-.768V5.91zm5.228 0V11h-.706l-2.588-3.734h-.047V11h-.768V5.91h.711l2.59 3.738h.047V5.909z" />
      </symbol>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export { Memo as IconSprite };

import { Spinner } from "@replo/design-system/components/spinner";
import * as React from "react";

export function FullScreenLoader() {
  return (
    <div className="position--center z-max fixed h-screen w-screen bg-white">
      <Spinner width={2} size={50} />
    </div>
  );
}

import Button from "@common/designSystem/Button";
import { ModalLayout } from "@common/ModalLayout";
import AskAIHeader from "@components/editor/ai/AskAiHeader";
import { prepareComponentTemplate } from "@components/editor/defaultComponentTemplates";
import PageElementEditor from "@components/editor/elementEditors/PageElementEditor";
import AIModalContent from "@components/modals/AIModalContent";
import Modal from "@editor/components/common/designSystem/Modal";
import { AskAILoading } from "@editor/components/modals/AskAIModal";
import { ElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import {
  useElementEditorErrorContext,
  withElementEditorErrorContext,
} from "@editor/contexts/ElementEditorErrorContext";
import { useCreateElement } from "@editor/hooks/useCreateElement";
import useElementPartial from "@editor/hooks/useElementPartial";
import { useGetAttribute } from "@editor/hooks/useGetAttribute";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import { useModal } from "@editor/hooks/useModal";
import { useSpecificStoreProducts } from "@editor/hooks/useStoreProducts";
import { analytics } from "@editor/infra/analytics";
import type { PageTopic } from "@editor/reducers/ai-reducer";
import {
  goals,
  goalsSchema,
  pageTopics,
  pageTopicsSchema,
  tones,
  tonesSchema,
  useAskAiMutation,
} from "@editor/reducers/ai-reducer";
import { selectLocaleData } from "@editor/reducers/commerce-reducer";
import {
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectPages,
  selectProjectId,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { useEditorSelector } from "@editor/store";
import type { ElementErrorType } from "@editor/utils/element";
import {
  elementFormHasErrors,
  formatElementNameWithFolderName,
} from "@editor/utils/element";
import type { EditorRoute } from "@editor/utils/router";
import { routes } from "@editor/utils/router";
import { getAiTemplate } from "@editor/utils/template";
import validatePageModalPath from "@editor/utils/validatePageModalPath";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import type { Component } from "replo-runtime/shared/Component";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { getProduct } from "replo-runtime/store/ReploProduct";
import { removeFolderNameFromElementName } from "replo-utils/element";
import {
  exhaustiveSwitch,
  isNotNullish,
  isNullish,
} from "replo-utils/lib/misc";
import { slugify } from "replo-utils/lib/string";
import type { ReploElement } from "schemas/element";
import { z } from "zod";

import type { AIPageModalProps } from "../AppModalTypes";

export const AIPageModal: React.FC<AIPageModalProps> =
  withElementEditorErrorContext(({ initialName }) => {
    const [selectedTopic, setSelectedTopic] = useLocalStorageState(
      "replo.ai.selectedTopic",
      null,
      { schema: pageTopicsSchema },
    );
    const [selectedTone, setSelectedTone] = useLocalStorageState(
      "replo.ai.selectedTone",
      null,
      { schema: tonesSchema },
    );
    const [customTone, setCustomTone] = useLocalStorageState(
      "replo.ai.customTone",
      null,
      { schema: z.string() },
    );
    const [selectedGoal, setSelectedGoal] = useLocalStorageState(
      "replo.ai.selectedGoal",
      null,
      { schema: goalsSchema },
    );
    const [selectedProductRef, setSelectedProductRef] = useLocalStorageState(
      "replo.ai.selectedProductRef",
      null,
      {
        schema: z.object({
          productId: z.union([z.string(), z.number()]),
          variantId: z.number().optional(),
        }),
      },
    );
    const [additionalContext, setAdditionalContext] = useLocalStorageState(
      "replo.ai.additionalContext",
      null,
      { schema: z.string() },
    );
    const [postAskAi, { isLoading: askAiIsLoading }] = useAskAiMutation();
    const { errorMapping, setErrors, clearErrors } =
      useElementEditorErrorContext();

    const projectId = useEditorSelector(selectProjectId);
    const {
      activeCurrency: currencyCode,
      activeLanguage: language,
      moneyFormat,
    } = useEditorSelector(selectLocaleData);
    const modal = useModal();
    const getAttribute = useGetAttribute();
    const { products, isLoading: isLoadingProducts } = useSpecificStoreProducts(
      selectedProductRef ? [Number(selectedProductRef.productId)] : [],
      { forceSkip: isNullish(selectedProductRef) },
    );
    const templateProduct =
      useEditorSelector(selectTemplateEditorStoreProduct) ?? null;
    const productResolutionDependencies = {
      products,
      currencyCode,
      language,
      moneyFormat,
      templateProduct,
    };
    const pages = useEditorSelector(selectPages);
    const editingElement = useEditorSelector(
      selectDraftElement_warningThisWillRerenderOnEveryUpdate,
    );
    const navigate = useNavigate();
    const template = getAiTemplate("page");

    const { createElement } = useCreateElement();

    const [element, setElement] = useElementPartial(
      "new",
      "page",
      editingElement as ReploElement,
      pages,
    );

    const controllerRef = React.useRef<AbortController>();
    React.useEffect(() => {
      return () => {
        if (controllerRef.current) {
          controllerRef.current.abort();
        }
      };
    }, []);

    const createElementCallback = async () => {
      if (!projectId) {
        return;
      }
      const elementHasFolderNameSetWithoutName = element.name
        ? element.name.indexOf("/") === element.name.length - 1
        : false;

      const titleErrors: ElementErrorType[] = [];
      const pathErrors: ElementErrorType[] = [];
      if (
        elementHasFolderNameSetWithoutName ||
        !Boolean(element?.shopifyPagePath)
      ) {
        titleErrors.push("nameIsEmpty");
      }
      if (!Boolean(element?.shopifyPagePath)) {
        pathErrors.push("pathIsEmpty");
      }
      if (validatePageModalPath(element.shopifyPagePath ?? "")) {
        pathErrors.push("isInvalidPathName");
      }
      if (pathErrors.length > 0 || titleErrors.length > 0) {
        setErrors("title", titleErrors);
        setErrors("path", pathErrors);
        return;
      }

      clearErrors("path", "allErrorsFromAllFormKeys");

      controllerRef.current = new AbortController();
      const product = selectedProductRef
        ? getProduct(
            selectedProductRef,
            getCurrentComponentContext(null, 0) ?? null,
            {
              productMetafieldValues: {},
              variantMetafieldValues: {},
              products,
              currencyCode,
              language,
              moneyFormat,
              templateProduct,
            },
          )
        : null;
      let tone: string;
      if (selectedTone === "other") {
        tone = customTone ?? "";
      } else {
        tone = selectedTone ?? "";
      }
      const aiResponse = await postAskAi({
        storeId: projectId,
        payload: {
          meta: {
            model: "gpt-4o-mini",
          },
          operation: "generate",
          type: "page",
          options: {
            context: {
              topic: (selectedTopic as PageTopic) ?? undefined,
              tone: tone,
              goal: selectedGoal ?? undefined,
              product: product ?? undefined,
              additionalContext: additionalContext ?? undefined,
            },
          },
        },
      });
      if (!isValidResponse(aiResponse)) {
        return;
      }

      const aborted = controllerRef.current?.signal.aborted;
      if (aborted) {
        return;
      }

      analytics.logEvent("element.new", {
        operation: "create",
        type: element.type!,
        creationMethod: "ai",
      });

      template.template = aiResponse.data.component;

      const response = await createElement({
        ...element,
        name: formatElementNameWithFolderName(element.name),
        component: prepareComponentTemplate(template, null, editingElement, {
          getAttribute,
          productResolutionDependencies,
          context: null,
        }),
      });
      if ("key" in response && response.key === "pathAlreadyInUse") {
        return setErrors("path", ["pathAlreadyExists"]);
      }
      if (response.element?.id) {
        modal.closeModal({});
        navigate(
          generatePath(routes.editor.element, {
            projectId: element.storeId,
            elementId: response.element.id,
          } as EditorRoute),
        );
      }

      if (pages.length === 0 && typeof window !== "undefined") {
        window.location.reload();
      }
    };

    const aiFormComplete =
      [selectedTopic, selectedTone, selectedGoal].every((x) =>
        isNotNullish(x),
      ) && !isLoadingProducts;

    // NOTE (Sebas, 2023-12-05): We want this to be a ref because we are calculating this
    // using the element.name, which will change every time you type something in the input.
    // This causes the initialName to reappear every time you type something in the input
    // and we don't want that. This is related to the effect below.
    const elementNameWithFolderIfNeeded = React.useRef(
      isNotNullish(initialName)
        ? `${initialName}${removeFolderNameFromElementName(element.name ?? "")}`
        : element.name,
    );

    // NOTE (Sebas 2023-11-09): This effect exists so that when this modal is
    // opened with an initialName we can set it as the element name.
    React.useEffect(() => {
      if (elementNameWithFolderIfNeeded.current) {
        setElement((element) => ({
          ...element,
          name: element.name || elementNameWithFolderIfNeeded.current,
          shopifyPagePath:
            element.shopifyPagePath ||
            slugify(
              removeFolderNameFromElementName(
                elementNameWithFolderIfNeeded.current,
              ),
            ),
        }));
      }
    }, [setElement]);

    return (
      <Modal
        isOpen={true}
        className="w-auto"
        onRequestClose={() => {
          modal.closeModal({});
        }}
      >
        <ModalLayout
          height="80vh"
          width="60vw"
          headerContent={
            <AskAIHeader
              whiteOnHover={false}
              content="Generate a Page"
              showIcon
            />
          }
          mainContent={() => {
            return askAiIsLoading ? (
              // Note (Noah, 2023-07-13): As of today, the average time to generate a
              // page with Replo AI is about 55 seconds. We can update this value if it changes
              <AskAILoading expectedMillisecondsToComplete={55_000} />
            ) : (
              <div
                className="flex h-full flex-col bg-white"
                data-testid="template-modal"
              >
                <ElementEditorDataContext.Provider
                  value={{ element, onChangeElement: setElement }}
                >
                  <PageElementEditor
                    requestType="new"
                    showLayoutSelector={false}
                    canEditSEOSettings={false}
                  />
                </ElementEditorDataContext.Provider>
                <div className="m-4 flex h-full flex-col gap-5 bg-white">
                  <AIModalContent
                    type="page"
                    selectedTopic={selectedTopic}
                    topicOptions={pageTopics.map((topic) => ({
                      value: topic,
                      label: exhaustiveSwitch({ type: topic })({
                        advertorial: "📙 Advertorial",
                        listicle: "📙 Listicle",
                        longFormProductOffer: "📜 Long Form Offer Page",
                        videoSalesLetter: "🎥 Video Sales Letter",
                        productDropPage: "👟 Product Drop Page",
                        faq: "🤔 FAQ",
                      }),
                      tooltip: exhaustiveSwitch({ type: topic })({
                        advertorial:
                          "Long-form pre-sale page that looks like organic content that educates customers on why your product solves a specific problem/gives a benefit.",
                        listicle:
                          "Short-form, pre-sale page similar to “Buzzfeed” content that educates customers on why your product solves a specific problem/benefit in a numbered list.",
                        longFormProductOffer:
                          "The classic landing page that educates consumers on your offer/product, why it's better than the competitors, and why they should buy today.",
                        videoSalesLetter:
                          "A classic landing page with a video at the top that does most of the selling for your product/offer.",
                        productDropPage:
                          "A sales page designed for one-time, time-sensitive offers and product launches.",
                        faq: "A page that answers your product/offer's frequently asked questions.",
                      }),
                    }))}
                    setTopic={(topic: PageTopic | null) =>
                      setSelectedTopic(topic)
                    }
                    toneOptions={tones.map((tone) => ({
                      value: tone,
                      label: exhaustiveSwitch({ type: tone })({
                        sassy: "🙃 Sassy",
                        casual: "😎 Casual",
                        professional: "👔 Professional",
                        confident: "🤩 Confident",
                        joyful: "😊 Joyful",
                        friendly: "🤗 Friendly",
                        direct: "🫵 Direct",
                        other: "Something Else",
                      }),
                    }))}
                    selectedTone={selectedTone}
                    setTone={(tone) => setSelectedTone(tone)}
                    customTone={customTone}
                    setCustomTone={(customTone) => setCustomTone(customTone)}
                    goalOptions={goals.map((goal) => ({
                      value: goal,
                      label: exhaustiveSwitch({ type: goal })({
                        maximizeConversion: "💸 Maximize Conversion",
                        maximizeCartValue: "💰 Maximize Cart Value",
                        maximizeCartSize: "🛒 Maximize Cart Size",
                        tellBrandStory: "📚 Tell Brand Story",
                        educateUser: "🎓 User Education",
                        lowerBounceRate: "👌 Lower Bounce Rate",
                      }),
                    }))}
                    selectedGoal={selectedGoal}
                    setGoal={(goal) => setSelectedGoal(goal)}
                    selectedProductRef={selectedProductRef}
                    setSelectedProductRef={(productRef) =>
                      setSelectedProductRef(productRef)
                    }
                    additionalContext={additionalContext}
                    setAdditionalContext={(additionalContext) =>
                      setAdditionalContext(additionalContext)
                    }
                  />
                </div>
              </div>
            );
          }}
          mainContentClassnames="mb-6"
          footerContent={
            askAiIsLoading
              ? undefined
              : () => {
                  return (
                    <div className="flex w-full flex-row justify-end">
                      <Button
                        type="primary"
                        onClick={() => {
                          void createElementCallback();
                        }}
                        isDisabled={
                          !aiFormComplete || elementFormHasErrors(errorMapping)
                        }
                        className="w-32"
                        size="base"
                        data-testid="select-template-button"
                        isLoading={isLoadingProducts}
                      >
                        Make Magic
                      </Button>
                    </div>
                  );
                }
          }
        />
      </Modal>
    );
  });

const isValidResponse = (
  response: any,
): response is { data: { component: Component } } => response?.data ?? false;

import type { EditorDispatch, EditorRootState } from "@editor/store";
import { getAlchemyEditorWindow } from "replo-runtime/shared/Window";

import {
  selectCanvasWillChangeTimeoutId,
  setWillChangeStatus,
  setWillChangeTimeoutId,
} from "./canvas-reducer";

/**
 * This action manages the canvas's willChange status, with the goal of
 * setting will-change to "transform" before a transform (zoom/scroll/pan)
 * and resetting it to "auto" after some span of time. This is what Tailwind
 * (and others) recommend: https://tailwindcss.com/docs/will-change#breakpoints-and-media-queries.
 *
 * When this action is dispatched, we do the following:
 * - clear the timeout by the ID in the state (if there is one)
 * - set the status to "transform"
 * - create a new timeout that sets the status to "auto" after a specified duration
 * - set the timeoutId state to the timeout we just created
 *
 * We should dispatch this action before any transform.
 */

const TIMEOUT_DURATION = 500;

export const handleTransformWillChange =
  () => (dispatch: EditorDispatch, getState: () => EditorRootState) => {
    const window = getAlchemyEditorWindow();
    if (!window) {
      return;
    }
    // Note (Evan, 2024-05-10): Clear any previous timeout
    const canvasWillChangeTimeoutId =
      selectCanvasWillChangeTimeoutId(getState());
    if (canvasWillChangeTimeoutId) {
      window.clearTimeout(canvasWillChangeTimeoutId);
      dispatch(setWillChangeTimeoutId(null));
    }

    dispatch(setWillChangeStatus("transform"));

    const newTimerId = window.setTimeout(() => {
      dispatch(setWillChangeStatus("auto"));
      dispatch(setWillChangeTimeoutId(null));
    }, TIMEOUT_DURATION);

    dispatch(setWillChangeTimeoutId(newTimerId));
  };

import Checkbox from "@common/Checkbox";
import Button from "@common/designSystem/Button";
import InlinePopover from "@common/designSystem/InlinePopover";
import InputComponent from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import {
  selectOpenPopoverId,
  setOpenPopoverId,
} from "@editor/reducers/ui-reducer";
import { useEditorSelector } from "@editor/store";
import { isSelectionEmpty } from "@editor/utils/rte";
import type { Editor } from "@tiptap/react";
import classNames from "classnames";
import * as React from "react";
import type { IconType } from "react-icons";
import { BsLink45Deg } from "react-icons/bs";
import { useDispatch } from "react-redux";
import type { LinkData } from "replo-runtime/shared/types";

export const ToolbarButton = ({
  icon,
  onClick,
  tooltipText,
  isActive,
}: {
  icon: IconType;
  onClick?: () => void;
  tooltipText: string;
  isActive: boolean;
}) => {
  const Icon = icon;
  return (
    <Button
      type="tertiary"
      className={classNames("bg-subtle p-0", {
        "bg-accent text-onEmphasis hover:bg-accent hover:text-onEmphasis":
          isActive,
      })}
      hasMinDimensions={false}
      tooltipText={tooltipText}
      onClick={onClick}
    >
      <Icon size={16} />
    </Button>
  );
};

const INITIAL_DATA: LinkData = {
  url: "",
  isUnderlined: true,
  isNewTab: true,
};

const TipTapToolbarLink: React.FC<
  React.PropsWithChildren<{
    editor: Editor | null;
    isActive: boolean;
  }>
> = ({ editor, isActive }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [linkData, setLinkData] = React.useState<LinkData>(INITIAL_DATA);

  React.useEffect(() => {
    if (isVisible) {
      setLinkData((linkData) => ({
        ...linkData,
        url: editor?.getAttributes("link").href,
        isUnderlined: editor?.isActive("underline") ?? true,
        isNewTab: editor?.getAttributes("link").target === "_blank",
      }));
    }
  }, [isVisible, editor]);

  const setLink = React.useCallback(() => {
    const { url, isUnderlined, isNewTab } = linkData;
    const isPreviouslyUnderlined = editor?.isActive("underline");
    let focusedChain = editor?.chain().focus();
    if (isSelectionEmpty(editor)) {
      focusedChain = focusedChain?.selectAll();
    }
    // cancelled
    if (url === null) {
      return;
    }
    if (url === "") {
      // empty
      focusedChain = focusedChain?.extendMarkRange("link").unsetLink();
    } else {
      // update link
      focusedChain = focusedChain?.extendMarkRange("link").setLink({
        href: url,
        target: isNewTab ? "_blank" : undefined,
      });
    }

    if (isUnderlined && !isPreviouslyUnderlined) {
      focusedChain = focusedChain?.setUnderline();
    } else if (!isUnderlined && isPreviouslyUnderlined) {
      focusedChain = focusedChain?.unsetUnderline();
    }

    focusedChain?.run();
  }, [editor, linkData]);
  const openPopoverId = useEditorSelector(selectOpenPopoverId);
  const dispatch = useDispatch();
  const isDefaultOpen = openPopoverId === "tiptap-toolbar-link";

  const renderContent = () => {
    const { url, isUnderlined, isNewTab } = linkData;

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setLink();
          setIsVisible(false);
          setLinkData(INITIAL_DATA);
        }}
        className="flex flex-col gap-4"
      >
        <LabeledControl label="URL" size="sm">
          <InputComponent
            size="sm"
            value={url}
            onChange={(e) =>
              setLinkData((prevData) => ({ ...prevData, url: e.target.value }))
            }
            autoFocus
          />
        </LabeledControl>
        <div className="flex items-center justify-between text-xs">
          <Checkbox
            isChecked={isUnderlined}
            onChange={() =>
              setLinkData((prevData) => ({
                ...prevData,
                isUnderlined: !prevData.isUnderlined,
              }))
            }
            className="mr-0"
          >
            Underline the link content
          </Checkbox>
        </div>
        <div className="flex items-center justify-between text-xs">
          <Checkbox
            isChecked={isNewTab}
            onChange={() =>
              setLinkData((prevData) => ({
                ...prevData,
                isNewTab: !prevData.isNewTab,
              }))
            }
            className="mr-0"
          >
            Open Link In New Tab
          </Checkbox>
        </div>
        <div className="flex flex-row items-center justify-end gap-2">
          <Button
            type="secondary"
            onClick={() => {
              setIsVisible(false);
              setLinkData(INITIAL_DATA);
              if (isDefaultOpen) {
                dispatch(setOpenPopoverId(null));
              }
            }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    );
  };

  return (
    <InlinePopover
      shouldPreventDefaultOnInteractOutside={false}
      isOpen={isVisible || isDefaultOpen}
      onOpenChange={() => {
        // NOTE (Juan, 2024-06-18): Once the popover is closed, we should reset the open popover state
        // to prevent from opening it again automatically.
        if (isDefaultOpen) {
          return dispatch(setOpenPopoverId(null));
        }
        setIsVisible(!isVisible);
      }}
      title="Configure Link"
      content={renderContent()}
      triggerAsChild
    >
      <Button
        type="tertiary"
        className={classNames("bg-subtle p-0", {
          "bg-accent text-onEmphasis hover:bg-accent hover:text-onEmphasis":
            isActive,
        })}
        hasMinDimensions={false}
        tooltipText="Set Link"
      >
        <BsLink45Deg size={16} />
      </Button>
    </InlinePopover>
  );
};

export default TipTapToolbarLink;

import * as React from "react";
import { Svg, type SvgProps } from "replo-utils/react/components";

export const SvgWidthIcon = (props: SvgProps) => (
  <Svg width="1em" height="1em" viewBox="0 0 9 6" fill="none" {...props}>
    <path
      fill="#94A3B8"
      stroke="#94A3B8"
      d="M7.5 3V2.207L8.293 3l-.793.793V3Z"
    />
    <path stroke="#94A3B8" d="M1 3h7M1 0v6" />
  </Svg>
);
export default SvgWidthIcon;

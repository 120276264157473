import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { Button, ButtonAnchor } from "@replo/design-system/components/button";
import classNames from "classnames";
import * as React from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

interface IntegrationCardProps {
  title: string;
  redirectUrl: string;
  image: string;
  summary?: string;
  isConnected?: boolean;
  isConfigurable?: true;
}

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  title,
  summary,
  redirectUrl,
  isConnected,
  image,
  isConfigurable,
}) => {
  const navigate = useNavigate();
  const logEvent = useLogAnalytics();
  return (
    <div
      className={classNames("border rounded-lg p-4 md:w-[300px] flex", {
        "flex-col justify-between items-start p5": isConfigurable,
        "items-center": !isConfigurable,
      })}
    >
      <div
        className={classNames({
          "flex justify-between items-start mb-6 w-full": isConfigurable,
        })}
      >
        <img
          src={image}
          alt={title}
          style={{
            width: isConfigurable ? "70px" : "30px",
            height: isConfigurable ? "70px" : "30px",
          }}
          className="contain rounded"
        />
        {isConnected && (
          <span className="bg-green-600 text-white text-sm font-medium rounded-full px-2 py-1">
            Connected
          </span>
        )}
      </div>
      <h2
        className={classNames("text-sm font-bold", {
          "text-xl mb-4": isConfigurable,
          "ml-4 mr-auto": !isConfigurable,
        })}
      >
        {title}
      </h2>
      {summary && <p className="text-sm text-slate-600 mb-4">{summary}</p>}
      {isConfigurable ? (
        <Button
          variant="secondary"
          size="lg"
          fullWidth
          onClick={() => {
            logEvent("integration.select", {
              integration_name: title,
            });
            navigate(redirectUrl);
          }}
          iconEnd={<BsArrowRight />}
        >
          Configure Integration
        </Button>
      ) : (
        <ButtonAnchor
          variant="secondary"
          size="lg"
          target="_blank"
          icon={<BsArrowRight />}
          onClick={() => {
            logEvent("integration.select", {
              integration_name: title,
            });
          }}
          href={redirectUrl}
        />
      )}
    </div>
  );
};

export default IntegrationCard;

import { trpc } from "@editor/utils/trpc";
import * as React from "react";
import { isLocalhost } from "replo-runtime/shared/env";

import { useLogAnalytics } from "./useLogAnalytics";

export default function useLoginSupportChatUser() {
  const { data: user } = trpc.user.get.useQuery({});
  const isSupportChatEnabled = !isLocalhost();
  const analytics = useLogAnalytics();

  React.useEffect(() => {
    // This effect handles the Pylon widget script injection and configuration
    if (user && isSupportChatEnabled) {
      // Setting up Pylon configuration on the window object
      window.pylon =
        window.pylon ||
        function () {
          /* eslint-disable prefer-rest-params */
          // biome-ignore lint/suspicious/noAssignInExpressions: allow assignment in expression:
          (window.pylon.q = window.pylon.q || []).push(arguments);
          /* eslint-enable prefer-rest-params */
        };
      window.pylon.chat_settings = {
        app_id: "efe32725-24c5-4f17-bb77-42f06cc70918",
        email: user.email,
        name: user.name ?? user.email,
      };

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src =
        "https://widget.usepylon.com/widget/efe32725-24c5-4f17-bb77-42f06cc70918";
      const firstScript = document.getElementsByTagName("script")[0];
      firstScript?.parentNode?.insertBefore(script, firstScript);

      let pylonCheckAttempts = 0;
      const maxAttempts = 10;

      const configurePylon = () => {
        if (window.Pylon) {
          window.Pylon("onShow", function () {
            analytics("support.chat.open", {
              source: "pylon",
              userEmail: user.email,
              userName: user.name ?? user.email,
            });
          });
        } else if (pylonCheckAttempts < maxAttempts) {
          // Note (Juan, 09/11/2024): The Pylon script is loaded asynchronously, which means it might not be
          // immediately available after the script's "load" event fires. To handle this,
          // we implement a retry mechanism using setTimeout. This function will keep
          // checking for the window.Pylon object every 500ms until it becomes available.
          // This ensures that we can configure the Pylon chat as soon as it's ready,
          // without risking trying to use it before it's fully loaded.
          pylonCheckAttempts++;
          setTimeout(configurePylon, 500);
        } else {
          console.warn("Failed to load Pylon after multiple attempts");
        }
      };

      script.addEventListener("load", configurePylon);
    }
  }, [user, isSupportChatEnabled, analytics]);
}

import Tooltip from "@editor/components/common/designSystem/Tooltip";
import {
  useApplyComponentAction,
  type UseApplyComponentActionType,
} from "@editor/hooks/useApplyComponentAction";
import { selectDraftComponentNodeFromActiveCanvas } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import type { Spacing, SpacingSide } from "@editor/types/modifiers";
import ModifiersInfo from "@editorComponents/ModifiersInfo";
import ModifierGroup from "@editorExtras/ModifierGroup";
import type { LengthInputModifierHotkey } from "@editorModifiers/LengthInputModifier";
import LengthInputModifier from "@editorModifiers/LengthInputModifier";
import {
  allSpacings,
  horizontals,
  spaceBarGap,
  spaceBarX,
  spaceBarY,
  spacings,
  spacingSideDirectory,
  verticals,
} from "@editorModifiers/utils";
import classNames from "classnames";
import * as React from "react";
import type { PreviewableProperty } from "replo-runtime/shared/utils/preview";
import { twMerge } from "tailwind-merge";

const propFromSpacingSide = (
  side: SpacingSide | null,
): "margin" | "padding" | null => {
  if (side?.includes("margin")) {
    return "margin";
  } else if (side?.includes("padding")) {
    return "padding";
  }
  return null;
};

const DimensionModifier: React.FC = () => {
  return (
    <ModifierGroup
      title="Spacing"
      titleEnhancer={<ModifiersInfo documentationType="spacing" />}
    >
      <SpacingControls />
    </ModifierGroup>
  );
};

const SpacingControls: React.FC = () => {
  const [activeProp, setActiveProp] = React.useState<
    "margin" | "padding" | null
  >(null);
  const spacingRefs = React.useRef<
    Record<string, React.RefObject<HTMLInputElement>>
  >(
    Object.fromEntries(
      allSpacings.map((spacing) => [spacing, React.createRef()]),
    ),
  );
  const onChangeSpacingRef = (spacing: SpacingSide, value: string) => {
    const ref = spacingRefs.current[spacing]!;
    if (ref.current) {
      ref.current.value = value;
    }
  };

  const onHoverSpacingSide = (side: SpacingSide | null) => {
    setActiveProp(propFromSpacingSide(side));
  };

  return (
    <div className="relative mt-2 mb-2 w-full" style={{ height: 131 }}>
      {allSpacings.map((spacing) => (
        <SingleSpacingControl
          key={spacing}
          field={spacing}
          onHoverSpacingSide={onHoverSpacingSide}
          inputRef={spacingRefs.current[spacing]!}
          onChangeSpacingRef={onChangeSpacingRef}
        />
      ))}
      <SpacingLabel
        key="margin"
        // Note (Sebas, 2022-11-22): This field is only for setting
        // the active property (margin / padding)
        field="marginLeft"
        className="bg-purple-600 text-purple-50 uppercase"
        onHoverSpacingSide={onHoverSpacingSide}
        tooltipText="Space Outside Component"
      >
        {activeProp === "margin" ? "Margin" : "M"}
      </SpacingLabel>
      <SpacingLabel
        key="padding"
        // Note (Sebas, 2022-11-22): This field is only for setting
        // the active property (margin / padding)
        field="paddingLeft"
        className="bg-green-400 text-green-100 uppercase"
        onHoverSpacingSide={onHoverSpacingSide}
        tooltipText="Space Inside Component"
        offset={{
          top: spaceBarY + spaceBarGap,
          left: spaceBarX + spaceBarGap,
        }}
      >
        {activeProp === "padding" ? "Padding" : "P"}
      </SpacingLabel>
    </div>
  );
};

const SingleSpacingControl: React.FC<{
  field: SpacingSide;
  onHoverSpacingSide: (spacing: SpacingSide | null) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  onChangeSpacingRef: (spacing: SpacingSide, value: string) => void;
}> = ({ field, onHoverSpacingSide, inputRef, onChangeSpacingRef }) => {
  const applyComponentAction = useApplyComponentAction();
  const draftComponentNode = useEditorSelector(
    selectDraftComponentNodeFromActiveCanvas,
  );

  const { draggingType, draggingDirection, style } =
    spacingSideDirectory[field];

  const previewSpacingsInDOM = (fields: SpacingSide[], value: string) => {
    if (draftComponentNode) {
      fields.forEach((field) => {
        onChangeSpacingRef(field, value);
      });
    }
  };

  const applySpacingActions = (fields: SpacingSide[], value: string) => {
    const compositeAction: UseApplyComponentActionType[] = fields.map(
      (field) => ({
        type: "setStyles",
        value: { [field]: value },
      }),
    );
    if (compositeAction.length > 1) {
      applyComponentAction({
        type: "applyCompositeAction",
        value: compositeAction,
      });
    } else {
      applyComponentAction({
        type: "setStyles",
        value: { [fields[0]!]: value },
      });
    }
  };

  const applyHotkeyActions = (
    value: any,
    hotkey = null as LengthInputModifierHotkey | null,
    applyActions: (spacings: SpacingSide[], value: string) => void,
  ) => {
    switch (hotkey) {
      case "shiftKey":
        (Object.keys(spacings) as Spacing[]).forEach((spacingType) => {
          if (field.indexOf(spacingType) === 0) {
            applyActions(spacings[spacingType], value);
          }
        });
        break;
      case "altKey":
        (Object.keys(spacings) as Spacing[]).forEach((spacingType) => {
          [verticals, horizontals].forEach((directions) => {
            const spacings = directions.map(
              (direction) => `${spacingType}${direction}` as SpacingSide,
            );
            if (spacings.some((value) => field.indexOf(value) === 0)) {
              applyActions(spacings, value);
            }
          });
        });
        break;
      default:
        applyActions([field], value);
        break;
    }
  };

  const onChange = (
    v: string,
    hotkey: LengthInputModifierHotkey | null = null,
  ) => {
    applyHotkeyActions(v, hotkey, applySpacingActions);
  };

  const previewablePropertiesForOption = [
    ["marginTop", "marginBottom"],
    ["marginLeft", "marginRight"],
    ["paddingTop", "paddingBottom"],
    ["paddingLeft", "paddingRight"],
  ].find((props) => props.includes(field))! as SpacingSide[];

  const previewablePropertiesForShift = field.includes("margin")
    ? ["marginTop", "marginRight", "marginBottom", "marginLeft"]
    : ["paddingTop", "paddingRight", "paddingBottom", "paddingLeft"];

  const getPreviewProps = (
    hotkey: "altKey" | "shiftKey" | null,
  ): SpacingSide[] => {
    if (hotkey === "shiftKey") {
      return previewablePropertiesForShift as SpacingSide[];
    }
    if (hotkey === "altKey") {
      return previewablePropertiesForOption;
    }
    return [];
  };

  return (
    <div
      onMouseEnter={() => onHoverSpacingSide(field)}
      onMouseLeave={() => onHoverSpacingSide(null)}
    >
      <LengthInputModifier
        inputRef={inputRef}
        draggingType={draggingType}
        draggingDirection={draggingDirection}
        className={classNames(
          "absolute w-full bg-slate-50 hover:bg-slate-200 focus-within:outline-none",
          ["paddingLeft", "paddingRight", "marginLeft", "marginRight"].includes(
            field,
          )
            ? "bg-slate-100"
            : "bg-slate-50",
        )}
        unitDefaults={{ unit: "px", value: "0" }}
        inputClassName="text-xs p-0 bg-transparent text-center h-full ring-0"
        style={{ width: "100%", height: "100%", ...style }}
        minDragValues={{ all: 0 }}
        placeholder="0"
        field={`style.${field}`}
        onPreviewChange={(value, hotkey) => {
          if (hotkey) {
            previewSpacingsInDOM(getPreviewProps(hotkey), value);
          }
        }}
        onChange={onChange}
        allowsNegativeValue={!field.includes("padding")}
        resetValue="0px"
        dragTrigger="entireInput"
        anchorValue="0px"
        transformValue={(v) => {
          // Sanitize v because "auto" is not valid for paddings
          const isAuto = v === "auto";
          if (isAuto && field.includes("padding")) {
            return "0px";
          }
          return v;
        }}
        metrics={["px", "em", "rem", "%", "pt", "vh", "vw"]}
        previewProperty={field}
        previewablePropertiesForOption={previewablePropertiesForOption}
        previewablePropertiesForShift={
          previewablePropertiesForShift as PreviewableProperty[]
        }
      />
    </div>
  );
};

const SpacingLabel: React.FC<
  React.PropsWithChildren<{
    onHoverSpacingSide: (spacing: SpacingSide | null) => void;
    tooltipText: string;
    className: string;
    field: "marginLeft" | "paddingLeft";
    offset?: { top: number; left: number };
  }>
> = ({
  onHoverSpacingSide,
  tooltipText,
  className,
  children,
  offset,
  field,
}) => {
  return (
    <div
      className={twMerge(
        "absolute select-none rounded bg-slate-600 px-1 py-0.5 text-[8px] tracking-wider text-slate-50 transition-all duration-300",
        className,
      )}
      style={{
        top: 4 + (offset?.top ?? 0),
        left: 4 + (offset?.left ?? 0),
      }}
      onMouseEnter={() => onHoverSpacingSide(field)}
      onMouseLeave={() => onHoverSpacingSide(null)}
    >
      <Tooltip content={tooltipText} triggerAsChild>
        <span tabIndex={0}>{children}</span>
      </Tooltip>
    </div>
  );
};

export default DimensionModifier;

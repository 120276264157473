import StandardCustomPropControl from "@editor/components/editor/page/element-editor/components/config-menus/StandardCustomPropControl";
import { useCustomPropDefinitionsWithValues } from "@editor/components/editor/page/element-editor/components/config-menus/utils";
import { selectDraftComponent } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import * as React from "react";

const ACCESSIBILITY_CUSTOM_PROP_IDS = new Set([
  "_accessibilityRole",
  "_accessibilityHidden",
  "_accessibilityChecked",
  "_accessibilityLabelledBy",
]);

export const ContainerConfigMenu: React.FC = () => {
  const customProps = useCustomPropDefinitionsWithValues();
  const draftComponent = useEditorSelector(selectDraftComponent);
  if (!draftComponent) {
    return null;
  }

  return (
    <div className="relative flex flex-col gap-4">
      {customProps.map(
        (customProp) =>
          !ACCESSIBILITY_CUSTOM_PROP_IDS.has(customProp.definition.id) && (
            <StandardCustomPropControl
              draftComponent={draftComponent}
              customProp={customProp}
              key={customProp.definition.id}
            />
          ),
      )}
    </div>
  );
};

export const ContainerAccessibilityMenu: React.FC = () => {
  const customProps = useCustomPropDefinitionsWithValues();
  const draftComponent = useEditorSelector(selectDraftComponent);
  if (!draftComponent) {
    return null;
  }

  return (
    <div className="relative flex flex-col gap-4">
      {customProps.map(
        (customProp) =>
          ACCESSIBILITY_CUSTOM_PROP_IDS.has(customProp.definition.id) && (
            <StandardCustomPropControl
              draftComponent={draftComponent}
              customProp={customProp}
              key={customProp.definition.id}
            />
          ),
      )}
    </div>
  );
};

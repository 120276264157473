import Button from "@common/designSystem/Button";
import Input from "@common/designSystem/Input";
import Modal from "@common/designSystem/Modal";
import { ModalLayout } from "@common/ModalLayout";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import toast from "@editor/components/common/designSystem/Toast";
import { useWorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { publisherApi } from "@editor/reducers/api-reducer";
import { useEditorDispatch } from "@editor/store";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import * as React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";

type FormValues = {
  name: string;
};

export const AddWorkspaceModal = () => {
  const logEvent = useLogAnalytics();
  const { closeModal } = useModal();
  const navigate = useNavigate();

  const dispatch = useEditorDispatch();

  const { mutate: createWorkspace, isPending: isLoadingCreation } =
    trpc.workspace.create.useMutation({
      onSuccess: (data) => {
        toast({
          header: "Workspace created",
        });
        navigate(`/workspace/${data.workspace.id}/projects`);
        setWorkspaceId(data.workspace.id);
        logEvent("workspace.create", {
          from: "dropdown",
          workspaceId: data.workspace.id,
          workspaceName: data.workspace.name,
        });
        void trpcUtils.workspace.getUserWorkspacesList.invalidate();
        dispatch(publisherApi.util.invalidateTags(["workspaces"]));
      },
      onSettled: () => {
        closeModal({ type: "addWorkspaceModal" });
      },
    });

  const { setWorkspaceId } = useWorkspaceDashboardContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      name: "",
    },
  });
  const nameValue = useWatch({ control, name: "name" });
  const hasNameErrors = !isEmpty(errors.name);

  const { user } = useCurrentUser();

  const onSubmit = ({ name }: FormValues) => {
    if (!user) {
      return;
    }
    void createWorkspace({
      name,
      preferredPaymentProcessor:
        user.source === "shopify" ? "shopify" : "stripe",
    });
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        closeModal({ type: "addWorkspaceModal" });
      }}
      className="w-auto"
    >
      <ModalLayout
        height="auto"
        width="25vw"
        minWidth={400}
        mainContent={() => (
          <div className="flex flex-col gap-6 p-2 w-full">
            <h2 className="text-xl font-medium">Add Workspace</h2>
            <form
              className="flex flex-col gap-6"
              onSubmit={(data) => {
                void handleSubmit(onSubmit)(data);
              }}
            >
              <div className="flex flex-col gap-2">
                <div className="text-sm text-slate-400">
                  Type the name of the workspace.
                </div>
                <label htmlFor="workspaceName" className="sr-only">
                  Workspace name
                </label>
                <Input
                  id="workspaceName"
                  aria-invalid={hasNameErrors ? "true" : undefined}
                  aria-describedby={
                    hasNameErrors ? "error-workspace-name" : undefined
                  }
                  autoComplete="off"
                  placeholder="Enter workspace name"
                  {...register("name", {
                    required: "Please enter the workspace name.",
                  })}
                  type="text"
                  size="base"
                  validityState={hasNameErrors ? "invalid" : "valid"}
                  autoFocus
                />
                <ErrorMessage
                  id="error-workspace-name"
                  error={errors.name?.message}
                />
              </div>
              <Button
                type="primary"
                size="base"
                className="self-end"
                textClassNames="text-xs"
                htmlType="submit"
                isLoading={isLoadingCreation}
                isDisabled={hasNameErrors || !nameValue}
              >
                Add
              </Button>
            </form>
          </div>
        )}
      />
    </Modal>
  );
};

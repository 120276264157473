import * as React from "react";

import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../shared/runtime-context";
import type { SharedLiquidReviewsProps } from "../../shared/types";
import { useProductFromProps } from "../hooks/useProductFromProps";
import { prependProductAssignmentToLiquidSource } from "../utils/reviews";
import { SharedLiquidReviewIntegrations } from "./SharedLiquidReviewIntegrations";

export const SharedJunipReviews = ({
  liquidSource,
  component,
  context,
  attributes,
  reviewsComponentType,
}: SharedLiquidReviewsProps) => {
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const product = useProductFromProps(component.props, context);

  const shouldHavePlaceHolder =
    isEditorApp &&
    // @ts-expect-error
    !globalWindow.junipLoaded;

  const liquid = prependProductAssignmentToLiquidSource({
    product,
    liquidSource,
    context,
  });

  return (
    <SharedLiquidReviewIntegrations
      attributes={attributes}
      component={component}
      liquidSource={liquid}
      placeholder={`Junip ${reviewsComponentType} Widget will appear here`}
      shouldHavePlaceHolder={shouldHavePlaceHolder || !product}
      appName="Junip"
      context={context}
    />
  );
};

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

const initialStarSize = 16;

export const starRating: ComponentTemplate = {
  id: "c8c30090-0b7b-4766-ab8c-8a11f2a23105",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Component/Star Rating",
  leftBarCategory: { main: "basic", subCategory: "core" },
  tags: ["reviews"],
  preview: "component/star-rating.svg",
  transforms: [],
  template: {
    id: "$uid",
    type: "starRating",
    props: {
      _starCount: 5,
      _size: "16px",
      _starColor: "#000000",
      _totalStars: 5,
      style: {
        display: "flex",
        flexDirection: "row",
      },
    },
  },
  metadata: {
    originalWidth: initialStarSize * 5,
  },
};

import { Input, useOverridableInput } from "@common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Tooltip from "@editor/components/common/designSystem/Tooltip";
import type { FormKey } from "@editor/contexts/ElementEditorErrorContext";
import { useElementEditorErrorContext } from "@editor/contexts/ElementEditorErrorContext";
import type { ElementErrorType } from "@editor/utils/element";
import { MAX_SECTION_NAME_LENGTH } from "@editor/utils/element";
import * as React from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import { exhaustiveSwitch, isEmpty } from "replo-utils/lib/misc";
import type { ReploElementType } from "schemas/element";

import { ElementEditorErrors } from "./ElementEditorErrors";

const ElementNameEditor: React.FC<{
  initialName?: string;
  type: ReploElementType;
  inputName: string;
  onChange: (newName: string) => void;
}> = ({ initialName, onChange, inputName, type }) => {
  const { errorMapping, setErrors, clearErrors } =
    useElementEditorErrorContext();
  const elementTypeSingularName = exhaustiveSwitch({ type })({
    page: "Page",
    shopifyArticle: "Post",
    shopifyProductTemplate: "Template",
    shopifySection: "Section",
  });
  const elementTypePluralName = exhaustiveSwitch({ type })({
    page: "pages",
    shopifyArticle: "posts",
    shopifyProductTemplate: "templates",
    shopifySection: "sections",
  });
  const mostRelevantError = getMostRelevantError({
    errorMapping,
    elementType: type,
  });

  const errorMappingIncludesPathAlreadyExists =
    errorMapping["path"]?.includes("pathAlreadyExists");
  const handleNameChange = React.useCallback(
    (name: string) => {
      // NOTE (Sebas, 2023-11-28): This is in charge of the input validation. In case the
      // user enters a name, we remove the error from the list of errors and also we remove
      // the "pathIsEmpty" error because the path is automatically generated from the name.
      if (name.length >= 1) {
        clearErrors("title", ["nameIsEmpty"]);
        clearErrors("path", ["pathIsEmpty"]);
      }
      if (name.length === 0) {
        setErrors("title", ["nameIsEmpty"]);
      }
      if (type === "shopifySection" && errorMappingIncludesPathAlreadyExists) {
        clearErrors("path", ["pathAlreadyExists"]);
      }
      // NOTE (Martin, 2023-10-02, USE-457): We need to validate name against
      // invisible Unicode characters since we disallow those in element's names.
      // https://stackoverflow.com/a/24231346
      // Note (Noah, 2024-03-18, USE-818): We need to have a pretty specific regex
      // here - we want to include printable characters, but exclude control characters,
      // format characters, invisible separators, etc
      //
      // From senior developer ChatGPT, this regular expression will keep:
      // \p{L}: any kind of letter from any language.
      // \p{M}: marks that are meant to be combined with another character (like diacritical marks).
      // \p{N}: any kind of numeric character in any script.
      // \p{P}: any kind of punctuation character.
      // \p{Z}: any kind of separator character.
      // -~: the range of printable ASCII characters, including the space.
      //
      // ALSO: we use a different slugify function for the page path, so diacritical marks
      // and such will never get included in the page path
      const newName = name.replace(/[^\p{L}\p{M}\p{N}\p{P}\p{Z} -~]+/gu, "");
      onChange(newName);
    },
    [
      clearErrors,
      errorMappingIncludesPathAlreadyExists,
      onChange,
      setErrors,
      type,
    ],
  );

  /* TODO (Yuxin, 2022-03-22) For some reason, the first input is highlighted when
      we open the modal, and onOpenAutoFocus={(e) => e.stopPropagation()} doesn't
      actually work https://github.com/radix-ui/primitives/discussions/935 */

  const inputProps = useOverridableInput({
    value: initialName ?? "",
    onValueChange: handleNameChange,
    timeout: 0,
  });

  return (
    <LabeledControl
      label={
        <>
          {elementTypeSingularName} Name
          <Tooltip
            triggerAsChild
            content={`If you use a slash-separated naming convention, Replo will group ${elementTypePluralName} together in the page navigation menu.`}
            disableHoverableContent={false}
          >
            <span tabIndex={0}>
              <BsInfoCircleFill size={12} className="ml-1 text-slate-300" />
            </span>
          </Tooltip>
        </>
      }
      className="flex items-center"
      id="page-data-name"
      error={
        mostRelevantError ? (
          <ElementEditorErrors
            errorType={mostRelevantError}
            elementType={type}
          />
        ) : null
      }
    >
      <Input
        {...inputProps}
        id="page-data-name"
        autoFocus
        placeholder={`${elementTypeSingularName} Name`}
        name={inputName}
        size="base"
        maxLength={
          type === "shopifySection" ? MAX_SECTION_NAME_LENGTH : undefined
        }
        autoComplete="off"
      />

      {type === "shopifySection" &&
        initialName &&
        initialName.length >= MAX_SECTION_NAME_LENGTH && (
          <span className="pt-2 text-xs text-slate-400">
            Shopify limits section names to {MAX_SECTION_NAME_LENGTH}{" "}
            characters.
          </span>
        )}
    </LabeledControl>
  );
};

const getMostRelevantError = (opts: {
  errorMapping: Record<FormKey, ElementErrorType[] | null>;
  elementType: ReploElementType;
}): ElementErrorType | null => {
  const { errorMapping, elementType } = opts;
  if (!isEmpty(errorMapping)) {
    if (errorMapping["title"]?.includes("isInvalidSectionName")) {
      return "isInvalidSectionName";
    }

    if (errorMapping["title"]?.includes("nameIsEmpty")) {
      return "nameIsEmpty";
    }

    // NOTE (Chance 2024-03-25): No relevance ranking from here, just make sure
    // we show *some* error
    if (errorMapping["title"] && errorMapping["title"].length > 0) {
      return errorMapping["title"][0]!;
    }

    // Note (Noah, 2024-07-15): If it's a shopify section, we show path errors as
    // part of the section name, since we want to tell the user that the section
    // already exists. For sections, the path is always generated from the name
    if (
      elementType === "shopifySection" &&
      errorMapping["path"]?.includes("pathAlreadyExists")
    ) {
      return "pathAlreadyExists";
    }
  }

  return null;
};

export default ElementNameEditor;

import * as React from "react";

import type { RenderComponentProps } from "../../../shared/types";
import { RenderComponentPlaceholder } from "../RenderComponentPlaceholder";

interface PostscriptSignupFormProps extends RenderComponentProps {
  _postscriptShopId?: string;
  _postscriptKeywordId?: string;
}

function PostscriptSignupForm(props: PostscriptSignupFormProps) {
  const { componentAttributes, _postscriptShopId, _postscriptKeywordId } =
    props;
  const postScriptHTML = `<div id="ps__subscribe-form"><form action="https://api.postscript.io/subscribe/form" method="post" onsubmit="document.getElementById('ps__sent-message').style.display = 'block'; document.getElementById('ps__subscribe-form').style.display = 'none';" target="dummyframe"><input id="ps__shop_id" name="shop_id" type="hidden" value="${_postscriptShopId}"/> <input id="ps__keyword_id" name="keyword_id" type="hidden" value="${_postscriptKeywordId}"/><div style="margin-bottom: 10px; text-align: center"><input id="ps__phone-subscribe" name="phone" type="phone" placeholder="Phone Number" style="width: 100%; padding: 0.75em; font-size: 1.5em" /></div><div style="max-width: 100%; font-size: 10px; margin-bottom: 25px; margin-top: 15px; text-align: center; line-height: 1.2;">*By providing your phone number, you agree to receive recurring automated marketing text messages (e.g. cart reminders) from this shop and third parties acting on its behalf. Consent is not a condition to obtain goods or services. Msg & data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel. You also agree to the <a href="https://terms.pscr.pt/legal/shop/replo-docsite/terms_of_service">Terms of Service</a> and <a href="https://terms.pscr.pt/legal/shop/replo-docsite/privacy_policy">Privacy Policy</a>.</div><div><input type="submit" value="Subscribe" class="btn" style="width: 100%; padding: 0.75em; font-size: 1.5em"/></div></form></div><div style="margin: auto; padding: 20px; display: none" id="ps__sent-message"><h4>Thanks for subscribing! We'll send you a message shortly.</h4></div><iframe style="display:none;" width="0" height="0" border="0" name="dummyframe" id="ps__dummyframe"></iframe>`;
  return (
    <div {...componentAttributes}>
      {!(_postscriptShopId && _postscriptKeywordId) ? (
        <RenderComponentPlaceholder title="Once you set your Postscript Shop ID and Keyword ID in the Config tab, your embedded Postscript Form will appear here." />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: postScriptHTML }} />
      )}
    </div>
  );
}

export default PostscriptSignupForm;

import ColorHexSelector from "@editor/components/common/designSystem/ColorHexSelector";
import ColorPicker from "@editor/components/common/designSystem/ColorPicker";
import ColorSwatch from "@editor/components/common/designSystem/ColorSwatch";
import InlinePopover from "@editor/components/common/designSystem/InlinePopover";
import Tooltip from "@editor/components/common/designSystem/Tooltip";
import { getHex8Color } from "@editor/components/common/designSystem/utils/colors";
import {
  selectDraftComponentId,
  selectDraftElementColors,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import {
  getFormattedColor,
  getFormattedColorWithoutOpacity,
} from "@editor/utils/colors";
import { getHotKey } from "@editor/utils/getHotKey";
import { hasDynamicData } from "@editorModifiers/utils";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import * as React from "react";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";

type Props = {
  value: string | null;
  defaultValue?: string;
  onPreviewChange?(value: string | null): void;
  onChange(value: string | null): void;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  openDynamicData?(): void;
  popoverTitle?: string;
  isDisabled?: boolean;
};

const SolidColorSelector: React.FC<Props> = ({
  value: color,
  defaultValue,
  onChange,
  onPreviewChange,
  onDragEnd,
  onDragStart,
  openDynamicData,
  popoverTitle = "Color",
  isDisabled = false,
}) => {
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const elementColors = useEditorSelector(selectDraftElementColors);

  const dynamicData =
    hasDynamicData(draftComponentId) && openDynamicData !== undefined;

  // Start with an uppercase color if it exists since some component color values
  // be lowercase
  const [inputTextValue, setInputTextValue] = useOverridableState(
    getFormattedColorWithoutOpacity(color),
  );
  /* eslint-disable react-hooks/exhaustive-deps */
  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = React.useCallback(
    debounce((value) => onChange(value), 300),
    [onChange],
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleChangeComplete = (
    color: string | null,
    opts: { debounce: boolean },
  ) => {
    let value: string | null = null;
    if (color) {
      const hex8color = getHex8Color(color);
      const formattedHex8Color = getFormattedColor(hex8color);
      value = formattedHex8Color;
    }

    onPreviewChange?.(value);
    if (opts.debounce) {
      debounceOnChange(value);
    } else {
      onChange(value);
    }
  };

  const handleColorRemove = () => {
    setInputTextValue(
      defaultValue ? getFormattedColorWithoutOpacity(defaultValue) : "",
    );
    onChange(defaultValue || null);
  };

  const handleColorInputChange = (value: string) => {
    setInputTextValue(value);
  };

  // Note (Sebas 2022-11-14): In case we want to reset the value with opt + click
  // we need to prevent the popover from opening
  const onTriggerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const hotkey = getHotKey(e);
    if (hotkey === "altKey") {
      e.preventDefault();
    }
  };

  return (
    <div className="modifier flex flex-grow flex-row justify-items-start gap-2">
      <InlinePopover
        shouldPreventDefaultOnInteractOutside={false}
        title={popoverTitle}
        content={
          <ColorPicker
            value={color ?? ""}
            allowsGradientSelection={false}
            onChange={(value) =>
              handleChangeComplete(value, { debounce: true })
            }
            documentColors={elementColors}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
          />
        }
        triggerAsChild
      >
        <div
          className={classNames({
            "pointer-events-none": isDisabled,
          })}
          onClick={onTriggerClick}
        >
          <Tooltip content={popoverTitle} triggerAsChild>
            <div tabIndex={0}>
              <ColorSwatch
                value={{ type: "color", color }}
                onOptionClick={handleColorRemove}
              />
            </div>
          </Tooltip>
        </div>
      </InlinePopover>
      <ColorHexSelector
        className="flex-grow"
        onChange={(value) => handleChangeComplete(value, { debounce: false })}
        handleColorRemove={handleColorRemove}
        value={inputTextValue || null}
        setValue={handleColorInputChange}
        allowsSettingDynamicData={dynamicData}
        onClickDynamicData={openDynamicData}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default SolidColorSelector;

import * as React from "react";
import type { RenderComponentProps } from "replo-runtime/shared/types";
import { ReploComponent } from "replo-runtime/store/components/ReploComponent";

const MuteIcon = (props: RenderComponentProps) => {
  const isMuted = props.context.state?.isMuted;
  return (
    <ReploComponent
      context={props.context}
      component={props.component}
      repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
      extraAttributes={props.extraAttributes}
      overrideComponentType="icon"
      overrideComponentProps={React.useMemo(
        () => ({ iconName: isMuted ? "mute" : "volumeUp" }),
        [isMuted],
      )}
      // @ts-expect-error: TODO (Noah, 2024-01-01, REPL-9866): These are errors
      // because the toggleMute action doesn't have a value, but that's only the
      // case because our types
      defaultActions={React.useMemo(
        () => ({
          actions: {
            onClick: [{ id: "alchemy:toggleMute", type: "toggleMute" }],
          },
          placement: "after",
        }),
        [],
      )}
    />
  );
};

export default MuteIcon;

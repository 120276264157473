import type { Announcement } from "@editor/types/sanity";
import { createClient } from "@sanity/client";

const config = {
  projectId: "089m4oq9", // This is public, so no need to hide it
  dataset: "production",
  apiVersion: "2023-09-19",
  useCdn: false,
};

export const sanityClient = createClient(config);

export const getAnnouncementsByPostHogFeatureFlag = async (
  announcementFlags: string[],
): Promise<Announcement[]> => {
  const query = `*[_type == "announcement" && postHogFeatureFlag in $announcementFlags && isActive == true] {
    postHogFeatureFlag,
    title,
    content,
    priority,
  } | order(priority asc)`;

  const params = {
    announcementFlags,
  };
  const response = await sanityClient.fetch(query, params);
  return response;
};

import classNames from "classnames";
import * as React from "react";
import screenfull from "screenfull";

import type { AssetLoadingType } from "../../../shared/asset-loading";
import type { Component } from "../../../shared/Component";
import {
  GlobalWindowContext,
  RuntimeHooksContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { mergeContext } from "../../../shared/utils/context";
import { useComponentClassNames } from "../../../shared/utils/renderComponents";
import useOnFirstViewportIntersection from "../../hooks/useOnFirstViewportIntersection";
import { customProperties, styleElements } from "../Player/config";
import FilePlayer from "../Player/FilePlayer";
import { ReploComponent } from "../ReploComponent";

const Player: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
  context,
}) => {
  const url = component.props.url;
  const autoplay = component.props._autoplay ?? false;
  const loop = component.props._loop ?? false;
  const preload = component.props._preload;
  const defaultMuted = component.props._defaultMuted ?? false;
  const autoFullscreen = component.props._autoFullscreen ?? false;
  const placeholderImage = component.props._placeholderImage as
    | { src: string }
    | undefined;
  const placeholderPlayIcon = component.props._placeholderPlayIcon as
    | { src: string }
    | undefined;
  const poster = component.props.poster as string | undefined;
  const loading = component.props.loading as AssetLoadingType | undefined;

  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const globalWindow = useRuntimeContext(GlobalWindowContext);

  const [isPlaying, setIsPlaying] = React.useState(
    !isEditorCanvas ? autoplay : false,
  );
  const [isMuted, setIsMuted] = React.useState(defaultMuted);
  const [isFullscreenEnabled, setIsFullscreenEnabled] = React.useState(false);
  const [isShowingPoster, setIsShowingPoster] = React.useState(!autoplay);
  const [shouldEagerLoad, setShouldEagerLoad] = React.useState(
    isEditorCanvas || (loading ?? "eager") === "eager",
  );

  const player = React.useRef<HTMLVideoElement | null>(null);
  const internalPlayerRef = React.useRef<HTMLVideoElement | null>(null);

  const handleClickFullscreen = () => {
    // Note (Noah, 2022-01-10): The webkit method here is ts-ignored because
    // webkit has other janky ways to enter fullscreen that typescript doesn't
    // actually know about on HTMLVideoElement
    if (screenfull.isEnabled) {
      void screenfull.request(player.current ?? undefined);
      // @ts-ignore
    } else if (internalPlayerRef.current) {
      const webkitVideoElement =
        internalPlayerRef.current! as HTMLVideoElement & {
          webkitEnterFullscreen: () => void;
        };
      if (webkitVideoElement.webkitEnterFullscreen) {
        webkitVideoElement.webkitEnterFullscreen();
      }
    }
  };

  React.useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => {
        // @ts-ignore
        setIsFullscreenEnabled(screenfull.isFullscreen);
      });
    }
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: missing dep handleClickFullscreen, autoFullscreen
  React.useEffect(() => {
    if (autoFullscreen && isPlaying && !isFullscreenEnabled) {
      handleClickFullscreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  React.useEffect(() => {
    const _handleFullscreenChange = () => {
      // https://stackoverflow.com/questions/32228024/how-to-detect-ios-leaving-fullscreen-video
      const targetDocument = globalWindow?.document;
      if (targetDocument) {
        const isFullScreen =
          // @ts-ignore
          targetDocument.fullScreenElement ||
          targetDocument.fullscreenElement ||
          // https://stackoverflow.com/questions/15509389/is-there-a-way-to-tell-whether-safari-is-fullscreen-e-g-document-fullscreenel
          // @ts-ignore
          targetDocument.webkitCurrentFullScreenElement ||
          // @ts-ignore
          targetDocument.webkitIsFullScreen == true;
        setIsFullscreenEnabled(isFullScreen);
      }
    };
    // Note (Noah, 2021-06-30): Some of these event listeners may enable observing
    // fullscreen state on iOS - the "resize" one is for observing full screen
    // state on desktop safari, for some reason none of these event listeners
    // actually work there. There might be a simpler way to do this, I spent like
    // an hour googling though so this is our best bet
    internalPlayerRef.current?.addEventListener(
      "webkitfullscreenchange",
      _handleFullscreenChange,
    );
    internalPlayerRef.current?.addEventListener(
      "webkitendfullscreen",
      _handleFullscreenChange,
    );
    internalPlayerRef.current?.addEventListener(
      "webkitenterfullscreen",
      _handleFullscreenChange,
    );
    internalPlayerRef.current?.addEventListener(
      "webkitbeginfullscreen",
      _handleFullscreenChange,
    );
    globalWindow?.addEventListener("resize", _handleFullscreenChange);
    return () => {
      internalPlayerRef.current?.removeEventListener(
        "webkitfullscreenchange",
        _handleFullscreenChange,
      );
      internalPlayerRef.current?.removeEventListener(
        "webkitendfullscreen",
        _handleFullscreenChange,
      );
      internalPlayerRef.current?.removeEventListener(
        "webkitEnterFullScreen",
        _handleFullscreenChange,
      );
      globalWindow?.removeEventListener("resize", _handleFullscreenChange);
    };
  });

  // Enable/disable autoplay while in the editor
  React.useEffect(() => {
    if (!isEditorCanvas && autoplay) {
      setIsPlaying(true);
    }
  }, [isEditorCanvas, autoplay]);

  const newContext = mergeContext(context, {
    state: {
      isPlaying,
      isMuted,
    },
    actionHooks: {
      togglePlay: () => {
        setIsShowingPoster(false);
        setIsPlaying(!isPlaying);
      },
      toggleMute: () => {
        setIsMuted(!isMuted);
      },
      toggleFullScreen: () => {
        handleClickFullscreen();
      },
    },
  });

  const classNameMap = useComponentClassNames("player", component, context);

  const playerStyles = {
    ...styleElements.player.defaultStyles,
    ...(isFullscreenEnabled
      ? {
          [customProperties.objectFit]: "contain",
          [customProperties.objectPosition]: "50% 50%",
        }
      : {}),
  };

  useOnFirstViewportIntersection(
    componentAttributes.ref,
    () => {
      setShouldEagerLoad(true);
    },
    undefined,
    { skip: loading !== "lazy" },
  );

  return (
    <div
      {...componentAttributes}
      className={classNames(
        "component__container",
        componentAttributes.className,
      )}
    >
      <div className={classNameMap?.inner}>
        <FilePlayer
          playerRef={player}
          playing={isPlaying}
          muted={isMuted}
          onEnded={() => {
            setIsPlaying(false);
          }}
          height="100%"
          width="100%"
          url={shouldEagerLoad ? url : undefined}
          style={playerStyles}
          onReady={() => {
            internalPlayerRef.current = player.current;
          }}
          playsinline={true}
          poster={
            // Note (Noah, 2023-08-28): If there's a "placeholder image", which is
            // a legacy prop which is like a poster but it always starts the video
            // playing on click, then we default to that if the image is lazy loaded
            // rather than using the poster attribute (we'll also render it below)
            // with a click handler. Eventually we should unify placeholderImage and
            // poster - poster is only used for lazy loading right now. Maybe if we
            // have an onClick action to play video then we don't need placeholderImage
            // at all?
            loading === "lazy"
              ? placeholderImage?.src ?? poster
              : placeholderImage?.src
          }
          preload={preload ? "auto" : "metadata"}
          loop={loop}
          controls={false}
          forceVideo={true}
          forceAudio={false}
          tracks={null}
          volume={null}
          playbackRate={1}
          progressInterval={1000}
          progressFrequency={null}
          pip={false}
          forceLoad={false}
          stopOnUnmount={true}
        />
        {isShowingPoster && placeholderImage && (
          <img
            onClick={() => {
              setIsShowingPoster(false);
              setIsPlaying(true);
            }}
            src={placeholderImage.src}
            className={classNameMap?.placeholderImage}
          />
        )}
        {isShowingPoster && placeholderPlayIcon && (
          <div
            onClick={() => {
              setIsShowingPoster(false);
              setIsPlaying(true);
            }}
            className={classNameMap?.placeholderPlayIcon}
          >
            <img src={placeholderPlayIcon.src} />
          </div>
        )}
      </div>
      {component.children?.map((child: Component) => {
        return (
          <ReploComponent
            key={child.id}
            component={child}
            context={newContext}
            repeatedIndexPath={context.repeatedIndexPath ?? ".0"}
          />
        );
      })}
    </div>
  );
};

export default Player;

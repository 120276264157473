import { docs } from "@editor/utils/docs";
import * as React from "react";

type ReferredTableEmptyStateProps = {
  type: "users" | "workspaces";
};

const ReferredTableEmptyState: React.FC<ReferredTableEmptyStateProps> = ({
  type,
}) => {
  return (
    <>
      <p className="mt-2 max-w-2xl font-light text-slate-400">
        When someone {type === "users" ? "signs up" : "creates a workspace"}{" "}
        with your referral link, you&lsquo;ll see their information here. If you
        have any questions, please reach out at{" "}
        <a className="hover:text-slate-400" href="mailto:support@replo.app">
          support@replo.app
        </a>
        , or visit our{" "}
        <a className="hover:text-slate-400" href={docs.faq}>
          FAQ
        </a>
        .
      </p>
    </>
  );
};

export default ReferredTableEmptyState;

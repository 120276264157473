import Button from "@common/designSystem/Button";
import * as React from "react";
import { BsCheckLg } from "react-icons/bs";

export const BillingConfirmation: React.FC<{
  title: string;
  body: string;
  onClose: () => void;
}> = ({ title, body, onClose }) => {
  return (
    <div className="flex flex-col justify-between items-center w-full h-full">
      <div className="flex flex-col justify-center items-center w-full gap-3 flex-grow">
        <div className="bg-blue-200 h-[80px] w-[80px] rounded-[40px] flex justify-center items-center">
          <BsCheckLg size={48} className="text-blue-600" />
        </div>
        <div className="text-4xl font-bold text-blue-600">{title}</div>
        <div>{body}</div>
      </div>
      <div className="flex w-full flex-row justify-end gap-3">
        <Button type="primary" size="lg" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
};

import ColorSwatch from "@common/designSystem/ColorSwatch";
import debounce from "lodash-es/debounce";
import * as React from "react";
import Draggable from "react-draggable";
import useMeasure from "react-use-measure";
import type { Gradient, GradientStop } from "replo-runtime/shared/types";
import { gradientToCssGradient } from "replo-runtime/shared/utils/gradient";
import { v4 as uuid } from "uuid";

type GradientSliderProps = {
  gradient: Gradient;
  colorForFirstStop?: string;
  onChange(gradient: Gradient): void;
  selectedStopId?: string;
  onChangeSelection?(gradientStopId: string | null): void;
  debounceDragging?: boolean;
};

function _percentageToNumber(percentage: string) {
  return Number.parseInt(percentage.replace("%", ""), 10);
}

function _sortGradientColors(gradientColors: GradientStop[]) {
  return gradientColors.sort(
    (a, b) => _percentageToNumber(a.location) - _percentageToNumber(b.location),
  );
}

const GradientSlider = ({
  gradient: { tilt, stops },
  onChange,
  selectedStopId,
  onChangeSelection,
  debounceDragging = false,
  colorForFirstStop,
}: GradientSliderProps) => {
  const [refMeasure, { width }] = useMeasure();
  const swatchsRef = React.useMemo(
    () =>
      Array.from({ length: stops.length }).map(() =>
        React.createRef<HTMLDivElement>(),
      ),
    [stops.length],
  );
  const [selectedDraggable, setSelectedDraggable] = React.useState<
    string | null
  >(selectedStopId ?? null);
  const gradientDiv = React.useRef<HTMLDivElement | null>(null);
  const gradientIndicatorSideBleed = 24;

  // biome-ignore lint/correctness/useExhaustiveDependencies(_onDrag): I think biome is wrong about this
  const onDebouncedDrag = React.useMemo(() => {
    return debounce(
      (x: number, lastX: number, index: number) => _onDrag(x, lastX, index),
      300,
    );
  }, [_onDrag]);

  function _percentageToPixel(percentage: string) {
    const correctedPixels = width - gradientIndicatorSideBleed;
    const percentageValue = Number.parseInt(percentage.replace("%", ""), 10);
    const pixels = (percentageValue * correctedPixels) / 100;
    return pixels;
  }

  function _pixelToPercentage(pixel: number) {
    const correctedPixels = width - gradientIndicatorSideBleed;
    const pixels = (pixel * 100) / correctedPixels;
    return `${pixels}%`;
  }

  function _onSwatchClick(clientX: number) {
    const rect = gradientDiv.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }
    const x = clientX - rect.left;
    const id = uuid();
    const newGradientStops = [
      ...stops,
      {
        id,
        color: "",
        location: _pixelToPercentage(x),
      },
    ];
    const newGradientStopsOrdered = _sortGradientColors(newGradientStops);

    const newSwatchIndex = newGradientStopsOrdered.findIndex(
      (stop) => stop.id === id,
    );
    if (newSwatchIndex > 0) {
      newGradientStopsOrdered[newSwatchIndex]!.color =
        newGradientStopsOrdered[newSwatchIndex - 1]!.color;
    } else if (newSwatchIndex === 0 && newGradientStopsOrdered.length > 1) {
      newGradientStopsOrdered[newSwatchIndex]!.color =
        newGradientStopsOrdered[newSwatchIndex + 1]!.color;
    } else {
      newGradientStopsOrdered[newSwatchIndex]!.color =
        colorForFirstStop || "black";
    }
    _selectSwatch(id);
    onChange({ tilt, stops: newGradientStopsOrdered });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function _onDrag(x: number, lastX: number, index: number) {
    if (x !== lastX || debounceDragging) {
      const newPercentageValue = _pixelToPercentage(x);
      const newGradientStops = stops.map((stop, i) =>
        i === index ? { ...stop, location: newPercentageValue } : stop,
      );
      const newGradientStopsOrdered = _sortGradientColors(newGradientStops);
      onChange({ tilt, stops: newGradientStopsOrdered });
    }
  }

  function _removeDraggable(e: React.KeyboardEvent<HTMLDivElement>) {
    if ((e.key === "Backspace" || e.key === "Delete") && selectedDraggable) {
      // Note (Noah, 2022-03-08): Stop propagation to ensure that things like
      // the default delete component shortcut don't get called
      e.stopPropagation();
      const newGradientStops = stops.filter(
        (stop) => stop.id !== selectedDraggable,
      );
      const currentSwatchIndex = stops.findIndex(
        (stop) => stop.id === selectedDraggable,
      );
      if (stops.length > 1) {
        if (currentSwatchIndex === 0) {
          _selectSwatch(stops[currentSwatchIndex + 1]!.id!);
        } else {
          _selectSwatch(stops[currentSwatchIndex - 1]!.id!);
        }
      } else {
        _selectSwatch(null);
      }
      onChange({ tilt, stops: newGradientStops });
    }
  }

  const getCurrentSwatchRef = React.useCallback(
    (id: string | null) => {
      const swatch = swatchsRef.find(
        (ref) => ref.current?.getAttribute("id") === id,
      );
      return swatch?.current;
    },
    [swatchsRef],
  );

  function _selectSwatch(id: string | null) {
    getCurrentSwatchRef(id)?.focus();
    setSelectedDraggable(id);
    onChangeSelection?.(id);
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies(stops): We want to rerun this when stops changes
  React.useEffect(() => {
    // Note (Sebas, 2022-08-10): This is for automatically focus a swatch when we
    // open the color picker. This prevents deleting the component instead of a swatch.
    getCurrentSwatchRef(selectedDraggable)?.focus();
  }, [getCurrentSwatchRef, selectedDraggable, stops]);

  return (
    <div
      className="relative flex w-full flex-col"
      onKeyDown={(e) => _removeDraggable(e)}
    >
      <div className="relative flex h-7 w-full" ref={refMeasure}>
        {width !== 0 &&
          stops.map((stop, index) => (
            <Draggable
              key={stop.id}
              bounds="parent"
              axis="x"
              onDrag={(_e, ui) =>
                debounceDragging
                  ? onDebouncedDrag(ui.x, ui.lastX, index)
                  : _onDrag(ui.x, ui.lastX, index)
              }
              defaultPosition={{
                x: _percentageToPixel(stop.location),
                y: 0,
              }}
              onMouseDown={() => _selectSwatch(stop.id!)}
            >
              <div
                // Note (Sebas, 2022-08-10): This is needed for focusing the swatch element
                // when other is removed.
                ref={swatchsRef[index]}
                className="absolute flex flex-col"
                tabIndex={0}
                id={stop.id}
              >
                <ColorSwatch
                  selected={selectedDraggable === stop.id}
                  className="h-5 w-5"
                  value={{ type: "color", color: stop.color }}
                />
                <div
                  className="flex h-0 w-0 self-center border-solid"
                  style={{
                    borderWidth: "4px 2px 0 2px",
                    borderColor: `${
                      selectedDraggable === stop.id
                        ? "rgb(37 99 324)"
                        : "rgb(226 232 240)"
                    } transparent transparent transparent`,
                  }}
                />
              </div>
            </Draggable>
          ))}
      </div>
      <div
        ref={gradientDiv}
        onClick={({ clientX }) => _onSwatchClick(clientX)}
        className="flex h-3 self-center"
        style={{
          width: width - gradientIndicatorSideBleed,
          background:
            "repeating-conic-gradient(#e2e8f0 0% 25%, #f1f9f5 0% 50%) 50%/ 12px 12px",
        }}
        data-testid="colorPickerGradientSlider"
      >
        <div
          className="h-full w-full"
          style={{
            background:
              gradientToCssGradient({
                tilt,
                stops: stops,
              }) ?? undefined,
          }}
        ></div>
      </div>
    </div>
  );
};

export default GradientSlider;

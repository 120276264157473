import { trpc } from "@editor/utils/trpc";
import useCurrentProjectId from "@hooks/useCurrentProjectId";
import { skipToken } from "@tanstack/react-query";
import sortBy from "lodash-es/sortBy";

export const useFetchProductMetafieldDefinitions = (config: {
  shouldSkip: boolean;
}) => {
  const projectId = useCurrentProjectId();
  const { data, isLoading } =
    trpc.shopify.getProductMetafieldDefinitions.useQuery(
      projectId && !config.shouldSkip ? projectId : skipToken,
    );

  return {
    isLoading,
    // Note (Noah, 2022-01-13, REPL-5986): Sort metafield definitions, there might
    // be a lot of them
    productMetafieldsDefinitions: data ? sortBy(data, "name") : undefined,
  };
};

import * as React from "react";

import type { Component } from "../../../shared/Component";
import type {
  RenderComponentProps,
  ShopifySellingPlan,
} from "../../../shared/types";
import { mergeContext } from "../../../shared/utils/context";
import { withLiquidAlternate } from "../../../shared/utils/withLiquidAlternate";
import type { SelectedSellingPlanIdOrOneTimePurchase } from "../../utils/product";
import { ReploComponent } from "../ReploComponent";
import ReploLiquidChunk from "../ReploLiquid/ReploLiquidChunk";

const ONE_TIME_PURCHASE_ID = "replo-one-time-purchase";
const ONE_TIME_PURCHASE_PLAN = {
  id: ONE_TIME_PURCHASE_ID,
  name: "One-Time Purchase",
  description: "",
  options: [],
  priceAdjustments: [],
} satisfies ShopifySellingPlan;

type SellingPlanId = typeof ONE_TIME_PURCHASE_ID | number;

export const SellingPlanSelect = ({
  component,
  context,
  componentAttributes: attributes,
  extraAttributes,
}: RenderComponentProps) => {
  const includeOneTimePurchase =
    component.props._includeOneTimePurchase ?? true;
  const sellingPlans: ShopifySellingPlan[] =
    context.attributes?._sellingPlans ?? [];
  const template =
    component.children?.length &&
    component.children[component.children.length - 1];
  if (!template) {
    return null;
  }

  if (sellingPlans.length === 0) {
    return null;
  }

  let selectedPlanId: SellingPlanId | null =
    context.state?.product?.selectedSellingPlan?.id ?? null;

  // Note (Fran: 2023-03-10): If the user enabled includeOneTimePurchase we need
  // to add one more selling plan to allow make a purchase only one time.
  // In this case the id will be null to not conflict with other selling plans.
  const allSellingPlans = [...sellingPlans];
  if (includeOneTimePurchase) {
    selectedPlanId ??= ONE_TIME_PURCHASE_ID;
    allSellingPlans.unshift(ONE_TIME_PURCHASE_PLAN);
  }

  return (
    <div {...attributes}>
      {allSellingPlans.map((sellingPlan, index) => {
        return (
          <SellingPlanComponent
            key={sellingPlan.id}
            component={component}
            template={template}
            extraAttributes={extraAttributes}
            sellingPlan={sellingPlan}
            context={context}
            index={index}
            isSelected={
              sellingPlan.id === ONE_TIME_PURCHASE_ID
                ? selectedPlanId === ONE_TIME_PURCHASE_ID
                : Number(sellingPlan.id) === Number(selectedPlanId)
            }
          />
        );
      })}
    </div>
  );
};

function SellingPlanComponent({
  component,
  template,
  extraAttributes,
  sellingPlan,
  context,
  index,
  isSelected,
  onClickOverride,
}: Pick<RenderComponentProps, "component" | "extraAttributes" | "context"> & {
  template: Component;
  sellingPlan?: ShopifySellingPlan;
  index: number;
  isSelected?: boolean;
  onClickOverride?: boolean;
}) {
  const childComponent = component.children?.[index] ?? template;
  if (!childComponent) {
    return null;
  }
  const nextContext = mergeContext(context, {
    attributes: {
      _currentSellingPlan: sellingPlan,
    },
    state: {
      sellingPlanSelect: {
        isSelected,
      },
    },
    variantTriggers: {
      "state.product.selectedSellingPlan": true,
    },
  });

  const repeatedIndex = template.id === childComponent.id ? index : 0;

  let sellingPlanId: SelectedSellingPlanIdOrOneTimePurchase | undefined;
  // NOTE (Matt 2024-05-28): The onClickOverride attribute is used by the liquid
  // version of this component to ensure that all associated html attributes
  // of the onClick action are applied to the pre-hydrated HTML to reduce
  // differences upon hydration.
  if (sellingPlan?.id === ONE_TIME_PURCHASE_ID || onClickOverride) {
    sellingPlanId = { __reploOneTimePurchase: true };
  } else if (sellingPlan?.id != null) {
    sellingPlanId = Number(sellingPlan.id);
  }

  return (
    <ReploComponent
      component={childComponent}
      extraAttributes={extraAttributes}
      context={nextContext}
      repeatedIndexPath={`${context.repeatedIndexPath}.${repeatedIndex}`}
      defaultActions={
        sellingPlanId
          ? {
              actions: {
                onClick: [
                  {
                    id: `alchemy:selectSellingPlan`,
                    type: "setActiveSellingPlan",
                    value: { sellingPlanId },
                  },
                ],
              },
            }
          : undefined
      }
    />
  );
}

const SellingPlanSelectLiquid: React.FC<RenderComponentProps> = ({
  component,
  extraAttributes,
  componentAttributes: attributes,
  context,
}) => {
  const includeOneTimePurchase =
    component.props._includeOneTimePurchase ?? true;
  const templateIndex = component.children?.length
    ? component.children.length - 1
    : 0;
  const template = component.children?.[templateIndex];

  if (!template) {
    return null;
  }

  // NOTE (Chance 2024-05-24): The liquid conditionals are a bit hard to read
  // without extra indentation
  // prettier-ignore
  return (
    <ReploLiquidChunk>
      {`{% if reploSortedSellingPlans != blank %}`}
        <div {...attributes}>
          {includeOneTimePurchase && (
            <>
              {`{% capture reploRepeatedSellingPlanName %}${ONE_TIME_PURCHASE_PLAN.name}{% endcapture %}`}
              {`{% if reploSelectedSellingPlan == blank %}`}
                <SellingPlanComponent
                  component={component}
                  template={template}
                  sellingPlan={ONE_TIME_PURCHASE_PLAN}
                  extraAttributes={extraAttributes}
                  context={context}
                  index={0}
                  isSelected={true}
                />
              {`{% else %}`}
                <SellingPlanComponent
                  component={component}
                  template={template}
                  sellingPlan={ONE_TIME_PURCHASE_PLAN}
                  extraAttributes={extraAttributes}
                  context={context}
                  index={0}
                  isSelected={false}
                />
              {`{% endif %}`}
            </>
          )}
          {`{% for reploRepeatedSellingPlan in reploSortedSellingPlans %}`}
            {`{% capture reploRepeatedSellingPlanName %}{{ reploRepeatedSellingPlan.name }}{% endcapture %}`}
            {/* NOTE (Chance 2024-05-23): If we have one-time purchase the
                index is 1-based because that plan always renders first */}
            {`{% case forloop.${includeOneTimePurchase ? "index" : "index0"} %}`}
              {component.children?.map((_, childIndex) => {
                return (
                  <>
                    {`{% when ${childIndex} %}`}
                    {`{% if reploSelectedSellingPlan == reploRepeatedSellingPlan %}`}
                    <SellingPlanComponent
                      // biome-ignore lint/correctness/useJsxKeyInIterable: ignore key
                      component={component}
                      template={template}
                      extraAttributes={extraAttributes}
                      context={context}
                      index={childIndex}
                      isSelected={true}
                      onClickOverride
                    />
                    {`{% else %}`}
                    <SellingPlanComponent
                      // biome-ignore lint/correctness/useJsxKeyInIterable: ignore key
                      component={component}
                      template={template}
                      extraAttributes={extraAttributes}
                      context={context}
                      index={childIndex}
                      isSelected={false}
                      onClickOverride
                    />
                    {`{% endif %}`}
                  </>
                );
              })}
            {`{% else %}`}
            {`{% if reploSelectedSellingPlan == reploRepeatedSellingPlan %}`}
              <SellingPlanComponent
                component={component}
                template={template}
                extraAttributes={extraAttributes}
                context={context}
                index={templateIndex}
                isSelected={true}
                onClickOverride
              />
            {`{% else %}`}
              <SellingPlanComponent
                component={component}
                template={template}
                extraAttributes={extraAttributes}
                context={context}
                index={templateIndex}
                isSelected={false}
                onClickOverride
              />
            {`{% endif %}`}
            {`{% endcase %}`}
          {`{% endfor %}`}
        </div>
      {`{% endif %}`}
    </ReploLiquidChunk>
  );
};

export default withLiquidAlternate(SellingPlanSelect, SellingPlanSelectLiquid);

import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

function getConfigurableProps(): CustomPropsRecord {
  return {
    _timerType: {
      name: "Countdown Type",
      type: "selectable",
      description:
        "Whether the timer should count down to a specific time, or count down for an amount of time after page load.",
      defaultValue: "date",
      selectableValues: {
        type: "options",
        options: [
          { label: "Specific Time", value: "date" },
          { label: "Time After Load", value: "afterLoad" },
        ],
      },
    },
    _timerLimit: {
      name: "Countdown Time",
      type: "timeInterval",
      description:
        "Time for the timer to count down. Once the timer reaches 0, it will stop.",
      defaultValue: { minutes: 15, hours: 0, seconds: 0, days: 0 },
      shouldDisplay: ({ component }) =>
        (component.props._timerType ?? "date") === "afterLoad",
    },
    _endTime: {
      name: "End Time",
      type: "date",
      description:
        "Date and time the countdown is counting down to, in your local timezone.",
      defaultValue: new Date().toISOString(),
      shouldDisplay: ({ component }) =>
        (component.props._timerType ?? "date") === "date",
    },
  };
}

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: true,
    customProps: convertToLegacyProps(getConfigurableProps()),
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: true,
    canContainChildren: true,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;

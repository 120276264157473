import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import StepSubtitle from "@components/flows/onboardingSteps/components/StepSubtitle";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import Input from "@editor/components/common/designSystem/Input";
import SelectableButtons from "@editor/components/common/designSystem/SelectableButtons";
import useGetCurrentStepResultsData from "@editor/components/flows/hooks/useGetCurrentStepResultsData";
import useIdentifyOtherValue from "@editor/components/flows/hooks/useIdentifyOtherValue";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepsLayout from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";
import { processOtherOptions } from "@editor/components/flows/utils/processOtherOptions";
import * as React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { isEmpty } from "replo-utils/lib/misc";
import type { FlowStepConfigPropsValueOf } from "schemas/flow";

type FormValues = {
  whatToDo: string[];
  whatToDoOther?: string;
};

const WhatDoYouWantToDoInReplo: React.FC = () => {
  const { currentStep, skipStep, submitStep } = useReploFlowsStepContext();
  const stepResultsData =
    useGetCurrentStepResultsData<"onboarding.user.what-do-you-want-to-do-in-replo">();
  const stepProps =
    currentStep?.props as FlowStepConfigPropsValueOf<"onboarding.user.what-do-you-want-to-do-in-replo">;

  const stepCommonOptions = stepProps.options.map((option) => option.value);
  const { otherValue: otherInitialValue, values: initialHeardFromValues } =
    useIdentifyOtherValue(stepCommonOptions, stepResultsData?.whatToDo);
  const { submitOrSkipStepCallback } = useRouterFlowCallbacks();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      whatToDo: initialHeardFromValues,
      whatToDoOther: otherInitialValue,
    },
  });

  const whatToDoValue = useWatch({ control, name: "whatToDo" });
  const whatToDoOtherValue = useWatch({ control, name: "whatToDoOther" });
  const isOtherSelected = whatToDoValue?.includes("other");

  const onSubmit = ({ whatToDo, whatToDoOther }: FormValues) => {
    if (currentStep) {
      const values = processOtherOptions(whatToDo, whatToDoOther, stepProps);
      if (currentStep?.isSkippable && isEmpty(values)) {
        skipStep(currentStep.id, currentStep.type, ({ instance, nextStep }) =>
          submitOrSkipStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          }),
        );
      } else {
        void submitStep(
          currentStep.id,
          currentStep.type,
          {
            whatToDo: values,
          },
          ({ instance, nextStep }) =>
            submitOrSkipStepCallback({
              nextStep: nextStep ?? null,
              flowSlug: instance.flow.slug,
            }),
        );
      }
    }
  };
  const whatToDoOtherError = errors.whatToDoOther?.message;

  const shouldDisableNextButton =
    !currentStep?.isSkippable ||
    (isOtherSelected &&
      (!Boolean(whatToDoOtherValue) || Boolean(whatToDoOtherError)));

  return (
    <OnboardingStepsLayout
      rightPanelContent={<StepImage src="/images/flows/grid.png" />}
    >
      <div className="flex flex-col gap-14">
        <div>
          <StepTitle>
            What do you want to do in Replo?
            <span className="text-red-600">*</span>
          </StepTitle>
          <StepSubtitle>Select all that apply.</StepSubtitle>
        </div>
        <form
          className="flex flex-col gap-16"
          onSubmit={(data) => {
            void handleSubmit(onSubmit)(data);
          }}
        >
          <Controller
            name="whatToDo"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectableButtons
                multiSelect
                options={stepProps.options ?? []}
                value={value}
                onChange={(value) => onChange(value ?? "")}
                className="my-4"
                textClassName="font-normal text-left"
                unselectedClassName="p-1.5 whitespace-break-spaces h-fit border border-slate-200 bg-transparent text-default hover:border-blue-600 hover:bg-blue-200 hover:text-blue-600"
                selectedClassName="p-1.5 whitespace-break-spaces h-fit border border-blue-600 bg-blue-200 text-blue-600 hover:bg-blue-300"
                inputEnhancer={
                  isOtherSelected ? (
                    <div className="flex w-full flex-col gap-2">
                      <Controller
                        name="whatToDoOther"
                        control={control}
                        rules={{
                          required: "Please enter a value.",
                          pattern: {
                            value: /^[\d A-Za-z]+$/,
                            message:
                              "Please enter only alphanumeric characters and whitespaces.",
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => (
                          <Input
                            type="text"
                            size="base"
                            autoFocus
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            name={name}
                            validityState={
                              Boolean(whatToDoOtherError) ? "invalid" : "valid"
                            }
                            aria-invalid={
                              Boolean(whatToDoOtherError) ? "true" : undefined
                            }
                            aria-describedby={
                              Boolean(whatToDoOtherError)
                                ? "error-what-to-do"
                                : undefined
                            }
                          />
                        )}
                      />
                      <ErrorMessage
                        id="error-what-to-do"
                        error={whatToDoOtherError}
                      />
                    </div>
                  ) : null
                }
              />
            )}
          />
          <FlowActionButtons
            shouldDisableNextButton={shouldDisableNextButton}
          />
        </form>
      </div>
    </OnboardingStepsLayout>
  );
};

export default WhatDoYouWantToDoInReplo;

import Separator from "@common/designSystem/Separator";
import DashboardMenuItem from "@editor/components/dashboard//DashboardMenuItem";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { trpc } from "@editor/utils/trpc";
import { skipToken } from "@tanstack/react-query";
import * as React from "react";
import {
  BsCreditCard,
  BsGear,
  BsGem,
  BsGraphUp,
  BsGrid,
  BsLayoutTextSidebarReverse,
  BsPeople,
  BsPerson,
  BsXDiamond,
} from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { filterNulls } from "replo-utils/lib/array";

export const DashboardMenuItems = () => {
  // TODO (Sebas, 2023-07-10): I don't know why but removing this hook causes
  // the app to crash saying "Cannot access 'andytownApi' before initialization".
  // Investigate and remove this when solved. REPL-7916
  // Note (Fran, 2023-09-12): I will keep the comment above for now, just in case we remove the
  // usage of this hook in the future.
  const logEvent = useLogAnalytics();
  const { user } = useCurrentUser();
  const { data } = trpc.workspace.getUserWorkspacesList.useQuery();
  const workspaces = data?.workspaces ?? [];
  const workspaceId = useCurrentWorkspaceId();

  const { data: projectMembershipsData } = trpc.project.findByUserId.useQuery(
    user?.id ? user.id : skipToken,
  );
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    {
      title: "Projects",
      path: `/workspace/${workspaceId}/projects`,
      onClick: () => {
        logEvent("dashboard.nav.click", {
          tab: "projects",
          userId: user?.id ?? null,
          workspaceId,
        });
        navigate(`/workspace/${workspaceId}/projects`);
      },
      startEnhancer: () => <BsLayoutTextSidebarReverse />,
    },
    isFeatureEnabled("analytics-dashboard") && {
      title: "Analytics",
      path: `/workspace/${workspaceId}/analytics`,
      onClick: () => {
        logEvent("dashboard.nav.click", {
          tab: "analytics",
          userId: user?.id ?? null,
          workspaceId,
        });
      },
      startEnhancer: () => <BsGraphUp />,
      subItems: [
        {
          title: "Overview",
          path: `/workspace/${workspaceId}/analytics/overview`,
          onClick: () => {
            logEvent("dashboard.nav.click", {
              tab: "analytics_overview",
              userId: user?.id ?? null,
              workspaceId,
            });
            navigate(`/workspace/${workspaceId}/analytics/overview`);
          },
        },
        {
          title: "List View",
          path: `/workspace/${workspaceId}/analytics/list`,
          onClick: () => {
            logEvent("dashboard.nav.click", {
              tab: "analytics_list",
              userId: user?.id ?? null,
              workspaceId,
            });
            navigate(`/workspace/${workspaceId}/analytics/coming-soon`);
          },
        },
        {
          title: "Deep Dive",
          path: `/workspace/${workspaceId}/analytics/deep-dive`,
          onClick: () => {
            logEvent("dashboard.nav.click", {
              tab: "analytics_deep_dive",
              userId: user?.id ?? null,
              workspaceId,
            });
            navigate(`/workspace/${workspaceId}/analytics/coming-soon`);
          },
        },
      ],
    },
    {
      title: "Members",
      path: `/workspace/${workspaceId}/members`,
      onClick: () => {
        logEvent("dashboard.nav.click", {
          tab: "members",
          userId: user?.id ?? null,
          workspaceId,
        });
        navigate(`/workspace/${workspaceId}/members`);
      },
      startEnhancer: () => <BsPeople />,
    },
    {
      title: "Billing",
      path: `/workspace/${workspaceId}/billing`,
      onClick: () => {
        logEvent("dashboard.nav.click", {
          tab: "billing",
          userId: user?.id ?? null,
          workspaceId,
        });
        navigate(`/workspace/${workspaceId}/billing`);
      },
      startEnhancer: () => <BsCreditCard />,
    },

    {
      title: "Affiliates Program",
      path: `/workspace/${workspaceId}/partner`,
      onClick: () => {
        logEvent("dashboard.nav.click", {
          tab: "affiliates_program",
          userId: user?.id ?? null,
          workspaceId,
        });
        navigate(`/workspace/${workspaceId}/partner/home`);
      },
      startEnhancer: () => <BsGem />,
    },
    isFeatureEnabled("integration-hub") && {
      title: "Integrations",
      path: `/workspace/${workspaceId}/integrations`,
      onClick: () => {
        logEvent("dashboard.nav.click", {
          tab: "integrations",
          userId: user?.id ?? null,
          workspaceId,
        });
        navigate(`/workspace/${workspaceId}/integrations`);
      },
      startEnhancer: () => <BsXDiamond />,
    },
    {
      title: "Settings",
      path: `/workspace/${workspaceId}/settings`,
      onClick: () => {
        logEvent("dashboard.nav.click", {
          tab: "settings",
          userId: user?.id ?? null,
          workspaceId,
        });
        navigate(`/workspace/${workspaceId}/settings`);
      },
      startEnhancer: () => <BsGear />,
    },
  ];

  const profileMenuItem = {
    title: "Your Profile",
    path: "/settings",
    onClick: () => {
      logEvent("dashboard.nav.click", {
        tab: "your_profile",
        userId: user?.id ?? null,
        workspaceId,
      });
      navigate("/settings/profile");
    },
    startEnhancer: () => <BsPerson className="text-slate-600" />,
  };

  const secondaryMenuItems =
    workspaces.length > 1 ||
    (projectMembershipsData?.allProjects ?? []).length > 0
      ? [
          {
            title: "All Projects",
            path: "/home",
            onClick: () => {
              logEvent("dashboard.nav.click", {
                tab: "all_projects",
                userId: user?.id ?? null,
                workspaceId,
              });
              navigate("/home");
            },
            startEnhancer: () => <BsGrid className="text-slate-600" />,
          },
          profileMenuItem,
        ]
      : [profileMenuItem];

  const [expandedParentMenuItems, setExpandedParentMenuItems] = React.useState<
    string[]
  >([]);
  const [selectedParentItem, setSelectedParentItem] = React.useState<
    string | null
  >(null);

  // NOTE (kurt, 2024-08-19): This is a bit of a hack to get the first sub item to expand
  // when the parent item is expanded.
  const toggleExpand = (title: string, item: (typeof menuItems)[number]) => {
    if (selectedParentItem !== title) {
      setSelectedParentItem(title);
      setExpandedParentMenuItems([title]);
    } else {
      const hasExpanded = expandedParentMenuItems.includes(title);
      setExpandedParentMenuItems(hasExpanded ? [] : [title]);
    }

    if (item && "subItems" in item && item.subItems?.length) {
      if (!expandedParentMenuItems.includes(title)) {
        item.subItems[0]?.onClick?.();
      }
    } else if (item) {
      item.onClick?.();
    }
  };

  const isItemSelected = (itemPath: string) => {
    return itemPath === location.pathname;
  };

  const renderMenuItem = (item: any, isSubItem = false) => {
    if (!item) {
      return null;
    }

    const hasSubItems = item.subItems && item.subItems.length > 0;
    const isExpanded = expandedParentMenuItems.includes(item.title);
    const isSelected = isItemSelected(item.path);

    return (
      <React.Fragment key={item.title}>
        <DashboardMenuItem
          item={{
            ...item,
            onClick: () => toggleExpand(item.title, item),
            isSubItem,
            hasSubItems,
            isExpanded,
            isSelected,
          }}
        />
        {hasSubItems && isExpanded && (
          <div className="ml-5 mt-1 relative">
            <div className="absolute left-0 top-0 bottom-0 w-px bg-gray-200"></div>
            <div className="pl-4">
              {item.subItems.map((subItem: (typeof item.subItems)[number]) => (
                <DashboardMenuItem
                  key={subItem.title}
                  item={{
                    ...subItem,
                    onClick: () => subItem.onClick(),
                    isSubItem: true,
                    hasSubItems: false,
                    isExpanded: false,
                    isSelected: isItemSelected(subItem.path),
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="flex w-full flex-col gap-0.5">
      {filterNulls(menuItems).map((item) => renderMenuItem(item))}
      <Separator className="my-2" />
      {secondaryMenuItems.map((item) => renderMenuItem(item))}
    </div>
  );
};

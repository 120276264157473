import { LoadingBar } from "@common/LoadingBar";
import classNames from "classnames";
import * as React from "react";

import type { CanvasLoadingType } from "./canvas-types";

export const EditorLoadingScreen: React.FC<{
  type: CanvasLoadingType | null;
}> = ({ type }) => {
  const loadingText = `Loading ${type === "fetchingContent" ? " Shopify Theme" : "Content"}`;
  return (
    <div
      className={classNames([
        "bg-blue-800 w-full h-full fixed flex flex-col items-center justify-center z-[1362574839]",
        // Note (Ovishek, 2022-04-20): This maxHeight is for fixing the editor
        // loading screen height otherwise it will take the full height of
        // previous element's height which we don't want
        "max-h-[1000px]",
        // NOTE (Chance 2024-05-09): When the editor is loading the loading
        // screen's parent may not have a height, so we give it a minimum until
        // loading is complete.
        "min-h-[60vh]",
      ])}
    >
      <LoadingBar isLoading loadingText={loadingText} />
      <p className="mt-8 text-2xl font-semibold text-white"></p>
      <div className="mt-8 w-72 font-mono text-sm text-slate-50">
        Something not working?
        <button
          type="button"
          className="cursor-pointer px-1 font-bold text-blue-200"
          onClick={() => window.location.reload()}
        >
          Refresh the page
        </button>
        or
        <a
          className="cursor-pointer px-1 font-bold text-blue-200"
          href="mailto:support@replo.app"
          target="_blank"
          rel="noreferrer"
        >
          contact support
        </a>
      </div>
    </div>
  );
};

import * as React from "react";

import type { BaseSharedLiquidProps } from "../../shared/types";
import { SharedShopifyLiquid } from "./SharedShopifyLiquid";

type SharedLiquidReviewIntegrationsProps = BaseSharedLiquidProps & {
  liquidSource: string;
  shouldHavePlaceHolder: boolean;
  placeholder: string;
  appName: string;
  isProductRating?: boolean;
};

export const SharedLiquidReviewIntegrations = ({
  attributes,
  component,
  shouldHavePlaceHolder,
  liquidSource,
  placeholder: placeholderFromProps,
  appName,
  context,
  isProductRating,
}: SharedLiquidReviewIntegrationsProps) => {
  const forcePlaceholder = shouldHavePlaceHolder || liquidSource === "";
  const placeholder = forcePlaceholder
    ? `The ${appName} Widget should appear here. Please ensure you have ${appName} installed, or reach out to support@replo.app`
    : placeholderFromProps;

  return (
    <>
      {component.props._css && (
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: String(component.props._css),
          }}
        />
      )}
      <SharedShopifyLiquid
        forceEditorPlaceholder={forcePlaceholder}
        liquidSource={liquidSource!}
        componentId={component.id}
        componentAttributes={attributes}
        isLiquidSupported
        placeholder={placeholder}
        repeatedIndexPath={context.repeatedIndexPath}
        placeholderStyles={
          isProductRating
            ? { minHeight: "30px", paddingTop: "5px", paddingBottom: "5px" }
            : {}
        }
      />
    </>
  );
};

export default SharedLiquidReviewIntegrations;

import type { ToggleGroupOption } from "@common/designSystem/ToggleGroup";
import ToggleGroup from "@common/designSystem/ToggleGroup";
import ComponentTemplatePane from "@components/ComponentTemplatePane";
import ComponentTreePane from "@components/ComponentTreePane";
import { minimumLeftBarWidth } from "@components/editor/constants";
import ElementsPane from "@components/ElementsPane";
import TourStepTrigger from "@components/flows/TourStepTrigger";
import ResizablePane from "@components/ResizablePane";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useEditorPerformanceContext } from "@editor/contexts/editor-performance.context";
import { isNewLeftBarUIEnable } from "@editor/infra/featureFlags";
import {
  selectLeftBarActiveTab,
  selectLeftBarNavigationOpenedMenu,
  selectLeftBarWidth,
  setLeftBarActiveTab,
  setLeftBarWidth,
} from "@editor/reducers/ui-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import useCreateComponentForm from "@hooks/useCreateComponentForm";
import useCurrentProjectId from "@hooks/useCurrentProjectId";
import { selectEditorMode } from "@reducers/core-reducer";
import type { LeftBarTab } from "@reducers/ui-reducer";
import { Spinner } from "@replo/design-system/components/spinner";
import * as React from "react";

const TOGGLE_OPTIONS: ToggleGroupOption<LeftBarTab>[] = [
  {
    label: "Layers",
    value: "tree",
    tooltipContent: "Navigation and Layers",
    attributes: {
      "data-testid": "component-library-tree",
    },
  },
  {
    label: "Components",
    value: "components",
    tooltipContent: "Component Library",
    attributes: {
      "data-testid": "component-library-tab",
    },
  },
];

const LeftBar: React.FC = React.memo(function LeftBar() {
  const editorMode = useEditorSelector(selectEditorMode);
  const projectId = useCurrentProjectId();
  const { isLoading } = useCurrentProjectContext();
  const activeTab = useEditorSelector(selectLeftBarActiveTab);
  const leftBarWidth = useEditorSelector(selectLeftBarWidth);
  const leftBarNavigationOpenedMenu = useEditorSelector(
    selectLeftBarNavigationOpenedMenu,
  );
  const isLeftBarNavigationOpen = leftBarNavigationOpenedMenu !== "";
  const dispatch = useEditorDispatch();
  const { leftBarElementRef } = useEditorPerformanceContext();

  useCreateComponentForm(() => dispatch(setLeftBarActiveTab("components")));

  if (editorMode !== EditorMode.edit || isLoading) {
    return null;
  }

  if (isNewLeftBarUIEnable()) {
    return <LeftPanelMenu />;
  }

  return (
    // NOTE (Gabe 2023-06-13): z-0 creates a new stacking context which enables
    // using z-index for sticky headers that don't overlay portals in the rest
    // of the app.
    <div className="flex z-0" ref={leftBarElementRef}>
      <ResizablePane
        minSize={minimumLeftBarWidth}
        maxSize={500}
        handleClassName="bg-slate-200 w-[1px]"
        className="flex h-full"
        size={leftBarWidth}
        contentClassName="flex-1 bg-white"
        onResize={(size) => dispatch(setLeftBarWidth(size))}
        isDisabled={isLeftBarNavigationOpen}
      >
        <div className="mx-2 mb-2">
          <ToggleGroup
            allowsDeselect={false}
            type="single"
            options={TOGGLE_OPTIONS}
            value={activeTab}
            onChange={(value) => {
              dispatch(setLeftBarActiveTab(value));
            }}
            style={{ width: "100%" }}
          />
        </div>

        {activeTab === "tree" ? (
          <TreeTabPane projectId={projectId} />
        ) : (
          <ComponentTemplatePane />
        )}
      </ResizablePane>
    </div>
  );
});

const TreeTabPane: React.FC<{
  projectId: string | undefined;
}> = ({ projectId }) =>
  projectId ? (
    <>
      <TourStepTrigger step="step-1">
        <ElementsPane />
      </TourStepTrigger>
      <ComponentTreePane />
    </>
  ) : (
    <div className="flex h-full w-full items-center justify-center">
      <Spinner width={1} size={30} />
    </div>
  );

export default LeftBar;

const LeftPanelMenu: React.FC = () => {
  return <div>Left Panel</div>;
};

import * as React from "react";
import { Svg, type SvgProps } from "replo-utils/react/components";

export const SvgBlank = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 800 551"
    {...props}
  >
    <rect
      width={797}
      height={549}
      x={1.5}
      y={1.5}
      fill="#fff"
      stroke="#1D4ED8"
      strokeWidth={3}
      rx={18.5}
    />
  </Svg>
);
export default SvgBlank;

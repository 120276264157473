import * as React from "react";

import {
  ReploElementContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { isFeatureSupported } from "../../../shared/utils/supportedFeatures";
import { prependProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const ShopifyRawLiquid: React.FC<RenderComponentProps> = ({
  component,
  context,
  componentAttributes,
}) => {
  const { elementType } = useRuntimeContext(ReploElementContext);
  const { repeatedIndexPath, state } = context;
  const liquidContent = component.props._liquidContent ?? "";
  const product = state.product?.product ?? null;

  const liquid = prependProductAssignmentToLiquidSource({
    product,
    liquidSource: liquidContent,
    context,
  });
  return (
    <SharedShopifyLiquid
      liquidSource={liquid}
      forceEditorPlaceholder={false}
      componentId={component.id}
      componentAttributes={componentAttributes}
      isLiquidSupported={isFeatureSupported(elementType, "shopifyLiquid")}
      repeatedIndexPath={repeatedIndexPath}
    />
  );
};

export default ShopifyRawLiquid;

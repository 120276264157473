import isEqual from "lodash-es/isEqual";
import * as React from "react";

import { type GlobalWindow, isSafeWindow } from "../../../shared/Window";

/**
 * NOTE (Noah, 2021-07-24): This is mostly ripped from react-player, but we don't
 * use react-player for a variety of reasons (large bundle size, we want to have
 * our own code for youtube embeds etc, more control over different properties,
 * we don't need a lot of the stuff it does like HLS support or whatever)
 */

function isMediaStream(
  globalWindow: GlobalWindow | null,
  url: string | MediaStream,
): url is MediaStream {
  return (
    globalWindow != null &&
    "MediaStream" in globalWindow &&
    // Note (Noah, 2023-11-05, REPL-9162): Apparently `"MediaStream" in window`
    // is not exhaustive enough of a check for this, somehow. I'm not sure why,
    // might be an old browser compatibility thing? We've seen this in Sentry,
    // so adding this extra check here to be safe. See also this bug which was
    // fixed in react-player long ago: https://github.com/CookPete/react-player/issues/415
    typeof globalWindow.MediaStream !== "undefined" &&
    url instanceof globalWindow.MediaStream
  );
}

export function supportsWebKitPresentationMode(
  globalWindow: GlobalWindow | null,
  video: HTMLVideoElement | HTMLAudioElement | null,
): video is (HTMLVideoElement | HTMLAudioElement) & {
  webkitSupportsPresentationMode: boolean;
  webkitSetPresentationMode: (mode: string) => void;
  webkitPresentationMode: string;
} {
  if (!globalWindow) {
    return false;
  }
  video ??= globalWindow.document.createElement("video");
  // Check if Safari supports PiP, and is not on mobile (other than iPad)
  // iPhone safari appears to "support" PiP through the check, however PiP does not function
  const notMobile =
    /iPhone|iPod/.test(globalWindow.navigator.userAgent) === false;
  return Boolean(
    notMobile &&
      // @ts-expect-error
      video.webkitSupportsPresentationMode &&
      // @ts-expect-error
      typeof video.webkitSetPresentationMode === "function",
  );
}

const AUDIO_EXTENSIONS =
  /\.(m4a|mp4a|mpga|mp2|mp2a|mp3|m2a|m3a|wav|weba|aac|oga|spx)($|\?)/;

const MATCH_DROPBOX_URL = /www\.dropbox\.com\/.+/;

interface FilePlayerProps {
  url: any;
  playsinline: boolean;
  playing: boolean;
  loop: boolean;
  controls: boolean;
  preload: string;
  muted: boolean;
  width: any;
  height: any;
  forceVideo: boolean;
  forceAudio: boolean;
  poster?: string;
  tracks: any[] | null;
  style: any;
  playerRef: any;

  onMount?: Function;
  onLoaded?: Function;
  onReady?: Function;
  onPlay?: Function;
  onBuffer?: Function;
  onBufferEnd?: Function;
  onPause?: Function;
  onEnded?: Function;
  onError?: (error: unknown) => void;
  onEnablePIP?: Function;
  onDisablePIP?: Function;
  onSeek?(currentTime: number): void;
}

class FilePlayer extends React.Component<FilePlayerProps> {
  static displayName = "FilePlayer";
  globalWindow: GlobalWindow | null = null;
  player: HTMLAudioElement | HTMLVideoElement | null = null;
  prevPlayer: HTMLAudioElement | HTMLVideoElement | null = null;

  componentDidMount() {
    this.props.onMount?.(this);
    this.addListeners(this.player);

    const globalWindow = this.globalWindow;
    const isIpadPro =
      globalWindow?.navigator &&
      globalWindow.navigator.platform === "MacIntel" &&
      globalWindow.navigator.maxTouchPoints > 1;
    const isIos =
      globalWindow?.navigator &&
      (/iPad|iPhone|iPod/.test(globalWindow.navigator.userAgent) ||
        isIpadPro) &&
      !globalWindow.MSStream;

    if (isIos) {
      this.player?.load();
    }
    this.setMutedAttribute(this.props.muted);
  }

  componentDidUpdate(prevProps: FilePlayerProps) {
    if (this.shouldUseAudio(this.props) !== this.shouldUseAudio(prevProps)) {
      this.removeListeners(this.prevPlayer);
      this.addListeners(this.player);
    }

    if (
      this.player &&
      this.props.url !== prevProps.url &&
      !isMediaStream(this.globalWindow, this.props.url)
    ) {
      this.player.srcObject = null;
    }
  }

  componentWillUnmount() {
    this.removeListeners(this.player);
  }

  addListeners(player: any) {
    const { playsinline } = this.props;
    player.addEventListener("play", this.onPlay);
    player.addEventListener("waiting", this.onBuffer);
    player.addEventListener("playing", this.onBufferEnd);
    player.addEventListener("pause", this.onPause);
    player.addEventListener("seeked", this.onSeek);
    player.addEventListener("ended", this.onEnded);
    player.addEventListener("error", this.onError);
    player.addEventListener("enterpictureinpicture", this.onEnablePIP);
    player.addEventListener("leavepictureinpicture", this.onDisablePIP);
    player.addEventListener(
      "webkitpresentationmodechanged",
      this.onPresentationModeChange,
    );
    player.addEventListener("canplay", this.onReady);
    if (playsinline) {
      player.setAttribute("playsinline", "");
      player.setAttribute("webkit-playsinline", "");
      player.setAttribute("x5-playsinline", "");
    }
  }

  removeListeners(player: any) {
    player.removeEventListener("canplay", this.onReady);
    player.removeEventListener("play", this.onPlay);
    player.removeEventListener("waiting", this.onBuffer);
    player.removeEventListener("playing", this.onBufferEnd);
    player.removeEventListener("pause", this.onPause);
    player.removeEventListener("seeked", this.onSeek);
    player.removeEventListener("ended", this.onEnded);
    player.removeEventListener("error", this.onError);
    player.removeEventListener("enterpictureinpicture", this.onEnablePIP);
    player.removeEventListener("leavepictureinpicture", this.onDisablePIP);
    player.removeEventListener(
      "webkitpresentationmodechanged",
      this.onPresentationModeChange,
    );
    player.removeEventListener("canplay", this.onReady);
  }

  // Proxy methods to prevent listener leaks
  onReady = (...args: any[]) => this.props.onReady?.(...args);
  onPlay = (...args: any[]) => this.props.onPlay?.(...args);
  onBuffer = (...args: any[]) => this.props.onBuffer?.(...args);
  onBufferEnd = (...args: any[]) => this.props.onBufferEnd?.(...args);
  onPause = (...args: any[]) => this.props.onPause?.(...args);
  onEnded = (...args: any[]) => this.props.onEnded?.(...args);
  onError = (error: unknown) => this.props.onError?.(error);
  onEnablePIP = (...args: any[]) => this.props.onEnablePIP?.(...args);

  onDisablePIP = (e: any) => {
    const { onDisablePIP, playing } = this.props;
    onDisablePIP?.(e);
    if (playing) {
      this.play();
    }
  };

  onPresentationModeChange = (e: any) => {
    if (
      this.player &&
      supportsWebKitPresentationMode(this.globalWindow, this.player)
    ) {
      const { webkitPresentationMode } = this.player;
      if (webkitPresentationMode === "picture-in-picture") {
        this.onEnablePIP(e);
      } else if (webkitPresentationMode === "inline") {
        this.onDisablePIP(e);
      }
    }
  };

  onSeek = (e: any) => {
    this.props.onSeek?.(e.target.currentTime);
  };

  shouldUseAudio(props: FilePlayerProps) {
    if (props.forceVideo) {
      return false;
    }
    if (props.poster) {
      return false; // Use <video> so that poster is shown
    }
    return AUDIO_EXTENSIONS.test(props.url) || props.forceAudio;
  }

  load(url: any) {
    if (!this.player) {
      return;
    }
    if (Array.isArray(url)) {
      // When setting new urls (<source>) on an already loaded video,
      // HTMLMediaElement.load() is needed to reset the media element
      // and restart the media resource. Just replacing children source
      // dom nodes is not enough
      this.player.load();
    } else if (isMediaStream(this.globalWindow, url)) {
      try {
        this.player.srcObject = url;
      } catch {
        // Note (Chance 2023-05-25) I'm not sure why this wouldn't throw?
        // Passing a MediaStream object to `createObjectURL` throws a TypeError
        // in the browser.
        // @ts-expect-error
        this.player.src = this.globalWindow?.URL.createObjectURL(url);
      }
    }
  }

  play() {
    const promise = this.player?.play();
    if (promise) {
      void promise.catch(this.props.onError);
    }
  }

  pause() {
    this.player?.pause();
  }

  stop() {
    this.player?.removeAttribute("src");
  }

  seekTo(seconds: number) {
    if (this.player) {
      this.player.currentTime = seconds;
    }
  }

  setVolume(fraction: number) {
    if (this.player) {
      this.player.volume = fraction;
    }
  }

  setMutedAttribute(value: boolean) {
    // Note (Noah, 2022-01-02, REPL-2821): In order for videos to autoplay, some
    // browsers like Chrome require the "muted" attribute to be present. It's not
    // enough to .muted = true on the video element, the attribute needs to be there
    // (for some reason)
    this.player?.setAttribute("muted", value as any);
  }

  mute = () => {
    if (this.player) {
      this.player.muted = true;
    }
    this.setMutedAttribute(true);
  };

  unmute = () => {
    if (this.player) {
      this.player.muted = false;
    }
    this.setMutedAttribute(false);
  };

  enablePIP() {
    if (!this.player) {
      return;
    }
    const _document = this.globalWindow?.document;
    if (
      "requestPictureInPicture" in this.player &&
      this.player.requestPictureInPicture &&
      _document?.pictureInPictureElement !== this.player
    ) {
      void this.player.requestPictureInPicture();
    } else if (
      supportsWebKitPresentationMode(this.globalWindow, this.player) &&
      this.player.webkitPresentationMode !== "picture-in-picture"
    ) {
      this.player.webkitSetPresentationMode("picture-in-picture");
    }
  }

  disablePIP() {
    const document = this.globalWindow?.document;
    if (
      document &&
      document.exitPictureInPicture &&
      document.pictureInPictureElement === this.player
    ) {
      void document.exitPictureInPicture();
    } else if (
      supportsWebKitPresentationMode(this.globalWindow, this.player) &&
      this.player.webkitPresentationMode !== "inline"
    ) {
      this.player.webkitSetPresentationMode("inline");
    }
  }

  setPlaybackRate(rate: number) {
    if (this.player) {
      this.player.playbackRate = rate;
    }
  }

  getDuration() {
    if (!this.player) {
      return 0;
    }
    const { duration, seekable } = this.player;
    // on iOS, live streams return Infinity for the duration
    // so instead we use the end of the seekable timerange
    if (duration === Number.POSITIVE_INFINITY && seekable.length > 0) {
      return seekable.end(seekable.length - 1);
    }
    return duration;
  }

  getCurrentTime() {
    if (!this.player) {
      return null;
    }
    return this.player.currentTime;
  }

  getSecondsLoaded() {
    if (!this.player) {
      return null;
    }
    const { buffered } = this.player;
    if (buffered.length === 0) {
      return 0;
    }
    const end = buffered.end(buffered.length - 1);
    const duration = this.getDuration();
    if (end > duration) {
      return duration;
    }
    return end;
  }

  getSource = (url: any) => {
    if (Array.isArray(url) || isMediaStream(this.globalWindow, url)) {
      return undefined;
    }
    if (MATCH_DROPBOX_URL.test(url)) {
      return url.replace("www.dropbox.com", "dl.dropboxusercontent.com");
    }
    return url;
  };

  renderSourceElement = (source: any, index: number) => {
    if (typeof source === "string") {
      return <source key={index} src={source} />;
    }
    return <source key={index} {...source} />;
  };

  renderTrack = (track: any, index: number) => {
    return <track key={index} {...track} />;
  };

  ref: {
    (player: HTMLVideoElement | null): void;
    (player: HTMLAudioElement | null): void;
  } = (player) => {
    if (this.player) {
      // Store previous player to be used by removeListeners()
      this.prevPlayer = this.player;
    }
    const globalWindow = player?.ownerDocument.defaultView;
    this.globalWindow =
      globalWindow && isSafeWindow(globalWindow) ? globalWindow : null;
    this.player = player;
    this.props.playerRef.current = player;
  };

  render() {
    const {
      url,
      playing,
      loop,
      controls,
      muted,
      preload,
      width,
      height,
      poster,
    } = this.props;
    const useAudio = this.shouldUseAudio(this.props);
    const Element = useAudio ? "audio" : "video";
    const style = {
      ...this.props.style,
      width: width === "auto" ? width : "100%",
      height: height === "auto" ? height : "100%",
    };
    return (
      <Element
        ref={this.ref}
        src={this.getSource(url)}
        style={style}
        preload={preload}
        autoPlay={playing || undefined}
        controls={controls}
        muted={muted}
        loop={loop}
        poster={poster}
      >
        {Array.isArray(url) &&
          url.map((source, index) => this.renderSourceElement(source, index))}
        {(this.props.tracks || []).map((track, index) =>
          this.renderTrack(track, index),
        )}
      </Element>
    );
  }
}

type GeneralPlayerProps = FilePlayerProps & {
  stopOnUnmount: boolean;
  volume: number | null;
  playbackRate: number;
  pip: boolean;
  forceLoad: boolean;
  progressFrequency: number | null;
  progressInterval: number;

  onDuration?: Function;
  onProgress?: Function;
  onStart?: Function;
  children?: React.ReactNode;
};

const SEEK_ON_PLAY_EXPIRY = 5000;

type Progress = {
  playedSeconds: number;
  played: number;
  loadedSeconds?: number;
  loaded?: number;
};

export default class ReactPlayer extends React.Component<GeneralPlayerProps> {
  mounted = false;
  isReady = false;
  isPlaying = false; // Track playing state internally to prevent bugs
  isLoading = true; // Use isLoading to prevent onPause when switching URL
  loadOnReady: boolean | null = null;
  startOnPlay = true;
  seekOnPlay: number | null = null;
  onDurationCalled = false;
  player: FilePlayer | null = null;
  progressTimeout: any = null;
  durationCheckTimeout: any = null;
  stopOnUnmount: any = null;
  prevPlayed: any = null;
  prevLoaded: any = null;

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    clearTimeout(this.progressTimeout);
    clearTimeout(this.durationCheckTimeout);
    if (this.isReady && this.props.stopOnUnmount) {
      this.player?.stop();

      if (this.player?.disablePIP) {
        this.player?.disablePIP();
      }
    }
    this.mounted = false;
  }

  componentDidUpdate(prevProps: GeneralPlayerProps) {
    // If there isn’t a player available, don’t do anything
    if (!this.player) {
      return;
    }
    // Invoke player methods based on changed props
    const { url, playing, volume, muted, playbackRate, pip } = this.props;
    if (!isEqual(prevProps.url, url)) {
      if (this.isLoading && !this.props.forceLoad) {
        console.warn(
          `FilePlayer: the attempt to load ${url} is being deferred until the player has loaded`,
        );
        this.loadOnReady = url;
        return;
      }
      this.isLoading = true;
      this.startOnPlay = true;
      this.onDurationCalled = false;
      this.player.load(url);
    }
    if (!prevProps.playing && playing && !this.isPlaying) {
      this.player.play();
    }
    if (prevProps.playing && !playing && this.isPlaying) {
      this.player.pause();
    }
    if (!prevProps.pip && pip && this.player.enablePIP) {
      this.player.enablePIP();
    }
    if (prevProps.pip && !pip && this.player.disablePIP) {
      this.player.disablePIP();
    }
    if (prevProps.volume !== volume && volume !== null) {
      this.player.setVolume(volume);
    }
    if (prevProps.muted !== muted) {
      if (muted) {
        this.player.mute();
      } else {
        this.player.unmute();
        if (volume !== null) {
          // Set volume next tick to fix a bug with DailyMotion
          setTimeout(() => this.player?.setVolume(volume));
        }
      }
    }
    if (
      prevProps.playbackRate !== playbackRate &&
      this.player.setPlaybackRate
    ) {
      this.player.setPlaybackRate(playbackRate);
    }
  }

  handlePlayerMount = (player: FilePlayer) => {
    this.player = player;
    this.player.load(this.props.url);
    this.progress();
  };

  getDuration() {
    if (!this.isReady) {
      return 0;
    }
    return this.player?.getDuration() ?? 0;
  }

  getCurrentTime() {
    if (!this.isReady) {
      return 0;
    }
    return this.player?.getCurrentTime() ?? 0;
  }

  getSecondsLoaded() {
    if (!this.isReady) {
      return 0;
    }
    return this.player?.getSecondsLoaded() ?? 0;
  }

  progress = () => {
    if (this.props.url && this.player && this.isReady) {
      const playedSeconds = this.getCurrentTime() || 0;
      const loadedSeconds = this.getSecondsLoaded();
      const duration = this.getDuration();
      if (duration) {
        const progress: Progress = {
          playedSeconds,
          played: playedSeconds / duration,
          loaded: undefined,
          loadedSeconds: undefined,
        };
        if (loadedSeconds !== null) {
          progress.loadedSeconds = loadedSeconds;
          progress.loaded = loadedSeconds / duration;
        }
        // Only call onProgress if values have changed
        if (
          this.props.onProgress &&
          (progress.playedSeconds !== this.prevPlayed ||
            progress.loadedSeconds !== this.prevLoaded)
        ) {
          this.props.onProgress(progress);
        }
        this.prevPlayed = progress.playedSeconds;
        this.prevLoaded = progress.loadedSeconds;
      }
    }
    this.progressTimeout = setTimeout(
      this.progress,
      this.props.progressFrequency || this.props.progressInterval,
    );
  };

  seekTo(amount: number, type: string | null) {
    // When seeking before player is ready, store value and seek later
    if (!this.isReady && amount !== 0) {
      this.seekOnPlay = amount;
      setTimeout(() => {
        this.seekOnPlay = null;
      }, SEEK_ON_PLAY_EXPIRY);
      return;
    }
    const isFraction = type ? type === "fraction" : amount > 0 && amount < 1;
    if (isFraction) {
      // Convert fraction to seconds based on duration
      const duration = this.player?.getDuration();
      if (!duration) {
        console.warn(
          "ReactPlayer: could not seek using fraction – duration not yet available",
        );
        return;
      }
      this.player?.seekTo(duration * amount);
      return;
    }
    this.player?.seekTo(amount);
  }

  handleReady = () => {
    if (!this.mounted) {
      return;
    }
    this.isReady = true;
    this.isLoading = false;
    const { onReady, playing, volume, muted } = this.props;
    onReady?.();
    if (!muted && volume !== null) {
      this.player?.setVolume(volume);
    }
    if (this.loadOnReady) {
      this.player?.load(this.loadOnReady);
      this.loadOnReady = null;
    } else if (playing) {
      this.player?.play();
    }
    this.handleDurationCheck();
  };

  handlePlay = () => {
    this.isPlaying = true;
    this.isLoading = false;
    const { onStart, onPlay, playbackRate } = this.props;
    if (this.startOnPlay) {
      if (this.player?.setPlaybackRate && playbackRate !== 1) {
        this.player?.setPlaybackRate(playbackRate);
      }
      onStart?.();
      this.startOnPlay = false;
    }
    onPlay?.();
    if (this.seekOnPlay) {
      this.seekTo(this.seekOnPlay, null);
      this.seekOnPlay = null;
    }
    this.handleDurationCheck();
  };

  handlePause = (e: any) => {
    this.isPlaying = false;
    if (!this.isLoading) {
      this.props.onPause?.(e);
    }
  };

  handleEnded = () => {
    const { loop, onEnded } = this.props;
    if (loop) {
      this.seekTo(0, null);
    }
    if (!loop) {
      this.isPlaying = false;
      onEnded?.();
    }
  };

  handleError = (error: unknown) => {
    this.isLoading = false;
    this.props.onError?.(error);
  };

  handleDurationCheck = () => {
    clearTimeout(this.durationCheckTimeout);
    const duration = this.getDuration();
    if (duration) {
      if (!this.onDurationCalled && this.props.onDuration) {
        this.props.onDuration(duration);
        this.onDurationCalled = true;
      }
    } else {
      this.durationCheckTimeout = setTimeout(this.handleDurationCheck, 100);
    }
  };

  handleLoaded = () => {
    // Sometimes we know loading has stopped but onReady/onPlay are never called
    // so this provides a way for players to avoid getting stuck
    this.isLoading = false;
  };

  render() {
    return (
      <FilePlayer
        {...this.props}
        onMount={this.handlePlayerMount}
        onReady={this.handleReady}
        onPlay={this.handlePlay}
        onPause={this.handlePause}
        onEnded={this.handleEnded}
        onLoaded={this.handleLoaded}
        onError={this.handleError}
      />
    );
  }
}

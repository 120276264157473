import type { Gradient } from "replo-runtime/shared/types.ts";

export const gradientToCssGradient = (
  gradient: Partial<Gradient>,
): string | null => {
  let stops = gradient.stops?.map((stop) => {
    return `${stop.color} ${stop.location}`;
  });

  if (stops?.length === 0) {
    return null;
  }

  if (stops?.length === 1) {
    stops = stops.concat(stops);
  }

  return `linear-gradient(${gradient.tilt}, ${stops?.join(", ")})`;
};

import AppModals from "@components/AppModals";
import * as React from "react";

const RouteWithModals: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      {children}
      <AppModals />
    </>
  );
};

export default RouteWithModals;

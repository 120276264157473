import { SimpleSkeletonLoader } from "@editor/components/common/designSystem/SkeletonLoader";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@replo/design-system/components/shadcn/core/card";
import clsxMerge from "@replo/design-system/components/shadcn/utils/cn-merge";
import * as React from "react";

import AllMetricsTable from "@/features/analytics/AllMetricsTable";

type PagesOverviewProps = {
  title?: string;
  skeleton?: boolean;
};

const PagesOverview: React.FC<PagesOverviewProps> = ({ title, skeleton }) => {
  return (
    <>
      <div className="text-2xl font-semibold my-8">
        {skeleton ? (
          <SimpleSkeletonLoader height="14" width="141" />
        ) : (
          "Pages Overview"
        )}
      </div>
      <Card>
        <CardHeader>
          <CardTitle>
            {skeleton ? (
              <SimpleSkeletonLoader width="141" height="10" />
            ) : (
              title
            )}
          </CardTitle>
        </CardHeader>
        <CardContent>
          {skeleton ? (
            <div className="flex flex-col w-full items-center space-y-2 py-12">
              <span
                className={clsxMerge(
                  "relative inline-flex rounded-full h-4 w-4 bg-blue-600 my-3",
                  skeleton ? "" : "animate-bounce",
                )}
              ></span>
              <div className="text-lg font-semibold">Gathering Data...</div>
              <div className="text-sm max-w-[400px] text-center">
                There&apos;s not enough data to show you the top pages yet.
                Please check back soon.
              </div>
            </div>
          ) : (
            <AllMetricsTable />
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default PagesOverview;

import type { AlchemyAnimationType } from "replo-runtime/shared/enums";
import type { AnimationField } from "replo-runtime/shared/types";

/**
 *
 * We are forcing the animation to reset itself.
 * To do that we change the name, call the offsetWidth and then change it back.
 *
 * Here's an explanation of why offsetWidth is necessary:
 * https://css-tricks.com/restart-css-animation/#aa-update-another-javascript-method-to-restart-a-css-animation
 *
 */
export const resetAnimationAndSetRunningState = (
  node: HTMLElement,
  animationName: string,
  animationPlayState: "paused" | "running",
) => {
  node.style.animationName = "none";
  void node.offsetWidth;
  node.style.animationName = animationName;
  node.style.animationPlayState = animationPlayState;
};

export type AnimationName =
  | "alchemy-slide-y"
  | "alchemy-fade"
  | "alchemy-flip-x"
  | "alchemy-grow"
  | "alchemy-spin"
  | "alchemy-fly-y"
  | "alchemy-drop";

export type AnimationValue = {
  styles: {
    "--offset"?: string;
    "--final-opacity"?: string;
    "--initial-opacity"?: string;
    "--initial-scale"?: string;
    "--final-scale"?: string;
    "--initial-angle"?: string;
    "--final-angle"?: string;
    "--angle"?: string;
    animationName?: string;
    animationDelay?: string;
    animationDuration?: string;
    animationFillMode?: "none" | "forwards" | "backwards" | "both";
    animationDirection?:
      | "normal"
      | "reverse"
      | "alternate"
      | "alternate-reverse";
    animationTimingFunction?: string;
    transformStyle?: string;
  } | null;
  from?: "top";
  direction?: "clockwise";
  [key: string]: any;
};

const animationTypeToDefaultValueMap: Record<
  AlchemyAnimationType,
  AnimationValue
> = {
  fadeIn: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      animationName: "alchemy-fade",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "normal",
      animationFillMode: "both",
    },
  },
  fadeOut: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      animationName: "alchemy-fade",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "reverse",
      animationFillMode: "both",
    },
  },
  slideIn: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      "--offset": "-50px",
      animationName: "alchemy-slide-y",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "normal",
      animationFillMode: "both",
    },
    from: "top",
  },
  slideOut: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      "--offset": "-50px",
      animationName: "alchemy-slide-y",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "reverse",
      animationFillMode: "both",
    },
    from: "top",
  },
  flipIn: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      "--angle": "180deg",
      animationName: "alchemy-flip-x",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "normal",
      animationFillMode: "both",
      transformStyle: "preserve-3d",
    },
    from: "top",
  },
  flipOut: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      "--angle": "180deg",
      animationName: "alchemy-flip-x",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "reverse",
      animationFillMode: "both",
      transformStyle: "preserve-3d",
    },
    from: "top",
  },
  growIn: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      "--initial-scale": "0.8",
      "--final-scale": "1",
      animationName: "alchemy-grow",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "normal",
      animationFillMode: "both",
    },
  },
  growOut: {
    styles: {
      "--initial-opacity": "1",
      "--final-opacity": "0",
      "--initial-scale": "0.8",
      "--final-scale": "1.8",
      animationName: "alchemy-grow",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "normal",
      animationFillMode: "both",
    },
  },
  growBigIn: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      "--initial-scale": "0",
      "--final-scale": "1",
      animationName: "alchemy-grow",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "normal",
      animationFillMode: "both",
    },
  },
  growBigOut: {
    styles: {
      "--initial-opacity": "1",
      "--final-opacity": "0",
      "--initial-scale": "0",
      "--final-scale": "1.8",
      animationName: "alchemy-grow",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "normal",
      animationFillMode: "both",
    },
  },
  shrinkIn: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      "--initial-scale": "1.2",
      "--final-scale": "1",
      animationName: "alchemy-grow",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "normal",
      animationFillMode: "both",
    },
  },
  shrinkOut: {
    styles: {
      "--initial-opacity": "1",
      "--final-opacity": "0",
      "--initial-scale": "1.2",
      "--final-scale": "1",
      animationName: "alchemy-grow",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "normal",
      animationFillMode: "both",
    },
  },
  shrinkBigIn: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      "--initial-scale": "1.8",
      "--final-scale": "1",
      animationName: "alchemy-grow",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "normal",
      animationFillMode: "both",
    },
  },
  shrinkBigOut: {
    styles: {
      "--initial-opacity": "1",
      "--final-opacity": "0",
      "--initial-scale": "1.8",
      "--final-scale": "1",
      animationName: "alchemy-grow",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "normal",
      animationFillMode: "both",
    },
  },
  spinIn: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      "--initial-angle": "0deg",
      "--final-angle": "360deg",
      animationName: "alchemy-spin",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "normal",
      animationFillMode: "both",
    },
    direction: "clockwise",
  },
  spinOut: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      "--initial-angle": "0deg",
      "--final-angle": "360deg",
      animationName: "alchemy-spin",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "reverse",
      animationFillMode: "both",
    },
    direction: "clockwise",
  },
  flyIn: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      "--offset": "-300px",
      animationName: "alchemy-fly-y",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "normal",
      animationFillMode: "both",
    },
    from: "top",
  },
  flyOut: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      "--offset": "-300px",
      animationName: "alchemy-fly-y",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "ease-in-out",
      animationDirection: "reverse",
      animationFillMode: "both",
    },
    from: "top",
  },
  dropIn: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      "--offset": "-300px",
      animationName: "alchemy-drop",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
      animationDirection: "normal",
      animationFillMode: "both",
    },
  },
  dropOut: {
    styles: {
      "--initial-opacity": "0",
      "--final-opacity": "1",
      "--offset": "-300px",
      animationName: "alchemy-drop",
      animationDuration: "300ms",
      animationDelay: "0ms",
      animationTimingFunction: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
      animationDirection: "reverse",
      animationFillMode: "both",
    },
  },
};

export const getAnimationValueFromType = (type: AlchemyAnimationType) => {
  return animationTypeToDefaultValueMap[type];
};

export const animationTriggerTypes: {
  [key: string]: {
    variants: AlchemyAnimationType[];
    fields?: AnimationField[];
  };
} = {
  Fade: {
    variants: ["fadeIn", "fadeOut"],
  },
  Slide: {
    variants: ["slideIn", "slideOut"],
    fields: [
      {
        id: "from",
        type: "select",
        name: "From",
        placeholder: "Select which direction to slide from",
        options: [
          {
            label: "Top",
            value: "top",
            override: {
              animationName: "alchemy-slide-y",
              "--offset": "-50px",
            },
          },
          {
            label: "Right",
            value: "right",
            override: {
              animationName: "alchemy-slide-x",
              "--offset": "50px",
            },
          },
          {
            label: "Bottom",
            value: "bottom",
            override: {
              animationName: "alchemy-slide-y",
              "--offset": "50px",
            },
          },
          {
            label: "Left",
            value: "left",
            override: {
              animationName: "alchemy-slide-x",
              "--offset": "-50px",
            },
          },
        ],
      },
    ],
  },
  Flip: {
    variants: ["flipIn", "flipOut"],
    fields: [
      {
        id: "from",
        type: "select",
        name: "From",
        placeholder: "Select which direction to flip from",
        options: [
          {
            label: "Top",
            value: "top",
            override: {
              animationName: "alchemy-flip-x",
              "--angle": "180deg",
            },
          },
          {
            label: "Right",
            value: "right",
            override: {
              animationName: "alchemy-flip-y",
              "--angle": "-180deg",
            },
          },
          {
            label: "Bottom",
            value: "bottom",
            override: {
              animationName: "alchemy-flip-x",
              "--angle": "-180deg",
            },
          },
          {
            label: "Left",
            value: "left",
            override: {
              animationName: "alchemy-flip-y",
              "--angle": "180deg",
            },
          },
        ],
      },
    ],
  },
  Grow: {
    variants: ["growIn", "growOut"],
  },
  "Grow Big": {
    variants: ["growBigIn", "growBigOut"],
  },
  Shrink: {
    variants: ["shrinkIn", "shrinkOut"],
  },
  "Shrink Big": {
    variants: ["shrinkBigIn", "shrinkBigOut"],
  },
  Spin: {
    variants: ["spinIn", "spinOut"],
    fields: [
      {
        id: "direction",
        type: "toggle",
        name: "Direction",
        options: [
          {
            label: "Clockwise",
            value: "clockwise",
            override: {
              "--final-angle": "360deg",
            },
          },
          {
            label: "Counter Clockwise",
            value: "counterClockwise",
            override: {
              "--final-angle": "-360deg",
            },
          },
        ],
      },
    ],
  },
  Fly: {
    variants: ["flyIn", "flyOut"],
    fields: [
      {
        id: "from",
        type: "select",
        name: "From",
        placeholder: "Select which direction to fly from",
        options: [
          {
            label: "Top",
            value: "top",
            override: {
              animationName: "alchemy-fly-y",
              animationDirection: "normal",
            },
          },
          {
            label: "Right",
            value: "right",
            override: {
              animationName: "alchemy-fly-x",
              "--offset": "300px",
            },
          },
          {
            label: "Bottom",
            value: "bottom",
            override: {
              animationName: "alchemy-fly-y",
              animationDirection: "reverse",
            },
          },
          {
            label: "Left",
            value: "left",
            override: {
              animationName: "alchemy-fly-x",
              "--offset": "-300px",
            },
          },
        ],
      },
    ],
  },
  Drop: {
    variants: ["dropIn", "dropOut"],
  },
};

export const getAnimationTriggerType = (type: AlchemyAnimationType) => {
  return Object.keys(animationTriggerTypes).find((a: string) =>
    animationTriggerTypes[a]?.variants.includes(type),
  );
};

/**
 * We use a wrapper around sliding animations to make sure
 * that the animation is triggered at the right position
 * when the trigger in scrollIntoView
 *
 * Because of that, we need to know which animations needs to
 * have that wrapper
 *
 * So we are listing them all here
 */
export const slidingAnimations = [
  "slideIn",
  "slideOut",
  "flyIn",
  "flyOut",
  "dropIn",
  "dropOut",
];

import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import { isTailwindClassName } from "@editor/utils/tailwind-classname";
import { Content, Root, Trigger } from "@radix-ui/react-collapsible";
import classNames from "classnames";
import * as React from "react";
import { BsX } from "react-icons/bs";
import { animated, config, useSpring } from "react-spring";
import useMeasure from "react-use-measure";
import { twMerge } from "tailwind-merge";
import z from "zod";

type BannerProps = {
  children: React.ReactNode;
  backgroundColor?: string;
  isShowing?: boolean;
  onShowingChanged?(showing: boolean): void;
  className?: string;
  style?: React.CSSProperties;
} & (
  | ({ isDismissable: true } & (
      | { dismissPersistence: "state" }
      | { dismissPersistence: "localStorage"; localStorageKey: string }
    ))
  | { isDismissable?: false }
);

const Banner = ({
  children,
  backgroundColor = "transparent",
  isShowing,
  onShowingChanged,
  className,
  style,
  ...props
}: BannerProps) => {
  let localStorageKey = undefined;
  if (props.isDismissable && props.dismissPersistence === "localStorage") {
    localStorageKey = props.localStorageKey;
  }
  const [isShownLocalStorage = false, setIsShownLocalStorage] =
    useLocalStorageState(`replo.banners.${localStorageKey}`, true, {
      schema: z.boolean().nullish(),
    });
  const [isShownState, setIsShownState] = React.useState(
    isShowing !== undefined ? isShowing : true,
  );

  const [isShown, setIsShown] =
    props.isDismissable && props.dismissPersistence === "localStorage"
      ? [isShownLocalStorage, setIsShownLocalStorage]
      : [isShownState, setIsShownState];

  const [measureRef, { height }] = useMeasure();

  const styles = useSpring({
    config: config.stiff,
    from: {
      height: 0,
      opacity: 0,
    },
    to: {
      height: isShown ? height : 0,
      opacity: isShown ? 1 : 0,
    },
  });

  return (
    <Root open={isShown === null ? false : isShown} onOpenChange={setIsShown}>
      <Content forceMount>
        <animated.div style={{ overflow: "hidden", ...styles }}>
          <div
            ref={measureRef}
            className={twMerge(
              classNames(
                "relative top-0 flex w-full",
                {
                  [backgroundColor]: isTailwindClassName(backgroundColor, "bg"),
                },
                className,
              ),
            )}
            style={{
              ...style,
              backgroundColor:
                backgroundColor && !isTailwindClassName(backgroundColor, "bg")
                  ? backgroundColor
                  : undefined,
            }}
          >
            <div className="flex w-full items-center justify-center py-3">
              {children}
            </div>
            {props.isDismissable && (
              <Trigger asChild>
                <button className="m-3 w-3">
                  <span onClick={() => onShowingChanged?.(!isShowing)}>
                    <BsX className="text-slate-400" size="16px" />
                  </span>
                </button>
              </Trigger>
            )}
          </div>
        </animated.div>
      </Content>
    </Root>
  );
};

export default Banner;

import App from "@editor/App";
import {
  initializeAnalytics,
  initializeErrorTracking,
  initializePerformanceMonitoring,
} from "@editor/infra/analytics";
import { store } from "@editor/store";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

initializeErrorTracking();
initializeAnalytics();
initializePerformanceMonitoring();

const root = createRoot(document.querySelector("#root") as HTMLElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

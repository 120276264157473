import * as React from "react";

import {
  ReploElementContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { isFeatureSupported } from "../../../shared/utils/supportedFeatures";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const ShopifyAppBlocks: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
  context,
}) => {
  const { repeatedIndexPath } = context;
  const liquid = `
  {% for block in section.blocks %}
    {% case block.type %}
      {% when '@app' %}
        {% render block %}
    {% endcase %}
  {% endfor %}
  `;
  const { elementType } = useRuntimeContext(ReploElementContext);
  return (
    <SharedShopifyLiquid
      liquidSource={liquid}
      forceEditorPlaceholder={true}
      placeholder="Any App Blocks for this section will appear here. Use this Section in the Shopify Theme Customizer to insert App Blocks."
      componentId={component.id}
      componentAttributes={componentAttributes}
      isLiquidSupported={isFeatureSupported(elementType, "shopifyLiquid")}
      repeatedIndexPath={repeatedIndexPath}
    />
  );
};

export default ShopifyAppBlocks;

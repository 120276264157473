import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";
import fullScreenIconConfig from "../FullScreenIcon/config";
import muteIconConfig from "../MuteIcon/config";
import playIconConfig from "../PlayIcon/config";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _autoplay: {
      name: "Autoplay",
      type: "boolean",
      description: "If enabled, the video will autoplay on mute when loaded",
      defaultValue: false,
    },
    _loop: {
      name: "Autoloop",
      type: "boolean",
      defaultValue: false,
      description: "If enabled, the video will loop after it ends",
    },
    _preload: {
      name: "Preload",
      type: "boolean",
      defaultValue: true,
      description:
        "If enabled, the browser will give preference to loading this video content. Use this when it's likely that visitors will view the video.",
    },
    _defaultMuted: {
      name: "Default Muted",
      type: "boolean",
      defaultValue: true,
      description: "If enabled, the video will play on mute by default",
    },
    _autoFullscreen: {
      name: "Auto Full Screen",
      type: "boolean",
      defaultValue: false,
      description:
        "If enabled, playing the video will cause it to become full screen.",
    },
    _placeholderImage: {
      name: "Placeholder Image",
      type: "image",
      defaultValue: null,
      description:
        "If set, this image will show before the video starts playing",
    },
    _placeholderPlayIcon: {
      name: "Placeholder Play Icon",
      type: "image",
      defaultValue: null,
      description: "Element to use as the play icon in preview",
      allowsSettingObjectFit: false,
    },
  };
}

export const customProperties = {
  objectFit: "--rof",
  objectPosition: "--rop",
};

export const styleElements = {
  root: {
    defaultStyles: {},
  },
  inner: {
    defaultStyles: {
      // Note (Noah, 2024-05-11, USE-954): In certain contexts, Safari calculates
      // 100% as 100% of the containing flex container (which may not be the parent
      // element). This is here so that the height: 100% which we have on inner
      // wrapper is relative to this component, not this component's parent Container
      display: "flex",
      position: "relative",
      width: "100%",
      height: "100%",
      objectFit: "inherit",
      objectPosition: "inherit",
    },
  },
  player: {
    defaultStyles: {
      width: "100%",
      height: "100%",
      objectFit: `var(${customProperties.objectFit}, inherit)`,
      objectPosition: `var(${customProperties.objectPosition}, inherit)`,
    },
  },
  placeholderImage: {
    defaultStyles: {
      position: "absolute",
      inset: 0,
      // Non-inset necessary for safari
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    overrideStyles({ component }) {
      const placeholderImage = component.props._placeholderImage;
      // @ts-expect-error
      return { ...placeholderImage?.style };
    },
  },
  placeholderPlayIcon: {
    defaultStyles: {
      position: "absolute",
      inset: 0,
      // Non-inset necessary for safari
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    overrideStyles({ component }) {
      const placeholderPlayIcon = component.props._placeholderPlayIcon;
      // @ts-expect-error
      return { ...placeholderPlayIcon?.style };
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
  children: {
    playIcon: playIconConfig,
    muteIcon: muteIconConfig,
    fullScreenIcon: fullScreenIconConfig,
  },
} satisfies ComponentConfig;

export default config;

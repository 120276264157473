import * as React from "react";

import { createContext } from "../../utils/react";

export const DEFAULT_VARIANT = "primary" satisfies ButtonVariant;
export const DEFAULT_SIZE = "base" satisfies ButtonSize;
export const DEFAULT_FILL_STYLE = "fill" satisfies ButtonFillStyle;
export const DEFAULT_CORNER_STYLE = "rounded" satisfies ButtonCornerStyle;

export type ButtonGroupVariant = "spaced" | "segmented";

interface ButtonGroupContextValue {
  size: ButtonSize;
  buttonGroupVariant: ButtonGroupVariant;
  buttonVariant: ButtonVariant;
  isDisabled: boolean;
  isBusy: boolean;
  fillStyle: ButtonFillStyle;
  cornerStyle: ButtonCornerStyle;
}

const ButtonGroupContext = createContext<ButtonGroupContextValue | null>(
  "ButtonGroupContext",
  null,
);
export function useButtonGroupContext() {
  return React.useContext(ButtonGroupContext);
}
export const ButtonGroupProvider = ButtonGroupContext.Provider;

/**
 * Infer some props for buttons rendered as links. Useful to normalize behavior
 * in framework-specific link components.
 * @internal
 */
export function useButtonLinkProps<
  Props extends {
    rel?: string;
    target?: string;
    tabIndex?: number;
    isDisabled?: boolean;
    tooltipText?: string;
  },
>(props: Props) {
  const { isDisabled, tooltipText, target, ...passthroughProps } = props;

  let rel = props.rel;
  if (target === "_blank") {
    const noreferrer = "noreferrer";
    if (rel && !rel.includes(noreferrer)) {
      rel = `${rel} ${noreferrer}`;
    } else {
      rel = noreferrer;
    }
  }

  let tabIndex = props.tabIndex;
  if (isDisabled) {
    if (tooltipText) {
      // Disabled links with tooltips should still be focusable and tabbable
      // so that the tooltip content is accessible
      tabIndex = 0;
    } else {
      // Disabled links should be focusable but excluded from the tab order
      tabIndex = props.tabIndex ?? -1;
    }
  }

  const ariaDisabled = isDisabled || undefined;

  return {
    ...passthroughProps,
    rel,
    tabIndex,
    "aria-disabled": ariaDisabled,
  } satisfies ButtonLinkCommonProps;
}

export interface ButtonLinkCommonProps {
  target?: string;
  rel?: ("nofollow" | "noreferrer" | "noopener") | (string & {});
  tabIndex?: number;
  "aria-disabled"?: boolean;
}

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "danger"
  | "success"
  | "no-style";
export type ButtonFillStyle = "fill" | "outline";
export type ButtonSize = "sm" | "base" | "lg";
export type ButtonCornerStyle = "rounded" | "pill" | "sharp";

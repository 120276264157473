import * as React from "react";
import { unsafe_useLayoutEffectWithoutWarning as useLayoutEffect } from "replo-utils/react/use-layout-effect";

import {
  DataTablesContext,
  useRuntimeContext,
} from "../../shared/runtime-context";
import { preloadAllImagesFromDataTable } from "../../store/AlchemyDataTable";

export function usePreloadImagesFromDataTable(
  tableId: string | null | undefined,
  options?: { skipPreload?: boolean },
) {
  const { skipPreload = false } = options ?? {};
  const { mapping: dataTableMapping } = useRuntimeContext(DataTablesContext);
  // NOTE (Chance 2024-06-01): We only need to preload the images once, so we
  // track them in a set
  const preloadedImage = React.useRef(new Set<string>());
  useLayoutEffect(() => {
    if (!tableId || skipPreload) {
      return;
    }
    if (!preloadedImage.current.has(tableId)) {
      preloadedImage.current.add(tableId);
      preloadAllImagesFromDataTable(tableId, dataTableMapping);
    }
  }, [tableId, dataTableMapping, skipPreload]);
}

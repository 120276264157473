import { getHotKey } from "@editor/utils/getHotKey";
import classNames from "classnames";
import * as React from "react";
import type { Gradient } from "replo-runtime/shared/types";
import { gradientToCssGradient } from "replo-runtime/shared/utils/gradient";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

type SwatchValue =
  | { type: "color"; color?: string | null }
  | { type: "image"; src: string | null }
  | { type: "gradient"; gradient: Gradient | null }
  | { type: "video"; src: string | null };

type SwatchProps = {
  value?: SwatchValue | null;
  className?: string;
  style?: React.CSSProperties;
  bgSquareSize?: string;
  selected?: boolean;
  dataTestId?: string;
  onOptionClick?(e: React.MouseEvent<HTMLDivElement>): void;
  id?: string;
};

function _getSwatch(value: SwatchValue) {
  return exhaustiveSwitch(value)({
    color: (value) => (
      <div
        className="h-full w-full"
        style={{
          backgroundColor: value.color ?? undefined,
        }}
      />
    ),
    gradient: (value) => (
      <div
        className="h-full w-full"
        style={{
          background: value.gradient
            ? gradientToCssGradient({
                tilt: value.gradient.tilt,
                stops: value.gradient.stops,
              }) ?? undefined
            : undefined,
        }}
      />
    ),
    image: (value) =>
      value.src && (
        <img className="h-full w-full object-cover" src={value.src} />
      ),
    video: (value) => (
      <video
        className="h-full w-full object-cover object-center"
        src={value.src ?? undefined}
      />
    ),
  });
}

const ColorSwatch = React.forwardRef<HTMLDivElement, SwatchProps>(
  function ColorSwatch(
    {
      value,
      className,
      style,
      bgSquareSize = "20px",
      selected = false,
      onOptionClick,
      dataTestId,
      id,
      ...props
    },
    ref,
  ) {
    // If it's an image OR video, just show a grey background instead of a grid placeholder
    const backgroundStyle =
      value && !["image", "video"].includes(value?.type)
        ? {
            background: `repeating-conic-gradient(#E2E8F0 0% 25%, #F1F5F9 0% 50%) 50%/ ${bgSquareSize} ${bgSquareSize}`,
          }
        : {};

    const onInputMouseUp: React.MouseEventHandler<HTMLDivElement> = (e) => {
      if (onOptionClick) {
        const hotkey = getHotKey(e);
        if (hotkey === "altKey") {
          onOptionClick(e);
        }
      }
    };

    return (
      <div
        ref={ref}
        onMouseUp={onInputMouseUp}
        className={classNames("rounded-[2px] border-2 bg-subtle", {
          "border-slate-200": !selected,
          "border-blue-600": selected,
        })}
        id={id}
        data-testid={dataTestId}
        {...props}
      >
        <div
          className={classNames({ "h-5 w-[60px]": !className }, className)}
          style={{
            ...style,
            ...backgroundStyle,
          }}
        >
          {value && _getSwatch(value)}
        </div>
      </div>
    );
  },
);

export default ColorSwatch;

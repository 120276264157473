import * as React from "react";
import { createArrayOfLength, filterNulls } from "replo-utils/lib/array";

import { mapStoreProductToReploProduct } from "../../../shared/mappers/product";
import {
  RenderEnvironmentContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps, StoreProduct } from "../../../shared/types";
import { mergeContext } from "../../../shared/utils/context";
import { ReploComponent } from "../ReploComponent";

const ProductCollection = ({
  component,
  context,
  extraAttributes,
  componentAttributes,
}: RenderComponentProps) => {
  const { repeatedIndexPath } = context;
  const {
    productMetafieldValues,
    variantMetafieldValues,
    activeCurrency: currencyCode,
    activeLanguage: language,
    moneyFormat,
  } = useRuntimeContext(ShopifyStoreContext);
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);

  const products: StoreProduct[] = context?.attributes?._products;
  const componentChildren = component.children;
  const componentTemplate = componentChildren?.length && componentChildren[0];

  if (!componentTemplate || (!isEditorApp && !products?.length)) {
    return null;
  }

  const length = products?.length ?? 4;
  const slots = createArrayOfLength(length);

  return (
    <div {...componentAttributes}>
      <>
        {slots.map((index) => {
          const newContext = mergeContext(context, {
            attributes: {
              _products: null,
              _product: products[index] || null,
            },
            state: {
              products: filterNulls(products).map((product) =>
                mapStoreProductToReploProduct(product, {
                  // TODO (Noah, 2024-03-17): It's weird that we have to pass quantity: 1
                  // here. I'm not even really sure if we use this `state.products` anywhere,
                  // it seems like it would be a footgun since it doesn't account for quantity
                  // or selected variant. We should probably remove it
                  quantity: 1,
                  metafields: {
                    productMetafieldsMapping: productMetafieldValues,
                    variantMetafieldsMapping: variantMetafieldValues,
                  },
                  currencyCode,
                  moneyFormat,
                  language,
                }),
              ),
            },
          });

          return (
            <ReploComponent
              key={String(index)}
              component={componentTemplate}
              context={newContext}
              repeatedIndexPath={`${repeatedIndexPath}.${index}`}
              extraAttributes={extraAttributes}
            />
          );
        })}
      </>
    </div>
  );
};

export default ProductCollection;

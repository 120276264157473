import * as React from "react";

import { useShopifyMoneyFormat } from "../../../shared/hooks/use-shopify-money-format";
import {
  RenderEnvironmentContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { mergeContext } from "../../../shared/utils/context";
import { getPriceIncludingDiscounts } from "../../../shared/utils/temporaryCart";
import { getProduct } from "../../ReploProduct";
import { ReploComponent } from "../ReploComponent";

const TemporaryCartItems = (props: RenderComponentProps) => {
  const { formatCurrency } = useShopifyMoneyFormat();
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const {
    fakeProducts,
    products,
    productMetafieldValues,
    variantMetafieldValues,
    activeCurrency: currencyCode,
    activeLanguage: language,
    moneyFormat,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  const template =
    props.component.children?.length && props.component.children[0];
  if (!template) {
    return null;
  }

  const items = props.context.state?.temporaryCart?.items || [];

  const renderOptions = () => {
    return items?.map((item: any, index: number) => {
      const context = mergeContext(props.context, {
        attributes: {
          _currentTemporaryCartItem: {
            ...getProduct(item.productRef, props.context, {
              products,
              currencyCode,
              language,
              productMetafieldValues,
              variantMetafieldValues,
              isEditor: isEditorApp,
              fakeProducts,
              moneyFormat,
              templateProduct,
            }),
            discountedPrice: formatCurrency(
              getPriceIncludingDiscounts(
                props.context.state.temporaryCart?.priceRules,
                item,
                props.context.state?.temporaryCart?.items,
                props.context,
                {
                  products,
                  currencyCode,
                  moneyFormat,
                  language,
                  templateProduct,
                },
              ),
              { currencyCode },
            ),
            quantityInTemporaryCart: `${item.productRef?.quantity || 1}`,
          },
        },
      });

      return (
        <ReploComponent
          {...props}
          context={context}
          key={index}
          component={template}
          repeatedIndexPath={`${props.context.repeatedIndexPath}.${index}`}
        />
      );
    });
  };

  return (
    <div
      {...props.componentAttributes}
      style={{
        display: "flex",
        ...props.componentAttributes.style,
      }}
    >
      {renderOptions()}
    </div>
  );
};

export default TemporaryCartItems;

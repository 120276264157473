import type { ComponentTemplateType } from "replo-runtime/shared/types";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import type { ReploElementType } from "schemas/element";
import { z } from "zod";

export type ComponentTemplateCategoryType = "page" | "section" | "shared";

export type ComponentTemplateSelectionId =
  | ComponentTemplateCategoryId
  | SharedCategoryId;

export type ComponentTemplateCategorySelectionSlug =
  | CategorySlug
  | SharedCategorySlug;

type BaseComponentTemplateCategories = {
  name: string;
  title?: string;
  slug: ComponentTemplateCategorySelectionSlug;
  componentTemplateCategoryType: ComponentTemplateCategoryType;
  order: number;
};

export type ComponentTemplateSelectionCategories =
  BaseComponentTemplateCategories & {
    id: ComponentTemplateSelectionId;
  };

export type ComponentTemplateCategories = BaseComponentTemplateCategories & {
  id: ComponentTemplateCategoryId;
};

const categoryIds = [
  "3ad88fad-1019-49f4-b970-f3fcbb61f4fb",
  "6cc4c4ae-5c4b-469c-85d3-59385422e9da",
  "824b2b1b-85e7-48a1-b380-41fa448c9b83",
  "21f108b8-14c5-445c-adad-c631776c0b7f",
  "ad406848-df2c-4d15-8399-e03d6bb768f4",
  "83177fa6-ce90-4c33-bdf3-da00bff510dd",
  "535fc5cf-db07-46dd-b2d4-9a1f7657c196",
  "9f1231b8-7e7d-4758-9fd7-0233452a9ef9",
  "c5cd3ebe-9548-463f-8056-4e27f0214768",
  "e1a8154e-82cb-4014-ad9e-5770e8ac7c55",
  "1c283f55-d1a0-4173-804a-a622f1f6c2fe",
  "d9be7379-beba-4334-b7fc-7e7f06f474f3",
  "a5ff41d0-c798-40e3-ad7c-448679ddf021",
  "a8b32fa0-02f8-4c8c-8e40-5c8069baf720",
  "def7687f-1488-4b81-8679-8944a92d56be",
  "33fd6bd7-a5ad-4e26-a8ff-66505873174e",
  "8cf813d0-6460-11ee-b489-f2472ab43fa3",
  "885e9648-d0f1-46b3-9e97-c2a446bb43d2",
] as const;

const sharedCategoryIds = [
  "shared.page-templates",
  "shared.component-templates",
] as const;

export const categoryIdSchema = z.enum(categoryIds);
export type ComponentTemplateCategoryId =
  | "3ad88fad-1019-49f4-b970-f3fcbb61f4fb"
  | "6cc4c4ae-5c4b-469c-85d3-59385422e9da"
  | "824b2b1b-85e7-48a1-b380-41fa448c9b83"
  | "21f108b8-14c5-445c-adad-c631776c0b7f"
  | "ad406848-df2c-4d15-8399-e03d6bb768f4"
  | "83177fa6-ce90-4c33-bdf3-da00bff510dd"
  | "535fc5cf-db07-46dd-b2d4-9a1f7657c196"
  | "9f1231b8-7e7d-4758-9fd7-0233452a9ef9"
  | "c5cd3ebe-9548-463f-8056-4e27f0214768"
  | "e1a8154e-82cb-4014-ad9e-5770e8ac7c55"
  | "1c283f55-d1a0-4173-804a-a622f1f6c2fe"
  | "d9be7379-beba-4334-b7fc-7e7f06f474f3"
  | "a5ff41d0-c798-40e3-ad7c-448679ddf021"
  | "a8b32fa0-02f8-4c8c-8e40-5c8069baf720"
  | "def7687f-1488-4b81-8679-8944a92d56be"
  | "33fd6bd7-a5ad-4e26-a8ff-66505873174e"
  | "8cf813d0-6460-11ee-b489-f2472ab43fa3"
  | "885e9648-d0f1-46b3-9e97-c2a446bb43d2";

export const sharedCategoryIdSchema = z.enum(sharedCategoryIds);
export type SharedCategoryId = z.infer<typeof sharedCategoryIdSchema>;

export const componentTemplateSelectionIdSchema = z.union([
  categoryIdSchema,
  sharedCategoryIdSchema,
]);

const categorySlugs = [
  "heros",
  "banners",
  "sections",
  "products",
  "bundles",
  "footers",
  "faqs",
  "content",
  "testimonials",
  "logos",
  "landing-pages",
  "advertorial",
  "listicle",
  "video-sales-letter",
  "blog-posts",
  "product-drop",
  "product-page-templates",
  "about-us",
] as const;

const sharedCategorySlugs = ["shared-components", "shared-pages"] as const;

export const categorySlugSchema = z.enum(categorySlugs);
export type CategorySlug = z.infer<typeof categorySlugSchema>;

export const sharedCategorySlugSchema = z.enum(sharedCategorySlugs);
export type SharedCategorySlug = z.infer<typeof sharedCategorySlugSchema>;

export const componentTemplateCategorySelectionSlug = z.union([
  categorySlugSchema,
  sharedCategorySlugSchema,
]);

// Note (Noah, 2023-05-03): These are a all the known categories we have - eventually
// we plan on moving these to the database. The ids are arbitrary, all that matters
// is that this code and the database agree on them.
export const CATEGORIES_IDS = {
  /* Sections */
  heros: "3ad88fad-1019-49f4-b970-f3fcbb61f4fb",
  banners: "6cc4c4ae-5c4b-469c-85d3-59385422e9da",
  sections: "824b2b1b-85e7-48a1-b380-41fa448c9b83",
  products: "21f108b8-14c5-445c-adad-c631776c0b7f",
  bundles: "ad406848-df2c-4d15-8399-e03d6bb768f4",
  footers: "83177fa6-ce90-4c33-bdf3-da00bff510dd",
  faqs: "535fc5cf-db07-46dd-b2d4-9a1f7657c196",
  content: "9f1231b8-7e7d-4758-9fd7-0233452a9ef9",
  testimonials: "c5cd3ebe-9548-463f-8056-4e27f0214768",
  logos: "e1a8154e-82cb-4014-ad9e-5770e8ac7c55",
  /* Pages */
  landingPage: "1c283f55-d1a0-4173-804a-a622f1f6c2fe",
  blogPostPage: "d9be7379-beba-4334-b7fc-7e7f06f474f3",
  listiclePage: "a5ff41d0-c798-40e3-ad7c-448679ddf021",
  videoSalesLetterPage: "a8b32fa0-02f8-4c8c-8e40-5c8069baf720",
  advertorialPage: "def7687f-1488-4b81-8679-8944a92d56be",
  productDropPage: "33fd6bd7-a5ad-4e26-a8ff-66505873174e",
  productPageTemplates: "8cf813d0-6460-11ee-b489-f2472ab43fa3",
  aboutUs: "885e9648-d0f1-46b3-9e97-c2a446bb43d2",
} as const satisfies Record<string, ComponentTemplateCategoryId>;

export const SHARED_CATEGORIES_SELECTIONS = {
  sharedPages: "shared.page-templates",
  sharedComponents: "shared.component-templates",
} as const satisfies Record<string, SharedCategoryId>;

export const isSharedCategoryId = (
  categoryId: string,
): categoryId is SharedCategoryId => {
  return sharedCategoryIdSchema.safeParse(categoryId).success;
};

export const getCategorySlugFromId = (
  categoryId: ComponentTemplateSelectionId,
) => {
  return COMPONENT_TEMPLATE_CATEGORIES.find(
    (category) => category.id === categoryId,
  )?.slug as ComponentTemplateCategorySelectionSlug;
};

export const getCategoryIdFromSlug = (
  slug: ComponentTemplateCategorySelectionSlug,
) => {
  return COMPONENT_TEMPLATE_CATEGORIES.find(
    (category) => category.slug === slug,
  )?.id as ComponentTemplateSelectionId;
};

export const getCategoryNameFromSlug = (slug: CategorySlug) => {
  return COMPONENT_TEMPLATE_CATEGORIES.find(
    (category) => category.slug === slug,
  )?.name;
};

// NOTE (Fran, 2023-04-17): After adding a new category we should add an icon in
// useGetComponentTemplateCategories hook. Otherwise it will use a default icon.
export const COMPONENT_TEMPLATE_CATEGORIES: ComponentTemplateSelectionCategories[] =
  [
    {
      id: CATEGORIES_IDS.heros,
      name: "Heros",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "heros",
    },
    {
      id: CATEGORIES_IDS.banners,
      name: "Banners",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "banners",
    },
    {
      id: CATEGORIES_IDS.productPageTemplates,
      name: "Product Page Templates",
      // TODO (Juan, 2023-10-06): This needs to be componentTemplateCategoryType: "section" in the future (https://github.com/replohq/andytown/pull/5847#discussion_r1348959952)
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "product-page-templates",
    },
    {
      id: CATEGORIES_IDS.sections,
      name: "Sections",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "sections",
    },
    {
      id: CATEGORIES_IDS.aboutUs,
      name: "About Us",
      componentTemplateCategoryType: "section",
      order: 3,
      slug: "about-us",
    },
    {
      id: CATEGORIES_IDS.products,
      name: "Products",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "products",
    },
    {
      id: CATEGORIES_IDS.bundles,
      name: "Bundles",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "bundles",
    },
    {
      id: CATEGORIES_IDS.footers,
      name: "Footers",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "footers",
    },
    {
      id: CATEGORIES_IDS.faqs,
      name: "FAQs",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "faqs",
    },
    {
      id: CATEGORIES_IDS.content,
      name: "Content",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "content",
    },
    {
      id: CATEGORIES_IDS.testimonials,
      name: "Testimonials",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "testimonials",
    },
    {
      id: CATEGORIES_IDS.logos,
      name: "Logos",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "logos",
    },
    {
      id: CATEGORIES_IDS.landingPage,
      name: "Landing Pages",
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "landing-pages",
    },
    {
      id: CATEGORIES_IDS.advertorialPage,
      name: "Advertorial",
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "advertorial",
    },
    {
      id: CATEGORIES_IDS.listiclePage,
      name: "Listicle",
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "listicle",
    },
    {
      id: CATEGORIES_IDS.videoSalesLetterPage,
      name: "Video Sales Letter",
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "video-sales-letter",
    },
    {
      id: CATEGORIES_IDS.blogPostPage,
      name: "Blogs",
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "blog-posts",
    },
    {
      id: CATEGORIES_IDS.productDropPage,
      name: "Product Drop",
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "product-drop",
    },
    {
      id: CATEGORIES_IDS.aboutUs,
      name: "About Us",
      componentTemplateCategoryType: "page",
      order: 3,
      slug: "about-us",
    },
    {
      id: CATEGORIES_IDS.faqs,
      name: "FAQs",
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "faqs",
    },
    {
      id: SHARED_CATEGORIES_SELECTIONS.sharedComponents,
      name: "Shared Components",
      componentTemplateCategoryType: "shared",
      order: 2,
      slug: "shared-components",
    },
    {
      id: SHARED_CATEGORIES_SELECTIONS.sharedPages,
      name: "Shared Pages",
      componentTemplateCategoryType: "shared",
      order: 1,
      slug: "shared-pages",
    },
  ];

const getCategoriesByType = (type: ComponentTemplateCategoryType) => {
  return COMPONENT_TEMPLATE_CATEGORIES.filter(
    (category) => category.componentTemplateCategoryType === type,
  );
};

export const getCategorySlugFromElementType = (
  elementType: ReploElementType,
) => {
  exhaustiveSwitch({ type: elementType })({
    page: "all-pages",
    shopifyArticle: "blog-posts",
    shopifyProductTemplate: "product-page-templates",
    shopifySection: "all-sections",
  });
};

/** Begin Industries */
const componentTemplatesIndustriesIds = [
  "dbec89c6-0222-4115-a994-967c07514b5b",
  "58785c14-4db0-47b5-9fcf-bd48cbb92938",
  "ef386249-c49f-49b3-8fcc-350a7b8ab1e0",
  "a95bc923-28ac-41da-978f-46be5c024793",
  "d16c03d2-c5b8-40e9-8f8b-a59d2c5755a1",
  "26fd6a4e-525c-48b9-bfb4-718332f2a661",
  "5b5f7cf6-2aee-4ea3-85c6-ea73111aca9e",
  "2932fe19-bbbf-4219-8b8d-b181b670f5d8",
  "3f39766e-9c1b-44ae-b105-5d64d93f2cad",
  "68395a9c-e77b-460b-9806-9cb1ec7e0dc1",
  "9107a7ec-4a26-4ed3-9307-98fbad60fbb1",
  "56ec366e-8626-46c3-ba69-6343b651f534",
  "be3a36d9-113e-4188-99dc-97a4420409e5",
  "73cb5719-5bd0-47a0-a861-9fa66818debf",
  "b785dd5d-efb4-4fe0-85a6-c3b3b59a34db",
  "d4e28d8a-9c59-4744-9044-121162f9b247",
  "7c7825e2-bf9f-4cae-81c4-2692d5213603",
] as const;

export const componentTemplateIndustryIdSchema = z.enum(
  componentTemplatesIndustriesIds,
);

// list of all actual industry values as union, not inferred
export type ComponentTemplateIndustryId =
  | "dbec89c6-0222-4115-a994-967c07514b5b"
  | "58785c14-4db0-47b5-9fcf-bd48cbb92938"
  | "ef386249-c49f-49b3-8fcc-350a7b8ab1e0"
  | "a95bc923-28ac-41da-978f-46be5c024793"
  | "d16c03d2-c5b8-40e9-8f8b-a59d2c5755a1"
  | "26fd6a4e-525c-48b9-bfb4-718332f2a661"
  | "5b5f7cf6-2aee-4ea3-85c6-ea73111aca9e"
  | "2932fe19-bbbf-4219-8b8d-b181b670f5d8"
  | "3f39766e-9c1b-44ae-b105-5d64d93f2cad"
  | "68395a9c-e77b-460b-9806-9cb1ec7e0dc1"
  | "9107a7ec-4a26-4ed3-9307-98fbad60fbb1"
  | "56ec366e-8626-46c3-ba69-6343b651f534"
  | "be3a36d9-113e-4188-99dc-97a4420409e5"
  | "73cb5719-5bd0-47a0-a861-9fa66818debf"
  | "b785dd5d-efb4-4fe0-85a6-c3b3b59a34db"
  | "d4e28d8a-9c59-4744-9044-121162f9b247"
  | "7c7825e2-bf9f-4cae-81c4-2692d5213603";

type ComponentTemplateIndustry = {
  id: ComponentTemplateIndustryId;
  name: string;
};

export const componentTemplateIndustries: ComponentTemplateIndustry[] = [
  { id: "dbec89c6-0222-4115-a994-967c07514b5b", name: "Accessories" },
  { id: "58785c14-4db0-47b5-9fcf-bd48cbb92938", name: "Activities & Outdoor" },
  { id: "ef386249-c49f-49b3-8fcc-350a7b8ab1e0", name: "Baby & kids" },
  {
    id: "a95bc923-28ac-41da-978f-46be5c024793",
    name: "Beauty",
  },
  { id: "d16c03d2-c5b8-40e9-8f8b-a59d2c5755a1", name: "Bags & Luggage" },
  { id: "26fd6a4e-525c-48b9-bfb4-718332f2a661", name: "Clothing" },
  {
    id: "5b5f7cf6-2aee-4ea3-85c6-ea73111aca9e",
    name: "Drink",
  },
  { id: "2932fe19-bbbf-4219-8b8d-b181b670f5d8", name: "Food" },
  { id: "3f39766e-9c1b-44ae-b105-5d64d93f2cad", name: "Hair Care" },
  { id: "68395a9c-e77b-460b-9806-9cb1ec7e0dc1", name: "Health & Wellness" },
  { id: "9107a7ec-4a26-4ed3-9307-98fbad60fbb1", name: "Home" },
  { id: "56ec366e-8626-46c3-ba69-6343b651f534", name: "Personal Care" },
  { id: "be3a36d9-113e-4188-99dc-97a4420409e5", name: "Pets" },
  { id: "73cb5719-5bd0-47a0-a861-9fa66818debf", name: "Shoes" },
  { id: "b785dd5d-efb4-4fe0-85a6-c3b3b59a34db", name: "Skin Care" },
  { id: "d4e28d8a-9c59-4744-9044-121162f9b247", name: "Supplements" },
  { id: "7c7825e2-bf9f-4cae-81c4-2692d5213603", name: "Tech" },
];

export const getComponentTemplateIndustryName = (
  industryId: ComponentTemplateIndustryId,
) => {
  return componentTemplateIndustries.find(
    (industry) => industry.id === industryId,
  )?.name;
};
/** End Industries */

/** Begin Badges */
const componentTemplateBadgesIds = [
  "295e4057-1b55-46ea-89e9-b0b26bbc3bb7",
  "d4e28d8a-28ac-46ea-85c6-b181b670f5d8",
] as const;

export const componentTemplateBadgeIdSchema = z.enum(
  componentTemplateBadgesIds,
);
export type ComponentTemplateBadgeId = z.infer<
  typeof componentTemplateBadgeIdSchema
>;

type ComponentTemplateBadge = {
  id: ComponentTemplateBadgeId;
  name: string;
  primaryColor: string;
  secondaryColor: string;
};

export const componentTemplateBadges: ComponentTemplateBadge[] = [
  {
    id: "295e4057-1b55-46ea-89e9-b0b26bbc3bb7",
    name: "CRO Top Pick",
    primaryColor: "#2563eb", // blue-600
    secondaryColor: "#1e40af", // blue-800
  },
  {
    id: "d4e28d8a-28ac-46ea-85c6-b181b670f5d8",
    name: "Hottest Brands",
    primaryColor: "#2563eb", // blue-600
    secondaryColor: "#1e40af", // blue-800
  },
];

export const getComponentTemplateBadgeName = (
  badgeId: ComponentTemplateBadgeId,
) => {
  return componentTemplateBadges.find((badge) => badge.id === badgeId)?.name;
};

export const getComponentTemplateBadgeColor = (
  badgeId: ComponentTemplateBadgeId,
) => {
  const badge = componentTemplateBadges.find((badge) => badge.id === badgeId);
  return {
    primaryColor: badge?.primaryColor,
    secondaryColor: badge?.secondaryColor,
  };
};
/** End Badges */

export type FilterOptionId =
  | ComponentTemplateBadgeId
  | ComponentTemplateSelectionId
  | ComponentTemplateIndustryId;

type IndustryFilter = {
  label: string;
  options: ComponentTemplateIndustry[];
  type: "industry";
};

type BadgeFilter = {
  label: string;
  options: ComponentTemplateBadge[];
  type: "badge";
};

type CategoryFilter = {
  label: string;
  options: ComponentTemplateSelectionCategories[];
  type: "category";
};

export type Filter = IndustryFilter | BadgeFilter | CategoryFilter;

export type SelectedFilters = {
  category: ComponentTemplateSelectionId[];
  badge: ComponentTemplateBadgeId[];
  industry: ComponentTemplateIndustryId[];
};

export function getFiltersByType(
  type: ComponentTemplateCategoryType,
): Filter[] {
  return exhaustiveSwitch({ type })({
    page: () => [
      { label: "Our Picks", options: componentTemplateBadges, type: "badge" },
      {
        label: "By Category",
        options: getCategoriesByType("page"),
        type: "category",
      },
      {
        label: "By Industry",
        options: componentTemplateIndustries,
        type: "industry",
      },
    ],
    section: () => [
      { label: "Our Picks", options: componentTemplateBadges, type: "badge" },
      {
        label: "By Category",
        options: getCategoriesByType("section"),
        type: "category",
      },
    ],
    shared: () => [
      {
        label: "By Category",
        options: getCategoriesByType("shared"),
        type: "category",
      },
    ],
  });
}

type FindComponentTemplatesQuery = {
  projectId?: string;
  skip?: string;
  userId?: string;
  componentCollectionId?: string;
  searchText?: string;
  scope?: Omit<ComponentTemplateType, "left-bar">;
  includeComponent: boolean;
  selectedCategories?: ComponentTemplateSelectionId[];
  selectedBadges?: ComponentTemplateBadgeId[];
  selectedIndustries?: ComponentTemplateIndustryId[];
  templateType: ComponentTemplateCategoryType | null;
};

type FindComponentTemplatesPayload = {
  storeId?: string;
  userId?: string;
  componentCategoryType: ComponentTemplateType[];
  selectedCategories?: ComponentTemplateSelectionId[];
  selectedBadges?: ComponentTemplateBadgeId[];
  selectedIndustries?: ComponentTemplateIndustryId[];
  componentCollectionId?: string;
  searchText?: string;
  pageSize?: string;
  skip?: string;
};

export const getFindComponentTemplatesQuery = (
  payload: FindComponentTemplatesQuery,
): FindComponentTemplatesPayload => {
  const templateTypeMapping: Record<
    ComponentTemplateCategoryType,
    ComponentTemplateType[]
  > = {
    page: ["page"],
    section: ["section", "component"],
    shared: componentTemplateTypes,
  };

  const query: FindComponentTemplatesPayload = {
    // NOTE (Fran 2023-11-14): To avoid showing saved components templates in the marketplace
    // we should add the store id only for shared categories.
    storeId: undefined,
    componentCategoryType: payload.templateType
      ? templateTypeMapping[payload.templateType]
      : componentTemplateTypes,
    userId: undefined,
  };

  const selectedCategoriesContainsSharedCategories =
    payload.selectedCategories?.some((category) =>
      isSharedCategoryId(category),
    );
  if (selectedCategoriesContainsSharedCategories) {
    query.componentCollectionId = undefined;
    query.selectedCategories = undefined;
    query.userId = payload.userId;
    query.storeId = payload.projectId;

    if (
      payload.selectedCategories?.includes(
        SHARED_CATEGORIES_SELECTIONS.sharedPages,
      )
    ) {
      query.componentCategoryType = ["page"];
    } else {
      query.componentCategoryType = ["component", "section"];
    }
  }

  if (payload.searchText) {
    query.componentCollectionId = undefined;
    // NOTE (Fran 2023-11-14): We add the user id to find also the shared templates.
    query.userId = payload.userId;
  }

  if (payload.scope === "store") {
    query.storeId = payload.projectId;
  }

  return {
    ...payload,
    ...query,
  };
};

const componentTemplateTypes: ComponentTemplateType[] = [
  "page",
  "section",
  "component",
];

export const componentTemplateCategoryTypeToModalTitle: Record<
  ComponentTemplateCategoryType,
  string
> = {
  page: "Full Pages Templates",
  section: "Sections Templates",
  shared: "Shared Templates",
};

import { Button } from "@replo/design-system/components/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@replo/design-system/components/shadcn/core/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@replo/design-system/components/shadcn/core/popover";
import clsxMerge from "@replo/design-system/components/shadcn/utils/cn-merge";
import classNames from "classnames";
import * as React from "react";
import { BsSearch } from "react-icons/bs";
import { RiCloseFill } from "react-icons/ri";
import { useControllableState } from "replo-utils/react/use-controllable-state";

type Separator = {
  value: string;
  label: string;
  component?: () => React.ReactNode;
};

type ComboboxProps = {
  options: { value: string; label: string }[];
  placeholder?: string;
  placeholderPrefix?: string;
  startEnhancer?: () => React.ReactNode;
  endEnhancer?: () => React.ReactNode;
  className?: string;
  defaultValue?: string;
  separator?: Separator;
  open?: boolean;
  onChange?: (value: string) => void;
  onOpenChange?: (open: boolean) => void;
  onRemove?: () => void;
  onClick?: () => void;
  isDisabled?: boolean;
  isSearchable?: boolean;
  value?: string;
};

export function Combobox({
  options,
  placeholder,
  placeholderPrefix,
  startEnhancer,
  endEnhancer,
  className,
  defaultValue,
  separator,
  onChange: onControlledChange,
  value: controlledValue,
  open: controlledOpen,
  onOpenChange: onControlledOpenChange,
  onRemove,
  onClick,
  isDisabled,
  isSearchable,
}: ComboboxProps) {
  const [open, setOpen] = useControllableState(
    controlledOpen,
    false,
    onControlledOpenChange,
  );
  const [value, setValue] = useControllableState(
    controlledValue,
    defaultValue ?? "",
    onControlledChange,
  );

  const selectedLabel =
    options.find((option) => option.value === value)?.label || value;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="no-style"
          role="combobox"
          aria-expanded={open}
          className={clsxMerge(
            classNames(
              "rounded-[4px] py-[6px] px-[8px] h-[32px] border flex items-center justify-center gap-[8px]",
              {
                " bg-slate-100 text-slate-800": !isDisabled,
                "border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed":
                  isDisabled,
              },
            ),
            className,
          )}
          iconStart={
            startEnhancer ? (startEnhancer() as React.ReactElement) : undefined
          }
          iconEnd={
            endEnhancer ? (endEnhancer() as React.ReactElement) : undefined
          }
          innerStyle="flex items-center justify-center gap-[8px]"
          isDisabled={isDisabled}
          onClick={onClick}
        >
          <div className="flex font-medium text-xs leading-5 items-center justify-center">
            {placeholderPrefix}
            {selectedLabel || placeholder}
            {Boolean(onRemove) && (
              <div
                className="ml-1 h-3 w-3 cursor-pointer text-gray-400"
                onClick={() => onRemove?.()}
              >
                <RiCloseFill size={12} />
              </div>
            )}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          {/* NOTE (TODO?) (kurt, 2024-08-15) We should probably get rid of this and use our own <Input /> component,
          this will require a refactor of the <Command /> component and possibly the <Input /> component itself
          to support selected states. This can probably be done by having a controllable state set by this component
          and passed into the <Input /> component.*/}
          {isSearchable && (
            <CommandInput
              placeholder={`Search ${placeholder?.toLowerCase() ?? ""}`}
              startEnhancer={<BsSearch className="text-slate-400 h-3 w-3" />}
              className="text-xs"
            />
          )}
          <CommandList>
            <CommandEmpty>No results</CommandEmpty>
            <CommandGroup className={classNames({ "pb-0": separator })}>
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  onSelect={(currentValue) => {
                    setValue(currentValue);
                    setOpen(false);
                  }}
                  className={classNames(
                    "px-2 py-1 rounded-[4px] h-[32px] text-slate-800 font-normal cursor-pointer",
                    {
                      "hover:bg-slate-50": value !== option.value,
                      "bg-slate-100": value === option.value,
                    },
                  )}
                >
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
            {separator && (
              <CommandGroup>
                <div className="border-t border-gray-300" />
                <CommandItem
                  key={separator.value}
                  value={separator.value}
                  className={classNames(
                    "px-2 py-1 rounded-[4px] h-[32px] cursor-pointer",
                    {
                      "hover:bg-slate-50": value !== separator.value,
                      "bg-slate-100": value === separator.value,
                    },
                  )}
                >
                  {separator.component
                    ? separator.component()
                    : separator.label}
                </CommandItem>
              </CommandGroup>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

import * as React from "react";
import type { TooltipTriggerState } from "react-stately";
import type { FocusableElement } from "replo-utils/dom/dom-types";

import { createContext } from "../../utils/react";

export type TooltipPosition = "top" | "left" | "right" | "bottom";

/**
 * @internal
 * This is exported for other tooltip files. Not intended to be outside of the
 * tooltip module.
 */
export type TooltipContextValue<TriggerElement extends FocusableElement> = {
  allowPointerEvents: boolean | undefined;
  closeDelay: number;
  delay: number;
  isDisabled: boolean;
  offset: number;
  position: TooltipPosition;
  state: TooltipTriggerState;
  tooltipProps: React.DOMAttributes<HTMLDivElement>;
  triggerEvents: "focus" | "hover focus";
  triggerProps: React.DOMAttributes<TriggerElement>;
  triggerRef: React.RefObject<TriggerElement>;
};

/**
 * @internal
 * This is exported for other tooltip files. Not intended to be outside of the
 * tooltip module.
 */
export const TooltipContext =
  createContext<TooltipContextValue<FocusableElement> | null>(
    "TooltipContext",
    null,
  );

export function useTooltipContext<TriggerElement extends FocusableElement>() {
  const ctx = React.useContext(
    TooltipContext,
  ) as TooltipContextValue<TriggerElement>;
  if (!ctx) {
    throw new Error("useTooltipContext must be used within a TooltipProvider");
  }
  return ctx;
}

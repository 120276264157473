import { getComponentEditorData } from "@components/editor/componentTypes";
import { selectDraftComponentType } from "@editor/reducers/core-reducer";
import * as React from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";

const DraftComponentPaneHeader = () => {
  const draftComponentType = useSelector(selectDraftComponentType);
  const draftComponent = getComponentEditorData(draftComponentType ?? "");
  return (
    draftComponent && (
      <div className="flex flex-row items-center justify-between pt-1">
        <span className="text-xs font-bold text-default">
          {draftComponent.defaultName}
        </span>
        {draftComponent.documentationLink && (
          <a
            href={draftComponent.documentationLink}
            className="flex cursor-pointer items-center text-xs text-blue-600"
            target="_blank"
            rel="noreferrer"
          >
            Learn More <BsInfoCircleFill aria-hidden className="ml-1" />
          </a>
        )}
      </div>
    )
  );
};

export default DraftComponentPaneHeader;

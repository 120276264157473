import Modal from "@editor/components/common/designSystem/Modal";
import * as React from "react";

export const ElementEditorModalForm: React.FC<
  React.PropsWithChildren<{
    testId: string;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    onCloseModal: () => void;
  }>
> = ({ children, testId, onSubmit, onCloseModal }) => {
  return (
    <Modal
      isOpen
      style={{ width: 600 }}
      onRequestClose={onCloseModal}
      data-testid={testId ?? "element-modal"}
    >
      <form onSubmit={onSubmit}>{children}</form>
    </Modal>
  );
};

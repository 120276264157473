import * as React from "react";

import type { Component } from "../../../shared/Component";
import { usePreloadImagesFromDataTable } from "../../../shared/hooks/usePreloadImagesFromDataTable";
import {
  DataTablesContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { mergeContext } from "../../../shared/utils/context";
import { getRowObjectsFromDataTable } from "../../AlchemyDataTable";
import { ReploComponent } from "../ReploComponent";

const Carousel = (props: RenderComponentProps) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const { mapping: dataTablesMapping } = useRuntimeContext(DataTablesContext);
  const {
    products,
    activeCurrency: currencyCode,
    activeLanguage: language,
    moneyFormat,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);

  const itemsConfig = props.component.props.items;
  const items = getRowObjectsFromDataTable(itemsConfig?.id, dataTablesMapping, {
    products,
    currencyCode,
    moneyFormat,
    language,
    templateProduct,
  });

  usePreloadImagesFromDataTable(itemsConfig?.id);

  const newContext = mergeContext(props.context, {
    state: {
      [props.component.id]: {
        current: items[currentIndex],
      },
    },
    attributes: {
      _currentItem: items[currentIndex],
    },
    actionHooks: {
      moveToNextCarouselItem: () => {
        const nextIndex =
          currentIndex === items.length - 1 ? 0 : currentIndex + 1;
        setCurrentIndex(nextIndex);
      },
      moveToPreviousCarouselItem: () => {
        setCurrentIndex(
          currentIndex === 0 ? items.length - 1 : currentIndex - 1,
        );
      },
    },
  });

  return (
    <div {...props.componentAttributes}>
      {props.component.children?.map((child: Component): any => {
        return (
          <ReploComponent
            {...props}
            key={child.id}
            component={child}
            context={newContext}
            repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
          />
        );
      })}
    </div>
  );
};

export default Carousel;

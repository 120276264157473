import type { FeatherIconNames } from "feather-icons";
import feather from "feather-icons";
import startCase from "lodash-es/startCase";
import * as React from "react";
import {
  BsArrowDown,
  BsArrowDownCircleFill,
  BsArrowLeft,
  BsArrowLeftCircleFill,
  BsArrowRight,
  BsArrowRightCircleFill,
  BsArrowUp,
  BsArrowUpCircleFill,
  BsAsterisk,
  BsBoxArrowUpRight,
  BsCartFill,
  BsCheck,
  BsCheckCircle,
  BsCheckCircleFill,
  BsChevronDown,
  BsChevronLeft,
  BsChevronRight,
  BsChevronUp,
  BsDash,
  BsFullscreen,
  BsPauseFill,
  BsPersonCircle,
  BsPlayFill,
  BsPlus,
  BsStar,
  BsStarFill,
  BsStarHalf,
  BsVolumeMuteFill,
  BsVolumeUpFill,
  BsX,
} from "react-icons/bs";
import { filterNulls } from "replo-utils/lib/array";

type IconDefinition = {
  Component: any;
  displayName: string;
};

const icons = Object.keys(feather.icons).reduce((icons, icon) => {
  return {
    ...icons,
    [icon]: {
      Component: (props: {
        style?: React.CSSProperties;
        className?: string;
        "aria-label"?: string;
        role?: string;
      }) => {
        const featherIcon = feather.icons[icon as FeatherIconNames];
        if (!featherIcon) {
          return null;
        }
        /**
         * Note (Noah, 2022-10-11, REPL-4487): We add the style prop as well as the width/height/color props
         * because some themes can have svg styling CSS that would otherwise override these props
         */
        /**
         * TODO (Noah, 2022-10-11): Because of how we use props.styles here, we're not generating correct
         * media queries for overriding icon sizes (this has been the case since feather-icons got introduced).
         * When we move off of Radium, we should be able to generate the correct media queries for these icon
         * components
         */
        const styleSource = filterNulls([
          // Make sure to use the correct fill/stroke from the icon's attrs, since
          // some icons use fill and some use stroke
          ...["fill", "stroke"].map((property) => {
            if (featherIcon.attrs[property]) {
              return `${property}: ${featherIcon.attrs[property]}`;
            }
            return null;
          }),
          // Set the width/height if they exist as CSS styles as well as properties
          // on the svg element (since there could be other styles overriding width/height)
          ...(["width", "height"] as const).map((property) => {
            if (props.style?.[property]) {
              return `${property}: ${props.style![property]}`;
            }
            return null;
          }),
        ]).join(";");

        const iconProps: Partial<feather.FeatherAttributes> = {
          style: styleSource,
        };

        // Note (Chance, 2023-08-09): Passing a value that is null or undefined
        // to a boolean prop to `toSvg` will result in a "null" or "undefined"
        // string being passed to the prop, so we need to filter nullable props
        // explicitly.
        for (const prop of ["role", "aria-label"] as const) {
          if (props[prop] != null) {
            iconProps[prop] = props[prop];
          }
        }

        const svgSrc =
          feather.icons[icon as FeatherIconNames]?.toSvg(iconProps);

        if (!svgSrc) {
          return null;
        }

        return (
          <span
            style={{ display: "contents" }}
            dangerouslySetInnerHTML={{
              __html: svgSrc,
            }}
          />
        );
      },
      displayName: startCase(icon),
    },
  };
}, {});

export const iconDirectory: Record<string, IconDefinition> = {
  ...icons,
  pause: { Component: BsPauseFill, displayName: "Pause" },
  play: { Component: BsPlayFill, displayName: "Play" },
  mute: { Component: BsVolumeMuteFill, displayName: "Mute" },
  volumeUp: { Component: BsVolumeUpFill, displayName: "Volume Up" },
  fullScreen: { Component: BsFullscreen, displayName: "Full Screen" },
  close: { Component: BsX, displayName: "Close" },
  chevronUp: { Component: BsChevronUp, displayName: "Chevron Up" },
  chevronDown: { Component: BsChevronDown, displayName: "Chevron Down" },
  chevronLeft: { Component: BsChevronLeft, displayName: "Chevron Left" },
  chevronRight: { Component: BsChevronRight, displayName: "Chevron Right" },
  starOutline: { Component: BsStar, displayName: "Star (Outlined)" },
  starFilled: { Component: BsStarFill, displayName: "Star (Filled)" },
  starHalf: { Component: BsStarHalf, displayName: "Star (Half-Filled)" },
  check: { Component: BsCheck, displayName: "Check" },
  checkCircle: { Component: BsCheckCircle, displayName: "Check Circle" },
  checkCircleFill: {
    Component: BsCheckCircleFill,
    displayName: "Check Circle Fill",
  },
  share: { Component: BsBoxArrowUpRight, displayName: "Share" },
  plus: { Component: BsPlus, displayName: "Plus" },
  minus: { Component: BsDash, displayName: "Minus" },
  asterisk: {
    Component: BsAsterisk,
    displayName: "Asterisk",
  },
  arrowBottomCircle: {
    Component: BsArrowDownCircleFill,
    displayName: "Arrow Bottom Circle Fill",
  },
  arrowTopCircle: {
    Component: BsArrowUpCircleFill,
    displayName: "Arrow Top Circle Fill",
  },
  arrowLeftCircle: {
    Component: BsArrowLeftCircleFill,
    displayName: "Arrow Left Circle Fill",
  },
  arrowRightCircle: {
    Component: BsArrowRightCircleFill,
    displayName: "Arrow Right Circle Fill",
  },
  arrowBottom: {
    Component: BsArrowDown,
    displayName: "Arrow Bottom",
  },
  arrowTop: { Component: BsArrowUp, displayName: "Arrow Top" },
  arrowLeft: {
    Component: BsArrowLeft,
    displayName: "Arrow Left",
  },
  arrowRight: {
    Component: BsArrowRight,
    displayName: "Arrow Right",
  },
  cart: {
    Component: BsCartFill,
    displayName: "Cart",
  },
  userCircle: {
    Component: BsPersonCircle,
    displayName: "User",
  },
};

export const allIconKeys = Object.keys(iconDirectory);

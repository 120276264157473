import { useEditorDispatch, useEditorSelector } from "@editor/store";
import * as React from "react";

import { handleTransformWillChange } from "./canvas-actions";
import {
  selectCanvasInteractionMode,
  setCanvasInteractionMode,
} from "./canvas-reducer";
import { useSetDeltaXY } from "./useSetDeltaXY";

export function useDragToPanCanvasMouseHandlers() {
  const dispatch = useEditorDispatch();
  const setDeltaXY = useSetDeltaXY();
  const canvasInteractionMode = useEditorSelector(selectCanvasInteractionMode);
  const isGrabbing = canvasInteractionMode === "grabbing";

  React.useEffect(() => {
    if (!isGrabbing) {
      return;
    }

    function handleMouseMove(event: MouseEvent) {
      dispatch(handleTransformWillChange());
      setDeltaXY(({ deltaX, deltaY }) => {
        const newDeltaY = deltaY + event.movementY;
        const newDeltaX = deltaX + event.movementX;
        event.preventDefault();
        return { deltaX: newDeltaX, deltaY: newDeltaY };
      });
    }

    function handleMouseUp() {
      dispatch(
        setCanvasInteractionMode(
          // Note (Noah, 2024-07-15): If they're holding space and grabbing the canvas to
          // pan it, then when they mouse up we want to go back to ready-to-grab mode so
          // that when they mouse down the next time, they'll be able to grab again
          canvasInteractionMode === "grabbing" ? "readyToGrab" : "edit",
        ),
      );
    }

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dispatch, setDeltaXY, isGrabbing, canvasInteractionMode]);
}

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import { useGetCurrentFlow } from "@components/flows/hooks/useGetCurrentFlow";
import { useSearchParams } from "react-router-dom";
import type { FlowStepDataValueOf, FlowStepType } from "schemas/flow";

function useGetCurrentStepResultsData<T extends FlowStepType>() {
  const [searchParams] = useSearchParams();
  const isDebug = Boolean(searchParams.get("debug"));
  const { currentStep } = useReploFlowsStepContext();
  const { currentInstance } = useGetCurrentFlow({ isDebug });

  const stepResults = currentStep?.id
    ? currentInstance?.stepResults?.[currentStep.id]
    : null;

  return (stepResults?.data as FlowStepDataValueOf<T>) ?? null;
}

export default useGetCurrentStepResultsData;

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";
import { forEachComponentAndDescendants } from "replo-runtime/shared/utils/component";

export const appBlocks: ComponentTemplate = {
  id: "0aeb9ca3-d6f1-4c91-b012-8266a1ebf982",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Shopify/App Blocks",
  leftBarCategory: { main: "pageIntegrations", subCategory: "shopify" },
  preview: "integrations/shopify-app-blocks.svg",
  template: {
    id: "4eeef748-81b9-401c-8e62-83580fd20f91",
    type: "shopifyAppBlocks",
    props: {
      style: {},
    },
  },
  canBeAddedAsChild: ({ element }) => {
    let canBeAdded = true;
    forEachComponentAndDescendants(element.component, (component) => {
      if (component.type == "shopifyAppBlocks") {
        canBeAdded = false;
        return "stop";
      }
      return "continue";
    });
    if (!canBeAdded) {
      return {
        message:
          "Shopify App Blocks can only be used once in a Shopify Section.",
        canBeAdded: false,
      };
    }

    return {
      canBeAdded: true,
    };
  },
};

import Spinner from "@common/designSystem/Spinner";
import { successToast } from "@common/designSystem/Toast";
import { PreviewLinkAndSVGSkeleton } from "@components/editor/SkeletonLoaders";
import IconButton from "@editor/components/common/designSystem/IconButton";
import { PublishPageButton } from "@editor/components/header/PublishPageButton";
import usePreviewBeforePublishInfo from "@editor/hooks/usePreviewBeforePublishInfo";
import { useLogAnalytics } from "@hooks/useLogAnalytics";
import copy from "copy-to-clipboard";
import * as React from "react";
import { BiCopy } from "react-icons/bi";
import { QRCode } from "react-qrcode-logo";

type PublishPreviewProps = {
  isPreviewCreateLoading: boolean;
  isPreviewCreateSuccessful: boolean;
};

const PublishPreview: React.FC<PublishPreviewProps> = (props) => {
  const { isPreviewCreateLoading, isPreviewCreateSuccessful } = props;
  const logEvent = useLogAnalytics();
  const { url: previewUrl, previewUrlAvailable } =
    usePreviewBeforePublishInfo();

  const CopyIcon = () => {
    return (
      <div className="-mt-0.5">
        <IconButton
          tooltipText="Copy Preview Link"
          aria-label="Copy Preview Link"
          type="tertiary"
          icon={<BiCopy size={16} color="royalblue" />}
          onClick={() => {
            copy(previewUrl);
            successToast(
              "Preview URL copied",
              "You can now open the Preview in a new window.",
            );
            logEvent("preview.copied", {});
          }}
        />
      </div>
    );
  };

  return (
    <div className="grid justify-items-center">
      <div className="h-auto m-0 self-start pt-2">
        {!isPreviewCreateLoading &&
        isPreviewCreateSuccessful &&
        previewUrlAvailable ? (
          <QRCode
            value={previewUrl}
            qrStyle="dots"
            bgColor="black"
            fgColor="white"
            logoImage="/replo-logo-quartercircle-black-bg.svg"
            logoWidth={50}
            removeQrCodeBehindLogo={true}
            quietZone={5}
          />
        ) : (
          <PreviewLinkAndSVGSkeleton />
        )}
      </div>
      <div className="flex flex-row space-x-0.5 pl-2 pt-3 pb-1 h-10 content-center text-blue-600 text-sm">
        {!isPreviewCreateLoading &&
        isPreviewCreateSuccessful &&
        previewUrlAvailable ? (
          <>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={previewUrl}
              className="cursor-pointer"
            >
              View Preview in Browser
            </a>
            <CopyIcon />
          </>
        ) : (
          <>
            <Spinner className="mr-1 mt-0.5" type="primary" />
            <p>Loading Preview...</p>
          </>
        )}
      </div>
      <hr className="w-full h-1 mx-auto pb-3" />
      <div className="w-56">
        <PublishPageButton isPreviewBeforePublishView={false} />
      </div>
    </div>
  );
};

export default PublishPreview;

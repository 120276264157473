import type {
  AddProductVariantToCartEditorPropsValue,
  ContextRef,
  LinkData,
  ProductRef,
} from "replo-runtime/shared/types";
import type { SelectedSellingPlanIdOrOneTimePurchase } from "replo-runtime/store/utils/product";

export enum Env {
  DEV = "dev",
  STAGING = "staging",
  PRODUCTION = "production",
}

export type ReploCustomEvent = "ActionStarted" | "ActionEnded";

export const ReploCustomEvents: Record<ReploCustomEvent, string> = {
  ActionStarted: "actionStarted",
  ActionEnded: "actionEnded",
};

export enum ConditionFieldType {
  PIXEL = "pixel",
  EVENT = "event",
  PAGE = "page",
  DATA_TABLE_ROW = "dataTableRow",
  HASHMARK = "hashmark",
  INTEGER = "integer",
  BOOLEAN = "boolean",
  PRODUCT_VARIANT = "productVariant",
  TEMPLATE_PRODUCT = "templateProduct",
  PRODUCT = "product",
}

export enum ConditionFieldEditorValue {
  NONE = "none",
  INPUT = "input",
  INPUT_WITH_STEPS = "inputWithSteps",
  DATA_TABLE_ROW = "dataTableRow",
  PRODUCT_VARIANT = "productVariant",
  TEMPLATE_PRODUCT = "templateProduct",
  PRODUCT = "product",
}

export const CustomComponentPropTypes = [
  // Intrinsic types
  "boolean",
  "string",
  "multiline",
  "float",
  "integer",
  "date",
  "timeInterval",
  "image",
  "seconds",
  "color",
  "pixels",
  "pixelsIncludingNegativeValues",
  "percentage",

  /**
   * A single Shopify product.
   */
  "product",

  /**
   * A single variant of a Shopify product.
   */
  "product_variant",

  /**
   * A list of Shopify products (potentially specifiable by hardcoded products, or
   * a collection, etc)
   */
  "products",

  /**
   * A list of product variants.
   */
  "productVariants",

  /**
   * A subscription selling plan of a Shopify product.
   */
  "productSellingPlan",

  /**
   * A hashmark (e.g. #product) that represents a component on the page which can be
   * linked to or scrolled to. Different from string, since we need to render the
   * hashmark, potentially only allow users to select hashmarks which exist, etc.
   */
  "hashmark",

  /**
   * A Replo component. Used for components which have "render props", e.g. the video
   * player which has a specific component always defined for its controls
   */
  "component",

  /**
   * A list of items. Could be dynamic, e.g. product images, or static as a list. Used
   * for things like tabs, carousels, etc
   */
  "inlineItems",

  /**
   * A list of key/value pairs to be rendered as htmlAttributes on an ReploComponent.
   * Currently only used on specific elements to add dataset attributes, but could be
   * expanded later to include other html attributes.
   */
  "htmlAttributes",

  /**
   * A list of items which can only be specified as a dynamic reference to something else
   * (e.g. product images of the current product)
   */
  "dynamicItems",

  /**
   * A single product option (e.g. Size, Color, etc).
   */
  "productOptions",

  /**
   * Either a product, or if there's no product selected the value will be taken from the
   * current product component's context
   */
  "productFromPropsOrFromContext",

  /**
   * Source code types (these render a code editor)
   */
  "liquidCodeEditor",
  "htmlCodeEditor",
  "cssCodeEditor",

  /**
   * A star rating. Used for reviews, etc
   */
  "ratingBalancer",

  /**
   * A list of Shopify theme sections.
   */
  "shopifyThemeSections",

  /**
   * One option out of a given list.
   */
  "selectable",

  /**
   * A list of swatches. Note (Noah, 2022-11-13): This is currently used only in
   * option/variant lists, it's a bit weird because you don't actually _select_ a
   * swatch here, you can just edit swatches using this custom prop modifier. Swatch
   * values are available in dynamic data.
   */
  "swatches",

  /**
   * An arbitrary dynamic value. Used for things like carousels where the user can select
   * a value to auto-scroll to when it changes.
   */
  "anyDynamicValue",

  /* Unused */
  "collection",
  "location",
] as const;
export type CustomComponentPropType = (typeof CustomComponentPropTypes)[number];

export const Operators = [
  "all",
  "gt",
  "gte",
  "lt",
  "lte",
  "eq",
  "neq",
  "includes",
  "excludes",
] as const;

export type Operator = (typeof Operators)[number];

export const AlchemyActionTypes = [
  "activateTabId",
  "addProductVariantToCart",
  "addTemporaryCartProductsToCart",
  "addVariantToTemporaryCart",
  "applyDiscountCode",
  "carouselNext",
  "carouselPrevious",
  "clearCart",
  "close", // Close modal
  "closeModalComponent",
  "createUserTag",
  "decreaseProductQuantity",
  "decreaseVariantCountInTemporaryCart",
  "executeJavascript",
  "goToItem",
  "goToNextItem",
  "goToPrevItem",
  "increaseProductQuantity",
  "multipleProductVariantsAddToCart",
  "openModal",
  "openKlaviyoModal",
  "phoneNumber",
  "redirect",
  "redirectToProductPage",
  "removeVariantFromTemporaryCart",
  "scrollContainerLeft",
  "scrollContainerRight",
  "scrollToNextCarouselItem",
  "scrollToPreviousCarouselItem",
  "scrollToSpecificCarouselItem",
  "scrollToUrlHashmark",
  "setActiveAlchemyVariant",
  "setActiveOptionValue",
  "setActiveSellingPlan",
  "setActiveTabIndex",
  "setActiveVariant",
  "setCurrentCollectionSelection",
  "setDropdownItem",
  "setProductQuantity",
  "toggleCollapsible",
  "toggleDropdown",
  "toggleFullScreen",
  "toggleMute",
  "togglePlay",
  "updateCart",
  "updateCurrentProduct",
  "setSelectedListItem",
] as const;

export type AlchemyActionType = (typeof AlchemyActionTypes)[number];

/**
 * Types of values that Replo Actions can have. These are separate from the action
 * types themselves, because two different types of actions might have the same value
 * type (for example, decreaseProductQuantity and increaseProductQuantity both might have
 * the integer value type).
 *
 * Note: depending on the action type, almost all of these values should be settable to
 * a dynamic value from the context.
 */
type AlchemyActionValueTypeConfig =
  /** No value at all! */
  | { type: "none"; value: never }
  /** A url, including whether to open  */
  | { type: "url"; value: LinkData | string }
  /** Any string */
  | { type: "string"; value: string }
  /** Any string, hint to render a multi-line text area because the value will usually be long */
  | { type: "multilineString"; value: string }
  /** Any product */
  | { type: "product"; value: ProductRef }
  /** Any product with redirection */
  | {
      type: "productRedirect";
      value: { product: ProductRef | null; openInNewTab: boolean };
    }
  /** Any product and optionally also a variant of that product */
  | {
      type: "productWithOptionalVariant";
      value: ProductRef | ContextRef;
    }
  /** A product variant, including data about how and when it should be added to cart */
  | {
      type: "productVariant";
      value: AddProductVariantToCartEditorPropsValue;
    }
  /** A specific row from a data table */
  | { type: "dataTableRow"; value: number }
  /** A number of pixels */
  | { type: "pixels"; value: { pixels: number } }
  /** A Replo modal component (e.g. for "open modal" actions) */
  | { type: "modalComponent"; value: { componentId: string | null } }
  /** A Replo Klaviyo component (e.g. for "open klaviyo modal" actions) */
  | { type: "klaviyoComponent"; value: { componentId: string | null } }
  /** A hashmark on the current page, including optional data about how it should be scrolled to */
  | {
      type: "offsetHashmark";
      value: {
        hashmark?: string;
        offset?: number;
        smoothScroll?: boolean;
      };
    }
  /** Any integer */
  | { type: "integer"; value: number }
  /** Any Shopify discount code */
  | { type: "discountCode"; value: string }
  /** Any Replo State on the current page (for example, for the activate state action) */
  | {
      type: "alchemyVariant";
      value: {
        variantId?: string;
        componentId?: string;
        type?: AlchemyActionType;
      };
    }
  /** An inline javascript syntax value */
  | { type: "jsCodeEditor"; value: string }
  /** Quantity of a product (i.e., a 1+ integer) */
  | {
      type: "productQuantity";
      value: number;
    }
  /** A zero-indexed index representing a tab inside the current tabs block */
  | { type: "tabIndex"; value: { index: number } }
  | {
      type: "sellingPlan";
      value: { sellingPlanId: SelectedSellingPlanIdOrOneTimePurchase };
    };
export type AlchemyActionValueType = AlchemyActionValueTypeConfig["type"];
export type ActionValueTypeValueOf<TargetType extends AlchemyActionValueType> =
  Extract<AlchemyActionValueTypeConfig, { type: TargetType }>["value"];

export enum AlchemyActionTrigger {
  OnClick = "onClick",
  OnHover = "onHover",
}
export const AlchemyActionTriggers: AlchemyActionTrigger[] = Object.values(
  AlchemyActionTrigger,
) as AlchemyActionTrigger[];
export const AlchemyAnimationTypes = [
  "fadeIn",
  "fadeOut",
  "slideIn",
  "slideOut",
  "flipIn",
  "flipOut",
  "growIn",
  "growOut",
  "growBigIn",
  "growBigOut",
  "shrinkIn",
  "shrinkOut",
  "shrinkBigIn",
  "shrinkBigOut",
  "spinIn",
  "spinOut",
  "flyIn",
  "flyOut",
  "dropIn",
  "dropOut",
] as const;

export type AlchemyAnimationType = (typeof AlchemyAnimationTypes)[number];

export const AlchemyAnimationTriggers = ["onViewportEnter"] as const;

export type AlchemyAnimationTriggerType =
  (typeof AlchemyAnimationTriggers)[number];

import { elementTypeToEditorData } from "@editor/components/editor/element";
import { useSpecificStoreProducts } from "@editor/hooks/useStoreProducts";
import {
  selectDraftElementId,
  selectDraftElementIsPublished,
  selectDraftElementShopifyBlogId,
  selectDraftElementShopifyPagePath,
  selectDraftElementType,
  selectProjectId,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorProduct } from "@editor/reducers/template-reducer";
import { useEditorSelector } from "@editor/store";
import { getPageUrl } from "@editor/utils/element";
import { trpc } from "@editor/utils/trpc";
import useGetStoreNameAndUrl from "@hooks/useGetStoreNameAndUrl";
import { skipToken } from "@tanstack/react-query";
import * as React from "react";
import type { StoreProduct } from "replo-runtime/shared/types";
import { mapNull } from "replo-runtime/shared/utils/optional";
import { fakeProducts } from "replo-runtime/store/utils/fakeProducts";
import { exhaustiveSwitch, isNullish } from "replo-utils/lib/misc";
import type { ReploElementType } from "schemas/element";

function getTooltipLabel(opts: {
  elementType: ReploElementType;
  urlIsDisabled: boolean;
  isProductTemplateWithFakeProduct: boolean;
}) {
  const { elementType, urlIsDisabled, isProductTemplateWithFakeProduct } = opts;

  const singularDisplayName =
    elementTypeToEditorData[elementType].singularDisplayName;

  if (isProductTemplateWithFakeProduct) {
    return "Select a Non-Placeholder Product to View Live Template";
  }

  if (urlIsDisabled) {
    return `Publish to View Live ${singularDisplayName}`;
  }

  if (elementType === "shopifySection") {
    return "View in Theme Editor";
  }

  return `View Live ${singularDisplayName}`;
}

const getElementUrl = (
  {
    id,
    type,
    shopifyPagePath,
  }: {
    id: string | undefined;
    type: ReploElementType;
    shopifyPagePath: string | undefined;
  },
  storeUrl: string | undefined,
  product: StoreProduct | null | undefined,
  blogData: { handle: string } | undefined,
  isLoading = false,
  isLoadingProducts = false,
) => {
  return exhaustiveSwitch({ type })({
    page: () => getPageUrl({ storeUrl, shopifyPagePath }),
    shopifyArticle: () => {
      if (blogData?.handle && shopifyPagePath && !isLoading) {
        return `https://${storeUrl}/blogs/${blogData.handle}/${shopifyPagePath}`;
      }
      return undefined;
    },
    shopifySection: () => `https://${storeUrl}/admin/themes/current/editor`,
    shopifyProductTemplate: () => {
      if (product && !isLoadingProducts) {
        return `https://${storeUrl}/products/${product.handle}?view=replo.${id}`;
      }
      return undefined;
    },
  });
};

export default function usePublishedInfo() {
  const draftElementType = useEditorSelector(selectDraftElementType);
  const draftElementIsPublished = useEditorSelector(
    selectDraftElementIsPublished,
  );
  const draftElementShopifyPagePath = useEditorSelector(
    selectDraftElementShopifyPagePath,
  );
  const draftElementBlogId = useEditorSelector(selectDraftElementShopifyBlogId);
  const projectId = useEditorSelector(selectProjectId);

  const { data: blogData, isLoading } = trpc.shopify.getBlogById.useQuery(
    isNullish(projectId) || isNullish(draftElementBlogId)
      ? skipToken
      : {
          projectId: projectId!,
          blogId: draftElementBlogId!,
        },
  );

  const { storeUrl } = useGetStoreNameAndUrl();
  const templateEditorProduct = useEditorSelector(selectTemplateEditorProduct);
  const draftElementId = useEditorSelector(selectDraftElementId);

  const productIds = React.useMemo(() => {
    const ids = [];
    if (templateEditorProduct?.productId) {
      ids.push(Number(templateEditorProduct.productId));
    }
    return ids;
  }, [templateEditorProduct?.productId]);

  const { products, isLoading: isLoadingProducts } =
    useSpecificStoreProducts(productIds);

  const product = products
    ? products.find(
        (product) =>
          String(product.id) ===
          mapNull(templateEditorProduct?.productId, (value) => String(value)),
      )
    : null;

  const path = exhaustiveSwitch({ type: draftElementType })({
    page: () =>
      `${elementTypeToEditorData[draftElementType].path}${draftElementShopifyPagePath}`,
    shopifyArticle: () => {
      if (!isLoading) {
        return `${elementTypeToEditorData[draftElementType].path}${blogData?.handle}/${draftElementShopifyPagePath}`;
      }
      return null;
    },
    shopifySection: () => null,
    shopifyProductTemplate: () => null,
  });

  const url = getElementUrl(
    {
      type: draftElementType,
      id: draftElementId,
      shopifyPagePath: draftElementShopifyPagePath,
    },
    storeUrl,
    product,
    blogData,
    isLoading,
    isLoadingProducts,
  );

  const isProductTemplateWithFakeProduct =
    draftElementType === "shopifyProductTemplate" &&
    product &&
    fakeProducts.some((p) => String(p.id) === String(product.id));
  const urlIsDisabled =
    !Boolean(url) ||
    !draftElementIsPublished ||
    isProductTemplateWithFakeProduct;
  const tooltipLabel = getTooltipLabel({
    elementType: draftElementType ?? "page",
    urlIsDisabled: urlIsDisabled ?? false,
    isProductTemplateWithFakeProduct: isProductTemplateWithFakeProduct ?? false,
  });

  return {
    url: draftElementIsPublished ? url : undefined,
    path,
    urlIsDisabled: urlIsDisabled ?? !draftElementIsPublished,
    tooltipLabel,
  };
}

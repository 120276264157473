import * as React from "react";
import { parseInteger } from "replo-utils/lib/math";

import {
  RuntimeHooksContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import type { RenderComponentProps } from "../../../shared/types";
import { mergeContext } from "../../../shared/utils/context";
import useSharedState from "../../hooks/useSharedState";
import { ReploComponent } from "../ReploComponent";
import {
  CompareSlider,
  CompareSliderHandle,
} from "./compare-slider/compare-slider";
import type { Orientation } from "./compare-slider/compare-slider-exports";

const BeforeAfterSlider: React.FC<RenderComponentProps> = (props) => {
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const initialPosition = props.component.props._startingPosition
    ? parseInteger(props.component.props._startingPosition as number)
    : 50;
  const [position, setPosition] = React.useState(initialPosition);

  // NOTE (Sebas, 2024-05-31): We cannot use the shared state for the position because it will cause
  // the slider to move laggy when the user is dragging the slider like it was debounced, but we need
  // the shared state to move the slider when the user changes the selected component in the editor.
  const [sharedPosition, setSharedPosition] = useSharedState(
    [props.component.id, "position"],
    initialPosition,
  );

  const onPositionChange = React.useCallback(
    (position: number) => {
      if (isEditorCanvas) {
        setSharedPosition(position);
      } else {
        setPosition(position);
      }
    },
    [isEditorCanvas, setSharedPosition],
  );

  React.useEffect(() => {
    // NOTE (Chance 2024-06-06): When the user exits preview mode, reset the
    // position to the initial position.
    if (!isEditorCanvas) {
      setPosition(initialPosition);
    }
  }, [isEditorCanvas, initialPosition]);

  const sliderComponent = props.component.props._sliderComponent;
  const beforeComponent = props.component.props._beforeComponent;
  const afterComponent = props.component.props._afterComponent;

  if (!sliderComponent || !afterComponent || !beforeComponent) {
    return null;
  }

  return (
    <div {...props.componentAttributes}>
      <CompareSlider
        position={isEditorCanvas ? sharedPosition : position}
        onPositionChange={onPositionChange}
        orientation={props.component.props._orientation as Orientation}
        changePositionOnHover={
          props.component.props._changePositionOnHover as boolean
        }
        style={{
          width: "100%",
          height: "100%",
        }}
        dragTarget={
          props.component.props._dragFromHandleOnly ?? true ? "handle" : "root"
        }
        isDisabled={isEditorCanvas}
        handle={
          <CompareSliderHandle
            style={{
              border: 0,
              padding: 0,
              appearance: "none",
              outline: 0,
              userSelect: "none",
              boxShadow: "none",
              background: "transparent",
            }}
          >
            {({ isDragging }) => {
              const newContext = mergeContext(props.context, {
                state: {
                  beforeAfterSlider: {
                    isDragging,
                  },
                },
              });
              return (
                <ReploComponent
                  component={sliderComponent}
                  context={newContext}
                  repeatedIndexPath={newContext.repeatedIndexPath ?? ".0"}
                />
              );
            }}
          </CompareSliderHandle>
        }
        beforeElement={
          <ReploComponent
            component={beforeComponent}
            context={props.context}
            repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
          />
        }
        afterElement={
          <ReploComponent
            component={afterComponent}
            context={props.context}
            repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
          />
        }
      />
    </div>
  );
};

export default BeforeAfterSlider;

import Separator from "@editor/components/common/designSystem/Separator";
import Header from "@editor/components/dashboard/Header";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import * as React from "react";

import AllMetricsTable from "@/features/analytics/AllMetricsTable";
import { SetupPage } from "@/features/analytics/Setup";

const Analytics: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();
  const { data, isLoading } = trpc.analytics.isEnabled.useQuery(
    workspaceId ? { workspaceId } : reactQuerySkipToken,
  );
  const isEnabled = data ?? false;
  const { mutate: enable, isPending: isEnabling } =
    trpc.analytics.enable.useMutation({
      onSuccess: () => {
        if (workspaceId) {
          void trpcUtils.analytics.isEnabled.invalidate({ workspaceId });
        }
      },
    });
  const handleEnable = () => void enable({ workspaceId: workspaceId ?? "" });
  const { data: workspaceData, isPending: isLoadingWorkspace } =
    trpc.workspace.getById.useQuery(
      workspaceId ? { id: workspaceId } : reactQuerySkipToken,
    );
  const isLoadingRequiredData = isLoading || isLoadingWorkspace;

  return (
    <div className="flex flex-col px-6 w-full relative overflow-hidden">
      <Header
        title={`${workspaceData?.workspace?.name} Analytics`}
        isLoading={isLoadingRequiredData}
      />
      <Separator className="my-4 col-span-12" />
      {isEnabled ? (
        <AllMetricsTable />
      ) : (
        <SetupPage onEnable={handleEnable} isEnabling={isEnabling} />
      )}
    </div>
  );
};

export default Analytics;

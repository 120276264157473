import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useModal } from "@editor/hooks/useModal";
import { selectDraftComponent } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { useInCanvasPreview } from "@editor/utils/preview";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";
import { ColorSelector } from "@editorModifiers/ColorModifier";
import { hasDynamicData } from "@editorModifiers/utils";
import * as React from "react";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import type {
  Gradient,
  GradientOrSolidOnChangeProps,
} from "replo-runtime/shared/types";
import type { PreviewableProperty } from "replo-runtime/shared/utils/preview";
import { hasOwnProperty, isNotNullish } from "replo-utils/lib/misc";

type DynamicColorModifierProps = {
  gradientSelectionType: "color" | "backgroundColor" | null;
  field: string;
  value?: string;
  previewProperty?: PreviewableProperty;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  popoverTitle?: string;
  gradientData?: Gradient;
};

export type GradientOrSolidModifierProps =
  | {
      allowsGradientSelection: false;
    }
  | {
      allowsGradientSelection: true;
      gradientSelectionType: "color" | "backgroundColor";
    };

type DynamicColorSelectorProps = {
  hasDynamicData?: boolean;
  field: string;
  previewProperty?: PreviewableProperty;
  openDynamicData?: (attribute: string) => void;
  onRemove?(): void;
  popoverTitle?: string;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  componentId: string;
} & GradientOrSolidOnChangeProps;

export const DynamicColorSelector = (props: DynamicColorSelectorProps) => {
  const isDraggingRef = React.useRef(false);
  const {
    enableCanvasPreviewCSSProperties,
    disableCanvasPreviewCSSProperties,
    setPreviewCSSPropertyValue,
  } = useInCanvasPreview();

  const attribute = props.field.replace("style.", "");
  const value =
    props.value != null &&
    typeof props.value === "object" &&
    props.value.type === "solid"
      ? props.value.color
      : props.value;

  // Note (Noah, 2022-03-14): We need this useEffect to disable the canvas preview
  // for colors because the color modifier is debounced - if we didn't have this
  // and just disabled the preview on dragEnd like normal, then there would be a time
  // before the debounced updated processed but after the preview was disabled where
  // we'd actually show the old color value. Instead, we just disable the preview
  // any time the value changes (i.e., when the debounce processes), unless the user
  // is currently dragging
  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    if (!isDraggingRef.current && props.previewProperty) {
      disableCanvasPreviewCSSProperties([props.previewProperty]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // Note (Noah, 2022-03-15): If the value contains handlebars, that means it's a
  // dynamic data value (the user set it from a data table or something instead of
  // specifying a color manually), so render a dynamic data indicator
  if (typeof value === "string" && value?.includes("{{")) {
    return (
      <DynamicDataValueIndicator
        type="color"
        templateValue={value}
        onClick={() => {
          props.openDynamicData?.(attribute);
        }}
        onRemove={() => props.onRemove?.()}
        componentId={props.componentId}
      />
    );
  }

  const dragProps = {
    onDragEnd: (e: React.MouseEvent) => {
      isDraggingRef.current = false;
      props.onDragEnd?.(e);
    },
    onDragStart: (e: React.MouseEvent) => {
      isDraggingRef.current = true;

      if (
        props.previewProperty &&
        isNotNullish(value) &&
        typeof value === "string"
      ) {
        enableCanvasPreviewCSSProperties([props.previewProperty], value);
        setPreviewCSSPropertyValue([props.previewProperty], value);
      }

      props.onDragStart?.(e);
    },
    onPreviewChange: (value: any) => {
      if (typeof value === "string") {
        if (props.previewProperty && value) {
          setPreviewCSSPropertyValue([props.previewProperty], value);
        }
      } else if (
        hasOwnProperty(value, "type") &&
        hasOwnProperty(value, "color") &&
        props.previewProperty
      ) {
        setPreviewCSSPropertyValue([props.previewProperty], value.color);
      }
      props.onPreviewChange?.(value);
    },
  };
  return (
    <>
      {props.allowsGradientSelection && (
        <ColorSelector
          {...dragProps}
          value={props.value}
          allowsGradientSelection={true}
          onChange={(v) => props.onChange(v)}
          openDynamicData={() => props.openDynamicData?.(attribute)}
          popoverTitle={props.popoverTitle}
          onRemove={() => props.onRemove?.()}
        />
      )}
      {!props.allowsGradientSelection && (
        <ColorSelector
          {...dragProps}
          value={props.value}
          allowsGradientSelection={false}
          onChange={(v) => props.onChange(v)}
          openDynamicData={() => props.openDynamicData?.(attribute)}
          popoverTitle={props.popoverTitle}
        />
      )}
    </>
  );
};

const DynamicColorModifier: React.FC<
  React.PropsWithChildren<
    DynamicColorModifierProps & { onChange: Function; onRemove?(): void }
  >
> = ({
  gradientSelectionType,
  previewProperty,
  onDragEnd,
  onDragStart,
  onRemove,
  popoverTitle,
  field,
  value,
  onChange,
  gradientData,
}) => {
  const draftComponent = useEditorSelector(selectDraftComponent);
  const applyComponentAction = useApplyComponentAction();
  const modal = useModal();

  const attribute = field.replace("style.", "");

  if (!draftComponent) {
    return null;
  }

  const openDynamicData = (attribute: string) => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT_COLOR,
        referrerData: {
          type: "style",
          styleAttribute: attribute,
        },
        initialPath: getPathFromVariable(value),
      },
    });
  };

  const handleRemove = (attribute: string) => {
    applyComponentAction({
      type: "setStyles",
      value: {
        [attribute]: null,
      },
    });
  };

  if (gradientSelectionType !== null) {
    return (
      <DynamicColorSelector
        previewProperty={previewProperty}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        hasDynamicData={hasDynamicData(draftComponent?.id)}
        field={field}
        value={
          value === "alchemy:gradient"
            ? {
                type: "gradient",
                gradient: gradientData!,
              }
            : { type: "solid", color: value! }
        }
        allowsGradientSelection={true}
        onChange={(value: any) => onChange?.(value)}
        onRemove={() => {
          if (onRemove) {
            onRemove();
          } else {
            handleRemove(attribute);
          }
        }}
        openDynamicData={openDynamicData}
        popoverTitle={popoverTitle}
        componentId={draftComponent.id}
      />
    );
  }
  return (
    <DynamicColorSelector
      previewProperty={previewProperty}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      hasDynamicData={hasDynamicData(draftComponent?.id)}
      field={field}
      value={value ?? null}
      allowsGradientSelection={false}
      onChange={(value: any) => onChange?.(value)}
      onRemove={() => {
        if (onRemove) {
          onRemove();
        } else {
          handleRemove(attribute);
        }
      }}
      openDynamicData={openDynamicData}
      popoverTitle={popoverTitle}
      componentId={draftComponent.id}
    />
  );
};

export default DynamicColorModifier;

import * as React from "react";
import { twMerge } from "tailwind-merge";

export function Loader({
  label,
  className,
}: {
  label: string;
  className?: string;
}) {
  return (
    <div
      className={twMerge(
        "flex h-full flex-col justify-center text-blue-600 gap-2",
        className,
      )}
    >
      <div
        className="inline-block h-8 w-8 animate-spin self-center justify-self-center rounded-full border-4 border-solid border-current border-r-slate-50 align-[-0.125em] text-blue-600 motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      />
      <p className="self-center justify-self-center">{label}</p>
    </div>
  );
}

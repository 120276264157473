import classNames from "classnames";
import omit from "lodash-es/omit";
import * as React from "react";
import type { ProductIdsWithVariantIds } from "replo-runtime/shared/types";
import { deepEqual, shallowEqual } from "replo-utils/lib/object";
import { useIsHydrated } from "replo-utils/react/use-is-hydrated";
import type { ReploComponentType } from "schemas/component";
import type { ReploSymbol, ReploVariant } from "schemas/symbol";

import type { Component } from "../../shared/Component";
import {
  findVariantWithTrueCondition,
  getConditionFieldRenderData,
  initVariantConditions,
} from "../../shared/condition";
import type { AlchemyActionTrigger } from "../../shared/enums";
import { ErrorBoundary } from "../../shared/ErrorBoundary";
import {
  ComponentErrorContext,
  ComponentInventoryContext,
  DynamicDataStoreContext,
  GlobalWindowContext,
  RenderEnvironmentContext,
  ReploSymbolsContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../shared/runtime-context";
import { getRuntimeStyles } from "../../shared/styles";
import {
  applySymbolOverrides,
  applyVariantOverrides,
  resolveVariants,
} from "../../shared/symbol";
import type {
  DynamicStyleDataMap,
  ProductRef,
  RenderComponentAttributes,
} from "../../shared/types";
import { mergeContext } from "../../shared/utils/context";
import { useComponentClassNames } from "../../shared/utils/renderComponents";
import { getVariantsWithState, isHashmarkVariant } from "../../shared/variant";
import type { GlobalWindow } from "../../shared/Window";
import type { Context } from "../AlchemyVariable";
import type { ComponentInventory } from "../componentInventory";
import useComponentRerenderKey from "../hooks/useComponentRerenderKey";
import { useConsistentLiquidId } from "../hooks/useConsistentLiquidId";
import { isContextRef } from "../ReploProduct";
import {
  evaluateComponentProps,
  generateCustomPropValues,
  getComponentAttributes,
  setGlobalComponentData,
} from "../utils/alchemy-component";
import { isCompletelyHiddenComponent } from "../utils/isCompletelyHiddenComponent";
import ComponentAnimationWrapper from "./ComponentAnimationWrapper";
import { GridWrapper } from "./GridWrapper";
import ReploLiquidChunk from "./ReploLiquid/ReploLiquidChunk";

export interface ReploComponentProps {
  /**
   * The Replo component this React component should render
   */
  component: Component;
  /**
   * Current Replo context (not a React context) which has data filled in
   * by this component's ancestor components
   */
  context: Context;
  /**
   * Extra attributes to pass directly to the React node that renders this
   * component (useful for aria attributes, etc)
   */
  extraAttributes?: Partial<RenderComponentAttributes>;
  /**
   * String representing the path through the repeated component tree to this
   * component. For example, if this component was the child of a component
   * which was repeated N times (e.g. a carousel content component) and we're
   * rendering the 2nd repetition, this string would end in ".1.0"
   */
  repeatedIndexPath: string;
  /**
   * Used to define actions that cannot be overridden by states, including
   * internal actions like setting the active selling plan (See USE-791 for
   * context).
   */
  defaultActions?: DefaultActionsMap;
  /** Overrides for any Replo component prop */
  overrideComponentProps?: Partial<Component["props"]>;
  /** Overrides the Replo component type */
  overrideComponentType?: ReploComponentType;
}

export type OverrideComponentPropsMap = Record<string, unknown>;

export type ActionsMap = Pick<Component["props"], AlchemyActionTrigger>;

export type DefaultActionsMap = {
  actions: ActionsMap;
  /** @default "before" */
  placement?: "before" | "after";
};

interface ReploComponentVariantOrSymbolProps extends ReploComponentProps {
  symbol?: ReploSymbol | null;
  variants?: ReploVariant[] | null;
  componentRef: React.RefObject<HTMLElement>;
}

type TemplateProductStateOverride =
  | { type: "variantActive"; activeVariantId: string }
  | { type: "allVariantsInactive" };

type TemplateProductOverrideProps = {
  /**
   * Used to force the template product state to apply when rendering on the
   * server. We can't determine server-side what the template product is, so we
   * calculate the template product states in liquid and pass the result down.
   *
   * "variantActive" means that we have found a variant whose condition is true (in Liquid)
   * "allVariantsInactive" means that we have determined that none of the template-dependent variants are active (in Liquid)
   *
   * When this is undefined, we fall back to normal variant calculations.
   */
  templateProductStateOverride?: TemplateProductStateOverride;
};

interface ReploComponentImplProps extends ReploComponentProps {
  symbol?: ReploSymbol | null;
  variants?: ReploVariant[] | null;
  activeVariantId: string | null;
  setActiveVariantId: SetActiveVariantId | null;
  componentRef: React.RefObject<HTMLElement | null>;
}

/**
 * Main React component which renders a Replo component. Handles things like
 * breakpoint styling, symbol resolution, and variants. Shells out to individual
 * React components to actually render img, p, div, etc
 */
export function ReploComponent(props: ReploComponentProps) {
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);

  // NOTE (Chance 2024-05-01): We need to control renders more in the editor
  // since component data changes frequently and many calculations become more
  // expensive. On published pages most of this data is static so there's no
  // need to memoize at this level.
  const SymbolComponent = isEditorApp
    ? ReploComponentSymbolMemoized
    : ReploComponentSymbol;
  const VariantComponent = isEditorApp
    ? ReploComponentWithVariantsMemoized
    : ReploComponentWithVariants;
  const ImplComponent = isEditorApp
    ? ReploComponentImplMemoized
    : ReploComponentImpl;

  const _component = useComponentWithPropOverrides({
    component: props.component,
    overrideComponentProps: props.overrideComponentProps,
    overrideComponentType: props.overrideComponentType,
  });

  const variants = React.useMemo(() => {
    return applyVariantOverrides(
      resolveVariants({
        componentType: _component.type,
        componentVariants: _component.variants,
        symbolVariants: null,
      }),
      _component.variantOverrides,
    );
  }, [_component.type, _component.variantOverrides, _component.variants]);
  const componentRef = React.useRef<HTMLElement | null>(null);

  if (props.component.type === "symbolRef") {
    return (
      <SymbolComponent
        {...props}
        componentRef={componentRef}
        component={_component}
        variants={variants}
      />
    );
  }

  if (variants && variants.length > 0) {
    return (
      <VariantComponent
        {...props}
        componentRef={componentRef}
        component={_component}
        variants={variants}
      />
    );
  }
  return (
    <ImplComponent
      {...props}
      componentRef={componentRef}
      component={_component}
      variants={null}
      activeVariantId={null}
      setActiveVariantId={null}
    />
  );
}

const getTemplateProductDependentVariants = (variants: ReploVariant[]) => {
  return variants.filter((variant) =>
    [
      "state.product.templateProductEquals",
      "state.product.selectedVariantUnavailable",
      "state.product.selectedVariantEquals",
    ].includes(variant.query?.statements[0]?.field ?? ""),
  );
};

// Note (Evan, 2024-08-30): Returns a liquid string which evaluates to whether the
// variant is active. Also returns whether the condition is negated, i.e. if we should
// really test !condition -- this is necessary because Liquid somehow doesn't support this.
// (https://github.com/Shopify/liquid/issues/138)
const getLiquidConditionForTemplateProductDependentVariant = (
  variant: ReploVariant,
): { condition: string; negated?: boolean } => {
  const statement = variant.query?.statements[0];

  if (!statement) {
    return { condition: "false" };
  }

  if (statement.field === "state.product.templateProductEquals") {
    const products = (statement.value ?? []) as ProductRef[];

    if (products.length === 0) {
      return { condition: "false" };
    }

    return {
      condition: `${products.map((product) => `product.id == ${product.productId}`).join(" or ")}`,
      negated: statement.operator === "neq",
    };
  }

  if (statement.field === "state.product.selectedVariantUnavailable") {
    return {
      condition:
        "product.selected_or_first_available_variant.available == false",
    };
  }

  if (statement.field === "state.product.selectedVariantEquals") {
    const productIdToVariantMapping =
      (statement.value as ProductIdsWithVariantIds) ?? {};

    const productIdsAndVariants = Object.entries(productIdToVariantMapping);
    if (productIdsAndVariants.length === 0) {
      return { condition: "false" };
    }

    const condition = productIdsAndVariants
      .flatMap(([productId, variantsOrNull]) => {
        // Note (Evan, 2024-08-30): If the variants list is null, we only check the product
        if (variantsOrNull === null) {
          return [`product.id == ${productId}`];
        }

        // Note (Evan, 2024-08-30): Otherwise, create one "or" condition per variant
        return variantsOrNull.map(
          (variantId) =>
            `product.selected_or_first_available_variant.id == ${variantId}`,
        );
      })
      .join(" or ");

    return { condition, negated: statement.operator === "neq" };
  }

  return { condition: "false" };
};

/**
 * Version of ReploComponent which includes state for which variants (Replo States)
 * are active. The reason this is a separate component is because we need it to be
 * independently memoizable, so that we can control rerenders - specifically, when
 * users take action like going into preview mode which affects how variants are overridden,
 * we want to make sure that only components with variants rerender.
 */
function ReploComponentWithVariants(
  props: ReploComponentVariantOrSymbolProps & TemplateProductOverrideProps,
) {
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const [activeVariantId, setActiveVariantId] = useAlchemyVariantRuntime({
    componentRef: props.componentRef,
    variants: props.variants ?? [],
    context: props.context,
    templateProductStateOverride: props.templateProductStateOverride,
    globalWindow,
  });

  const consistentLiquidId = useConsistentLiquidId();

  // Note (Evan, 2024-08-30): Some states (such as Template Product Equals states) depend on the identity of the
  // template product. Since we don't know this at publish time, we have to do some liquid tomfoolery - see below.
  const templateProductDependentVariants = getTemplateProductDependentVariants(
    props.variants ?? [],
  );

  if (
    props.context.isPublishing &&
    props.context.isInsideTemplateProductComponent &&
    templateProductDependentVariants.length > 0 &&
    // Note (Evan, 2023-11-15): Avoid infinite loop here by checking that we're not already in
    // this Liquid-controlled version.
    props.templateProductStateOverride === undefined
  ) {
    const firstTrueVariantName = `firstTrueVariant${consistentLiquidId}`;

    return (
      // Note (Evan, 2024-08-30): The idea here is to evaluate states that could depend on the identity of the template product
      // in Liquid, so that we SSR the component with correct state. This takes place in a few steps:
      // 1) Identify the ID of the first template-product-dependent variant whose condition returns true (this is equivalent to
      // findVariantWithTrueCondition in the non-liquid world)
      // 2) For each template-product-dependent variant, essentially render
      //    {% if variant.id == firstTrueVariantId %}
      //        <ReploComponentWithVariants templateProductStateOverride={variant.id} />
      //    {% endif %}
      // i.e., conditionally rendering the variant if we have previously determined it to be active.
      // 3) As a fallback, if NONE of the template-product-dependent variants are active, pass templateProductStateOverride={null}
      // so that we can skip those variants (in useAlchemyVariantRuntime)
      //
      // These are each done in separate chunks, to quote Matt, "in order to reduce the likelihood of there being errors on publish for a chunk
      // being too big, which is possible if someone puts this state on a very high level component in the tree."
      <>
        <ReploLiquidChunk>
          {`{% capture ${firstTrueVariantName} %}{% endcapture %}`}
          {templateProductDependentVariants.map((variant) => {
            const { condition, negated } =
              getLiquidConditionForTemplateProductDependentVariant(variant);

            if (negated) {
              return (
                <>
                  {`{% if ${firstTrueVariantName} == empty %}`}
                  {`{% unless ${condition} %}`}
                  {`{% capture ${firstTrueVariantName} %}${variant.id}{% endcapture %}`}
                  {`{% endunless %}`}
                  {`{% endif %}`}
                </>
              );
            }

            return (
              // biome-ignore lint/correctness/useJsxKeyInIterable: allow no key in iterable
              <>
                {`{% if ${firstTrueVariantName} == empty and ${condition} %}`}
                {`{% capture ${firstTrueVariantName} %}${variant.id}{% endcapture %}`}
                {`{% endif %}`}
              </>
            );
          })}
        </ReploLiquidChunk>
        {templateProductDependentVariants.map((variant) => {
          return (
            <ReploLiquidChunk key={variant.id}>
              {`{% unless firstTrueVariant${consistentLiquidId} == empty %}`}
              {`{% capture variantId %}${variant.id}{% endcapture %}`}
              {`{% if firstTrueVariant${consistentLiquidId} == variantId %}`}
              <ReploComponentWithVariants
                {...props}
                templateProductStateOverride={{
                  type: "variantActive",
                  activeVariantId: variant.id,
                }}
              />
              {`{% endif %}`}
              {`{% endunless %}`}
            </ReploLiquidChunk>
          );
        })}
        <ReploLiquidChunk>
          {`{% if firstTrueVariant${consistentLiquidId} == empty %}`}
          <ReploComponentWithVariants
            {...props}
            templateProductStateOverride={{ type: "allVariantsInactive" }}
          />
          {`{% endif %}`}
        </ReploLiquidChunk>
      </>
    );
  }

  return (
    <ReploComponentImpl
      {...props}
      activeVariantId={activeVariantId}
      componentRef={props.componentRef}
      setActiveVariantId={setActiveVariantId}
      variants={props.variants}
    />
  );
}

const ReploComponentWithVariantsMemoized = React.memo(
  ReploComponentWithVariants,
);

// NOTE (Chance 2024-04-24): This is broken out into a separate component so
// that non-symbol components don't have a render dependency on the symbol
// context.
function ReploComponentSymbol(props: ReploComponentVariantOrSymbolProps) {
  const { symbols } = useRuntimeContext(ReploSymbolsContext);
  const symbol = getSymbolDefinition({
    componentType: props.component.type,
    componentSymbolId: props.component.symbolId,
    symbols,
  });
  return (
    <ReploComponentWithVariants
      {...props}
      componentRef={props.componentRef}
      symbol={symbol}
      variants={props.variants}
    />
  );
}
const ReploComponentSymbolMemoized = React.memo(ReploComponentSymbol);

function ReploComponentImpl({
  symbol = null,
  overrideComponentProps,
  overrideComponentType,
  component: componentFromProps,
  variants: _variants,
  activeVariantId,
  setActiveVariantId,
  ...props
}: ReploComponentImplProps) {
  // Note (Noah, 2024-07-29, USE-1162): Important for this to be memoized so it's
  // a stable reference, otherwise we might accidentally trigger rerenders if this
  // is used as a dependency in another useMemo or as a prop to a React.memo'd component
  const variants = React.useMemo(() => _variants ?? [], [_variants]);

  const editorOverrideVariantId = useRuntimeContext(
    RuntimeHooksContext,
  ).useEditorOverrideActiveVariantId(componentFromProps.id);
  const componentInventoryContext = useRuntimeContext(
    ComponentInventoryContext,
  );
  const { componentInventory } = componentInventoryContext;

  const _component = componentFromProps;

  const variantAndSymbolOverridesRef = React.useRef<Record<string, Object>>({});

  // If the component is a symbol instance, we need to merge it with its symbol
  // definition to get the effective component to actually render
  const { component, variantAndSymbolOverrides } = React.useMemo(() => {
    const { component, variantAndSymbolOverrides } = applySymbolOverrides(
      _component,
      symbol,
      editorOverrideVariantId ?? activeVariantId,
      props.context.componentOverrides ?? null,
    );
    // NOTE (Chance 2024-05-02): IMPORTANT BUT HACK-TASTIC OPTIMIZATION. In
    // order to stabilize excessive renders due to arbitrary changes to the
    // context object, I only want the `variantAndSymbolOverrides` reference to
    // change if any of its values actually change in this calculation. Deps of
    // this hook may not be stable, and trying to do this properly takes us down
    // a huge rabbit hole.
    if (
      !shallowEqual(
        variantAndSymbolOverridesRef.current,
        variantAndSymbolOverrides,
      )
    ) {
      variantAndSymbolOverridesRef.current = variantAndSymbolOverrides;
    }
    return {
      component,
      variantAndSymbolOverrides: variantAndSymbolOverridesRef.current,
    };
  }, [
    activeVariantId,
    editorOverrideVariantId,
    _component,
    props.context.componentOverrides,
    symbol,
  ]);

  // Grab the actual react component we will use to render
  const Wrapper =
    componentInventory[component.type as keyof ComponentInventory];

  if (!Wrapper) {
    // NOTE (Chance 2024-04-28): We should consider throwing here. There's no
    // reason we should just render nothing since this is an error we'd want to
    // surface to the user somehow.
    return null;
  }

  return (
    <ReploComponentSafe
      {...props}
      activeVariantId={activeVariantId}
      component={_component}
      componentRef={props.componentRef}
      componentWithAppliedOverrides={component}
      setActiveVariantId={setActiveVariantId}
      variantAndSymbolOverrides={variantAndSymbolOverrides}
      variants={variants}
      wrapper={Wrapper}
    />
  );
}

const ReploComponentImplMemoized = React.memo(ReploComponentImpl);

interface ReploComponentSafeProps extends ReploComponentImplProps {
  componentWithAppliedOverrides: Component;
  activeVariantId: string | null;
  setActiveVariantId: SetActiveVariantId | null;
  variants: ReploVariant[];
  variantAndSymbolOverrides: Record<string, Object>;
  wrapper: any;
  componentRef: React.RefObject<HTMLElement | null>;
}

/**
 * NOTE (Chance 2024-04-12): This component is rendered after we have the data
 * we need to ultimately render the runtime component. We can bail early in the
 * higher-level implementation without breaking the rule-of-hooks.
 */
function ReploComponentSafe({
  activeVariantId,
  setActiveVariantId,
  variants,
  wrapper: Wrapper,
  componentWithAppliedOverrides: component,
  variantAndSymbolOverrides,
  componentRef,
  ...props
}: ReploComponentSafeProps) {
  const animations = React.useMemo(
    () => props.component?.animations || [],
    [props.component?.animations],
  );
  const animationRef = React.useRef<HTMLDivElement | null>(null);
  const {
    fakeProducts,
    products,
    productMetafieldValues,
    variantMetafieldValues,
    activeCurrency: currencyCode,
    activeLanguage: language,
    moneyFormat,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  const isLabelledByOtherComponent = useRuntimeContext(
    RuntimeHooksContext,
  ).useIsLabelledByOtherComponent(props.component.id);
  const { isEditorApp, previewEnvironment } = useRuntimeContext(
    RenderEnvironmentContext,
  );
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const editorOverrideVariantId = useRuntimeContext(
    RuntimeHooksContext,
  ).useEditorOverrideActiveVariantId(props.component.id);
  const { onComponentError } = useRuntimeContext(ComponentErrorContext);
  const { store: dynamicDataStore } = useRuntimeContext(
    DynamicDataStoreContext,
  );

  const repeatedIndex = Number.parseInt(
    props.repeatedIndexPath.split(".").pop() ?? "0",
  );

  // Resolve dynamic data from any of this component's props values.
  const { dynamicStyleDataMap, evaluatedProps, evaluatedDynamicDataPaths } =
    React.useMemo(() => {
      const dynamicStyleDataMap: DynamicStyleDataMap = {};
      const context = Object.is(props.context.store, dynamicDataStore)
        ? props.context
        : { ...props.context, store: dynamicDataStore };

      const { evaluatedProps, evaluatedDynamicDataPaths } =
        evaluateComponentProps(component, context, repeatedIndex, (data) => {
          dynamicStyleDataMap[data.dynamicDataKey] = {
            key: data.attribute,
            value: data.evaluatedValue,
            index: data.repeatedIndex,
          };
        });

      return {
        dynamicStyleDataMap,
        evaluatedProps,
        evaluatedDynamicDataPaths,
      };
    }, [component, dynamicDataStore, props.context, repeatedIndex]);

  const runtimeStyleProps = React.useMemo(
    () =>
      getRuntimeStyles({
        dynamicStyleDataMap,
        desktopBackgroundImage: component.props?.style?.backgroundImage ?? null,
      }),
    [component, dynamicStyleDataMap],
  );

  const rerenderKey = useComponentRerenderKey(
    evaluatedDynamicDataPaths,
    component,
    props.context.elementType,
  );

  const selfOrParentHasVariants =
    props.context.selfOrParentHasVariants || (variants || []).length > 0;

  // Now that we're done compiling componentAttributes, create the new Replo
  // Context which will be passed through to the wrapper component

  // NOTE (Gabe 2023-08-28): We inherit `isInsideTemplateProductComponent` from the parent
  // context unless it's a product component. If it is a product component, the
  // referenced product determines whether or not we're in a productTemplate.
  let isInsideTemplateProductComponent = Boolean(
    props.context.isInsideTemplateProductComponent,
  );
  let isInsideProductComponent = Boolean(
    props.context.isInsideProductComponent,
  );
  const isInsideProductImageCarousel = Boolean(
    props.context.isInsideProductImageCarousel,
  );

  const componentReferencesTemplateProduct =
    component.props._product &&
    isContextRef(component.props._product) &&
    component.props._product.ref.includes("_templateProduct");

  if (component.type === "product") {
    isInsideProductComponent = true;
    if (componentReferencesTemplateProduct) {
      isInsideTemplateProductComponent = true;
    } else {
      isInsideTemplateProductComponent = false;
    }
  }

  const getActiveVariantId = React.useCallback(() => {
    if (isEditorApp) {
      return editorOverrideVariantId ?? null;
    }
    return activeVariantId;
  }, [isEditorApp, activeVariantId, editorOverrideVariantId]);

  const actionHooks = React.useMemo<Context["actionHooks"]>(() => {
    return {
      componentIdToVariantSetters: {
        [props.component.id]: {
          setActiveVariantId: setActiveVariantId ?? undefined,
          getActiveVariantId,
        },
      },
    };
  }, [getActiveVariantId, props.component.id, setActiveVariantId]);

  const customPropValuesRef = React.useRef<Record<string, any>>({});
  const customPropValues = React.useMemo(() => {
    const customPropValues = generateCustomPropValues(
      component,
      props.context,
      {
        products,
        fakeProducts,
        currencyCode,
        language,
        moneyFormat,
        templateProduct,
      },
      productMetafieldValues,
      variantMetafieldValues,
      evaluatedProps,
      previewEnvironment,
      // NOTE (Gabe 2023-08-29): We pass isInsideTemplateProductComponent here because the value
      // from context is out of date (from the parent) and we need to override it
      // in order to get the correct product.
      isInsideTemplateProductComponent,
    );

    // NOTE (Chance 2024-05-02): IMPORTANT BUT HACK-TASTIC OPTIMIZATION. In
    // order to stabilize excessive renders due to arbitrary changes to the
    // context object, I only want the `customPropValuesRef` reference to change
    // if any of its values actually change in this calculation.
    if (!deepEqual(customPropValuesRef.current, customPropValues)) {
      customPropValuesRef.current = customPropValues;
    }
    return customPropValuesRef.current;
  }, [
    component,
    currencyCode,
    language,
    moneyFormat,
    templateProduct,
    evaluatedProps,
    fakeProducts,
    isInsideTemplateProductComponent,
    previewEnvironment,
    productMetafieldValues,
    products,
    props.context,
    variantMetafieldValues,
  ]);

  const variantsWithState = React.useMemo(
    () => getVariantsWithState(variants, activeVariantId),
    [activeVariantId, variants],
  );

  const ancestorWithVariantsId =
    variants.length > 0 ? component.id : props.context.ancestorWithVariantsId;

  const klaviyoEmbedCode = component.props._embedCode;

  const context = React.useMemo<Context>(() => {
    // Note (Ovishek, 2023-02-03): Here we need an extra .0 added in the middle
    // of repeated index b/c there is always ReploComponent in between two
    // store component render, for example SlidesComponent calls ->
    // ReploComponent calls -> Slide 1 Component, and we are always adding and
    // extra ".0" in ReploComponent.
    const repeatedIndexPath = `${props.repeatedIndexPath}.0`;

    const additionalContext: Partial<Context> = {
      attributes: customPropValues,
      selfOrParentHasVariants,
      variants: variantsWithState,
      ancestorWithVariantsId,
      componentOverrides: variantAndSymbolOverrides,
      actionHooks,
      repeatedIndexPath,
      isInsideProductComponent,
      isInsideTemplateProductComponent,
      isInsideProductImageCarousel,
      componentReferencesTemplateProduct,
      klaviyoEmbedCode,
      globalWindow,
    };

    return mergeContext(props.context, additionalContext);
  }, [
    actionHooks,
    ancestorWithVariantsId,
    customPropValues,
    isInsideProductComponent,
    isInsideProductImageCarousel,
    isInsideTemplateProductComponent,
    klaviyoEmbedCode,
    props.context,
    props.repeatedIndexPath,
    componentReferencesTemplateProduct,
    selfOrParentHasVariants,
    variantAndSymbolOverrides,
    variantsWithState,
    globalWindow,
  ]);

  const isHydrated = useIsHydrated();

  // Quick detour before we actually render the component: set globals so that
  // the editor and runtime can access this component's context, etc
  setGlobalComponentData({
    componentId: component.id,
    repeatedIndexPath: props.repeatedIndexPath,
    context,
    isEditor: isEditorApp,
  });

  // Note (Noah, 2023-06-30): We set the class name for the root DOM element of
  // whatever type of component this is to be the className for the "root" style
  // element. This means that every component gets its correct class name
  // automatically, as long as they pass the componentAttributes through to
  // their DOM element. This is implicit, but it means that we don't have to
  // worry about passing the correct class name and merging in
  // extraAttributes.className in every single component.
  const className = classNames(
    props.extraAttributes?.className,
    useComponentClassNames(component.type, component, context)?.root,
  );

  const componentAttributes = React.useMemo<RenderComponentAttributes>(() => {
    // Create the props to be put on the rendered React component
    const componentAttributes = getComponentAttributes({
      component,
      context: props.context,
      runtimeStyleProps,
      evaluatedComponentProps: evaluatedProps,
      variants,
      selfOrParentHasVariants,
      componentRef,
      repeatedIndexPath: props.repeatedIndexPath,
      extraAttributes: props.extraAttributes,
      products,
      isLabelledByOtherComponent,
      defaultActions: props.defaultActions,
      templateProduct,
    });
    componentAttributes.className = className;

    // Reset each animation to its initial state if we are server rendering it
    if (isHydrated && animations.length > 0) {
      for (const animation of animations) {
        const animationName = animation.value?.styles?.animationName;
        componentAttributes.style = {
          ...componentAttributes.style,
          animationName: animationName ?? "none",
          animationPlayState: "paused",
        };
      }
    }

    return componentAttributes;
  }, [
    animations,
    isLabelledByOtherComponent,
    className,
    component,
    componentRef,
    evaluatedProps,
    isHydrated,
    products,
    props.context,
    props.defaultActions,
    props.extraAttributes,
    props.repeatedIndexPath,
    runtimeStyleProps,
    selfOrParentHasVariants,
    variants,
    templateProduct,
  ]);

  // Pass evaluated props so that the render component can use its component's
  // props as a source of truth (needed for things like access onClick actions
  // and stuff)
  const componentWithEvaluatedProps = React.useMemo(() => {
    return {
      ...component,
      props: evaluatedProps,
    };
  }, [component, evaluatedProps]);
  const componentId = componentWithEvaluatedProps.id;

  // Note (Sebas, 2023-05-17): If the component is completely hidden, we don't
  // want to render it at all. This is at the bottom of the file because
  // otherwise we'd render a different number of hooks when the component
  // changes from hidden to visible. Also, we don't support rendering null when
  // the component or a parent has variants because it's too complicated to
  // figure out all possible states and check them all for visibility.
  if (
    isCompletelyHiddenComponent(props.component) &&
    !selfOrParentHasVariants
  ) {
    return null;
  }

  // Now, finally render the component!!
  const renderedComponent =
    animations.length > 0 ? (
      <ComponentAnimationWrapper
        componentRef={componentRef}
        animations={animations}
        animationRef={animationRef}
      >
        {(ref) => {
          return (
            // Note (Ovishek, 2023-03-09, REPL-6359): We add this GridWrapper only
            // for grid components to fix the infamous safari flex vs grid issue,
            // where 100% height over column gets messed up.
            <GridWrapper component={componentWithEvaluatedProps}>
              <Wrapper
                {...evaluatedProps}
                {...customPropValues}
                data-component-type={component.type}
                context={context}
                component={componentWithEvaluatedProps}
                componentAttributes={componentAttributes}
                {...componentAttributes} // unwrap attributes for bottom level component
                ref={ref}
                key={`replo-component-${rerenderKey}`}
              />
            </GridWrapper>
          );
        }}
      </ComponentAnimationWrapper>
    ) : (
      <GridWrapper component={componentWithEvaluatedProps}>
        <Wrapper
          {...evaluatedProps}
          {...customPropValues}
          data-component-type={component.type}
          context={context}
          component={componentWithEvaluatedProps}
          componentAttributes={componentAttributes}
          {...omit(componentAttributes, "ref")} // unwrap attributes for bottom level component
          key={`replo-component-${rerenderKey}`}
        />
      </GridWrapper>
    );

  // Note (Sebas, 2023-05-26): We add an error boundary around the component so that
  // if it throws an error, we can show a nice error message instead of crashing the
  // whole page.
  return (
    <ErrorBoundary
      onError={(error, info) => {
        console.error(
          "[Replo] Error encountered rendering components, page content may not look correct",
          error,
          info,
        );
        onComponentError?.(componentId, error, info);
      }}
      fallback={null}
    >
      {renderedComponent}
    </ErrorBoundary>
  );
}

/**
 * Returns true if we're running on the client and the component has variants
 * that depend on client state (e.g. window scroll position) and as such might
 * need to be rerendered.
 *
 * Example is a component which shows only after a user scrolls to a certain
 * point on the page - on the server we always assume the scroll position is 0,
 * but on the client the user might have reloaded the page halfway down and we
 * have a different initial scroll position. Thus, the active variant might be
 * incorrect and we need to re-check the variant conditions.
 *
 * This hook causes the component to rerender if we're on the client and the
 * variants need to be rechecked, to make sure we account for this case.
 */
const useIsClientAndVariantsNeedRerender = (variants: ReploVariant[]) => {
  const [isClientAndVariantsNeedRerender, setIsClientAndVariantsNeedRerender] =
    React.useState(false);
  const variantsNeedRerender =
    variants &&
    variants.some((variant) =>
      variant.query?.statements.some(
        (statement) =>
          getConditionFieldRenderData(statement.field).dependsOnClientState,
      ),
    );

  React.useEffect(() => {
    if (variantsNeedRerender) {
      setIsClientAndVariantsNeedRerender(true);
    }
  }, [variantsNeedRerender]);

  return isClientAndVariantsNeedRerender;
};

function useComponentWithPropOverrides(props: {
  component: Component;
  overrideComponentProps: OverrideComponentPropsMap | undefined;
  overrideComponentType: ReploComponentType | undefined;
}) {
  const { overrideComponentProps, overrideComponentType } = props;

  const componentFromProps = props.component;
  const component = React.useMemo(() => {
    if (!overrideComponentProps && !overrideComponentType) {
      return componentFromProps;
    }

    let component = componentFromProps;

    if (overrideComponentProps) {
      component = getComponentWithPropOverrides(
        component,
        overrideComponentProps,
      );
    }

    if (overrideComponentType) {
      component = getComponentWithTypeOverride(
        component,
        overrideComponentType,
      );
    }

    return component;
  }, [componentFromProps, overrideComponentProps, overrideComponentType]);

  return component;
}

function getComponentWithPropOverrides(
  component: Component,
  overrides: OverrideComponentPropsMap,
) {
  let nextComponentProps: Component["props"] | undefined;
  for (const propName in overrides) {
    const propValue = overrides[propName];
    if (component.props[propName] !== propValue) {
      nextComponentProps ??= { ...component.props };
      nextComponentProps[propName] = propValue as any;
    }
  }
  return nextComponentProps
    ? { ...component, props: nextComponentProps }
    : component;
}

function getComponentWithTypeOverride(
  component: Component,
  overrideType: ReploComponentType,
) {
  if (overrideType === component.type) {
    return component;
  }
  return { ...component, type: overrideType };
}

type SetActiveVariantId = (activeVariantId: string) => void;

/**
 * Hook which manages the active Replo Variant id. The value and setter work
 * as if you had called useState, but the value is guaranteed to always be the
 * currently active variant of this component.
 *
 * @param args.componentRef DOM ref to the component
 * @param args.variants List of variants to manage
 * @param args.context Current render context of the component whose variants we're managing
 */
function useAlchemyVariantRuntime(args: {
  componentRef: React.RefObject<HTMLElement>;
  variants: ReploVariant[];
  context: Context;
  templateProductStateOverride?: TemplateProductStateOverride;
  globalWindow: GlobalWindow | null;
}): [string | null, SetActiveVariantId] {
  const {
    componentRef,
    variants,
    context,
    templateProductStateOverride,
    globalWindow,
  } = args;
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const isPreviewMode = isEditorApp && !isEditorCanvas;

  const isClientAndVariantsNeedRerender =
    useIsClientAndVariantsNeedRerender(variants);

  const initialVariant = (() => {
    // Note (Evan, 2024-08-30): If there's an override defined, use that
    if (
      templateProductStateOverride &&
      templateProductStateOverride.type === "variantActive"
    ) {
      return variants.find(
        (variant) =>
          variant.id === templateProductStateOverride.activeVariantId,
      );
    }

    // Note (Evan, 2024-08-30): When the override is undefined, we aren't
    // overriding at all, and should therefore not skip anything. Otherwise,
    // templateProductStateOverride has type "allVariantsInactive" -- so we should
    // skip all variants that depend on the template product.
    const variantIdsToSkip = templateProductStateOverride
      ? getTemplateProductDependentVariants(variants).map(
          (variant) => variant.id,
        )
      : [];

    return findVariantWithTrueCondition({
      variants,
      event: null,
      componentContext: context,
      ref: componentRef,
      isInitialHydration: !isClientAndVariantsNeedRerender,
      skip: variantIdsToSkip,
      isEditorApp,
      isPreviewMode,
      globalWindow,
    });
  })();

  const initialVariantId = initialVariant?.id ?? null;
  const [activeVariantId, setActiveVariantId] =
    React.useState(initialVariantId);
  const activeVariant = variants.find((v) => v.id === activeVariantId);
  const activeVariantIsHashmarkVariant =
    activeVariant && isHashmarkVariant(activeVariant);

  const activeVariantIdRef = React.useRef(activeVariantId);
  React.useEffect(() => {
    const previousActiveVariantId = activeVariantIdRef.current;
    activeVariantIdRef.current = activeVariantId;
    // Note (Noah, 2021-07-02): If the variant just changed, we may be inside a
    // container which we now need to scroll in order to show the component
    // whose variant just changed. This enables things like sticky menus to
    // auto-scroll as the user moves down the page. (It doesn't have to be
    // implemented like this necessarily, but this is just the easiest way for
    // now)
    // Note (Noah, 2023-12-03, USE-594): ^ The above comment was written years
    // ago when we were trying to get Replo working for Studs. It was only ever
    // intended to be used for hashmark states (to implement the scrolling
    // sticky-menu behavior on the Studs /pierce page). If we use the same
    // behavior for other states, it causes issues - for example, we don't want
    // to auto-scroll a scrolling list of tabs when you hover over the last one,
    // even if the tab has a hover state, because it's jarring. Besides, this
    // logic is imperfect - it only accounts for scrolling to the left, doesn't
    // take into account which state transitions it should apply to, only
    // accounts for one level of parent element being scrollable, etc.
    // Eventually the solution is probably to have some sort of config on
    // containers that you can set to opt-into this behavior. For now, we just
    // restrict it to hashmark states.
    if (
      previousActiveVariantId !== activeVariantId &&
      componentRef.current &&
      activeVariantIsHashmarkVariant
    ) {
      const targetDocument = componentRef.current.ownerDocument;
      // Figure out if the element is fully visible. If it's not, scroll
      // the parent to make it visible.
      // Note (Noah, 2021-07-02): This assumes the element's direct
      // parent is the scrollable container. We might want to do something
      // like do trickier logic to find the scrollable container in the
      // future
      const pageWidth = Math.max(
        targetDocument.body.scrollWidth,
        targetDocument.documentElement.scrollWidth,
        targetDocument.body.offsetWidth,
        targetDocument.documentElement.offsetWidth,
        targetDocument.documentElement.clientWidth,
      );
      const clientRect = componentRef.current.getBoundingClientRect();
      const isFullyVisible =
        clientRect.left > 0 && clientRect.right < pageWidth;
      if (!isFullyVisible && componentRef.current) {
        // Note (Noah, 2021-07-02): 20 offset is arbitrary, but it looks
        // pretty good on Studs
        scrollParentToElement(componentRef.current, 20);
      }
    }
  }, [activeVariantId, activeVariantIsHashmarkVariant, componentRef]);

  React.useEffect(() => {
    const removeListeners = initVariantConditions({
      ref: componentRef,
      variants,
      context,
      onChange: (variantId) => {
        if (!isEditorCanvas) {
          setActiveVariantId(variantId);
        }
      },
      globalWindow,
      isPreviewMode,
      isEditorApp,
    });
    return () => {
      removeListeners();
    };
  }, [
    variants,
    componentRef,
    context,
    globalWindow,
    isEditorCanvas,
    isPreviewMode,
    isEditorApp,
  ]);

  // Note (Noah, 2021-10-21): We run this affect when the initial variant id
  // changes (e.g. when a variant that that is controlled by outside state like
  // the "Is Current Tab" variant changes), to make sure to override any currently
  // active variant (like hover).
  React.useEffect(() => {
    setActiveVariantId(initialVariantId);
  }, [initialVariantId]);

  return [activeVariantId, setActiveVariantId];
}

function scrollParentToElement(
  element: HTMLElement,
  leftOffset: number,
  behavior: "smooth" | "auto" = "auto",
) {
  const elementPosition = element.offsetLeft;
  const offsetPosition = elementPosition - leftOffset;

  element.offsetParent?.scrollTo({
    left: offsetPosition,
    behavior: behavior,
  });
}

function getSymbolDefinition({
  componentType,
  componentSymbolId,
  symbols,
}: {
  componentType: ReploComponentType | undefined;
  componentSymbolId: string | undefined;
  symbols: ReploSymbol[];
}) {
  if (componentType !== "symbolRef") {
    return null;
  }
  return symbols.find((s) => s.id === componentSymbolId) ?? null;
}

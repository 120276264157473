import Separator from "@editor/components/common/designSystem/Separator";
import DashboardMenuItem from "@editor/components/dashboard/DashboardMenuItem";
import { DashboardMenuItems } from "@editor/components/dashboard/DashboardMenuItems";
import WorkspaceCard from "@editor/components/dashboard/sidebar/WorkspaceCard";
import * as React from "react";
import { BsArrowUpRight } from "react-icons/bs";

const DashboardSidebar = () => {
  const bottomItems = [
    {
      title: "Learn Replo",
      href: "https://www.replo.app/learn",
      endEnhancer: () => <BsArrowUpRight />,
      className: "justify-between",
      textClassName: "text-black",
    },
    {
      title: "Find an Expert",
      href: "https://www.replo.app/experts",
      endEnhancer: () => <BsArrowUpRight />,
      className: "justify-between",
      textClassName: "text-black",
    },
    {
      title: "Share Product Feedback",
      href: "https://feedback.replo.app/?utm_source=dashboard",
      endEnhancer: () => <BsArrowUpRight />,
      className: "justify-between",
      textClassName: "text-black",
    },
  ];

  return (
    <div className="flex flex-col border-r border-slate-100 justify-between">
      <div className="px-3 gap-2 flex flex-col pt-5">
        <WorkspaceCard />
        <DashboardMenuItems />
      </div>
      <div className="flex px-3 flex-col gap-2 pb-4">
        <Separator />
        {bottomItems.map((item) => (
          <DashboardMenuItem key={item.title} item={item} />
        ))}
      </div>
    </div>
  );
};

export default DashboardSidebar;

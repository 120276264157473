import type { Component } from "replo-runtime/shared/Component";

type ComponentId = Component["id"];

export function getAccordionNewItems(
  newItemId: ComponentId,
  currentOpenItems?: ComponentId[],
  shouldAutoClose = true,
) {
  const newAccordionOpenItems = new Set<string>();

  const currentCollapsibleIsAlreadyOpen = currentOpenItems?.some(
    (id: string) => id === newItemId,
  );

  if (shouldAutoClose && !currentCollapsibleIsAlreadyOpen) {
    newAccordionOpenItems.add(newItemId);
  } else {
    currentOpenItems?.forEach((id: string) => {
      newAccordionOpenItems.add(id);
    });
    if (currentCollapsibleIsAlreadyOpen) {
      newAccordionOpenItems.delete(newItemId);
    } else {
      newAccordionOpenItems.add(newItemId);
    }
  }

  return Array.from(newAccordionOpenItems);
}

import { selectProductData } from "@editor/reducers/commerce-reducer";
import type { EditorRootState } from "@editor/store";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { ProductRef } from "replo-runtime/shared/types";

const initialState: { templateEditorProduct: ProductRef | null } = {
  templateEditorProduct: null,
};

const templateReducer = createSlice({
  name: "template",
  initialState,
  reducers: {
    setTemplateEditorProduct: (
      state,
      action: PayloadAction<ProductRef | null>,
    ) => {
      state.templateEditorProduct = action.payload;
    },
  },
});

export const selectTemplateEditorProduct = (state: EditorRootState) => {
  return state.template.templateEditorProduct;
};

export const selectTemplateEditorStoreProduct = createSelector(
  selectTemplateEditorProduct,
  selectProductData,
  (templateEditorProduct, productMap) => {
    return templateEditorProduct
      ? productMap[Number(templateEditorProduct.productId)]
      : undefined;
  },
);

const { reducer, actions } = templateReducer;
export const { setTemplateEditorProduct } = actions;
export default reducer;

import {
  useApplyComponentAction,
  type UseApplyComponentActionType,
} from "@editor/hooks/useApplyComponentAction";
import { selectDraftComponentTextTag } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import type { RichTextEditorTag } from "@editor/types/rich-text-editor";
import type { Editor } from "@tiptap/react";
import * as React from "react";

type RichTextComponentContext = {
  /**
   * the tipTapEditor instance being used by the RichTextControl (if it exists)
   */
  tipTapEditor: Editor | null;
  /**
   * sets the tipTapEditor instance being used in the RichTextControl. This
   * should only be set from the RichTextControl
   */
  setTipTapEditor: React.Dispatch<Editor | null>;

  /**
   * queues an action to be applied when the editor is updated. it is necessary
   * to use this when modifiers are changing the same component that the editor
   * will also be changing. examples include:
   * - setting text component color resets the inline text color
   * - setting text component fontStyles or textDecorations resets the inline
   *   text fontStyles/textDecoration
   * actions should only be queued when tipTapEditor is defined or they will not
   * be applied
   */
  queueAction: (action: UseApplyComponentActionType) => void;
  /**
   * clears all queued actions in the event that we want to cancel & apply them manually
   */
  clearQueuedActions: () => void;
  /**
   * applies all queued actions to the component with a composite action that
   * also updates the textValue
   */
  applyChanges: (value: string) => void;

  /**
   * the current text tag being used by the text being modified by the
   * RichTextControl (<p>, <h1>, etc)
   */
  currentTag: RichTextEditorTag | undefined;
  /**
   * sets the text tag being used by the text component being modified by the
   * RichTextControl (<p>, <h1>, etc)
   */
  setTag: React.Dispatch<RichTextEditorTag>;
  toggleBulletList: () => void;
  toggleNumberedList: () => void;
};

const RichTextComponentContext = React.createContext<
  RichTextComponentContext | undefined
>(undefined);

type RichTextComponentProviderProps = { children: React.ReactNode };

type Level = 1 | 2 | 3 | 4 | 5 | 6;

/**
 * Must wrap usage of the TextStyleModifier so that only one instance of the
 * tipTapEditor exists and can be accessed by other modifiers
 */
const RichTextComponentProvider: React.FC<RichTextComponentProviderProps> = ({
  children,
}) => {
  const [tipTapEditor, setTipTapEditor] = React.useState<Editor | null>(null);
  const queuedActionsRef = React.useRef<UseApplyComponentActionType[]>([]);

  const queueAction = React.useCallback(
    (action: UseApplyComponentActionType) => {
      queuedActionsRef.current = [...queuedActionsRef.current, action];
    },
    [],
  );

  const clearQueuedActions = React.useCallback(() => {
    queuedActionsRef.current = [];
  }, []);

  const currentTag = useEditorSelector(selectDraftComponentTextTag);

  const applyComponentAction = useApplyComponentAction();

  const applyChanges = React.useCallback(
    (value: string) => {
      const actions: UseApplyComponentActionType[] = [
        { type: "setProps", value: { text: value } },
        ...queuedActionsRef.current,
      ];
      clearQueuedActions();
      applyComponentAction({
        type: "applyCompositeAction",
        value: actions,
      });
    },
    [applyComponentAction, clearQueuedActions],
  );

  const setTag = React.useCallback(
    (newTag: string) => {
      if (newTag === "P") {
        tipTapEditor?.chain().focus().setParagraph().run();
      } else {
        tipTapEditor
          ?.chain()
          .focus()
          .setHeading({ level: Number.parseInt(newTag) as Level })
          .run();
      }
    },
    [tipTapEditor],
  );

  const toggleBulletList = React.useCallback(() => {
    tipTapEditor?.chain().toggleBulletList().run();
  }, [tipTapEditor]);

  const toggleNumberedList = React.useCallback(() => {
    tipTapEditor?.chain().toggleOrderedList().run();
  }, [tipTapEditor]);

  return (
    <RichTextComponentContext.Provider
      value={{
        tipTapEditor,
        setTipTapEditor,
        queueAction,
        clearQueuedActions,
        applyChanges,
        currentTag,
        setTag,
        toggleBulletList,
        toggleNumberedList,
      }}
    >
      {children}
    </RichTextComponentContext.Provider>
  );
};

/**
 * Provides the ability to set and access an instance of the TipTap editor so
 * that other components can interact with the same instance being used by the
 * RichTextConrol.
 */
function useRichTextComponent() {
  const context = React.useContext(RichTextComponentContext);
  if (context === undefined) {
    throw new Error(
      "useRichTextComponent must be used within a RichTextComponentProvider",
    );
  }
  return context;
}

export { RichTextComponentProvider, useRichTextComponent };

import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const dynamicBundle: ComponentTemplate = {
  id: "35c6a2e2-b0cd-407f-b673-ec1a277bffe6",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Rebuy/Rebuy Dynamic Bundle",
  leftBarCategory: { main: "pageIntegrations", subCategory: "rebuy" },
  preview: "rebuy/dynamic-bundle.svg",
  transforms: [],
  template: {
    id: "$uid",
    type: "rebuyWidget",
    props: {
      style: {
        alignSelf: "stretch",
        flexGrow: 1,
      },
    },
  },
};

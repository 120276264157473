import { useModal } from "@editor/hooks/useModal";
import { Button } from "@replo/design-system/components/button";
import * as React from "react";
import type { Integration } from "schemas/integration";

const ShopifyIntegrationCard = ({
  number,
  integration,
}: {
  number: number;
  integration: Integration;
}) => {
  const {
    id,
    workspaceId,
    shopifyIntegrationConfig: { shopifyUrl, name },
  } = integration;

  const { openModal } = useModal();

  function handleRemove() {
    openModal({
      type: "uninstallShopifyIntegrationModal",
      props: { workspaceId, integrationId: id },
    });
  }
  return (
    <div className="border rounded-lg p-4 mb-3 w-[600px] flex">
      <div className="bg-slate-800 text-blue-50 text-sm font-bold w-[30px] h-[30px] rounded-full flex items-center justify-center mr-4">
        {number}
      </div>
      <div className="mr-auto">
        <p className="text-base font-semibold">
          {name ?? shopifyUrl.replace(".myshopify.com", "")}
        </p>
        <p className="text-sm">{shopifyUrl}</p>
      </div>
      <Button variant="secondary" onClick={() => handleRemove()}>
        Remove
      </Button>
    </div>
  );
};

export default ShopifyIntegrationCard;

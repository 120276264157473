import type { ComponentTemplate } from "@editor/types/component-template";
import { Env } from "replo-runtime/shared/enums";

export const imageGrid: ComponentTemplate = {
  id: "a994da26-7624-48cc-91e1-538ae72673ac",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Basic/Image Grid",
  leftBarCategory: { main: "basic", subCategory: "core" },
  preview: "image-grid.svg",
  template: {
    id: "e089b1df-2b94-47d9-85d5-3a7c4f5d7c5d",
    type: "container",
    name: "Image Grid",
    props: {
      style: {
        display: "grid",
        alignItems: "center",
        justifyContent: "space-between",
        width: "50%",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        __numberOfColumns: 2,
        __flexGap: 0,
        columnGap: "15px",
        rowGap: "15px",
        flexGrow: 1,
        __width: "50%",
      },
    },
    children: [
      {
        id: "25c088cb-fd18-43e1-a982-759d541dfb58",
        type: "image",
        props: {
          style: {
            objectFit: "cover",
            __imageSource:
              "https://images.unsplash.com/photo-1504198266287-1659872e6590?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTd8fHxlbnwwfHx8fHw%3D",
            __imageAltText:
              "Close-up of a single green leaf against a white background.",
          },
          loading: "eager",
        },
        name: "Image 1",
        markers: {
          _aiGeneratedAltText: true,
        },
      },
      {
        id: "089a7ee5-a4d8-4c5c-aa3b-61e27d5e7821",
        type: "image",
        props: {
          style: {
            objectFit: "cover",
            __imageSource:
              "https://images.unsplash.com/photo-1504204267155-aaad8e81290d?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            __imageAltText:
              "A single green leaf in a transparent glass of water on a white background.",
          },
          loading: "eager",
        },
        name: "Image 2",
        markers: {
          _aiGeneratedAltText: true,
        },
      },
      {
        id: "021f4d9a-8526-4a6d-830f-da84ab50f1e3",
        type: "image",
        props: {
          style: {
            objectFit: "cover",
            __imageSource:
              "https://images.unsplash.com/photo-1471086569966-db3eebc25a59?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            __imageAltText:
              "Green leafy branch in a glass vase on a white background.",
          },
          loading: "eager",
        },
        name: "Image 3",
        markers: {
          _aiGeneratedAltText: true,
        },
      },
      {
        id: "154538b2-6662-423f-b122-15e3ffb566ba",
        type: "image",
        props: {
          style: {
            objectFit: "cover",
            __imageSource:
              "https://images.unsplash.com/photo-1655658233701-a0bada796e94?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Ds.unsplash.com/photo-1504204267155-aaad8e81290d?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            __imageAltText:
              "A green palm leaf next to two blank white cards on a white background.",
          },
          loading: "eager",
        },
        name: "Image 4",
        markers: {
          _aiGeneratedAltText: true,
        },
      },
    ],
  },
};

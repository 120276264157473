import {
  selectDraftElementId,
  selectDraftElementProjectId,
  selectDraftElementType,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import type { EditorRoute } from "@editor/utils/router";
import { routes } from "@editor/utils/router";
import useSetDraftElement from "@hooks/useSetDraftElement";
import * as React from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { validate as isUUIDValid } from "uuid";

// Make sure we sync the draft element with the url elementId
export const useSyncDraftElementWithRouter = () => {
  const navigate = useNavigate();
  const params = useParams();
  const setDraftElement = useSetDraftElement();
  const draftElementId = useEditorSelector(selectDraftElementId);
  const draftElementProjectId = useEditorSelector(selectDraftElementProjectId);
  const draftElementType = useEditorSelector(selectDraftElementType);

  React.useEffect(() => {
    const isValidElementId = params.elementId
      ? isUUIDValid(params.elementId)
      : undefined;
    // Note (Vicky 2023-12-12): If invalid element uuid and elementId param is not "add" path, then navigate to the store url.
    if (isValidElementId === false && params.elementId !== "add") {
      navigate(
        generatePath(routes.editor.project, {
          projectId: params.projectId,
        }),
        {
          replace: true,
        },
      );
    } else if (draftElementId && !params.elementId) {
      if (draftElementType === "shopifyProductTemplate" && params.productId) {
        navigate(
          generatePath(routes.editor.product, {
            projectId: params.projectId,
            elementId: draftElementId,
            productId: draftElementId,
          } as EditorRoute),
          {
            replace: true,
          },
        );
      } else if (draftElementProjectId === params.projectId) {
        navigate(
          generatePath(routes.editor.element, {
            projectId: params.projectId,
            elementId: draftElementId,
          } as EditorRoute),
          {
            replace: true,
          },
        );
      }
    } else if (
      params.elementId &&
      params.elementId !== draftElementId &&
      draftElementProjectId === params.projectId
    ) {
      setDraftElement({ id: params.elementId });
    }
  }, [
    params.elementId,
    draftElementId,
    params.projectId,
    params.productId,
    draftElementType,
    navigate,
    setDraftElement,
    draftElementProjectId,
  ]);
};

import Separator from "@editor/components/common/designSystem/Separator";
import Header from "@editor/components/dashboard/Header";
import { useGetIntegrationsQuery } from "@editor/reducers/sanity-reducer";
import { trpc } from "@editor/utils/trpc";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import * as React from "react";
import { generatePath, useParams } from "react-router-dom";

import { configurableIntegrations } from "../integrations/configurable-integrations";
import IntegrationCard from "../integrations/IntegrationCard";

const WorkspaceIntegrations: React.FC = () => {
  const { data: otherIntegrations } = useGetIntegrationsQuery({});
  const { workspaceId } = useParams();
  const { data: integrationsData } =
    trpc.integration.getIntegrationsByWorkspaceId.useQuery(
      workspaceId ? { workspaceId } : reactQuerySkipToken,
    );
  const integrationTypes =
    integrationsData?.integrations.map(({ type }) => type) ?? [];
  return (
    <div className="flex flex-col px-6 w-full">
      <Header
        title="Integrations"
        subtitle={"View and manage your workspace's Replo Integrations"}
      />
      <Separator className="my-4 col-span-12" />
      <div className="flex gap-4 mb-6">
        {configurableIntegrations.map((integration) => (
          <IntegrationCard
            key={integration.title}
            title={integration.title}
            image={integration.image}
            summary={integration.summary}
            redirectUrl={generatePath(integration.redirectUrl, { workspaceId })}
            isConnected={integrationTypes.includes(integration.type)}
            isConfigurable
          />
        ))}
      </div>
      <h3 className="font-semibold mb-2">Other Available Integrations</h3>
      <div className="flex gap-4 mb-4 flex-wrap">
        {otherIntegrations &&
          otherIntegrations.map((integration) => (
            <IntegrationCard
              key={integration.name}
              title={integration.name}
              image={integration.image}
              redirectUrl={integration.documentationUrl}
            />
          ))}
      </div>
    </div>
  );
};

export default WorkspaceIntegrations;

import type { Component } from "replo-runtime/shared/Component";
import { isEmptyOrAuto } from "replo-runtime/shared/utils/css";
import { mediaQueriesPassingTest } from "replo-runtime/store/utils/styles";

/**
 * This function loop into each media query and check is has a set width.
 *
 * @param component CarouselV3Slides component to check for defined widths (the children
 * of this component will be checked, because they're slides)
 */
export const mediaQueriesComponentHasDefinedWidth = (
  component: Component,
): (`@media ${string}` | "none")[] => {
  return mediaQueriesPassingTest(
    component,
    (styles) => !isEmptyOrAuto(styles?.width),
  );
};

/**
 * This function loop into each media query and check is has a set overflow and
 * that is not hidden.
 *
 * @param component CarouselV3Slides component to check for defined widths (the children
 * of this component will be checked, because they're slides)
 */
export const mediaQueriesComponentHasNotHiddenOverflow = (
  component: Component,
): (`@media ${string}` | "none")[] => {
  return mediaQueriesPassingTest(
    component,
    (styles) => styles?.overflow !== "hidden",
  );
};

export const carouselCssForSlideTrackComponent = (component: Component) => {
  const queries = mediaQueriesComponentHasNotHiddenOverflow(component);

  // Note (Fran, 2022-01-26): If the user wants to make the carousel overflow: visible
  // this will allow that. But we need to make sure that the part that is actually shown is
  // the part we want. That's why we put the overflow on each slide and in all the track elements.
  // We can't set overflow: visible in both axes in the track because otherwise
  // with clones and inifinite carousels we will see all the clones, and we don't
  // want that.

  const carouselOverflowStyles = `
      [data-rid="${component.id}"] .splide__track {
        overflow-y: visible !important;
        overflow-x: clip !important;
      }
      [data-rid="${component.id}"] .splide__slide {
        overflow: visible !important;
      }
    `;

  return queries
    .map((query) => {
      if (query === "none") {
        return carouselOverflowStyles;
      }
      return `${query} {
        ${carouselOverflowStyles}
      }`;
    })
    .join("\n");
};

import { useEditorDispatch, useEditorSelector } from "@editor/store";
import type { DropTarget } from "@editor/types/drop-target";
import { setDropTarget } from "@reducers/drag-and-drop-reducer";

export default function useDropTarget() {
  const dropTarget = useEditorSelector((state) => state.dragAndDrop.dropTarget);
  const dispatch = useEditorDispatch();

  return {
    dropTarget,
    setDropTarget: (dropTarget: DropTarget) =>
      dispatch(setDropTarget(dropTarget)),
  };
}

import { elementTypeToEditorData } from "@editor/components/editor/element";
import { getNewPagePlaceholderComponent } from "@editor/components/editor/templates/newPagePlaceholder";
import type { ComponentTemplate } from "@editor/types/component-template";
import { findAncestorComponentOrSelf } from "@utils/component";
import type { Component } from "replo-runtime/shared/Component";
import { type ReploComponentType } from "schemas/component";
import type { ReploElement, ReploElementType } from "schemas/element";

const blankPreviews: Record<ReploElementType, string> = {
  page: "blank-page.svg",
  shopifyArticle: "blank-post.svg",
  shopifySection: "blank-section.svg",
  shopifyProductTemplate: "",
};

const blankPreviewsSelected: Record<ReploElementType, string> = {
  page: "blank-page-filled.svg",
  shopifyArticle: "blank-post-filled.svg",
  shopifySection: "blank-section-filled.svg",
  shopifyProductTemplate: "",
};

export const getEmptyTemplate = (
  elementType: ReploElementType,
): ComponentTemplate => {
  return {
    id: `blank-${elementType ?? "page"}-template`,
    name: `Blank ${
      elementType
        ? elementTypeToEditorData[elementType].singularDisplayName
        : "Page"
    }`,
    scope: "store",
    env: "production",
    type:
      elementType && elementType === "shopifySection" ? elementType : "page",
    storeId: null,
    preview: blankPreviews[elementType],
    selectedPreview: blankPreviewsSelected[elementType],
    template: getNewPagePlaceholderComponent(elementType),
  };
};

export const getAiTemplate = (
  elementType: ReploElementType,
): ComponentTemplate => {
  return {
    id: `ai-${elementType ?? "page"}-template`,
    name: "Ask Replo AI",
    scope: "store",
    env: "production",
    type:
      elementType && elementType === "shopifySection" ? elementType : "page",
    storeId: null,
    preview: "replo-ai-page.svg",
    selectedPreview: "replo-ai-page-filled.svg",
    template: getNewPagePlaceholderComponent(elementType),
  };
};

/**
 * Returns a function for canBeAddedAsChild that returns true if the component has
 * an ancestor of a given type, or false with a specified message otherwise. This is
 * useful for checking if a template is being dragged into a product box.
 *
 * @param ancestorType The type of ancestor we're looking for
 * @param rejectionMessage The message to return if the template cannot be added
 */
export function getCanBeAddedFunction(
  ancestorType: ReploComponentType,
  rejectionMessage: string,
) {
  return (extras: {
    parent: Component | null;
    element: ReploElement;
  }): { canBeAdded: true } | { canBeAdded: false; message: string } => {
    const matchingAncestor = findAncestorComponentOrSelf(
      extras.element,
      extras.parent?.id ?? null,
      (c) => c.type === ancestorType,
    );
    if (!matchingAncestor) {
      return {
        canBeAdded: false,
        message: rejectionMessage,
      };
    }
    return { canBeAdded: true };
  };
}

export function getIsTemplateNewlyAdded(createdAt?: string): boolean {
  if (!createdAt) {
    return false;
  }

  const createdAtDate = new Date(createdAt).getTime();
  const today = new Date().getTime();
  const daysDifference = (today - createdAtDate) / (1000 * 60 * 60 * 24);
  return daysDifference <= 30;
}

import ReploAreaChart from "@replo/design-system/components/shadcn/AreaChart";
import ReploPieChart from "@replo/design-system/components/shadcn/PieChart";
import * as React from "react";

import PagesOverview from "./PagesOverview";
import ShopifyURL from "./selects/ShopifyURL";
import TimeFrame from "./selects/TimeFrame";

const Overview: React.FC = () => {
  return (
    <div className="relative min-h-screen max-w-xl xl:max-w-7xl pr-5">
      <div className="flex w-full flex-col">
        <div className="flex flex-row space-x-4">
          <TimeFrame />
          <ShopifyURL />
        </div>
        <div className="text-2xl font-semibold my-8">All Sessions</div>
        <div className="flex gap-5 flex-col xl:flex-row">
          <ReploAreaChart title="User Sessions" />
          <ReploPieChart title="Device Breakdown" />
        </div>
        <PagesOverview title="Top Pages" />
      </div>
    </div>
  );
};

export default Overview;

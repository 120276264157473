import {
  useInitCanvasElementsLoadingStateContext,
  useInitComponentErrorContext,
  useInitComponentInventoryContext,
  useInitComponentUpdateContext,
  useInitCustomFontsContext,
  useInitDataTablesContext,
  useInitDraftElementContext,
  useInitDynamicDataStoreContext,
  useInitEditorMediaUploadContext,
  useInitEditorSelectionContext,
  useInitExtraContext,
  useInitFeatureFlagsContext,
  useInitRenderedLiquidContext,
  useInitRenderEnvironmentContext,
  useInitReploEditorActiveCanvasContext,
  useInitReploEditorCanvasContext,
  useInitReploElementContext,
  useInitReploSymbolsContext,
  useInitRuntimeHooksContext,
  useInitSelectedRevisionContext,
  useInitSharedStateContext,
  useInitShopifyStoreContext,
  useInitSwatchesContext,
  useInitTemplateEditorProductContext,
} from "@editor/contexts/editor-runtime-context";
import { useDraftElementProductData } from "@editor/hooks/useStoreProducts";
import {
  getTargetFrameDocument,
  getTargetFrameWindow,
} from "@editor/hooks/useTargetFrame";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectComponentDataMapping,
  selectComponentIdsToStyle,
  selectElementWithRevisionState,
  selectStreamingUpdate,
  setComponentDataMappingStylesForChildren,
} from "@editor/reducers/core-reducer";
import type {
  EditorRepaintDependencies,
  PaintTypeResult,
} from "@editor/reducers/utils/core-reducer-utils";
import { editorNeedsRepaint } from "@editor/reducers/utils/core-reducer-utils";
import { store, useEditorDispatch, useEditorSelector } from "@editor/store";
import { findComponentById } from "@editor/utils/component";
import { injectStyleElementIntoDocument } from "@editor/utils/styles";
import * as React from "react";
import { Provider } from "react-redux";
import type {
  Component,
  ComponentDataMapping,
  ComponentStylesForChildren,
} from "replo-runtime/shared/Component";
import type { RuntimeContextValueMap } from "replo-runtime/shared/runtime-context";
import type { ComponentStyleRulesMapping } from "replo-runtime/shared/styles";
import { generateComponentStyleDataMapping } from "replo-runtime/shared/styles";
import { type RuntimeFeatureFlags } from "replo-runtime/shared/types";
import { getFullPageOffset } from "replo-runtime/shared/utils/dom";
import type { ElementToPaint } from "replo-runtime/store/paint";
import { paint } from "replo-runtime/store/paint";
import { isEmpty } from "replo-utils/lib/misc";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { ReploElement } from "schemas/element";
import type { ReploSymbol } from "schemas/symbol";

import {
  increasePaintVersion,
  selectCanvases,
  selectCanvasesIFrames,
  selectCanvasesKeys,
  selectCanvasLoadingType,
} from "./canvas-reducer";
import type { PartialCanvases } from "./canvas-types";

const WrappedInReduxProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return <Provider store={store}>{children}</Provider>;
};

/**
 * Hook for running paint on the targetFrame when necessary.
 */
export function usePaintTargetFrames() {
  const canvasesKeys = useEditorSelector(selectCanvasesKeys);
  const canvasFramesRef = React.useRef(
    canvasesKeys.reduce<typeof canvasesIframes>(
      (acc, canvas) => ({ ...acc, [canvas]: null }),
      {} as Record<EditorCanvas, HTMLIFrameElement | null>,
    ),
  );
  const previousRepaintDependenciesRef =
    React.useRef<EditorRepaintDependencies | null>(null);
  const currentRulesRef = React.useRef<ComponentStyleRulesMapping | undefined>(
    undefined,
  );

  const dispatch = useEditorDispatch();
  const canvasLoadingType = useEditorSelector(selectCanvasLoadingType);
  const canvasesIframes = useEditorSelector(selectCanvasesIFrames);
  const componentIdsToStyle = useEditorSelector(selectComponentIdsToStyle);
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);
  const { elementMapping, elementVersionMapping, elementRevisions } =
    useEditorSelector(selectElementWithRevisionState);
  const streamingUpdate = useEditorSelector(selectStreamingUpdate);
  const canvases = useEditorSelector(selectCanvases);
  const historyIndex = useEditorSelector((state) => state.core.history.index);
  const { products, productIds } = useDraftElementProductData();

  const renderEnvironmentContext = useInitRenderEnvironmentContext();
  const canvasElementsLoadingStateContext =
    useInitCanvasElementsLoadingStateContext();
  const componentErrorContext = useInitComponentErrorContext();
  const componentInventoryContext = useInitComponentInventoryContext();
  const componentUpdateContext = useInitComponentUpdateContext();
  const customFontsContext = useInitCustomFontsContext();
  const dataTablesContext = useInitDataTablesContext();
  const draftElementContext = useInitDraftElementContext();
  const dynamicDataStoreContext = useInitDynamicDataStoreContext();
  const shopifyStoreContext = useInitShopifyStoreContext();
  const extraContext = useInitExtraContext();
  const featureFlagsContext = useInitFeatureFlagsContext();
  const renderedLiquidContext = useInitRenderedLiquidContext();
  const reploEditorCanvasContext = useInitReploEditorCanvasContext();
  const reploEditorActiveCanvasContext =
    useInitReploEditorActiveCanvasContext();
  const reploElementContext = useInitReploElementContext();
  const reploSymbolsContext = useInitReploSymbolsContext();
  const selectedRevisionContext = useInitSelectedRevisionContext();
  const selectionContext = useInitEditorSelectionContext();
  const sharedStateContext = useInitSharedStateContext();
  const swatchesContext = useInitSwatchesContext();
  const templateEditorProductContext = useInitTemplateEditorProductContext();
  const editorMediaUploadContext = useInitEditorMediaUploadContext();
  const runtimeHooksContext = useInitRuntimeHooksContext();

  const {
    productMetafieldValues,
    variantMetafieldValues,
    storeId,
    storeUrl,
    activeCurrency: currencyCode,
  } = shopifyStoreContext;
  const { renderEnvironment: renderContext } = renderEnvironmentContext;
  const { isContentEditing, onSubmitContentEditableTextUpdate } =
    componentUpdateContext;
  const { sharedState, setSharedState, setEditorReadableState } =
    sharedStateContext;
  const { templateEditorProduct } = templateEditorProductContext;
  const {
    draftElementId,
    draftElementComponentId,
    draftRepeatedIndex,
    draftSymbolInstanceId,
    draftSymbolId,
  } = draftElementContext;
  const { selectedRevisionId } = selectedRevisionContext;
  const { state: canvasLoadingState } = canvasElementsLoadingStateContext;
  const { onComponentError } = componentErrorContext;
  const { uploadedFonts } = customFontsContext;
  const {
    requestRenderLiquid,
    cache: renderedLiquidCache,
    requestsInProgress: liquidRequestsInProgress,
  } = renderedLiquidContext;
  const { mapping: dataTableMapping } = dataTablesContext;
  const { swatches } = swatchesContext;
  const { featureFlags } = featureFlagsContext;
  const { symbols } = reploSymbolsContext;
  const { editorMediaUploadingComponentIds } = editorMediaUploadContext;

  const draftElement = draftElementId
    ? elementMapping[draftElementId] ?? null
    : null;
  const isMultiCanvasEnabled = isFeatureEnabled("multiple-canvases");

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    const dataTables = {
      mapping: dataTableMapping,
    };
    const elements = {
      draftElementId,
      draftComponentId: draftElementComponentId,
      draftRepeatedIndex,
      draftSymbolInstanceId,
      draftSymbolId,
      mapping: elementMapping,
      versionMapping: elementVersionMapping,
      elementRevisions,
      selectedRevisionId,
      isLoading: canvasLoadingState === "loading",
      streamingUpdate,
    };
    const repaintDependencies: EditorRepaintDependencies = {
      dataTables,
      elements,
      historyIndex,
      isContentEditing,
      liquidRequestsInProgress,
      productIds,
      productMetafieldValues,
      renderedLiquidCache,
      selectedRevisionId,
      sharedState,
      storeId,
      storeUrl,
      swatches,
      templateEditorProduct,
      variantMetafieldValues,
      elementId: draftElementId,
      editorMediaUploadingComponentIds,
    };

    let isSafeToPaint: boolean;
    if (isMultiCanvasEnabled) {
      // NOTE (Martin, 2024-08-07): we need to check all canvases because
      // we are painting all of them, even the non-visible ones. Those are then
      // hidden with CSS.
      isSafeToPaint = Object.values(canvases).every((canvas) => {
        const frame = canvas.targetFrame ?? null;
        // NOTE (Matt 2023-10-23): It is possible for targetFrameWindow to be null
        // for only a brief moment when a user switches the element type they are
        // editing (ie switching from a "Page" element to a "Section"). It is okay
        // to not throw an error here, as the same check is made in useFrameOnLoad
        // and an error is correctly thrown at that time.
        const frameWindow = frame ? getTargetFrameWindow(frame) : null;
        return Boolean(frameWindow);
      });
    } else {
      const frame = canvasesIframes.desktop;
      const frameWindow = frame ? getTargetFrameWindow(frame) : null;
      isSafeToPaint = Boolean(frameWindow);
    }

    const previousTargetFrames = canvasFramesRef.current;
    const previousRepaintDependencies = previousRepaintDependenciesRef.current;
    canvasFramesRef.current = canvasesIframes;
    previousRepaintDependenciesRef.current = repaintDependencies;
    if (!isSafeToPaint) {
      return;
    }

    const elementToPaint = getElementToPaint(draftElement);
    const needsRepaint = editorNeedsRepaint(
      previousRepaintDependencies,
      repaintDependencies,
    );

    // Note (Evan, 2024-07-18): We call execUpdateElementStylesheets in the
    // same cases in which we repaint - paintType is not "none" or the target frame
    // is new. In the multicanvas case, we check that any target frame is new.
    if (isMultiCanvasEnabled) {
      const isAnyCanvasNewTargetFrame = Object.entries(canvases).some(
        ([key, canvas]) =>
          canvas.targetFrame !== previousTargetFrames[key as EditorCanvas],
      );

      if (isAnyCanvasNewTargetFrame || needsRepaint.paintType !== "none") {
        const targetFramesToStyle = [];
        for (const [key, { targetFrame }] of Object.entries(canvases)) {
          if (!targetFrame) {
            continue;
          }

          targetFramesToStyle.push(targetFrame);
          execPaint(
            key as EditorCanvas,
            targetFrame,
            previousTargetFrames[key as EditorCanvas],
            needsRepaint,
          );
        }
        dispatch(increasePaintVersion());
        execUpdateElementStylesheets(
          Object.fromEntries(
            Object.entries(canvases).filter(([, canvas]) => {
              return Boolean(canvas.targetFrame);
            }),
          ),
        );
      }
    } else {
      const targetFrame = canvasesIframes.desktop!;
      const previousTargetFrame = previousTargetFrames.desktop;
      execPaint("desktop", targetFrame, previousTargetFrame, needsRepaint);
      if (
        needsRepaint.paintType !== "none" ||
        previousTargetFrame !== targetFrame
      ) {
        dispatch(increasePaintVersion());
        if (canvases.desktop.targetFrame) {
          execUpdateElementStylesheets({ desktop: canvases.desktop });
        }
      }
    }

    function execPaint(
      canvas: EditorCanvas,
      targetFrame: HTMLIFrameElement,
      previousTargetFrame: HTMLIFrameElement | null,
      needsRepaint: PaintTypeResult,
    ) {
      // NOTE (Chance 2024-06-25): Non-null assertion is safe here because we
      // checked for the target frame window before exiting via `isSafeToPaint`
      const targetFrameWindow = getTargetFrameWindow(targetFrame)!;

      const contexts: RuntimeContextValueMap = {
        canvasElementsLoadingState: canvasElementsLoadingStateContext,
        componentError: componentErrorContext,
        componentInventory: componentInventoryContext,
        componentUpdate: componentUpdateContext,
        customFonts: customFontsContext,
        dataTables: dataTablesContext,
        draftElement: draftElementContext,
        dynamicDataStore: dynamicDataStoreContext,
        shopifyStore: shopifyStoreContext,
        extraContext: extraContext,
        featureFlags: featureFlagsContext,
        renderedLiquid: renderedLiquidContext,
        // NOTE (Chance 2024-06-25): This is a hack to get around the fact
        // that we are initializing context outside of a specific canvas, so
        // we don't know the target window or canvas until now.
        globalWindow: targetFrameWindow,
        editorCanvas: canvas,
        renderEnvironment: renderEnvironmentContext,
        reploEditorCanvas: reploEditorCanvasContext,
        reploEditorActiveCanvas: reploEditorActiveCanvasContext,
        reploElement: reploElementContext,
        reploSymbols: reploSymbolsContext,
        selectedRevision: selectedRevisionContext,
        editorSelection: selectionContext,
        sharedState: sharedStateContext,
        swatches: swatchesContext,
        templateEditorProduct: templateEditorProductContext,
        editorMediaUpload: editorMediaUploadContext,
        runtimeHooks: runtimeHooksContext,
      };

      // Paint is required initially, when targetFrame has just been loaded.
      // Note (Noah, 2022-02-01): If the iframe was updated to a different element
      // (which happens sometimes, for example if this component is HMR updated)
      // then we always repaint
      if (previousTargetFrame !== targetFrame) {
        paint({
          targetWindow: targetFrameWindow,
          elements: Object.values(repaintDependencies.elements.mapping),
          elementToPaint,
          // Note (Mariano, 2022-05-26): if we are here it means the iframe was
          // re-mounted, probably due to a new call to mirror which means we are
          // loading everything from scratch, so it makes sense to force the
          // remount. Otherwise we won't see any element in the canvas
          forceRemount: true,
          // TODO (Noah, 2024-07-03, REPL-12467): Simplify the types to make null make sense here
          // @ts-expect-error
          contexts,
          ProviderComponent: WrappedInReduxProvider,
        });
      } else {
        if (needsRepaint.paintType === "none") {
          return;
        }

        const forceRemount =
          needsRepaint.paintType === "all" ? needsRepaint.forceRemount : false;

        paint({
          targetWindow: targetFrameWindow,
          elements: Object.values(repaintDependencies.elements.mapping),
          elementToPaint,
          forceRemount,
          // TODO (Noah, 2024-07-03, REPL-12467): Simplify the types to make null make sense here
          // @ts-expect-error
          contexts,
          ProviderComponent: WrappedInReduxProvider,
        });
      }
    }

    function execUpdateElementStylesheets(canvases: PartialCanvases) {
      if (elementToPaint.type === "specificElement") {
        // Note (Chance 2023-07-17, REPL-7972) When switching between a page and a
        // section, a new canvas is created and we need to make sure it's finished
        // loading before we try to update the stylesheets. Otherwise the new
        // styles will be attached to the document from the old canvas and the new
        // page will end up without styles.
        if (canvasLoadingType === "loaded") {
          // NOTE (Chance 2024-06-25): Non-null assertion is safe here because we
          // checked for the target frame window before exiting via `isSafeToPaint`
          const { rules, stylesForChildrenMapping } =
            updateElementStylesheets({
              draftElement:
                repaintDependencies.elements.mapping[
                  elementToPaint.specificElementId!
                ]!,
              symbols,
              currentRules: currentRulesRef.current,
              featureFlags,
              canvases,
              componentIds: componentIdsToStyle,
              componentDataMapping,
            }) ?? {};

          currentRulesRef.current = rules;

          if (!isEmpty(stylesForChildrenMapping)) {
            dispatch(
              setComponentDataMappingStylesForChildren(
                stylesForChildrenMapping,
              ),
            );
          }
        } else {
          currentRulesRef.current = undefined;
        }
      }
    }

    // NOTE (Chance 2024-03-28): EXTREMELY IMPORTANT: We should *not* be
    // ignoring this, but since we're constructing new context objects in the
    // effect we need to be careful not to trigger unwanted re-runs of `paint`.
    // If new dependencies are introduced to this effect, make sure to update
    // the dependency array below, as the linter won't catch it. Once we
    // completely eliminate `renderExtras` and `repaintDependencies` we should
    // be able to remove this before the hook itself is removed.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canvasLoadingState,
    canvasLoadingType,
    componentDataMapping,
    canvasesIframes,
    componentIdsToStyle,
    currencyCode,
    dataTableMapping,
    draftElement,
    draftElementComponentId,
    draftElementId,
    draftRepeatedIndex,
    draftSymbolId,
    draftSymbolInstanceId,
    editorMediaUploadingComponentIds,
    elementMapping,
    elementRevisions,
    elementVersionMapping,
    featureFlags,
    historyIndex,
    isContentEditing,
    isMultiCanvasEnabled,
    liquidRequestsInProgress,
    onComponentError,
    onSubmitContentEditableTextUpdate,
    productIds,
    productMetafieldValues,
    products,
    renderContext,
    renderedLiquidCache,
    requestRenderLiquid,
    selectedRevisionId,
    selectionContext,
    setEditorReadableState,
    setSharedState,
    sharedState,
    streamingUpdate,
    storeId,
    storeUrl,
    swatches,
    symbols,
    templateEditorProduct,
    uploadedFonts,
    variantMetafieldValues,
  ]);
}

function getElementToPaint(draftElement: ReploElement | null) {
  return {
    type: "specificElement" as const,
    specificShopifyPageId: draftElement?.shopifyPageId,
    specificElementId: draftElement?.id,
  } satisfies ElementToPaint;
}

function updateElementStylesheets(opts: {
  draftElement: ReploElement;
  symbols: ReploSymbol[];
  featureFlags: RuntimeFeatureFlags;
  canvases: PartialCanvases;
  componentDataMapping: ComponentDataMapping;
  currentRules?: ComponentStyleRulesMapping;
  componentIds?: string[];
}) {
  const {
    draftElement,
    symbols,
    canvases,
    featureFlags,
    componentIds,
    componentDataMapping,
  } = opts;
  const rootComponent = draftElement?.component;

  if (!rootComponent) {
    return;
  }

  const newRules: ComponentStyleRulesMapping = {};
  const buildConfig = {
    isEditor: true,
    symbols,
    featureFlags,
  };

  const stylesForChildrenMapping: Record<
    Component["id"],
    ComponentStylesForChildren
  > = {};

  const firstCanvasFrame = Object.values(canvases)[0]?.targetFrame;
  const canvasDocument = firstCanvasFrame
    ? getTargetFrameDocument(firstCanvasFrame)
    : null;
  const fullPageOffset = canvasDocument ? getFullPageOffset(canvasDocument) : 0;

  if (componentIds && componentIds.length > 0) {
    const styledComponentIds = new Set();
    // NOTE (Martin, 2024-05-27): componentIds might have repeated values for
    // some actions like "Group Into Container" so we create a new in order
    // to be sure that we don't run the loop below unnecessarily.
    const uniqueComponentIds = new Set(componentIds);
    for (const componentId of uniqueComponentIds) {
      // NOTE (Martin, 2024-04-30): this guard optimizes re-styling by
      // preventing style trees that have been already recreated to be
      // done again for no reason. This happens when an ancestor has already
      // triggered a restyle for a child component.
      if (!styledComponentIds.has(componentId)) {
        // Note (Evan, 2024-08-21): We cannot use the componentMapping here
        // (as things currently stand) because it doesn't reflect AI streaming updates.
        const component = findComponentById(draftElement, componentId);
        if (component) {
          const componentData = componentDataMapping[componentId];
          const parentComponentStylesForChildren = componentData?.parentId
            ? componentDataMapping[componentData.parentId]?.stylesForChildren ??
              null
            : null;
          const parsedParentComponentStylesForChildren =
            parentComponentStylesForChildren
              ? (JSON.parse(
                  parentComponentStylesForChildren,
                ) as ComponentStylesForChildren)
              : null;

          const componentStyleDataMapping = generateComponentStyleDataMapping(
            component,
            {
              componentContext: {
                isRoot: componentData?.parentId === null,
                parentHasVariants: componentData?.ancestorHasVariants ?? false,
                parentOverrideStyleRules:
                  parsedParentComponentStylesForChildren?.parentOverrideStyleRules,
                parentStyleProps:
                  parsedParentComponentStylesForChildren?.parentStyleProps ??
                  null,
                fullPageOffset,
              },
              buildConfig,
              overridesRegistry: {},
            },
          );

          for (const [componentId, componentStyleData] of Object.entries(
            componentStyleDataMapping,
          )) {
            const { styleRulesMapping, childComponentContext } =
              componentStyleData;
            if (styleRulesMapping) {
              for (const [key, canvas] of Object.entries(canvases)) {
                const paintableDocument = getTargetFrameDocument(
                  canvas.targetFrame!,
                )!;
                injectStyleElementIntoDocument({
                  document: paintableDocument,
                  documentKey: key,
                  componentId,
                  rules: styleRulesMapping,
                });
              }

              if (
                childComponentContext?.parentStyleProps ||
                childComponentContext?.parentOverrideStyleRules
              ) {
                stylesForChildrenMapping[componentId] = {
                  parentStyleProps:
                    childComponentContext.parentStyleProps ?? null,
                  parentOverrideStyleRules:
                    childComponentContext.parentOverrideStyleRules ?? null,
                };
              }

              styledComponentIds.add(componentId);
            }
          }
        }
      }
    }
  } else {
    const componentStyleDataMapping = generateComponentStyleDataMapping(
      rootComponent,
      {
        componentContext: {
          isRoot: true,
          // TODO (Chance 2024-01-30): The component property on ReploElement is
          // inferred from the `reploComponentSchema` which is incomplete. Once that
          // schema is updated to include the `variants` property, this should
          // surface as a type error and we can remove the directive.
          parentHasVariants: (rootComponent.variants?.length ?? 0) > 0,
          parentStyleProps: null,
          parentOverrideStyleRules: null,
          fullPageOffset,
        },
        buildConfig,
        overridesRegistry: {},
      },
    );

    for (const [componentId, componentStyleData] of Object.entries(
      componentStyleDataMapping,
    )) {
      const { styleRulesMapping, childComponentContext } = componentStyleData;
      if (styleRulesMapping) {
        for (const [key, canvas] of Object.entries(canvases)) {
          const paintableDocument = getTargetFrameDocument(
            canvas.targetFrame!,
          )!;
          injectStyleElementIntoDocument({
            document: paintableDocument,
            documentKey: key,
            componentId,
            rules: styleRulesMapping,
          });
        }

        if (
          childComponentContext?.parentStyleProps ||
          childComponentContext?.parentOverrideStyleRules
        ) {
          stylesForChildrenMapping[componentId] = {
            parentStyleProps: childComponentContext.parentStyleProps ?? null,
            parentOverrideStyleRules:
              childComponentContext.parentOverrideStyleRules ?? null,
          };
        }
      }
    }
  }

  return { rules: newRules, stylesForChildrenMapping };
}

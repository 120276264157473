import * as React from "react";

type ModifierLabelProps = {
  label: string;
};

const ModifierLabel: React.FC<ModifierLabelProps> = ({ label }) => {
  return (
    <div className="text-xs text-muted whitespace-nowrap select-none">
      {label}
    </div>
  );
};

export default ModifierLabel;

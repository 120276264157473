import type { ComponentTemplate } from "@editor/types/component-template";
import set from "lodash-es/set";
import { Env } from "replo-runtime/shared/enums";

export const subscribeAndSave: ComponentTemplate = {
  id: "25299112-b0aa-4528-a2cc-bedcb4c8968f",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Subscribe & Save",
  leftBarCategory: { main: "product", subCategory: "sellingPlans" },
  preview: "product/subscribeAndSave.svg",
  transforms: [
    (component, parent) => {
      if (parent?.type === "container" && parent.children?.length === 0) {
        set(component, "props.style.flexGrow", 1);
      }
      return component;
    },
  ],
  template: {
    id: "7994ac50-51fd-4169-97e9-d645e0988101",
    name: "Subscribe & Save",
    type: "selectionList",
    props: {
      style: {
        __width: "50%",
        display: "flex",
        __flexGap: "10px",
        alignSelf: "stretch",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-between",
      },
      _accessibilitySelection: true,
    },
    children: [
      {
        id: "7fabe257-b372-4eda-877d-fee319ccca68",
        type: "container",
        props: {
          style: {
            color: "#000000FF",
            display: "flex",
            flexGrow: 1,
            alignSelf: "stretch",
            paddingTop: "20px",
            paddingLeft: "32px",
            paddingRight: "32px",
            paddingBottom: "20px",
            borderTopColor: "#000000",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            backgroundColor: "#FFFFFFFF",
            borderLeftColor: "#000000",
            borderLeftStyle: "solid",
            borderLeftWidth: "1px",
            borderRightColor: "#000000",
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderBottomColor: "#000000",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
            alignItems: "center",
            justifyContent: "flex-start",
            __flexGap: "10px",
          },
          onClick: [
            {
              id: "9cb4e5ba-0865-4114-965c-30da52486364",
              type: "setActiveSellingPlan",
              value: {
                sellingPlanId: {
                  __reploOneTimePurchase: true,
                },
              },
            },
          ],
        },
        children: [
          {
            id: "c9377dd3-d747-4291-91f6-15bf7a31a73d",
            type: "text",
            props: {
              text: "<p>One-time purchase</p>",
              style: {
                fontSize: "20px",
                lineHeight: "20px",
                letterSpacing: "2px",
              },
            },
          },
          {
            id: "a3f0faaa-6f17-487e-a41d-4fb4805c4a82",
            type: "text",
            props: {
              text: "{{attributes._variant.displayPriceWithoutSellingPlanDiscount}}",
              style: {
                fontSize: "16px",
                alignSelf: "stretch",
                textAlign: "left",
                fontFamily: "ITCGaramondStd-LtCond, sans-serif",
                fontWeight: "700",
                lineHeight: "22px",
              },
              "style@md": {
                fontSize: "14px",
                lineHeight: "18px",
              },
            },
            name: "Price without Selling Plan",
          },
        ],
        variants: [
          {
            id: "8609cf54-3d29-4e66-ab11-303b8f768e33",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "601cfe34-237d-4c92-b491-6b9015690ad6",
            name: "Selected List Item",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "fde26c5f-3ebe-4607-965b-f0e4aa350826",
                  field: "state.selectionList.isItemSelected",
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "601cfe34-237d-4c92-b491-6b9015690ad6": {
            componentOverrides: {
              "7fabe257-b372-4eda-877d-fee319ccca68": {
                props: {
                  style: {
                    color: null,
                    borderColor: null,
                    borderStyle: null,
                    borderWidth: null,
                    borderTopColor: "#000000",
                    borderTopStyle: "solid",
                    borderTopWidth: "3px",
                    backgroundColor: null,
                    borderLeftColor: "#000000",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "3px",
                    borderRightColor: "#000000",
                    borderRightStyle: "solid",
                    borderRightWidth: "3px",
                    borderBottomColor: "#000000",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "3px",
                  },
                },
              },
            },
          },
        },
        name: "One-time Purchase",
      },
      {
        id: "0d192b9c-abe3-4a22-9190-2d59ecc68966",
        name: "Subscribe",
        type: "container",
        props: {
          style: {
            color: "#000000FF",
            display: "flex",
            maxWidth: "100%",
            alignSelf: "stretch",
            alignItems: "center",
            paddingTop: "20px",
            paddingLeft: "32px",
            paddingRight: "32px",
            paddingBottom: "20px",
            borderTopColor: "#000000",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            justifyContent: "center",
            backgroundColor: "#FFFFFFFF",
            borderLeftColor: "#000000",
            borderLeftStyle: "solid",
            borderLeftWidth: "1px",
            borderRightColor: "#000000",
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderBottomColor: "#000000",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
            __animateVariantTransitions: false,
            flexDirection: "column",
            cursor: "default",
          },
        },
        children: [
          {
            id: "4bd3843a-8d69-4b42-80a7-7191ab7a25c3",
            type: "container",
            props: {
              style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                maxWidth: "100%",
                alignSelf: "stretch",
                __flexGap: "10px",
              },
            },
            children: [
              {
                id: "feb5d57e-f63c-4ae7-b5c6-84ffa70863e9",
                type: "text",
                props: {
                  text: "<p>Subscribe and Save</p>",
                  style: {
                    fontSize: "20px",
                    lineHeight: "20px",
                    letterSpacing: "2px",
                  },
                },
              },
              {
                id: "36745497-8734-4e4a-a199-60cd4aa1b2f2",
                type: "text",
                props: {
                  text: "{{attributes._variant.displayPrice}}",
                  style: {
                    fontSize: "16px",
                    alignSelf: "stretch",
                    textAlign: "left",
                    fontWeight: "700",
                    lineHeight: "22px",
                  },
                  "style@md": {
                    fontSize: "14px",
                    lineHeight: "18px",
                  },
                },
                name: "Price",
              },
            ],
            name: "Title and Price",
          },
          {
            id: "c75f728f-cd03-4e34-a6a2-89df4e113186",
            name: "Selling Plan List Dropdown Container",
            type: "container",
            props: {
              style: {
                color: "#000000FF",
                display: "none",
                flexGrow: 1,
                maxWidth: "100%",
                __display: "flex",
                __flexGap: "24px",
                alignSelf: "stretch",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
                backgroundColor: "#FCFCFCFF",
              },
              "style@md": {
                display: "none",
                __display: "flex",
              },
              "style@sm": {
                display: "none",
                __display: "flex",
              },
            },
            children: [
              {
                id: "73ae7dac-1667-4828-8d4e-b28cc1e3383b",
                name: "Selling Plan Dropdown 2",
                type: "sellingPlanSelectDropdown",
                props: {
                  style: {
                    zIndex: "2",
                    fontSize: "16px",
                    alignSelf: "stretch",
                    fontWeight: 700,
                    paddingTop: "12px",
                    borderColor: "rgb(0, 0, 0)",
                    borderWidth: "1px",
                    paddingLeft: "12px",
                    paddingRight: "24px",
                    paddingBottom: "12px",
                    borderTopStyle: "solid",
                    borderLeftStyle: "solid",
                    borderRightStyle: "solid",
                    borderBottomStyle: "solid",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                  },
                  _includeOneTimePurchase: false,
                  _hideDefaultArrowDisplay: true,
                },
              },
              {
                id: "e9c322dd-84b9-43a6-b859-89401dc58dd0",
                name: "Arrow",
                type: "icon",
                props: {
                  style: {
                    top: "50%",
                    right: "2px",
                    width: "24px",
                    height: "24px",
                    zIndex: 1,
                    position: "absolute",
                    __transform: {
                      skewX: "0deg",
                      skewY: "0deg",
                      scaleX: "100%",
                      scaleY: "100%",
                      scaleZ: "100%",
                      rotateX: "0deg",
                      rotateY: "0deg",
                      rotateZ: "0deg",
                      translateX: "0px",
                      translateY: "-50%",
                      translateZ: "0px",
                    },
                  },
                  iconName: "chevron-down",
                },
              },
            ],
          },
        ],
        variants: [
          {
            id: "e1661c5d-651b-4111-a603-3a1dc4229187",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "cd652669-fc0f-477d-aea3-2b6362451e4d",
            name: "Selected List Item",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "3c43ef1c-4465-44f5-942d-5653e84ee6b1",
                  field: "state.selectionList.isItemSelected",
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "cd652669-fc0f-477d-aea3-2b6362451e4d": {
            componentOverrides: {
              "c75f728f-cd03-4e34-a6a2-89df4e113186": {
                props: {
                  style: {
                    display: "flex",
                  },
                  "style@md": {
                    display: "flex",
                  },
                  "style@sm": {
                    display: "flex",
                  },
                },
              },
              "0d192b9c-abe3-4a22-9190-2d59ecc68966": {
                props: {
                  style: {
                    color: null,
                    __flexGap: "10px",
                    backgroundColor: null,
                    borderTopWidth: "3px",
                    borderRightWidth: "3px",
                    borderBottomWidth: "3px",
                    borderLeftWidth: "3px",
                    borderWidth: null,
                    borderTopColor: "#000",
                    borderRightColor: "#000",
                    borderBottomColor: "#000",
                    borderLeftColor: "#000",
                    borderColor: null,
                    borderTopStyle: "solid",
                    borderRightStyle: "solid",
                    borderBottomStyle: "solid",
                    borderLeftStyle: "solid",
                    borderStyle: null,
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  },
                },
              },
              "4bd3843a-8d69-4b42-80a7-7191ab7a25c3": {
                props: {
                  style: {
                    alignItems: "center",
                    justifyContent: "flex-start",
                    __flexGap: "10px",
                    display: "flex",
                    flexDirection: "row",
                  },
                },
              },
              "feb5d57e-f63c-4ae7-b5c6-84ffa70863e9": {
                props: {
                  style: {
                    width: "auto",
                    flexGrow: "unset",
                  },
                },
              },
              "36745497-8734-4e4a-a199-60cd4aa1b2f2": {
                props: {
                  style: {
                    height: "auto",
                  },
                },
              },
              "73ae7dac-1667-4828-8d4e-b28cc1e3383b": {
                props: {
                  style: {
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};

import Switch from "@common/designSystem/Switch";
import DynamicDataButton from "@editor/components/common/designSystem/DynamicDataButton";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import { useModal } from "@editor/hooks/useModal";
import { docs } from "@editor/utils/docs";
import { getSellingPlanOptionsFromProduct } from "@editor/utils/sellingPlans";
import SelectablePopover from "@editorComponents/SelectablePopover";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";
import * as React from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import type { ReploShopifyProduct } from "replo-runtime/shared/types";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";

type SellingPlansSelectorProps = {
  product: ReploShopifyProduct;
  sellingPlanId: string | number | null;
  allowThirdPartySellingPlan?: boolean;
  onChange: (sellingPlanId: string | number | null) => void;
  onRemove(): void;
  onChangeThirdPartySellingPlan: (checked: boolean) => void;
};

export const SellingPlansSelector: React.FC<SellingPlansSelectorProps> = ({
  product,
  sellingPlanId,
  allowThirdPartySellingPlan,
  onChangeThirdPartySellingPlan,
  onChange,
  onRemove,
}) => {
  const sellingPlanOptions = getSellingPlanOptionsFromProduct(product, false);
  const selectedSellingPlan = sellingPlanOptions.find(
    (option) => Number(option.value) === Number(sellingPlanId),
  );

  const modal = useModal();

  const _openDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        excludedAttributeKeys: ["_variant"],
        requestType: "prop",
        targetType: DynamicDataTargetType.SELLING_PLAN,
        referrerData: {
          type: "callback",
          onChange: (sellingPlanId: string) => {
            onChange(sellingPlanId);
          },
        },
      },
    });
  };

  if (sellingPlanOptions.length === 0) {
    return null;
  }

  return (
    <LabeledControl label="Selling Plan" size="sm">
      {isDynamicDataValue(String(sellingPlanId)) ? (
        <DynamicDataValueIndicator
          type="other"
          templateValue={
            sellingPlanId && isDynamicDataValue(String(sellingPlanId))
              ? String(sellingPlanId)
              : "{{attributes_selectedSellingPlan}}"
          }
          onClick={_openDynamicData}
          onRemove={onRemove}
        />
      ) : (
        <div className="flex gap-2">
          <div className="flex-1">
            <SelectablePopover
              title="Selling Plans"
              itemSize={28}
              itemsOnViewCount={5}
              selectedItems={sellingPlanId ? [sellingPlanId] : []}
              options={sellingPlanOptions}
              isRemovable={Boolean(sellingPlanId)}
              onSelect={(newSellingPlanId: number) => {
                onChange(newSellingPlanId);
              }}
            >
              <span className="text-slate-600">
                {selectedSellingPlan
                  ? selectedSellingPlan.label
                  : "Select Selling Plan"}
              </span>
            </SelectablePopover>
          </div>
          <DynamicDataButton
            onClick={(e) => {
              e.stopPropagation();
              _openDynamicData();
            }}
            tooltipText="Use dynamic value"
          />
        </div>
      )}
      <div className="mt-1 flex w-full flex-row justify-between">
        <span className="text-xs font-normal text-slate-400 w-4/5">
          Allow third-party embeds to override selling plan?{" "}
          <a
            href={docs.sellingPlans.thirdPartyAtc}
            className="inline-flex cursor-pointer items-center text-xs text-blue-600"
            target="_blank"
            rel="noreferrer"
            aria-label="Learn More"
          >
            <BsInfoCircleFill aria-hidden />
          </a>
        </span>
        <Switch
          isOn={
            allowThirdPartySellingPlan ||
            allowThirdPartySellingPlan == undefined
          }
          onChange={onChangeThirdPartySellingPlan}
          thumbColor="#fff"
          backgroundOnColor="bg-blue-600"
        />
      </div>
    </LabeledControl>
  );
};

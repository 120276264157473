import {
  getBoxShadowObject,
  getBoxShadowString,
} from "@editor/components/editor/page/element-editor/components/modifiers/BoxShadowModifier";
import {
  getTextOutlineObject,
  getTextOutlineString,
} from "@editor/components/editor/page/element-editor/components/modifiers/TextOutlineModifier";
import {
  getTextShadowObject,
  getTextShadowString,
} from "@editor/components/editor/page/element-editor/components/modifiers/TextShadowModifier";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectBackgroundSize,
  selectBoxShadow,
  selectDraftComponent,
  selectDraftComponentId,
  selectDraftElementId,
  selectDraftRepeatedIndex,
  selectHasBorderStyles,
  selectObjectPosition,
  selectTextOutline,
  selectTextShadow,
  selectTransform,
} from "@editor/reducers/core-reducer";
import type { EditorStore } from "@editor/store";
import { useEditorStore } from "@editor/store";
import * as React from "react";
import type { RuntimeStyleAttribute } from "replo-runtime/shared/styleAttribute";
import {
  editorCanvasToMediaSize,
  getStylePropsFromComponent,
  MediaSizeToEditorCanvas,
} from "replo-runtime/shared/utils/breakpoints";
import type {
  PreviewableProperty,
  PreviewableSubProperty,
} from "replo-runtime/shared/utils/preview";
import {
  getPreviewPropertyClassName,
  previewablePropertyDataMapping,
} from "replo-runtime/shared/utils/preview";
import { getTransformStyleString } from "replo-runtime/shared/utils/transform";
import {
  coerceNumberToString,
  isEmpty,
  isNotNullish,
} from "replo-utils/lib/misc";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";

import {
  selectActiveCanvas,
  selectVisibleCanvases,
} from "@/features/canvas/canvas-reducer";

import { getEditorComponentNodes } from "./component";

/**
 * Hook which provides access to starting/ending/setting in-canvas previews for
 * specific previewable properties, which are useful for providing fast user feedback
 * while values of a certain CSS property are being updated in quick succession.
 *
 * Exposes three methods:
 *
 * - enableCanvasPreviewCSSProperties: Starts the preview. After calling this,
 * the node will take on whatever value was last set with setPreviewCSSPropertyValue
 * - setPreviewCSSPropertyValue: Sets the preview value. Usually called directly
 * before starting previews or when a value is updated. After this, the node will have
 * the given value for the preview property until disableCanvasPreviewCSSProperties is
 * called.
 * - disableCanvasPreviewCSSProperties: Stops the preview. After calling this, any
 * preview values have no effect and the node will use whatever its last painted value
 * was.
 */
export function useInCanvasPreview() {
  const store = useEditorStore();

  const setPreviewCSSPropertyValue = React.useCallback(
    (
      properties: PreviewableProperty[],
      value: string,
      subProperty?: PreviewableSubProperty,
      propertyIndex?: number,
    ) => {
      const activeCanvas = selectActiveCanvas(store.getState());
      const canvasToDraftComponentNodeMapping =
        getCanvasToDraftComponentNodeMapping(store);

      if (!canvasToDraftComponentNodeMapping) {
        return;
      }

      let cssValue = value;
      // Note (Sebas, 2022-09-23): Properties that require border-width to be set
      const borderProperties = new Set([
        "borderWidth",
        "borderBottomWidth",
        "borderLeftWidth",
        "borderRightWidth",
        "borderTopWidth",
      ]);
      const hasBorderStyles = selectHasBorderStyles(store.getState());

      // Note (Sebas, 2022-09-07): We have to check the previous values of the transform
      // property because if not, we reset all the values and only apply the modified one.
      if (properties.includes("transform") && subProperty) {
        const previousTransformValues = selectTransform(store.getState());
        cssValue = getPreviewTransformValue(
          previousTransformValues,
          subProperty,
          value,
        );
      }

      if (properties.includes("transformOrigin") && subProperty) {
        cssValue = getPreviewTransformOriginValue(
          canvasToDraftComponentNodeMapping[activeCanvas] ?? null,
          subProperty,
          value,
        );
      }

      const previousBoxShadow = selectBoxShadow(store.getState());
      if (
        properties.includes("boxShadow") &&
        isNotNullish(propertyIndex) &&
        subProperty &&
        previousBoxShadow
      ) {
        cssValue = getPreviewBoxShadowsValue(
          previousBoxShadow,
          propertyIndex,
          subProperty,
          value,
        );
      }

      const previousObjectPositionValues = selectObjectPosition(
        store.getState(),
      );

      const previousBackgroundSizeValues = selectBackgroundSize(
        store.getState(),
      );
      if (
        properties.includes("backgroundSize") &&
        subProperty &&
        previousBackgroundSizeValues
      ) {
        cssValue = getPreviewBackgroundSize(
          coerceNumberToString(previousBackgroundSizeValues),
          subProperty,
          value,
        );
      }

      const previousTextShadow = selectTextShadow(store.getState());
      if (
        properties.includes("textShadow") &&
        isNotNullish(propertyIndex) &&
        subProperty &&
        previousTextShadow
      ) {
        cssValue = getPreviewTextShadowValue(
          previousTextShadow,
          propertyIndex,
          subProperty,
          value,
        );
      }

      const previousTextOutline = selectTextOutline(store.getState());
      if (
        properties.includes("__textStroke") &&
        subProperty &&
        previousTextOutline
      ) {
        cssValue = getPreviewTextOutlineValue(
          previousTextOutline,
          subProperty,
          value,
        );
      }

      for (const property of properties) {
        const nodesToStyle = getCanvasNodesToStyle(
          store,
          canvasToDraftComponentNodeMapping,
          property,
        );

        for (const node of nodesToStyle) {
          node?.style.setProperty(
            `--${previewablePropertyDataMapping[property].cssVariable}`,
            cssValue,
          );
        }

        if (
          property === "objectPosition" &&
          subProperty &&
          previousObjectPositionValues
        ) {
          cssValue = getPreviewObjectPosition(
            coerceNumberToString(previousObjectPositionValues),
            subProperty,
            value,
          );
          for (const node of nodesToStyle) {
            const imgDraftComponentNodes = node?.querySelectorAll("img");
            if (imgDraftComponentNodes) {
              imgDraftComponentNodes[0]?.style.removeProperty(
                `--${previewablePropertyDataMapping["objectPosition"].cssVariable}`,
              );
              imgDraftComponentNodes[0]?.classList.add(
                getPreviewPropertyClassName("objectPosition"),
              );
              imgDraftComponentNodes[0]?.style.setProperty(
                `--${previewablePropertyDataMapping["objectPosition"].cssVariable}`,
                cssValue,
              );
            }
          }
        }

        // Note (Sebas, 2022-09-23): If there is no border style set and
        // the property is a border property , we need to add border style
        // for the preview to work.
        if (borderProperties.has(property) && !hasBorderStyles) {
          for (const node of nodesToStyle) {
            node?.style.setProperty("border-style", "solid");
          }
        }
      }
    },
    [store],
  );

  const enableCanvasPreviewCSSProperties = React.useCallback(
    (
      properties: PreviewableProperty[],
      value: string,
      subProperty?: PreviewableSubProperty,
      propertyIndex?: number,
    ) => {
      const canvasToDraftComponentNodeMapping =
        getCanvasToDraftComponentNodeMapping(store);

      if (!canvasToDraftComponentNodeMapping) {
        return;
      }

      for (const property of properties) {
        const nodesToStyle = getCanvasNodesToStyle(
          store,
          canvasToDraftComponentNodeMapping,
          property,
        );

        for (const node of nodesToStyle) {
          node?.style.removeProperty(
            `--${previewablePropertyDataMapping[property].cssVariable}`,
          );
          node?.classList.add(getPreviewPropertyClassName(property));
        }
      }

      setPreviewCSSPropertyValue(properties, value, subProperty, propertyIndex);
    },
    [setPreviewCSSPropertyValue, store],
  );

  const disableCanvasPreviewCSSProperties = React.useCallback(
    (properties: PreviewableProperty[]) => {
      const canvasToDraftComponentNodeMapping =
        getCanvasToDraftComponentNodeMapping(store);

      if (!canvasToDraftComponentNodeMapping) {
        return;
      }

      for (const property of properties) {
        const nodesToStyle = getCanvasNodesToStyle(
          store,
          canvasToDraftComponentNodeMapping,
          property,
        );

        for (const node of nodesToStyle) {
          node?.classList.remove(getPreviewPropertyClassName(property));

          if (property === "objectPosition") {
            const imgDraftComponentNodes = node?.querySelectorAll("img");
            if (imgDraftComponentNodes) {
              imgDraftComponentNodes[0]?.classList.remove(
                getPreviewPropertyClassName("objectPosition"),
              );
            }
          }
        }
      }
    },
    [store],
  );

  return {
    disableCanvasPreviewCSSProperties,
    enableCanvasPreviewCSSProperties,
    setPreviewCSSPropertyValue,
  };
}

type CanvasToDraftComponentNodeMapping = Partial<
  Record<EditorCanvas, HTMLElement>
>;

function getCanvasToDraftComponentNodeMapping(store: EditorStore) {
  const visibleCanvases = selectVisibleCanvases(store.getState());
  const draftElementId = selectDraftElementId(store.getState());
  const draftComponentId = selectDraftComponentId(store.getState());

  if (!draftElementId || !draftComponentId) {
    return;
  }

  const canvasToDraftComponentNodeMapping: CanvasToDraftComponentNodeMapping =
    {};

  Object.entries(visibleCanvases).forEach(([key, canvas]) => {
    const targetDocument = canvas?.targetFrame.contentDocument;
    if (!targetDocument) {
      return;
    }

    const nodes = getEditorComponentNodes(
      targetDocument,
      draftElementId,
      draftComponentId,
    );

    if (nodes.length === 0) {
      return;
    }

    const draftRepeatedIndex = selectDraftRepeatedIndex(store.getState());
    const draftComponentNode =
      nodes.find((n) => n.dataset.reploRepeatedIndex === draftRepeatedIndex) ??
      nodes[0];

    if (draftComponentNode) {
      canvasToDraftComponentNodeMapping[key as EditorCanvas] =
        draftComponentNode;
    }
  });

  if (isEmpty(canvasToDraftComponentNodeMapping)) {
    return;
  }

  return canvasToDraftComponentNodeMapping;
}

function getCanvasNodesToStyle(
  store: EditorStore,
  canvasToDraftComponentNodeMapping: CanvasToDraftComponentNodeMapping,
  property: PreviewableProperty,
) {
  const draftComponent = selectDraftComponent(store.getState());
  const draftComponentStyles = draftComponent
    ? getStylePropsFromComponent(draftComponent)
    : null;
  const activeCanvas = selectActiveCanvas(store.getState());
  const activeCanvasDependentsSizes =
    editorCanvasToMediaSize[activeCanvas].dependents;

  if (!isFeatureEnabled("multiple-canvases")) {
    return [canvasToDraftComponentNodeMapping["desktop"]];
  }

  const nodesToStyle = [];
  const activeCanvasNode = canvasToDraftComponentNodeMapping[activeCanvas];
  if (activeCanvasNode) {
    nodesToStyle.push(activeCanvasNode);
  }

  for (const dependentSize of activeCanvasDependentsSizes) {
    const dependentSizeStyles =
      draftComponentStyles?.[`style@${dependentSize}`];
    // If the property is already set in a dependent size, this canvas should
    // not be styled. This is in order to avoid unnecessary styling for
    // previewable properties.
    if (dependentSizeStyles?.[property as RuntimeStyleAttribute]) {
      // NOTE (Martin, 2024-08-05): we entirely break the for-loop because
      // as soon as we recognize a dependent size that has the property set,
      // we know that other dependent sizes will depend on this value.
      break;
    }

    const dependentSizeNode =
      canvasToDraftComponentNodeMapping[MediaSizeToEditorCanvas[dependentSize]];

    if (dependentSizeNode) {
      nodesToStyle.push(dependentSizeNode);
    }
  }

  return nodesToStyle;
}

function getPreviewTransformValue(
  previousTransformValues: any,
  subProperty: PreviewableSubProperty,
  value: string,
) {
  let newValues = {
    ...previousTransformValues,
    // Note (Sebas, 2022-09-07): Value can be an empty string so we need
    // to use || instead of ??
    [subProperty as string]: value || null,
  };
  if (subProperty === "scaleXY") {
    newValues = {
      ...previousTransformValues,
      scaleX: value,
      scaleY: value,
    };
  }
  return getTransformStyleString(newValues);
}

function getPreviewTransformOriginValue(
  draftComponentNode: HTMLElement | null,
  subProperty: PreviewableSubProperty,
  value: string,
) {
  const previousValues =
    draftComponentNode?.style.getPropertyValue("transform-origin") || "";
  const parsedValues =
    previousValues?.length > 0
      ? previousValues.split(" ")
      : ["50%", "50%", "0px"];
  if (subProperty === "transformOriginX") {
    parsedValues[0] = value;
  } else if (subProperty === "transformOriginY") {
    parsedValues[1] = value;
  } else {
    parsedValues[2] = value;
  }
  return parsedValues.join(" ");
}

function getPreviewBoxShadowsValue(
  previousBoxShadow: string,
  internalPropIndex: number,
  subProperty: PreviewableSubProperty,
  value: string,
) {
  const boxShadowObject = getBoxShadowObject(previousBoxShadow.split(","));
  const selectedBoxShadowObject = boxShadowObject[internalPropIndex]!;
  selectedBoxShadowObject[
    subProperty as "offsetX" | "offsetY" | "blur" | "spread"
  ] = value;

  return getBoxShadowString(boxShadowObject);
}

function getPreviewObjectPosition(
  previousObjectPositionValue: string | null,
  subProperty: PreviewableSubProperty,
  value: string,
) {
  const [objPositionXValue, objPositionYValue] = previousObjectPositionValue
    ? previousObjectPositionValue.split(" ")
    : ["0px", "0px"];

  if (subProperty === "objectPositionX") {
    return `${value} ${objPositionYValue}`;
  }

  if (subProperty === "objectPositionY") {
    return `${objPositionXValue} ${value}`;
  }

  return `${objPositionXValue} ${objPositionYValue}`;
}

function getPreviewBackgroundSize(
  previousBackgroundSizeValue: string | null,
  subProperty: PreviewableSubProperty,
  value: string,
) {
  const [bgSizeWidth, bgSizeHeight] = previousBackgroundSizeValue
    ? previousBackgroundSizeValue.split(" ")
    : ["auto", "auto"];

  if (subProperty === "backgroundSizeWidth") {
    return `${value} ${bgSizeHeight}`;
  }

  if (subProperty === "backgroundSizeHeight") {
    return `${bgSizeWidth} ${value}`;
  }

  return `${bgSizeWidth} ${bgSizeHeight}`;
}

function getPreviewTextShadowValue(
  previousTextShadow: string,
  internalPropIndex: number,
  subProperty: PreviewableSubProperty,
  value: string,
) {
  const textShadowObject = getTextShadowObject(previousTextShadow.split(","));
  const selectedTextShadowObject = textShadowObject[internalPropIndex]!;
  selectedTextShadowObject[subProperty as "offsetX" | "offsetY" | "blur"] =
    value;

  return getTextShadowString(textShadowObject);
}

function getPreviewTextOutlineValue(
  previousTextOutline: string,
  subProperty: PreviewableSubProperty,
  value: string,
) {
  const textOutlineObject = getTextOutlineObject(previousTextOutline);
  textOutlineObject[subProperty as "width"] = value;

  return getTextOutlineString(textOutlineObject);
}

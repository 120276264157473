import productTemplate from "@components/editor/templates/product/product";
import type { Component } from "replo-runtime/shared/Component";
import { refreshComponentIds } from "replo-shared/refreshComponentIds";
import type { ReploElementType } from "schemas/element";

export const getNewPagePlaceholderComponent = (
  elementType: ReploElementType,
) => {
  const template: Component = {
    id: "$uid",
    type: "container",
    props: {
      style: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    children:
      elementType === "shopifyProductTemplate" && productTemplate.template
        ? [productTemplate.template]
        : [],
  };
  return refreshComponentIds(template).component;
};

import * as React from "react";
import { noop } from "replo-utils/lib/misc";

const ErrorBoundaryContext = React.createContext<ErrorBoundaryContextValue>({
  error: undefined,
  reset: noop,
});

const initialState: ErrorBoundaryState = {
  didCatch: false,
  error: null,
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = initialState;

  static getDerivedStateFromError(error: unknown) {
    return { didCatch: true, error };
  }

  componentDidCatch(error: unknown, info: React.ErrorInfo) {
    this.props.onError?.(error, info);
  }

  reset = () => {
    if (this.state.didCatch) {
      this.props.onReset?.(this.state.error);
      this.setState(initialState);
    }
  };

  render() {
    const { fallback } = this.props;
    const { didCatch, error } = this.state;
    const context = { error, reset: this.reset };

    let children = this.props.children;
    if (didCatch) {
      if (React.isValidElement(fallback)) {
        children = fallback;
      } else if (typeof fallback === "function") {
        children = fallback(context);
      } else if (fallback !== undefined) {
        children = fallback;
      } else {
        children = <ErrorBoundaryDefaultFallback />;
      }
    }

    return (
      <ErrorBoundaryContext.Provider value={context}>
        {children}
      </ErrorBoundaryContext.Provider>
    );
  }
}

export function ErrorBoundaryDefaultFallback() {
  return (
    <p>
      Uh oh! An error has occurred. Please try refreshing the page. If this
      error persists, feel free to reach out to{" "}
      <a className="underline" href="mailto:support@replo.app">
        support@replo.app
      </a>
      .
    </p>
  );
}

export function useErrorBoundaryContext() {
  return React.useContext(ErrorBoundaryContext);
}

interface ErrorBoundaryState {
  didCatch: boolean;
  error: unknown;
}

interface ErrorBoundaryContextValue {
  error: unknown;
  reset: () => void;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback?:
    | React.ReactNode
    | ((props: ErrorBoundaryContextValue) => React.ReactNode);
  onError?: (error: unknown, info: React.ErrorInfo) => void;
  onReset?: (error: unknown) => void;
}
